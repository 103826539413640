import {NGXLogger} from 'ngx-logger';

import {PreferencesCoreService} from '../../core/platform/preferences/preferences.service';
import {PropertyFactory} from '../../core/property/property.factory';
import {PropertyFileFactory} from '../../core/property/propertyFile.factory';
import {DataService} from '../../services/communication/data.service';
import {SessionStorageService} from '../../services/session-storage.service';
import {PropertyTimestampService} from './property-timestamp.service';
import {PropertyStateService} from './property-state.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PropertyFactoryProvider = {
	provide: PropertyFactory,
	deps: [DataService, PropertyStateService, PropertyFileFactory, PreferencesCoreService, NGXLogger, SessionStorageService,
		PropertyTimestampService],
	useFactory: getPropertyFactory
};

export function getPropertyFactory(
	dataService: DataService,
	propertyStateService: PropertyStateService,
	propertyFileFactory: PropertyFileFactory,
	preferencesCoreService: PreferencesCoreService,
	logger: NGXLogger,
	sessionStorageService: SessionStorageService,
	propertyTimestampService: PropertyTimestampService
): any {
	return new PropertyFactory(dataService, propertyStateService, propertyFileFactory, preferencesCoreService, true, Promise, logger,
		sessionStorageService, propertyTimestampService);
}
