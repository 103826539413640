import {Injectable} from '@angular/core';

import DeviceConfigCoreService from '../core/platform/services/DeviceConfigServices';
import {WindowRefService} from './window-ref.service';
import {ZoomService} from './zoom/zoom.service';

@Injectable({
	providedIn: 'root',
	deps: [WindowRefService, ZoomService],
	useFactory: getDeviceConfigCoreService
})
export class DeviceConfigService extends DeviceConfigCoreService {}

export function getDeviceConfigCoreService(
	window: WindowRefService,
	zoomService: ZoomService
): DeviceConfigCoreService {
	return new DeviceConfigCoreService(window.nativeWindow, window.nativeWindow.window, zoomService);
}
