import {isString, isUndefined} from 'lodash';

import {PropertyFactory} from '../../property/property.factory';

let propertiesPromise;

export const loadProperties = (propertyFactory: PropertyFactory): Promise<any> => {
	if (isUndefined(propertiesPromise)) {
		propertiesPromise = propertyFactory.loadProperties([
			{
				name: 'container_web-common_slideOutContainer_strings.properties',
				path: '/components/zed-core/properties/generic/slideOutContainer/'
			}
		]);
	}
	return propertiesPromise;
};

export const getSlideOutRoute = (route: string | string[]): string[] => (isString(route) ? route.split('/') : route);
