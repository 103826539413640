import * as jQuery from 'jquery';
import {endsWith, get, isEmpty, isNumber, size} from 'lodash';

import {SimpleInputType} from '../generic/simple-input/simple-input.interfaces';
import {SearchAttrs} from './search.interfaces';

import {getUniqueId} from '../platform/form-element.utility';
import {readForSR} from '../platform/accessibility/accessibility-speaker.utility';
import {PropertyFactory} from '../property/property.factory';
import {PropertyFilterConfig} from '../property/property.filter';
import {AUTOMATION_ID_ATTR, DEBOUNCE_TIME, DEFAULT_INPUT_CLASSES, DEFAULT_OPTIONS, SUBMIT_ON_ENTER_CLASS} from './search.constants';

const $: any = jQuery;

export const getAutomationId = (attrs: object, suffix: string): string => {
	const automationId = get(attrs, AUTOMATION_ID_ATTR);

	if (isEmpty(automationId)) {
		return undefined;
	}
	return endsWith(automationId, 'Input') ? automationId : `${automationId}${suffix}`;
};

export const getBtnClasses = (btnKey: string, aligment: string, label: string, btnStyleType = 'secondary'): string =>
	`btn-${btnStyleType} position-relative ${btnKey}-btn p-xs`;

export const getFormElementClasses = (submitOnEnter: boolean, searchBoxClass?: string): string[] => {
	const formElementClasses = [...DEFAULT_INPUT_CLASSES];

	if (searchBoxClass) {
		formElementClasses.push(searchBoxClass);
	}
	if (submitOnEnter) {
		formElementClasses.push(SUBMIT_ON_ENTER_CLASS);
	}
	return formElementClasses;
};

export const getInputAttrs = (attrs: SearchAttrs, defaultPlaceholder: string, propertyFilter: PropertyFilterConfig, label?: string): SearchAttrs => ({
	...attrs,
	'aria-label': get(attrs, 'aria-label') || (label ? undefined : propertyFilter('common.search.placeholder')),
	[AUTOMATION_ID_ATTR]: getAutomationId(attrs, 'Input'),
	id: getUniqueId(attrs, label, DEFAULT_OPTIONS.BOX_ID),
	placeholder: get(attrs, 'placeholder') || defaultPlaceholder,
	type: SimpleInputType.SEARCH
});

export const hasSearchValue = (searchTerm: string): boolean => !isEmpty(searchTerm);

export const loadProperties = (propertyFactory: PropertyFactory): Promise<any> =>
	propertyFactory.loadProperties([
		{
			name: 'container_web-common_search_strings.properties',
			path: '/components/zed-core/properties/search/'
		}
	]);

export const notifyUserOfSearch = (searchTerm: string, totalResults: number, propertyFilter: PropertyFilterConfig): void => {
	const readConfig = {readTimeout: DEBOUNCE_TIME};

	if (!hasSearchValue(searchTerm)) {
		readForSR(propertyFilter('common.search.cleared'), readConfig, $);
	} else if (isNumber(totalResults)) {
		if (totalResults > 0) {
			readForSR(propertyFilter('common.search.xMatchesFound', [totalResults]), readConfig, $);
		} else {
			readForSR(propertyFilter('common.search.noMatchesFound'), readConfig, $);
		}
	}
};

export const selectText = (event: FocusEvent, searchTerm: string): void => {
	const inputElement = get(event, 'target') as HTMLInputElement;

	if (!isEmpty(searchTerm) && inputElement) {
		try {
			inputElement.setSelectionRange(0, size(searchTerm));
		} catch (ex) {
			// some browsers (Chrome), support the w3 standard that number, email, ...
			// fields should not allow setSelectionRange.  We ignore the error for those browsers
			// https://www.w3.org/Bugs/Public/show_bug.cgi?id=24796
		}
	}
};
