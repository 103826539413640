import {Injectable} from '@angular/core';

import {OneAppEventBusCoreService} from '../core/one-app/one-app-event-bus.core.service';
import {SessionStorageCoreService} from '../core/platform/caching/sessionStorage.service';
import {PropertyFactory} from '../core/property/property.factory';
import {PropertyFilterPipe} from '../providers/property/property.filter.pipe';
import {ClientContainerService} from './clientContainer/client-container.service';
import {SessionStorageService} from './session-storage.service';
import {WindowRefService} from './window-ref.service';

@Injectable({providedIn: 'root'})
export class OneAppEventBusService extends OneAppEventBusCoreService {
	constructor(
		windowRefService: WindowRefService,
		sessionStorage: SessionStorageService,
		protected clientContainerService: ClientContainerService,
		protected propertyFactory: PropertyFactory,
		protected propertyFilter: PropertyFilterPipe
	) {
		super(windowRefService.nativeWindow, sessionStorage as SessionStorageCoreService, clientContainerService, propertyFactory);
	}

	public getProperties = (key: string): string => this.propertyFilter.transform(key);
}
