import {assign} from 'lodash';

export class MenuItemConfig {
	public isNested = false;
	public tooltip = 'default tooltip';
	public id: number;
	public isSelected = false;
	public icon = 'icon';
	public text = 'default text item';
	public label?: string;
	public options = {
		expanded: false,
		visible: true
	};
	public menu: MenuItemConfig[];
	public category = null;
	public innerMenu: MenuItemConfig[] = [];
	public target = null;
	public NEW_WINDOW_TYPE_ID = null;
	public userMenuState: any;
	public isSearch = false;
	public typeId = null;
	public filteredMenu = [];
	public uri?: string;
	public href?: string;

	public searchId?: string;
	public automationId?: string;

	public isSPA?: boolean;

	public totalCategory?: number;
	public positionItem?: number;
	public foundLinksAmount?: number;
	public shiftTabToId?: string;
	public tabToId?: string;

	constructor(private menuItemProperties?: Partial<MenuItemConfig>) {
		assign(this, menuItemProperties);
	}
}
