import {NgModule} from '@angular/core';

import {PropertyFileFactoryProvider} from './property-file.factory.provider';
import {PropertyStateServiceProvider} from './property-state.service.provider';
import {PropertyFactoryProvider} from './property.factory.provider';
import {PropertyFilterPipe} from './property.filter.pipe';
import {PropertyFilterProvider} from './property.filter.provider';

@NgModule({
	exports: [PropertyFilterPipe],
	declarations: [PropertyFilterPipe],
	providers: [PropertyStateServiceProvider, PropertyFileFactoryProvider, PropertyFactoryProvider, PropertyFilterProvider]
})
export class PropertyModule {}
