import {Directive} from '@angular/core';
import {get} from 'lodash';

import {ActionItemBaseComponent} from './action-item-base.component';

@Directive()
export abstract class ActionItemIframeBaseComponent extends ActionItemBaseComponent {
	public onClick(event: Event): void {
		this.slideOutHelperService.openSlider(
			['legacySlideout', `${this.options.id}`],
			{autoFocus: get(this.options, 'autoFocus', false), returnFocusElement: this.returnFocusId()},
			this.context,
			get(this.options, 'skipLocationChange', true)
		);
	}
}
