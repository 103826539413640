import {Injectable} from '@angular/core';
import {get, includes, keys} from 'lodash';
import {Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class OtherActionService {
	private otherActionSource = new Subject();

	public dispatch = (action): void => {
		this.otherActionSource.next(action);
	};

	public otherActionHandler = (actions, dispatch): void => {
		this.otherActionSource.subscribe(action => {
			if (includes(keys(actions), get(action, 'type'))) {
				dispatch(action);
			}
		});
	};
}
