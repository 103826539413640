import {get} from 'lodash';

import {AuthStack} from './authStack/auth-stack.interface';

import {SESSION_TIMEOUT_PROPERTIES} from './session.constants';
import {AuthenticationRegistrationCoreService} from '../authentication/authentication.registration.core.service';
import {PropertyFactory} from '../property/property.factory';
export abstract class SessionCoreService {
	protected authStackService: AuthStack;
	protected isAuth0Service: boolean;

	protected constructor(
		protected authenticationRegistrationService: AuthenticationRegistrationCoreService,
		protected propertyFactory: PropertyFactory
	) {}

	/* eslint-disable @typescript-eslint/no-empty-function */
	// Following protected methods will be implemented by descendents to implement framework specific logic.
	public extendedCallback(eventName: string, eventArgs): any {}
	public expiringCallback(eventName: string, eventArgs): any {}
	public expiredCallback(eventName: string, eventArgs): any {}
	public endedCallback(eventName: string, eventArgs?): any {}
	public reconnectCallback(eventName: string, eventArgs): any {}
	public unavailableCallback(eventName: string, eventArgs): any {}

	public destroy(): void {
		if (get(this, 'authStackService.destroy')) {
			this.authStackService.destroy();
		}
	}

	protected initAuthService(): void {
		this.authenticationRegistrationService.whenAuthenticated(() =>
			this.propertyFactory.loadProperties(SESSION_TIMEOUT_PROPERTIES).then(() => {
				this.setAuthStackService();
			}));
	}

	protected setAuthStackService(): void {}
}
