import {Pipe, PipeTransform} from '@angular/core';

import {setCategoriesPosition, setFilterCategoriesPosition} from '../../../../../core/navBar/accessibility-helper.utility';
import {MenuItemConfig} from '../../../../../core/navBar/models/menu-item-config.model';

@Pipe({
	name: 'menuItemPositions'
})
export class MenuItemPositionsPipe implements PipeTransform {
	public transform(items: MenuItemConfig[] = [], isSearch = false): MenuItemConfig[] {
		if (isSearch) {
			setFilterCategoriesPosition(items);
		} else {
			setCategoriesPosition(items, isSearch);
		}

		return items;
	}
}
