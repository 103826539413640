import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

import {ClientContainer} from '../../core/platform/clientContainer/client-container.interface';

import {BrowserContainerCoreService} from '../../core/platform/clientContainer/browser-container.core.service';
import {ClientContainerCoreService} from '../../core/platform/clientContainer/client-container.core.service';
import {ClientContainerContextService} from '../../services/clientContainer/client-container-context.service';
import {SessionStorageService} from '../../services/session-storage.service';
import {EmbeddedMenuItemsService} from '../embedded-menu-items.service';
import {WindowRefService} from '../window-ref.service';
import {DataService} from '../communication/data.service';

@Injectable({
	providedIn: 'root',
	deps: [WindowRefService, SessionStorageService, ClientContainerContextService, EmbeddedMenuItemsService, DataService, NGXLogger],
	useFactory: getClientContainerService
})
export class ClientContainerService extends BrowserContainerCoreService {}

export function getClientContainerService(
	windowRefService: WindowRefService,
	sessionStorageService: SessionStorageService,
	clientContainerContextService: ClientContainerContextService,
	embeddedMenuItemsService: EmbeddedMenuItemsService,
	apiService: DataService,
	logger: NGXLogger): ClientContainer {
	return new ClientContainerCoreService(windowRefService.nativeWindow, sessionStorageService, clientContainerContextService, embeddedMenuItemsService, apiService, logger).getContainer();
}
