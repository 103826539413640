import {RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';

export class AppRouteReuseStrategy implements RouteReuseStrategy {
	public shouldDetach(/* route: ActivatedRouteSnapshot */): boolean {
		return false;
	}
	public store(/* route: ActivatedRouteSnapshot */): void {
		return null;
	}
	public shouldAttach(/* route: ActivatedRouteSnapshot */): boolean {
		return false;
	}
	public retrieve(/* route: ActivatedRouteSnapshot */): DetachedRouteHandle {
		return null;
	}

	// https://engjira.int.kronos.com/browse/WFD-167301
	// This is the important part for our use case where when you switch between calendars (schedules or absence calendars), only fragments (after #)
	// change, for instance from wfd/ess/myschedule#/3014003?pageId=-8003014003 to wfd/ess/myschedule#/3014002?pageId=-8003014002
	// is considered the same route, so myschedule module was not being reloaded. This makes myschedule module get unloaded/reloaded
	// when you switch calendars. By default Angular reuses the component if the route isn't changed. Ideally we wouldn't have this
	// route reuse strategy but instead would just reset the state for my schedule component, so a full page reload wouldn't be needed. This is the
	// the quicker workaround.
	public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
		//We need to check both current and future since closing the slider is also a route change
		const futureUrl = future['_routerState'].url as string;
		const currentUrl = current['_routerState'].url as string;
		const isOpeningOrClosingSlider = futureUrl.includes('app-slideout') || currentUrl.includes('app-slideout');

		if (
			(futureUrl.startsWith('/ess/myschedule') && currentUrl.startsWith('/ess/myschedule') && !isOpeningOrClosingSlider) ||
			(futureUrl.startsWith('/ess/myabsencecalendar') && currentUrl.startsWith('/ess/myabsencecalendar') && !isOpeningOrClosingSlider)
		) {
			return false;
		}
		//Default behavior
		return future.routeConfig === current.routeConfig;
	}
}
