<div class="modal-header node-down-error-modal-header">
	<span>{{"app.core.node.down.error.header" | property}}</span>
</div>
<div class="modal-body node-down-error-modal-body">
	<img class="node-down-error-modal-content-title node-down-error-modal-content-img" src="/images/500-error-spot.svg">
	<p class="modal-content-title node-down-error-modal-content-title truncate">
		{{"app.core.node.down.error.body"| property}}
	</p>
	<p class="modal-content-text node-down-error-modal-content-text truncate">{{"app.core.node.down.content.title"| property}}</p>

	<p class="modal-error-message node-down-error-modal-error-message">
		{{"app.core.node.down.content.body"| property}}
	</p>
	<a class="modal-home-link node-down-error-modal-home-link" href="/" (click)="onClose()">{{"app.core.node.down.content.home"| property}}</a>
</div>
