import {Injectable} from '@angular/core';

import {UnsavedDataService} from '../../../services/unsaved-data-service';
import {EmbeddedUnsavedDataCoreService} from '../../../core/embedded/embedded-unsaved-data.core.service';
import {WindowRefService} from '../../../services/window-ref.service';
import {DefaultSliderActionHandler} from '../slide-out/slide-out-content/handlers/default-slider-action-handler';
import {SlideOutManagerService} from '../slide-out/slide-out-manager.service';

@Injectable({providedIn: 'root'})
export class EmbeddedUnsavedDataService extends EmbeddedUnsavedDataCoreService {
	constructor(
        protected windowRefService: WindowRefService,
        protected unsavedDataService: UnsavedDataService,
        protected slideOutManagerService: SlideOutManagerService,
		protected defaultSliderActionHandler: DefaultSliderActionHandler
	) {
        super(windowRefService.nativeWindow, unsavedDataService, slideOutManagerService);
    }

	protected verifyUnsavedDataForLastSlideOut = (): Promise<boolean> => {
		if (this.isIframeSlideOutDirty(this.defaultSliderActionHandler)) {
			return this.verifyUnsavedDataForIframeSlideOut();
		}
		return super.verifyUnsavedDataForLastSlideOut();
	};

	protected verifyUnsavedDataForAllSlideOuts = (): Promise<boolean> => {
		if (this.isIframeSlideOutDirty(this.defaultSliderActionHandler)) {
			return this.verifyUnsavedDataForIframeSlideOut();
		}
		return super.verifyUnsavedDataForAllSlideOuts();
	};
}
