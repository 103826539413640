import {AfterViewInit, Component, Inject, Input, OnChanges, OnInit, Optional} from '@angular/core';

import {IllustrationMessageAttributes} from '../../core/no-data-message/illustration-message.interfaces';

import {
	computeIllustrationStatus,
	createDefaultConfig,
	getImagePath,
	getPropertyName,
	loadProperties
} from '../../core/no-data-message/no-data-message.utility';
import {PropertyFactory} from '../../core/property/property.factory';
import {PropertyFilterPipe} from '../../providers/property/property.filter.pipe';
import {BASE_PATH} from '../../services/communication/data.config';

@Component({
	selector: 'krn-ng-illustration-message',
	templateUrl: './illustration-message.component.html',
	styleUrls: ['./illustration-message.component.less'],
	providers: [PropertyFilterPipe]
})
export class IllustrationMessageComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() public illustration: string;
	@Input() public customMessage: string;
	@Input() public customImage: string;
	@Input() public classes: string;
	@Input() public showIllustration: boolean;
	@Input() public attrs: IllustrationMessageAttributes;

	public imgSrc;
	public illustrationStatus = false;
	public propertyName;

	private propertiesPromise: Promise<any>;

	constructor(
		protected propertyFactory: PropertyFactory,
		@Optional() @Inject(BASE_PATH) private basePath: string
	) {}

	public async ngOnInit(): Promise<void> {
		this.propertiesPromise = loadProperties(this.propertyFactory);
	}

	public ngOnChanges(): void {
		this.updateProperties();
	}

	public async ngAfterViewInit(): Promise<void> {
		this.propertiesPromise.then(() => {
			this.updateProperties();
		});
	}

	private updateProperties(): void {
		this.updateMessage();
		this.setImageSrc();
		this.setToolTip();
	}

	private updateMessage(): void {
		this.propertyName = getPropertyName(this.illustration);
	}

	private setImageSrc(): void {
		const imagePath = getImagePath(this.illustration, this.customImage);

		this.imgSrc = (this.basePath || '') + imagePath;
		this.illustrationStatus = computeIllustrationStatus(this.showIllustration, this.imgSrc);
	}

	private setToolTip(): void {
		this.attrs = createDefaultConfig(this.attrs);
	}
}
