import {Injectable} from '@angular/core';
import {assign, find, forEach, isArray, remove} from 'lodash';

import {ActionConfig} from './action-framework.interfaces';

@Injectable()
export class ActionFrameworkService {
	private actionInstances = [];

	public addActionInstance(action: ActionConfig): void {
		const cacheActionInstance = this.getActionInstance(action.id);

		if (cacheActionInstance) {
			assign(action, cacheActionInstance);
		}
		this.actionInstances.push(action);
	}

	public clearActionInstancesByJsonDataInput(jsonDataInput): void {
		if (isArray(jsonDataInput.elements)) {
			forEach(jsonDataInput.elements, element => {
				this.clearActionInstancesByJsonDataInput(element);
			});
		}
		if (jsonDataInput.id) {
			this.removeActionInstance(jsonDataInput.id);
		}
	}

	public updateActionInstance(action: ActionConfig): void {
		forEach(this.actionInstances, actionInstance => {
			if (action.id === actionInstance.id) {
				assign(actionInstance, action);
			}
		});
	}

	public focusActionInstance(actionId: string): void {
		if (actionId) {
			forEach(this.actionInstances, actionInstance => {
				if (actionId === actionInstance.id) {
					actionInstance.setFocus();
				}
			});
		}
	}

	private getActionInstance = (id: string): ActionConfig => find(this.actionInstances, {id});

	private removeActionInstance = (actionId: string): ActionConfig[] => remove(this.actionInstances, instance => instance.id === actionId);
}
