import {Inject, Injectable} from '@angular/core';

import {ApiService} from '../../../core/communication/api-service/api-service.interfaces';

import {UserInfoService} from '../../../core/framework/services/user-info.service';
import {NewReleaseFeaturePreferenceService} from '../../../core/navBar/new-release-feature-preference.service';
import {DataService} from '../../../services/communication/data.service';
import {SessionStorageService} from '../../../services/session-storage.service';

@Injectable({providedIn: 'root'})
export class NewFeaturesService extends NewReleaseFeaturePreferenceService {
	constructor(
		sessionStorageService: SessionStorageService,
		userInfoService: UserInfoService,
		@Inject(DataService) public apiDataService: ApiService
	) {
		super(sessionStorageService, userInfoService, apiDataService);
	}
}
