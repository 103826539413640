import {get} from 'lodash';

import {CordovaPluginWindow} from '../mobile/cordova-plugin-window.interface';
import {CommunicationEventName, ScanConfig, ScanResult, TabType} from './client-container.interface';
import {ApiService} from '../../communication/api-service/api-service.interfaces';
import {LogServiceInterface} from '../services/logService/log.interface';
import {GlobalData} from '../../one-app/one-app-event-bus.interfaces';

import {EmbeddedMenuItemsCoreService} from '../../embedded/embedded-menu-items.core.service';
import {IS_ONE_APP} from '../../embedded/embedded.constants';
import {COMMON_BRANDING, COMMON_FEATURE_FLAGS, SENDER_NAME} from '../../one-app/one-app-event-bus.constants';
import {OneAppMappingCoreService} from '../../one-app/one-app-mapping.core.service';
import {PropertyFactory} from '../../property/property.factory';
import {PropertyFilterConfig} from '../../property/property.filter';
import {SessionStorage} from '../caching/sessionStorage.service';
import {LOGON_PROFILE_SESSION_STORAGE_KEY, ONE_LOGON_PROFILE} from '../mobile/local-auth.constants';
import {OneAppLocalAuthCoreService} from '../oneApp/local-auth.service';
import {OneAppWifiLocationService} from '../oneApp/one-app-wifi-location.service';
import {BaseWifiLocationService} from '../services/base-wifi-location.service';
import {BaseEmbeddedContainerCoreService} from './base-embedded-container.core.service';
import {MobileCommonFeature} from './mobile-common-feature';

const THROTTLE_DELAY = 800;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const ONEAPP_MIN_VERSION = [1, 3, 6];
const ONEAPP_FORCEUPDATE_PATH = 'wfd/home/oneappForceUpdate';

export class OneAppSessionContainerCoreService extends BaseEmbeddedContainerCoreService {
	public searchMenuItem;
	private isNotlaunchingMap = true;
	private mobileCommon: MobileCommonFeature;

	constructor(
		protected window: CordovaPluginWindow,
		protected sessionStorage: SessionStorage,
		protected embeddedMenuItemsService: EmbeddedMenuItemsCoreService,
		protected apiService: ApiService,
		protected logger: LogServiceInterface
	) {
		super(window, sessionStorage, embeddedMenuItemsService);
		this.getLogonProfileFromOneApp();
		this.mobileCommon = new MobileCommonFeature(window);
		this.sessionStorage.set(IS_ONE_APP, 'true');
	}

	public getEventName = (): CommunicationEventName => CommunicationEventName.ONE_APP_EVENT_BUS;

	public localAuth = (propertyFactory: PropertyFactory, propertyFilter: PropertyFilterConfig): OneAppLocalAuthCoreService =>
		new OneAppLocalAuthCoreService(this.getTopWfdWindow(), propertyFactory, propertyFilter);

	public wifiLocation = (propertyFactory: PropertyFactory, propertyFilter: PropertyFilterConfig): BaseWifiLocationService =>
		new OneAppWifiLocationService(this.getTopWfdWindow(), this.supportsWifiLocation(), propertyFactory, propertyFilter);

	public mapping(propertyFactory: PropertyFactory, propertyFilter: PropertyFilterConfig): OneAppMappingCoreService | undefined {
		if (this.isNotlaunchingMap) {
			this.isNotlaunchingMap = false;
			setTimeout(() => {
				this.isNotlaunchingMap = true;
			}, THROTTLE_DELAY);

			return new OneAppMappingCoreService(this.getTopWfdWindow(), this.apiService, this.sessionStorage, this.logger, propertyFactory, propertyFilter);
		}
	}

	public scan = (scanConfig: ScanConfig = {prompt: ''}): Promise<ScanResult> => this.mobileCommon.scan(scanConfig);

	public supportsLocalAuth = (): boolean => !!get(this.getTopWfdWindow(), 'kronosLocalAuth.anyAuth');

	public supportsWifiLocation = (): boolean => this.mobileCommon.supportsWifiLocation();

	public supportsMapping = (): boolean => this.mobileCommon.supportsMapping();

	public supportsPrint = (): boolean => this.mobileCommon.supportsPrint();

	public print = (content?: string): void => this.mobileCommon.print(content);

	public openNewTab = (url: string, tab: string = TabType.BLANK): void => this.mobileCommon.openNewTab(url, tab);

	public navigatedBack(): void {
		this.dispatchEvent({detail: {method: 'navigatedBack'}});
	}

	public onRoleSwitched(data): void {
		this.dispatchEvent({detail: {method: 'onRoleSwitched', arg: data}});
		if (data.type !== 'switchProDefaultRole') {
			super.onRoleSwitched(data);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public replaceState = (path: string): void => {
		// do nothing
	};

	public signalContentComplete(pageIdentifier: string, senderID: string): void {
		const topWfdWindow: CordovaPluginWindow = this.getTopWfdWindow();

		topWfdWindow.OneAppCommon.UI.contentReady(topWfdWindow, senderID, {contentID: pageIdentifier});
	}

	public setGlobalData(data: GlobalData, senderID: string): void {
		const topWfdWindow: CordovaPluginWindow = this.getTopWfdWindow();

		topWfdWindow.OneAppCommon.DATA.setGlobalData(topWfdWindow, senderID, data);
	}

	public getGlobalData(key: string, senderID: string): Promise<any> {
		const topWfdWindow: CordovaPluginWindow = this.getTopWfdWindow();

		return topWfdWindow.OneAppCommon.DATA.getGlobalData(topWfdWindow, senderID, {key});
	}

	public b64DecodeJSON(b64jsonString: any): any {
		const topWfdWindow: CordovaPluginWindow = this.getTopWfdWindow();

		return topWfdWindow.OneAppCommon.UTIL.b64DecodeJSON(b64jsonString);
	}

	public getActionSubscription(action, callback): void {
		const topWfdWindow: CordovaPluginWindow = this.getTopWfdWindow();

		topWfdWindow.OneAppCommon.DATA.getActionSubscription(topWfdWindow, action, callback);
	}

	public getColorSync(colorsData: any): void {
		this.getGlobalData(COMMON_BRANDING, SENDER_NAME).then(response => {
			if (response.data && response.data.value) {
				const backgroundGradientColor = get(colorsData, 'themeExtension.NavigationBar.BackgroundGradientColor.colorValue') || '';
				const colorValue = get(this.b64DecodeJSON(response.data.value), 'headerBackground');

				if (backgroundGradientColor !== colorValue) {
					const cssTokenName = 'zed_sys_color_brandColorPrimary';

					colorsData.themeExtension.NavigationBar.BackgroundGradientColor = {
						colorValue,
						cssTokenName
					};

					return document.documentElement.style.setProperty(`--${cssTokenName}`, colorValue);
				}
			}
		});
	}

	public isOneAppVersionOutOfDate(senderID: string): void {
		const topWfdWindow: CordovaPluginWindow = this.getTopWfdWindow();

		topWfdWindow.OneAppCommon.UTIL.getDeviceInfo(topWfdWindow, senderID).then(deviceInfoResponse => {
			let deviceAppVersion = get(deviceInfoResponse, 'data.app.versions');

			deviceAppVersion = deviceAppVersion['app'] ? deviceAppVersion['app'] : deviceAppVersion['App'];

			const appPackageName = deviceInfoResponse.data.app.packageName.toUpperCase();

			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			if (this.checkSemver(deviceAppVersion) && appPackageName.search('UKG') >= 0) {
				this.forceUpdateCheck(topWfdWindow, senderID);
			}
		});
	}

	public checkFeatureFlag(featureFlag: string): Promise<boolean> {
		const topWfdWindow: CordovaPluginWindow = this.getTopWfdWindow();

		return topWfdWindow.OneAppCommon.DATA.getGlobalData(topWfdWindow, SENDER_NAME, {key: COMMON_FEATURE_FLAGS})
			.then((featureFlags: {data: {value: string}}) => {
				const parsedFlags = get(featureFlags, 'data.value') ? this.b64DecodeJSON(featureFlags.data.value) : {};

				return Promise.resolve(get(parsedFlags, featureFlag, false));
			})
			.catch(() => Promise.resolve(false));
	}

	public openADPDimSearch(dataKey: string, searchTabId: string, brand: string): void {
		const ADP = 'ADP';
		const topWfdWindow: CordovaPluginWindow = this.getTopWfdWindow();

		if (brand && brand.toLocaleUpperCase() === ADP) {
			if (!this.searchMenuItem) {
				topWfdWindow.OneAppCommon.DATA.getUserData(topWfdWindow, SENDER_NAME, {key: dataKey}).then(response => {
					const menus = get(response, 'data.value') ? topWfdWindow.OneAppCommon.UTIL.b64DecodeStr(response.data.value) : undefined;

					if (menus) {
						// Setting search menu item
						this.searchMenuItem = (JSON.parse(menus) as any[]).find(menu => menu && menu.id === searchTabId);
						topWfdWindow.OneAppCommon.NAV.forward(topWfdWindow, SENDER_NAME, this.searchMenuItem);
					}
				});
			} else {
				topWfdWindow.OneAppCommon.NAV.forward(topWfdWindow, SENDER_NAME, this.searchMenuItem);
			}
		}
	}

	private checkSemver(deviceAppVersion): boolean {
		deviceAppVersion = deviceAppVersion.split('.');

		for (let increment = 0; increment < ONEAPP_MIN_VERSION.length; increment++) {
			// eslint-disable-next-line no-console
			if (Number(deviceAppVersion[increment]) < ONEAPP_MIN_VERSION[increment]) {
				return true;
			} else if (Number(deviceAppVersion[increment]) > ONEAPP_MIN_VERSION[increment]) {
				return false;
			}
		}
		return false;
	}

	private forceUpdateCheck(win, senderID): void {
		const hostname = win.location.hostname;
		const navigateData = {
			id: 'dim.home.update',
			type: 'WEB_CORDOVA',
			label: 'Update Required',
			url: `https://${hostname}/${ONEAPP_FORCEUPDATE_PATH}`,
			showToolbar: false,
			showFooter: false
		};

		win.OneAppCommon.NAV.forward(win, senderID, navigateData);
	}

	private getLogonProfileFromOneApp = (): Promise<any> => {
		const topWfdWindow: CordovaPluginWindow = this.getTopWfdWindow();

		return topWfdWindow.OneAppCommon.DATA.getUserData(topWfdWindow, SENDER_NAME, {key: ONE_LOGON_PROFILE}).then(response => {
			if (response.data && response.data.value) {
				const logonProfile = topWfdWindow.OneAppCommon.UTIL.b64DecodeJSON(response.data.value);

				this.sessionStorage.set(LOGON_PROFILE_SESSION_STORAGE_KEY, logonProfile);
			}
		});
	};
}
