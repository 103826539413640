import {keyCodes, keys} from '../../platform/constants/keys.constant';

export const DEFAULT_DATA_LABEL = 'name';
export const EMPTY_VALUE = '';
export const W_100_CLASS = 'w-100';
export const LABEL_LEFT_CLASS = 'label-left';
export const SINGLE_ICON_LEFT_CLASS = 'single-icon-left';
export const FLEX_ROW_REVERSE_CLASS = 'flex-row-reverse';
export const ICON_FLEX_ROW_LEFT_CLASS = 'icon-flex-row-left';
export const ICON_FLEX_ROW_RIGHT_CLASS = 'icon-flex-row-right';
export const POPOVER_CONTENT_CLASS = 'popover-content';
export const DELETE_KEYS = [keys.BACKSPACE, keys.DEL, keyCodes.BACKSPACE, keyCodes.DEL];
export const PROPERTIES = {
	EMPTY_VALUE: 'picker.value.empty',
	TOOLTIP: 'picker.tooltipWithLabel'
};
export const SINGLE_SELECT_PICKER_PROPERTIES = [
	{
		name: 'container_web-common_single-select-picker_strings.properties',
		path: '/components/zed-core/properties/generic/single-select-picker/'
	}
];
export const POPOVER_CONTENT_SELECTOR = '.popoverBody';
export const MENUBAR = 'menubar';
export const DEFAULT_TOOLTIP = 'krn-default-tooltip';
export const EMPTY_CLASS = ' ';
