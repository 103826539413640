import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {get, isNil, isUndefined, uniqueId} from 'lodash';
import * as jQuery from 'jquery';

import {SingleSelectPickerAttrs} from '../../core/generic/single-select-picker/single-select-picker.interfaces';

import {
	DEFAULT_DATA_LABEL,
	EMPTY_VALUE,
	POPOVER_CONTENT_SELECTOR,
	MENUBAR
} from '../../core/generic/single-select-picker/single-select-picker.constants';
import {getSelectedValue} from '../../core/generic/single-select-picker/single-select-picker.utility';
import {DeviceConfigService} from '../../services/device-config.service';
import {ReactiveFormsBase} from '../../utils/reactive-forms/reactive-forms-base.class';

const NO_ITEMS = 0;
const ONE_ITEM_HEIGHT = 3;
const PADDING = 0.5;

@Component({
	selector: 'krn-ng-single-select-picker',
	templateUrl: './single-select-picker.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SingleSelectPickerComponent),
			multi: true
		}
	]
})
export class SingleSelectPickerComponent extends ReactiveFormsBase implements OnChanges {
	@Input() public alignment: string;
	@Input() public adaptivePosition: boolean;
	@Input() public attrs: SingleSelectPickerAttrs;
	@Input() public classes: string | string[];
	@Input() public containerClass: string | string[];
	@Input() public data: object[];
	@Input() public dataLabel: string;
	@Input() public label: string;
	@Input() public maxVisibleItems: number;
	@Input() public selected: object;
	@Input() public showAsNative: boolean;
	@Input() public toolTipLabel: string;

	@Output() public selectedChange = new EventEmitter<object>();

	public modalRole = MENUBAR;
	public pickerId = `picker${uniqueId()}`;
	public popoverCloseLabel: string;
	public selectedValue: object;

	constructor(private deviceConfigService: DeviceConfigService) {
		super();
		this.classes = EMPTY_VALUE;
	}

	public clickPopoverButton(status: boolean): void {
		if (status) {
			this.updateMaxHeight();
		}
	}

	public ngOnChanges(): void {
		this.showAsNative = isNil(this.showAsNative) ? this.deviceConfigService.isMobileDevice() : this.showAsNative;
		this.attrs = {...this.attrs, id: get(this.attrs, 'id') || this.pickerId};
		this.dataLabel = this.dataLabel || DEFAULT_DATA_LABEL;
		this.setSingleSelectPickerClass();
	}

	public onSelected(): void {
		this.selected = this.selectedValue;
		this.selectedChange.emit(this.selectedValue);
		this.onChange(this.selectedValue);
	}

	public onSelectedChange(selectedChange: object): void {
		this.selectedValue = getSelectedValue(this.data, selectedChange, this.selectedValue);
		this.onSelected();
	}

	public setSingleSelectPickerClass(): void {
		if (!this.showAsNative) {
			this.updateMaxHeight();
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public writeValue(outsideValue: any): void {
		this.selectedValue = outsideValue;
		this.onSelectedChange(outsideValue);
	}

	private updateMaxHeight(): void {
		const content = jQuery(POPOVER_CONTENT_SELECTOR);

		if (content.length) {
			content.css(
				'maxHeight',
				isUndefined(this.maxVisibleItems) || this.maxVisibleItems === NO_ITEMS
					? EMPTY_VALUE
					: `${ONE_ITEM_HEIGHT * this.maxVisibleItems + PADDING}rem`
			);
		}
	}
}
