import {Inject, Injectable, Optional} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ApiService} from '../../../../core/communication/api-service/api-service.interfaces';

import {OpenAMStackCoreService} from '../../../../core/session/authStack/openam-stack.core.service';
import {SESSION_EVENTS} from '../../../../core/session/session.constants';
import {DataService} from '../../../../services/communication/data.service';
import {AuthenticationRegistrationService} from '../../authentication/authentication.registration.service';
import {SessionActionService} from '../session.action';
import {ClientContainerContextService} from '../../../../services/clientContainer/client-container-context.service';

@Injectable({providedIn: 'root'})
export class OpenAMStackService extends OpenAMStackCoreService {
	private notifier$ = new Subject();

	constructor(
		protected logger: NGXLogger,
		protected authenticationRegistrationService: AuthenticationRegistrationService,
		@Inject(DataService) protected dataService: ApiService,
		protected sessionActionService: SessionActionService,
		@Optional() protected clientContainerContextService: ClientContainerContextService
	) {
		super(sessionActionService.dispatch, logger, authenticationRegistrationService, dataService, clientContainerContextService);
		this.sessionActionService
			.select('status')
			.pipe(takeUntil(this.notifier$))
			.subscribe((status: string) => {
				if (status === SESSION_EVENTS.RESET) {
					this.resetSession();
				}
			});
	}

	public destroy(): void {
		super.destroy();
		this.notifier$.next(undefined);
		this.notifier$.complete();
	}

	protected setSessionStatus(eventName): string {
		return eventName;
	}

}
