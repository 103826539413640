import {Injectable} from '@angular/core';
import {createLogger, INFO} from 'browser-bunyan';

import {NetworkStreamLogger} from '../core/platform/services/logger.network-stream';

@Injectable({
	providedIn: 'root'
})
export class LoggerNetworkStreamService extends NetworkStreamLogger {
	constructor() {
		super(createLogger, INFO, window, console);
	}
}
