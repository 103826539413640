import {ElementRef, FactoryProvider} from '@angular/core';

import {ExcludeSelector} from '../../../../core/platform/accessibility/selectors/exclude.selector';
import {SelectorService} from '../../../../core/platform/accessibility/selectors/selector.service';
import {VisableSelector} from '../../../../core/platform/accessibility/selectors/visable.selector';
import {TWO_LEVEL_UNDER_SELECTOR} from '../../../../core/platform/accessibility/utils.constants';

export const mainMenuSelectorProvider: FactoryProvider = {
	provide: SelectorService,
	deps: [ElementRef],
	useFactory: getSelectorService
};

export function getSelectorService(elementRef: ElementRef<HTMLElement>): SelectorService {
	const service = new SelectorService();

	service.loadSelector(new VisableSelector());
	service.loadSelector(new ExcludeSelector(elementRef.nativeElement, TWO_LEVEL_UNDER_SELECTOR));
	return service;
}
