export const DATA_CHUNK_SIZE = 5000;
export const KNOWN_PLACES_ENDPOINT = 'mobileapp/knownplaces';
export const TIMECARD_ENDPOINT = 'mobileapp/timecarddata';
export const KNOWN_PLACES_MULTI_ENDPOINT = 'mobileapp/knownplacesmulti';
export const MAPPING_USE_RADIUS = 'EMPLOYEE_VIEW_KNOWNPLACE_AREA';
export const NGSTORAGE_USER_INFO = 'UserInfo';
export const EMPLOYEE_OFFLINE_MOBILE_PUNCH = 'EMPLOYEE_OFFLINE_MOBILE_PUNCH';
export const REQUIRED_FACPS = [EMPLOYEE_OFFLINE_MOBILE_PUNCH, 'TS_CANCEL_MEAL_DEDUCTS', 'EMPLOYEE_LOCATION_RECORD_DATA', MAPPING_USE_RADIUS];

export const DATA_TYPES = {
	PUNCH_DATA: 'knownplaces',
	TIMECARD_DATA: 'users'
};

export enum MAPS {
	PUNCH_MAP = 'punch',
	TIMECARD_MAP = 'timecard'
}

export const TOTAL_USERS = 150;

export const EDIT_BY_EMPLOYEE = -1;

export enum VALIDATION {
	GPS = 'GPS',
	WIFI = 'WIFI'
}

export const GEOFENCE_METHOD_ID = {
	UNDEFINED: -1,
	GPS: 0,
	WIFI: 1
};

export enum GEOFENCE_METHOD {
	GPS = 'gps',
	WIFI = 'wifi'
}

export enum MapType {
	PUNCH = 'punch',
	TIMECARD = 'timecard'
}

export const CAPS = {
	STARTUP_API: 'startup-api',
	SIGNON_STATES: 'auth-and-logon-states',
	APP_REVIEW: 'app-review',
	WIFI: 'wifi',
	MAPPING_KNOWN_PLACES_MULTI_READ: 'known-places-multi',
	DEVICE_INFO_MOBILE_APP_NAME: 'device-info-mobileappname'
};
