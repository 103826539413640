export const DEFAULT_TIME_AM_MARKER = 'am';
export const DEFAULT_TIME_PM_MARKER = 'pm';
export const LOCALE_TIME_MARKER_EXCEPTIONS = ['cs','sv'];
export const NUMBER_WITHOUT_SEPARATORS_REGEXP = '^(([0-9])|([0-1][0-9])|(2[0-4])|([0-9][0-5][0-9])|([0-2][0-4][0-5][0-9])|([0-1][0-9][0-5][0-9]))';

export const escapeRegExp = (str): any => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const formatTimeStr = (timeStr, timeSeparator): any => {
	let timeSections;

	if (timeStr.indexOf('.') > -1) {
		if (timeSeparator === '.') {
			return timeStr;
		}
		timeSections = timeStr.split('.');
		return `${timeSections[0]}:${getMinutesFromFraction(timeSections[1])}`;
	} else if (timeStr.indexOf(':') > -1) {
		timeSections = timeStr.split(':');
		return `${timeSections[0]}:${timeSections[1]}`;
	} 
		const timeLength = timeStr.length;

		if (timeLength === 1 || timeLength === 2) {
			return `${timeStr}:00`;
		} else if (timeLength === 3) {
			return `${timeStr.substr(0, 1)}:${timeStr.substr(1)}`;
		} else if (timeLength === 4) {
			return `${timeStr.substr(0, 2)}:${timeStr.substr(2)}`;
		}
	
};

const getMinutesFromFraction = (fraction): any => {
	let minutes = 0;
	const frac = parseInt(fraction, 10);

	if (fraction.startsWith('0')) {
		minutes = frac;
	} else {
		minutes = frac < 10 ? frac * 10 : frac;
	}
	return Math.round((60 * minutes) / 100);
};
