import {Injectable} from '@angular/core';

import {LocalStorageService} from '@kronos/zed/src/lib/services/local-storage.service';
import {SessionStorageService} from '@kronos/zed/src/lib/services/session-storage.service';
import {WindowRefService} from '@kronos/zed/src/lib/services/window-ref.service';

import {setSessionStorageData} from '../core/utils/kiosk.utility';

@Injectable({providedIn: 'root'})
export class KioskBootstrapService {
	constructor(
		private windowRefService: WindowRefService,
		private localStorageService: LocalStorageService,
		private sessionStorageService: SessionStorageService
	) {
		if (windowRefService.nativeWindow.location.href.includes('kiosk')) {
			setSessionStorageData(this.sessionStorageService, this.localStorageService);
		}
	}
}
