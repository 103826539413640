import {get, isObject} from 'lodash';

import {MOBILE_OS, TOUCHPOINT} from './DeviceConfigServices.constants';

export function isRealMobileDevice(windowObj: Window): boolean {
	if (!isObject(windowObj) || !isObject(windowObj.navigator)) {
		return false;
	}

	const regExp = /iPad|iPhone|iPod|Android|webOS|BlackBerry|Windows Phone|IEMobile|Opera Mini/i;
	const regResult = regExp.test(windowObj.navigator.userAgent || windowObj.navigator.vendor || (windowObj as any).opera);
	const iPadOS = windowObj.navigator.platform === 'MacIntel' && windowObj.navigator.maxTouchPoints > 1;

	return regResult || iPadOS;
}

export const isWfdMobileApp = (windowObj: Window): boolean => {
	const userAgent = get(windowObj, 'navigator.userAgent', '');

	return userAgent.match(/(wfdMobileApp)/i) !== null;
};

export const isOneApp = (windowObj: Window): boolean => {
	const userAgent = get(windowObj, 'navigator.userAgent', '');

	return userAgent.match(/(UkgOneApp)/i) !== null;
};

export const getMobileOS = (windowObj: Window) : string => {
	const userAgent = windowObj.navigator.userAgent || windowObj.navigator.vendor || get(windowObj, 'opera');
	const isiPadOS = windowObj.navigator.platform === 'MacIntel' && windowObj.navigator.maxTouchPoints > TOUCHPOINT;

	if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i) || isiPadOS) {
		return MOBILE_OS.IOS;
	}
	if (userAgent.match(/Android/i)) {
		return MOBILE_OS.ANDROID;
	}

	return MOBILE_OS.UNKNOWN;
};
