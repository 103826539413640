import {isUndefined} from 'lodash';

import {LocalStorage} from './local-storage.interfaces';

import {IS_STORAGE_ENABLED, STORAGE_ENABLED} from './local-storage.constants';
import {getCopy} from './local-storage.utility';

export class LocalStorageCoreService implements LocalStorage {
	private storageEnabled: boolean;
	private localStorageCache = {};

	public isDOMStorageEnabled(): any {
		if (isUndefined(this.storageEnabled)) {
			this.setItem(IS_STORAGE_ENABLED, STORAGE_ENABLED);
			this.storageEnabled = this.getItem(IS_STORAGE_ENABLED) === STORAGE_ENABLED;
			this.clear(IS_STORAGE_ENABLED);
		}
		return this.storageEnabled;
	}

	public get(key: string): any {
		return this.isDOMStorageEnabled() ? getCopy(this.getItem(key)) : this.localStorageCache[key];
	}

	public set(key: string, value: any): void {
		if (this.isDOMStorageEnabled()) {
			this.setItem(key, value);
		} else {
			this.localStorageCache[key] = value;
		}
	}

	public clear(key: string): void {
		if (this.isDOMStorageEnabled()) {
			this.removeItem(key);
		} else {
			delete this.localStorageCache[key];
		}
	}

	// To be overwritten
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public getItem(key: string): any {}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public setItem(key: string, value: any): void {}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public removeItem(key: string): void {}
}
