import {isString} from 'lodash';

import {LOGIN_CONSTANTS, MESSAGES} from './authentication.constants';

const authenticationErrMsgMap = {
	[LOGIN_CONSTANTS.ECONNREFUSED_CODE]: MESSAGES.SYSTEM_ERROR,
	[LOGIN_CONSTANTS.UNAUTHORIZED_CODE]: MESSAGES.UNAUTHENTICATED_MESSAGE,
	[LOGIN_CONSTANTS.SYSTEM_UNAVAILABLE]: MESSAGES.SYSTEM_UNAVAILABLE,
	[LOGIN_CONSTANTS.LOGINLIMITREACHED]: MESSAGES.LOGINLIMITREACHED_MESSAGE,
	[LOGIN_CONSTANTS.SESSION_EXPIRED_CODE]: MESSAGES.SESSION_EXPIRED_MESSAGE
};

const headingErrMap = {
	[LOGIN_CONSTANTS.UNAUTHORIZED_CODE]: MESSAGES.UNAUTHORIZED,
	[LOGIN_CONSTANTS.SYSTEM_UNAVAILABLE]: MESSAGES.SYSTEM_ERROR,
	[LOGIN_CONSTANTS.LOGINLIMITREACHED]: MESSAGES.LOGINLIMITREACHED,
	[LOGIN_CONSTANTS.SESSION_EXPIRED_CODE]: MESSAGES.SESSION_EXPIRED
};

export const getAuthenticationErrorMessage = (error: Error): string => authenticationErrMsgMap[error.message] || error.message;

export const getErrorHeading = (error: Error | string): string => headingErrMap[isString(error) ? error : error.message] || MESSAGES.SYSTEM_ERROR;
