import { Injectable } from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import { Subject, takeUntil, interval, of, from, catchError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {DataService} from '@kronos/zed/src/lib/services/communication/data.service';
import {LocalStorageService} from '@kronos/zed/src/lib/services/local-storage.service';

import {extendAuth0} from '../core/utils/kiosk.utility';

@Injectable({
	providedIn: 'root'
})
export class KioskAuth0TokenRefreshService {

	private static readonly CHECK_INTERVAL: number = 60000; // Check every 30 secs
	private static readonly FIVE_MINUTES_IN_MILLISECONDS: number = 300000; // 5 mins is 300000

	private readonly AUTH0_TOKEN_EXPIRY: string = 'kioskAuth0TokenExpiry';
	private readonly NO_REFRESH_NEEDED = 'No Refresh Needed';
	private readonly TOKEN_REFRESH_ERROR = 'Token Refresh Error';
	private readonly REFRESH_IN_PROGRESS = 'Refresh In Progress';
	private isTokenRefreshInProgress = false;
	private tokenRefreshCompleted: Subject<void> = new Subject<void>();
	private tokenRefreshSubject: Subject<void> = new Subject<void>();


	constructor(private apiService: DataService, private localStorageService: LocalStorageService, private logger: NGXLogger) {}

	public startTokenRefreshMonitoring(): void {
		this.logger.info('Start token refresh monitoring');
		interval(KioskAuth0TokenRefreshService.CHECK_INTERVAL).pipe(
			switchMap(() => {
				if (this.shouldTriggerTokenRefresh() && !this.isTokenRefreshInProgress) {
					this.logger.info('Token refresh in progress');
					this.isTokenRefreshInProgress = true;
					return from(extendAuth0(this.apiService)).pipe(
						catchError(error => {
							this.logger.error('Error refreshing token:', error);
							return of(this.TOKEN_REFRESH_ERROR); // Keep the stream alive on error
						})
					);
				}
				if (this.isTokenRefreshInProgress) {
					return of(this.REFRESH_IN_PROGRESS);
				}
				return of(this.NO_REFRESH_NEEDED);
			}),
			takeUntil(this.tokenRefreshSubject),
		).subscribe({
			next: result => {
				if (result === this.NO_REFRESH_NEEDED) {
					this.isTokenRefreshInProgress = false;
				} else if (result === this.TOKEN_REFRESH_ERROR) {
					this.isTokenRefreshInProgress = false;
					this.stopTokenRefreshMonitoring();
				}
				if (result === this.TOKEN_REFRESH_ERROR) {
					this.isTokenRefreshInProgress = false;
					this.stopTokenRefreshMonitoring();
				} else if (result === this.NO_REFRESH_NEEDED) {
					this.isTokenRefreshInProgress = false;
				} else if (result !== this.REFRESH_IN_PROGRESS) {
					if (!this.isSuccessfulResponse(result)) {
						this.isTokenRefreshInProgress = false;
						this.stopTokenRefreshMonitoring();
					} else {
						this.logger.info('Token refresh finished');
						const expiryTime = this.extractTokenExpiryTimeFromResult(result);

						this.updateSessionTokenExpiry(expiryTime);

						this.isTokenRefreshInProgress = false;
						this.tokenRefreshCompleted.next(); // Emit signal to http interceptor
					}
				}
			},
			error: error => {
				this.logger.error('Error in subscription:', error);
				this.isTokenRefreshInProgress = false;
				this.stopTokenRefreshMonitoring();
			},
		});
	}

	public stopTokenRefreshMonitoring(): void {
		this.logger.info('Stop token refresh monitoring');
		this.tokenRefreshSubject.next();
	}

	public getTokenRefreshInProgress(): boolean {
		return this.isTokenRefreshInProgress;
	}

	public getTokenRefreshSubject(): Subject<void> {
		return this.tokenRefreshSubject;
	}

	public getTokenRefreshCompleted(): Subject<void> {
		return this.tokenRefreshCompleted;
	}

	private getTokenExpiryTime(): number {
		return this.localStorageService.get(this.AUTH0_TOKEN_EXPIRY);
	}

	private isSuccessfulResponse(response): boolean {
		return (response.status && this.isSuccessfulStatus(response.status)) ||
			(response.statusCodeValue && this.isSuccessfulStatus(response.statusCodeValue)) ||
			(response.statusCode && this.isSuccessfulStatus(response.statusCode));
	}

	private isSuccessfulStatus(status): boolean {
		return status === 200 || status === 202;
	}

	private shouldTriggerTokenRefresh(): boolean {
		const tokenExpiryTime = this.getTokenExpiryTime();
		const currentTime = new Date().getTime();

		return tokenExpiryTime && currentTime &&
			(tokenExpiryTime - currentTime) <= KioskAuth0TokenRefreshService.FIVE_MINUTES_IN_MILLISECONDS;
	}

	private updateSessionTokenExpiry(expiryTime: number): void {
		this.localStorageService.set(this.AUTH0_TOKEN_EXPIRY, expiryTime.toString());
	}

	private extractTokenExpiryTimeFromResult(result: any): number {
		if (result && result.body) {
			return Number(result.body.kioskAuth0TokenExpiry);
		}
	}
}
