import {CommonConstants} from '../../constants/CommonConstants';
import {OneAppDataCoreService} from '../../personalization/one-app-data.core.service';
import {ngStorage} from './dim-local-storage.service';

export class OneAppDataService extends OneAppDataCoreService {
	constructor(protected window: Window) {
		'ngInject';
		super(window);
		this.localStorage = this.window.localStorage;
		this.syncData();
	}

	public getItem(key: string): any {
		return JSON.parse(this.localStorage.getItem(ngStorage + key));
	}

	public setItem(key: string, value: any): void {
		this.localStorage.setItem(ngStorage + key, JSON.stringify(value));
		if (key.includes(CommonConstants.PERSONALIZATION_PREFIX)) {
			this.setGlobalData({key, value});
			this.updateStorageKeys();
		}
	}

	public removeItem(key: string): void {
		this.localStorage.removeItem(ngStorage + key);
		if (key.includes(CommonConstants.PERSONALIZATION_PREFIX)) { 
			this.deleteGlobalData(key);
			this.updateStorageKeys();
		}
	}
}
