import {ClientContext} from '../../clientContainer/client-container.interface';

import {BrowserOfflineButtonCoreService} from './browser-offline-button-core.service';
import {EmbeddedSessionOfflineButtonCoreService} from './embedded-session-offline-button-core.service';
import {KioskOfflineButtonCoreService} from './kiosk-offline-button-core.service';
import {MobileOfflineButtonCoreService} from './mobile-offline-button-core.service';
import {OneAppSessionOfflineButtonCoreService} from './one-app-session-offline-button-core.service';
import {ProEmbeddedOfflineButtonCoreService} from './pro-embedded-offline-button-core.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const OfflineButtonServiceMap: {[key in ClientContext]: object} = {
    [ClientContext.BROWSER]: BrowserOfflineButtonCoreService,
    [ClientContext.WFD_MOBILE]: MobileOfflineButtonCoreService,
    [ClientContext.EMBEDDED_SESSION]: EmbeddedSessionOfflineButtonCoreService,
    [ClientContext.KIOSK]: KioskOfflineButtonCoreService,
    [ClientContext.ONE_APP]: OneAppSessionOfflineButtonCoreService,
    [ClientContext.PRO_EMBEDDED]: ProEmbeddedOfflineButtonCoreService
};