import {concat, find, get, intersection, isEmpty, isEqual, keys, reject, sortBy} from 'lodash';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';
import {UserInfo} from '../framework/services/user-info.interface';
import {ClientContainer} from '../platform/clientContainer/client-container.interface';

import {UserInfoService} from '../framework/services/user-info.service';
import {CommonConstants} from '../platform/constants/CommonConstants';
import {PreferencesCoreService} from '../platform/preferences/preferences.service';
import {SessionStorage} from '../platform/caching/sessionStorage.service';
import {PropertyFilterConfig} from '../property/property.filter';
import {DelegateAuthData, FlatDelegatedManagerRole, ManagerRole, ManagerRoleData, ManagerRoleDelegation} from './models/manager-role.models';
import {ProfileMenuItemConfig} from './profile-menu';

export class ManagerRoleService {
	private switchRoleMap = {
		switchProDefaultRole: this.performRoleSwitch.bind(this),
		switchMyRole: this.performRoleSwitch.bind(this),
		switchDelegatedRole: this.performDelegatedRoleSwitch.bind(this)
	};
	private delegationInProgress: boolean;

	constructor(
		private logger: any,
		private clientContainerService: ClientContainer,
		private sessionStorageService: SessionStorage,
		protected localStorageService,
		protected preferencesService: PreferencesCoreService,
		private userDataService: UserInfoService,
		private propertyFilter: PropertyFilterConfig,
		private apiService: ApiService
	) {
		'ngInject';
		this.delegationInProgress = false;
	}

	public getButtonConfig(callback: () => void): ProfileMenuItemConfig {
		return {
			id: 'managerRoleSwitchButton',
			text: this.propertyFilter('html.navbar.delegation.roleSwitch'),
			classes: 'btn managerRoleSwitch info-menu',
			postpendTransclude: false,
			callback
		};
	}

	public getRoleString(role: ManagerRole): string {
		return role.name;
	}

	public getDelegatedRoleString(role: FlatDelegatedManagerRole): string {
		return role.name; // stub. Correct implementation will be added in future story
	}

	public getDelegateString(person): string {
		const name = person.delegator;
		const start = person.startDate ? person.startDate : '';
		const end = person.endDate ? person.endDate : '';

		return person.id === '-1337' ? name : `${name} [${start} - ${end}]`;
	}

	public performRoleSwitch(role: ManagerRole): Promise<boolean> {
		const roleData = this.getManagerRoleData(role.assignmentId);

		return this.performRoleSwitchByRoleData(roleData);
	}

	public performDelegatedRoleSwitch(person): Promise<boolean> {
		if (!this.delegationInProgress) {
			this.delegationInProgress = true;
			return this.performDelegateAction(person).then(result => {
				this.delegationInProgress = false;
				return result;
			});
		}
		return Promise.resolve(false);
	}

	public hasSwitchRoleInQueryParams(queryParams): boolean {
		return !isEmpty(intersection(keys(queryParams), keys(this.switchRoleMap)));
	}

	public switchRoleViaQueryParams(queryParams): Promise<boolean> {
		let role;
		const switchRoleKey = intersection(keys(queryParams), keys(this.switchRoleMap))[0];

		if (switchRoleKey) {
			try {
				role = JSON.parse(atob(queryParams[switchRoleKey]));
			} catch {
				this.logger.error(`The ${switchRoleKey}: ${queryParams[switchRoleKey]} is not a valid base64-encoded JSON.`);
				return Promise.resolve(false);
			}

			return this.switchRoleMap[switchRoleKey](role)
				.then(success => {
					this.clientContainerService.onRoleSwitched({
						type: switchRoleKey
					});
					return success;
				})
				.catch(error => {
					this.logger.error('Failed to switch role via query params.', error);
					return false;
				});
		}

		return Promise.resolve(false);
	}

	/**
	 * Flat map manager delegations since one manager delegation can contain several delegated roles
	 * @param delegations manager delegations
	 */
	public flatMap(delegations: ManagerRoleDelegation[]): FlatDelegatedManagerRole[] {
		return []; // stub. Correct implementation will be added in future story
	}

	/**
	 * Sort roles in order:
	 * - default role is always the first role
	 * - rest of roles are sorted ascending by role name
	 *
	 * @param roles sorted roles
	 */
	public sort(roles: ManagerRole[]): ManagerRole[] {
		const defaultRole = find(roles, {default: true});
		const sortedRoles = sortBy(reject(roles, {assignmentId: defaultRole.assignmentId}), 'name');

		return concat(defaultRole, sortedRoles);
	}

	/**
	 * Filter roles by purpose. Roles with purpose="Delegation" should not present in the list.
	 * @param roles list of allowed roles
	 */
	public filterAllowed(roles: ManagerRole[]): ManagerRole[] {
		return reject(roles, {purpose: 'Delegation'});
	}

	public getDelegateAuthData(): DelegateAuthData {
		return {
			isKDlgt: true,
			wsid: 5,
			ORGOID: 1,
			AssociateID: '',
			DelegateID: '',
			DelegatorAOID: '',
			DelegatorCOID: 1
		};
	}

	private getManagerRoleData(assignmentId: number): ManagerRoleData {
		return {assignmentId};
	}

	private performRoleSwitchByRoleData(roleData: ManagerRoleData): Promise<boolean> {
		return this.userDataService.getLoggedOnUserData().then((userInfo: UserInfo) => {
			if (isEqual(userInfo.delegate.activeRole.assignmentId, roleData.assignmentId)) {
				return false;
			}

			return this.switchRole(roleData).then(() => {
				this.clearSessionBeforeSwitchRole(userInfo);
				return this.preferencesService.fetchPreferences().then(() => true);
			});
		});
	}

	private performDelegateAction(person): Promise<any> {
		return this.userDataService.getLoggedOnUserData().then(userInfo => {
			const authData = this.getDelegateAuthData();
			const delegateActiveRole = get(userInfo, 'delegate.delegateActiveRole');

			authData.DelegatorAOID = person.delegatorId;
			authData.AssociateID = userInfo.personId;
			authData.DelegateID = person.id;

			if (delegateActiveRole.id === person.id) {
				return false;
			}
			return this.doDelegate(authData).then(() => {
				this.clearSessionBeforeSwitchRole(userInfo);
				return this.preferencesService.fetchPreferences().then(() => true);
			});
		});
	}

	private clearSessionBeforeSwitchRole(userInfo: UserInfo): void {
		this.sessionStorageService.clear('preferences');
		this.sessionStorageService
			.getKeys()
			.filter(key => key.startsWith('schedule-context-'))
			.forEach(key => this.sessionStorageService.clear(key));
		this.sessionStorageService.clear([CommonConstants.HTML_INFRASTRUCTURE_SESSIONSTORAGE.HTML_NOTIFICATION_COUNT_CACHE]);
		this.sessionStorageService.clear(['EVENT_ACTIONS_DATA']);
		this.localStorageService.removeItem(`personalization:timekeeping:${userInfo.tenantId}:${userInfo.personId}:timecard:tcHyperFind`);
		this.userDataService.clearLoggedOnUserData();
	}

	private switchRole(data: ManagerRoleData): Promise<any> {
		const uniqueParam = new Date().getTime();

		return this.apiService.get(
			`/sso/portal?roleId=${data.assignmentId}&isMMR=${Boolean(data.assignmentId)}&unique_param=${uniqueParam}`,
			null,
			{responseType: 'text'},
			Transport.REST
		);
	}

	private doDelegate(data: DelegateAuthData): Promise<any> {
		const uniqueParam = new Date().getTime();

		return this.apiService.get(
			`/sso/portal?
			isKDlgt=${data.isKDlgt}
			&wsid=${data.wsid}
			&AssociateID=${data.AssociateID}
			&ORGOID=${data.ORGOID}
			&DelegatorAOID=${data.DelegatorAOID}
			&DelegatorCOID=${data.DelegatorCOID}
			&unique_param=${uniqueParam}
			&DelegateID=${data.DelegateID}`,
			null,
			{responseType: 'text'},
			Transport.REST
		);
	}
}
