import {get, isEmpty, isFunction, last, toArray} from 'lodash';

import {BaseSlideOutContainer} from './base-slideout-container';

export class SlideOutManagerCoreService {
	private openedSlideOuts: BaseSlideOutContainer[] = [];
	private slideId: string;

	public hasOpenedSlideOuts(): boolean {
		return !isEmpty(this.openedSlideOuts);
	}

	public updateOpenedSlideOuts(baseSlideOutContainer: BaseSlideOutContainer): void {
		this.openedSlideOuts.push(baseSlideOutContainer);
	}

	public closeLastOpenedSlideOut(): void {
		const lastOpenedSlideOut: BaseSlideOutContainer = this.getLastOpenedSlideout();

		if (isFunction(get(lastOpenedSlideOut, 'close'))) {
			lastOpenedSlideOut.close();
		}
	}

	public popLastOpenedSlideOut(): void {
		this.openedSlideOuts.pop();
	}

	public getLastOpenedSlideout(): BaseSlideOutContainer {
		return last(this.openedSlideOuts);
	}

	public hasUnsavedData(): Promise<boolean> {
		const validationPromises = this.openedSlideOuts
			.filter(slideout => isFunction(slideout.validationCallback))
			.map(slideout => slideout.validationCallback());

		return Promise.all(validationPromises)
			.then(results => results.some(result => !result))
			.catch(() => true);
	}

	public checkSameSlideAndSetNewOne(sliderJQElement: JQuery): boolean {
		const currentSlideId = toArray(sliderJQElement.find('.slideout__content').children())
			.map(({className, tagName}) => `${tagName}.${className}`)
			.join();
		const isSameSlide = currentSlideId === this.slideId;

		this.slideId = currentSlideId;

		return isSameSlide;
	}
}
