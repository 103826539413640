export const STORE_PATH = 'common.tile';

export const BUSINESS_PROCESS_TYPE = 'businessProcessCard';
export const CHART_TYPE = 'CHART';
export const MULTISERIESCHART_TYPE = 'MULTISERIESCHART';
export const NOTIFICATIONS_TYPE = 'notifications';
export const SUBTYPE_PATH = 'card[0].subType';
export const TYPE_PATH = 'card[0].type';
export const NAME_PATH = 'attributes.label';
export const TYPE_CONFIG = 'card[0].configuration';
export const TYPE_CONFIG_PATH = 'card[0].configuration.cardType';
export const TYPE_DATA_PATH = 'card[0].data.type';
export const MVCARD_TYPE_DEFAULT = 'mvCardDefault';
export const MVCARD_TYPE_ACCRUAL = 'mvCardAccrual';
export const HCA_CARD_TYPE = 'healthcareProductivityCard';
export const MANAGEEXCEPTIONSCARD_TYPE = 'manageExceptionsCard';

export const TILE_LIST_MAPPING = [BUSINESS_PROCESS_TYPE];

export const DEF_ACTION_MENU_ALIGNMENT = 'bottom right';
export const DEF_TILE_IMAGE = 'tile-custom';

export const TILE_THUMBNAIL_DIR = '/images/illustrations-thumbnail/';
export const TILE_THUMBNAIL_EXT = '.png';

export const TILE_IMAGES = {
	activityFormsCard: 'tile-activity',
	bar2d: 'tile-accruals',
	businessProcessCard: 'tile-mybusinessprocesses',
	column2d: 'tile-overtimeanalysis',
	emptimecard: 'tile-managetimecards',
	line: 'tile-charts',
	managerAttendanceCard: 'tile-manageattendance',
	manageLeaveCard: 'tile-manageabsence',
	manageScheduleCard: 'tile-manageschedule',
	mscolumn2d: 'tile-overtimeanalysis',
	msline: 'tile-charts',
	myRequestsCard: 'tile-managemyschedule',
	'My-Schedule': 'tile-myschedule',
	notifications: 'tile-mynotifications',
	'one-click-time-off': 'tile-mytimeoff',
	pie2d: 'tile-absenceanalysis',
	timestamp: 'tile-punch',
	team: 'tile-hcm',
	talk: 'tile-communications',
	swp: 'tile-swp'
};

export const TILE_PROPERTIES = [
	{
		name: 'container_web-common_tile_strings.properties',
		path: 'components/zed-core/properties/tile/'
	},
	{
		name: 'container_web-common_chart_strings.properties',
		path: 'components/zed-core/properties/cardLibrary/cards/chart/'
	}
];

export const FOCUS_TIMEOUT = 500;
