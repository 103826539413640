import {UserInfoService} from '../core/framework/services/user-info.service';
import {DataService} from '../services/communication/data.service';
import {SessionStorageService} from '../services/session-storage.service';

export function getUserInfoService(dataService, sessionStorageService): any {
	return new UserInfoService(dataService, sessionStorageService);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const UserInfoServiceProvider = {
	provide: UserInfoService,
	deps: [DataService, SessionStorageService],
	useFactory: getUserInfoService
};
