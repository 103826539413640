import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {PropertyFilterPipe} from '../../../../providers/property/property.filter.pipe';
import {PropertyModule} from '../../../../providers/property/property.module';
import {ButtonModule} from '../../../button/button.module';
import {SlideoutContentComponent} from './slideout-content.component';

@NgModule({
	imports: [CommonModule, ButtonModule, PropertyModule],
	providers: [PropertyFilterPipe],
	declarations: [SlideoutContentComponent],
	exports: [SlideoutContentComponent]
})
export class SlideoutContentModule {}
