import {Pipe, PipeTransform} from '@angular/core';
import {forEach, get} from 'lodash';

import {ActionItemBaseComponent} from './action-item-base.component';

@Pipe({name: 'krnNgActionItemNoText'})
export class ActionItemNoTextPipe implements PipeTransform {
	public transform(items: ActionItemBaseComponent[] = []): ActionItemBaseComponent[] {
		forEach(items, item => {
			ActionItemBaseComponent.modifyOptionsOnCreate(get(item, 'actionItemName'), {text: ''});
		});
		return items;
	}
}
