import {Inject, Injectable, Optional} from '@angular/core';
import {get} from 'lodash';

import {APP_CONFIG_ENVIRONMENT, Environment} from '../environment.token';
import {WindowRefService} from '../window-ref.service';

@Injectable({
	providedIn: 'root'
})
export class DeprecationMessageService {
	constructor(
		private windowRefService: WindowRefService,
		@Optional() @Inject(APP_CONFIG_ENVIRONMENT) private environment: Environment
	) {}

	public log = (componentName: string): void => {
		if (get(this.environment, 'production') === false) {
			this.windowRefService.nativeWindow.console.warn(`This ${componentName} component is deprecated and will be removed in the future in favor of components found within the Ignite design system. For more details, please view the README of Zed`);
		}
	};
}
