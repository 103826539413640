import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {PropertyFilterPipe} from '../../../providers/property/property.filter.pipe';
import {PropertyModule} from '../../../providers/property/property.module';
import {AttributesModule} from '../../../utils/attributes/attributes.module';
import {LoaderModule} from '../../loader/loader.module';
import {IframeContainerComponent} from './iframe-container.component';

@NgModule({
	declarations: [IframeContainerComponent],
	exports: [AttributesModule, LoaderModule, PropertyModule, IframeContainerComponent],
	imports: [AttributesModule, CommonModule, LoaderModule, PropertyModule],
	providers: [PropertyFilterPipe]
})
export class IframeContainerModule {}
