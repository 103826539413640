import {Injectable} from '@angular/core';
import {find, flatMap, get} from 'lodash';
import {BsModalService} from 'ngx-bootstrap/modal';

import {Configuration, EventData} from '../../core/iframe-framework/iframe-framework.interface';

import {ACTION_TYPES} from '../../core/generic/slideOutContainer/slideout.constants';
import {NAVIGATION_ACTION_TYPE} from '../../core/iframe-framework/iframe-framework.constant';
import {openNewTab} from '../../core/iframe-framework/iframe-message-handler.utility';
import {IframeNavigationCoreService} from '../../core/iframe-framework/iframe-navigation.core.service';
import {IframeContainerComponent} from '../../ui/framework/iframe-container/iframe-container.component';
import {SlideOutHelperService} from '../slide-out-helper.service';
import {WindowRefService} from '../window-ref.service';

@Injectable({providedIn: 'root'})
export class IFrameNavigation extends IframeNavigationCoreService {
	private modalInstanceMap = {};
	constructor(private windowRef: WindowRefService, private modalService: BsModalService, private slideOutHelperService: SlideOutHelperService) {
		super(windowRef.nativeWindow);
	}

	public getReadOnlyPromise = (data: any): void => {
		const configuration = data.configuration;
		const menuId = configuration.id || configuration.menuId;
		const menuData = get(data, 'configuration.menu');
		const serviceParam = configuration.serviceName ? `&serviceName=${configuration.serviceName}` : '';
		const href = `/portal#/?path=${encodeURIComponent(configuration.url)}${serviceParam}`;
		const selectedmenu = find(
			flatMap(menuData, item => (item.menu ? item.menu : item)),
			{label: menuId}
		);
		const pageId = get(selectedmenu, 'id', '');

		this.setBrowserUrl(data, `${href}&pageId=${pageId}`);
	};

	public openPopup = (configuration: Configuration): void => {
		const modalInstance = this.modalService.show(IframeContainerComponent, {
			animated: false,
			backdrop: 'static',
			class: `krn-${configuration.size}`,
			ignoreBackdropClick: true,
			initialState: {
				config: configuration || {}
			}
		});

		this.modalInstanceMap[configuration.serviceName] = modalInstance;
	};

	public closePopup = (configuration: Configuration): void => {
		if (this.modalInstanceMap[configuration.serviceName] && this.modalInstanceMap[configuration.serviceName].hide) {
			this.modalInstanceMap[configuration.serviceName].hide();
			this.modalInstanceMap[configuration.serviceName] = undefined;
		}
	};

	public openSlider = (configuration: Configuration): void => {
		this.slideOutHelperService.openSlider(
			`customIframeSlideout/iframe-container/${get(configuration, 'serviceName', 'default')}/${ACTION_TYPES.OPEN_WITH_ROUTER}`,
			{returnFocusElement: get(configuration, 'btnId')},
			configuration || {}
		);
	};

	public handleNavigation = (data: EventData): void => {
		switch (data.type.toUpperCase()) {
			case NAVIGATION_ACTION_TYPE.POPUP:
				this.openPopup(data.configuration);
				break;
			case NAVIGATION_ACTION_TYPE.CLOSE_POPUP:
				this.closePopup(data.configuration);
				break;
			case NAVIGATION_ACTION_TYPE.SLIDER:
				this.openSlider(data.configuration);
				break;
			case NAVIGATION_ACTION_TYPE.CLOSE_SLIDER:
				this.slideOutHelperService.closeSlider(get(data, 'configuration.serviceName'));
				break;
			case NAVIGATION_ACTION_TYPE.NEWTAB:
				openNewTab(this.windowRef.nativeWindow, data.configuration);
				break;
			case NAVIGATION_ACTION_TYPE.NAVIGATE:
			case NAVIGATION_ACTION_TYPE.PORTAL:
			case NAVIGATION_ACTION_TYPE.UPDATE:
				this.navigate(data);
				break;
			default:
			// No action
		}
	};
}
