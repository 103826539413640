import {createActions} from 'redux-actions';

import {PropertyValues} from './property.interface';

export const actionsHelper = (): Partial<any> => ({
	LOAD_FILE: (fileName: string, properties: PropertyValues) => ({
		fileName,
		properties
	}),
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	CLEAR_PROPERTY_FILES: () => {}
});

export const {loadFile, clearPropertyFiles} = createActions(actionsHelper());
