import {NgModule} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {TooltipConfigProvider} from '../../providers/tooltip-config.provider';
import {AttributesDirective} from './attributes.directive';

@NgModule({
	declarations: [AttributesDirective],
	exports: [AttributesDirective],
	imports: [TooltipModule.forRoot()],
	providers: [TooltipConfigProvider]
})
export class AttributesModule {}
