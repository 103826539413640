import {concat, get, has, isEmpty, isString, isUndefined, split, uniqueId} from 'lodash';
import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild} from '@angular/core';

import {PropertiesApi, SingleSelectPickerAttrs} from '../../../core/generic/single-select-picker/single-select-picker.interfaces';
import {PopoverButtonAttrs} from '../../../core/popover-button/popover-button.interfaces';
import {PropertyState} from '../../../core/property/property.interface';

import {
	getBaseAttrs,
	getContainerClasses,
	getPickerClasses,
	getPopoverAttrs,
	getPopoverClasses,
	getTooltipValue
} from '../../../core/generic/single-select-picker/single-select-picker.utility';
import {
	DEFAULT_DATA_LABEL,
	PROPERTIES,
	SINGLE_SELECT_PICKER_PROPERTIES
} from '../../../core/generic/single-select-picker/single-select-picker.constants';
import {PropertyFactory} from '../../../core/property/property.factory';
import {PropertyFilterPipe} from '../../../providers/property/property.filter.pipe';
import {PopoverButtonComponent} from '../../popover-button/popover-button.component';

const FOCUS_DELAY = 100;

@Component({
	selector: 'krn-ng-single-select-popover-picker',
	templateUrl: './single-select-popover-picker.component.html',
	styleUrls: ['./single-select-popover-picker.component.less'],
	providers: [PropertyFilterPipe]
})
export class SingleSelectPopoverPickerComponent implements OnChanges {
	@Input() public alignment: string;
	@Input() public attrs: SingleSelectPickerAttrs;
	@Input() public autoResize: boolean;
	@Input() public adaptivePosition = false;
	@Input() public classes: string | string[];
	@Input() public containerClass: string | string[];
	@Input() public data: object[];
	@Input() public dataLabel: string;
	@Input() public label: string;
	@Input() public modalRole: string;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() public outerPopoverBodyTemplate: TemplateRef<any>;

	@Input() public renderType: string;
	@Input() public selected: object;
	@Input() public toolTipLabel: string;
	@Input() public tooltipContainerClass: string | string[];

	@Output() public clickPopoverButton = new EventEmitter<boolean>();
	@Output() public selectedChange = new EventEmitter<object>();

	@ViewChild(PopoverButtonComponent) public popoverButton: PopoverButtonComponent;
	@ViewChild('popoverBody') public popoverBody: ElementRef;

	public baseAttrs: SingleSelectPickerAttrs;
	public dataList: object[];
	public layout: string[];
	public isOpened: boolean;
	public pickerId = `picker${uniqueId()}`;
	public popoverAttrs: PopoverButtonAttrs;
	public popoverClasses: string[];
	public popoverContainerId = `container_${this.pickerId}`;
	public getItemClasses = getPickerClasses;

	private properties: Promise<PropertyState>;
	private allPropertiesApi: PropertiesApi = {
		tooltipProp: (label: string, value: string): string => this.propertyFilterPipe.transform(PROPERTIES.TOOLTIP, [label, value])
	};
	private popoverFocusId: string;

	constructor(private propertyFactory: PropertyFactory, private propertyFilterPipe: PropertyFilterPipe) {
		this.properties = this.loadSingleSelectPickerStrings();
		this.autoResize = true;
	}

	public async ngOnChanges(): Promise<void> {
		this.attrs = {...this.attrs, id: get(this.attrs, 'id') || this.pickerId};
		this.dataLabel = this.dataLabel || DEFAULT_DATA_LABEL;
		this.dataList = this.data || (isEmpty(this.selected) ? [] : [this.selected]);
		this.containerClass = getContainerClasses(this.containerClass);
		this.setSingleSelectPickerClass();

		this.baseAttrs = {...this.attrs};
		this.popoverAttrs = {id: this.attrs.id};
		await this.properties;
		this.baseAttrs = getBaseAttrs(this.allPropertiesApi, this.attrs, this.label, getTooltipValue(this.selected, this.toolTipLabel, this.dataLabel));

		this.popoverAttrs = getPopoverAttrs(this.baseAttrs, isUndefined(this.outerPopoverBodyTemplate));
	}

	public onArrowDown(): void {
		if (!this.isOpened) {
			this.popoverButton.showPopover();
		}
	}

	public onHidden(): void {
		this.isOpened = false;
		this.clickPopoverButton.emit(this.isOpened);
	}

	public onShown(): void {
		this.isOpened = true;
		this.clickPopoverButton.emit(this.isOpened);
	}

	public onDefaultPopoverSelectedChange($event, selectedChange: object): void {
		this.selectedChange.emit(selectedChange);
	}

	public onSelectedChange(selectedChange: object): void {
		this.selectedChange.emit(selectedChange);
	}

	public setSingleSelectPickerClass(): void {
		const popoverClasses = split(getPopoverClasses(this.alignment), ' ');
		const classes = isEmpty(this.classes) ? [] : isString(this.classes) ? split(this.classes, ' ') : this.classes;

		this.popoverClasses = concat(popoverClasses, classes);
	}

	public onPopoverFocusChange(focusChange: object): void {
		this.popoverFocusId = get(focusChange, 'id');
	}

	public isSelectedObject = (item: object): boolean => has(this.selected, 'id') ? get(this.selected, 'id') === get(item, 'id') : this.selected === item;

	public onFocus(): void {
		setTimeout(() => {
			if (get(this.popoverBody, 'nativeElement') === document.activeElement) {
				document.getElementById(this.popoverFocusId).focus({preventScroll: true});
			}
		}, FOCUS_DELAY);
	}

	private loadSingleSelectPickerStrings = (): Promise<PropertyState> => this.propertyFactory.loadProperties(SINGLE_SELECT_PICKER_PROPERTIES);
}
