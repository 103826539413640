import {Injectable} from '@angular/core';

import {IofflineButtonService} from '../core/platform/services/offlineButton/offline-button.interface';

import {getServiceByClientContext} from '../core/platform/clientContainer/client-container.utility';
import {BrowserOfflineButtonCoreService} from '../core/platform/services/offlineButton/browser-offline-button-core.service';
import {OfflineButtonServiceMap} from '../core/platform/services/offlineButton/offline-button.constant';
import {ClientContainerContextService} from './clientContainer/client-container-context.service';
import {DeviceConfigService} from './device-config.service';
import {WindowRefService} from './window-ref.service';

@Injectable({
	providedIn: 'root',
	deps: [DeviceConfigService, ClientContainerContextService, WindowRefService],
	useFactory: getOfflineButtonService
})
export class OfflineButtonService extends BrowserOfflineButtonCoreService {}

export function getOfflineButtonService(
	deviceConfigService: DeviceConfigService,
	clientContainerContextService: ClientContainerContextService, 
	windowRefService: WindowRefService
): IofflineButtonService {
	return getServiceByClientContext<IofflineButtonService>(
		clientContainerContextService.context(), 
		OfflineButtonServiceMap, 
		[deviceConfigService, windowRefService.nativeWindow]
	);
}