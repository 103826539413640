import {A11yAttrs, FocusAttrs, PopupElementAttrs} from '../platform/attributes.interfaces';

export interface PopoverButtonAttrs extends A11yAttrs, FocusAttrs, PopupElementAttrs {}

export enum PopoverType {
	DIALOG = 'dialog',
	GRID = 'grid',
	LIST_BOX = 'listbox',
	MENU = 'menu',
	TREE = 'tree'
}

export enum PopoverCloseType {
	UNKNOWN,
	CONTAINER,
	POPOVER
}

export enum popoverRenderType {
    ALWAYS_FIT_CONTAINER = 'alwaysFitContainer',
    ALWAYS_FULL_DISPLAY = 'alwaysFullDisplay'
}
