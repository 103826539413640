import {get} from 'lodash';

import {CordovaPluginWindow} from '../mobile/cordova-plugin-window.interface';
import {ErrorResult, LocalAuth, LocalAuthHandler} from '../mobile/local-auth.interface';

import {
	ANY_AUTH,
	BIO_AUTH,
	LOCAL_AUTH_INT_ERR_AUTH_REQ,
	LOCAL_AUTH_INT_ERR_CANNOT_USE,
	LOCAL_AUTH_INT_ERR_FLAG_REQ,
	LOCAL_AUTH_INT_ERR_INVALID_TYPE
} from '../mobile/local-auth.constants';
import {runAnyAuth, runBioAuth} from './local-auth-utilities';
import {PropertyFactory} from '../../property/property.factory';
import {PropertyFilterConfig} from '../../property/property.filter';
import {LOCAL_AUTH_MSG_LIST} from '../mobile/local-auth.constants';

export class OneAppLocalAuthCoreService implements LocalAuthHandler {
	constructor(protected $window: CordovaPluginWindow, protected propertyFactory: PropertyFactory, protected propertyFilter: PropertyFilterConfig) {
		this.propertyFactory.loadProperties([
			{
				name: 'container_web-common_geolocation_strings.properties',
				path: '/components/zed-core/properties/platform/'
			}
		]);
	}

	public runLocalAuth(localAuthFlag: number, localAuthType: string): Promise<LocalAuth> {
		return new Promise<LocalAuth>((resolve, reject) => {
			if (!localAuthFlag) {
				reject(LOCAL_AUTH_INT_ERR_FLAG_REQ);
			}

			if (!localAuthType) {
				reject(LOCAL_AUTH_INT_ERR_AUTH_REQ);
			}

			if (!get(this.$window, 'kronosLocalAuth.anyAuth')) {
				reject(LOCAL_AUTH_INT_ERR_CANNOT_USE);
			}

			let localAuthPromise;
			const localAuthMsg = this.propertyFilter(LOCAL_AUTH_MSG_LIST[localAuthFlag].MSG);

			switch (localAuthType) {
				case BIO_AUTH:
					localAuthPromise = runBioAuth(this.$window, localAuthMsg);
					break;
				case ANY_AUTH:
					localAuthPromise = runAnyAuth(this.$window, localAuthMsg);
					break;
				default:
					reject(LOCAL_AUTH_INT_ERR_INVALID_TYPE);
					break;
			}
			if (localAuthPromise) {
				localAuthPromise.then((result: LocalAuth) => resolve(result)).catch((error: ErrorResult) => reject(error));
			}
		});
	}
}
