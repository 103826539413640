import {Pipe, PipeTransform} from '@angular/core';

import {propertyFilterInit} from '../../core/property/property.filter';
import {PropertyStateService} from './property-state.service';

@Pipe({
	name: 'property',
	pure: false
})
export class PropertyFilterPipe implements PipeTransform {
	constructor(private propertyStateService: PropertyStateService) {}

	public transform(property: string, replacements?: any[]): string {
		const propertyFilter = propertyFilterInit(this.propertyStateService);

		return propertyFilter(property, replacements);
	}
}
