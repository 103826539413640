import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {DeprecationMessageService} from '../../services/deprecation-message/deprecation-message.service';
import {TooltipConfigProvider} from '../../providers/tooltip-config.provider';
import {AttributesModule} from '../../utils/attributes/attributes.module';
import {SingleSelectNativePickerModule} from './single-select-native-picker/single-select-native-picker.module';
import {SingleSelectPickerComponent} from './single-select-picker.component';
import {SingleSelectPopoverPickerModule} from './single-select-popover-picker/single-select-popover-picker.module';
import {SingleSelectPickerBaseModule} from './single-select-picker-base/single-select-picker-base.module';

/**
 * @deprecated This single-select-picker component is deprecated and will be removed in the future in favor of components found within the Ignite design system. [Click here for more details](https://engstash.int.kronos.com/projects/FRONT/repos/frontend/browse/packages/web/zed/README.md)
 */
@NgModule({
	declarations: [SingleSelectPickerComponent],
	exports: [SingleSelectPickerComponent],
	imports: [
		CommonModule,
		AttributesModule,
		SingleSelectPopoverPickerModule,
		SingleSelectNativePickerModule,
		SingleSelectPickerBaseModule,
		TooltipModule.forRoot()
	],
	providers: [TooltipConfigProvider]
})
export class SingleSelectPickerModule {
	constructor(private deprecationMessageService: DeprecationMessageService) {
		this.deprecationMessageService.log('single-select-picker');
	}
}
