import {A11yAttrs, InteractiveAttrs, PopupElementAttrs} from '../platform/attributes.interfaces';

export enum ButtonClasses {
	LINK = 'btn-link',
	PRIMARY = 'btn-primary',
	SECONDARY = 'btn-secondary'
}

export enum ButtonTypes {
	ANCHOR = 'anchor',
	BUTTON = 'button'
}

export interface ButtonAttrs extends InteractiveAttrs, A11yAttrs, PopupElementAttrs {
	type?: 'button' | 'submit';
}
