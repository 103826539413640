import {get} from 'lodash';
import {handleActions} from 'redux-actions';

import {updateMainMenu} from './nav-bar-external.utility';
import {fetchMenuData, menuToggled, setRightMenuItems, setTitle, setVisible, updateMenuData, setState} from './nav-bar.actions';
import {NavBarState} from './nav-bar.state';

export const NAV_BAR_DEFAULT_STATE: NavBarState = {
	visible: false,
	isMenuToggled: false,
	rightMenuItems: [],
	userMenuState: {mainMenu: {categories: []}}
};

export const navBarReducerHelper = (): any => ({
	[setState]: (state, action) => ({...state, ...action.payload ? action.payload.state : action.state}),
	[setRightMenuItems]: (state, action) => ({...state, rightMenuItems: action.payload ? action.payload.rightMenuItems : action.rightMenuItems}),
	[setTitle]: (state, action) => ({...state, title: action.payload ? action.payload.title : action.title}),
	[setVisible]: (state, action) => ({...state, visible: action.payload ? action.payload.visible : action.visible}),
	[menuToggled]: (state, action) => ({...state, isMenuToggled: action.payload.isMenuToggled}),
	[fetchMenuData]: (state, action) => ({
		...state,
		menu: action.payload ? action.payload.menu : action.menu,
		additionalData: action.payload ? action.payload.additionalData : {},
		error: action.payload.error,
		isFetching: action.payload.isFetching,
		title: get(action, 'payload.menu[0].text'),
		home: {href: get(action, 'payload.menu[0].uri')},
		logo: {href: get(action, 'payload.menu[0].uri')}
	}),
	[updateMenuData]: (state, action) =>
		state.menu
			? {
					...state,
					menu: updateMainMenu(get(action, 'payload.extendedMenu', state.menu), {menu: state.menu}, false).menu
}
			: state
});

export const navBarReducer = handleActions(navBarReducerHelper(), NAV_BAR_DEFAULT_STATE);
