import {Pipe, PipeTransform} from '@angular/core';
import {filter, get} from 'lodash';

import {ActionItemBaseComponent} from './action-item-base.component';

@Pipe({name: 'krnNgActionItemSticky'})
export class ActionItemStickyPipe implements PipeTransform {
	public transform(items: ActionItemBaseComponent[] = [], sticky = true): ActionItemBaseComponent[] {
		return filter(items, (item: ActionItemBaseComponent): boolean => get(item, 'actionItemOptions.sticky', false) === sticky);
	}
}
