<div class="nav nav-pills nav-stacked menu-links-container" tabindex="-1" role="menu">
  <div *ngIf="!searchTerm" krn-ng-modal-keyboard-navigation modalRole="topLevelMenu">
		<krn-ng-menu-item *ngFor="let item of (menuData | menuItemPositions: false); index as i" [itemConfig]="item" [itemIndex]="i" [menuData]="menuData" [isSelected]="item.isSelected"> </krn-ng-menu-item>
  </div>
  <div *ngIf="searchTerm" krn-ng-modal-keyboard-navigation modalRole="topLevelMenu">
		<krn-ng-menu-item *ngFor="let item of (menuData | menuItemFilter: searchTerm | menuItemPositions: true); index as i" [itemIndex]="i" [menuData]="(menuData | menuItemFilter: searchTerm | menuItemPositions: true)" [isSelected]="item.isSelected" [itemConfig]="item" isSearch="true"></krn-ng-menu-item>
  </div>
	<div *ngIf="searchTerm && (menuData | menuItemFilter: searchTerm).length === 0" class="no-search-results">{{'html.navBar.search.noMatchesFound' | property}}</div>
</div>

<krn-ng-screen-reader-only
  *ngIf="[(menuData | menuItemFilter: searchTerm | menuItemTotalFilterCount)] as totalCountParam"
  [content]="{text: totalCountParam[0] ? ('html.navBar.search.xMatchesFound' | property: totalCountParam) : ('html.navBar.search.noMatchesFound' | property)}"
  removeTimeout="1000"></krn-ng-screen-reader-only>