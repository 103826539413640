import {delay, get, includes, isEmpty, isEqual, isUndefined} from 'lodash';

import {ApiService} from '../../communication/api-service/api-service.interfaces';
import {Preferences} from './preferences.interface';
import {LogServiceInterface} from '../services/logService/log.interface';

import {SessionStorage} from '../caching/sessionStorage.service';
import {SENDER_NAME} from '../../one-app/one-app-event-bus.constants';
import {isOneApp} from '../services/DeviceConfig.utility';

const SUCCESS_STATUS_CODE = 200;

export const BC_NAME = 'LOCALE_CHANGE';
export const BC_LOCALE_CHANGE_EVENT = 'LOCALE_CHANGE_EVENT';
export const RELOAD_WINDOW_DELAY_TIME = 1000;

export class PreferencesCoreService {
	protected preferences: Preferences;
	protected isDataBeingFetched = false;

	private readonly URL = 'get/preferences/current_user';
	private readonly LOCALE_URL = 'get/preferences?locale=';
	private channel;
	private request = new XMLHttpRequest();

	constructor(
		protected logger: LogServiceInterface,
		protected window: any,
		protected storage: SessionStorage,
		protected dataService: ApiService,
		protected locale?: string
	) {
		this.initPreferences();
		if (this.hasBroadcastChannel()) {
			this.channel = new BroadcastChannel(BC_NAME);
			this.channel.onmessage = (event: MessageEvent) => {
				if (event.data === BC_LOCALE_CHANGE_EVENT) {
					this.reloadPage();
				}
			};
		}
	}

	public initPreferences(): Preferences {
		const storagePreferences = this.storage.get('preferences');

		if (storagePreferences && !this.preferences) {
			this.setPreferences(storagePreferences);
		}
		return this.getPreferences();
	}

	public getPreferences(): Preferences {
		if (this.preferences) {
			return this.preferences;
		}
		return this.storage.get('preferences');
	}

	public async fetchPreferences(locale?: string): Promise<Preferences> {
		let preferences = this.getPreferences();
		const storageLocale = await this.fetchLocale();

		locale = locale || storageLocale;

		if (((!isEmpty(locale) && this.hasLocaleChanged(preferences, locale)) || isEmpty(preferences)) && !this.isDataBeingFetched) {
			this.isDataBeingFetched = true;
			try {
				preferences = await this.callForPreferences(locale);
				this.setPreferences(preferences);
			} catch (error) {
				this.logger.error(error);
			} finally {
				this.isDataBeingFetched = false;
			}
		}
		return this.getPreferences();
	}

	public fetchPreferencesSynchronously(successCallback?: (result: Preferences) => void, locale?: string | null): Preferences {
		const preferences = this.storage.get('preferences');
		const url = isEmpty(locale) ? this.URL : this.LOCALE_URL + locale;

		if (this.hasLocaleChanged(preferences, locale) && !this.isDataBeingFetched) {
			this.isDataBeingFetched = true;
			const urlPrefix =
				!isUndefined(this.window) &&
				(includes(this.window.location.href, 'wfd') || isEqual(this.window.location.pathname, '/'))
					? '/'
					: '';

			this.request.open('GET', `${urlPrefix}${url}`, false);
			this.request.setRequestHeader('Cache-Control', 'no-cache');
			this.request.setRequestHeader('Content-Type', 'application/json');
			this.request.withCredentials = true;

			try {
				this.request.send();

				if (this.request.status === SUCCESS_STATUS_CODE) {
					const result = JSON.parse(this.request.response);

					this.setPreferences(result);
					if (successCallback) {
						successCallback(result);
					}
				} else if (this.request.statusText !== 'parsererror') {
					this.logger.error(this.request.response);
				}
			} catch (error) {
				this.logger.error(error);
			}
		}

		this.isDataBeingFetched = false;
		return this.getPreferences();
	}

	public sentChangeLocale(): void {
		if (this.hasBroadcastChannel()) {
			this.channel.postMessage(BC_LOCALE_CHANGE_EVENT);
		}
		this.reloadPage();
	}

	public fetchLocale(): Promise<string> {
		const storageLocale = this.storage.get('locale') || '';

		if (!isOneApp(this.window) || storageLocale) {
			return Promise.resolve(storageLocale);
		}

		return this.window.OneAppCommon.DATA.getUserData(this.window, SENDER_NAME, {key: this.window.OneAppCommon.DataKeys.COMMON.i18n})
			.then(res => {
				if (get(res, 'data.value')) {
					const locale = get(this.window.OneAppCommon.UTIL.b64DecodeJSON(res.data.value), 'dimensions.locale', '');

					this.storage.set('locale', locale);
					return locale;
				}
				return '';
			})
			.catch(error => {
				this.logger.error(error);
				return '';
			});
	}

	protected setPreferences(preferences: Preferences): void {
		this.storage.set('preferences', preferences);
		this.preferences = preferences;
	}

	protected reloadPage(): void {
		this.storage.clear('preferences');
		delay(() => {
			const window = get(this.window, 'nativeWindow', this.window);

			window.location.reload();
		}, RELOAD_WINDOW_DELAY_TIME);
	}

	private callForPreferences(locale?: string): Promise<Preferences> {
		const URL = locale ? this.LOCALE_URL + locale : this.URL;

		return this.dataService.get(URL).then((response: {data: Preferences} | Preferences) => get(response, 'data', response));
	}

	private hasLocaleChanged(preferences: any, locale: string | null): boolean {
		const localePolicy = get(preferences, 'localePolicy');

		return !localePolicy || (locale && locale.replace('_', '-').toLowerCase() !== `${localePolicy.languageCode}-${localePolicy.countryCode}`.toLowerCase());
	}

	private hasBroadcastChannel(): boolean {
		return typeof BroadcastChannel === 'function';
	}
}
