import {EmbeddedMenuCategory, LegacyEmbeddedMenuItem} from '../../embedded/embedded.interfaces';
import {CordovaPluginWindow} from '../mobile/cordova-plugin-window.interface';
import {LocalAuthHandler} from '../mobile/local-auth.interface';
import {DispatchParam, ScanResult, TabType} from './client-container.interface';

import {EmbeddedMenuItemsCoreService} from '../../embedded/embedded-menu-items.core.service';
import {postMessage} from '../../iframe-framework/iframe-message-handler.utility';
import {EmbeddedMappingCoreService} from '../../embedded/embedded-mapping.core.service';
import {scan} from '../../embedded/embedded-page.utility';
import {EmbeddedWifiLocationService} from '../../embedded/embedded-wifi-location.service';
import {PropertyFactory} from '../../property/property.factory';
import {PropertyFilterConfig} from '../../property/property.filter';
import {SessionStorage} from '../caching/sessionStorage.service';
import {BaseWifiLocationService} from '../services/base-wifi-location.service';
import {GeolocationCoreService} from '../services/geolocation.service';
import {BrowserContainerCoreService} from './browser-container.core.service';
import {getTabType, INAPPBROWSER_OPTIONS} from './client-container.utility';

export abstract class BaseEmbeddedContainerCoreService extends BrowserContainerCoreService {
	constructor(
		protected window: CordovaPluginWindow,
		protected sessionStorage: SessionStorage,
		protected embeddedMenuItemsService: EmbeddedMenuItemsCoreService
	) {
		super(window, sessionStorage);
	}

	public scan(): Promise<ScanResult> {
		return scan(this.window);
	}

	public geolocation(): GeolocationCoreService {
		return new GeolocationCoreService(this.window, null, this.sessionStorage);
	}

	public localAuth(propertyFactory?: PropertyFactory, propertyFilter?: PropertyFilterConfig): LocalAuthHandler {
		// Business decision: bypass local auth for SMA for R8 update 1
		return {runLocalAuth: () => Promise.resolve({status: true})};
	}

	public print(content = ''): void {
		// this will call into embedded post messaging service when a new print contract is made.
	}

	public wifiLocation = (propertyFactory: PropertyFactory, propertyFilter: PropertyFilterConfig): BaseWifiLocationService => new EmbeddedWifiLocationService(this.getTopWfdWindow(), this.supportsWifiLocation(), propertyFactory, propertyFilter);

	public openNewTab = (url: string, tab: string = TabType.BLANK): void => {
		const arg = {
			url,
			options: INAPPBROWSER_OPTIONS,
			target: getTabType(tab)
		};

		this.window.dispatchEvent(new CustomEvent(this.getEventName(), {detail: {method: 'openNewTab', arg}}));
	};

	public supportsExport = (): boolean => true;

	public supportsPrint = (): boolean => false;

	public allowExternalEmbedding = (): boolean => true;

	public supportsWifiLocation = (): boolean => true;

	public supportsMapping = (): boolean => true;

	public navigate = (window: Window, href: string, useCurrentWin = false, serviceName = 'wfd'): void => {
		if (useCurrentWin || window.top === window.parent) {
			window.location.href = href;
		} else {
			const message = {
				type: 'NAVIGATE',
				action: 'navigation',
				configuration: {
					isInternalUrl: true,
					serviceName,
					url: href
				}
			};

			postMessage(this.getTopWfdWindow(), window.location.origin, message);
		}
	};

	public mapping(propertyFactory?: PropertyFactory, propertyFilter?: PropertyFilterConfig): EmbeddedMappingCoreService {
		return new EmbeddedMappingCoreService(this.getTopWfdWindow());
	}

	public talkNavigation(deeplink: string, tenantId: string, vanityUrl: string): void {
		this.getTopWfdWindow().dispatchEvent(
			new CustomEvent(
				this.getEventName(),
				{
					detail: {
						method: 'launchUniversalLink',
						arg: {
							deeplink, tenantId, vanityUrl
						}
					}
				})
		);
	}

	public swpNavigation(deeplink: string): void {
		this.getTopWfdWindow().dispatchEvent(
			new CustomEvent(
				'upPostMessage',
				{
					detail: {
						method: 'launchUniversalLink',
						arg: {
							deeplink
						}
					}
				})
		);
	}

	public dispatchEvent(dispatchParam: DispatchParam): void {
		this.getTopWfdWindow().dispatchEvent(new CustomEvent(this.getEventName(), dispatchParam));
	}

	public getMenuItems = (): Promise<EmbeddedMenuCategory[] | LegacyEmbeddedMenuItem[]> => this.embeddedMenuItemsService.getMenuItems(true);
}
