import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {SessionTimeoutCoreController} from '../../../core/session/session.timeout.core.component';
import {MOBILE_OS} from '../../../core/platform/services/DeviceConfigServices.constants';
import {DeviceConfigService} from '../../../services/device-config.service';
import {SessionActionService} from './session.action';

@Component({
	selector: 'krn-ng-session-timeout',
	templateUrl: './session-timeout.component.html',
	styleUrls: ['./session-timeout.component.less'],
	encapsulation: ViewEncapsulation.None
})
export class SessionTimeoutComponent extends SessionTimeoutCoreController implements OnInit {
	public timeLeft: string[];
	public currentTimeLeft: string[];
	public isIOSDevice;
	constructor(
		protected sessionActionService: SessionActionService,
		protected deviceConfigService: DeviceConfigService
	) {
		super(sessionActionService.dispatch);
		this.isIOSDevice = this.deviceConfigService.getMobileOS() === MOBILE_OS.IOS;
	}

	public get sessionTimeoutTextPropertyKey(): string {
		return this.extendable ? 'app.core.session.timeout.body' : 'app.core.session.timeout.body.final';
	}

	public get sessionTimeoutConfirmButtonTextPropertyKey(): string {
		return this.extendable ? 'app.core.session.timeout.confirm' : 'app.core.session.timeout.confirm.final';
	}

	public ngOnInit(): void {
		super.init();
	}

	protected setSessionStatus = (eventName: string): any => eventName;
}
