import {compact, get, isEmpty, join, map, pick} from 'lodash';

const toAttrString = (attr: Attr): string => join(compact([attr.name, toAttrValueString(attr.value)]), '=');

const toAttrsString = (attrs: NamedNodeMap): string => {
	let reducedAttrs: any = pick(attrs, ['id']);

	if (isEmpty(reducedAttrs)) {
		reducedAttrs = pick(attrs, ['class', 'type']);
	}
	return join(
		map(isEmpty(reducedAttrs) ? attrs : reducedAttrs, attr => toAttrString(attr)),
		' '
	);
};

const toAttrValueString = (value: string): string => (value ? `"${value}"` : undefined);

export const toFocusableElementString = (element: HTMLElement): string => `<${get(element, 'tagName')} ${toAttrsString(get(element, 'attributes'))}>`;

export const toFocusableElementStrings = (elements: HTMLElement[]): string[] => map(elements, toFocusableElementString);

export const FIRST = 0;

export const ONE_ITEM = 1;

export const isFirstElementFocused = (currentFocusIndex: number): boolean => currentFocusIndex === FIRST;

export const isLastElementFocused = (currentFocusIndex: number, focusableElementCount: number): boolean =>
	currentFocusIndex === focusableElementCount - ONE_ITEM;
