export const AUTH_COOKIE_NAME = 'falconAuthCookie';
export const EXPIRE_FALCON_AUTH_COOKIE = `${AUTH_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`;
export const EMBEDDED_SESSION = 'embeddedSession';
export const SSO_LOGIN_COOKIE_NAME = 'ssologin';
export const THEME_MESSAGE_KEY = 'html.commonInfra.platform.Branding.Preview.Warning';
export const STATE_ROOT = 'authentication';
export const URL = {
	OPEN_AM: '/get/isOpenAm',
	PREFERENCES: 'get/preferences/current_user'
};
export const MAX_TIMEOUT = 5000;
export const TIMEOUT_DELAY = 1000;
export const LOGIN_CONSTANTS = {
	ECONNREFUSED_CODE: 'ECONNREFUSED',
	LOGOUT_URL: '/UI/Logout?goto=',
	LOGOUT_SECURE_URL: '/sso/loggedout',
	AUTH0_LOGOUT_URL: '/oidc/logout',
	SESSION_EXPIRED_CODE: 'SessionExpired',
	SSO_PORTAL: 'sso/portal',
	SSO_VALID: 'sso/isValid',
	UNAUTHORIZED_URL: '/unauthorized',
	SYSTEM_ERROR: 'System Error',
	SYSTEM_UNAVAILABLE: 'Service Unavailable',
	UNAUTHORIZED_CODE: 'Unauthorized',
	CLEAR_WFC_COOKIES: '/wfcCookies/clear',
	CLEAR_NODE_SPECIFIC_COOKIES: '/user/clearNodeSpecificSessionCookie',
	LOGINLIMITREACHED: 'LOGINLIMITREACHED',
	GET_OPENAM_PATH: '/get/openam',
	GET_ENVIRONMENT_PATH: '/get/authEnvironment',
	HTTP_PROTOCOL: 'http:'
};
export const MESSAGES = {
	SYSTEM_ERROR: 'app.core.authentication.error',
	SYSTEM_UNAVAILABLE: 'app.core.authentication.system.unavailable',
	UNAUTHENTICATED_MESSAGE: 'app.core.authentication.unauthenticated.message',
	UNAUTHORIZED: 'app.core.authentication.unauthorized',
	UNAUTHORIZED_MESSAGE: 'app.core.authentication.unauthorized.message',
	LOGINLIMITREACHED: 'app.core.authentication.login.limited.reached',
	LOGINLIMITREACHED_MESSAGE: 'app.core.authentication.login.limited.reached.message',
	SESSION_EXPIRED: 'app.core.authentication.session.expired',
	SESSION_EXPIRED_MESSAGE: 'app.core.authentication.session.expired.message'
};
export const IP_RESTRICTION_CONSTANTS = {
	IPR_LOGOUT_MESSAGE: 'You are not authorized and session has ended.'
};
export const PROPERTIES = [
	{
		name: 'container_web-common_authentication_real_strings.properties',
		path: 'components/zed-core/properties/authentication/'
	}
];
