import {ApiService} from '../../../../core/communication/api-service/api-service.interfaces';

import {NavBarService} from '../../../../core/navBar/nav-bar.service';
import {DataService} from '../../../../services/communication/data.service';
import {WindowRefService} from '../../../../services/window-ref.service';
import {NavbarActionService} from './navbar.action.service';

class NavBarProviderService extends NavBarService {
	protected fetchMenuData;
	protected setTitle;

	constructor(dataService: ApiService, windowService: WindowRefService, navbarActionService: NavbarActionService) {
		super(dataService, windowService.nativeWindow);
		this.fetchMenuData = navbarActionService.actions.fetchMenuData;
		this.setTitle = navbarActionService.actions.setTitle;	
	}
}

export function getNavBarService(dataService: ApiService, windowService: WindowRefService, navbarActionService: NavbarActionService): any {
	return new NavBarProviderService(dataService, windowService, navbarActionService);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NavBarServiceProvider = {
	provide: NavBarService,
	deps: [DataService, WindowRefService, NavbarActionService],
	useFactory: getNavBarService
};
