import {createAction} from 'redux-actions';
import {Observable} from 'rxjs';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';

import {CommonConstants, NOTIFICATIONS_CARD_REFRESH_NOTIFICATIONS} from '../platform/constants/CommonConstants';
import {SessionStorage} from '../platform/caching/sessionStorage.service';
import {ReleaseToggleService} from '../platform/releaseToggle/release-toggle.service';

export interface NotificationCount {
	[key: string]: number;
}

const ALERTS_ICON = '#alerts_icon';
const NOTIFICATION_CLASS = ' fa fa-bell faa-ring animated faa-slow';

export const init = (
	dataService: ApiService,
	sessionStorageService: SessionStorage,
	releaseToggleService: ReleaseToggleService,
	bellNotVisible = true,
	notificationVisible = true,
	callback?: () => void,
	dispatch?: any
): Promise<any> => {
	if (!bellNotVisible && !notificationVisible) {
		return Promise.resolve(sessionStorageService.get(CommonConstants.HTML_INFRASTRUCTURE_SESSIONSTORAGE.HTML_NOTIFICATION_COUNT_CACHE));
	}
	return dataService.find('controlCenter/notification/count', undefined, Transport.REST, false).then(
		data => {
			sessionStorageService.set(CommonConstants.HTML_INFRASTRUCTURE_SESSIONSTORAGE.HTML_NOTIFICATION_COUNT_CACHE, data);

			releaseToggleService.getAsync('ServerSentEvents').then(sseEnabled => {
				const incEventObs = sseEnabled ? dataService.stream('/controlCenter/incrementCount') : dataService.listen('controlCenter.incrementCount');
				const decEventObs = sseEnabled ? dataService.stream('/controlCenter/decrementCount') : dataService.listen('controlCenter.decrementCount');

				const refreshNotificationAction = createAction(NOTIFICATIONS_CARD_REFRESH_NOTIFICATIONS);

				incEventObs.subscribe(eventData => updateNotificationCount(eventData, data, true, sessionStorageService, callback, dispatch, refreshNotificationAction));
				decEventObs.subscribe(eventData => updateNotificationCount(eventData, data, false, sessionStorageService, callback, dispatch, refreshNotificationAction));
			});

			return data;
		},
		errors => {
			sessionStorageService.set(CommonConstants.HTML_INFRASTRUCTURE_SESSIONSTORAGE.HTML_NOTIFICATION_MESSAGE, errors);
			return errors;
		}
	);
};

const updateNotificationCount = (
	eventData,
	notificationCount: NotificationCount,
	increment: boolean,
	sessionStorageService: SessionStorage,
	callback?: (arg?: NotificationCount) => void,
	dispatch?: any,
	refreshNotificationAction?: any
): any => {
	const category = eventData.args[2];
	const noNotifications = 0;

	if (category) {
		if (increment && notificationCount[category] >= noNotifications) {
			$(ALERTS_ICON).addClass(NOTIFICATION_CLASS);
			removeAnimation();
			notificationCount.ALL++;
			notificationCount[category]++;
		} else if (!increment && notificationCount[category] > noNotifications) {
			notificationCount.ALL--;
			notificationCount[category]--;
		}
	}
	if (callback) {
		callback(notificationCount);
	}
	sessionStorageService.set(CommonConstants.HTML_INFRASTRUCTURE_SESSIONSTORAGE.HTML_NOTIFICATION_COUNT_CACHE, notificationCount);
	if (dispatch && refreshNotificationAction) {
		dispatch(refreshNotificationAction());
	}
};

const removeAnimation = (): any => {
	const ANIMATION_DELAY = 500;

	$(ALERTS_ICON).on('animationend MSTransitionEnd webkitAnimationEnd oanimationend', () => {
		// eslint-disable-next-line @typescript-eslint/no-invalid-this
		$(this)
			.delay(ANIMATION_DELAY)
			.queue(() => {
				// eslint-disable-next-line @typescript-eslint/no-invalid-this
				$(this).removeClass(NOTIFICATION_CLASS).dequeue();
			});
	});
};
