import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {PropertyModule} from '../../../../providers/property/property.module';
import {ModalKeyboardNavigationModule} from '../../../../utils/modal-keyboard-navigation/modal-keyboard-navigation.module';
import {ButtonModule} from '../../../button/button.module';
import {CardLoaderManagerProvider} from '../../../framework/tile/card/card-loader-manager.provider';
import {CardModule} from '../../../framework/tile/card/card.module';
import {TileFullScreenModalComponent} from './fullscreen-modal.component';


@NgModule({
  declarations: [TileFullScreenModalComponent],
  imports: [
    CommonModule,
    ButtonModule,
    PropertyModule,
    CardModule,
    TooltipModule,
    ModalKeyboardNavigationModule
  ],
  providers: [CardLoaderManagerProvider],
  exports: [TileFullScreenModalComponent],
})
export class TileFullScreenModalModule {}
