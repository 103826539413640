import {isNil, get} from 'lodash';
import {fromEvent, Subject} from 'rxjs';
import {filter, map, take, takeUntil} from 'rxjs/operators';

import {EmbeddedUnsavedDataServiceInterface} from './embedded.interfaces';

import {sendPostMessage} from '../iframe-framework/iframe-message-handler.utility';
import {UnsavedDataCoreService} from '../framework/services/unsaved-data-core.service';
import {SlideOutManagerCoreService} from '../generic/slideOutContainer/slideout-manager.core.service';

const ONE = 1;

export abstract class BaseEmbeddedUnsavedDataCoreService implements EmbeddedUnsavedDataServiceInterface {
	private unsubscribe$ = new Subject();

	constructor(
		protected window: Window,
		protected unsavedDataService: UnsavedDataCoreService,
		protected slideOutManagerService: SlideOutManagerCoreService
	) {}

	public destroySubscription(): void {
		this.unsubscribe$.next(undefined);
		this.unsubscribe$.complete();
	}

	protected isIframeSlideOutDirty = (defaultSliderActionHandler: any): boolean =>
		get(this.window, 'frames.angularIframeSlider') && defaultSliderActionHandler.isDataDirty;

	protected verifyUnsavedDataForIframeSlideOut = (): Promise<boolean> =>
		new Promise(resolve => {
			sendPostMessage(
				get(this.window, 'frames.angularIframeSlider.contentWindow'),
				this.window.location.origin,
				'info',
				'navigation',
				{type: 'close'},
				'wfd'
			);

			fromEvent(this.window, 'message')
				.pipe(
					filter((event: MessageEvent) => !isNil(get(event, 'data.configuration.data.closeSlideOut'))),
					map(event => get(event, 'data.configuration.data.closeSlideOut')),
					take(ONE),
					takeUntil(this.unsubscribe$)
				)
				.subscribe(doClose => {
					resolve(!doClose);
				});
		});

	public abstract verifyUnsavedData(action: string, url?: string): Promise<boolean>;
}
