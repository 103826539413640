import * as jQuery from 'jquery';
import {difference} from 'lodash';

import {Selector} from './selector.interface';

const $: any = jQuery;

export class ExcludeSelector implements Selector {
	constructor(private element: HTMLElement, private excludeSelector: string) {
	}

	public select(list: HTMLElement[]): HTMLElement[] {
		const excludedList: HTMLElement[] = $(this.element).find(this.excludeSelector).get();

		return difference(list, excludedList);
	}
}
