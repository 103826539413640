import {Injectable} from '@angular/core';

import {SessionStorage} from '../core/platform/caching/sessionStorage.service';

export const ngStorage = 'ngStorage-';

@Injectable({providedIn: 'root'})
export class SessionStorageService implements SessionStorage {
	public isDOMStorageEnabled(): boolean {
		return true;
	}

	public getKeys(): string[] {
		return Object.keys(window.sessionStorage).map(key => key.substr(ngStorage.length));
	}

	public get(key): any {
		return JSON.parse(window.sessionStorage.getItem(ngStorage + key));
	}

	public set(key, value): void {
		window.sessionStorage.setItem(ngStorage + key, JSON.stringify(value));
	}

	public clear(key): void {
		window.sessionStorage.removeItem(ngStorage + key);
	}

	public apply(): void {
		//
	}

	public reset(): void {
		window.sessionStorage.clear();
	}
}
