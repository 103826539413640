import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DeprecationMessageService} from '../../services/deprecation-message/deprecation-message.service';
import {PropertyModule} from '../../providers/property/property.module';
import {InlineValidationComponent} from './inline-validation.component';

/**
 * @deprecated This inline-validation component is deprecated and will be removed in the future in favor of components found within the Ignite design system. [Click here for more details](https://engstash.int.kronos.com/projects/FRONT/repos/frontend/browse/packages/web/zed/README.md)
 */
@NgModule({
	imports: [CommonModule, PropertyModule],
	declarations: [InlineValidationComponent],
	exports: [InlineValidationComponent]
})
export class InlineValidationModule {
	constructor(private deprecationMessageService: DeprecationMessageService) {
		this.deprecationMessageService.log('inline-validation');
	}
}
