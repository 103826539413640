import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';

import {PropertyState} from '@kronos/zed/src/lib/core/property/property.interface';

import {PropertyFactory} from '@kronos/zed/src/lib/core/property/property.factory';

@Injectable()
export class ViewPaycodeAnalyzerResolver implements Resolve<PropertyState> {
	constructor(private propertyFactory: PropertyFactory) {}

	public resolve(): Promise<PropertyState> {
		return this.propertyFactory.loadProperties([
			{
				name: 'container_view-paycode-analyzer_strings.properties',
				path: 'bundles/wfd/schedule/plugins/view-paycode-analyzer/properties/'
			}
		]);
	}
}
