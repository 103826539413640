<div
	ngClass="select-item d-flex mb-0 position-relative align-items-center native-{{ alignment }}"
	role="presentation"
	[attr.aria-disabled]="attrs?.disabled">
	<input
		*ngIf="!isReadOnly"
		[checked]="isSelected"
		[krn-ng-attributes]="attrs"
		[ngClass]="{'native-checkbox': showAsNative}"
		[value]="value"
		(change)="handleSelect()"
		(click)="handleSingleSelect($event)"
		(focus)="handleFocus()"
		(keydown.enter)="handleSingleSelect($event)"
		#inputId />
	<div
		class="focus-area d-flex flex-grow-1 justify-content-between align-items-center w-100 p-1"
		role="presentation"
		[ngClass]="allClasses"
		#focusArea>
		<div class="select-item-content" role="presentation" [container]="container" [tooltip]="attrs?.title">
			<ng-content></ng-content>
		</div>
		<div
			*ngIf="!isReadOnly"
			aria-hidden="true"
			class="select-item-selection justify-content-center align-items-center"
			[ngClass]="showAsNative ? 'native-input' : 'icon-k-checkmark'"></div>
	</div>
</div>
