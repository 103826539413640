import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

import {Auth0SessionTimeoutCoreController} from '../../../core/session/auth0.session.timeout.core.component';
import {SESSION_EVENTS} from '../../../core/session/session.constants';
import {DeviceConfigService} from '../../../services/device-config.service';
import {WindowRefService} from '../../../services/window-ref.service';
import {SessionActionService} from './session.action';
import {Auth0StackService} from './authStack/auth0-stack.service';

@Component({
	selector: 'krn-ng-auth0-session-timeout',
	templateUrl: './auth0-session-timeout.component.html',
	styleUrls: ['./session-timeout.component.less'],
	encapsulation: ViewEncapsulation.None
})
export class Auth0SessionTimeoutComponent extends Auth0SessionTimeoutCoreController implements OnInit {
	public isIOSDevice;
	constructor(
		protected sessionActionService: SessionActionService,
		protected deviceConfigService: DeviceConfigService,
		protected auth0StackService: Auth0StackService,
		protected windowRefService: WindowRefService,
		protected logger: NGXLogger
	) {
		super(sessionActionService.dispatch, windowRefService.nativeWindow, logger);
		this.isIOSDevice = this.deviceConfigService.isIOSDevice();
	}

	public ngOnInit(): void {
		super.init();
	}

	public confirm(): void {
		super.confirm();
		this.dispatch(SESSION_EVENTS.EXTEND);
	}

	public decline(): void {
		super.decline();
		this.dispatch(SESSION_EVENTS.ENDED);
	}
}
