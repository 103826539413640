<krn-slide-out-container class="iframed-panel"
                         [autoFocus]="autoFocus"
                         [actionHandler]="actionHandler"
                         [navigateOnClose]="navigateOnClose"
                         (onLoad)="onSlideOutLoad()"
                         (onSlideOutClose)="onSlideOutClose()"
                         [krn-ng-return-focus-element]="returnFocusElement">
    <div class="slideout-container-content">
        <div class="slideout__header slideout-header__wrapper" *ngIf="iframeConfig.title && isCustomIframeContent">
            <h2 id="form-title" class="slideout-header-title" [tooltip]="iframeConfig.title">{{iframeConfig.title}}</h2>
        </div>
        <div class="slideout__content iframed-content">
			<krn-ng-iframe-container [config]="iframeConfig" [iframeId]="iframeId" [isCustomIframeContent]="isCustomIframeContent"></krn-ng-iframe-container>
        </div>
    </div>
</krn-slide-out-container>
