import * as SHA1 from 'crypto-js/sha1';
import {includes, inRange, noop} from 'lodash';
import * as Primus from 'primus-client';
import {BehaviorSubject, fromEvent, lastValueFrom, Observable, Subscription} from 'rxjs';
import {filter, first, map, switchMap, take, takeUntil} from 'rxjs/operators';

import {SocketBus, SocketData, SocketError} from './socketBus.interface';
import {ClientContext} from '../../platform/clientContainer/client-container.interface';

import {unAuthorizeUserSession} from '../../authentication/authentication.utility';
import {HealthCheckCoreService} from '../../health-check/health-check.core.service';

const DEFAULT_SESSION_TTL = 1800000; // 30 mins
const ERROR_CYCLE_SPAN = 2500;
const EVENT_RESET = 0;
const ERROR_REQUEST_INTERRUPT = 'WS-REQ-INTERRUPT';
const NO_ERRORS = 0;
const ONE = 1;
const ONE_MINUTE = 60000;
const PING_CYCLE_SPAN = 120000; // 2 mins
const RETRY_3_TIMES = 3;
const TAKE_FIRST = 1;
const ZERO = 0;

export abstract class PrimusSocket implements SocketBus {
	private lastConnectError = NO_ERRORS;
	private lastConnectAttempt = NO_ERRORS;
	private lastIncomingPing = EVENT_RESET;
	private lastTimeOnline = EVENT_RESET;
	private readonly connection$: Observable<any>;

	private connectErrCount = NO_ERRORS;
	private incomingErrCount = NO_ERRORS;
	private isHealthyInProgress = false;

	private readonly moduleMap = {
		ess: 'scheduling',
		forecast: 'scheduling',
		patternTemplates: 'scheduling',
		schedule: 'scheduling',
		'schedule-period': 'scheduling',
		scheduleAdmin: 'scheduling',
		'shift-templates': 'scheduling',
		shiftBuilder: 'scheduling',
		staffing: 'scheduling',
		teamManagement: 'scheduling',
		workloadplanner: 'scheduling',
		attendance: 'timekeeping',
		'hours-allocation-landing-page': 'timekeeping',
		leave: 'timekeeping',
		'ota-landing-page': 'timekeeping',
		quicktimestamp: 'timekeeping',
		'timecard-landing-page': 'timekeeping',
		timekeeping: 'timekeeping',
		'tk-print': 'timekeeping'
	};

	protected constructor(private log, private windowRef, protected clientContainerContextService, private healthCheck: HealthCheckCoreService) {
		this.connection$ = new BehaviorSubject(null).pipe(filter(spark => !!spark));
	}

	public write(name: string, data: any): Subscription {
		return this.connection$.subscribe(spark => spark.write({name, args: [data]}));
	}

	public request(event: string, requestObj = {}, useDefaultError = false): Promise<any> {
		return this.requestToSocket(event, requestObj, useDefaultError);
	}

	public broadcast(name: string, requestObj = {}): void {
		this.connection$.pipe(take(TAKE_FIRST)).subscribe(spark => {
			try {
				return spark.write({name, args: [requestObj]});
			} catch (error) {
				this.log.error(`Unable to broadcast ${name} event`, {error});
			}
		});
	}

	public on(event: string): Observable<any> {
		return this.connection$.pipe(switchMap(spark => fromEvent(spark, event)));
	}

	public listen(event: string): Observable<SocketData> {
		return this.on('data').pipe(
			map(data => data[0]),
			filter(data => data.name === event)
		);
	}

	public once(event: string): Observable<SocketData> {
		return this.listen(event).pipe(first());
	}

	/**
	 * Attempts to open a websocket connection to the specified server.
	 * @param addr Server address to connect to.
	 */
	protected connect(addr: string, isReconnect = false): any {
		this.lastConnectError = NO_ERRORS;
		this.lastConnectAttempt = this.getCurrentTime();

		const primusConfig = {
			manual: true,
			strategy: 'online, disconnect, timeout',
			reconnect: {min: 200, max: 5000, retries: Infinity, factor: 1.4} // Retry up to 5 seconds forever
		};
		const spark = Primus.connect(addr, primusConfig);

		(this.connection$ as BehaviorSubject<any>).next(spark);

		if (!isReconnect) {
			this.on('open').subscribe(() => this.open());
			this.on('close').subscribe(() => this.close());
			this.on('error').subscribe(err => this.connectError(err));
			this.on('incoming::error').subscribe(err => this.incomingError(err));
			this.on('incoming::ping').subscribe(timestamp => this.incomingPing(timestamp));
		}
		return spark;
	}

	protected connected(): void {
		this.isHealthyInProgress = false;
	}

	/**
	 * Ends the socket connection with the server.  Calling this indicates that
	 * we do not intend to reopen a connection and that neither the client nor
	 * the server should attempt to reconnect.
	 */
	protected end(): void {
		this.connection$.subscribe(spark => spark.end());
		this.healthCheck.destroy();
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected handleTokenException(): void {}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected handleDefaultError(error: SocketError): void {}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected handleRuntimeIsolationError(): void {}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected resetSession(resetSessionFlag: boolean): void {}

	protected openConnection(spark): void {
		if (spark.readyState !== Primus.OPEN) {
			this.log.info(`Attempting to open the socket at ${new Date().toUTCString()}`);
			spark.open();
		}
	}

	// a callback function is assigned in DataService for this method's implementation
	private isHealthy(): Promise<boolean> {
		return lastValueFrom(this.healthCheck.whenHealthy(RETRY_3_TIMES).pipe(take(TAKE_FIRST)));
	}

	private requestToSocket(event, request, useDefaultError): Promise<any> {
		const correlationKey = this.createCorrelationKey(event, request);

		return this.promiseWrapper((resolve, reject) => {
			let responded = false;

			this.once(correlationKey)
				.pipe(takeUntil(this.on('close')))
				.subscribe(
					value => {
						const [data, status, resetSessionFlag] = value.args;

						if (this.isSuccess(status)) {
							this.resetSession(resetSessionFlag);
							responded = true;
							resolve(data);
						} else if (this.isTokenException(data)) {
							this.handleTokenException();
						} else {
							this.resetSession(resetSessionFlag);

							if (this.isRuntimeIsolationNodeDownError(status)) {
								this.log.error(`Showing Error Modal on this socket error ${{request, status, data}}`);
								this.handleRuntimeIsolationError();
							}

							// badGateway and gatewayTimeout errors are handled by app.core.socket.error module
							if (useDefaultError && !this.isGatewayError(status)) {
								this.handleDefaultError({data, status});
							}

							// Session token error (WCO-115006) is handled by app.core.socket.error module
							// If we still reject the promise for session token error, then each action's failure fucniton will still process
							// and handle the error by its own (most often by displaying its own error popup on top of
							// 'socket-session-error.modal.html'). We don't want these duplicated popups.
							if (!this.isSessionTokenError(data)) {
								responded = true;
								reject(data);
							}
						}
						writeSubscription.unsubscribe();
					},
					noop,
					() => {
						if (!responded) {
							this.log.error(`Socket response ${correlationKey} completed without responding ${new Date().toUTCString()}`);
							reject({errorCode: ERROR_REQUEST_INTERRUPT});
						}
					}
				);

			const writeSubscription = this.write(event, request);
		});
	}

	private connectError(err): void {
		this.lastConnectError = this.getCurrentTime();
		this.log.error(`socket connect error #${++this.connectErrCount}: ${JSON.stringify(err)} at ${new Date().toUTCString()}`);
	}

	private createCorrelationKey(event: string, request: any): any {
		const requestHash = SHA1(JSON.stringify(request)).toString();

		return `${event}.${requestHash}`;
	}

	private incomingError(err): void {
		const now = this.getCurrentTime();

		this.log.error(`socket incoming::error #${++this.incomingErrCount}: ${JSON.stringify(err)} at ${new Date().toUTCString()} (retrying for another ${Math.max(Math.ceil((DEFAULT_SESSION_TTL - (now - this.lastTimeOnline)) / ONE_MINUTE), ZERO)} minutes)`);

		if (!this.isHealthyInProgress) {
			this.isHealthyInProgress = true;
			this.isHealthy();
		}

		// if we come to connect and we get a socket connect error followed by consecutive incoming:errors (within a buffer time),
		// our socket connection can not reconnect and we can refresh the page.
		//
		// Note: We need to make sure we are only in the BROWSER context when reloading the page (not in SMA, any type of Mobile, or Kiosk).
		if (now - this.lastConnectAttempt < ERROR_CYCLE_SPAN && now - this.lastConnectError < ERROR_CYCLE_SPAN && this.incomingErrCount > ONE && this.clientContainerContextService.isBrowser()) {
			this.lastConnectAttempt = NO_ERRORS;
			this.lastConnectError = NO_ERRORS;
			this.end();
			if (this.windowRef.location && this.windowRef.location.reload) {
				this.log.error(`Socket Disruption error, refreshing page ${new Date().toUTCString()}`);
				this.windowRef.location.reload();
			}
		}

		// if we are looping through the socket error condition and we are past the user's default session TTL, we need to unauthorize the user session
		if (this.lastTimeOnline !== EVENT_RESET && now - this.lastTimeOnline > DEFAULT_SESSION_TTL && this.clientContainerContextService.isBrowser()) {
			this.log.error(`We have passed the Session TTL, unauthorizing the user session ${new Date().toUTCString()}`);
			unAuthorizeUserSession(this.windowRef);
		}
	}

	private incomingPing(timestamp): void {
		const now = this.getCurrentTime();

		if (this.lastIncomingPing === EVENT_RESET) {
			this.lastIncomingPing = now;
		}

		if (now - this.lastIncomingPing > PING_CYCLE_SPAN) {
			this.log.error(
				`Potential Sleep Disruption detected. Sleep start: ${new Date(this.lastIncomingPing).toUTCString()}, Sleep end: ${new Date(
					now
				).toUTCString()}`
			);
		}

		this.lastIncomingPing = now;
	}

	private close(): void {
		if (this.lastTimeOnline === EVENT_RESET) {
			this.lastTimeOnline = this.getCurrentTime();
		}
	}

	private open(): void {
		this.lastTimeOnline = EVENT_RESET;
	}

	private isSuccess(status): any {
		const successCodeMin = 200;
		const successCodeMax = 300;

		return inRange(status, successCodeMin, successCodeMax);
	}

	private isTokenException(data): any {
		const body = data.body || data.data;

		return body && body.errorCode === 100 && body.exceptionClass === 'com.kronos.wfc.services.tokens.exception.TokenException';
	}

	private isSessionTokenError(data): any {
		const ERROR_CODE_FOR_INVALID_SESSION = 'WCO-115006';

		return data.errorCode === ERROR_CODE_FOR_INVALID_SESSION;
	}

	private isGatewayError(status): any {
		const gatewayErrorCodes = {
			badGateway: 502,
			gatewayTimeout: 504
		};

		return includes(gatewayErrorCodes, status);
	}

	private isRuntimeIsolationNodeDownError(status): boolean {
		const pageHeader = window.location.pathname.split('/')[1].replace(new RegExp('#', 'g'), '');

		return status === 502 && pageHeader && !!this.moduleMap[pageHeader];
	}

	private getCurrentTime = (): number => new Date().getTime();

	protected abstract promiseWrapper(execute: (resolve, reject) => void): Promise<any>;
}
