import {Observable, Subject} from 'rxjs';

import {OverrideUkgLanguageJson} from '../platform.interfaces';

export class OverrideUkgLanguageJsonCoreService {
    public  overrideUkgStringSource = new Subject<OverrideUkgLanguageJson>();

	private overrideJson;

	public getOverrideJson = (): Observable<OverrideUkgLanguageJson>  => this.overrideUkgStringSource;

    public overrideUkgLanguageJson = (overrideJson: OverrideUkgLanguageJson): void => {
        this.overrideJson = {...this.overrideJson, ...overrideJson};

        this.overrideUkgStringSource.next(this.overrideJson);
    };
}