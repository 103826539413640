import {includes} from 'lodash';

import {PropertyState, PropertyValues} from './property.interface';

import {Store} from '../platform/types/state';
import {loadFile} from './property.actions';

export abstract class PropertyStateCoreService {
	constructor(protected store: Store) {}

	public dispatchPropertyAction(file: string, properties: PropertyValues): void {
		this.store.dispatch(loadFile(file, properties));
	}

	public getProperty(property: string): string | undefined {
		return this.getPropertiesState().properties[property];
	}

	public isFileLoaded(file: string): boolean {
		return includes(this.getPropertiesState().loadedFiles, file);
	}

	public abstract getPropertiesState(): PropertyState;
}
