import {Inject, Injectable} from '@angular/core';
import {ActionReducerMap, ReducerManager, Store} from '@ngrx/store';

import {Bootstrappable} from '@kronos/zed/src/lib/services/bootstrap.service';
import {ReducerRegistryService} from '@kronos/zed/src/lib/services/reducer-registry.service';
import {StoreManagerService} from '@kronos/zed/src/lib/services/store-manager.service';

import {APP_CONFIG_ACTION_REDUCER_MAP} from '../app.token';

@Injectable()
export class NgrxService implements Bootstrappable {
	private storeManager;

	constructor(
		private reducerRegistry: ReducerRegistryService,
		private store: Store,
		private reducerManager: ReducerManager,
		@Inject(APP_CONFIG_ACTION_REDUCER_MAP) private actionReducerMap: ActionReducerMap<any, any>
	) {}

	public async bootstrap(): Promise<void> {
		this.reducerRegistry.register(this.actionReducerMap);
		this.storeManager = new StoreManagerService(this.reducerRegistry, this.store, this.reducerManager);
	}
}
