import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {find} from 'lodash';

import {WFD_HOME_URL} from '../../../../../core/iframe-framework/iframe-framework.constant';
import {MenuIntegrationService} from '../../../../../core/navBar/menu-integration.service';
import {MenuIntegrationParam} from '../../../../../core/navBar/models/menu-integration-param.model';
import {PropertyFactory} from '../../../../../core/property/property.factory';
import {PropertyFilterPipe} from '../../../../../providers/property/property.filter.pipe';
import {ExternalAuthService} from '../../../../../services/external-auth-service';
import {WindowRefService} from '../../../../../services/window-ref.service';
import {NavBarMenuService} from '../../nav-bar-menu.service';

export interface EINButtonOptions {
	id: string;
	text: string;
	title: string;
	uri: string;
	visible: boolean;
	expanded: boolean;
	postpendTransclude: boolean;
	btnClasses: string;
	ariaLabel: string;
	onClick: (event: Event) => void;
}

@Component({
	selector: 'krn-ng-menu-integration-param',
	templateUrl: './menu-integration-param.html'
})
export class MenuIntegrationParamComponent implements OnInit, OnChanges {
	@Input('service-id') public serviceId: string;
	@Input('integration-param') public integrationParam: string;
	@Input('integration-values') public integrationValues: any;
	@Input('menu-item-total') public menuItemTotal: number;
	@Input() public selected: MenuIntegrationParam;

	public propertiesLoaded: boolean;
	public buttonConfig: EINButtonOptions;
	public isCollapsed: boolean;
	public updatedParamValue: MenuIntegrationParam;

	public defaults: any;
	public componentData: any = {};
	public btnText: string;

	private INTERGRATION_ORDER: number;

	constructor(
		private window: WindowRefService,
		private menuIntegrationService: MenuIntegrationService,
		private propertyFactory: PropertyFactory,
		private propertyFilterPipe: PropertyFilterPipe,
		private externalAuthService: ExternalAuthService,
		private navBarMenuService: NavBarMenuService
	) {
		this.propertiesLoaded = false;
		this.isCollapsed = true;
		this.INTERGRATION_ORDER = 1;
	}

	public ngOnInit(): void {
		this.propertyFactory
			.loadProperties([
				{
					name: 'container_web-common_nav-bar_strings.properties',
					path: 'components/zed-core/properties/navBar/'
				}
			])
			.then(() => {
				this.propertiesLoaded = true;

				this.btnText = this.propertyFilterPipe.transform(`html.navbar.${this.integrationParam}.label`);
				this.buttonConfig = {
					id: `${this.integrationParam}ToggleButton`,
					text: this.btnText,
					title: '',
					uri: null,
					visible: true,
					expanded: !this.isCollapsed,
					postpendTransclude: true,
					btnClasses: 'btn delegateAuth info-menu',
					ariaLabel: '',
					onClick: this.toggleCollapsed.bind(this)
				};

				this.integrationValues = this.menuIntegrationService.putPrimaryOnTop(this.integrationValues);
			});
	}

	public ngOnChanges(change: SimpleChanges): void {
		if (this.buttonConfig && change.menuItemTotal.currentValue) {
			this.buttonConfig.ariaLabel = this.propertyFilterPipe.transform('html.navBar.profileMenu.settings.subMenu.ariaLabel', [this.btnText]);
		}
	}

	public toggleCollapsed(): void {
		this.isCollapsed = !this.isCollapsed;
		this.buttonConfig.expanded = !this.isCollapsed;
	}

	public switchParam(param: MenuIntegrationParam): void {
		this.selected = find(this.integrationValues, 'selected');
		if (this.selected && param.id === this.selected.id) {
			return;
		}
		this.updatedParamValue = param;
		this.proceedWithParamSwitch(param);
	}

	public trackFunc(index, item): any {
		return item.id;
	}

	public settingsMenuKeydown(event): void {
		this.navBarMenuService.menuArrowKeyCallback(this.isCollapsed, event, this.toggleCollapsed.bind(this));
	}

	public handleSubMenuKeyDown(event): void {
		this.navBarMenuService.handleSubMenuKeyDown(event);
	}

	private proceedWithParamSwitch(updatedParamValue: MenuIntegrationParam): void {
		this.menuIntegrationService
			.menuChange(this.integrationParam, updatedParamValue)
			.then(() =>
				this.window.nativeWindow.location.pathname === WFD_HOME_URL
					? this.window.nativeWindow.location.reload()
					: this.window.nativeWindow.location.assign('/')
			);

		this.externalAuthService.allowReloginToExternalService(this.serviceId);
	}
}
