import {defaultConfig} from './accessibility-speaker.constant';

export class ScreenReaderOnlyCtrlBase {
	protected ariaLive = 'polite';
	protected removeTimeout: number;

	private emptyContentTimeoutID;

	public watchContent(newText: string, emptyContent?: () => void): void {
		if (newText) {
			this.cancelEmptyContentTimeout();
			this.emptyContentTimeoutID = setTimeout(emptyContent, this.removeTimeout || defaultConfig.removeTimeout);
		}
	}

	public cancelEmptyContentTimeout(): void {
		clearTimeout(this.emptyContentTimeoutID);
	}
}
