import {AfterViewChecked, AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {ModalKeyboardBaseNavigation} from '../../core/platform/accessibility/modal-keyboard-navigation-base.directive';
import {SelectorService} from '../../core/platform/accessibility/selectors/selector.service';

const NO_OBSERVERS = 0;

@Directive({
	selector: '[krn-ng-modal-keyboard-navigation]'
})
export class ModalKeyboardNavigationDirective extends ModalKeyboardBaseNavigation implements AfterViewChecked, AfterViewInit, OnDestroy, OnInit {
	@Output() protected blurCallback = new EventEmitter();
	@Output() protected closeCallback = new EventEmitter();
	@Output() protected expandCallback = new EventEmitter();
	@Output() protected enterCallback = new EventEmitter();
	@Input() protected modalRole: string;
	@Input() protected noWrap: boolean;

	protected element: HTMLElement;
	protected hasBlurCallback: boolean;
	protected hasCloseCallback: boolean;
	protected hasEnterCallback: boolean;

	constructor(private elementRef: ElementRef<HTMLElement>, protected selectorService: SelectorService) {
		super();
		this.selectorService = selectorService;
		this.element = elementRef.nativeElement;
	}

	public ngAfterViewChecked(): void {
		this.loadNavigationServiceOnChanges();
	}

	public ngAfterViewInit(): void {
		this.loadNavigationServiceOnInit();
	}

	public ngOnDestroy(): void {
		this.clearNavigationService();
	}

	public ngOnInit(): void {
		this.hasBlurCallback = this.blurCallback.observers.length > NO_OBSERVERS;
		this.hasCloseCallback = this.closeCallback.observers.length > NO_OBSERVERS;
		this.hasEnterCallback = this.enterCallback.observers.length > NO_OBSERVERS;
	}
}
