import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, Subject, switchMap } from 'rxjs';
import {NGXLogger} from 'ngx-logger';

import { KioskAuth0TokenRefreshService } from './kiosk.auth0.token-refresh.service';

@Injectable()
export class KioskAuth0HTTPInterceptorService implements HttpInterceptor {

	private requestSubjectQueue: Array<Subject<HttpEvent<any>>> = [];
	private isQueueProcessing = false;

	constructor(private tokenRefreshService: KioskAuth0TokenRefreshService, private logger: NGXLogger) {
		this.tokenRefreshService.getTokenRefreshCompleted().subscribe(() => {
			this.processQueue();
		});

		this.tokenRefreshService.getTokenRefreshSubject().subscribe( () => {
			this.clearQueue();
		});
	}

	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if ((this.tokenRefreshService.getTokenRefreshInProgress() && !this.isAuth0ExtendRequest(request)) || this.isQueueProcessing) {
			//If token refresh is in progress, queue the request
			const requestSubject = new Subject<HttpEvent<any>>();

			this.requestSubjectQueue.push(requestSubject);
			this.logger.info('Incoming request has been queued. Token Refresh in progress.');

			return requestSubject.pipe(
				switchMap(() => next.handle(request))
			);
		}
		// If token refresh is not in progress, proceed with the request
		return next.handle(request);
	}

	private processQueue(): void {
		this.logger.info('Processing queued requests');
		this.isQueueProcessing = true;
		while (this.requestSubjectQueue.length > 0) {
			const requestSubject = this.requestSubjectQueue.shift();

			requestSubject.next(null);
			requestSubject.complete();
		}
		this.isQueueProcessing = false;
	}

	private clearQueue(): void {
		this.logger.info('Clearing queue');
		this.isQueueProcessing = false;
		this.requestSubjectQueue = [];
	}

	private isAuth0ExtendRequest(request: HttpRequest<any>): boolean {
		return request && request.url ? request.url.toLowerCase().includes('auth0extend') : false;
	}
}
