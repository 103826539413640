import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {PropertyFilterPipe} from '../../../providers/property/property.filter.pipe';
import {PropertyModule} from '../../../providers/property/property.module';
import {TooltipConfigProvider} from '../../../providers/tooltip-config.provider';
import {AttributesModule} from '../../../utils/attributes/attributes.module';
import {UserInfoServiceProvider} from '../../../providers/user-info-service.provider';
import {MenuButtonComponent} from './menu-button/menu-button.component';
import {MenuLinkComponent} from './menu-link/menu-link.component';

@NgModule({
	imports: [AttributesModule, CommonModule, PropertyModule, RouterModule, TooltipModule.forRoot()],
	exports: [MenuLinkComponent, MenuButtonComponent, PropertyModule, RouterModule, FormsModule],
	declarations: [MenuLinkComponent, MenuButtonComponent],
	providers: [PropertyFilterPipe, TooltipConfigProvider, UserInfoServiceProvider]
})
export class NavBarSharedModule {}
