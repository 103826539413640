import {CardLoaderManagerCoreService} from '../../../../core/cardLibrary/card-loader-manager.core.service';
import {WindowRefService} from '../../../../services/window-ref.service';

export function getCardLoaderManagerService(windowService: WindowRefService): any {
	return new CardLoaderManagerCoreService(windowService.nativeWindow);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CardLoaderManagerProvider = {
	provide: CardLoaderManagerCoreService,
	deps: [WindowRefService],
	useFactory: getCardLoaderManagerService
};
