import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {Observable} from 'rxjs';

import {UnsavedDataService} from '@kronos/zed/src/lib/services/unsaved-data-service';

@Injectable()
export class RouteGuard implements CanDeactivate<CanComponentDeactivate> {
	constructor(private unsavedDataService: UnsavedDataService) {}

	public canDeactivate(): // component: CanComponentDeactivate,
	// currentRoute: ActivatedRouteSnapshot,
	// currentState: RouterStateSnapshot,
	// nextState?: RouterStateSnapshot
	boolean | Observable<boolean> {
		if (this.unsavedDataService.isDirty) {
			this.unsavedDataService.showUnsavedDialog(this.unsavedDataService.unsavedModal, undefined, undefined, undefined, undefined);
			return this.unsavedDataService.redirect$;
		}
		return true;
	}
}

export interface CanComponentDeactivate {
	canDeactivate: () => boolean | Observable<boolean>;
}
