import * as jQuery from 'jquery';
import {filter, isEmpty, last} from 'lodash';

import {FocusElementId} from './services/keyboard-navigation.interfaces';

import {keys} from '../constants/keys.constant';
import {EVENTS_HANDLERS, FOCUSABLE_ELEMENTS} from './utils.constants';

const $: any = jQuery;

export class MoveShiftTabToBase {
	protected shiftTabToElementId: FocusElementId;
	protected keyDownElement: HTMLElement;

	protected initialize(): void {
		$(this.keyDownElement).on(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler);
	}

	protected keyDownHandler = (event: KeyboardEvent): void => {
		if (!isEmpty(this.shiftTabToElementId)) {
			const shiftElm = $(this.shiftTabToElementId);
			const focusElement = shiftElm.is(FOCUSABLE_ELEMENTS)
				? $(document).find(this.shiftTabToElementId)
				: last(filter(shiftElm.find(FOCUSABLE_ELEMENTS), element => !$(element).is(':hidden')));

			if (event.code === keys.TAB && event.shiftKey && focusElement) {
				event.preventDefault();
				event.stopPropagation();
				$(focusElement).trigger('focus');
			}
		}
	};

	protected destroy(): void {
		$(this.keyDownElement).off(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler);
	}
}
