import {assign, get} from 'lodash';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';
import {NewReleaseFeaturePreference, UserFeatures} from '../framework/services/user-info.interface';
import {HelpContext} from './contextual-help/contextual-help.interfaces';

import {UserInfoService} from '../framework/services/user-info.service';
import {SessionStorage} from '../platform/caching/sessionStorage.service';
import {getHelpContext} from './contextual-help/contextual-help.utils';

export class NewReleaseFeaturePreferenceService {
	public releaseFeaturePreference: NewReleaseFeaturePreference;

	constructor(protected sessionStorage: SessionStorage, protected userInfoService: UserInfoService, protected apiService: ApiService) {}

	public getNewReleaseFeaturePreference(): NewReleaseFeaturePreference {
		return this.releaseFeaturePreference;
	}

	public async showNewReleaseFeature(): Promise<NewReleaseFeaturePreference> {
		if (this.releaseFeaturePreference) {
			this.newPpopupFeatureShowed();
		} else {
			const newReleaseFeaturePreference: NewReleaseFeaturePreference = await this.getNewFeaturePreference();

			this.releaseFeaturePreference = this.setNewReleaseFeaturePreference(newReleaseFeaturePreference);
		}
		return this.releaseFeaturePreference;
	}

	public async saveUserNewReleaseFeaturePreference(doNotShow: boolean): Promise<void> {
			if (!doNotShow) {
				return;
			}

			await this.apiService.create(
				'new.release.feature.preference',
				{ data: { enabled: !doNotShow } },
				null,
				Transport.SOCKET
			);
		}

	public createNewFeatureContentURL(helpContext: HelpContext, releaseFeaturePreference: NewReleaseFeaturePreference): string {
		const HELP_CONTENT = 'Content';
		const RELEASE = 'R';
		const HTM_FILE_EXTENSION = '.htm';
		const MAJOR_RELEASE: string = releaseFeaturePreference.release.substr(0, 2);
		const pageName = [RELEASE, MAJOR_RELEASE, HTM_FILE_EXTENSION].join('');

		return encodeURI([helpContext.baseUrl, helpContext.lang, HELP_CONTENT, pageName].join('/'));
	}

	public async getNewFeaturePreference(): Promise<NewReleaseFeaturePreference> {
		const userFeatures: UserFeatures = await this.userInfoService.getUserFeaturesData();

		return userFeatures.newReleaseFeaturePreference;
	}

	public async popupNotAlreadyDisplayed(): Promise<boolean> {
		const newReleaseFeaturePreference = await this.getNewFeaturePreference();

		return !get(newReleaseFeaturePreference, 'hasAlertOpened');
	}
	public newPpopupFeatureShowed(): void {
		const alertOpenedValue = true;

		this.apiService.create(
			'new.release.feature.preference.session_state',
			{
				data: {
					hasAlertOpened: alertOpenedValue
				}
			},
			null,
			Transport.SOCKET
		);
	}
	public getContextualHelpContext(): Promise<HelpContext> {
		return getHelpContext(this.userInfoService, this.sessionStorage, this.apiService, null);
	}
	public setNewReleaseFeaturePreference(releaseFeaturePreference: NewReleaseFeaturePreference): NewReleaseFeaturePreference {
		return assign(this.releaseFeaturePreference, releaseFeaturePreference);
	}
}
