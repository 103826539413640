import * as jquery from 'jquery';
import {head, isFunction, isObject, isString} from 'lodash';

import {keyCodes} from '../../platform/constants/keys.constant';
import {FOCUS_EXCLUDE} from './slideout.constants';

const $: JQueryStatic = jquery;

const ZERO_INDEX = 0;
const AUTO_FOCUS_TIMEOUT = 250;
const RETURN_FOCUS_TIMEOUT = 500;
const innerModal = '.inner-modal';

interface KeyBoardOptions {
	handleESC: boolean;
	returnValue: boolean;
}

export function setCloseButtonFocus(autoFocus: boolean, element: JQuery, focusTarget?: JQuery): void {
	const targetFocus = focusTarget ? focusTarget : element.find('button.close-button');

	if (String(autoFocus) !== 'false') {
		setTimeout(() => {
			if (targetFocus.length > 1) {
				head(targetFocus).focus();
			} else {
				targetFocus.focus();
			}
		}, AUTO_FOCUS_TIMEOUT);
	}
}

export function setReturnFocusOnClose(returnFocus: boolean, returnFocusElement: JQuery | string): void {
	if (String(returnFocus) !== 'false') {
		if (returnFocusElement) {
			if (isObject(returnFocusElement)) {
				setTimeout(() => {
					returnFocusElement.focus();
				}, RETURN_FOCUS_TIMEOUT);
			} else if (isString(returnFocusElement)) {
				$(document).find(returnFocusElement).eq(0).focus();
			}
		}
	}
}

export function onDocumentTouchMove(event: any): void {
	const targetElement = $(event.target);

	if (
		$('krn-slide-out-container .inner-modal.visible').length > ZERO_INDEX &&
		!targetElement.hasClass(innerModal) &&
		!targetElement.parents(innerModal).length
	) {
		event.preventDefault();
		event.stopPropagation();
	}
}

export function handleKeyboardActions(event: any, element: JQuery, showSpinner: boolean): KeyBoardOptions {
	const options: KeyBoardOptions = {handleESC: false, returnValue: true};

	switch (event.keyCode) {
		case keyCodes.ESC:
			options.handleESC = true;
			break;
		case keyCodes.TAB:
			// Preventing focus from going outside of slideOutContainer
			if (!handleTAB(event, element, showSpinner)) {
				options.returnValue = false;
			}
			break;
		case keyCodes.ENTER:
			// Preventing form submit when combo opened
			handleENTER(event, element, showSpinner);
			break;
		default:
			break;
	}
	return options;
}

export function closeWithValidationCallback(validationCallback: () => Promise<any>): Promise<boolean> {
	if (isFunction(validationCallback)) {
		return validationCallback().then(function onValidationCallback(doClose) {
			return !doClose;
		});
	}
	return Promise.resolve(false);
}

export function closeSlideOut(
	innerModalObj: JQuery,
	slideOutMask: JQuery,
	withoutAnimation: boolean,
	onTransitionEnd: () => void,
	processRemoveSlideoutOnClose: () => void
): void {
	if (innerModalObj) {
		innerModalObj.off('keydown');
	}

	if (slideOutMask && slideOutMask.length) {
		slideOutMask.off('keydown');
		slideOutMask.off('touchmove');
	}

	if ($('krn-slide-out-container .inner-modal.visible').length === 1) {
		// Scheduling Edit Shift => Choose Template / Change Shift Labels slide panels
		$('.view-content').removeClass('no-webkit-scroll');
	}

	if (innerModalObj) {
		innerModalObj.removeClass('changed-position');
	}

	if (withoutAnimation) {
		processRemoveSlideoutOnClose();
	} else if (innerModalObj) {
		innerModalObj.one('transitionend', onTransitionEnd);
	}
}

export function handleTAB(event: any, element: JQuery, showSpinner: boolean): boolean {
	const tabbables = element.find(':tabbable');
	const firstTabbable = tabbables.filter(':first');
	const lastTabbable = tabbables.filter(':last');

	if (showSpinner || event.target === lastTabbable[ZERO_INDEX] && !event.shiftKey) {
		firstTabbable.focus();
		return false;
	} else if (event.target === firstTabbable[ZERO_INDEX] && event.shiftKey) {
		if (lastTabbable[ZERO_INDEX].nodeName === 'IFRAME') {
			lastTabbable.contents().find(':tabbable:last').focus();
		} else {
			lastTabbable.focus();
		}
		return false;
	}
	return true;
}

export function handleENTER(event: any, element: JQuery, showSpinner: boolean): void {
	const targetElement = element.find(event.target);
	const comboboxOpen = targetElement.is('a') && targetElement.parents().eq(3).attr('aria-expanded') === 'false';

	if (comboboxOpen || showSpinner) {
		event.preventDefault();
	}
}

export function setFocusToBottom(element: JQuery): void {
	const lastFocusableElementSelector = `:tabbable:not(${FOCUS_EXCLUDE}):last`;
	const lastFocusableElement = element.find(lastFocusableElementSelector);

	if (lastFocusableElement[ZERO_INDEX].nodeName === 'IFRAME') {
		lastFocusableElement.contents().find(lastFocusableElementSelector).focus();
	} else {
		lastFocusableElement.focus();
	}
}
