import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {cloneDeep, filter, get, indexOf, isEmpty} from 'lodash';
import {NGXLogger} from 'ngx-logger';
import {defaultIfEmpty, map, Observable, Subscription} from 'rxjs';

import {ApiService} from '../../../../core/communication/api-service/api-service.interfaces';
import {UserInfo} from '../../../../core/framework/services/user-info.interface';
import {BrandingImageVersion} from '../../../../core/platform/services/branding.interface';
import {SearchTermChange} from '../../../../core/search/search.interfaces';

import {UserInfoService} from '../../../../core/framework/services/user-info.service';
import {listenForExtendedMenu} from '../../../../core/navBar/nav-bar-external.utility';
import {NO_OFFSET_TOP} from '../../../../core/navBar/nav-bar.constants';
import {NavBarService} from '../../../../core/navBar/nav-bar.service';
import {keyCodes} from '../../../../core/platform/constants/keys.constant';
import {BrandingService} from '../../../../services/branding.service';
import {DataService} from '../../../../services/communication/data.service';
import {DeviceConfigService} from '../../../../services/device-config.service';
import {ReturnFocusElementDirective} from '../../../../utils/return-focus-element/return-focus-element.directive';
import {SearchComponent} from '../../../search/search.component';
import {ActionItemBaseComponent} from '../header/action-item/action-item-base.component';
import {navBarActions} from '../header/action-item/right-section-actions.helper';
import {NavBarMenuService} from '../nav-bar-menu.service';
import {navbarSelectorProvider} from './navbar-selector.provider';
import {NavbarActionService} from './navbar.action.service';
import {UserInfoActionService} from '../../../../services/user-info-action.service';
import {WindowRefService} from '../../../../services/window-ref.service';

const NAVBAR = 'navBar';

@Component({
	selector: 'krn-ng-navbar-container',
	templateUrl: './navbar.container.html',
	styleUrls: ['./navbar.container.less'],
	providers: [navbarSelectorProvider]
})
export class NavBarContainerComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
	@Input() public app: any;
	@Input() public styles?: object;
	@Input() public displayTitle: boolean;
	@Input() public visible: boolean;
	@Input() public rightMenuItems: ActionItemBaseComponent[];
	@Output() public navbarStateChange = new EventEmitter();
	@Output() public onMainMenuToggle = new EventEmitter();

	@ViewChild(SearchComponent)
	public searchComponent;
	@ViewChild('navMenuRef')
	public navMenu: ElementRef;

	@ViewChild('openMenuBtn', {read: ReturnFocusElementDirective, static: false})
	public returnFocusElementDirective: ReturnFocusElementDirective;

	public readonly userInfo$: Observable<UserInfo>;
	public readonly additionalData$: Observable<any>;
	public readonly homeUri$: Observable<string>;
	public readonly title$: Observable<string>;
	public readonly menuData$: Observable<any>;
	public readonly rightMenuItems$: Observable<ActionItemBaseComponent[]>;

	public logoVersion: string;
	public searchTerm = '';
	public isMainMenuOpen = false;
	public menuState: any;
	public isHome: boolean;
	public showPersistentBanner: boolean;

	private clearSearchSubscription: Subscription;
	private menuToggleSubscription: Subscription;
	private titleSubscription: Subscription;

	constructor(
		private logger: NGXLogger,
		private userInfoActionService: UserInfoActionService,
		private navBarService: NavBarService,
		private navBarMenuService: NavBarMenuService,
		private userDataService: UserInfoService,
		@Inject(DataService) protected dataService: ApiService,
		private titleService: Title,
		private deviceConfigService: DeviceConfigService,
		private brandingService: BrandingService,
		private navbarActionService: NavbarActionService,
		private windowRefService: WindowRefService
	) {
		this.userInfo$ = this.userInfoActionService.select(['userInfo']).pipe(map(userData => cloneDeep(userData)));
		this.additionalData$ = this.navbarActionService.select([NAVBAR, 'additionalData']);
		this.homeUri$ = this.navbarActionService.select([NAVBAR, 'home', 'href']);
		this.title$ = this.navbarActionService.select([NAVBAR, 'title']);
		this.menuData$ = this.navbarActionService
			.select([NAVBAR, 'menu'])
			.pipe(map(menu => cloneDeep(menu)));
		this.rightMenuItems$ = this.navbarActionService
			.select([NAVBAR, 'rightMenuItems'])
			.pipe(defaultIfEmpty([]), map(keys => filter(navBarActions, navBarAction => navBarAction.DEFAULT || indexOf(keys, navBarAction.actionItemName) >= 0)));
	}

	@HostListener('keydown', ['$event'])
	public handleKeyDown(event): void {
		if (this.isMainMenuOpen && event.keyCode === keyCodes.ESC) {
			this.onMainMenuToggled();
		}
	}

	public ngOnChanges(changes?: SimpleChanges): void {
		if (!isEmpty(this.styles)) {
			this.setNavStyle();
		}

		if (get(changes, 'visible')) {
			this.navbarActionService.dispatch(this.navbarActionService.actions.setVisible(this.visible));
		}

		if (get(changes, 'rightMenuItems')) {
			this.navbarActionService.dispatch(this.navbarActionService.actions.setRightMenuItems(this.rightMenuItems));
		}
	}

	public ngOnInit(): void {
		this.userDataService.clearLoggedOnUserData();
		this.userDataService.getLoggedOnUserData().then(this.setUserInfo);

		listenForExtendedMenu(this.dataService, this.logger, this.onExtendedMenu);

		this.menuToggleSubscription = this.navBarMenuService.menuToggle$().subscribe(this.onMainMenuToggled);

		this.titleSubscription = this.title$.subscribe(this.onTitleChange);

		this.clearSearchSubscription = this.navBarMenuService.clearSearch$().subscribe(this.onClearSearch);

		this.brandingService.getImageVersion().then(this.onGetBrandingVersion);

		// TODO: add dynamic page location for pathUrl param
		this.navBarService.init(this.app || 'falcon', '#/')(this.navbarActionService.dispatch);
		this.navbarActionService.select([NAVBAR]).subscribe(state => {
			this.navbarStateChange.emit(state);
		});
	}

	public ngOnDestroy(): void {
		this.clearSearchSubscription.unsubscribe();
		this.menuToggleSubscription.unsubscribe();
		this.titleSubscription.unsubscribe();
	}

	public ngAfterViewInit(): void {
		if (this.searchComponent) {
			this.searchTerm = this.searchComponent.searchTerm;
		}
	}

	public updateSearchTerm(newItem: SearchTermChange): void {
		this.searchTerm = newItem.searchTerm;
	}

	public onMainMenuToggled = (): void => {
		this.isMainMenuOpen = !this.isMainMenuOpen;
		this.navbarActionService.dispatch(this.navbarActionService.actions.menuToggled(this.isMainMenuOpen));
		if (this.isMainMenuOpen && !this.deviceConfigService.isShowMobileUIOnDesktop()) {
			this.navBarMenuService.scrollTo();
		}
		if (this.deviceConfigService.isShowMobileUIOnDesktop()) {
			$('.show-mobile-ui').css({'overflow-y': this.isMainMenuOpen ? 'hidden' : 'auto'});
		}
		this.setNavStyle();
		if (this.isMainMenuOpen) {
			this.windowRefService.nativeWindow.setTimeout(() => {
				this.windowRefService.nativeWindow.document.getElementById('closeMenuHeaderButton').focus();
			});
		}
		this.onMainMenuToggle.emit(this.isMainMenuOpen);
	};

	private onClearSearch = (): void => {
		this.searchTerm = '';
	};

	private onExtendedMenu = (tenantId?: any, personId?: any, extendedMenu?: any): void => {
		if (!isEmpty(extendedMenu)) {
			this.navbarActionService.dispatch(this.navbarActionService.actions.updateMenuData(extendedMenu));
		}
	};

	private onGetBrandingVersion = (version: BrandingImageVersion): void => {
		this.logoVersion = version.logoVersion;
	};

	private onTitleChange = (title: string): void => {
		if (title) {
			this.titleService.setTitle(title);
		}
	};

	private setUserInfo = (data: UserInfo): void => {
		this.userInfoActionService.dispatch(this.userInfoActionService.actions.userInfo(data));
	};

	private setNavStyle = (): void => {
		this.styles = {...this.styles, height: `calc(100vh - ${get(this.navMenu, 'nativeElement.offsetTop', NO_OFFSET_TOP)}px)`};
	};
}
