import {GeoLocation, LocationType, Position} from './geolocation.interfaces';

import {FINE_ACCURACY, ONE_MINUTE, ZERO_DELTA} from '../constants/geo.constants';

export {determineLocationType, fetchRelevantAttrs, isNewLocationBetter};

function determineLocationType(accuracy: number): LocationType {
	return accuracy <= FINE_ACCURACY ? LocationType.FINE_LOCATION_TYPE : LocationType.COARSE_LOCATION_TYPE;
}

function fetchRelevantAttrs(position: Position): GeoLocation {
	return {
		latitude: position.coords.latitude,
		longitude: position.coords.longitude,
		accuracy: position.coords.accuracy,
		timestamp: position.timestamp
	};
}

/**
 * Determines, whether the newly retrieved location is better than the current best location.
 *
 * @param {object} location - newly retrieved geolocation.
 *
 * @returns {boolean} true, if the newly retrieved geolocation is better than the current best one and false, otherwise.
 */
function isNewLocationBetter(location: GeoLocation, currentBestLocation?: GeoLocation): boolean {
	if (!currentBestLocation) {
		// A new location is always better than no location
		return true;
	}

	// Check whether the new location is newer or older
	const timeDelta = location.timestamp - currentBestLocation.timestamp;
	const isSignificantlyNewer = timeDelta > ONE_MINUTE;
	const isSignificantlyOlder = timeDelta < -ONE_MINUTE;
	const isNewer = timeDelta > ZERO_DELTA;

	if (isSignificantlyNewer) {
		return true;
	}

	if (isSignificantlyOlder) {
		return false;
	}

	// Check whether the new location is more or less accurate
	const accuracyDelta = location.accuracy - currentBestLocation.accuracy;
	const hasEqualAccuracy = accuracyDelta === ZERO_DELTA;
	const isMoreAccurate = accuracyDelta < ZERO_DELTA;

	return isMoreAccurate || (isNewer && hasEqualAccuracy);
}
