import * as jQuery from 'jquery';
import {findIndex, isEqual, isUndefined, noop} from 'lodash';

import {KeyNavService} from './keyboard-navigation.interfaces';

import {keys} from '../../constants/keys.constant';
import {toFocusableElementString, isFirstElementFocused, isLastElementFocused} from '../focusable-elements.utility';
import {EVENTS_HANDLERS} from '../utils.constants';

const $: any = jQuery;
const CALC_THRESHOLD = 500;
const FIRST = 0;
const ONE_ITEM = 1;
const NO_MATCH = -1;
const FOCUS_DELAY = 600;

export class ArrowsNavigationService implements KeyNavService {
	private calcIndexThreshold: number;
	private currentFocusIndex: number;
	private focusOnInitialize: boolean;
	private KEYDOWN_HANDLERS;

	constructor(
		private focusableElements: HTMLElement[],
		private focusableElementStrings: string[],
		private container: HTMLElement,
		private focus: boolean,
		private noWrap: boolean
	) {
		this.KEYDOWN_HANDLERS = {
			[keys.UP_ARROW]: this.onMoveUp,
			[keys.UP]: this.onMoveUp,
			[keys.LEFT_ARROW]: this.onMoveUp,
			[keys.LEFT]: this.onMoveUp,
			[keys.DOWN_ARROW]: this.onMoveDown,
			[keys.DOWN]: this.onMoveDown,
			[keys.RIGHT_ARROW]: this.onMoveDown,
			[keys.RIGHT]: this.onMoveDown
		};
		this.calcIndexThreshold = 0;
		this.focusOnInitialize = focus;
	}

	public initialize(): void {
		if (this.focusOnInitialize) {
			setTimeout(() => {
				if (this.findFocusIndex() === NO_MATCH) {
					this.currentFocusIndex = FIRST;
					this.setFocusable();
				}
			}, FOCUS_DELAY);
		}
		$(this.container).on(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler);
	}

	public destroy(): void {
		$(this.container).off(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler);
	}

	public getNode(index): any {
		return $(this.focusableElements[index]);
	}

	private calcCurrentFocusIndex(movingDown: boolean): number {
		const noMatchIndex = movingDown ? this.getElementsCount() - ONE_ITEM : FIRST;
		const index = this.findFocusIndex();

		return index === NO_MATCH ? noMatchIndex : index;
	}

	private findCurrentFocusIndex(movingDown: boolean): number {
		const newThreshold = Date.now();
		const pastThreshold = newThreshold - this.calcIndexThreshold > CALC_THRESHOLD;

		this.calcIndexThreshold = newThreshold;
		return pastThreshold || isUndefined(this.currentFocusIndex) ? this.calcCurrentFocusIndex(movingDown) : this.currentFocusIndex;
	}

	private findFocusIndex(): number {
		const focusElementString = toFocusableElementString(document.activeElement as HTMLElement);

		return findIndex(this.focusableElementStrings, element => isEqual(element, focusElementString));
	}

	private getElementsCount(): number {
		return this.focusableElements.length;
	}

	private keyDownHandler = (event: KeyboardEvent): void => {
		(this.KEYDOWN_HANDLERS[event.key] || noop)(event);
	};

	private onMoveDown = (event: KeyboardEvent): void => {
		event.preventDefault();
		event.stopPropagation();
		this.currentFocusIndex = this.findCurrentFocusIndex(true);
		if (!this.noWrap) {
			this.currentFocusIndex = isLastElementFocused(this.currentFocusIndex, this.getElementsCount()) ? FIRST : this.currentFocusIndex + ONE_ITEM;
		} else {
			this.currentFocusIndex = isLastElementFocused(this.currentFocusIndex, this.getElementsCount())
				? this.currentFocusIndex
				: this.currentFocusIndex + ONE_ITEM;
		}

		this.setFocusable();
	};

	private onMoveUp = (event: KeyboardEvent): void => {
		event.preventDefault();
		event.stopPropagation();
		this.currentFocusIndex = this.findCurrentFocusIndex(false);
		if (!this.noWrap) {
			this.currentFocusIndex = isFirstElementFocused(this.currentFocusIndex) ? this.getElementsCount() - ONE_ITEM : this.currentFocusIndex - ONE_ITEM;
		} else {
			this.currentFocusIndex = isFirstElementFocused(this.currentFocusIndex) ? this.currentFocusIndex : this.currentFocusIndex - ONE_ITEM;
		}

		this.setFocusable();
	};

	private setFocusable(): void {
		this.getNode(this.currentFocusIndex).trigger('focus');
	}
}
