import {Injectable} from '@angular/core';
import moment from 'moment';

import {DateServiceUtility} from '../../core/platform/converters/date-service.utility';
import {ConverterUtilService} from './converter-util.service';

@Injectable({
	providedIn: 'root',
	deps: [ConverterUtilService]
})
export class DateService extends DateServiceUtility {
	constructor(converterUtilService: ConverterUtilService) {
		super(converterUtilService, moment);
	}
}
