export enum OneAppProducts {
	DIMENSIONS = 'dimensions',
	PRO = 'pro',
	TALK = 'talk'
}

export enum OneAppAction {
	IS_UNSAVED_DATA_PRESENT = 'isUnsavedDataPresent',
	IS_UNSAVED_DATA_PRESENT_RESPONSE = 'isUnsavedDataPresentResponse',
	CONFIRMATION_DIALOG = 'confirmationDialog',
	CONFIRMATION_DIALOG_RESPONSE = 'confirmationDialogResponse',
	NAVIGATION_COMPLETE = 'navigationComplete',
	GET_MENU_ITEMS = 'getMenuItems',
	GET_MENU_ITEMS_RESPONSE = 'getMenuItemsResponse',
	AUTH_FLOW_DONE = 'AuthFlowDone',
	GO_MODAL = 'goModal',
	SET_GLOBAL_DATA = 'setGlobalData',
	GET_GLOBAL_DATA = 'getGlobalData',
	DELETE_GLOBAL_DATA = 'deleteGlobalData',
	GET_GLOBAL_DATA_RESPONSE = 'getGlobalDataResponse'
}

export const actionMap = {
	[OneAppAction.IS_UNSAVED_DATA_PRESENT]: OneAppAction.IS_UNSAVED_DATA_PRESENT_RESPONSE
};

export enum NavigationDirection {
	FORWARD = 'forward',
	BACK = 'back'
}

export const SENDER_NAME = 'web.dim';

export const POSITION_BUTTON = 'positive';

export const COMMON_BRANDING = 'common.branding';
export const COMMON_FEATURE_FLAGS = 'common.featureFlags';
