import {LocalStorageCoreService} from '../../personalization/local-storage.service';

export const ngStorage = 'ngStorage-';

export class DimLocalStorageService extends LocalStorageCoreService {
	protected localStorage;

	constructor(protected window: Window) {
		super();
		this.localStorage = this.window.localStorage;
	}

	public getItem(key: string): any {
		return JSON.parse(this.localStorage.getItem(ngStorage + key));
	}

	public setItem(key: string, value: any): void {
		this.localStorage.setItem(ngStorage + key, JSON.stringify(value));
	}

	public removeItem(key: string): void {
		this.localStorage.removeItem(ngStorage + key);
	}
}
