import {AfterViewChecked, AfterViewInit, Directive, ElementRef, Input, OnDestroy} from '@angular/core';

import {BaseReturnFocusElement} from '../../core/platform/accessibility/return-focus-element-base.directive';

@Directive({
	selector: '[krn-ng-return-focus-element]'
})
export class ReturnFocusElementDirective extends BaseReturnFocusElement implements AfterViewChecked, AfterViewInit, OnDestroy {
	@Input('krn-ng-return-focus-element') public focusElementId: string;
	@Input('return-focus-from') public focusFromElement: string;
	@Input('return-focus-on-destroy') public focusOnDestroy: boolean;
	@Input('return-focus-from-class') public focusFromClass: boolean;
	@Input('return-focus-delay') public focusDelay: number;

	constructor(protected elementRef: ElementRef<HTMLElement>) {
		super();
		this.element = elementRef.nativeElement;
	}

	public ngAfterViewInit(): void {
		this.initialize();
	}

	public ngAfterViewChecked(): void {
		this.onChecked();
	}

	public ngOnDestroy(): void {
		this.destroy();
	}
}
