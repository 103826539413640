import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Classes, ToggleAnimation, ToggleAttrs} from '../../core/toggle/toggle.interfaces';


import {CommonConstants} from '../../core/platform/constants/CommonConstants';
import {getPlacement} from '../../core/platform/form-element.utility';
import {
	getAnimateToggleState,
	getAriaDescribedBy,
	getAriaLabel,
	getAriaLabelledBy,
	getVerticalAlign,
	initAlignment,
	initClasses,
	initToggleLabelId
} from '../../core/toggle/toggle.utility';

@Component({
	selector: 'krn-ng-toggle',
	templateUrl: './toggle.component.html',
	styleUrls: ['./toggle.component.less']
})
export class ToggleComponent implements OnInit {
	@Input() public alignment: string;
	@Input() public attrs: ToggleAttrs;
	@Input() public classes: Classes;
	@Input() public toggleState = false;
	@Input() public label = '';

	@Output() public toggleStateChange = new EventEmitter<boolean>();

	public animateToggleState: ToggleAnimation;
	public placement: string;
	public toggleLabelId: string;

	public commonConstants = CommonConstants;
	public toggleAnimation = ToggleAnimation;

	public ariaDescribedByAttr(): string {
		return getAriaDescribedBy(this.attrs);
	}

	public ariaLabelAttr(): string {
		return getAriaLabel(this.attrs, this.label);
	}

	public ariaLabelledByAttr(): string {
		return getAriaLabelledBy(this.attrs, this.toggleLabelId);
	}

	public ngOnInit(): void {
		this.alignment = initAlignment(this.alignment);
		this.animateToggleState = ToggleAnimation.None;
		this.classes = initClasses(this.classes);
		this.placement = getPlacement(this.alignment);
		this.toggleLabelId = initToggleLabelId(this.attrs);
	}

	public triggerToggle(): void {
		this.toggleState = !this.toggleState;
		this.animateToggleState = getAnimateToggleState(this.toggleState);
		this.toggleStateChange.emit(this.toggleState);
	}

	public verticalAlignClass(): string {
		return getVerticalAlign(this.classes);
	}
}
