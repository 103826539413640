import {NGXLogger} from 'ngx-logger';

import {UserInfoService} from '../../../../../core/framework/services/user-info.service';
import {ManagerRoleService} from '../../../../../core/navBar/manager-role.service';
import {PreferencesCoreService} from '../../../../../core/platform/preferences/preferences.service';
import {propertyFilterInit} from '../../../../../core/property/property.filter';
import {ClientContainerService} from '../../../../../services/clientContainer/client-container.service';
import {DataService} from '../../../../../services/communication/data.service';
import {SessionStorageService} from '../../../../../services/session-storage.service';
import {LocalStorageService} from '../../../../../services/local-storage.service';

export function getManagerRoleService(logger, clientContainerService, sessionStorageService, localStorageService, preferencesService, userDataService, propertyFilter, apiService): any {
	return new ManagerRoleService(logger, clientContainerService, sessionStorageService, localStorageService, preferencesService, userDataService, propertyFilter, apiService);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ManagerRoleServiceProvider = {
	provide: ManagerRoleService,
	deps: [NGXLogger, ClientContainerService, SessionStorageService, LocalStorageService, PreferencesCoreService, UserInfoService, propertyFilterInit, DataService],
	useFactory: getManagerRoleService
};
