import {isEmpty} from 'lodash';

import {KioskConfig, KioskInstance, KioskOIDCLogoutInfo, KioskUserInfo} from '../kiosk.interfaces';
import {Transport} from '@kronos/zed/src/lib/core/communication/api-service/api-service.interfaces';

import {GET_PROPERTY_TIMESTAMP} from '@kronos/zed/src/lib/core/property/property.utility';
import {accessibilitySpeakerRead} from '@kronos/zed/src/lib/services/accessibility/accessibility-speaker.utility';
import {LocalStorageService} from '@kronos/zed/src/lib/services/local-storage.service';
import {SessionStorageService} from '@kronos/zed/src/lib/services/session-storage.service';

import {
	GET_PROPERTY_PREFERENCES,
	KIOSK_ADMIN_LOGOUT,
	KIOSK_CLEAR_IMPERSONATION_COOKIES,
	KIOSK_CONFIG,
	KIOSK_CONFIG_API_URL,
	KIOSK_DEACTIVATE_URL,
	KIOSK_EXTEND_URL,
	KIOSK_INSTANCE_COPY_API_URL,
	KIOSK_OIDC_LOGOUT_URL,
	KIOSK_RESTORE_URL,
	KIOSK_START_URL,
	KIOSK_USER_INSTANCE_API_URL,
	KIOSK_USERBYBADGE_API_URL,
	POSITION,
	SSO_PORTAL_URL,
	KIOSK_ACTIONS,
	KIOSK_TIMEOUT_ERROR_STATUS_CODE,
	KIOSK_TIMEOUT_ERROR_CODE
} from '../constants';

export function parseQueryStringToObject(queryString): any {
	const reg = /\??(.*?)=([^&]*)&?/gi;
	const ret = {};

	for (;;) {
		const match = reg.exec(queryString);

		if (!match) {
			break;
		}
		ret[match[1]] = match[2];
	}
	return ret;
}

export function getKioskInstances(apiService): Promise<KioskInstance[]> {
	return apiService.get(KIOSK_USER_INSTANCE_API_URL);
}

export function getKioskConfig(apiService, id): Promise<KioskConfig> {
	return apiService.get(KIOSK_CONFIG_API_URL, null, {id});
}

export function createAuth0OIDCLogout(apiService, vanity_url, clearSessionTokenCookie): Promise<KioskOIDCLogoutInfo> {
	return apiService.create(KIOSK_OIDC_LOGOUT_URL, {vanity_url}, {clearSessionTokenCookie});
}

export function clearImpersonationCookies(apiService, clearSessionTokenCookie: boolean): Promise<void> {
	return apiService.create(KIOSK_CLEAR_IMPERSONATION_COOKIES, {clearSessionTokenCookie});
}

export function duplicateKiosk(apiService, id): Promise<KioskInstance> {
	return apiService.create(KIOSK_INSTANCE_COPY_API_URL, {id});
}

export function startKiosk(apiService, id): Promise<any> {
	return apiService.create(KIOSK_START_URL, {kioskInstanceId: id});
}

export function restoreKiosk(apiService, id: number): Promise<any> {
	return apiService.create(KIOSK_RESTORE_URL, {id});
}

export function deactivateKiosk(apiService, id: number): Promise<any> {
	return apiService.create(KIOSK_DEACTIVATE_URL, {id});
}

export function extendAuth0(apiService): Promise<any> {
	return apiService.create(KIOSK_EXTEND_URL);
}

export function logout(apiService, action?: number): Promise<any> {
	const headers = action === KIOSK_ACTIONS.STOP ? {'kiosk-clear-session-token': 'true'} : undefined;

	return apiService.get(KIOSK_ADMIN_LOGOUT, undefined, {headers}, Transport.REST);
}

export function getUserInfoByBadge(apiService, badgeId): Promise<KioskUserInfo> {
	return apiService.get(KIOSK_USERBYBADGE_API_URL, null, {badgeId});
}

function loadKioskProperties(propertyFactory: any, filename: string): Promise<any> {
	return propertyFactory.loadProperties([
		{
			name: filename,
			path: 'apps/kiosk/properties/'
		}
	]);
}

export function loadKioskAdminProperties(propertyFactory: any): Promise<any> {
	return loadKioskProperties(propertyFactory, 'container_web-common_kiosk-admin_strings.properties');
}

export function loadKioskUserProperties(propertyFactory: any): Promise<any> {
	return loadKioskProperties(propertyFactory, 'container_web-common_kiosk-user_strings.properties');
}

export function loadKioskFederatedProperties(propertyFactory: any): Promise<any> {
	return loadKioskProperties(propertyFactory, 'container_web-common_kiosk-federated_strings.properties');
}

export function loadKioskEllipsisMenuProperties(propertyFactory: any): Promise<any> {
	return loadKioskProperties(propertyFactory, 'container_web-common_kiosk-ellipsis-menu_strings.properties');
}

export function isBadgeNonNumeric(badgeId: string, badgeLoginEnabled: boolean): boolean {
	return !/^\d+$/.test(badgeId) && badgeLoginEnabled;
}

export function isBadgeSubmitDisabled(badgeId: string, badgeLoginEnabled: boolean): boolean {
	return badgeLoginEnabled && isEmpty(badgeId);
}

export function isUsernameSubmitDisabled(username: string, password: string, badgeLoginEnabled: boolean): boolean {
	return !badgeLoginEnabled && (isEmpty(username) || isEmpty(password));
}

export function establishSsoConnection(document, apiService): Promise<boolean> {
	if (!document.cookie.includes('ssologin')) {
		return apiService
			.get(SSO_PORTAL_URL)
			.then(() => true)
			.catch(error => error.status === 200);
	}
	return Promise.resolve(true);
}

export function getGeoLocation(body): Promise<void> {
	return new Promise<void>(resolve => {
		if (!navigator.geolocation || !navigator.geolocation.getCurrentPosition) {
			resolve();
		}
		navigator.geolocation.getCurrentPosition(
			position => {
				body[POSITION.LONGITUDE] = position.coords.longitude;
				body[POSITION.LATITUDE] = position.coords.latitude;
				body[POSITION.ACCURACY] = position.coords.accuracy;
				return resolve();
			},
			() => resolve(),
			{
				enableHighAccuracy: false,
				timeout: 5000
			}
		);
	});
}

export function setPageTitle(title: string): void {
	document.title = title;
	accessibilitySpeakerRead(title, {readTimeout: 300});
}

export function setLocalStorageData(sessionStorageService: SessionStorageService, localStorageService: LocalStorageService): void {
	localStorageService.set(GET_PROPERTY_PREFERENCES, sessionStorageService.get(GET_PROPERTY_PREFERENCES));
	localStorageService.set(GET_PROPERTY_TIMESTAMP, sessionStorageService.get(GET_PROPERTY_TIMESTAMP));
}

export function setSessionStorageData(sessionStorageService: SessionStorageService, localStorageService: LocalStorageService): void {
	if (localStorageService.get(GET_PROPERTY_PREFERENCES) && !sessionStorageService.get(GET_PROPERTY_PREFERENCES)) {
		sessionStorageService.set(GET_PROPERTY_PREFERENCES, localStorageService.get(GET_PROPERTY_PREFERENCES));
	}
	if (localStorageService.get(GET_PROPERTY_TIMESTAMP) && !sessionStorageService.get(GET_PROPERTY_TIMESTAMP)) {
		sessionStorageService.set(GET_PROPERTY_TIMESTAMP, localStorageService.get(GET_PROPERTY_TIMESTAMP));
	}
}

export function isAuth0KioskRunning(localStorageService: LocalStorageService): boolean {
	const kioskConfig = localStorageService.getItem(KIOSK_CONFIG) || {};

	return !!kioskConfig.authZero;
}

export function isTimeoutError(error: any): boolean {
	return error?.status === KIOSK_TIMEOUT_ERROR_STATUS_CODE && error?.error?.errorCode === KIOSK_TIMEOUT_ERROR_CODE;
}
