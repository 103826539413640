import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {Observable} from 'rxjs';
import {get} from 'lodash';

import {AuthenticationState} from '../../../core/authentication/authentication.state';
import {authenticationAction} from '../../../core/authentication/authentication.actions';
import {AUTHENTICATION_DEFAULT_STATE, authenticateReduce, logoutReduce} from '../../../core/authentication/authentication.reducer';

@Injectable({providedIn: 'root'})
export class AuthenticationStore extends ComponentStore<AuthenticationState> {
	constructor() {
		super(AUTHENTICATION_DEFAULT_STATE);
	}

	public selector<Result>(path: string): Observable<Result> {
		return this.select(state => get(state, path));
	}

	public updateAuthenticateState = (error?): void => {
		const errorInstance = {message: error.message} as unknown as Partial<AuthenticationState>;
		const payload = error ? errorInstance : authenticationAction(false);

		this.updater<AuthenticationState>((state, value): AuthenticationState => authenticateReduce(state, {payload, error: !!error}))(error);
	};

	public updateLogoutState(): void {
		this.updater<Partial<AuthenticationState>>((state, value): AuthenticationState => logoutReduce(state))({isAuthenticated: false});
	}
}
