import {get} from 'lodash';

import {ErrorCallback, GeoContext, Position, SuccessCallback} from './geolocation.interfaces';

import {isSMA} from '../../embedded/embedded-page.utility';
import {getWindowInstance} from '../../iframe-framework/iframe-message-handler.utility';
import {DESIRED_ACCURACY, FINE_ACCURACY, INITIAL_TIMEOUT, RETRIEVAL_OPTIONS, TRACKING_TIMEOUT} from '../constants/geo.constants';
import {SessionStorage} from '../caching/sessionStorage.service';
import {getGeoFromSMA} from './geolocation.sma.utility';
import {determineLocationType, fetchRelevantAttrs, isNewLocationBetter} from './geolocation.utility';

export class GeolocationCoreService {
	private context: GeoContext;
	private isErrorCallbackCalled: boolean;
	private win: any;

	constructor(protected $window: Window, protected $timeout = null, private sessionStorage: SessionStorage) {}

	public retrieveGeolocation(successCallback: SuccessCallback, errorCallback: ErrorCallback): void {
		const startTimeout = this.$timeout || setTimeout;

		this.context = {};
		this.win = getWindowInstance(this.$window);
		this.isErrorCallbackCalled = false;

		if (!successCallback) {
			throw new Error('Success callback must be specified.');
		}

		this.context.successCallback = successCallback;
		this.context.errorCallback = errorCallback;

		if (isSMA(this.sessionStorage)) {
			// this is for exchanging message with pro app using top layer Dimension window
			// since pro app only communicate with top layer Dimension window.
			let windowForMessage: Window;

			try {
				windowForMessage = this.$window.parent.location.origin === this.$window.location.origin ? this.$window.parent : this.$window;
			} catch {
				windowForMessage = this.$window;
			}
			getGeoFromSMA(windowForMessage, this.context.successCallback, this.context.errorCallback);
			windowForMessage.dispatchEvent(new CustomEvent('upPostMessage', {detail: {method: 'getGeoCoordinates'}}));
		} else {
			this.context.watch = null;
			this.context.initialTimeout = null;
			this.context.timeout = null;
			this.context.currentBestLocation = null;

			// flag, which indicates, whether a geolocation with desired accuracy can be immediately accepted
			this.context.canAccept = false;

			// Starts geolocation tracking process and calls successCallback, that is provided as the constructor parameter,
			// once geolocation with desired accuracy is found. In case of an error, the errorCallback will be called.
			if (!get(this.win, 'navigator.geolocation')) {
				return errorCallback('Geolocation cannot be retrieved');
			}

			// FLC-56810 calling for this key is the fix for bug found in GPS timing issues.
			// This setting allows and admin to decrease the accuracy of the GPS signal given so users with a poor signal can
			// still submit a punch.
			this.context.desiredAccuracy = DESIRED_ACCURACY;
			this.context.watch = this.win.navigator.geolocation.watchPosition(
				this.positionSuccess.bind(this),
				this.positionError.bind(this),
				RETRIEVAL_OPTIONS
			);

			// Start returning geolocation at least after 3 seconds after the retrieval is requested.
			// It is done to prevent return of geolocations with coarse accuracy, which are normally returned within
			// the first second. If no location with desired accuracy is retrieved within the first 3 seconds,
			// set the flag to allow acceptance of any geolocation with the desired accuracy.
			this.context.initialTimeout = startTimeout(this.checkCurrentBestLocation.bind(this), INITIAL_TIMEOUT);

			// Set a timeout in case the geolocation accuracy never meets the desired value.
			this.context.timeout = startTimeout(this.returnCurrentBestLocation.bind(this), TRACKING_TIMEOUT);
		}
	}

	private acceptGeolocation(): any {
		if (this.context.currentBestLocation) {
			this.prepareForReturn();
			this.context.successCallback(this.context.currentBestLocation);
		} else {
			this.positionError();
		}
	}

	private checkCurrentBestLocation(): void {
		// Prevent processing, if timeout has already been cancelled.
		// Such a situation can take place on iOS, presumably because of the call of timeout handler,
		// that was placed to the JS event queue, but not valid any more, because success callback
		// for the watchPosition accepted the provided geolocation a bit earlier and cancelled the timeout.
		if (!this.context.initialTimeout) {
			return;
		}

		if (this.context.currentBestLocation && this.context.currentBestLocation.accuracy <= this.context.desiredAccuracy) {
			this.clearState();
			this.acceptGeolocation();
		} else {
			this.context.canAccept = true;

			// set to null to prevent reset timeout
			this.context.initialTimeout = null;
		}
	}

	private clearState(): void {
		const cancelTimeout = get(this.$timeout, 'cancel') || clearTimeout;

		if (this.context.watch !== null) {
			this.win.navigator.geolocation.clearWatch(this.context.watch);
			this.context.watch = null;
		}

		if (this.context.timeout !== null) {
			cancelTimeout(this.context.timeout);
			this.context.timeout = null;
		}

		if (this.context.initialTimeout !== null) {
			cancelTimeout(this.context.initialTimeout);
			this.context.initialTimeout = null;
		}
	}

	private positionError(error?: string | Error): void {
		if (!this.isErrorCallbackCalled) {
			this.clearState();
			this.context.errorCallback(error);
			this.isErrorCallbackCalled = true;
		}
	}

	private positionSuccess(position: Position): void {
		// Prevent processing, if watch has already been canceled and set to null.
		// Such situation takes place on iOS, presumably because there are still calls to this callback,
		// which were placed to the JS event queue, before the watch has been canceled.
		if (this.context.watch === null) {
			return;
		}

		const location = fetchRelevantAttrs(position);

		if (isNewLocationBetter(location, this.context.currentBestLocation)) {
			this.context.currentBestLocation = location;
		}

		// Allow acceptance of geolocation with the minimum desired accuracy after initial timeout only.
		// The only exception is, if a geolocation with a fine accuracy is returned.
		if ((location.accuracy <= this.context.desiredAccuracy && this.context.canAccept) || location.accuracy <= FINE_ACCURACY) {
			this.clearState();
			this.acceptGeolocation();
		}
	}

	private prepareForReturn(): void {
		// add location type
		this.context.currentBestLocation.locationType = determineLocationType(this.context.currentBestLocation.accuracy);

		// timestamp is used to find the best geolocation from the list of the tracked ones
		// and is not required to be returned
		delete this.context.currentBestLocation.timestamp;
	}

	private returnCurrentBestLocation(): void {
		// Prevent processing, if timeout has already been cancelled.
		// Such a situation can take place on iOS, presumably because of the call of timeout handler,
		// that was placed to the JS event queue, but not valid any more, because success callback
		// for the watchPosition accepted the provided geolocation a bit earlier and cancelled the timeout.
		if (!this.context.timeout) {
			return;
		}

		this.clearState();
		this.acceptGeolocation();
	}
}
