import * as moment from 'moment';
import {Duration} from 'moment';

import {setSessionStatus} from './session.actions';
import {SESSION_EVENTS} from './session.constants';

export abstract class SessionTimeoutCoreController {
	public gracePeriod: number;
	public extendable: boolean;
	public onClose: () => void;
	public onDismiss: () => void;
	public duration: Duration;
	public timeLeft: string[];
	public currentTimeLeft: string[];
	public onCountdownOver: () => void;

	private interval: any;
	private readonly INCREMENT_INTERVAL = 1000;

	protected constructor(protected dispatch) {
	}

	public init(): void {
		this.startTimer(this.gracePeriod);
	}

	public confirm(): void {
		this.dispatch(this.setSessionStatus(SESSION_EVENTS.RESET));
		this.cancelInterval();
		if (this.onClose) {
			this.onClose();
		}
	}

	public decline(): void {
		this.dispatch(this.setSessionStatus(SESSION_EVENTS.ENDED));
		this.cancelInterval();
		if (this.onDismiss) {
			this.onDismiss();
		}
	}

	public cancelInterval(): void {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	protected setSessionStatus = (eventName: string): any => setSessionStatus(eventName);

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected applyFormat(): any {}

	private startTimer(seconds: number): void {
		this.duration = (moment as any).duration(seconds, 'seconds');
		this.decrementTimer();
		this.interval = setInterval(() => this.decrementTimer(), this.INCREMENT_INTERVAL);
	}

	private formatTimeLeft(): void {
		this.timeLeft = [(moment as any).utc(this.duration.asMilliseconds()).format('mm:ss')];
		this.applyFormat();
		if (this.currentTimeLeft === undefined) {
			this.currentTimeLeft = this.timeLeft;
		}
	}

	private decrementTimer(): void {
		this.duration.subtract(this.INCREMENT_INTERVAL);
		this.formatTimeLeft();

		if (this.duration.asMilliseconds() <= 0) {
			if (this.onCountdownOver) {
				this.onCountdownOver();
			} else {
				this.decline();
			}
		}
	}
}
