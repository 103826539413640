import {Component, OnDestroy, OnInit} from '@angular/core';
import {get} from 'lodash';

import {HelpRowData} from '../../../../../../core/navBar/contextual-help/contextual-help.interfaces';

import {ContextualHelpService} from '../contextual-help/contextual-help.service';

@Component({
	selector: 'krn-ng-contextual-help-search-result-list',
	templateUrl: './contextual-help-search-result-list.component.html',
	styleUrls: ['./contextual-help-search-result-list.component.less']
})
export class ContextualHelpSearchResultListComponent implements OnInit, OnDestroy {
	public rowData: HelpRowData[];
	public hasMoreDataToLoad = false;
	public loading: boolean;
	public loadingMore = false;
	public subscription;
	public searchQueryUid: string;

	constructor(private contextualHelpService: ContextualHelpService) {}

	public ngOnInit(): void {
		this.loading = true;
		this.subscription = this.contextualHelpService.subscribeToStore(this.subscribeSearchResultChange);
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public loadMore(): void {
		this.contextualHelpService.loadMore();
	}

	public clickTitle(rowDataItem: HelpRowData, index: number): void {
		window.open(rowDataItem.href, '_blank');

		const ONE = 1;
		const payload = {
			documentTitle: rowDataItem.originalTitle,
			documentUri: rowDataItem.href,
			documentUriHash: rowDataItem.documentUriHash,
			documentPosition: index + ONE,
			sourceName: rowDataItem.objName,
			searchQueryUid: this.searchQueryUid
		};

		this.contextualHelpService.clickTitle(payload);
	}

	public subscribeSearchResultChange = (state): void => {
		this.loadingMore = state.loadingMore;
		this.loading = state.loading;

		if (this.loading || this.loadingMore) {
			return;
		}

		this.rowData = get(state.searchResult, 'results.hits', []);
		this.hasMoreDataToLoad = this.rowData.length < get(state.searchResult, 'results.total');
		this.searchQueryUid = get(state.searchResult, 'searchQueryUid', '');
	};
}
