import {cloneDeep, find, forEach, get, isArray, isEmpty, isUndefined, map, set} from 'lodash';

import {BaseMessageModel, KrnMessageDetailsModel, KrnMessageGroupModel, KrnMessageInpageModel} from './message.interface';

import {PropertyFactory} from '../../property/property.factory';
import {DEBOUNCE_TIMEOUT, MESSAGE_ORDER, MESSAGE_TITLES, MESSAGE_TYPES, ONE_MESSAGE} from './message.constant';

export {
	findFirstClose,
	getCloseSettingForType,
	getIconForType,
	getModelWrapper,
	getMessageForType,
	getSRMessage,
	isSafeHtml,
	loadMessageProperties,
	setElementFocus,
	sortMessagesByTheme
};

function isSafeHtml(messageText: string): boolean {
	const re = /<\/*?([^>]*?\b(?:a|script|style|href|form)\b)[^>]*?>/;

	return !re.exec(messageText || '');
}

function loadMessageProperties(propertyFactory: PropertyFactory): Promise<any> {
	return propertyFactory.loadProperties([
		{
			name: 'container_web-common_message_strings.properties',
			path: 'components/zed-core/properties/generic/message/'
		}
	]);
}

function findFirstClose(models: KrnMessageGroupModel[]): string {
	return get(find(models, ['options.showCloseButton', true]), 'messageType');
}

function getIconForType(type: string): string {
	return `/images/${type}.svg`;
}

function getCloseSettingForType(setting: boolean, type: string): boolean {
	return isUndefined(setting) ? type === MESSAGE_TYPES.INFO || type === MESSAGE_TYPES.SUCCESS : setting;
}

function getMessageForType(type: string, numMsgs: number): string {
	return `${MESSAGE_TITLES[type]}${numMsgs > ONE_MESSAGE ? '.plural' : ''}`;
}

function getModelWrapper(inpageModel: KrnMessageInpageModel | KrnMessageInpageModel[]): KrnMessageInpageModel[] {
	const model = cloneDeep(inpageModel);
	const modelWrapper = isArray(model) ? model : [model];

	return map(modelWrapper, (item: KrnMessageInpageModel): KrnMessageInpageModel => {
		if (item) {
			set(item, 'messagemodel.isSafeHtml', isSafeHtml(get(item, 'messagemodel.messageText')));
		}
		return item;
	});
}

function sortMessagesByTheme(model: KrnMessageDetailsModel[]): KrnMessageGroupModel[] {
	const msgs = [
		{messages: [], messageType: MESSAGE_TYPES.ERROR},
		{messages: [], messageType: MESSAGE_TYPES.WARNING},
		{messages: [], messageType: MESSAGE_TYPES.SUCCESS},
		{messages: [], messageType: MESSAGE_TYPES.INFO}
	];

	forEach(model, (msg: KrnMessageDetailsModel): void => {
		const msgGroup: KrnMessageGroupModel = msgs[getIndex(msg)];

		msgGroup.messages.push(msg.message);
		if (!msgGroup.options) {
			msgGroup.options = msg.options;
		}
	});
	return msgs.filter(group => !isEmpty(group.messages));
}

function getIndex(msg: KrnMessageDetailsModel): number {
	return MESSAGE_ORDER[get(msg, 'messageType')] || 0;
}

function getSRMessage(groupHeader: string, messages: BaseMessageModel[]): string {
	let message = `${groupHeader}: `;

	forEach(messages, item => {
		const headerText = get(item, 'headerText');
		const messageText = get(item, 'messageText');

		if (headerText && messageText) {
			message += `${headerText}: `;
		}

		if (messageText) {
			message += `${messageText}\n`;
		}
	});

	return message;
}

function setElementFocus(focus: boolean, element: HTMLElement): void {
	if (focus && element) {
		setTimeout(() => element.focus(), DEBOUNCE_TIMEOUT * 2);
	}
}
