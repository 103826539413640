import {ClientContext} from './client-container.interface';

import {BrowserContainerCoreService} from './browser-container.core.service';
import {EmbeddedSessionContainerCoreService} from './embedded-session-container.core.service';
import {KioskContainerCoreService} from './kiosk-container.core.service';
import {WfdMobileContainerCoreService} from './wfd-mobile-container.core.service';
import {OneAppSessionContainerCoreService} from './one-app-session-container.core.service';
import {ProEmbeddedContainerCoreService} from './pro-embedded-container.core.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ClientServiceMap: {[key in ClientContext]: object} = {
    [ClientContext.BROWSER]: BrowserContainerCoreService,
    [ClientContext.WFD_MOBILE]: WfdMobileContainerCoreService,
    [ClientContext.EMBEDDED_SESSION]: EmbeddedSessionContainerCoreService,
    [ClientContext.KIOSK]: KioskContainerCoreService,
    [ClientContext.ONE_APP]: OneAppSessionContainerCoreService,
    [ClientContext.PRO_EMBEDDED]: ProEmbeddedContainerCoreService
};