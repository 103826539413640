import {find, forEach, keys, size} from 'lodash';

import {ExternalAuthCoreService} from '../authentication/external.auth.core.service';
import {SessionStorageCoreService} from '../platform/caching/sessionStorage.service';
import {EXTERNAL_SERVICE_IDENTIFIERS} from './iframe-framework.constant';

export class ExternalIdentifierCoreService {
	private serviceIdentifierCallbacks: any = {};

	constructor(private sessionStorageService: SessionStorageCoreService, private externalAuthService: ExternalAuthCoreService) {}

	public getServiceIdentifier = (serviceId: string, callback: (data: any) => void): void => {
		const allServiceIdentifiers = this.sessionStorageService.get(EXTERNAL_SERVICE_IDENTIFIERS);
		const selectedService = find(allServiceIdentifiers, {serviceId});

		if (selectedService && selectedService.url) {
			callback(selectedService);
		} else {
			if (!size(keys(this.serviceIdentifierCallbacks))) {
				this.getAllServiceIdentifier()
					.then(data => {
						forEach(data, service => {
							forEach(this.serviceIdentifierCallbacks[service.serviceId], cbFunction => {
								cbFunction(service);
							});
						});
						this.serviceIdentifierCallbacks = {};
					});
			}
			if (!this.serviceIdentifierCallbacks[serviceId]) {
				this.serviceIdentifierCallbacks[serviceId] = [];
			}
			this.serviceIdentifierCallbacks[serviceId].push(callback);
		}
	};

	public getAllServiceIdentifier = (): Promise<any> => this.externalAuthService.getExternalServices();
}