<krn-slide-out-container [actionHandler]="actionHandler" class="contextual-help-panel" (onLoad)="onSlideOutLoad()">
	<div class="slideout-container-content">
		<div class="slideout__header slideout-header__wrapper">
			<h2 id="form-title" class="slideout-header-title">{{ 'html.navbar.contextualHelp.panelTitle' | property }}</h2>
		</div>
		<div *ngIf="inPageMessages" automation-id="infoMsgContainer">
			<krn-ng-message-inpage 
				[model]="inPageMessages" 
				(onMessageClosed)="onCloseInfoMsg()"
				[setFocus]="true"
				krn-ng-return-focus-element="help-search-box"
				[return-focus-on-destroy]="false"
				messagesIdSuffix="help-info"
				#helpInfoMsg>
			</krn-ng-message-inpage>
		</div>
		<div class="slideout__content contextual-help-content" [ngClass]="{'show-help-content': !communityHelpBeingSearched}">
			<div class="search-content" *ngIf="allowedByFAP">
				<div class="search-area-container">
					<div class="row legal-text">
						<span>
							{{ 'html.navbar.contextualHelp.legal' | property }}
							<a target="_blank" href="https://community.kronos.com/s/terms-of-use"> {{ 'html.navbar.contextualHelp.legal.terms' | property }} </a>
						</span>
					</div>
					<div>
						<krn-ng-search
							[submitOnChange]="false"
							[label]="'html.navbar.contextualHelp.searchPlaceholder' | property"
							[classes]="validationMessage ? 'invalid' : ''"
							[attrs]="{
								id: 'help-search-box',
								placeholder: ' ',
								title: 'html.navbar.contextualHelp.searchTitle' | property,
								'aria-label': 'html.navbar.contextualHelp.searchPlaceholder' | property
							}"
							(onSearchTermChange)="onSearchTermChange($event)"
							(updatedSearchTerm)="triggerSearch($event)">
						</krn-ng-search>
						<krn-ng-inline-validation [condition]="validationMessage" [message]="validationMessage?.message.messageText" [messageAsKey]="false">
						</krn-ng-inline-validation>
					</div>
				</div>
			</div>
			<div class="community-search" *ngIf="communityHelpBeingSearched">
				<krn-ng-contextual-help-search-result
					#searchResult
					(setContentBeingRefined)="setContentBeingRefined($event)"
					(toggleCommunitySearch)="toggleCommunitySearch()"></krn-ng-contextual-help-search-result>
			</div>
			<div class="row result-list" *ngIf="communityHelpBeingSearched && !refineOpened">
				<krn-ng-contextual-help-search-result-list class="container"></krn-ng-contextual-help-search-result-list>
			</div>
			<iframe role="presentation" automation-id="contextualHelpContentIframe" *ngIf="componentSrc && !communityHelpBeingSearched" [src]="componentSrc"></iframe>
			<krn-ng-loader classes="bg-transparent spinner-size-small contextual-help-spinner" *ngIf="loading && !communityHelpBeingSearched">
			</krn-ng-loader>
		</div>
		<div class="slideout__footer">
			<krn-ng-button
				[attrs]="{
					id: 'browseAllHelp_button',
					title: 'html.navbar.contextualHelp.browseAllHelp' | property,
					'aria-describedby': 'browseHelpDesc'
				}"
				classes="btn-primary w-100 btn-justify-center"
				[buttonText]="'html.navbar.contextualHelp.browseAllHelp' | property"
				(buttonClicked)="browseAllHelp()">
			</krn-ng-button>
			<span class="sr-only" id="browseHelpDesc">
				{{'html.navbar.contextualHelp.browseAllHelp.description' | property}}
			</span>
		</div>
	</div>
	<div *ngIf="contentBeingRefined">
		<krn-ng-contextual-help-search-filter (markRefineClosed)="markRefineClosed()"></krn-ng-contextual-help-search-filter>
	</div>
</krn-slide-out-container>
