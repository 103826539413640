export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const KEEP_ALIVE_INTERVAL = ONE_MINUTE;
export const TARGET_ORIGIN = '*';
export const UKGPRO_MENU_ITEMS = 'ukgproMenuItems';
export const GEO_REQUEST_ID = '100';
export const LOCAL_AUTH_REQUEST_ID = '200';
export const OPEN_NEW_TAB_REQUEST_ID = '300';
export const EXPORT_FILE_REQUEST_ID = '400';
export const NAVIGATED_FOFWARD_REQUEST_ID = '500';
export const NAVIGATED_BACK_REQUEST_ID = '600';
export const WIFI_INFORMATION_ID = '700';
export const CAPABILITIES = [{name: 'GetAllMenuItems'}, {name: 'InitSession'}, {name: 'supportFullBackNavigation'}];
export const LAUNCH_NATIVE_MAP_REQUEST_ID = '800';
export const LAUNCH_UNIVERSAL_LINK_REQUEST_ID = '900';
export const LOG_REQUEST_ID = '1000';
export const SCAN_REQUEST_ID = '1100';
export const EMBEDDED_BACK_CLICKED = 'embeddedBackClicked';
export const IS_ONE_APP = 'isOneApp';
