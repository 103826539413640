import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {flattenDeep, get, has, includes, isEmpty, isNil, join, union} from 'lodash';
import * as jQuery from 'jquery';

import {SingleSelectPickerAttrs} from '../../../core/generic/single-select-picker/single-select-picker.interfaces';

import {
	DEFAULT_DATA_LABEL,
	DEFAULT_TOOLTIP,
	DELETE_KEYS,
	EMPTY_CLASS,
	EMPTY_VALUE,
	FLEX_ROW_REVERSE_CLASS,
	ICON_FLEX_ROW_LEFT_CLASS,
	POPOVER_CONTENT_CLASS,
	POPOVER_CONTENT_SELECTOR,
	SINGLE_ICON_LEFT_CLASS
} from '../../../core/generic/single-select-picker//single-select-picker.constants';
import {getClasses} from '../../../core/platform/form-element.utility';
import {getAttributesWithId, getSelectedValue} from '../../../core/generic/single-select-picker/single-select-picker.utility';
import {TEXT_ALIGMENT} from '../../../core/platform/constants/CommonConstants';
import {DeviceConfigService} from '../../../services/device-config.service';

@Component({
	selector: 'krn-ng-single-select-picker-base',
	templateUrl: './single-select-picker-base.component.html',
	styleUrls: ['./single-select-picker-base.component.less']
})
export class SingleSelectPickerBaseComponent implements AfterViewInit, OnChanges {
	@Input() public alignment: string;
	@Input() public attrs: SingleSelectPickerAttrs;
	@Input() public autoResize: boolean;
	@Input() public classes: string | string[];
	@Input() public data: object[];
	@Input() public dataLabel: string;
	@Input() public isOpened: boolean;
	@Input() public label: string;
	@Input() public selected: object;
	@Input() public showAsNative: boolean;
	@Input() public tooltipContainerClass: string | string[];

	@Output() public selectedChange = new EventEmitter<object>();

	@ViewChild('picker') public picker: ElementRef;

	public labelClasses: string[];
	public layout: string;
	public selectedValue: object;

	private selectPickerAttrs: SingleSelectPickerAttrs;

	constructor(private deviceConfigService: DeviceConfigService) {
		this.autoResize = true;
		this.classes = EMPTY_VALUE;
	}

	public ngAfterViewInit(): void {
		if (!this.showAsNative) {
			setTimeout(this.updateWidth);
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.showAsNative = isNil(this.showAsNative) ? this.deviceConfigService.isMobileDevice() : this.showAsNative;
		this.dataLabel = this.dataLabel || DEFAULT_DATA_LABEL;

		if (has(changes, 'attrs')) {
			this.selectPickerAttrs = this.attrs;
		}
		if (has(changes, 'selected.currentValue')) {
			this.selectedValue = getSelectedValue(this.data, get(changes, 'selected.currentValue'), this.selectedValue);
		}
		if (get(changes, 'isOpened.currentValue', false)) {
			setTimeout(this.updateWidth);
		}
		this.tooltipContainerClass = isEmpty(this.tooltipContainerClass) ? DEFAULT_TOOLTIP : join(flattenDeep([this.tooltipContainerClass]), EMPTY_CLASS);
		this.attrs = getAttributesWithId(this.selectPickerAttrs, this.label, this.data);
		this.setSingleSelectPickerClass();
	}

	public handleBubbleBtnClick = (event): void => {
		event.preventDefault();

		if (this.picker && !this.showAsNative && get(event, 'target') && !event.target.closest('button')) {
			const btn = this.picker.nativeElement.querySelector('button');

			btn.focus();
			btn.click();
		}
	};

	public selectPickerDelete(event: KeyboardEvent): void {
		if (!this.attrs.required && (includes(DELETE_KEYS, event.key) || includes(DELETE_KEYS, event.keyCode))) {
			this.selectedValue = undefined;
			this.selectedChange.emit(this.selectedValue);
		}
	}

	public setSingleSelectPickerClass(): void {
		this.labelClasses = getClasses(this.classes, this.alignment, this.attrs, this.label, this.selectedValue);
		if (!this.showAsNative) {
			this.labelClasses = union(this.labelClasses, [SINGLE_ICON_LEFT_CLASS, POPOVER_CONTENT_CLASS]);
			this.layout = includes(this.classes, ICON_FLEX_ROW_LEFT_CLASS) || this.alignment === TEXT_ALIGMENT.RIGHT ? FLEX_ROW_REVERSE_CLASS : EMPTY_VALUE;
		}
	}

	private updateWidth = (): void => {
		const content = jQuery(POPOVER_CONTENT_SELECTOR);

		if (content.length && this.autoResize) {
			content.css('width', `${get(this.picker, 'nativeElement.offsetWidth')}px`);
		}
	};
}
