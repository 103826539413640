import {PropertyFactoryConfig} from '../property/property.factory';
import {PropertyFilterConfig} from '../property/property.filter';

export interface ProfileMenuItemConfig {
	id: string;
	visible?: boolean;
	disabled?: boolean;
	icon?: string;
	classes?: string;
	callback?: () => void;
	postpendTransclude?: boolean;
	open?: boolean;
	uri?: string;
	text?: string;
	tooltip?: string;
	href?: string;
	typeId?: number;
	target?: string;
}

export interface ProfileMenuConfig {
	id: string;
	visible: boolean;
	hideProfile: boolean;
	settings: ProfileMenuItemConfig;
	user: ProfileMenuItemConfig;
	signOut: ProfileMenuItemConfig;
	changePassword: ProfileMenuItemConfig;
	offlineButton: ProfileMenuItemConfig;
}

export class ProfileMenu {
	public config: ProfileMenuConfig;

	constructor(private propertyFilter: PropertyFilterConfig, private propertyFactory: PropertyFactoryConfig, private navBarMenuService) {
		this.config = {
			id: 'profileMenu',
			visible: true,
			hideProfile: false,
			settings: {
				id: 'profileSettings',
				icon: '',
				visible: true,
				classes: 'btn navmenu-info',
				callback: this.navBarMenuService.toggleSettingsMenu,
				disabled: true,
				open: false
			},
			user: {
				id: 'profileUserData',
				callback: this.navBarMenuService.toggleSettingsMenu
			},
			signOut: {
				id: 'profileSignOut',
				visible: true,
				classes: 'btn btn-signout',
				uri: '/logout'
			},
			changePassword: {
				id: 'changePassword',
				uri: 'userProfile#/editProfile',
				visible: true,
				classes: 'btn btn-editProfile',
				callback: this.navBarMenuService.goToEditProfile
			},
			offlineButton: {
				id: 'offlineButton',
				visible: false,
				classes: 'btn btn-offline',
				callback: this.navBarMenuService.runOfflineMode
			}
		};

		this.initialize();
	}

	public switchTabIndexElements(isOpened: boolean, settingsMenu: JQuery): void {
		settingsMenu.find('[tabindex="-1"]').attr('tabindex', isOpened ? '0' : '-1');
	}

	private initialize(): void {
		this.propertyFactory
			.loadProperties([
				{
					name: 'container_web-common_nav-bar_strings.properties',
					path: 'components/zed-core/properties/navBar/'
				}
			])
			.then(() => {
				this.config.settings.tooltip = this.propertyFilter('html.navBar.profileMenu.settings');
				this.config.signOut.tooltip = this.config.signOut.text = this.propertyFilter('html.navBar.profileMenu.signOut');
				this.config.changePassword.tooltip = this.config.changePassword.text = this.propertyFilter('html.navBar.profileMenu.editProfile');
				this.config.offlineButton.tooltip = this.config.offlineButton.text = this.propertyFilter('html.navbar.profileMenu.offlineButton');
			});
	}
}
