import * as jQuery from 'jquery';
import {isEmpty} from 'lodash';

const $: any = jQuery;

export class BaseReturnFocusElement {
	protected focusElementId: string;
	protected focusFromElement: string;
	protected focusOnDestroy: boolean;
	protected focusFromClass: boolean;
	protected focusDelay: number;
	protected element: HTMLElement;

	private focusElement: JQuery;
	private focusFromFound: boolean;
	private checkFocusInProgress: boolean;

	constructor() {
		this.focusFromFound = false;
		this.focusOnDestroy = true;
		this.focusFromClass = false;
		this.checkFocusInProgress = false;
		this.focusDelay = 0;
	}

	public setFocusFromElement(): void {
		if (this.focusFromElement) {
			this.focusFromFound = this.checkFocusFromElement();
		}
	}

	public returnFocusNow(): void {
		this.focusElement.trigger('focus');
	}

	public returnFocusNowEvent(): void {
		setTimeout(() => {
			this.initialize();
			if (!isEmpty(this.focusElement)) {
				this.returnFocusNow();
			}
		}, this.focusDelay);
	}

	protected initialize(): void {
		this.focusElement = this.getFocusElement();

		this.setFocusFromElement();
	}

	protected onChecked(): void {
		if (isEmpty(this.focusElement)) {
			this.focusElement = this.getFocusElement();
		}
		if (!this.checkFocusInProgress && this.focusFromFound && !isEmpty(this.focusElement)) {
			this.checkFocusInProgress = true;
			setTimeout(() => {
				if (!this.checkFocusFromElement()) {
					this.returnFocusNow();
					this.focusFromFound = false;
				}
				this.checkFocusInProgress = false;
			}, this.focusDelay);
		}
	}

	protected getFocusElement = (): JQuery<HTMLElement> => {
		if (!this.focusElementId) {
			return undefined;
		}
		let focusElement = $(document).find(`#${this.focusElementId}`);

		if (isEmpty(focusElement)) {
			focusElement = $(document).find(this.focusElementId);
		}
		return focusElement;
	};

	protected destroy(): void {
		if (!isEmpty(this.focusElement) && this.focusOnDestroy) {
			this.returnFocusNowEvent();
		}
	}

	private checkFocusFromElement(): boolean {
		return this.focusFromClass ? this.classElementExists() : this.childElementExists();
	}

	private childElementExists = (): boolean => !isEmpty($(document).find(`#${this.focusFromElement}`));

	private classElementExists = (): boolean => !isEmpty($(document).find(`.${this.focusFromElement}`));
}
