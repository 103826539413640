import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';

import {ClientContainer} from '../core/platform/clientContainer/client-container.interface';

import {BrandingCoreService} from '../core/platform/services/branding.core.service';
import {DataService} from '../services/communication/data.service';
import {LocalStorageService} from './local-storage.service';
import {WindowRefService} from './window-ref.service';
import {ClientContainerService} from './clientContainer/client-container.service';

@Injectable({providedIn: 'root'})
export class BrandingService extends BrandingCoreService {
	constructor(
		protected dataService: DataService,
		protected localStorageService: LocalStorageService,
		@Inject(DOCUMENT) protected document: Document,
		protected windowService: WindowRefService,
		@Inject(ClientContainerService) protected clientContainerService: ClientContainer
	) {
		super(dataService, localStorageService, document, windowService.nativeWindow, clientContainerService);
	}
}
