import {EventData, StateData} from './iframe-framework.interface';

import {ACTIONS} from './iframe-framework.constant';
import {setLocationUrl} from './iframe-message-handler.utility';

export abstract class IframeNavigationCoreService {
	public serviceIdentifierPromise: any = {};
	public stateData: StateData;
	
	constructor(protected window) {}

	public setStateData = (data: EventData): void => {
		this.stateData = {
			action: data.action,
			url: data.configuration.url
		};
	};
	
	public getStateData = (): StateData => this.stateData;

	public setBrowserUrl = (data: EventData, href: string): void => {
		if (data.action === ACTIONS.STATE) {
			this.setStateData(data);
			setTimeout(() => {
				this.window.location.replace(href);
			});
		} else {
			setLocationUrl(this.window, href);
		}
	};

	public internalNavigate = (data: EventData): void => {
		this.window.location.href = data.configuration.url;
	};
	
	public navigate = (data: EventData): void => {
		if (data.configuration.isInternalUrl) {
			this.internalNavigate(data);
		} else {
			this.getReadOnlyPromise(data);
		}
	};

	protected abstract getReadOnlyPromise (data: any): void;
}