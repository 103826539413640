<div class="modal-header">
  <h3 id="sessionTimeoutModalTitle" class="modal-title">{{'app.core.session.timeout.title' | property}}</h3>
</div>
<div class="modal-body">{{sessionTimeoutTextPropertyKey | property:timeLeft}}</div>
<krn-ng-screen-reader-only
  id="sessionTimeoutModalDesc"
  [ariaLive]="isIOSDevice ? 'polite' : 'off'"
  removeTimeout="5000"
  [content]="{text:sessionTimeoutTextPropertyKey | property:currentTimeLeft}">
</krn-ng-screen-reader-only>
<div class="modal-footer">
  <button id="sessionTimeoutModalConfirmButton" type="button" [attr.aria-label]="sessionTimeoutConfirmButtonTextPropertyKey | property" class="btn btn-primary" (click)="confirm()">{{sessionTimeoutConfirmButtonTextPropertyKey | property}}</button>
  <button *ngIf="extendable" id="sessionTimeoutModalDeclineButton" type="button" [attr.aria-label]="'app.core.session.timeout.decline' | property" class="btn btn-warning" (click)="decline()">{{'app.core.session.timeout.decline' | property}}</button>
</div>
