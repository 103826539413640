import {Observable, Subject} from 'rxjs';

import {KrnMessageDetailsModel, KrnMessageInpageModel} from '../../../generic/message/message.interface';

export default class PersistentBannerCoreService {
	private persistentBannerMessageSource = new Subject<KrnMessageInpageModel | KrnMessageDetailsModel>();
	private persistentBannerHideSource = new Subject<boolean>();

	public showMessage = (message: KrnMessageInpageModel | KrnMessageDetailsModel): void => this.persistentBannerMessageSource.next(message);

	public persistentBannerMessageSource$ = (): Observable<KrnMessageInpageModel | KrnMessageDetailsModel> => this.persistentBannerMessageSource;

	public persistentBannerHideSource$ = (): Observable<boolean> => this.persistentBannerHideSource;

	public hideMessage = (): void => this.persistentBannerHideSource.next(null);
}
