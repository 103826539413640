import {CordovaPluginWindow} from '../mobile/cordova-plugin-window.interface';
import {CommunicationEventName} from './client-container.interface';

import {EmbeddedMenuItemsCoreService} from '../../embedded/embedded-menu-items.core.service';
import {SessionStorage} from '../caching/sessionStorage.service';
import {BaseEmbeddedContainerCoreService} from './base-embedded-container.core.service';

export class ProEmbeddedContainerCoreService extends BaseEmbeddedContainerCoreService {
	constructor(
		protected window: CordovaPluginWindow,
		protected sessionStorage: SessionStorage,
		protected embeddedMenuItemsService: EmbeddedMenuItemsCoreService
	) {
		super(window, sessionStorage, embeddedMenuItemsService);
	}

	public getEventName = (): CommunicationEventName => CommunicationEventName.UP_POST_MESSAGE;
}
