import {AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {get, has, includes, isString, isUndefined, omitBy, random} from 'lodash';

import {IframeApi} from '../../../core/iframe-framework/iframe-framework.interface';

import {UserInfoService} from '../../../core/framework/services/user-info.service';
import {getUrlWithQueryParamList} from '../../../core/iframe-framework/iframe-message-handler.utility';
import {DeviceConfigService} from '../../../services/device-config.service';
import {IFrameValidatorService} from '../../../services/iframe-framework/iframe-validator.service';

@Component({
	selector: 'krn-ng-iframe-container',
	templateUrl: './iframe-container.component.html',
	styleUrls: ['./iframe-container.component.less']
})
export class IframeContainerComponent implements AfterViewInit, OnChanges {
	@Input() public config: any;
	@Input() public api: IframeApi;
	@Input() public iframeId: string;
	@Input() public isCustomIframeContent = true;
	@Input() public refresh: boolean;
	@Input() public classes: string | string[];

	public frameName: string;
	public loading = false;
	public frameSrc: SafeUrl;

	constructor(
		private domSanitizer: DomSanitizer,
		private changeDetectorRef: ChangeDetectorRef,
		private userDataService: UserInfoService,
		private deviceConfigService: DeviceConfigService,
		private IFrameValidator: IFrameValidatorService
	) {}

	public ngAfterViewInit(): void {
		this.frameName = `portal-frame-${random(new Date().getTime())}`;

		this.changeDetectorRef.detectChanges();
		this.init();
		this.api = {
			getIFrameDetails: () => ({name: this.frameName}),
			reload: (url?: string) => {
				this.config.url = url || this.config.url;
				this.getIframeAttributes();
			}
		};
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (get(changes, 'refresh.currentValue') && this.api) {
			this.api.reload();
		}
		if (get(changes, 'config.currentValue') && !get(changes, 'config.firstChange')) {
			this.init();
		}
	}

	private getIframeAttributes(): void {
		if (this.isCustomIframeContent) {
			this.loading = true;
		}
		this.userDataService.getLoggedOnUserData().then(userData => {
			const timestamp = new Date().getTime();
			const userLocale = `${get(userData, 'localePolicy.languageCode', 'EN')}_${get(userData, 'localePolicy.countryCode', 'US')}`;
			const configUrl = get(this.config, 'url');
			const REDIRECT_DIVIDER = 'redirect_uri=';

			this.frameSrc = this.getIFrameUrl(configUrl, userLocale, timestamp);
			this.IFrameValidator.registerFrameUrl(this.frameSrc);
			if (includes(configUrl, REDIRECT_DIVIDER)) {
				const redirectUrl = configUrl.slice(configUrl.indexOf(REDIRECT_DIVIDER) + REDIRECT_DIVIDER.length);

				this.IFrameValidator.registerFrameUrl(this.getIFrameUrl(redirectUrl, userLocale, timestamp));
			}
			this.loading = false;
		});
	}

	private init(): void {
		if (!has(this, 'config.url')) {
			return;
		}
		if (!this.isCustomIframeContent) {
			this.frameSrc = get(this.config, 'url');
			this.changeDetectorRef.detectChanges();
		}
		this.getIframeAttributes();
	}

	private calcIFrameUrl(url: string, locale: string, time: number): string {
		return getUrlWithQueryParamList(
			url,
			omitBy(
				{
					locale,
					deviceType: this.deviceConfigService.getDeviceType(),
					timestamp: this.config.noTimestamp ? undefined : time
				},
				isUndefined
			)
		);
	}

	private getIFrameUrl(url, locale: string, time: number): SafeUrl {
		return isString(url) ? this.domSanitizer.bypassSecurityTrustResourceUrl(this.calcIFrameUrl(url, locale, time)) : url;
	}
}
