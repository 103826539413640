import 'whatwg-fetch';

import {RestService} from './rest-service.interface';

export class RestServiceFetchPolyfill implements RestService {
	public get(url: string, data?: any, param?: any, header?: any): Promise<any> {
		return this.call('GET', url, data, param, header);
	}

	public post(
		url: string,
		data?: any,
		param?: any,
		header?: any,
		transformRequest?: any
	): Promise<any> {
		return this.call('POST', url, data, param, header, transformRequest);
	}

	public destroy(
		url: string,
		data?: any,
		param?: any,
		header?: any
	): Promise<any> {
		return this.call('DELETE', url, data, param, header);
	}

	public update(
		url: string,
		data?: any,
		param?: any,
		header?: any
	): Promise<any> {
		return this.call('PUT', url, data, param, header);
	}

	private call(
		method: string,
		url: string,
		data: any,
		param: any,
		header: any,
		transformRequest?: any
	): Promise<any> {
		const config: RequestInit = {
			body: JSON.stringify(data),
			credentials: 'include',
			headers: header,
			method
		};

		return fetch(url, config).then(response => this.checkStatus(response));
	}

	private checkStatus(response): void {
		if (response.status >= 200 && response.status < 300) {
			return response;
		} 
		const error = new Error(response.statusText) as any;

		error.response = response;
		throw error;
	}
}
