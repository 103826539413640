import {isEmpty} from 'lodash';
import {finalize, Observable, Subject} from 'rxjs';

import {ApiService, Transport} from './api-service/api-service.interfaces';

const EVENT = 'event';

export abstract class DataCoreService implements ApiService {
	public stream(route: string, query?: object): Observable<object> {
		let stream$: Subject<object> = new Subject<object>();
		const closeStream = (): void => {
			stream$ = undefined;
		};

		let src = new EventSource(this.toUrl(route, query));
		const messageListener = (evt: MessageEvent): void => {
			if (evt.lastEventId === EVENT) {
				stream$.next(JSON.parse(evt.data));
			}
		};
		const errorListener = (): void => {
			closeStream();
			src.removeEventListener('message', messageListener);
			src.removeEventListener('error', errorListener);
			src = undefined;
		};

		src.addEventListener('message', messageListener);
		src.addEventListener('error', errorListener);

		return stream$.pipe(finalize(closeStream));
	}

	private toUrl = (route: string, query?: object): string =>
		isEmpty(query)
			? route
			: `${route}?${Object.keys(query)
					.map(key => `${key}=${query[key]}`)
					.join('&')}`;

	public abstract broadcast(route: string, id: number, query?: object): void;

	public abstract create(
		route: string,
		data?: object,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	): Promise<any>;

	public abstract find(
		route: string,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	): Promise<any>;

	public abstract get(
		route: string,
		id?: number,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	): Promise<any>;

	public abstract listen(event: string): Observable<any>;

	public abstract patch(
		route: string,
		id: number,
		data?: object,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	): Promise<any>;

	public abstract remove(
		route: string,
		id: number,
		data?: object,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	): Promise<any>;

	public abstract update(
		route: string,
		id: number,
		data?: object,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	): Promise<any>;
}
