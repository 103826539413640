import {join, map, trim} from 'lodash';

export const elementText = (element: Node): string => {
	const childStrings = map(element.childNodes, child => elementText(child)) || [];

	if (element.nodeType === Node.TEXT_NODE) {
		childStrings.unshift(element.nodeValue);
	}
	return trim(join(childStrings, ' '));
};

export const isAnyFocusableElement = (element: HTMLElement): boolean =>
	element instanceof HTMLButtonElement ||
	element instanceof HTMLInputElement ||
	element instanceof HTMLSelectElement ||
	element instanceof HTMLTextAreaElement;
