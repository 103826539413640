import {handleActions} from 'redux-actions';

import {UserInfo} from './user-info.interface';

import {userInfo} from './user-info.actions';

export const USER_INFO_DEFAULT_STATE: UserInfo = {
	personId: null,
	firstName: null,
	lastName: null,
	middleInitial: null,
	photoId: undefined,
	isManager: null,
	timecardViewType: null,
	currencyCode: null,
	tenantId: null,
	tenantExternalName: null,
	locale: null,
	userFeatures: null,
	timeZone: null,
	delegate: null,
	userAuthType: null,
	initials: null,
	impersonate: null,
	vendorName: null,
	auth0Login: false
};

export const reducerHelper = (): Partial<any> => ({
	[userInfo]: userInfoReduce
});

export const userInfoReduce = (state: UserInfo, {payload}: {payload: Partial<UserInfo>}): any => ({
	...state,
	...payload
});

export const userInfoReducer = handleActions(
	{
		[userInfo]: userInfoReduce
	},
	USER_INFO_DEFAULT_STATE
);
