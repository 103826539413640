// NOTE ---> This code was taken as is from the original implementation in nav-bar-menu.service.js
// A few larger functions were broken up into smaller ones, but for the most part they are unchanged.
import {filter, find, forEach, get, isMatch, isNil, omit, some} from 'lodash';

export interface SelectedLink {
	category: any;
	item: any;
}

export interface SelectedElement {
	scrolledElement: any;
	isMenuLink: boolean;
}

export const HOME_URI = 'wfd/home';
export const OLD_HOME_URI = '/#/';

export class NavBarMenu {
	public getExpandedState = (menuObject: any): boolean => {
		if (isNil(menuObject)) {
			return false;
		}

		if (menuObject.mainData) {
			return menuObject.mainData.some(item => {
				const category = some(item.menu, {isSelected: true});

				return category && item.options && item.options.expanded;
			});
		} 
			return menuObject.some(item => {
				const category = some(item.menu, {isSelected: true});

				return category && item.options && item.options.expanded;
			});
		
	};

	public scrollSelectedElement = (menuObject: any, selectedLink: SelectedLink): SelectedElement => {
		const expandedState = this.getExpandedState(menuObject);
		let isMenuLink;
		let scrolledElement;

		if (expandedState) {
			scrolledElement = selectedLink.item || selectedLink.category;
			isMenuLink = Boolean(selectedLink.item);
		} else {
			scrolledElement = selectedLink.category || selectedLink.item;
			isMenuLink = !selectedLink.category;
		}

		return {scrolledElement, isMenuLink};
	};

	public markSelectedMenu = (menu: any[], queryParams: any, urlPageId: string, urlPageLink: string): void => {
		const menuItemsArray = [];
		const similarMenuLinks = [];

		this.formatMenuToArray(menu, menuItemsArray);

		// If we haven't marked a menu item... try to mark similar ones
		if (this.getSimilarMenuLinks(menuItemsArray, similarMenuLinks, urlPageLink, urlPageId)) {
			if (this.tryMarkSingleMenuItem(similarMenuLinks) || this.tryMarkMenuItemWithoutPageId(similarMenuLinks)) {
				return;
			}
			this.tryMarkMenuItemIgnoringPageId(queryParams, similarMenuLinks);
		}
	};

	public getPageLink = (url: string): string => {
		const queryParams = url.split('?');
		// eslint-disable-next-line no-useless-escape
		let pageLink = queryParams[0].replace(/((^\/)|(\#\/|\/)$)/g, '');
		const queryParamsPath = queryParams[queryParams.length === 2 ? 1 : 0].split('#');

		if (pageLink.indexOf('#') === -1) {
			pageLink += '#';
			pageLink += queryParamsPath.length === 2 ? queryParamsPath[1] : '/';
		}

		return pageLink;
	};

	public getQueryParams = (url: string): any => {
		const urlParts = url.split('?');
		const resultParams = {};
		let urlParams;
		let paramParts;

		if (urlParts.length > 1) {
			urlParams = urlParts[1].split('&');

			forEach(urlParams, function each(param) {
				paramParts = param.split('=');
				resultParams[paramParts[0]] = paramParts[1];
			});
		}

		return resultParams;
	};

	public resetSelectedMenu = (menu: any[]): void => {
		for (const item of menu) {
			if (item.menu) {
				this.resetSelectedMenu(item.menu);
			} else if (item.isSelected) {
				item.isSelected = false;
				return;
			}
		}
	};

	private formatMenuToArray = (menu: any[], menuItemsArray: any[]): void => {
		forEach(menu, value => {
			if (this.isNestedMenu(value)) {
				value.isNested = true;
				this.formatMenuToArray(value.menu, menuItemsArray);
			} else {
				value.isSelected = false;
				menuItemsArray.push(value);
			}
		});
	};

	private isNestedMenu = (menuItem: any): boolean => menuItem.menu !== undefined;

	private getSimilarMenuLinks = (menuItemsArray: any[], similarMenuLinks: any[], urlPageLink: string, urlPageId: string): boolean => {
		let menuLink;
		let formattedMenuLink;
		let menuPageId;

		for (const item of menuItemsArray) {
			menuLink = item.uri || item.href;

			if (menuLink) {
				formattedMenuLink = this.getPageLink(menuLink);

				menuPageId = this.getQueryParams(menuLink).pageId;
				if (this.matchedLink(urlPageLink, formattedMenuLink, urlPageId, menuPageId)) {
					if (urlPageId === menuPageId) {
						item.isSelected = true;
						return false;
					}

					similarMenuLinks.push({
						menuItem: item,
						pageId: menuPageId
					});
				}
			}
		}
		return true;
	};

	private matchedLink = (urlCurrent: string, urlFormatted: string, urlPageId: string, menuPageId: string): boolean => {
		let isMatched = urlCurrent === urlFormatted;

		if (!isMatched && urlPageId && menuPageId) {
			isMatched = urlPageId === menuPageId;
		}
		return isMatched;
	};

	private tryMarkSingleMenuItem = (similarMenuLinks: any[]): boolean => {
		if (similarMenuLinks.length === 1) {
			similarMenuLinks[0].menuItem.isSelected = true;
			return true;
		}

		return false;
	};

	private tryMarkMenuItemWithoutPageId = (similarMenuLinks: any[]): void => {
		const foundMenuLink = find(similarMenuLinks, {pageId: undefined});

		if (foundMenuLink) {
			foundMenuLink.menuItem.isSelected = true;
		}
	};

	private tryMarkMenuItemIgnoringPageId = (queryParams: string[], similarMenuLinks: any): boolean => {
		const requiredParams = omit(queryParams, 'pageId');
		const links = filter(similarMenuLinks, link => {
			const linkQueryParams = this.getQueryParams(get(link, 'menuItem.uri'));

			return isMatch(linkQueryParams, requiredParams);
		});

		return this.tryMarkSingleMenuItem(links);
	};
}
