import {Injectable} from '@angular/core';

import {EmbeddedPostMessageCoreService} from '../core/embedded/embedded-post-message.core.service';
import {SessionStorageCoreService} from '../core/platform/caching/sessionStorage.service';
import {ClientContainerService} from './clientContainer/client-container.service';
import {SessionStorageService} from './session-storage.service';
import {WindowRefService} from './window-ref.service';

@Injectable({providedIn: 'root'})
export class EmbeddedPostMessageService extends EmbeddedPostMessageCoreService {
	constructor(windowRefService: WindowRefService, sessionStorage: SessionStorageService, protected clientContainerService: ClientContainerService) {
		super(windowRefService.nativeWindow, sessionStorage as SessionStorageCoreService, clientContainerService);
	}
}
