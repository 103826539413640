import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, SimpleChanges} from '@angular/core';
import * as jQuery from 'jquery';
import {get, isEmpty, last} from 'lodash';

import {FocusElementId} from '../../core/platform/accessibility/services/keyboard-navigation.interfaces';

import {MoveTabToBase} from '../../core/platform/accessibility/move-tab-to-base.directive';
import {FOCUSABLE_ELEMENTS} from '../../core/platform/accessibility/utils.constants';

const $: any = jQuery;

@Directive({
	selector: '[krn-ng-move-tab-to]'
})
export class MoveTabToDirective extends MoveTabToBase implements AfterViewInit, OnDestroy {
	@Input('krn-ng-move-tab-to') public tabToElementId: FocusElementId;

	constructor(private element: ElementRef) {
		super();
	}

	public ngAfterViewInit(): void {
		const nativeElement = $(this.element.nativeElement);

		this.keyDownElement = nativeElement.is(FOCUSABLE_ELEMENTS) ? this.element.nativeElement : (last(nativeElement.find(FOCUSABLE_ELEMENTS)) || undefined);

		if (!isEmpty(this.tabToElementId) && this.keyDownElement) {
			this.initialize();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (get(changes, 'tabToElementId.previousValue')) {
			this.destroy();
		}
		if (this.tabToElementId && this.keyDownElement) {
			this.initialize();
		}
	}

	public ngOnDestroy(): void {
		this.destroy();

		this.keyDownElement = undefined;
		this.tabToElementId = undefined;
	}
}
