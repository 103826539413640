import {MessageKindType as MessageKindTypeClass, MessageType as MessageTypeClass} from '../services/MessageServices.interface';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MessageType: MessageTypeClass = {
	ERROR: 'error',
	INFO: 'info',
	WARNING: 'warning',
	SUCCESS: 'success'
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MessageKindType: MessageKindTypeClass = {
	INPAGE: 'INPAGE',
	POPUP: 'POPUP'
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MessageHeaderText = {
	[MessageType.INFO]: 'html.commonInfra.components.common.words.information',
	[MessageType.WARNING]: 'html.commonInfra.components.common.words.warning',
	[MessageType.SUCCESS]: 'html.commonInfra.components.common.words.success',
	[MessageType.ERROR]: 'html.commonInfra.components.common.words.error',
	default: 'html.commonInfra.platform.errorframework.errormessage.systemerror.header'
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MessageIconClass = {
	[MessageType.INFO]: 'icon-k-info-as-indicator ',
	[MessageType.WARNING]: 'icon-k-warning-as-indicator warning ',
	[MessageType.SUCCESS]: 'icon-k-success-as-indicator success',
	[MessageType.ERROR]: 'icon-k-error-as-indicator error',
	default: 'icon-k-cancel-circle'
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MessageIconShapeClass = {
	[MessageType.WARNING]: 'icon-k-triangle',
	default: 'icon-k-circle'
};

export const ERROR_MESSAGE_TYPE = {
	PARSE: 'Http failure response for'
};