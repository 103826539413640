import {get} from 'lodash';

import {ApiCriteria, Mapping} from '../platform/mobile/mapping.interface';
import {KnownPlace} from '../platform/oneApp/mapping.interface';
import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';
import {LogServiceInterface} from '../platform/services/logService/log.interface';

import {MAP_SUCCESS, MapType} from '../platform/mobile/mapping.constants';
import {EmbeddedMappingCoreService} from '../embedded/embedded-mapping.core.service';
import {CAPS, DATA_CHUNK_SIZE, DATA_TYPES, EMPLOYEE_OFFLINE_MOBILE_PUNCH, KNOWN_PLACES_ENDPOINT, KNOWN_PLACES_MULTI_ENDPOINT, MAPPING_USE_RADIUS, NGSTORAGE_USER_INFO, REQUIRED_FACPS, TIMECARD_ENDPOINT, TOTAL_USERS} from '../platform/oneApp/mapping.constants';
import {buildPunchTileContract, buildTimecardContract, checkPropByPath, chunkSubstr, getOrgJobIds, hasPlottableData} from '../platform/oneApp/mapping.utility';
import {SessionStorage} from '../platform/caching/sessionStorage.service';
import {POSITION_BUTTON, SENDER_NAME} from './one-app-event-bus.constants';
import {PropertyFactory} from '../property/property.factory';
import {PropertyFilterConfig} from '../property/property.filter';

export class OneAppMappingCoreService extends EmbeddedMappingCoreService {
	private spinnerKey: string;

	constructor(
		protected window: any,
		private apiService: ApiService,
		protected sessionStorage: SessionStorage,
		protected logger: LogServiceInterface,
		protected propertyFactory: PropertyFactory,
		protected propertyFilter: PropertyFilterConfig) {
		super(window);
		this.propertyFactory.loadProperties([
			{
				name: 'container_web-common_geolocation_strings.properties',
				path: '/components/zed-core/properties/platform/'
			}
		]);
	}

	public launchMap(apiCriteria: ApiCriteria, mapType: MapType): Promise<Mapping> {
		this.spinnerKey = this.generateUUID();
		this.window.OneAppCommon.UI.spinner.show(this.window, SENDER_NAME, this.spinnerKey, '', '');
		return this.checkCapabilities()
		.then(() => MAP_SUCCESS)
		.finally(() => {
			this.launchNativeMap(apiCriteria, mapType);
		});
	}

	public displayMap = (MAP_ID: string, showNoKnownPlacesMsg: boolean, embeddedKnownPlaces = false): void => {
		const userInfo = this.sessionStorage.get(NGSTORAGE_USER_INFO);
		const isManager = userInfo && userInfo.isManager;
		const useRadius = !!this.sessionStorage.get(MAPPING_USE_RADIUS) || isManager || embeddedKnownPlaces;

		if (showNoKnownPlacesMsg) {
			this.window.OneAppCommon.UI.spinner.hide(this.window, SENDER_NAME, this.spinnerKey);
			this.window.OneAppCommon.UI.confDialog(this.window, SENDER_NAME, {
				message: this.propertyFilter('common.inject.mapping.no.know.places'),
				positiveButtonText: this.propertyFilter('common.inject.map.proceed.label')
			}).then(res => {
				this.spinnerKey = this.generateUUID();
				this.window.OneAppCommon.UI.spinner.show(this.window, SENDER_NAME, this.spinnerKey);
				if (res.data.buttonPressed === POSITION_BUTTON) {
					this.window.wfdPunchMap.launchMap(MAP_ID, useRadius);
				}
				this.window.OneAppCommon.UI.spinner.hide(this.window, SENDER_NAME, this.spinnerKey);
			});
		} else {
			this.window.wfdPunchMap.launchMap(MAP_ID, useRadius);
			this.window.OneAppCommon.UI.spinner.hide(this.window, SENDER_NAME, this.spinnerKey);
		}
	};

	public launchNativeMap = async (apiCriteria: ApiCriteria, mapType: MapType, mapId = Date.now().toString(), embeddedKnownPlaces = false): Promise<void> => {
		let mapData: any;
		let callMap = true;
		let showNoKnownPlacesMsg = false;
		let showNoPunchesMsg = false;

		if ((mapType === MapType.PUNCH || mapType === MapType.TIMECARD) && checkPropByPath(apiCriteria, 'employees')) {
			if (mapType === MapType.PUNCH && apiCriteria.employees.length > 0) {
				mapData = await this.getPunchMapData(apiCriteria);
				showNoKnownPlacesMsg = mapData.knownPlaces.length === 0;
			}

			if (mapType === MapType.TIMECARD && apiCriteria.employees.length > 0 && checkPropByPath(apiCriteria, 'dateRange')) {
				if (apiCriteria.employees.length <= TOTAL_USERS) {
					mapData = await this.getTimeCardMap(apiCriteria);
					showNoPunchesMsg = !mapData || !hasPlottableData(mapData);
				} else {
					callMap = false;
					this.window.OneAppCommon.UI.spinner.hide(this.window, SENDER_NAME, this.spinnerKey);
					this.window.OneAppCommon.UI.confDialog(this.window, SENDER_NAME, {
						message: this.propertyFilter('common.inject.mapping.too.many.employees'),
						positiveButtonText: this.propertyFilter('common.inject.mapping.dialog.ok')
					});
				}
			}
		}

		if (showNoPunchesMsg) {
			callMap = false;
			this.window.OneAppCommon.UI.spinner.hide(this.window, SENDER_NAME, this.spinnerKey);
			this.window.OneAppCommon.UI.confDialog(this.window, SENDER_NAME, {
				message: this.propertyFilter('common.inject.mapping.no.punches'),
				positiveButtonText: this.propertyFilter('common.inject.mapping.dialog.ok')
			});
		}

		if (callMap) {
			this.logger.info('Mapping', `Map Data : ${JSON.stringify(mapData)}`);
			this.saveDataChunk(mapData, mapType, mapId);
			this.displayMap(mapId, showNoKnownPlacesMsg, embeddedKnownPlaces);
		}
	};

	public saveDataChunk(mapData: any, mapType: MapType, mapId: string): void {
		let SEQUENCE_ID = 0;

		if (checkPropByPath(mapData, 'knownPlaces') || checkPropByPath(mapData, 'users')) {
			const dataChunkType = mapType === MapType.PUNCH ? DATA_TYPES.PUNCH_DATA : DATA_TYPES.TIMECARD_DATA;
			const arrayOfSmallerStrings = chunkSubstr(JSON.stringify(mapData), DATA_CHUNK_SIZE);

			for (const item of arrayOfSmallerStrings) {
				this.window.wfdPunchMap.saveDataChunk(mapId, SEQUENCE_ID.toString(), dataChunkType, item);
				SEQUENCE_ID++;
			}
		}
	}

	private generateUUID(): string {
		return `${new Date().getTime()}mapping`;
	}

	private async getPunchMapData(apiCriteria: ApiCriteria): Promise<any> {
		const employeeId = apiCriteria.employees[0].id;
		const knownPlaceData = await this.apiService.get(`${KNOWN_PLACES_ENDPOINT}?employee_id=${employeeId}`, undefined, undefined, Transport.REST);
		const mapData = buildPunchTileContract(knownPlaceData.kpResults);

		return mapData;
	}

	private async getTimeCardMap(apiCriteria: ApiCriteria): Promise<any> {
		let mapData;

		try {
			const tcData: any = await this.getTimecardMapTcData(apiCriteria);

			mapData = buildTimecardContract(apiCriteria, tcData.tcResult);
			if (hasPlottableData(mapData)) {
				const pullKnownPlacesForTC = !!this.sessionStorage.get(CAPS.MAPPING_KNOWN_PLACES_MULTI_READ);

				if (pullKnownPlacesForTC) {
					try {
						const orgJobIds = getOrgJobIds(tcData.tcResult);

						this.logger.info('Mapping', `Timecard Mapping Org Job Ids: ${JSON.stringify(orgJobIds)}`);
						if (orgJobIds.length > 0) {
							const kpData = await this.getTimecardMapKpDat(orgJobIds);

							this.logger.info('Mapping', `Timecard Mapping Knownplaces: ${kpData}`);
							mapData.knownPlaces = buildPunchTileContract(kpData.result).knownPlaces;
						}
					} catch (err) {
						this.logger.error('Mapping', `Timecard Mapping Knownplaces Error: ${err}`);
					}
				}
			}
		} catch (err) {
			this.logger.error('Mapping', JSON.stringify(err));
		}
		return mapData;
	}

	private async getTimecardMapTcData(apiCriteria: ApiCriteria): Promise<void> {
		const employeeList = apiCriteria.employees.map(emp => emp.id);
		const dateRange: any = apiCriteria.dateRange;
		const timecard = {
			select: ['WORKED_SHIFTS'],
			where: {
				employees: {
					ids: employeeList
				},
				dateRange: {
					symbolicPeriod: dateRange.symbolicPeriod,
					startDate: dateRange.startDate,
					endDate: dateRange.endDate
				}
			}
		};

		this.logger.info('Mapping', `Timecard Data From Frontend : ${JSON.stringify(timecard)}`);
		return this.apiService.create(TIMECARD_ENDPOINT, timecard, undefined, Transport.REST);
	}

	private async getTimecardMapKpDat(orgJobIds: KnownPlace[]): Promise<any> {
		const kpBody = {
			where: {
				orgJobs: {
					ids: orgJobIds
				}
			}
		};

		return this.apiService.create(KNOWN_PLACES_MULTI_ENDPOINT, kpBody, undefined, Transport.REST);
	}

	private async checkCapabilities(): Promise<any> {
		const endpoint = await this.getEndPoint();
		const mobileInitData = await this.apiService.get(endpoint, undefined, undefined, Transport.REST);
		const facps = get(mobileInitData, 'facps.permissions', []);

		this.sessionStorage.clear(MAPPING_USE_RADIUS);
		facps.forEach(facp => {
			if (facp.name === EMPLOYEE_OFFLINE_MOBILE_PUNCH) {
				this.sessionStorage.set('mobileFACP', EMPLOYEE_OFFLINE_MOBILE_PUNCH);
			} else if (facp.name === MAPPING_USE_RADIUS) {
				this.sessionStorage.set(MAPPING_USE_RADIUS, MAPPING_USE_RADIUS);
			}
		});
	}

	private async getEndPoint(): Promise<any> {
		let endpoint = `mobileapp/offlinepunchinformation?facpNames=${REQUIRED_FACPS.join(',')}`;
		const cap = await this.apiService.get('mobileapp/capabilities', undefined, undefined, Transport.REST);

		get(cap, 'data.capabilities', []).forEach(capability => {
			switch (capability.name) {
				case CAPS.STARTUP_API:
					endpoint = `mobileapp/clientstartup?facpNames=${REQUIRED_FACPS.join(',')}`;
					break;
				case CAPS.MAPPING_KNOWN_PLACES_MULTI_READ:
					this.sessionStorage.set(CAPS.MAPPING_KNOWN_PLACES_MULTI_READ, CAPS.MAPPING_KNOWN_PLACES_MULTI_READ);
					break;
				case CAPS.DEVICE_INFO_MOBILE_APP_NAME:
					this.sessionStorage.set(CAPS.DEVICE_INFO_MOBILE_APP_NAME, CAPS.DEVICE_INFO_MOBILE_APP_NAME);
					break;
			}
		});
		return endpoint;
	}
}
