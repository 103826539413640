<div class="spinner-container d-flex align-items-center"
     [ngClass]="adjustedClasses"
     [krn-ng-attributes]="{
        'aria-label': attrs['aria-label'] || ('html.commonInfra.components.loader.loading' | property),
        'aria-live': attrs['aria-live'],
        'role': attrs['aria-live'] === 'off' ? '' : 'alert'
    }"
     tabindex="0">
    <div class="spinner d-flex justify-content-center align-items-center flex-column w-100">
        <div id="spinnerCircles" *ngIf="showImage()">
            <div id="spinnerCircle_1" class="spinner-circle">
            </div>
            <div id="spinnerCircle_2" class="spinner-circle">
            </div>
            <div id="spinnerCircle_3" class="spinner-circle">
            </div>
            <div id="spinnerCircle_4" class="spinner-circle">
            </div>
            <div id="spinnerCircle_5" class="spinner-circle">
            </div>
            <div id="spinnerCircle_6" class="spinner-circle">
            </div>
            <div id="spinnerCircle_7" class="spinner-circle">
            </div>
            <div id="spinnerCircle_8" class="spinner-circle">
            </div>
        </div>
        <div class="spinner-text" *ngIf="showMessage() && hasVisibleMessages()">{{spinnerText}}</div>
    </div>
</div>
