import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {PropertyModule} from '../../../providers/property/property.module';
import {FocusOnShowModule} from '../../../utils/focus-on-show/focus-on-show.module';
import {AuthenticationService} from './authentication.service';
import {AuthenticationErrorComponent} from './error/authentication-error.component';
import {LogoutComponent} from './logout/logout.component';
import {AuthenticationStore} from './authentication.store';

@NgModule({
	declarations: [AuthenticationErrorComponent, LogoutComponent],
	exports: [AuthenticationErrorComponent, LogoutComponent],
	imports: [CommonModule, FocusOnShowModule, PropertyModule],
	providers: [AuthenticationService, AuthenticationStore]
})
export class AuthenticationModule {}
