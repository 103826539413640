import {head, includes, isEmpty, last, reverse, size} from 'lodash';

const FIRST_LETTER = 0;
const FULL_NAME_SIZE = 2;

const initialsFromName = (name: string): string => {
	const photoNameSplit = includes(name, ',') ? reverse(name.split(/,\s*/)) : name.split(' ');

	return head(photoNameSplit).charAt(FIRST_LETTER) + (size(photoNameSplit) >= FULL_NAME_SIZE ? last(photoNameSplit).charAt(FIRST_LETTER) : '');
};

export const getInitialsFromName = (name: string): string => isEmpty(name) ? undefined : initialsFromName(name);
