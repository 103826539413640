import {forEach, invokeMap, isEmpty, values} from 'lodash';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';

import {SessionStorageCoreService} from '../platform/caching/sessionStorage.service';
import {TIMEOUT_DELAY} from './authentication.constants';

export class ExternalAuthCoreService {
	public externalAuthCallbackMap: any = {};
	public serviceLoginTimeoutMap: any = {};
	public EXTERNAL_SERVICE_IDENTIFIERS = 'external-services';

	constructor(private window: Window, private logService, private apiService: ApiService, private sessionStorageService: SessionStorageCoreService) {}

	public getExternalServices = (): Promise<any> => {
		const allServices = this.sessionStorageService.get(this.EXTERNAL_SERVICE_IDENTIFIERS);

		if (allServices) {
			return Promise.resolve(allServices);
		}
		return this.apiService.get('external.services.get', undefined, undefined, Transport.SOCKET, false)
			.then(data => {
				this.sessionStorageService.set(this.EXTERNAL_SERVICE_IDENTIFIERS, data);
				return data;
			});
	};

	public initialize = (): void => {
		this.getExternalServices()
			.then(services => {
				let extServices = services || [];

				if (!services.length) {
					extServices = values(services);
				}

				/**
				 * Loop over each service and check if login success and login url present.
				 * then create frame and set the src to login url.
				 */
				forEach(extServices, service => {
					let frame;

					this.serviceLoginTimeoutMap[service.serviceId] = service.loginTimeout;

					if (this.sessionStorageService.get(this.combineAuthSessionKey(service.serviceId)) !== 'true' &&
						this.sessionStorageService.get(this.combineAuthInitiatedKey(service.serviceId)) !== 'true' &&
						service.enable && service.loginUrl) {
						frame = this.window.document.createElement('iframe');
						frame.src = service.loginUrl;
						frame.id = `ext-frame-${service.serviceId}`;
						frame.className = 'ext-service-frame';
						frame.setAttribute('tabindex', '-1');
						this.logService.info('External Login initiated. Service Name: ', service.serviceId);
						this.window.document.body.appendChild(frame);
						this.sessionStorageService.set(this.combineAuthInitiatedKey(service.serviceId), 'true');
						this.fetchKeepAliveData(service);
					}
				});
			});
	};

	public fetchKeepAliveData = (service): void => {
		if (service.postMessageEnable) {
			this.apiService.get('authnssid.get', undefined, undefined, Transport.SOCKET).then(data => {
				this.sendKeepAlivePostMessage(data, service.serviceId, service.url);
			});
		}
	};

	public sendKeepAlivePostMessage = (data, serviceId, targetOrigin): void => {
		const checkExist = setInterval(() => {
			// eslint-disable-next-line no-useless-escape
			const authnssid = !isEmpty(data) && data.charAt(0) === '\"' ? data.slice(1, data.length - 1) : data;
			const obj =
				{
					type: '',
					action: 'authn_ssid',
					configuration: {
						data: authnssid,
						serviceName: 'WFD'
					}
				};

			if (!this.sessionStorageService.get(`authnssidSent${serviceId}`)) {
				$(`#ext-frame-${serviceId}`).on('load', () => {
					const iframeEle = $(`iframe[id*=ext-frame-${serviceId}]`)[0] as HTMLIFrameElement;

					iframeEle.contentWindow.postMessage(obj, targetOrigin);
					this.sessionStorageService.set(`authnssidSent${serviceId}`, 'true');
					clearInterval(checkExist);
				});
			}
		}, TIMEOUT_DELAY);
	};

	public subscribeToAuth = (serviceId: string, callback: () => void): any => {
		if (this.sessionStorageService.get(this.combineAuthSessionKey(serviceId)) === 'true') {
			callback();
		} else {
			if (!this.externalAuthCallbackMap[serviceId]) {
				this.externalAuthCallbackMap[serviceId] = [];
			}
			this.externalAuthCallbackMap[serviceId].push(callback);
			setTimeout(() => {
				if (this.sessionStorageService.get(this.combineAuthSessionKey(serviceId)) !== 'true') {
					this.setLoginSuccess(serviceId);
				}
			}, this.serviceLoginTimeoutMap[serviceId]);
		}
	};

	public setLoginSuccess = (serviceId: string): void => {
		const frameElem = this.window.document.querySelector(`#ext-frame-${serviceId}`);

		if (frameElem) {
			// eslint-disable-next-line no-extra-boolean-cast
			if (!!frameElem.remove) {
				frameElem.remove();
			} else if (frameElem.parentNode) {
				frameElem.parentNode.removeChild(frameElem);
			}
		}
		this.sessionStorageService.set(this.combineAuthSessionKey(serviceId), 'true');
		this.logService.info('External Login completed. Service Name: ', serviceId);
		invokeMap(this.externalAuthCallbackMap[serviceId], 'call', null);
		this.externalAuthCallbackMap[serviceId] = [];
	};

	public allowReloginToExternalService = (serviceId: string): void => {
		this.sessionStorageService.set(this.combineAuthSessionKey(serviceId), 'false');
		this.sessionStorageService.set(this.combineAuthInitiatedKey(serviceId), 'false');
	};

	private combineAuthSessionKey = (serviceId: string): string => `auth-${serviceId}`;

	private combineAuthInitiatedKey = (serviceId: string): string => `auth-initiated-${serviceId}`;
}