import {Injectable} from '@angular/core';
import {camelCase, forIn, get, set} from 'lodash';
import {map, BehaviorSubject, Observable, Subject, first} from 'rxjs';

import {SlideoutState} from '../core/generic/slideOutContainer/slideout.interfaces';

import {reducerHelper, SLIDEOUT_INITIAL_STATE} from '../core/generic/slideOutContainer/slideout.reducer';
import {actionsHelper} from '../core/generic/slideOutContainer/slideout.actions';

@Injectable({
	providedIn: 'root'
})
export class SlideOutActionService {
	public actions;
	private slideOutSource = {...SLIDEOUT_INITIAL_STATE};
	private slideOutSubject = new BehaviorSubject({...SLIDEOUT_INITIAL_STATE});
	private actionSubject = new Subject<any>();

	constructor() {
		this.actions = this.slideoutActionsHelper();
	}

	public select = (path: string[]): Observable<SlideoutState[keyof SlideoutState]> =>
		this.slideOutSubject.pipe(map(state => get({slideout: state}, path)));

	public dispatch = (action: any): void => {
		this.actionSubject.next(action);
		this.slideOutSource = get(reducerHelper(), action.type)(this.slideOutSource, action);
		this.slideOutSubject.next(this.slideOutSource);
	};

	public slideoutActionsHelper = (): Partial<any> => {
		const actions = {};

		forIn(actionsHelper(), (val: (...params) => any, key: string) => {
			set(actions, camelCase(key), (...params) => ({type: key, payload: val(...params)}));
		});
		return actions;
	};

	public extraActionHandler = (actionType: string, dispatch: (action) => void): void => {
		this.actionSubject.subscribe(action => {
			if (actionType === action.type) {
				dispatch(action);
			}
		});
	};

	public getActionObservable = <T = string>(actionType: string): Observable<T> =>
		this.actionSubject.pipe(first(action => actionType === action.type));

	public restore = (store: SlideoutState): void => {
		const newStore = {...SLIDEOUT_INITIAL_STATE, ...store};

		this.slideOutSource = newStore;
		this.slideOutSubject.next(this.slideOutSource);
	};
}
