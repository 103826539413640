export enum SESSION_EVENTS {
	PING_EXPIRING = 'session.ping.expiring',
	EXTENDED = 'session.extended',
	EXPIRING = 'session.expiring',
	EXPIRED = 'session.expired',
	ENDED = 'session.ended',
	RESET = 'reset.session',
	RECONNECT = 'reconnect.session',
	UNAVAILABLE = 'session.unavailable',
	END = 'end.session',
	EXTEND = 'extend.session'
}

export const SESSION_TIMEOUT_PROPERTIES = [
	{
		name: 'container_web-common_session_strings.properties',
		path: '/components/zed-core/properties/session/'
	}
];

export const SESSION_EXPIRATION_LAST_TICK = 'SESSION_EXPIRATION_LAST_TICK';
export const MILLISECONDS_IN_SECOND = 1000;
export const REMOVE_ARIA_LIVE_TIMEOUT = 5000;
export const AUTH0_CLOSE_POPUP_TIMEOUT = 90;