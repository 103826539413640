import {handleActions} from 'redux-actions';
import {get} from 'lodash';

import {authenticate, logout} from './authentication.actions';
import {MESSAGES} from './authentication.constants';
import {getAuthenticationErrorMessage, getErrorHeading} from './authentication.failure.message';
import {AuthenticationState} from './authentication.state';

export const AUTHENTICATION_DEFAULT_STATE: AuthenticationState = {
	error: '',
	errorHeading: MESSAGES.UNAUTHORIZED,
	authenticationErrorMessage: MESSAGES.UNAUTHORIZED_MESSAGE,
	isAuthenticated: false
};

export const authenticateReduce = (
	state: AuthenticationState,
	{payload, error}: {payload: Partial<AuthenticationState>; error: boolean}
): AuthenticationState => {
	const isAuthenticated = !error;
	const origError: Error = get(payload, 'error', payload);
	const authError = error ? origError : undefined;
	const authenticationErrorMessage = error ? getAuthenticationErrorMessage(origError) : MESSAGES.UNAUTHORIZED_MESSAGE;
	const errorHeading = error ? getErrorHeading(origError) : MESSAGES.UNAUTHORIZED;

	return {
		...state,
		isAuthenticated,
		authenticationErrorMessage,
		errorHeading,
		error: authError
	};
};

export const logoutReduce = (state: AuthenticationState): AuthenticationState => ({
	...state,
	isAuthenticated: false
});

const authenticationReducer = handleActions(
	{
		[authenticate]: authenticateReduce,
		[logout]: logoutReduce
	},
	AUTHENTICATION_DEFAULT_STATE
);

export default authenticationReducer;
