<div class="modal-header border-0">
	<h3 id="sessionTimeoutModalTitle" class="modal-title">{{'app.core.auth0.session.timeout.title' | property}}</h3>
</div>
<div class="modal-body">
	{{'app.core.auth0.session.timeout.body' | property}}
	<div class="auth0TimeLeft">{{timeLeft}}</div>
</div>
<krn-ng-screen-reader-only id="sessionTimeoutModalDesc" [ariaLive]="isIOSDevice ? 'polite' : 'off'" [removeTimeout]="removeAriaLiveTimeout"
	[content]="{text: 'app.core.auth0.session.timeout.body' | property}">
</krn-ng-screen-reader-only>
<div class="modal-footer border-0">
	<ukg-button
		[attr.aria-label]="'app.core.auth0.session.timeout.decline' | property"
		emphasis="mid"
		(click)="decline()">
		{{'app.core.auth0.session.timeout.decline' | property}}
	</ukg-button>
	<ukg-button
		[attr.aria-label]="'app.core.auth0.session.timeout.confirm' | property"
		(click)="confirm()">
		{{'app.core.auth0.session.timeout.confirm' | property}} 
	</ukg-button>
</div>
