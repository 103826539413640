import {EmbeddedMenuCategory, LegacyEmbeddedMenuItem} from '../../embedded/embedded.interfaces';
import {CordovaPluginWindow} from '../mobile/cordova-plugin-window.interface';
import {LocalAuthHandler} from '../mobile/local-auth.interface';
import {MappingHandler} from '../mobile/mapping.interface';
import {ClientContainer, ScanConfig, ScanResult, TabType, DispatchParam, CommunicationEventName} from './client-container.interface';
import {GlobalData} from '../../one-app/one-app-event-bus.interfaces';

import {getTopWfdWindow, setLocationUrl} from '../../iframe-framework/iframe-message-handler.utility';
import {PropertyFactory} from '../../property/property.factory';
import {PropertyFilterConfig} from '../../property/property.filter';
import {SessionStorage} from '../caching/sessionStorage.service';
import {BaseWifiLocationService} from '../services/base-wifi-location.service';
import {GeolocationCoreService} from '../services/geolocation.service';
import {LOCAL_AUTH_INT_ERR_CANNOT_USE} from '../mobile/local-auth.constants';
import {MAPPING_INT_ERR_CANNOT_USE} from '../mobile/mapping.constants';

export class BrowserContainerCoreService implements ClientContainer {
	constructor(protected window: CordovaPluginWindow, protected sessionStorage: SessionStorage) {}

	public scan(scanConfig: ScanConfig, callback?: () => void): Promise<ScanResult> {
		callback();
		return null;
	}

	public geolocation(): GeolocationCoreService {
		return new GeolocationCoreService(this.window.top, null, this.sessionStorage);
	}

	public print(): void {
		this.window.print();
	}

	public wifiLocation = (propertyFactory: PropertyFactory, propertyFilter: PropertyFilterConfig): BaseWifiLocationService =>
		new BaseWifiLocationService(this.getTopWfdWindow(), this.supportsWifiLocation(), propertyFactory, propertyFilter);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public localAuth(propertyFactory?: PropertyFactory, propertyFilter?: PropertyFilterConfig): LocalAuthHandler {
		return {runLocalAuth: () => Promise.reject(LOCAL_AUTH_INT_ERR_CANNOT_USE)};
	}

	public openNewTab = (url: string): void => this.window.top.open(url, TabType.BLANK);

	public supportsExport = (): boolean => true;

	public supportsLocalAuth = (): boolean => false;

	public supportsPrint = (): boolean => true;

	public supportsWifiLocation = (): boolean => false;

	public allowExternalEmbedding = (): boolean => this.window.location === this.window.top.location;

	public supportsNewTab = (): boolean => true;

	public getTopWfdWindow = (): CordovaPluginWindow => getTopWfdWindow(this.window);

	public navigate = (window: Window, href: string, useCurrentWin = false): void => {
		setLocationUrl(window, href, useCurrentWin);
	};

	public supportsMapping = (): boolean => false;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public mapping(propertyFactory?: PropertyFactory, propertyFilter?: PropertyFilterConfig): MappingHandler {
		return {launchMap: () => Promise.reject(MAPPING_INT_ERR_CANNOT_USE)};
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public talkNavigation(deeplink: string, tenantId: string, vanityUrl: string): void {
		this.openNewTab(deeplink);
	}

	public swpNavigation(deeplink: string): void {
		this.openNewTab(deeplink);
	}

	public navigatedBack(): void {
		// do nothing
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public onRoleSwitched(data): void {
		this.window.location.href = '/';
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public signalContentComplete(pageIdentifier: string, senderID: string): void {
		// do nothing
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public isOneAppVersionOutOfDate(senderID: string): void {
		// do nothing
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public setGlobalData(data: GlobalData, senderID: string): void {
		// do nothing
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public getGlobalData(key: string, senderID: string): Promise<any> {
		return Promise.resolve(null);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public b64DecodeJSON(b64jsonString: any): any {
		//do nothing
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public getColorSync(colorsData: any): void {
		// do nothing
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public dispatchEvent(dispatchParam: DispatchParam): void {
		//
	}

	public getActionSubscription(action, callback): void {
		// do nothing
	}

	public getEventName = (): CommunicationEventName => null;

	public getMenuItems = (): Promise<EmbeddedMenuCategory[] | LegacyEmbeddedMenuItem[]> => Promise.resolve([]);

	public replaceState = (path: string): void => {
		this.window.history.replaceState(null, null, path);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public checkFeatureFlag(featureFlag: string): Promise<boolean> {
		return Promise.resolve(false);
	}
	
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public openADPDimSearch(key, id, brand): void {
		// do nothing
	}
}
