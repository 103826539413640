import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {
	HelpSearchResultSort,
	HelpSortParams,
	ResultSortByData
} from '../../../../../../core/navBar/contextual-help/contextual-help.interfaces';

import {NO_RESULTS} from '../../../../../../core/navBar/contextual-help/contextual-help.constants';
import {
	doKeyPressAction,
	sortResultsBy,
	subscribeSearchResultSortChange,
	isCoveoEngine
} from '../../../../../../core/navBar/contextual-help/contextual-help.utils';
import {AggregationsType} from '../../../../../../core/navBar/contextual-help/contextual-help-search.constants';
import {PropertyFilterPipe} from '../../../../../../providers/property/property.filter.pipe';
import {ContextualHelpService} from '../contextual-help/contextual-help.service';

const sortKeyRelevance = 'html.navbar.contextualHelp.search.sortKey.relevance';

@Component({
	selector: 'krn-ng-contextual-help-search-result',
	templateUrl: './contextual-help-search-result.component.html',
	styleUrls: ['./contextual-help-search-result.component.less']
})
export class ContextualHelpSearchResultComponent implements OnInit, OnDestroy, HelpSearchResultSort {
	@Output() public toggleCommunitySearch = new EventEmitter();
	@Output() public setContentBeingRefined = new EventEmitter<boolean>();
	public sortByLabel: string;
	public dropdownIsOpen = false;
	public selectedSortBy;
	public subscription;
	public sortState: HelpSortParams = {};
	public newSearch = true;
	public resultSortByData: ResultSortByData[];

	constructor(private contextualHelpService: ContextualHelpService, private propertyFilter: PropertyFilterPipe) {}

	public ngOnInit(): void {
		this.sortState.resultCount = 0;
		this.sortState.loading = true;
		this.sortState.contentBeingRefined = false;
		this.setDefaultSelectedSortBy();
		this.subscription = this.contextualHelpService.subscribeToStore(this.subscribeSearchResultChange);
		this.sortByLabel = this.propertyFilter.transform('html.navbar.contextualHelp.search.sortBy');
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public backToHelp(): void {
		this.toggleCommunitySearch.emit();
	}

	public openRefinePanel(): void {
		this.sortState.contentBeingRefined = true;
		this.setContentBeingRefined.emit(true);
		this.contextualHelpService.refineOpen();
		this.subscription.unsubscribe();
	}

	public sortBy(sortKey: string): void {
		sortResultsBy(sortKey, this.sortState.sortby, this.sortState.resultCount, this.contextualHelpService);
	}

	public markRefineClosed(): void {
		this.sortState.contentBeingRefined = false;
		this.setContentBeingRefined.emit(false);
		this.subscription = this.contextualHelpService.subscribeToStore(this.subscribeSearchResultChange);
	}

	public backToHelpOnKeyPressAction(keyCode: number): void {
		if (doKeyPressAction(keyCode)) {
			this.backToHelp();
		}
	}

	public onClose(): void {
		this.dropdownIsOpen = false;
	}

	public subscribeSearchResultChange = (state): void => {
		if (state?.searchResult?.results?.total > NO_RESULTS && !state?.loading) {
			this.newSearch = false;
		}
		subscribeSearchResultSortChange(this.sortState, state);
		this.getResultSortByData(state);
	};

	public resetNewSearch(): void {
		this.setDefaultSelectedSortBy();
		this.newSearch = true;
	}

	private setDefaultSelectedSortBy(): void {
		this.selectedSortBy = {key: '_score', name: this.propertyFilter.transform(sortKeyRelevance)};
	}

	private getResultSortByData(state): void {
		this.resultSortByData = [{key: '_score', name: this.propertyFilter.transform('html.navbar.contextualHelp.search.relevance')}];
		if (!isCoveoEngine(state)) {
			this.resultSortByData.unshift({
				key: AggregationsType.CreatedDate,
				name: this.propertyFilter.transform('html.navbar.contextualHelp.search.createdDate')
			});
		}
	}
}
