<li>
	<a *ngIf="!itemConfig.isNested"
		[ngClass]="{'selected': isSelected}"
		(click)="handleMenuSelection($event)"
		id="{{itemConfig.id}}_link"
		[krn-ng-attributes]="{
				'aria-posinset': itemConfig?.positionItem,
				'aria-setsize': itemConfig?.totalCategory,
				'aria-current': isSelected && 'page',
				'aria-label': getLinkAriaLabel(),
				'automation-id': itemConfig?.automationId,
				href: getRouteUrl(),
				target: itemConfig?.target,
				title: itemConfig?.tooltip
			}"
		[krn-ng-move-shift-tab-to]="itemConfig.shiftTabToId"
		[krn-ng-move-tab-to]="itemConfig.tabToId"
		[tooltip]="itemConfig?.tooltip"
		role="menuitem"
		(keydown)="handleSubCategoryKeyDown($event)"
		tabindex="0">
		<ng-container *ngTemplateOutlet="menuLinkParts"></ng-container>
	</a>
	<div class="menu-category"
				*ngIf="itemConfig.isNested"
				[class.expanded]="isExpanded()"
				[class.collapsed] = "isCollapsed()"
				(keydown)="toggleCategoryByKey($event)">
		<div id="{{itemConfig.id}}_category"
					class="expander"
					(click)="toggleCategory()"
					[krn-ng-attributes]="{
						'aria-expanded': itemConfig?.options?.expanded,
						'aria-posinset': itemConfig?.positionItem,
						'aria-setsize': itemConfig?.totalCategory,
						'aria-label': getCategoryAriaLabel(),
						tabindex: '0',
						'automation-id': itemConfig?.automationId,
						title: itemConfig?.tooltip
					}"
					[tooltip]="itemConfig?.tooltip"
					role="menuitem">
			<span>{{itemConfig.text}}</span>
			<span *ngIf="!itemConfig.isSearch" id="{{itemConfig.id}}_title" class="menu-expander icon-k-expander">
			</span>
		</div>
		<ul class="submenu" [hidden]="!isExpanded()" role="menubar" krn-ng-modal-keyboard-navigation modalRole="searchableMenu">
			<krn-ng-menu-item *ngFor="let item of itemConfig.innerMenu; index as index" [menuData]="menuData" [itemConfig]="item" [isSearch]="isSearch" [isSelected]="item.isSelected" [itemIndex]="itemIndex" [subIndex]="index + 1"></krn-ng-menu-item>
		</ul>
	</div>

	<div class="help-accessibility-speaker" style="position: absolute; top: 0px; left: -9999px;" aria-live="polite"></div>

	<ng-template #menuLinkParts>
	<span id="{{itemConfig.id}}_text" *ngIf="itemConfig.text">{{itemConfig.text}}</span>
	<span *ngIf="itemConfig.target" id="{{itemConfig.id}}_new_tab_icon" class="icon-k-link"></span>
	</ng-template>
</li>
