<div
  *ngIf="!useTaskDrawer"
  tabindex="-1"
  class="inner-modal"
  id="slideout-inner-modal"
  [class.visible]="showSlideOut"
  [class.no-nav]="!(navbarVisible$ | async)"
  [ngClass]="classes"
  [krn-ng-return-focus-element]="returnFocusElement"
  [return-focus-on-destroy]="returnFocus"
  [krn-ng-focus-on-show]="deviceConfigService.isMobileDevice() ? 'none' : 'slideout-inner-modal'"
  (keydown.esc)="close()"
  role="dialog"
  [attr.aria-hidden]="!showSlideOut"
  [attr.aria-label]="context?.title"
  [attr.aria-labelledby]="context?.title ? undefined : 'form-title form-subtitle'"
  aria-modal="true"
  krn-ng-modal-keyboard-navigation
  #innerModal
>
  <krn-slide-out-content [actionHandler]="actionHandler" [context]="currentContext">
    <ng-content class="slideout-container-inner"></ng-content>
  </krn-slide-out-content>
  </div>
<div *ngIf="!useTaskDrawer" tabindex="-1" class="slideout__mask" #slideoutMask></div>

<ukg-task-drawer-container [disableInert]="true" *ngIf="useTaskDrawer && hasTaskDrawerContainer" [ngClass]="classes">
  <ukg-task-drawer
    krn-ng-modal-keyboard-navigation
    id="slideout-inner-modal"
    visualState="overlay"
    [width]="isInnerSlideOut ? '20rem' : '22.5rem'"
    [class.mobile-task-drawer]="deviceConfigService.isMobileDevice()"
    [krn-ng-return-focus-element]="returnFocusElement"
    [return-focus-on-destroy]="returnFocus"
    [closeButtonId]="taskDrawerCloseButtonId"
    [subtitle]="subtitle"
    [isOpen]="taskDrawerIsOpen"
    [heading]="heading"
    [hasPadding]="false"
    (keydown.esc)="actionHandler.close()">
    <krn-slide-out-content [actionHandler]="actionHandler" [context]="currentContext">
      <div
        *ngIf="slideOutAccessibilityService.isNeedIosDeviceHelper()"
        [id]="slideOutIosDeviceHelperId"
        class="sr-only"
        tabindex="-1">
      </div>
      <ng-content select="[slideOutTaskDrawerSelect]" class="slideout-container-inner"></ng-content>
    </krn-slide-out-content>
  </ukg-task-drawer>
</ukg-task-drawer-container>
