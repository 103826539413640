export const CURRENT_CONTEXT_KEY = 'currentContext';
export const SELECT_TILE_ID_KEY = 'otherContext.selectedTileId';

export const DEFAULT_CONTEXT = {
	timeframeId: null,
	hyperfind: {
		id: null,
		type: null // can be 'hyperfind' or 'location'
	},
	peopleIdList: null,
	dateSpan: {
		startDate: null,
		endDate: null
	},
	otherContext: {}
};

export const CONTEXT_UKGPRO = 'USParams';

export const CONTEXT_WFM = 'context';
