import {indexOf, isString, remove, split, startsWith, union, values} from 'lodash';

import {Classes, MessageType} from './message.interface';

import {getIconForType} from './message.utility';
import {ALERT_CLASS_PREFIX, MESSAGE_TYPES} from './message.constant';

const NO_MATCH = -1;

export const selectClasses = (classes: Classes = [], messageType: MessageType = MESSAGE_TYPES.INFO): Classes => {
	const matched = indexOf(values(MESSAGE_TYPES), messageType) !== NO_MATCH;

	return matched ? addAlertClass(isString(classes) ? split(classes, ' ') : classes, messageType) : classes;
};

export const selectImagePath = (messageType: MessageType = MESSAGE_TYPES.INFO, iconClass?: string): string => iconClass ? undefined : getIconForType(messageType);

const addAlertClass = (classes: Classes, messageType: MessageType): Classes => {
	remove(classes, cls => startsWith(cls, ALERT_CLASS_PREFIX));
	return union(classes, [`${ALERT_CLASS_PREFIX}${messageType}`]);
};
