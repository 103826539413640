import {Inject, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

import {ApiService} from '../core/communication/api-service/api-service.interfaces';

import {ExternalAuthCoreService} from '../core/authentication//external.auth.core.service';
import {SessionStorageCoreService} from '../core/platform/caching/sessionStorage.service';
import {DataService} from './communication/data.service';
import {SessionStorageService} from './session-storage.service';
import {WindowRefService} from './window-ref.service';

@Injectable({providedIn: 'root'})
export class ExternalAuthService extends ExternalAuthCoreService {
	constructor(window: WindowRefService, logger: NGXLogger, @Inject(DataService) apiService: ApiService, sessionStorageService: SessionStorageService) {
		super(window.nativeWindow, logger, apiService, sessionStorageService as SessionStorageCoreService);
	}
}
