import {get} from 'lodash';

import {IncentiveType, ShiftIncentive, ShiftRiskInfo, ShiftRiskLevel} from '../../generic/interfaces/shift-incentive.interface';
import {PropertyFilter} from '../../property/property.interface';

import {PropertyFactory} from '../../property/property.factory';
import {FORMAT_TYPE} from './incentive-formatter-service.constants';

export class IncentiveFormatterCoreService {
	constructor(private propertyFilter: PropertyFilter, private propertyFactory: PropertyFactory, private currencyService) {
		this.propertyFactory.loadProperties([
			{
				name: 'container_web-common_converters_strings.properties',
				path: 'components/zed-core/properties/platform/'
			}
		]);
	}

	public format = (incentive: ShiftIncentive, formatType: FORMAT_TYPE = FORMAT_TYPE.SHORT): string => {
		if (incentive != null) {
			const incentiveValue = incentive.incentiveValue != null ? incentive.incentiveValue : get(incentive, 'currentValue.amount');

			switch (incentive.incentiveType) {
				case IncentiveType.MONEY_FIXED_AMOUNT:
				case IncentiveType.MONEY_FIXED_AMOUNT_PER_HOUR: {
					const formattedValue = this.currencyService.format(incentiveValue);

					return this.propertyFilter(`common.platform.converter.incentive.type.${formatType}.${incentive.incentiveType}`, [formattedValue]);
				}
				default:
					return incentiveValue ? incentiveValue.toLocaleString() : '';
			}
		}
	};

	public getShiftRiskInfo = (shiftRiskLevel: ShiftRiskLevel): ShiftRiskInfo => {
		switch (shiftRiskLevel) {
			case ShiftRiskLevel.LOW:
				return {
					formattedShiftRiskLevel: this.propertyFilter('common.platform.converter.shiftRiskLevel.LOW'),
					shiftRiskLevel
				};
			case ShiftRiskLevel.MODERATE:
				return {
					formattedShiftRiskLevel: this.propertyFilter('common.platform.converter.shiftRiskLevel.MODERATE'),
					shiftRiskLevel
				};
			case ShiftRiskLevel.HIGH:
				return {
					formattedShiftRiskLevel: this.propertyFilter('common.platform.converter.shiftRiskLevel.HIGH'),
					shiftRiskLevel
				};
			case ShiftRiskLevel.NONE:
			default:
				return {
					formattedShiftRiskLevel: '',
					shiftRiskLevel: ShiftRiskLevel.NONE
				};
		}
	};
}
