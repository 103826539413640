import {forEach, get, has, isArray} from 'lodash';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';
import {LogServiceInterface} from '../platform/services/logService/log.interface';
import {PropertyValues} from '../property/property.interface';
import {Tile} from '../tile/tile.interfaces';
import {EmbeddedMenuCategory, EmbeddedMenuItem, LegacyEmbeddedMenuItem} from './embedded.interfaces';

import {SessionStorageCoreService} from '../platform/caching/sessionStorage.service';
import {PropertyFactory} from '../property/property.factory';
import {getTileSessionStorageKey} from '../tile/tile.utility';
import {UKGPRO_MENU_ITEMS} from './embedded.constants';

export abstract class EmbeddedMenuItemsCoreService {

    constructor(protected apiService: ApiService, protected logService: LogServiceInterface, protected propertyFactory: PropertyFactory, protected sessionStorage: SessionStorageCoreService){}

    public getMenuItems = (isAll = false): Promise<EmbeddedMenuCategory[] | LegacyEmbeddedMenuItem[]> => {
        const query = isAll ? {isAll: 'true'} : undefined;
        const menuItem = this.sessionStorage.get(UKGPRO_MENU_ITEMS);

        if (menuItem) {
			return Promise.resolve(menuItem.menu);
		}

        return Promise.all([
            this.apiService.get('/embedded/getMenuItems', undefined, query, Transport.REST, true, null, true, {observe: 'response'}),
            this.propertyFactory.loadProperties([
                {
                    name: 'container_web-common_tileLibrary_strings.properties',
                    path: 'components/zed-core/properties/tileLibrary/'
                },
                {
                    name: 'wfp_web-controlcenter_controlcenter_strings.properties',
                    path: 'apps/controlCenter/pages/mainPage/js/properties/'
                },
                {
                    name: 'container_web-common_embedded_strings.properties',
                    path: 'components/zed-core/properties/embedded/'
                }
            ])
        ]).then(([menusRes, propertiesRes]) => {
            const menuItems: EmbeddedMenuCategory[] | LegacyEmbeddedMenuItem[] = this.getData(menusRes, 'menus');
            const properties: PropertyValues = get(propertiesRes, 'properties', {});

            this.translateMenuItems(properties, menuItems);
            this.logService.info(menusRes);

            this.translateMenuItems(properties, menuItems);
            this.sessionStorage.set(UKGPRO_MENU_ITEMS, {menu: menuItems});
            this.setTilesToSessionStorage(this.getData(menusRes, 'tiles'));
            return menuItems;
        }).catch(error => {
            this.logService.error(error);
            return null;
        });
    };

    private setTilesToSessionStorage(tiles: Tile[]): void {
		forEach(tiles, (tileItem: Tile) => {
			this.sessionStorage.set(getTileSessionStorageKey(tileItem.attributes.id), tileItem);
		});
	}

    private translateMenuItems(properties: PropertyValues, menus: EmbeddedMenuCategory[] | EmbeddedMenuItem[] | LegacyEmbeddedMenuItem[]): void {
        menus.forEach(menu => {
            if (isArray(menu.menu)) {
                this.translateMenuItems(properties, menu.menu);
            }
            if (has(menu, 'name') && properties[menu.name]) {
                menu.name = properties[menu.name];
            }
            if (has(menu, 'text') && properties[menu.text]) {
                menu.text = properties[menu.text];
            }
            if (has(menu, 'tooltip') && properties[menu.tooltip]) {
                menu.tooltip = properties[menu.tooltip];
            }
        });
    }

    public abstract getData(menus: EmbeddedMenuCategory[] | LegacyEmbeddedMenuItem[], key: string): any;
}
