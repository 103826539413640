import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {AttributesModule} from '../../../utils/attributes/attributes.module';
import {SingleSelectPickerBaseComponent} from './single-select-picker-base.component';
import {PropertyModule} from '../../../providers/property/property.module';
import {TooltipConfigProvider} from '../../../providers/tooltip-config.provider';

@NgModule({
	declarations: [SingleSelectPickerBaseComponent],
	exports: [SingleSelectPickerBaseComponent],
	imports: [AttributesModule, CommonModule, PropertyModule, TooltipModule.forRoot()],
	providers: [TooltipConfigProvider]
})
export class SingleSelectPickerBaseModule {}
