import {CordovaPluginWindow} from '../mobile/cordova-plugin-window.interface';

const errCB = (): object  => ({status: false, msg: 'Plugin call has failed.'});

export const runAnyAuth = (window: CordovaPluginWindow, msg: string): Promise<any>  => new Promise((resolve, reject) => {
	try {
		window.kronosLocalAuth.anyAuth(
			msg,
			res => {
				resolve(res);
			},
			res => {
				resolve(res);
			}
		);
	} catch (error) {
		resolve(errCB());
	}
});

export const runBioAuth = (window: CordovaPluginWindow, msg: string): Promise<any> => new Promise((resolve, reject) => {
	try {
		window.kronosLocalAuth.bioAuth(
			msg,
			res => {
				resolve(res);
			},
			res => {
				resolve(res);
			}
		);
	} catch (error) {
		resolve(errCB());
	}
});
