import {A11yAttrs, FormElemAttrs, PopupElementAttrs} from '../../platform/attributes.interfaces';

export enum SimpleInputType {
	BUTTON = 'button',
	EMAIL = 'email',
	NUMBER = 'number',
	PASSWORD = 'password',
	SEARCH = 'search',
	TEXT = 'text'
}
export interface SimpleInputAttrs extends A11yAttrs, FormElemAttrs, PopupElementAttrs {
	dir?: string;
	name?: string;
	readonly?: boolean;
	maxLength?: number;
	type: SimpleInputType;
}
