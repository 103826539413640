import {get, isEmpty, isUndefined, omitBy, some, toLower} from 'lodash';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';
import {RequestParams} from './permissions.interfaces';

export class PermissionsCoreService {
	constructor(private apiService: ApiService) {}

	public fetchPermissions(params: RequestParams): Promise<object> {
		return this.apiService.get('get/permissions', undefined, omitBy(params, isUndefined), Transport.REST, false);
	}

	public isAccessGranted(permissions: object, facpName?: string, action?: string): boolean {
		const actionLowerCase = toLower(action);
		const foundPermission = get(permissions, facpName);

		if (!foundPermission) {
			return false;
		}

		return action ? some(foundPermission.actions, currentAction => actionLowerCase === toLower(currentAction)) : !isEmpty(foundPermission.actions);
	}
}
