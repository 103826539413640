import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {FactoryProvider, Injector} from '@angular/core';

import {ClientContainerContextService} from '../clientContainer/client-container-context.service';
import {DeviceConfigService} from '../device-config.service';
import {DataService} from './data.service';
import {PrimusSocketService} from './primus-socket.service';
import {SocketService} from './socket.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DataServiceProvider: FactoryProvider = {
	provide: DataService,
	deps: [Location, HttpClient, Injector, ClientContainerContextService],
	useFactory: getDataService
};

export function getDataService(
	location: Location,
	httpClient: HttpClient,
	injector: Injector,
	clientContainerContextService: ClientContainerContextService
): DataService {
	return (location.path().startsWith('/component/') || location.path().startsWith('/ukgpro/')) && !clientContainerContextService.isEmbedded()
		? new DataService(injector.get<DeviceConfigService>(DeviceConfigService), httpClient)
		: new DataService(injector.get<DeviceConfigService>(DeviceConfigService), httpClient, injector.get<PrimusSocketService>(SocketService));
}
