import {filter, forEach, includes, isEmpty, keys, map, mapValues, merge, omit, transform, uniqueId, values} from 'lodash';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';
import {FetchedProperty, Property, PropertyState, PropertyValues} from './property.interface';

import {PreferencesCoreService} from '../platform/preferences/preferences.service';
import {PropertyTimestampService} from './property-timestamp.service';
import {PropertyFileFactory} from './propertyFile.factory';
import {PropertyStateCoreService} from './propertyState.core.service';
import {GET_PROPERTY_FLAG} from './property.utility';

export interface PropertyFactoryConfig {
	loadProperties: (propertiesToLoad: Property[]) => Promise<PropertyState>;
}

export class PropertyFactory {
	private readFromFileSystemPromise;
	private allPropertiesRequests = {};

	constructor(
		private apiService: ApiService,
		private propertyStateService: PropertyStateCoreService,
		private propertyFileFactory: PropertyFileFactory,
		private preferencesService: PreferencesCoreService,
		private restPropertyService: boolean,
		private promiseWrapper: PromiseConstructor = Promise,
		private logService,
		private sessionStorageService,
		private propertyTimestampService: PropertyTimestampService
	) {}

	public loadProperties(propertiesToLoad: Property[], readFromCache = true): Promise<PropertyState> {
		const filteredProperties = filter(propertiesToLoad, (property: Property) => !this.propertyStateService.isFileLoaded(property.name));

		if (isEmpty(filteredProperties)) {
			return this.promiseWrapper.resolve(this.propertyStateService.getPropertiesState());
		}

		const propertiesToRequest = filter(filteredProperties, (property: Property) => !includes(keys(this.allPropertiesRequests), property.name));

		const sendRequestPromise = this.createRequest(propertiesToRequest, readFromCache);

		const propertyFileNames = {};

		filteredProperties.map((prop: Property) => {
			propertyFileNames[prop.name] = undefined;
		});
		const currentRequestedProperties = mapValues(propertyFileNames, (property, name) => this.allPropertiesRequests[name] || sendRequestPromise);

		merge(this.allPropertiesRequests, currentRequestedProperties);

		return this.promiseWrapper.all(values(currentRequestedProperties)).then(() => this.propertyStateService.getPropertiesState());
	}

	public getPropertyByKey(key: any): any {
		return this.apiService.find(`property/get?key=${key}&random=${Math.random()}`, undefined, Transport.REST, false, undefined, undefined, {
			requireAuthenticate: false
		});
	}

	private createRequest(properties: Property[], readFromCache: boolean): Promise<PropertyState> {
		let fetchPropertiesPromise = this.promiseWrapper.reject('rejected').catch(() => null);

		if (!isEmpty(properties)) {
			fetchPropertiesPromise = this.fetchReadFromFileSystemFlag()
				.then(response => {
					const readFromFileSystem = this.restPropertyService ? response : response.data;

					if (readFromFileSystem) {
						return this.propertyFileFactory.loadPropertiesFromFileSystem(properties);
					}
					return this.fetchProperties(properties, readFromCache);
				})
				.then(() => {
					this.allPropertiesRequests = omit(this.allPropertiesRequests, map(properties, 'name'));
				});
		}
		return fetchPropertiesPromise;
	}

	private configureRequest(fileIds, readFromCache: boolean): any {
		return this.propertyTimestampService.getPropertyTimestamp(this.apiService, this.logService, this.sessionStorageService).then((timestamp): any => {
			const query = {context: fileIds, timestamp: timestamp.translationTimestamp !== '' ? timestamp.translationTimestamp : null};
			const requireAuthenticate = false;

			return this.restPropertyService && readFromCache
				? this.apiService.find('property/get', this.getCacheQuery(query), Transport.REST, undefined, undefined, undefined, {requireAuthenticate})
				: this.apiService.find('/property/get', query, undefined, undefined, undefined, undefined, {requireAuthenticate});
		});
	}

	private getCacheQuery(query): any {
		try {
			const localePolicy = this.preferencesService.getPreferences().localePolicy;

			return {...query, cacheControl: `${localePolicy.languageCode}-${localePolicy.countryCode}`};
		} catch {
			return {...query, cacheControl: new Date().toISOString() + uniqueId()};
		}
	}

	private fetchProperties(propertiesToLoad: Property[], readFromCache: boolean): Promise<PropertyState | void> {
		const fileIds = map(propertiesToLoad, 'name');

		return this.configureRequest(fileIds, readFromCache).then(
			response => {
				forEach(response, (propertyPairs, propertyFile) => {
					const propertiesFromResponse: PropertyValues = transform(
						propertyPairs,
						(result, property: FetchedProperty) => {
							result[property.propertyName] = property.propertyValue;
						},
						{}
					);

					this.propertyStateService.dispatchPropertyAction(propertyFile, propertiesFromResponse);
				});
				return this.propertyStateService.getPropertiesState();
			},
			error => {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		);
	}

	private configurePropertyFlagRequest(): any {
		return this.restPropertyService
			? this.apiService.find(GET_PROPERTY_FLAG, undefined, Transport.REST, undefined, undefined, undefined, {requireAuthenticate: false})
			: this.apiService.find(`/${GET_PROPERTY_FLAG}`, undefined, undefined, undefined, undefined, undefined, {requireAuthenticate: false});
	}

	private fetchReadFromFileSystemFlag(): Promise<{data: boolean}> {
		if (!this.readFromFileSystemPromise) {
			this.readFromFileSystemPromise = this.configurePropertyFlagRequest().catch(err => {
				this.readFromFileSystemPromise = null;
				return err;
			});
		}
		return this.readFromFileSystemPromise;
	}
}
