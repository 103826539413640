
import {Component, Inject, Input, OnChanges, Optional, SimpleChanges} from '@angular/core';
import {has, isEmpty} from 'lodash';

import {MessageType} from '../../../../core/generic/message/message.interface';

import {selectImagePath} from '../../../../core/generic/message/message.selectors';
import {BASE_PATH} from '../../../../services/communication/data.config';

@Component({
	selector: 'krn-ng-message-base',
	template: '<div></div>'
})
export class MessageBaseComponent implements OnChanges {
	@Input() public messageType: MessageType;
	@Input() public iconClass: string;

	public imagePath: string;

	constructor(@Optional() @Inject(BASE_PATH) private basePath: string) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (has(changes, 'iconClass') || has(changes, 'messageType')) {
			this.imagePath = this.selectImagePath();
		}
	}

	protected selectImagePath() {
		const imagePath = selectImagePath(this.messageType, this.iconClass);

		this.basePath = this.basePath || '';

		return isEmpty(imagePath) ? imagePath : `${this.basePath}${imagePath}`;
	}
}
