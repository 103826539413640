import {Store} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {head, includes} from 'lodash';
import {Observable} from 'rxjs';

import {setVisible} from '@kronos/zed/src/lib/core/navBar/nav-bar.actions';
import {NavBarState} from '@kronos/zed/src/lib/core/navBar/nav-bar.state';
import {BootstrapService} from '@kronos/zed/src/lib/services/bootstrap.service';
import {SessionService} from '@kronos/zed/src/lib/ui/framework/session/session.service';

import {AppService} from './app.service';
import {setKioskMode} from './kiosk/core/kiosk.actions';

@Injectable()
export class RouteResolver implements Resolve<any> {
	constructor(
		protected store: Store<NavBarState>,
		private bootstrapService: BootstrapService,
		private injector: Injector,
		private appService: AppService
	) {}

	public resolve(route: ActivatedRouteSnapshot /*, state: RouterStateSnapshot */): Observable<any> | Promise<any> | any {
		const routePath = head(route.url).path;
		const showNavBar = this.appService.getShowNavBar();

		const isKiosk = includes(['kiosk'], routePath);

		if (showNavBar) {
			this.injector.get(SessionService);
		}

		return this.bootstrapService.bootstrap().then(() => {
			this.store.dispatch(setVisible(showNavBar));
			this.store.dispatch(setKioskMode(isKiosk));
		});
	}
}
