import {NgModule} from '@angular/core';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';

import {AppService} from './app.service';
import {HomeRouteResolver} from './home/home-route.resolver';
import {RouteGuard} from './route.guard';
import {RouteResolver} from './route.resolver';
import {EssRouteResolver} from './ess/ess-route.resolver';
import {ViewPaycodeAnalyzerResolver} from './schedule/plugins/view-paycode-analyzer/view-paycode-analyzer.resolver';
import {SkillsRouteResolver} from './skills/skills-route.resolver';
import {AppRouteReuseStrategy} from './app-route-reuse-strategy';

const appRoutes: Routes = [
	{
		path: 'component',
		resolve: {team: RouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./component-router/component-router.module').then(componentRouter => componentRouter.ComponentRouterModule)
	},
	{
		path: 'ess',
		resolve: {team: EssRouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./ess/ess.module').then(ess => ess.ESSModule)
	},
	{
		path: 'home',
		resolve: {team: HomeRouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./home/home.module').then(home => home.HomeModule)
	},
	{
		path: 'kiosk',
		resolve: {team: RouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./kiosk/kiosk.module').then(kiosk => kiosk.KioskModule)
	},
	{
		path: 'ia',
		resolve: {team: RouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./ia/ia.module').then(ia => ia.IaModule)
	},
	{
		path: 'skills',
		resolve: {team: SkillsRouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./skills/skills.module').then(skills => skills.SkillsModule)
	},
	{
		path: 'ukgpro',
		resolve: {team: RouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./embedded/embedded.module').then(ukgpro => ukgpro.EmbeddedModule)
	},
	{
		path: 'logout',
		resolve: {team: RouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./logout/logout.module').then(logout => logout.LogoutModule)
	},
	{
		path: 'unauthorized',
		resolve: {team: RouteResolver},
		loadChildren: () => import('./authentication/authentication-routing.module').then(unauthorized => unauthorized.AuthenticationRoutingModule)
	},
	{
		path: 'slide-out/new',
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./ess/slide-out/slide-out-routing.module').then(mod => mod.SlideOutRoutingModule)
	},
	{
		path: 'slide-out',
		outlet: 'app-slideout',
		canDeactivate: [RouteGuard],
		children: [
			{
				path: '',
				loadChildren: () =>
					import('@kronos/zed/src/lib/ui/framework/slide-out/slide-out-content/slide-out-content-lazy-load.module').then(
						mod => mod.SlideOutContentLazyLoadModule
					)
			},
			{
				path: 'knowledgebase',
				loadChildren: () => import('./home/components/cards/knowledgebase-card/knowledgebase-card.module').then(mod => mod.KnowledgebaseCardModule)
			},
			{
				path: 'hrsd',
				loadChildren: () => import('./documents-manager/documents-manager.module').then(mod => mod.DocumentsManagerModule)
			},
			{
				path: 'new',
				loadChildren: () => import('./ess/slide-out/slide-out-routing.module').then(mod => mod.SlideOutRoutingModule)
			}
		]
	},
	{
		path: 'prointegration',
		resolve: {team: RouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./prointegration/prointegration.module').then(config => config.ProIntegrationModule)
	},
	{
		path: 'wit',
		resolve: {team: RouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./wit/wit.module').then(wit => wit.WitModule)
	},
	{
		path: 'template-run',
		outlet: 'app-slideout',
		children: [
			{
				path: '',
				loadChildren: () => import('./wit/view-jobs/detail-slideout/detail-slide-out.module').then(mod => mod.DetailSlideOutModule)
			},
			{
				path: '',
				loadChildren: () => import('./wit/view-jobs/template-slideout/template-slide-out.module').then(mod => mod.TemplateSlideOutModule)
			},
			{
				path: '',
				loadChildren: () => import('./wit/view-imports/fileupload-slideout/fileupload-slide-out.module').then(mod => mod.FileUploadSlideOutModule)
			}
		]
	},
	{
		path: 'component/slide-out/run-report',
		resolve: {team: RouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./repexefram/run-report/run-report.module').then(runReport => runReport.RunReportModule)
	},
	{
		path: 'component/slide-out/run-preferred-report',
		resolve: {team: RouteResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () =>
			import('./repexefram/run-preferred-report/run-preferred-report.module').then(runPreferredReport => runPreferredReport.RunPreferredReportModule)
	},
	{
		path: 'component/view-paycode-analyzer',
		resolve: {team: RouteResolver, ViewPaycodeAnalyzerResolver},
		canDeactivate: [RouteGuard],
		loadChildren: () => import('./schedule/plugins/view-paycode-analyzer/view-paycode-analyzer.module').then(m => m.ViewPaycodeAnalyzerModule)
	},
	{
		path: '**',
		canDeactivate: [RouteGuard],
		redirectTo: 'home'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule],
	providers: [
		{provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy},
		HomeRouteResolver,
		RouteGuard,
		RouteResolver,
		EssRouteResolver,
		SkillsRouteResolver,
		ViewPaycodeAnalyzerResolver
	]
})
export class AppRoutingModule {
	constructor(private appService: AppService) {
		this.appService.setShowEmployeeHeader(true);
		this.appService.setShowNavBar(true);
	}
}
