import {BehaviorSubject} from 'rxjs';

export class ContextualHelpStore {
	private state;
	private reducer;

	constructor(reducer, initialState) {
		this.state = new BehaviorSubject(initialState);
		this.reducer = reducer;
	}

	public getState = (): any => this.state.value;

	public dispatch = (action): any => {
		const nextState = this.reducer(this.getState(), action);

		this.state.next(nextState);
	};

	public subscribe = (observer): any => this.state.subscribe(observer);
}

export const createStore = (reducer, initialState = {}): any => new ContextualHelpStore(reducer, initialState);
