import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, map} from 'rxjs';
import {get} from 'lodash';

import {SessionState} from '../../../core/session/session.state';
import {SESSION_EVENTS} from '../../../core/session/session.constants';

const SESSION_DEFAULT_STATE: SessionState = {
	status: ''
};

@Injectable({providedIn: 'root'})
export class SessionActionService {
	private sessionSource = {...SESSION_DEFAULT_STATE};
	private sessionSubject = new BehaviorSubject({...SESSION_DEFAULT_STATE});

	public select = (path: keyof SessionState): Observable<SessionState[keyof SessionState]> => this.sessionSubject.pipe(map(state => get(state, path)));

	public dispatch = (status: SESSION_EVENTS): void => {
		this.sessionSource = {...this.sessionSource, status};
		this.sessionSubject.next(this.sessionSource);
	};
}
