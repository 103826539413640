import {isUndefined, omitBy} from 'lodash';
import {createAction, createActions} from 'redux-actions';

import {OpenSlideoutPayload} from './slideout.interfaces';

const openNewSlideout = (
	sliderRoute: string,
	returnFocusElement?: string,
	context?: object,
	queryParams?: object,
	skipLocationChange = true
): OpenSlideoutPayload =>
	omitBy(
		{
			context,
			sliderRoute,
			queryParams,
			returnFocusElement,
			skipLocationChange
		},
		isUndefined
	);

export const actionsHelper = (): any => ({
	ALLOW_CLOSE: allow => ({allow}),
	CLOSE: (id, success?: boolean, message?: {type: string, message: string},optOut?:any) => ({id, success, message,optOut}),
	OPEN: id => ({id}),
	OPEN_AFTER_CLOSE: openNewSlideout,
	OPEN_WITH_ROUTER: openNewSlideout,
	SET_KEEP_FOCUS_AFTER_LOADING: shouldKeepFocus => ({shouldKeepFocus}),
	SUBMIT: id => ({id})
});
export const {allowClose, close, open, openAfterClose, openWithRouter, setKeepFocusAfterLoading, submit} = createActions(actionsHelper());
export const focusOnClose = createAction('SLIDEOUT_FOCUS_ON_CLOSE');
