import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forIn, get} from 'lodash';
import {Observable} from 'rxjs';

import {Transport} from '../../core/communication/api-service/api-service.interfaces';
import {SocketData} from '../../core/communication/socket/socketBus.interface';

import {DeviceConfigService} from '../device-config.service';
import {PrimusSocketService, SOCKET_UNAVAILABLE_ERROR} from './primus-socket.service';
import {DataCoreService} from '../../core/communication/data.core.service';

@Injectable({providedIn: 'root'})
export class DataService extends DataCoreService {
	private deviceType: string;

	constructor(deviceConfigService: DeviceConfigService, private http: HttpClient, private sockets?: PrimusSocketService) {
		super();
		this.deviceType = deviceConfigService.getDeviceType();
	}

	public broadcast(route: string, id: number, query?: object): void {
		const fullRoute = this.getFullRoute(route, id);

		this.checkSocket();
		this.sockets.broadcast(fullRoute, query);
	}

	public create(
		route: string,
		data?: object,
		query?: object,
		transport?: Transport,
		handleError = true,
		header?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	): Promise<object> {
		if (transport === Transport.SOCKET) {
			this.checkSocket();
			return this.sockets.request(route, data);
		}

		const params = get(query, 'responseType') === 'text' ? query : this.generateParams(query);

		return this.http.post(this.prependSlash(route), data, {...params, ...extraConfig, ...this.generateHeaders(query)}).toPromise();
	}

	public find(route: string, query?: object, transport?: Transport, handleError = true): Promise<object> {
		return this.get(route, undefined, query, transport, handleError);
	}

	public get(
		route: string,
		id?: number,
		query?: object,
		transport?: Transport,
		handleError = true,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	): Promise<object> {
		const fullRoute = this.getFullRoute(route, id);

		if (transport === Transport.SOCKET) {
			this.checkSocket();
			return this.sockets.request(fullRoute, query);
		}

		const params = fullRoute.includes('.properties')
			? {responseType: 'text'}
			: get(query, 'responseType') === 'text'
			? query
			: this.generateParams(query);

		return this.http.get(this.prependSlash(fullRoute), {...params, ...extraConfig, ...this.generateHeaders(query)}).toPromise();
	}

	public listen(event: string): Observable<SocketData> {
		this.checkSocket();
		return this.sockets.listen(event);
	}

	public patch(route: string, id: number, data?: object, query?: object, transport?: Transport, handleError = true): Promise<object> {
		throw new Error('patch is not implemented yet in DataService');
	}

	public remove(route: string, id: number, data?: object, query?: object, transport?: Transport, handleError = true): Promise<object> {
		const fullRoute = this.getFullRoute(route, id);

		return this.http.delete(this.prependSlash(fullRoute), {...this.generateParams(data)}).toPromise();
	}

	public update(
		route: string,
		id: number,
		data?: object,
		query?: object,
		transport?: Transport,
		handleError = true,
		extraConfig?: object
	): Promise<object> {
		const fullRoute = this.getFullRoute(route, id);

		if (transport === Transport.SOCKET) {
			this.checkSocket();
			return this.sockets.request(fullRoute, query);
		}

		return this.http.put(this.prependSlash(fullRoute), data, {...query, ...extraConfig, ...this.generateHeaders(query)}).toPromise();
	}

	private checkSocket = (): void => {
		if (!this.sockets) {
			throw new Error(SOCKET_UNAVAILABLE_ERROR);
		}
	};

	private generateHeaders(query: object): {headers: HttpHeaders} {
		let newHeaders = this.toHttpHeaders(get(query, 'headers', new HttpHeaders()));

		const page = this.getPage();

		if (page) {
			newHeaders = newHeaders.append('page', page);
		}

		return {headers: newHeaders.append('device-type', this.deviceType)};
	}

	private generateParams(query: object): {params: HttpParams} {
		if (!query) {
			return;
		}
		let params = new HttpParams();

		Object.keys(query).forEach(key => {
			params = params.set(key, query[key]);
		});

		return {params};
	}

	private getFullRoute(route: string, id: number): any {
		return id ? `${route}/${id}` : route;
	}

	private getPage(): string {
		const indexOfPage = 2;

		return window.location.pathname.split('/')[indexOfPage];
	}

	private prependSlash(path: string): string {
		return path.startsWith('/') ? path : `/${path}`;
	}

	private toHttpHeaders(headers: object | HttpHeaders): HttpHeaders {
		if (headers instanceof HttpHeaders) {
			return headers;
		}

		let newHeaders = new HttpHeaders();

		forIn(headers, (value, key) => {
			newHeaders = newHeaders.append(key, value);
		});
		return newHeaders;
	}
}
