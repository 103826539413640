import {forEach, includes, isEmpty, isEqual, noop, size} from 'lodash';

import {KeyNavService} from './services/keyboard-navigation.interfaces';

import {toFocusableElementStrings} from './focusable-elements.utility';
import {SelectorService} from './selectors/selector.service';
import {ArrowsNavigationService} from './services/arrows-navigation.service';
import {BlurDetectionService} from './services/blur-detection.service';
import {EnterDetectionService} from './services/enter-detection.service';
import {EnterNavigationService} from './services/enter-navigation.service';
import {EscNavigationService} from './services/esc-navigation.service';
import {NoTabNavigationService} from './services/no-tab-navigation.service';
import {TabsNavigationService} from './services/tabs-navigation.service';
import {FOCUSABLE_ELEMENTS, MODAL_ROLES} from './utils.constants';

export class ModalKeyboardBaseNavigation {
	protected blurCallback;
	protected closeCallback;
	protected enterCallback;
	protected expandCallback;
	protected element: HTMLElement;
	protected hasBlurCallback: boolean;
	protected hasCloseCallback: boolean;
	protected hasEnterCallback: boolean;
	protected modalRole: string;
	protected noWrap: boolean;
	protected isFocusOnInitialize = true;
	protected selectorService: SelectorService;

	private focusableElementStrings: string[];
	private focusableElements: HTMLElement[];
	private focusOnInitialize: string[] = [MODAL_ROLES.MENU, MODAL_ROLES.MENUBAR];
	private navigationServices: KeyNavService[];
	private readonly navigationMapping;

	constructor() {
		this.hasBlurCallback = false;
		this.hasCloseCallback = false;
		this.hasEnterCallback = false;
		this.navigationServices = [];
		this.navigationMapping = {
			[MODAL_ROLES.LIST]: this.loadArrowsAndTabsAndEnterNavigation,
			[MODAL_ROLES.MENU]: this.loadArrowsNoTabNavigation,
			[MODAL_ROLES.MENUBAR]: this.loadArrowsNoTabNavigation,
			[MODAL_ROLES.NONE]: noop,
			[MODAL_ROLES.APPLICATION]: this.loadTabsAndEnterNavigation,
			[MODAL_ROLES.SEARCHABLEMENU]: this.loadArrowsNoTabNavigation,
			[MODAL_ROLES.SELECT]: this.loadArrowsNavigation,
			[MODAL_ROLES.TOPLEVELMENU]: this.loadArrowsNavigation
		};
	}

	protected clearNavigationService(): void {
		if (size(this.navigationServices)) {
			forEach(this.navigationServices, service => service.destroy());
			this.navigationServices = [];
		}
	}

	protected getFocusableElements(): HTMLElement[] {
		const focusableElements: HTMLElement[] = Array.from(this.element.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS));

		return this.selectorService.select(focusableElements);
	}

	protected loadNavigationService(): void {
		if (this.hasFocusableElements()) {
			const navigationServices: KeyNavService[] = [];

			(this.navigationMapping[this.modalRole] || this.loadTabsNavigation)(navigationServices);

			this.loadEscNavigation(navigationServices);
			this.loadBlurDetection(navigationServices);
			this.loadEnterDetection(navigationServices);

			forEach(navigationServices, service => service.initialize());
			this.navigationServices = navigationServices;
		}
	}

	protected loadNavigationServiceOnInit(): void {
		this.focusableElements = this.getFocusableElements();
		this.focusableElementStrings = toFocusableElementStrings(this.focusableElements);
		this.loadNavigationService();
	}

	protected loadNavigationServiceOnChanges(): void {
		const newFocusableElements = this.getFocusableElements();
		const newFocusableElementStrings = toFocusableElementStrings(newFocusableElements);

		if (!isEqual(newFocusableElementStrings, this.focusableElementStrings)) {
			this.clearNavigationService();
			this.focusableElements = newFocusableElements;
			this.focusableElementStrings = newFocusableElementStrings;
			this.loadNavigationService();
		}
	}

	private hasFocusableElements(): boolean {
		return !isEmpty(this.focusableElements);
	}

	private loadArrowsNavigation = (navigationServices: KeyNavService[]): void => {
		navigationServices.push(
			new ArrowsNavigationService(
				this.focusableElements,
				this.focusableElementStrings,
				this.element,
				includes(this.focusOnInitialize, this.modalRole) && this.isFocusOnInitialize,
				this.noWrap
			)
		);
	};

	private loadArrowsAndTabsAndEnterNavigation = (navigationServices: KeyNavService[]): void => {
		this.loadArrowsNavigation(navigationServices);
		this.loadTabsNavigation(navigationServices);
		this.loadEnterNavigation(navigationServices);
	};

	private loadArrowsNoTabNavigation = (navigationServices: KeyNavService[]): void => {
		this.loadArrowsNavigation(navigationServices);
		this.loadNoTabNavigation(navigationServices);
	};

	private loadBlurDetection = (navigationServices: KeyNavService[]): void => {
		if (this.hasBlurCallback) {
			navigationServices.push(new BlurDetectionService(this.focusableElements, this.focusableElementStrings, this.blurCallback));
		}
	};

	private loadEnterDetection = (navigationServices: KeyNavService[]): void => {
		if (this.hasEnterCallback) {
			navigationServices.push(new EnterDetectionService(this.focusableElements, this.focusableElementStrings, this.enterCallback));
		}
	};

	private loadEscNavigation = (navigationServices: KeyNavService[]): void => {
		if (this.hasCloseCallback) {
			navigationServices.push(new EscNavigationService(this.focusableElements, this.element, this.closeCallback));
		}
	};

	private loadTabsAndEnterNavigation = (navigationServices: KeyNavService[]): void => {
		this.loadTabsNavigation(navigationServices);
		this.loadEnterNavigation(navigationServices);
	};

	private loadNoTabNavigation = (navigationServices: KeyNavService[]): void => {
		navigationServices.push(new NoTabNavigationService(this.focusableElements, this.element));
	};

	private loadTabsNavigation = (navigationServices: KeyNavService[]): void => {
		navigationServices.push(new TabsNavigationService(this.focusableElements, this.noWrap));
	};

	private loadEnterNavigation = (navigationServices: KeyNavService[]): void => {
		navigationServices.push(new EnterNavigationService(this.focusableElements, this.element, this.expandCallback));
	};
}
