import {ApiService} from '../../communication/api-service/api-service.interfaces';
import {ReleaseToggle} from './release-toggle.interface';

import {SessionStorageCoreService} from '../caching/sessionStorage.service';
import {isFeatureEnabled, registerFeatures} from './app-release-toggle.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
const ReleaseToggleSessionKey = 'ReleaseToggle';

export class ReleaseToggleService {
	private releaseTogglesPromise: Promise<void | ReleaseToggle>;

	constructor(
		private apiService: ApiService,
		private sessionStorageService: SessionStorageCoreService,
		private promiseWrapper: PromiseConstructor = Promise
	) {}

	public getAll(): Promise<void | ReleaseToggle> {
		const data = this.getFromSessionStorage();

		if (data) {
			const releaseToggles = data as ReleaseToggle;

			registerFeatures(releaseToggles);
			return this.promiseWrapper.resolve(data);
		}
		return this.requestForReleaseToggles();
	}

	/**
	 * Doesn't load data and just returns the response if it's already cached.
	 * @param key
	 */
	public get(key: string): boolean {
		return isFeatureEnabled(key);
	}

	/**
	 * Loads data if necessary before returning value.
	 * @param key
	 */
	public async getAsync(key: string): Promise<boolean> {
		await this.getAll();
		return isFeatureEnabled(key);
	}

	/**
	 * Removes user data from the session storage cache. This will cause the next getLoggedOnUserData() call to refetch the data from the server.
	 * This should happen automatically when the user logs out, so only use this if you know what you're doing.
	 */
	public clearReleaseToggles(): void {
		this.sessionStorageService.clear(ReleaseToggleSessionKey);
		registerFeatures(null);
	}

	private getFromSessionStorage(): ReleaseToggle {
		return this.sessionStorageService.get(ReleaseToggleSessionKey);
	}

	private requestForReleaseToggles(): Promise<void | ReleaseToggle> {
		if (!this.releaseTogglesPromise) {
			this.releaseTogglesPromise = this.apiService.get('/get/release/toggles').then(
				response => {
					const releaseToggles = response as ReleaseToggle;

					this.sessionStorageService.set(ReleaseToggleSessionKey, releaseToggles);
					registerFeatures(response);
					return releaseToggles;
				},
				error => {
					this.clearReleaseToggles();
					return Promise.reject(error);
				}
			);
		}
		return this.releaseTogglesPromise;
	}
}
