<div *ngIf="label !== ''" class="row toggle" [ngClass]="classes" [attr.id]="'container-' + toggleLabelId">
  <div class="col p-0">
    <button [attr.aria-describedby]="ariaDescribedByAttr()"
            [attr.aria-label]="ariaLabelAttr()"
            [attr.aria-labelledby]="ariaLabelledByAttr()"
            [attr.aria-pressed]="toggleState"
            [attr.disabled]="attrs?.disabled"
            class="btn w-100"
            (click)="triggerToggle()"
            containerClass="krn-default-tooltip"
            [ngClass]="{'active': toggleState,
                        'active-animated': animateToggleState === toggleAnimation.Active,
                        'inactive-animated': animateToggleState === toggleAnimation.Inactive}"
            type="button">
      <div class="d-flex"
           [ngClass]="{'flex-row': alignment === commonConstants.HTML_ALIGNMENT.LEFT,
                       'flex-row-reverse': alignment === commonConstants.HTML_ALIGNMENT.RIGHT}">
        <div class="label w-100">
          <div #bsTooltip="bs-tooltip"
               class="label-content"
               [container]="'#container-' + toggleLabelId"
               [attr.id]="toggleLabelId"
               [placement]="placement"
               [tooltip]="label">{{label}}
          </div>
        </div>
        <div class="value"
             [ngClass]="verticalAlignClass()">
          <div class="row container-fluid align-items-center slider">
            <div class="col gutter"></div>
            <div class="knob"></div>
          </div>
        </div>
      </div>
    </button>
  </div>
</div>
