import {LocalAuthHandler} from '../mobile/local-auth.interface';
import {MappingHandler} from '../mobile/mapping.interface';
import {EmbeddedMenuCategory, LegacyEmbeddedMenuItem} from '../../embedded/embedded.interfaces';
import {GlobalData} from '../../one-app/one-app-event-bus.interfaces';

import {PropertyFactory} from '../../property/property.factory';
import {PropertyFilterConfig} from '../../property/property.filter';
import {BaseWifiLocationService} from '../services/base-wifi-location.service';
import {GeolocationCoreService} from '../services/geolocation.service';

export interface ClientContainer {
	localAuth: (propertyFactory?: PropertyFactory, propertyFilter?: PropertyFilterConfig) => LocalAuthHandler;
	geolocation: () => GeolocationCoreService;
	print?: (content?: string) => void;
	wifiLocation: (propertyFactory: PropertyFactory, propertyFilter: PropertyFilterConfig) => BaseWifiLocationService;
	supportsExport: () => boolean;
	supportsLocalAuth: () => boolean;
	supportsPrint: () => boolean;
	supportsWifiLocation: () => boolean;
	allowExternalEmbedding: () => boolean;
	openNewTab: (url: string, tab?: string) => void;
	getTopWfdWindow: () => Window;
	navigate: (window: Window, href: string, useCurrentWin?: false, serviceName?: string) => void;
	supportsMapping: () => boolean;
	mapping: (propertyFactory?: PropertyFactory, propertyFilter?: PropertyFilterConfig) => MappingHandler;
	talkNavigation: (deeplink: string, tenantId: string, vanityUrl: string) => void;
	scan: (scanConfig: ScanConfig, callback?: () => void) => Promise<ScanResult>;
	swpNavigation: (deeplink: string) => void;
	onRoleSwitched: (data) => void;
	dispatchEvent: (dispatchParam: DispatchParam) => void;
	getEventName: () => CommunicationEventName;
	getMenuItems: () => Promise<EmbeddedMenuCategory[] | LegacyEmbeddedMenuItem[]>;
	replaceState: (path: string) => void;
	signalContentComplete: (pageIdentifier: string, senderID: string) => void;
	setGlobalData: (data: GlobalData, senderID: string) => void;
	getGlobalData: (key: string, senderID: string) => Promise<any>;
	b64DecodeJSON: (b64jsonString: any) => any;
	isOneAppVersionOutOfDate: (senderID: string) => void;
	getColorSync: (colorsData: any) => void;
	getActionSubscription: (action: string, callback: (result: any) => void) => void;
	checkFeatureFlag: (featureFlag: string) => Promise<any>;
	openADPDimSearch: (key: string, id: string, brand: string) => void;
}

export enum ClientContext {
	BROWSER,
	KIOSK,
	WFD_MOBILE,
	EMBEDDED_SESSION,
	ONE_APP,
	PRO_EMBEDDED
}

export enum TabType {
	BLANK = '_blank',
	SYSTEM = '_system'
}

export interface ScanConfig {
	prompt: string;
}

export interface ScanResult {
	text: string;
	cancelled: boolean;
}

export enum CommunicationEventName {
	UP_POST_MESSAGE = 'upPostMessage',
	ONE_APP_EVENT_BUS = 'mobileAppEventBus'
}

export interface DispatchParam {
	detail: {
		method: string;
		arg?: object;
	};
}
