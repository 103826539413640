import {Component, Input} from '@angular/core';

@Component({
	selector: 'krn-ng-inline-validation',
	templateUrl: './inline-validation.component.html',
	styleUrls: ['./inline-validation.component.less']
})
export class InlineValidationComponent {
	@Input() public classes: string | string[];
	@Input() public condition: boolean;
	@Input() public message: string;
	@Input() public messageAsKey = true;
}
