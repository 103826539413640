import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';

import {MAX_TIMEOUT, PROPERTIES} from '../../../../core/authentication/authentication.constants';
import {PropertyFactory} from '../../../../core/property/property.factory';
import {WindowRefService} from '../../../../services/window-ref.service';
import {AuthenticationService} from '../authentication.service';
import {AuthenticationStore} from '../authentication.store';

@Component({
	selector: 'krn-ng-authentication-error',
	templateUrl: './authentication-error.component.html',
	styleUrls: ['./authentication-error.component.less'],
	encapsulation: ViewEncapsulation.None
})
export class AuthenticationErrorComponent implements OnDestroy, OnInit {
	public heading$: Observable<string>;
	public message$: Observable<string>;

	private delayLogoutId: NodeJS.Timeout;

	constructor(
		private authenticationService: AuthenticationService,
		protected authenticationStore: AuthenticationStore,
		private propertyFactory: PropertyFactory,
		private windowRefService: WindowRefService
	) {
		this.heading$ = this.authenticationStore.selector('errorHeading');
		this.message$ = this.authenticationStore.selector('authenticationErrorMessage');
	}

	public logoutUser = (): void => {
		this.authenticationService.navigateToLogoutRoute();
	};

	public ngOnDestroy(): void {
		clearTimeout(this.delayLogoutId);
	}

	public ngOnInit(): void {
		this.propertyFactory.loadProperties(PROPERTIES);
		this.delayLogoutId = setTimeout(this.logoutUser, MAX_TIMEOUT);
	}
}
