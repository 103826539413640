import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {get, isEmpty} from 'lodash';
import {Subject, Subscription} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {MenuItemConfig} from '../../../../core/navBar/models/menu-item-config.model';
import {NavBarMenu} from '../../../../core/navBar/nav-bar-menu';
import {PropertyFactory} from '../../../../core/property/property.factory';
import {WindowRefService} from '../../../../services/window-ref.service';
import {NavBarMenuService} from '../nav-bar-menu.service';
import {NavBarUrlService} from '../nav-bar-url.service';
import {mainMenuSelectorProvider} from './main-menu-selector.provider';

@Component({
	selector: 'krn-ng-nav-bar-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.less'],
	providers: [mainMenuSelectorProvider]
})
export class MainMenuComponent implements OnChanges, OnDestroy {
	@Input() public menuData: MenuItemConfig[];
	@Input() public searchTerm = '';
	private resetMenuSubscription: Subscription;
	private scrollToSubscription: Subscription;
	private unsubscribe$ = new Subject();
	private window: Window;

	constructor(
		router: Router,
		windowRefService: WindowRefService,
		private navBarMenuUtils: NavBarMenu,
		private navBarMenuService: NavBarMenuService,
		private navBarUrlService: NavBarUrlService,
		private propertyFactory: PropertyFactory
	) {
		this.window = windowRefService.nativeWindow;
		this.resetMenuSubscription = this.navBarMenuService.resetMenu$().subscribe(this.onResetMenu);
		this.scrollToSubscription = this.navBarMenuService.scrollToElement$().subscribe(this.scrollToElement);
		this.propertyFactory.loadProperties([
			{
				name: 'container_web-common_nav-bar_strings.properties',
				path: 'components/zed-core/properties/navBar/'
			}
		]);
		router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				takeUntil(this.unsubscribe$)
			)
			.subscribe(this.selectMenuItem);
	}

	public ngOnChanges(change: SimpleChanges): void {
		if (change.menuData && !isEmpty(change.menuData.currentValue)) {
			this.menuData = Object.keys(change.menuData.currentValue).map(key => new MenuItemConfig(change.menuData.currentValue[key]));
			this.selectMenuItem();
		}
	}

	public ngOnDestroy(): void {
		this.resetMenuSubscription.unsubscribe();
		this.scrollToSubscription.unsubscribe();
		this.unsubscribe$.next(undefined);
		this.unsubscribe$.complete();
	}

	private onResetMenu = (): void => this.navBarMenuUtils.resetSelectedMenu(this.menuData);

	private selectMenuItem = (): void => {
		if (this.menuData) {
			const route = this.navBarUrlService.parseURLForLink();
			const queryParams = this.navBarMenuUtils.getQueryParams(get(this.window, 'location.href'));

			this.navBarMenuUtils.markSelectedMenu(this.menuData, queryParams, queryParams.pageId, route);
		}
	};

	private scrollToElement = (): void => {
		const selectedElement = this.navBarMenuUtils.scrollSelectedElement(this.menuData, this.navBarMenuService.getSelectedElement());
		const scrolledElement = selectedElement.scrolledElement;

		if (scrolledElement) {
			if (scrolledElement.isMenuLink) {
				// we need to scroll exactly to <a> because of issue in Firefox
				scrolledElement.querySelector('a').scrollIntoView();
			} else {
				scrolledElement.scrollIntoView();
			}
		}
	};
}
