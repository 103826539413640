import {ControlValueAccessor} from '@angular/forms';
import {noop} from 'lodash';

export abstract class ReactiveFormsBase implements ControlValueAccessor {
	public registerOnTouched = noop;

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	protected onChange: any = (() => {
		// notify form API to update the value of the form control
	}); 

	public abstract writeValue(value: any): void; // set the default value from form control to element
}
