import {Inject, Injectable, Injector} from '@angular/core';

export interface Bootstrappable {
	bootstrap: () => Promise<void>;
}

@Injectable()
export class BootstrapService {
	private bootstrapPromise = null;

	constructor(private injector: Injector, @Inject('BootstrappableProviders') private bootstrappableProviders: Bootstrappable[]) {}

	public isBootstrappable(boostrappable: Bootstrappable): boostrappable is Bootstrappable {
		return boostrappable.bootstrap !== undefined;
	}

	public async bootstrap(): Promise<void> {
		if (this.bootstrapPromise) {
			return this.bootstrapPromise;
		}

		const promises = new Array<Promise<void>>();

		for (const boostrappableProvider of this.bootstrappableProviders) {
			const instance = this.injector.get(boostrappableProvider);

			if (this.isBootstrappable(instance)) {
				await instance.bootstrap();
			}
		}

		this.bootstrapPromise = Promise.all(promises);
		return this.bootstrapPromise;
	}
}
