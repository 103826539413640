import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {camelCase, find, forEach, get, head, isEmpty, isEqual, isNil, isUndefined, pull} from 'lodash';

import {HelpSearchAggregation, HelpSearchRequestAggregation} from '../../../../../../core/navBar/contextual-help/contextual-help.interfaces';

import {
	checkIfUserHasUpdatedFilters,
	getExistingFilters,
	markExistingFiltersChecked,
	preserveInitialState,
	setAggregationsArray
} from '../../../../../../core/navBar/contextual-help/contextual-help.utils';
import {AggregationsType, CreatedDateValues} from '../../../../../../core/navBar/contextual-help/contextual-help-search.constants';
import {PropertyFilterPipe} from '../../../../../../providers/property/property.filter.pipe';
import {accessibilitySpeakerRead} from '../../../../../../services/accessibility/accessibility-speaker.utility';
import {DefaultSliderActionHandler} from '../../../../slide-out/slide-out-content/handlers/default-slider-action-handler';
import {SlideOutComponent} from '../../../../slide-out/slide-out.component';
import {ContextualHelpService} from '../contextual-help/contextual-help.service';

@Component({
	selector: 'krn-ng-contextual-help-search-filter',
	templateUrl: './contextual-help-search-filter.component.html',
	styleUrls: ['./contextual-help-search-filter.component.less']
})
export class ContextualHelpSearchResultFilterComponent implements OnInit, OnDestroy {
	@ViewChild(SlideOutComponent, {static: false})
	public slideOutContainer: SlideOutComponent;
	@Output() public markRefineClosed = new EventEmitter();

	public isOpen: boolean;
	public loading: boolean;
	public resultCount: number;
	public aggregationsArray: HelpSearchRequestAggregation[];
	public sources: HelpSearchAggregation;
	public createdDates: HelpSearchAggregation;
	public filterBySource: string[];
	public filterByDate: string;
	public refined: boolean;
	public userClearedFilters: boolean;
	public userChangedFilters: boolean;
	public userAppliedFilters: boolean;
	public initialFilters;
	public subscription;
	public readonly ALLTIME_LABEL = CreatedDateValues.AllTime;

	constructor(
		private contextualHelpService: ContextualHelpService,
		public actionHandler: DefaultSliderActionHandler,
		private propertyFilter: PropertyFilterPipe
	) {}

	public ngOnInit(): void {
		this.userClearedFilters = false;
		this.userChangedFilters = false;
		this.userAppliedFilters = false;
		this.subscription = this.contextualHelpService.subscribeToStore(this.subscribeSearchResultChange);
	}

	public ngOnDestroy(): void {
		setTimeout(() => {
			if ((this.refined || this.userChangedFilters) && this.userAppliedFilters) {
				this.contextualHelpService.refine({aggregations: this.aggregationsArray}, this.refined, false);
			} else {
				this.contextualHelpService.cancelRefine();
			}
			this.subscription.unsubscribe();
		});
	}

	public onSlideOutLoad(): void {
		setTimeout(() => {
			if (!this.isOpen) {
				this.slideOutContainer.openSlideOut();
				this.isOpen = true;
			} else {
				this.slideOutContainer.close();
			}
		});
	}

	public closeHandler(): void {
		this.isOpen = false;
		this.initialFilters = undefined;
		this.refined = false;
		this.markRefineClosed.emit();
	}

	public cancel(): void {
		this.isOpen = false;
		this.refined = false;
		accessibilitySpeakerRead(this.propertyFilter.transform('html.navbar.contextualHelp.search.refine.announce.not.applied'));
		this.markRefineClosed.emit();
	}

	public apply(): void {
		this.isOpen = false;
		this.userAppliedFilters = true;
		this.refined = !isEqual(this.aggregationsArray, [{type: AggregationsType.CreatedDate, filter: [CreatedDateValues.AllTime]}]);
		this.userChangedFilters = !this.userChangedFilters || checkIfUserHasUpdatedFilters(this.aggregationsArray, this.initialFilters);
		accessibilitySpeakerRead(this.propertyFilter.transform('html.navbar.contextualHelp.search.refine.announce.applied'));
		this.markRefineClosed.emit();
	}

	public clearFilter(): void {
		this.aggregationsArray = [{type: AggregationsType.CreatedDate, filter: [CreatedDateValues.AllTime]}];
		this.filterBySource = [];
		this.filterByDate = CreatedDateValues.AllTime;
		this.refined = false;
		this.userClearedFilters = true;
		this.contextualHelpService.refine({aggregations: this.aggregationsArray}, this.refined, true);
	}

	public addSourceFilter(sourceName: string): void {
		this.userChangedFilters = true;
		this.userClearedFilters = false;
		const sourcesObject = find(this.aggregationsArray, aggregation => aggregation.type === AggregationsType.Sources);

		if (isEmpty(sourcesObject) && isEmpty(this.filterBySource)) {
			this.filterBySource = [sourceName];
		} else {
			const sourceNameFilter = find(sourcesObject.filter, filter => filter === sourceName);

			if (isNil(sourceNameFilter)) {
				sourcesObject.filter.push(sourceName);
			} else {
				pull(sourcesObject.filter, sourceName);
			}
			this.filterBySource = sourcesObject.filter;
		}
		this.aggregationsArray = isEmpty(this.filterBySource) ? [] : [{type: AggregationsType.Sources, filter: this.filterBySource}];
		this.addDateFilter(this.filterByDate);
	}

	public subscribeSearchResultChange = (state): void => {
		const searchResult = state.searchResult || {};

		this.loading = state.loading;

		if (this.loading) {
			return;
		}
		this.refined = state.refined;
		const typeFilter = getExistingFilters(state, AggregationsType.Sources);

		this.filterBySource = get(typeFilter, 'filter') || [];
		const dateFilter = getExistingFilters(state, AggregationsType.CreatedDate);

		this.filterByDate = get(dateFilter, 'filter') ? head(get(dateFilter, 'filter')) : CreatedDateValues.AllTime;
		this.aggregationsArray = setAggregationsArray(this.filterBySource, this.filterByDate);
		this.sources = markExistingFiltersChecked(searchResult.aggregationsArray, this.filterBySource, AggregationsType.Sources);
		this.createdDates = markExistingFiltersChecked(searchResult.aggregationsArray, this.filterByDate, AggregationsType.CreatedDate);
		if (!this.userClearedFilters) {
			this.initialFilters = preserveInitialState(this.initialFilters, this.filterBySource, this.filterByDate);
		}
		this.resultCount = get(searchResult, 'results.total', 0);
	};

	public addDateFilter(selectedDateFilter: string): void {
		this.userChangedFilters = true;
		this.userClearedFilters = false;
		this.filterByDate = selectedDateFilter;
		forEach(this.createdDates.values, dateFilter => {
			if (dateFilter.Contentname === selectedDateFilter) {
				dateFilter.checked = true;
			} else {
				dateFilter.checked = false;
			}
		});
		const existingDateFilter = find(this.aggregationsArray, item => item.type === AggregationsType.CreatedDate);

		if (isUndefined(existingDateFilter)) {
			this.aggregationsArray.push({type: AggregationsType.CreatedDate, filter: [this.filterByDate]});
		} else {
			existingDateFilter.filter = [this.filterByDate];
		}
	}

	public generateInputAutomationId(type: string, displayName: string): string {
		return `${type}_${camelCase(displayName)}_input`;
	}
}
