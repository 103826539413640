import {get} from 'lodash';

import {CordovaPluginWindow} from '../mobile/cordova-plugin-window.interface';
import {ScanConfig, ScanResult, TabType} from './client-container.interface';

import {PropertyFactory} from '../../property/property.factory';
import {PropertyFilterConfig} from '../../property/property.filter';
import {SessionStorage} from '../caching/sessionStorage.service';
import {BaseWifiLocationService} from '../services/base-wifi-location.service';
import {LocalAuthCoreService} from '../mobile/local-auth.service';
import {MappingCoreService} from '../mobile/mapping.core.service';
import {MobileWifiLocationService} from '../mobile/mobile-wifi-location.service';
import {BrowserContainerCoreService} from './browser-container.core.service';
import {getTabType, INAPPBROWSER_OPTIONS} from './client-container.utility';
import {MobileCommonFeature} from './mobile-common-feature';

export class WfdMobileContainerCoreService extends BrowserContainerCoreService {
	private mobileCommon: MobileCommonFeature;

	constructor(window: CordovaPluginWindow, sessionStorage: SessionStorage) {
		super(window, sessionStorage);
		this.mobileCommon = new MobileCommonFeature(window);
	}
	public scan = (scanConfig: ScanConfig = {prompt: ''}): Promise<ScanResult> => this.mobileCommon.scan(scanConfig);

	public localAuth(): LocalAuthCoreService {
		return new LocalAuthCoreService(this.window.top);
	}

	public print = (content?: string): void => this.mobileCommon.print(content);

	public wifiLocation = (propertyFactory: PropertyFactory, propertyFilter: PropertyFilterConfig): BaseWifiLocationService => new MobileWifiLocationService(this.getTopWfdWindow(), this.supportsWifiLocation(), propertyFactory, propertyFilter);

	public openNewTab = (url: string, tab: string = TabType.BLANK): void => this.mobileCommon.openNewTab(url, tab);

	public supportsLocalAuth = (): boolean => !!get(this.window.top, 'krnUtilities.runAnyAuth');

	public supportsPrint = (): boolean => this.mobileCommon.supportsPrint();

	public supportsWifiLocation = (): boolean => this.mobileCommon.supportsWifiLocation();

	public supportsMapping = (): boolean => this.mobileCommon.supportsMapping();

	public mapping(): MappingCoreService {
		return new MappingCoreService(this.window.top);
	}

	public talkNavigation(deeplink: string, tenantId: string, vanityUrl: string): void {
		this.window.top.universalLink.launchUniversalLink(deeplink, tenantId, vanityUrl);
	}

	public swpNavigation(deeplink: string): void {
		this.window.top.universalLink.launchUniversalLink(deeplink);
	}
}
