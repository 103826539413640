import {includes, isFinite} from 'lodash';

import {DeviceInfo} from './DeviceConfigServices.interface';

import {Breakpoints} from '../constants/breakpoints';
import {CommonConstants} from '../constants/CommonConstants';
import * as deviceConfigUtility from './DeviceConfig.utility';
import {DEVICE_MEDIA_QUERY, DEVICES, MOBILE_OS, TOUCHPOINT, USER_AGENT} from './DeviceConfigServices.constants';
import {ZoomCoreService} from './zoom.core.service';

export default class DeviceConfigCoreService {
	private deviceInfo: DeviceInfo = {};
	private CommonConst: any = CommonConstants;

	constructor(private window: Window, protected document: Document, private zoomService: ZoomCoreService) {}

	public isShowMobileUIOnDesktop = (): boolean => this.zoomService.isMimicMobileUI();

	public getUrlParameter(paramName: string): string | null {
		let i;
		let val;
		const searchString = window.location.search ? window.location.search.substring(1) : null;
		const params = searchString !== null ? searchString.split('&') : undefined;

		if (params) {
			for (i = 0; i < params.length; i++) {
				val = params[i].split('=');
				if (val[0] === paramName) {
					return val[1];
				}
			}
		}
		return null;
	}
	// query setting for interactionMode
	public getInteractionMode(): string {
		let interactionMode: string | null;

		// call getUrlParameter() only once to define interaction_mode
		if (!this.CommonConst.interaction_mode) {
			interactionMode = this.getUrlParameter('interactionMode');
			this.CommonConst.interaction_mode = interactionMode || 'mouse';
		}
		return this.CommonConst.interaction_mode;
	}
	// Checks the width of the window and returns a string with the name of the current breakpoint
	public getBreakpoint(): string {
		const viewportWidth = this.window.innerWidth;

		switch (isFinite(viewportWidth)) {
			case viewportWidth < Breakpoints.EXTRASMALL.MAX:
				return Breakpoints.EXTRASMALL.NAME;
			case viewportWidth < Breakpoints.SMALL.MAX:
				return Breakpoints.SMALL.NAME;
			case viewportWidth >= Breakpoints.SMALL.MAX && viewportWidth < Breakpoints.MEDIUM.MAX:
				return Breakpoints.MEDIUM.NAME;
			case viewportWidth >= Breakpoints.MEDIUM.MAX:
				return Breakpoints.LARGE.NAME;
		}
	}
	// Identifies if the device is a tablet
	public isTablet(): boolean {
		if (!this.isMobileDevice()) {
			return false;
		}
		if (
			(this.window.matchMedia(DEVICE_MEDIA_QUERY.ORIENTATION_PORTRAIT).matches &&
				this.window.matchMedia(DEVICE_MEDIA_QUERY.MIN_WIDTH_PORTRAIT).matches) ||
			(this.window.matchMedia(DEVICE_MEDIA_QUERY.ORIENTATION_LANDSCAPE).matches &&
				this.window.matchMedia(DEVICE_MEDIA_QUERY.MIN_WIDTH_LANDSCAPE).matches)
		) {
			return true;
		}
		return false;
	}

	// Determine the mobile operating system. This function either returns 'ios', 'android' or 'unknown'
	public getMobileOS(): string {
		return deviceConfigUtility.getMobileOS(this.window);
	}

	public isIOSDevice = (): boolean => this.getMobileOS() === MOBILE_OS.IOS;

	public isRealMobileDevice = (): boolean => deviceConfigUtility.isRealMobileDevice(this.window);

	public isMobileDevice = (): boolean => deviceConfigUtility.isRealMobileDevice(this.window) || this.zoomService.isHitZoomBreakpoint();

	public isPhone(): boolean {
		return this.getDeviceType() === DEVICES.PHONE;
	}

	public isMobileApp = (): boolean => this.window.navigator.userAgent.match(/(wfdMobileApp)/i) !== null;
	// The method above this will be refactored to deal with checking if running either WFD or Ultipro app. This method is specifically for running in WFD native app, not through Ultipro tile injection.
	// Gets the mobile device type
	public getDeviceType(): string {
		let deviceType;

		if (this.isMobileDevice()) {
			if (this.isTablet()) {
				deviceType = DEVICES.TABLET;
			} else {
				deviceType = DEVICES.PHONE;
			}
		} else {
			deviceType = DEVICES.DESKTOP;
		}
		return deviceType;
	}
	// Stores device dimension at load time
	public setDeviceInfo(): void {
		if (this.window.innerHeight > this.window.innerWidth) {
			this.deviceInfo.portraitHeight = this.window.innerHeight;
			this.deviceInfo.landscapeHeight = this.window.innerWidth;
		} else {
			this.deviceInfo.portraitHeight = this.window.innerWidth;
			this.deviceInfo.landscapeHeight = this.window.innerHeight;
		}
	}
	// Returns cached height of the device based on current orientation.
	public getDeviceHeight(): number {
		const isPortrait = this.window.matchMedia('(orientation: portrait)').matches;

		return isPortrait ? this.deviceInfo.portraitHeight : this.deviceInfo.landscapeHeight;
	}
	// Manually triggers end cell edit of grid to hide select element on blur
	public hideGridEditorOnTouchMove(grid: any): void {
		grid.find('div[role="gridcell"]').on('touchmove', () => {
			const isDropdownVisible = grid.find('.trans-select, .paycode-native-select').is(':visible'); // TODO: move to swimlane this is used in or to a separate service
			const persistChanges = false;

			if (isDropdownVisible) {
				const selectedCell = grid.jqxGrid('getselectedcell');

				grid.jqxGrid('endcelledit', selectedCell.rowindex, selectedCell.datafield, persistChanges);
			}
		});
	}

	// determine if the ID of the modal is in a list or not
	public modalInExclusionList(dirModel, listID): boolean {
		const onOpenList = ['timecard.callout', 'unsaveddialog'];
		const flickerShimList = ['timecard.callout'];
		const closeModalList = ['transferdialog'];
		let isModalInExclusionList = false;
		let index = -1;

		switch (listID) {
			case 'on_open_modals':
				index = onOpenList.indexOf(dirModel.options.id);
				break;
			case 'flicker_shim_modals':
				index = flickerShimList.indexOf(dirModel.options.id);
				break;
			case 'close_modals':
				index = closeModalList.indexOf(dirModel.options.id);
				break;
		}
		if (index >= 0) {
			isModalInExclusionList = true;
		}
		return isModalInExclusionList;
	}

	public isDeviceExtraSmall(): boolean {
		return this.getBreakpoint() === Breakpoints.EXTRASMALL.NAME;
	}
	public isSmallDevice(): boolean {
		const breakpoint = this.getBreakpoint();

		return includes([Breakpoints.EXTRASMALL.NAME, Breakpoints.SMALL.NAME], breakpoint);
	}
	public shouldBeFullscreen(): boolean {
		return this.isSmallDevice();
	}
	public isSafari = (nav: any): boolean => nav.userAgent.indexOf(USER_AGENT.SAFARI) !== -1 && !this.isChrome(nav);
	public isChrome = (nav: any): boolean => nav.userAgent.indexOf(USER_AGENT.CHROME) !== -1;
	public isFirefox = (nav): boolean => nav.userAgent.indexOf(USER_AGENT.FIREFOX) !== -1;
	public isIE(nav): boolean {
		const ieIndex = nav.userAgent.indexOf('MSIE '); // IE 10 or older
		const ie11Index = nav.userAgent.indexOf('Trident/'); // IE 11

		return ieIndex >= 0 || ie11Index >= 0;
	}
}
