<div id="login-base" class="base-wrapper">
	<div id="content">
		<h3 id="content_title" class="inner_content" aria-hidden="true">
			{{ heading$ | async | property }}
		</h3>
		<hr id="content_line" aria-hidden="true" />
		<p class="inner_content">
			<span id="content_message" aria-hidden="true">{{ message$ | async | property }}</span>
			<button id="logout_btn" class="btn btn-primary mt-3" aria-labelledby="content_title content_message logout_btn" (click)="logoutUser()" krn-ng-focus-on-show>
				{{ 'app.core.authentication.login.again' | property }}
			</button>
		</p>
	</div>
	<div id="kronos_footer" aria-hidden="true">
		<div id="krn_footer_img"></div>
	</div>
</div>
