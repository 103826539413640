import {BaseAttrs, FocusAttrs} from '../platform/attributes.interfaces';

export interface IllustrationMessageAttributes extends BaseAttrs, FocusAttrs {}

export enum IllustrationState {
	NODATA = 'nodata',
	ERROR = 'error',
	WARNING = 'warning',
	SUCCESS = 'success',
	DONE = 'done',
	LOADING = 'loading'
}
