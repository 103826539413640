import {TooltipConfig} from 'ngx-bootstrap/tooltip';

import {DeviceConfigService} from '../services/device-config.service';

export const defaultTooltipConfig = {
	container: 'body',
	containerClass: 'krn-default-tooltip',
	delay: 1000,
	placement: 'bottom'
};

export const getCustTooltipConfig = (customTooltipConfig, deviceConfigService: DeviceConfigService): TooltipConfig =>
	Object.assign(new TooltipConfig(), defaultTooltipConfig, customTooltipConfig || {}, {
		triggers: deviceConfigService.isMobileDevice() ? '' : 'mouseenter:mouseleave'
	});

const getTooltipConfig = (deviceConfigService: DeviceConfigService): TooltipConfig => getCustTooltipConfig({}, deviceConfigService);

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const TooltipConfigProvider = {
	deps: [DeviceConfigService],
	provide: TooltipConfig,
	useFactory: getTooltipConfig
};
