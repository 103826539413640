import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import{MfeHostComponent} from './mfe-host.component';
import {MfeHostRoutingModule} from './mfe-host-routing.module';

@NgModule({
	declarations: [MfeHostComponent],
	exports: [MfeHostComponent],
	imports: [
		CommonModule,
		MfeHostRoutingModule
	],
	providers: []
})
export class MfeHostComponentModule {}
