import {AUTH_COOKIE_NAME} from './authentication.constants';

export const getAuthCookieValue = (cookies = ''): string | null => {
	const nameEQ = `${AUTH_COOKIE_NAME}=`;
	const allCookies = cookies.split(';');

	for (const cookieItem of allCookies) {
		const cookie = cookieItem.trim();

		if (cookie.indexOf(nameEQ) === 0) {
			return cookie.substring(nameEQ.length, cookie.length);
		}
	}
	return null;
};
