<krn-ng-button
	*ngIf="context?.closeBtnVisible"
	[attrs]="{
		'aria-label': ('common.slideOutContainer.closeButton.label' | property),
		title: ('common.slideOutContainer.closeButton.label' | property)
	}"
	btnType="button"
	(buttonClicked)="actionHandler.close()"
	classes="btn btn-secondary close-button"
	icon="icon-k-close">
</krn-ng-button>
<div class="slideout-container">
	<ng-content></ng-content>
  <div id="slideout-help-accessibility-speaker" class="help-accessibility-speaker" style="position: absolute; top: 0px; left: -9999px;" aria-live="polite"></div>
</div>
<!--[ngClass]="{'z-topmost': showSpinner}"-->
