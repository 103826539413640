import {get, union} from 'lodash';
import {BehaviorSubject, from, interval, Subject} from 'rxjs';
import {mergeMap, take, takeUntil} from 'rxjs/operators';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';

import {PropertyFactory} from '../property/property.factory';
import {PROPERTIES as AUTH_PROPERTIES} from '../authentication/authentication.constants';
import * as constants from './offline.constants';

const FIRST_EMISSION_ONLY = 1;
const NO_ERRORS = 0;
const NO_RETRIES = 0;
const RETRY_COUNT = 3;
const TTL_EXPIRED = 0;
const THREE_SECONDS = 3000;
const BEFOREUNLOAD_EVENT = 'beforeunload';

const OFFLINE_PROPERTIES = [
	{
		name: constants.PROPERTY_FILE_NAME,
		path: constants.PROPERTY_FILE_PATH
	}
];

export class OfflineCoreService {
	// Accessible through angularJS provider
	public static enabled = true;

	// Internal subscriptions to socket event
	private unsubscribe$ = new Subject();

	// Observables that allow for outside interactions
	private propertiesObs$;
	private modalObs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private errorModalObs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private displayObs$: Subject<boolean> = new Subject<boolean>();

	private timeout = null; // Current timer
	private connected = true;
	private disconnectTimer = THREE_SECONDS;
	private errorRetryCount = NO_RETRIES;

	constructor(
		protected sockets: any,
		private propFactory: PropertyFactory,
		protected logger: any,
		private apiService: ApiService,
		private windowRef: any,
		private authenticationRegistrationService: any
	) {
		this.apiService.get(constants.OFFLINE_TIMEOUT_API, undefined, undefined, Transport.REST).then(timeout => this.setTimer(timeout));

		this.sockets
			.on('open')
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(() => {
				this.displayObs$.next(false);
				this.modalObs$.next(false);
				this.errorModalObs$.next(false);
				this.clearTimeout();
				logger.info(`Online detected: ${new Date().toUTCString()}`);
				if (!this.sockets.isReconnect) {
					this.authenticationRegistrationService.whenAuthenticated(() => {
						this.apiService.get(constants.SESSION_ALIVE_API, undefined, undefined, Transport.REST).then(this.hasSessionExpired);
					});
				}
			});

		this.sockets
			.on('close')
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(() => {
				this.startTimer();
				logger.info(`Offline detected: ${new Date().toUTCString()}`);
			});

		this.sockets
			.on('offline')
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(() => {
				logger.info(`Offline detected: local network is offline at ${new Date().toUTCString()}`);
				logger.error(`Detecting a Network Disruption: Offline detected: local network is offline at ${new Date().toUTCString()}`);
			});

		this.sockets
			.on('online')
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(() => {
				logger.info(`Online detected: local network is restored at ${new Date().toUTCString()}`);
				logger.error(`Coming out of a Network Disruption: Online detected: local network is restored at ${new Date().toUTCString()}`);
			});

		this.sockets
			.on('error')
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(err => {
				this.errorModalObs$.next(true);
				logger.error(`Error detected: ${err} ${new Date().toUTCString()}`);
			});

		this.sockets
			.on('incoming::error')
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(err => {
				if (this.errorRetryCount > NO_RETRIES) {
					if (--this.errorRetryCount === NO_RETRIES) {
						this.retriesExpired();
					} else {
						logger.error(`Error retries remaining: ${this.errorRetryCount}`);
					}
				}
			});

		this.windowRef.document.addEventListener('visibilitychange', this.visibilityChanged);
		this.windowRef.addEventListener(BEFOREUNLOAD_EVENT, this.navigationAction);

		// Allows us to only emit events from modal obs if we have loaded props (line 58)
		this.propertiesObs$ = from(this.propFactory.loadProperties(union(OFFLINE_PROPERTIES, AUTH_PROPERTIES)));
	}

	public setTimer(value): void {
		this.disconnectTimer = value;
	}

	public unsubscribeFromAll(): void {
		this.unsubscribe$.next(undefined);
		this.windowRef.document.removeEventListener('visibilitychange', this.visibilityChanged);
	}

	public getDisplayObservable(): Subject<boolean> {
		return this.displayObs$;
	}

	public getModalObservable(): BehaviorSubject<boolean> {
		return this.propertiesObs$.pipe(mergeMap(() => this.modalObs$));
	}

	public getErrorModalObservable(): BehaviorSubject<boolean> {
		return this.propertiesObs$.pipe(mergeMap(() => this.errorModalObs$));
	}

	protected retriesExpired(): void {
		this.logger.error('Error retries expired.');
	}

	protected sessionExpired(): void {
		this.logger.error('Session has expired.');
	}

	private startTimer(): void {
		this.timeout =
			this.timeout ||
			interval(this.disconnectTimer)
				.pipe(take(FIRST_EMISSION_ONLY))
				.subscribe({
					next: () => this.initTimerFinish(),
					complete: () => this.secondaryTimerStart()
				});
	}

	private initTimerFinish(): void {
		this.displayObs$.next(true);
		this.timeout.complete();
	}

	private secondaryTimerStart(): void {
		if (OfflineCoreService.enabled) {
			this.connected = false;
			this.timeout = interval(this.disconnectTimer)
				.pipe(take(FIRST_EMISSION_ONLY))
				.subscribe({
					next: () => {
						this.timeout = null;
						this.modalObs$.next(true);
					}
				});
		} else {
			this.timeout = null;
		}
	}

	private clearTimeout(): void {
		if (this.timeout) {
			this.timeout.unsubscribe();
			this.timeout = null;
		}
		this.connected = true;
	}

	private hasSessionExpired = (res): void => {
		if (get(res, 'ttl', TTL_EXPIRED) <= TTL_EXPIRED) {
			this.sessionExpired();
		}
	};

	private visibilityChanged = (): void => {
		const isVisible = this.windowRef.document.visibilityState === 'visible';

		this.logger.info(`connection is ${isVisible ? '' : 'not '}visible ${new Date().toUTCString()}`);
		if (isVisible && !this.connected) {
			this.errorRetryCount = RETRY_COUNT;
		}
	};

	private navigationAction = (): void => {
		this.logger.error(`Navigation Action Disruption: Page Unload Event (Back Button, Page Refresh, Navigate Forward): ${new Date().toUTCString()}`);
	};
}
