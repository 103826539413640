<div class="contextual-help-search-result">
	<div class="row result-header flex-nowrap justify-content-between">
		<div class="search-area">
			<krn-ng-button
				icon="open-caret-left"
				[buttonText]="'html.navbar.contextualHelp.search.back' | property"
				(buttonClicked)="backToHelp()"
				(keypress)="backToHelpOnKeyPressAction($event.keyCode)"
				classes="btn-link btn-icon-small"
				id="helpSearchBackAction"
				[attrs]="{
					'aria-label': 'html.navbar.contextualHelp.search.backToHelp' | property,
					title: 'html.navbar.contextualHelp.search.backToHelp' | property
				}">
			</krn-ng-button>
		</div>
		<div class="result-data">
			<span>{{ 'html.navbar.contextualHelp.search.results' | property }}</span>
			<span class="count">[{{ sortState.resultCount }}]</span>
		</div>
	</div>
	<div *ngIf="!newSearch" class="row result-action flex-nowrap justify-content-between">
		<div class="sort-area">
			<div class="dropdown">
				<krn-ng-single-select-picker
					id="resultSortBy"
					[attr.aria-label]="sortByLabel"
					classes="sort-dropdown border-0"
					[data]="resultSortByData"
					[label]="sortByLabel"
					[ngClass]="{disabled: sortState.resultCount === 0, enabled: sortState.resultCount > 0}"
					[(selected)]="selectedSortBy"
					(selectedChange)="sortBy(selectedSortBy.key)">
				</krn-ng-single-select-picker>
			</div>
		</div>
		<div class="refine-area refine">
			<krn-ng-button
				[icon]="sortState.refined ? 'icon-k-filter-dot' : 'icon-k-filter-on'"
				[buttonText]="'html.navbar.contextualHelp.search.refine' | property"
				(buttonClicked)="openRefinePanel()"
				classes="btn-link btn-icon-small"
				alignment="left"
				[attrs]="{
					'aria-label': 'html.navbar.contextualHelp.search.refineResults' | property,
					title: 'html.navbar.contextualHelp.search.refineResults' | property,
					id: 'helpSearchRefineAction'
				}">
			</krn-ng-button>
		</div>
	</div>
</div>
<div class="contextual-help-spinner-container">
	<krn-ng-loader classes="bg-transparent spinner-size-small contextual-help-spinner" *ngIf="sortState.loading"></krn-ng-loader>
</div>
