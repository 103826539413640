import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ReleaseToggleServiceProvider} from '../../../../providers/release-toggle-service.provider';
import {DynamicItemModule} from '../../../../utils/dynamic-item/dynamic-item.module';
import {ButtonModule} from '../../../button/button.module';
import {IframeContainerModule} from '../../../framework/iframe-container/iframe-container.module';
import {MessageModule} from '../../../message/message.module';
import {CardComponent} from './card.component';

@NgModule({
	imports: [
		CommonModule,
		DynamicItemModule,
		MessageModule,
		IframeContainerModule,
		ButtonModule
	],
	exports: [CardComponent],
	declarations: [CardComponent],
	providers: [ReleaseToggleServiceProvider]
})
export class CardModule {}