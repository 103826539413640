import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';

import {DeprecationMessageService} from '../../services/deprecation-message/deprecation-message.service';
import {PropertyFilterPipe} from '../../providers/property/property.filter.pipe';
import {PropertyModule} from '../../providers/property/property.module';
import {FocusOnShowModule} from '../../utils/focus-on-show/focus-on-show.module';
import {ModalKeyboardNavigationModule} from '../../utils/modal-keyboard-navigation/modal-keyboard-navigation.module';
import {ButtonModule} from '../button/button.module';
import {PopoverButtonComponent} from './popover-button.component';
import {ScrollDetectorModule} from '../../utils/scroll-detector/scroll-detector.module';

/**
 * @deprecated This popover-button component is deprecated and will be removed in the future in favor of components found within the Ignite design system. [Click here for more details](https://engstash.int.kronos.com/projects/FRONT/repos/frontend/browse/packages/web/zed/README.md)
 */
@NgModule({
	declarations: [PopoverButtonComponent],
	exports: [PopoverButtonComponent],
	imports: [
		ButtonModule,
		CommonModule,
		ModalModule.forRoot(),
		PopoverModule.forRoot(),
		FocusOnShowModule,
		PropertyModule,
		ModalKeyboardNavigationModule,
		ScrollDetectorModule
	],
	providers: [PropertyFilterPipe]
})
export class PopoverButtonModule {
	constructor(private deprecationMessageService: DeprecationMessageService) {
		this.deprecationMessageService.log('popover-button');
	}
}
