import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {MOBILE_OS} from '../../../../core/platform/services/DeviceConfigServices.constants';
import {SessionTimeoutCoreController} from '../../../../core/session/session.timeout.core.component';
import {DeviceConfigService} from '../../../../services/device-config.service';
import {SessionActionService} from '../session.action';

@Component({
	selector: 'krn-ng-session-unavailable',
	templateUrl: './session-unavailable.component.html',
	styleUrls: ['./session-unavailable.component.less'],
	encapsulation: ViewEncapsulation.None
})
export class SessionUnavailableComponent extends SessionTimeoutCoreController implements OnInit {
	public timeLeft: string[];
	public currentTimeLeft: string[];
	public isIOSDevice;

	constructor(
		protected sessionActionService: SessionActionService,
		protected deviceConfigService: DeviceConfigService
	) {
		super(sessionActionService.dispatch);
		this.isIOSDevice = this.deviceConfigService.getMobileOS() === MOBILE_OS.IOS;
	}

	public ngOnInit(): void {
		super.init();
	}
}
