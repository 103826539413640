export interface ShiftIncentive {
	incentiveValue?: number;
	localizedIncentiveName?: string;
	localizedIncentiveDesc?: string;
	incentiveType: IncentiveType;
	creationDateTime?: Date;
	currentValue?: any;
	id?: number;
	incentiveDefId?: number;
	shiftId?: number;
}

export enum ShiftRiskLevel {
	NONE = 'NONE',
	LOW = 'LOW',
	MODERATE = 'MODERATE',
	HIGH = 'HIGH'
}

export interface ShiftRiskInfo {
	formattedShiftRiskLevel: string;
	shiftRiskLevel: ShiftRiskLevel;
}

export enum IncentiveType {
	MONEY_FIXED_AMOUNT = 'MONEY_FIXED_AMOUNT',
	MONEY_FIXED_AMOUNT_PER_HOUR = 'MONEY_FIXED_AMOUNT_PER_HOUR',
	MONEY_RATIO_AMOUNT = 'MONEY_RATIO_AMOUNT',
	MONEY_RATIO_AMOUNT_PER_HOUR = 'MONEY_RATIO_AMOUNT_PER_HOUR',
	TIME_FIXED_AMOUNT = 'TIME_FIXED_AMOUNT',
	TIME_RATIO_AMOUNT = 'TIME_RATIO_AMOUNT',
	PERK = 'PERK',
	WORK_RULE = 'WORK_RULE'
}
