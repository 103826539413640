import {cloneDeepWith, isInteger, isNil, noop} from 'lodash';

import {PageContext} from './page-context.interfaces';
import {ClientContainer} from '../platform/clientContainer/client-container.interface';

import {AuthenticationRegistrationCoreService} from '../authentication/authentication.registration.core.service';
import {SessionStorageCoreService} from '../platform/caching/sessionStorage.service';
import {DateServiceUtility} from '../platform/converters/date-service.utility';
import {CURRENT_CONTEXT_KEY} from './page-context.constants';
import {excludedItem, getContextFromURL, getDefaultContext, getPathFromLocation} from './page-context.utility';

export class PageContextCoreService {

	constructor(
		protected logger,
		private dateService: DateServiceUtility,
		protected sessionStorageService: SessionStorageCoreService,
		private authenticationRegistrationService: AuthenticationRegistrationCoreService,
		private clientContainer: ClientContainer
	) {
		const urlContext = getContextFromURL(window.location.href, noop);
		const urlPath = getPathFromLocation(window.location);

		this.authenticationRegistrationService.whenAuthenticated(() => {
			// once authenticated, apply context and update history
			if (urlContext) {
				this.clientContainer.replaceState(urlPath || getPathFromLocation(window.location));
				this.setContext(urlContext);
			}
		});
	}

	public clearContext(): void {
		this.sessionStorageService.clear(CURRENT_CONTEXT_KEY);
	}

	public getContext(): PageContext {
		const context = this.getContextWithoutClear();

		this.clearContext(); // context will be automatically cleared after retrieval
		return context;
	}

	public defaultContext(): PageContext {
		return getDefaultContext();
	}

	public hasContext(): boolean {
		const context = this.sessionStorageService.get(CURRENT_CONTEXT_KEY);

		return !isNil(context);
	}

	public setContext(contextObject: PageContext): void {
		const context = contextObject ? JSON.stringify(contextObject) : null;

		this.sessionStorageService.set(CURRENT_CONTEXT_KEY, context);
		this.sessionStorageService.apply();
	}

	public normalizeDates(data: object): object {
		return cloneDeepWith(data, (value, key) => {
			if (!isInteger(key) && !excludedItem(key)) {
				const normalizedDate = this.dateService.normalizeDate(value);

				if (normalizedDate) {
					return normalizedDate;
				}
			}
		});
	}

	public getContextWithoutClear(): PageContext {
		let context = this.sessionStorageService.get(CURRENT_CONTEXT_KEY) || null;

		try {
			context = this.normalizeDates(JSON.parse(context));
		} catch (ex) {
			this.logger.log(ex);
		}

		return context;
	}
}
