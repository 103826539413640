import {get, isNull, isString, isUndefined} from 'lodash';

import {cleanPatternSymbols, formatWithNegativePattern, formatWithPositivePattern, isNegativePatternCorrect} from './currency.utility';

export class CurrencyCoreService {
	constructor(protected converterUtilService, protected floatService, protected preferences, protected logService) {}

	public isValid = (currencyStr: string): boolean => {
		if (!isString(currencyStr)) {
			return false;
		}

		if (this.converterUtilService.isContainsNegativeSymbol(currencyStr)) {
			currencyStr = cleanPatternSymbols(currencyStr, this.preferences);
			if (isNegativePatternCorrect(currencyStr, this.preferences, this.logService)) {
				currencyStr = this.converterUtilService.cleanNegativeSymbols(currencyStr);
				return this.floatService.isValid(currencyStr);
			}
			return false;
		}
		return this.floatService.isValid(currencyStr);
	};

	public format = (currency, precision, symbol: string): string => {
		const decimalPrecision = this.preferences.localePolicy.currencyFormat.decimalPrecision;

		symbol = symbol || get(this.preferences, 'currency.code') || get(this.preferences, 'currencyAssignment.currencyCode');

		if (symbol === 'UNS') {
			symbol = '';
		}

		if (isUndefined(precision) || isNull(precision)) {
			precision = decimalPrecision;
		}

		const formattedNumber = this.floatService.format(currency, precision);

		return currency < 0 ? formatWithNegativePattern(formattedNumber, symbol, this.preferences, this.logService) : formatWithPositivePattern(formattedNumber, symbol, this.preferences, this.logService);
	};

	public formatWithoutSymbol = (currency, precision): string => this.format(currency, precision, 'UNS');

	public parse = (currencyStr: string, decimalPrecision: number): number => {
		if (!this.isValid(currencyStr)) {
			throw new Error();
		}

		if (this.converterUtilService.isContainsNegativeSymbol(currencyStr)) {
			currencyStr = this.converterUtilService.cleanNegativeSymbols(currencyStr);
			const currencyFloat = this.floatService.parse(currencyStr, true, false, decimalPrecision || this.preferences.localePolicy.currencyFormat.decimalPrecision);

			return parseFloat(`-${currencyFloat}`);
		}
		return this.floatService.parse(currencyStr, true, false, decimalPrecision || this.preferences.localePolicy.currencyFormat.decimalPrecision);
	};
}