import {Injectable} from '@angular/core';

import {ZoomCoreService} from '../../core/platform/services/zoom.core.service';
import {WindowRefService} from '../window-ref.service';

@Injectable({
	providedIn: 'root',
	deps: [WindowRefService],
	useFactory: getZoomService
})
export class ZoomService extends ZoomCoreService {}

export function getZoomService(windowRefService: WindowRefService): ZoomService {
	return new ZoomCoreService(windowRefService.nativeWindow);
}
