import {get, includes} from 'lodash';

import {CordovaPluginWindow} from './cordova-plugin-window.interface';
import {ApiCriteria, Mapping, MappingHandler} from './mapping.interface';

import {MAP_SUCCESS, MAPPING_INT_ERR_CANNOT_USE, MapType} from './mapping.constants';

export class MappingCoreService implements MappingHandler {
	constructor(protected $window: CordovaPluginWindow) {}

	public launchMap(apiCriteria: ApiCriteria, mapType: MapType): Promise<Mapping> {
		return new Promise<Mapping>((resolve, reject) => {
			if (!get(this.$window, 'ukgMapping.launchNativeMap') || !includes([MapType.PUNCH, MapType.TIMECARD], mapType)) {
				reject(MAPPING_INT_ERR_CANNOT_USE);
			}

			this.$window.ukgMapping.launchNativeMap(apiCriteria, mapType);
			resolve(MAP_SUCCESS);
		});
	}
}
