import {BaseWifiLocationService} from '../platform/services/base-wifi-location.service';
import {getWifiFromSMA} from '../platform/services/geolocation.sma.utility';
import {PropertyFactory} from '../property/property.factory';

export class EmbeddedWifiLocationService extends BaseWifiLocationService {
	constructor(protected window: Window, protected supportsWifiLocation: boolean, protected propertyFactory: PropertyFactory, protected propertyFilter) {
		super(window, supportsWifiLocation, propertyFactory, propertyFilter);
	}

	public requestWifiLocation(): void {
		getWifiFromSMA(this.window, this.context.successCallback, this.context.errorCallback, this.errorMessage);
		this.window.dispatchEvent(new CustomEvent('upPostMessage', {detail: {method: 'getWifiInformation'}}));
	}
}