const AUTH_FLOW_ACTIVE_KEY = 'dim.is.authflow.active';
const AUTH_FLOW_DONE_PATH = '/mobileapp/authdone';

export const oneappSSOMobileContextInit = (win, sender): Promise<any> =>
	new Promise(resolve => {
		const hostname = win.location.hostname;

		win.OneAppCommon.DATA.getGlobalData(win, sender, {key: AUTH_FLOW_ACTIVE_KEY}).then(response => {
			// eslint-disable-next-line eqeqeq
			if (response.data.value == 'true') {
				resolve(false);
				win.location.assign(`https://${hostname}${AUTH_FLOW_DONE_PATH}`);
			} else {
				resolve(true);
			}
		});
	});
