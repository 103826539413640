import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {camelCase, forIn, get, isEqual, set} from 'lodash';

import {PropertyState} from '../core/property/property.interface';

import {actionsHelper} from '../core/property/property.actions';
import {PROPERTY_DEFAULT_STATE, reducerHelper} from '../core/property/property.reducer';

@Injectable({
	providedIn: 'root'
})
export class PropertyActionService {
	public actions;
	private propertySource = {...PROPERTY_DEFAULT_STATE};
	private propertySubject = new BehaviorSubject({...PROPERTY_DEFAULT_STATE});

	constructor() {
		this.actions = this.propertyActionsHelper();
	}

	public select = (path: string[]): Observable<PropertyState[keyof PropertyState]> =>
		this.propertySubject.pipe(map(state => get({property: state}, path)));

	public dispatch = (action: any): void => {
		let newSource = {...this.propertySource};

		newSource = get(reducerHelper(), action.type)(newSource, action);
		if (!isEqual(newSource, this.propertySource)) {
			this.propertySource = newSource;
			this.propertySubject.next(this.propertySource);
		}
	};

	private propertyActionsHelper = (): Partial<any> => {
		const actions = {};

		forIn(actionsHelper(), (val: (...params) => any, key: string) => {
			set(actions, camelCase(key), (...params) => ({type: key, payload: val(...params)}));
		});
		return actions;
	};
}
