<krn-ng-menu-link *ngIf="context?.displayNotifications && counter <= 0"
                  [config]="options"
                  aria-describedby="navBarAlertsAriaDesc">
</krn-ng-menu-link>
<krn-ng-menu-button *ngIf="!context?.displayNotifications || counter > 0"
                    [config]="options"
                    [class]="options.mainClasses"
                    aria-describedby="navBarAlertsAriaDesc">
  <div *ngIf="context.displayNotifications" class="alert-counter pill">{{counter}}</div>
</krn-ng-menu-button>
