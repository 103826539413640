// eslint-disable-next-line @typescript-eslint/naming-convention
export const Breakpoints = {
	EXTRASMALL: {
		MAX: 320,
		NAME: 'extrasmall'
	},
	SMALL: {
		MIN: 321,
		MAX: 768,
		NAME: 'small'
	},
	MEDIUM: {
		MIN: 769,
		MAX: 996,
		NAME: 'medium'
	},
	LARGE: {
		MIN: 997,
		NAME: 'large'
	},
	EXTRALARGE: {
		MIN: 1400,
		NAME: 'extralarge'
	}
};
