import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ContainerSemaphoreService {
	private semaphore = new BehaviorSubject<boolean>(false);

	public getSemaphore(): BehaviorSubject<boolean> {
		return this.semaphore;
	}

	public toggleSemaphore(): void {
		this.semaphore.next(true);
	}
}
