import * as jQuery from 'jquery';
import {find, forEach, isEqual, isFunction} from 'lodash';

import {KeyNavService} from './keyboard-navigation.interfaces';

import {toFocusableElementString} from '../focusable-elements.utility';
import {EVENTS_HANDLERS} from '../utils.constants';

const $: any = jQuery;
const DELAY = 500;

export class BlurDetectionService implements KeyNavService {
	private blurInProgressId: ReturnType<typeof setTimeout>;

	constructor(private focusableElements: HTMLElement[], private focusableElementStrings: string[], private blurCallback) {}

	public getNode(element: HTMLElement): any {
		return $(element);
	}

	public initialize(): void {
		forEach(this.focusableElements, element => this.getNode(element).on(EVENTS_HANDLERS.BLUR, this.blurHandler));
	}

	public destroy(): void {
		forEach(this.focusableElements, element => this.getNode(element).off(EVENTS_HANDLERS.BLUR, this.blurHandler));
	}

	private blurHandler = (): void => {
		if (this.blurCallback) {
			this.initializeBlurInProgress();
			this.blurInProgressId = setTimeout(() => {
				this.blurInProgressId = undefined;
				const focusElementString = toFocusableElementString(document.activeElement as HTMLElement);
				const found = find(this.focusableElementStrings, element => isEqual(element, focusElementString));

				if (!found) {
					if (isFunction(this.blurCallback.emit)) {
						this.blurCallback.emit();
					} else {
						this.blurCallback();
					}
				}
			}, DELAY);
		}
	};

	private initializeBlurInProgress(): void {
		if (this.blurInProgressId) {
			clearTimeout(this.blurInProgressId);
			this.blurInProgressId = undefined;
		}
	}
}
