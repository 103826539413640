import {camelCase, clone, compact, concat, get, includes, isBoolean, isEmpty, isString, remove, uniq, uniqueId} from 'lodash';

import {FormElemAttrs} from './attributes.interfaces';

import {CommonConstants, TEXT_ALIGMENT} from './constants/CommonConstants';

export const centerLabel = (attrs: FormElemAttrs, label: string, value: object | string): boolean =>
	!isEmpty(label) && isEmpty(value) && !get(attrs, 'placeholder', false);

const getContainerClasses = (classes?: string | string[]): string[] => isString(classes) ? classes.split(' ') : clone(classes) || [];

export const getClasses = (
	classes?: string | string[],
	alignment?: string,
	attrs?: FormElemAttrs,
	label?: string,
	value?: object | string
): string[] => {
	let labelClass: string | string[] = alignment ? `label-${alignment}` : [];

	if (isEmpty(labelClass)) {
		labelClass = centerLabel(attrs, label, value) ? 'label-center' : [];
	}
	return compact(
		concat(
			getContainerClasses(classes),
			labelClass,
			label ? 'withLabel' : [],
			get(attrs, 'disabled') ? 'disabled' : [],
			get(attrs, 'required') ? 'required' : []
		)
	);
};

export const getPlacement = (alignment?: string, dir?: string): string => {
	const defaultPlacement = CommonConstants.HTML_ALIGNMENT.BOTTOM;
	const textAligments = [TEXT_ALIGMENT.CENTER, TEXT_ALIGMENT.LEFT, TEXT_ALIGMENT.RIGHT];

	if (includes(textAligments, alignment)) {
		return alignment === TEXT_ALIGMENT.CENTER ? defaultPlacement : `${defaultPlacement} ${alignment}`;
	}
	return `${defaultPlacement} ${dir === 'rtl' ? TEXT_ALIGMENT.RIGHT : TEXT_ALIGMENT.LEFT}`;
};

export const getUniqueId = (attrs?: FormElemAttrs, label?: string, prefix?: string): string =>
	get(attrs, 'id') || `${prefix}_${camelCase(label) || uniqueId()}`;

export const isDisabled = (attrs: FormElemAttrs): boolean => {
	const disabled = get(attrs, 'disabled', false);

	return isBoolean(disabled) ? disabled : !isEmpty(disabled);
};

export const updateClass = (classes: string | string[], className: string, addClass: boolean): string[] => {
	const arrayOfClass = uniq(getContainerClasses(classes));

	if (addClass) {
		arrayOfClass.push(className);
	} else {
		remove(arrayOfClass, item => item === className);
	}
	return arrayOfClass;
};
