import {PropertyActionService} from '../../services/property-action.service';
import {PropertyStateService} from './property-state.service';


// eslint-disable-next-line @typescript-eslint/naming-convention
export const PropertyStateServiceProvider = {
	provide: PropertyStateService,
	deps: [PropertyActionService],
	useFactory: getPropertyStateService
};

export function getPropertyStateService(propertyActionService: PropertyActionService): any {
	return new PropertyStateService(propertyActionService);
}
