import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {TooltipConfigProvider} from '../../../providers/tooltip-config.provider';
import {AttributesModule} from '../../../utils/attributes/attributes.module';
import {SingleSelectNativePickerComponent} from './single-select-native-picker.component';
import {SingleSelectPickerBaseModule} from '../single-select-picker-base/single-select-picker-base.module';

@NgModule({
	declarations: [SingleSelectNativePickerComponent],
	exports: [SingleSelectNativePickerComponent],
	imports: [CommonModule, AttributesModule, FormsModule, SingleSelectPickerBaseModule, TooltipModule.forRoot()],
	providers: [TooltipConfigProvider]
})
export class SingleSelectNativePickerModule {}
