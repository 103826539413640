import {Inject, Injectable, Injector} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NGXLogger} from 'ngx-logger';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {UserInfo} from '../../../core/framework/services/user-info.interface';

import {SocketService} from '../../../services/communication/socket.service';
import {
	MILLISECONDS_IN_SECOND,
	SESSION_EXPIRATION_LAST_TICK,
	SESSION_TIMEOUT_PROPERTIES
} from '../../../core/session/session.constants';
import {PropertyFactory} from '../../../core/property/property.factory';
import {SessionCoreService} from '../../../core/session/session.core.service';
import {UserInfoActionService} from '../../../services/user-info-action.service';
import {AuthenticationRegistrationService} from '../authentication/authentication.registration.service';
import {Auth0SessionTimeoutComponent} from './auth0-session-timeout.component';
import {Auth0StackService} from './authStack/auth0-stack.service';
import {OpenAMStackService} from './authStack/openam-stack.service';
import {SessionTimeoutComponent} from './session-timeout.component';
import {SessionUnavailableComponent} from './session-unavailable/session-unavailable.component';

const ZERO_COUNT = 0;

@Injectable({providedIn: 'root'})
export class SessionService extends SessionCoreService {
	public bsModalRef: BsModalRef;
	private hideModel: () => void;
	private notifier$ = new Subject();

	constructor(
		private injector: Injector,
		protected logger: NGXLogger,
		private bsModalService: BsModalService,
		protected authenticationRegistrationService: AuthenticationRegistrationService,
		protected propertyFactory: PropertyFactory,
		private userInfoActionService: UserInfoActionService,
		@Inject(SocketService) protected socketService: SocketService
	) {
		super(authenticationRegistrationService, propertyFactory);
		this.initAuthService();
		this.hideModel = () => {
			this.bsModalRef.hide();
		};
	}

	public expiringCallback(eventName: string, eventArgs): void {
		if (!this.isAuth0Service) {
			this.openAMExpiringCallback(eventArgs);
		} else {
			this.Auth0ExpiringCallback(eventArgs);
		}
	}

	public extendedCallback(eventName: string, eventArgs: any): void {
		if (!this.isAuth0Service) {
			this.bsModalService.hide();
			clearInterval(this.bsModalRef?.content.interval);
		}
	}

	public unavailableCallback(eventName: string, eventArgs): void {
		if (!this.isAuth0Service) {
			this.showModal(eventArgs, SessionUnavailableComponent);
		}
	}

	public reconnectCallback(eventName: string, eventArgs): void {
		this.socketService.reconnect();
	}

	protected setAuthStackService(): void {
		this.userInfoActionService
			.select(['userInfo'])
			.pipe(takeUntil(this.notifier$))
			.subscribe((data: UserInfo) => {
				if (!this.authStackService) {
					this.injectAuthStackService(data.auth0Login);
				} else if (data.auth0Login !== this.isAuth0Service) {
					this.authStackService?.destroy();
					this.injectAuthStackService(data.auth0Login);
				}
			});
	}

	private injectAuthStackService(auth0Login: boolean): void {
		this.authStackService = this.injector.get(auth0Login ? Auth0StackService : OpenAMStackService);
		this.authStackService?.setSessionService(this);
		this.isAuth0Service = auth0Login;
	}

	private showModal(eventArgs, component, callback?): void {
		if (this.bsModalService.getModalsCount() === ZERO_COUNT) {
			this.bsModalRef = this.bsModalService.show(component, {
				backdrop: 'static',
				ignoreBackdropClick: true,
				initialState: {
					gracePeriod: eventArgs.gracePeriod,
					extendable: eventArgs.extendable,
					onClose: this.hideModel,
					onDismiss: this.hideModel
				},
				ariaDescribedby: 'sessionUnavailableModalDesc',
				ariaLabelledBy: 'sessionUnavailableModalTitle'
			});
			if (callback) {
				callback();
			}
		}
	}

	private Auth0ExpiringCallback(eventArgs: any): void {
		this.showModal(eventArgs, Auth0SessionTimeoutComponent);
	}

	private openAMExpiringCallback(eventArgs): void {
		const now = Date.now();

		if (!eventArgs.extendable) {
			const value = sessionStorage.getItem(SESSION_EXPIRATION_LAST_TICK);
			const lastDisplayedOn = Number(value || 0);
			const millisSinceLastDisplayed = now - lastDisplayedOn;

			if (millisSinceLastDisplayed < eventArgs.gracePeriod * MILLISECONDS_IN_SECOND) {
				return;
			}
		}

		this.showModal(eventArgs, SessionTimeoutComponent, () => {
			if (!eventArgs.extendable) {
				sessionStorage.setItem(SESSION_EXPIRATION_LAST_TICK, now.toString());
			}
		});
	}
}
