import {size} from 'lodash';

import {Selector} from './selector.interface';

export class SelectorService {
	private selectors: Selector[];

	constructor() {
		this.selectors = [];
	}

	public cleanSelectors(): void {
		if (size(this.selectors)) {
			this.selectors = [];
		}
	}

	public loadSelector(selector: Selector): void {
		this.selectors.push(selector);
	}

	public select = (list: HTMLElement[]): HTMLElement[] =>	this.selectors.reduce((newState, reducer) => reducer.select(newState), list);
}
