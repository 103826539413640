<krn-ng-single-select-picker-base
	[alignment]="alignment"
	[attrs]="baseAttrs"
	[classes]="classes"
	[data]="data"
	[dataLabel]="dataLabel"
	[label]="label"
	[selected]="selected"
	[showAsNative]="true"
	[tooltipContainerClass]="tooltipContainerClass"
	(selectedChange)="onSelectedChange($event)">
	<select
		class="single-select-picker-dropdown form-element position-absolute"
		[ngClass]="alignment === 'right' ? 'overflow-value-right' : 'overflow-value-left'"
		[class.placeholder]="selected === undefined"
		[krn-ng-attributes]="nativeAttrs"
		[tooltip]="baseAttrs?.title"
		[placement]="placement"
		[(ngModel)]="selectedValue"
		(ngModelChange)="onSelected()"
		[disabled]="attrs?.disabled"
		[required]="attrs?.required">
		<option *ngIf="attrs?.placeholder" [ngValue]="undefined" [disabled]="attrs?.required">{{ attrs?.placeholder }}</option>
		<option *ngFor="let item of data" [ngValue]="item">{{ item[dataLabel] }}</option>
	</select>
	<span class="single-select-picker-icon" [ngClass]="layout" [krn-ng-attributes]="{'aria-hidden': 'true', dir: attrs?.dir}"></span>
	<span class="single-select-picker-focus"></span>
</krn-ng-single-select-picker-base>
