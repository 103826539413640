import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {FocusOnShowModule} from '../../../../../utils/focus-on-show/focus-on-show.module';
import {ReturnFocusElementModule} from '../../../../../utils/return-focus-element/return-focus-element.module';
import {UserInfoServiceProvider} from '../../../../../providers/user-info-service.provider';
import {DynamicItemModule} from '../../../../../utils/dynamic-item/dynamic-item.module';
import {ButtonModule} from '../../../../button/button.module';
import {IllustrationMessageModule} from '../../../../illustration-message/illustration-message.module';
import {InlineValidationModule} from '../../../../inline-validation/inline-validation.module';
import {LoaderModule} from '../../../../loader/loader.module';
import {MessageModule} from '../../../../message/message.module';
import {PopoverButtonModule} from '../../../../popover-button/popover-button.module';
import {SearchModule} from '../../../../search/search.module';
import {SelectItemModule} from '../../../../select-item/select-item.module';
import {SingleSelectPickerModule} from '../../../../single-select-picker/single-select-picker.module';
import {MfeHostComponentModule} from '../../../mfe-host/mfe-host.module';
import {OfflineComponent} from '../../../offline/offline.component';
import {SlideoutContentModule} from '../../../slide-out/slide-out-content/slideout-content.module';
import {SlideOutModule} from '../../../slide-out/slide-out.module';
import {NavBarSharedModule} from '../../navbar.shared.module';
import {ContextualHelpSearchResultFilterComponent} from './contextual-help-search-result-filter/contextual-help-search-filter.component';
import {ContextualHelpSearchResultListComponent} from './contextual-help-search-result-list/contextual-help-search-result-list.component';
import {ContextualHelpSearchResultComponent} from './contextual-help-search-result/contextual-help-search-result.component';
import {ContextualHelpComponent} from './contextual-help/contextual-help.component';
import {HelpActionItemComponent} from './contextual-help/help-action-item.component';
import {NetworkActionItemComponent} from './network-action-item.component';
import {NotificationActionItemComponent} from './notification-action-item.component';
import {QuickFindActionItemComponent} from './quick-find-action-item.component';
import {SuiteXSearchActionItemComponent} from './suite-x-search-action-item.component';

@NgModule({
	imports: [
		ButtonModule,
		CommonModule,
		DynamicItemModule,
		FocusOnShowModule,
		IllustrationMessageModule,
		InlineValidationModule,
		LoaderModule,
		MfeHostComponentModule,
		NavBarSharedModule,
		SlideOutModule,
		SlideoutContentModule,
		MessageModule,
		ModalModule.forRoot(),
		PopoverButtonModule,
		ReturnFocusElementModule,
		SearchModule,
		SelectItemModule,
		SingleSelectPickerModule,
		TooltipModule.forRoot()
	],
	declarations: [
		HelpActionItemComponent,
		NetworkActionItemComponent,
		NotificationActionItemComponent,
		QuickFindActionItemComponent,
		SuiteXSearchActionItemComponent,
		ContextualHelpComponent,
		ContextualHelpSearchResultFilterComponent,
		ContextualHelpSearchResultComponent,
		ContextualHelpSearchResultListComponent,
		OfflineComponent
	],
	providers: [UserInfoServiceProvider]
})
export class ActionItemModule {}
