import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {ClientContainerContextService} from '../../../services/clientContainer/client-container-context.service';
import {ClientContainerService} from '../../../services/clientContainer/client-container.service';
import {SessionStorageService} from '../../../services/session-storage.service';
import {UnsavedDataService} from '../../../services/unsaved-data-service';
import {WindowRefService} from '../../../services/window-ref.service';
import {BaseEmbeddedPageComponent} from '../embedded-page/base-embedded-page.component';
import {OneAppEventBusService} from '../../../services/one-app-event-bus.service';
import {OneAppUnsavedDataService} from './one-app-unsaved-data.service';

@Component({
	selector: 'krn-ng-one-app-page',
	templateUrl: './one-app-page.component.html'
})
export class OneAppPageComponent extends BaseEmbeddedPageComponent{
	constructor(
		protected clientContainerService: ClientContainerService,
		protected oneAppEventBusService: OneAppEventBusService,
		protected router: Router,
		protected sessionStorageService: SessionStorageService,
        protected unsavedDataService: UnsavedDataService,
		protected windowRefService: WindowRefService,
		protected oneAppUnsavedDataService: OneAppUnsavedDataService,
		protected clientContainerContextService: ClientContainerContextService
	) {
		super(
			clientContainerService,
			oneAppEventBusService,
			router,
			sessionStorageService,
			windowRefService,
			oneAppUnsavedDataService,
			clientContainerContextService
		);
	}

	public navigationEnd = (event: NavigationEnd): void => {
		super.navigationEnd(event);
		this.unsavedDataService.setDirty(false);
	};
}
