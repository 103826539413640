
<label class="input-container form-element-container"
    [class.disabled]="attrs?.disabled"
    [class.focus]="isFocused"
    [class.label-center]="!alignment && !attrs?.placeholder && !inputValue && !!label"
    [class.required]="attrs?.required"
    [class.withLabel]="!!label"
    [ngClass]="alignment ? inputClasses + ' label-' + alignment : inputClasses"
    [attr.dir]="attrs?.dir"
    [attr.for]="attrs?.id">
    <span *ngIf="attrs?.required" class="required-flag" aria-hidden="true">*</span>
    <span *ngIf="label" [attr.id]="attrs?.id + 'Label'"
        class="control-label"
        [tooltip]="label"
        [placement]="placement">
        {{label}}
    </span>
    <input
        class="simple-input form-element"
        [class.placeholder]="isPlaceholder(attrs, inputValue)"
        [disabled]="attrs?.disabled"
        [tooltip]="inputTooltip"
        [placement]="placement"
        [krn-ng-attributes]="{
            'aria-controls': attrs['aria-controls'],
            'aria-describedby': attrs['aria-describedby'],
            'aria-expanded': attrs['aria-expanded'],
            'aria-haspopup': attrs['aria-haspopup'],
            'aria-label': attrs['aria-label'],
            'aria-labelledby': ariaLabelBy,
            'autocomplete': attrs['autocomplete'] || 'on',
            'automation-id': attrs['automation-id'],
            'id': attrs?.id,
            'maxLength': attrs?.maxLength,
            'name': attrs?.name,
            'placeholder': attrs?.placeholder,
            'readonly': attrs?.readonly,
            'required': attrs?.required,
            'tabindex': attrs?.tabindex,
            'type': attrs?.type
        }"
        [(ngModel)]="inputValue"
        (ngModelChange)="onValueChange($event)"
        (blur)="handleBlur($event)"
        (click)="onInputClick($event)"
        (focus)="handleFocus($event)"
    />
</label>
<div class="control-label">
    <span aria-live="polite" aria-atomic="true" *ngIf="hint" [attr.id]="attrs?.id + 'Hint'" class="control-hint">{{hint}}</span>
</div>
