import {A11yAttrs} from '../platform/attributes.interfaces';

export enum ToggleAnimation {
	None,
	Inactive,
	Active,
}

export interface ToggleAttrs extends A11yAttrs {
	disabled?: string;
	id?: string;
}

export type Classes = string[] | string;
