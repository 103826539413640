import {isArray, isEmpty, keys, omit} from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';

import {ReducersMapping} from './redux.interfaces';

export class ReducerRegistry <T> {
	protected reducers$: BehaviorSubject<ReducersMapping<T>>;

	constructor() {
		const reducerMap = {} as unknown as T;

		this.reducers$ = new BehaviorSubject<ReducersMapping<T>>({currentReducers: reducerMap});
	}

	public getReducers(): ReducersMapping<T> {
		return {...this.reducers$.getValue()};
	}

	public getReducers$(): Observable<ReducersMapping<T>> {
		return this.reducers$;
	}

	public register(reducers: T): void {
		if (!reducers) {
			return;
		}
		const currentMapping = this.getReducers();
		const newReducers = omit<any>(reducers, keys(currentMapping.currentReducers)) as T;
		const currentReducers: T = {...currentMapping.currentReducers, ...reducers};
		const allReducers = isEmpty(newReducers) ? {currentReducers} : {currentReducers, newReducers};

		this.reducers$.next(allReducers);
	}

	public unregister(names: string | string[]): void {
		const namesToUnRegister = isArray(names) ? names : [names];
		const currentMapping = this.getReducers();
		const allReducers = {currentReducers: omit<any>(currentMapping.currentReducers, ...namesToUnRegister) as T, namesToUnRegister};

		this.reducers$.next(allReducers);
	}
}
