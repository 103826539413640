import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

import {ServerFileSaving} from '../core/platform/services/fileSaving/server-file-saving.interface';

import {getServiceByClientContext} from '../core/platform/clientContainer/client-container.utility';
import {BrowserServerFileSavingCoreService} from '../core/platform/services/fileSaving/browser-server-file-saving.core.service';
import {ServerFileSavingServiceMap} from '../core/platform/services/fileSaving/server-file-saving.constant';
import {ClientContainerContextService} from './clientContainer/client-container-context.service';
import {DataService} from './communication/data.service';
import {WindowRefService} from './window-ref.service';
import {DeviceConfigService} from './device-config.service';

@Injectable({
	providedIn: 'root',
	deps: [DataService, ClientContainerContextService, WindowRefService, DeviceConfigService, NGXLogger],
	useFactory: getServerFileSavingService
})
export class ServerFileSavingService extends BrowserServerFileSavingCoreService {}

export function getServerFileSavingService(
	dataService: DataService,
	clientContainerContextService: ClientContainerContextService,
	windowRefService: WindowRefService,
	deviceConfigService: DeviceConfigService,
	logger: NGXLogger
): ServerFileSaving {
	const dependencies = [dataService, windowRefService.nativeWindow];

	if (clientContainerContextService.isOneApp()) {
		dependencies.push(deviceConfigService, logger);
	}
	return getServiceByClientContext<ServerFileSaving>(
		clientContainerContextService.context(),
		ServerFileSavingServiceMap,
		dependencies
	);
}
