export type Classes = string | string[];

export enum LoaderShow {
	ALL = 'all',
	IMG = 'img',
	MSG = 'msg'
}

export enum LoaderSize {
	SMALL = 'small',
	LARGE = 'large'
}

export enum LoaderSpeed {
	SLOW = 'slow',
	MEDIUM = 'medium',
	FAST = 'fast'
}

export enum LoaderState {
	RUN = 'run',
	STOP = 'stop',
	FINISH = 'finish'
}

export interface LoaderOptions {
	size?: LoaderSize;
	speed?: LoaderSpeed;
	state?: LoaderState;
}

export type Messages = string | string[];

export interface PropertiesApi {
	defFirstLoaderProp: (() => string);
	defSecondLoaderProp: (() => string);
	defSpeakCompleteProp: (() => string);
	defSpeakStartedProp: (() => string);
	defThirdLoaderProp: (() => string);
	speakCompleteProp: ((label: string) => string);
	speakStartedProp: (() => string);
}
