import {Observable, Subject} from 'rxjs';

import {LogServiceInterface} from '../platform/services/logService/log.interface';

interface CommonWorker {
	postMessage: (message: any) => void;
	onmessage: (event: MessageEvent) => void;
}

export class HealthCheckCoreService {
	private healthCheckWorker: SharedWorker | Worker;
	private healthCheckSubject: Subject<boolean>;

	constructor(private log: LogServiceInterface, private windowRef: any, private path: string) {
		this.createWorker();
	}

	public destroy(): void {
		this.getWorker().postMessage({action: 'destroy'});
		this.healthCheckWorker = undefined;
	}

	public whenHealthy(retryLimit = 0): Observable<boolean> {
		if (!this.healthCheckSubject) {
			this.healthCheckSubject = new Subject<boolean>();
			this.getWorker().postMessage({action: 'check', retryLimit});
		}
		return this.healthCheckSubject.asObservable();
	}

	private createSharedWorker = (): SharedWorker => {
		const sharedWorker = new SharedWorker(`${this.path}/health-check-shared.worker.js`, {type: 'module'});

		sharedWorker.port.start();
		return sharedWorker;
	};

	private createWebWorker = (): Worker => new Worker(`${this.path}/health-check-web.worker.js`, {type: 'module'});

	private createWorker(): void {
		this.healthCheckWorker = this.useSharedWorker() ? this.createSharedWorker() : this.createWebWorker();

		this.getWorker().onmessage = event => {
			this.logMsg(event.data.message);
			if (event.data.lastMessage && this.healthCheckSubject) {
				this.healthCheckSubject.next(event.data);
				this.healthCheckSubject.complete();
				this.healthCheckSubject = undefined;
			}
		};
	}

	private getWorker = (): CommonWorker =>
		(this.useSharedWorker() ? (this.healthCheckWorker as SharedWorker).port : this.healthCheckWorker) as CommonWorker;

	private logMsg = (message: string): void => this.log.error(`${message} at ${new Date().toUTCString()}`);

	private useSharedWorker = (): boolean => 'SharedWorker' in this.windowRef;
}
