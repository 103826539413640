import {ReleaseToggleService} from '../core/platform/releaseToggle/release-toggle.service';
import {DataService} from '../services/communication/data.service';
import {SessionStorageService} from '../services/session-storage.service';

export function getReleaseToggleService(dataService, sessionStorageService, promiseWrapper: PromiseConstructor = Promise): ReleaseToggleService {
	const releaseToggleSerivice = new ReleaseToggleService(dataService, sessionStorageService, promiseWrapper);

	releaseToggleSerivice.getAll();
	return releaseToggleSerivice;
}

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const ReleaseToggleServiceProvider = {
	provide: ReleaseToggleService,
	deps: [DataService, SessionStorageService],
	useFactory: getReleaseToggleService
};
