import {Preferences} from '../preferences/preferences.interface';

import {ConverterUtilCoreService} from './converter-util-core.service';
import {NEGATIVE_PATTERNS} from './converter-util.constants';
import {FloatCoreService} from './float-core.service';

export class PercentageCoreService {
	private negativePatternMap = {
		[NEGATIVE_PATTERNS.BETWEEN_PARENTHESIS]: (percentageStr: string): string => `(${percentageStr}%)`,
		[NEGATIVE_PATTERNS.MINUS_RIGHT_NO_SPACE]: (percentageStr: string): string => `${percentageStr}%-`,
		[NEGATIVE_PATTERNS.MINUS_RIGHT_WITH_SPACE]: (percentageStr: string): string => `${percentageStr}% -`,
		[NEGATIVE_PATTERNS.MINUS_LEFT_NO_SPACE]: (percentageStr: string): string => `-${percentageStr}%`,
		[NEGATIVE_PATTERNS.MINUS_LEFT_WITH_SPACE]: (percentageStr: string): string => `- ${percentageStr}%`
	};

	constructor(
		private logService: any, 
		private preferences: Preferences, 
		private converterUtilService: ConverterUtilCoreService, 
		private floatService: FloatCoreService
	) {}

	public formatWithNegativePattern(percentageStr: string): string {
		percentageStr = this.converterUtilService.cleanNegativeSymbols(percentageStr);
		const negativePattern = this.preferences.localePolicy.numberFormat.negativePattern;

		return (this.negativePatternMap[negativePattern] || this.negativePatternDefault)(percentageStr, negativePattern);
	}

	public isValid(percentageStr: string): boolean {
		return this.floatService.isValid(percentageStr.replace(new RegExp('%', 'g'), ''));
	}

	public format(percentage: number, precision: number = this.preferences.localePolicy.numberFormat.decimalPrecision): string {
		const formattedPercentage = this.floatService.format(percentage, precision);

		return percentage < 0 ? this.formatWithNegativePattern(formattedPercentage) : `${formattedPercentage}%`;
	}

	public parse(percentageStr: string): number {
		return this.floatService.parse(percentageStr.replace(new RegExp('%', 'g'), ''));
	}

	private negativePatternDefault = (percentageStr: string, negativePattern: string): string => {
		this.logService.warn(`userPreferences.localePolicy.numberFormat.negativePattern = ${negativePattern} is not found in`, NEGATIVE_PATTERNS);
		return `-${percentageStr}`;
	};
}
