import {Component, Input, OnInit} from '@angular/core';

const menuIdForExternalTab = 4;

@Component({
	selector: 'krn-ng-menu-link',
	templateUrl: './menu-link.component.html'
})
export class MenuLinkComponent implements OnInit {
	@Input() public config: any;
	@Input() public menuOpenClass: string;

	public ngOnInit(): void {
		if (this.config.typeId && this.config.typeId === menuIdForExternalTab) {
			this.config.target = '_blank';
		}
		this.config.href = this.config.uri.startsWith('/') ? this.config.uri : `/${  this.config.uri}`;
	}

	public onClick(): void {
		if (this.config.callback) {
			this.config.callback();
		}
	}
}
