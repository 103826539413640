import {Observable, Subscription} from 'rxjs';

import {SocketData} from '../../core/communication/socket/socketBus.interface';

export const SOCKET_UNAVAILABLE_ERROR = 'sockets: SocketService is not available';

// Default Socket Service definition to prevent it being loaded when it is not needed
export abstract class PrimusSocketService {
	public isReconnect: boolean;

	public processReconnect(): void {
		throw new Error(SOCKET_UNAVAILABLE_ERROR);
	}

	public reconnect(): void {
		this.isReconnect = true;
	}

	public abstract write(name: string, data: any): Subscription;

	public abstract request(event: string, requestObj?: object, useDefaultError?: boolean): Promise<any>;

	public abstract broadcast(name: string, requestObj?: object): void;

	public abstract on(event: string): Observable<any>;

	public abstract listen(event: string): Observable<SocketData>;

	public abstract once(event: string): Observable<SocketData>;
}
