import {get, isFunction} from 'lodash';
import {fromEvent, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {ErrorCallback, SuccessCallback} from './wifi-location.interface';

import {GEO_REQUEST_ID, WIFI_INFORMATION_ID} from '../../embedded/embedded.constants';
import {determineLocationType} from './geolocation.utility';

export {getWifiFromSMA, getGeoFromSMA};

function getGeoFromSMA(win, successCallback, errorCallback): void {
	const unsubscribe$ = new Subject();

	fromEvent(win, 'message').pipe(
		takeUntil(unsubscribe$),
		filter((event: MessageEvent) => event.data && event.data.requestId === GEO_REQUEST_ID)
	).subscribe((event: MessageEvent) => {
		const coordinates = get(event, 'data.coordinates');
		const isGeoAvailable = get(event, 'data.isGeoAvailable');

		if (isGeoAvailable && coordinates) {
			successCallback({
				latitude: coordinates.latitude,
				longitude: coordinates.longitude,
				accuracy: coordinates.accuracy,
				locationType: determineLocationType(coordinates.accuracy)
			});
		} else {
			errorCallback('Geolocation cannot be retrieved');
		}

		unsubscribe$.next(undefined);
		unsubscribe$.complete();
	});
}

function getWifiFromSMA(win: Window, successCallback: SuccessCallback, errorCallback: ErrorCallback, errorMessage: string): void {
	const unsubscribe$ = new Subject();

	if (isFunction(win.addEventListener) && isFunction(win.removeEventListener)) {
		fromEvent(win, 'message').pipe(
			takeUntil(unsubscribe$),
			filter((event: MessageEvent) => event.data && event.data.requestId === WIFI_INFORMATION_ID)
		).subscribe((event: MessageEvent) => {
			const isWifiAvailable = get(event, 'data.isWifiAvailable');

			if (isWifiAvailable) {
				successCallback(get(event, 'data'));
			} else {
				errorCallback(errorMessage);
			}

			unsubscribe$.next(undefined);
			unsubscribe$.complete();
		});
	}
}
