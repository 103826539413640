import {isNil, isNumber, toNumber} from 'lodash';
import {MomentInput} from 'moment';

import {DateInput, DateRange} from './date-service.interface';

import {ConverterUtilCoreService} from './converter-util-core.service';
import {DATE_TIME_PATTERN, DAY, GRANULARITY, UTILS, YEAR} from './converter-util.constants';

const INVALID_DATE = 'Invalid date';

export class DateServiceUtility {
	constructor(private converterUtilService: ConverterUtilCoreService, private moment) {}

	public isAfter(firstDate: DateInput, secondDate: DateInput, granularity: GRANULARITY = GRANULARITY.DAY): boolean {
		return this.moment(firstDate).isAfter(secondDate, granularity);
	}

	public isBefore(firstDate: DateInput, secondDate: DateInput, granularity: GRANULARITY = GRANULARITY.DAY): boolean {
		return this.moment(firstDate).isBefore(secondDate, granularity);
	}

	public isInRange(
		dateToCheck: DateInput,
		minDate: DateInput,
		maxDate: DateInput,
		granularity: GRANULARITY = GRANULARITY.DAY,
		incMinMaxDates = true
	): boolean {
		return (
			(isNil(minDate) || this[incMinMaxDates ? 'isSameOrAfter' : 'isAfter'](dateToCheck, minDate, granularity)) &&
			(isNil(maxDate) || this[incMinMaxDates ? 'isSameOrBefore' : 'isBefore'](dateToCheck, maxDate, granularity))
		);
	}

	public isSame(firstDate: DateInput, secondDate: DateInput, granularity: GRANULARITY = GRANULARITY.DAY): boolean {
		return this.moment(firstDate).isSame(secondDate, granularity);
	}

	public isSameOrAfter(firstDate: DateInput, secondDate: DateInput, granularity: GRANULARITY = GRANULARITY.DAY): boolean {
		return this.moment(firstDate).isSameOrAfter(secondDate, granularity);
	}

	public isSameOrBefore(firstDate: DateInput, secondDate: DateInput, granularity: GRANULARITY = GRANULARITY.DAY): boolean {
		return this.moment(firstDate).isSameOrBefore(secondDate, granularity);
	}

	public isOverlap(range1: DateRange, range2: DateRange, granularity: GRANULARITY = GRANULARITY.DAY, incMinMaxDates = true): boolean {
		return (
			this.isInRange(range1.startDate, range2.startDate, range2.endDate, granularity, incMinMaxDates) ||
			this.isInRange(range1.endDate, range2.startDate, range2.endDate, granularity, incMinMaxDates) ||
			this.isInRange(range2.startDate, range1.startDate, range1.endDate, granularity, incMinMaxDates) ||
			this.isInRange(range2.endDate, range1.startDate, range1.endDate, granularity, incMinMaxDates)
		);
	}

	public isValid(dateTimeStr, dateTimePatternId): any {
		return this.moment(dateTimeStr, this.converterUtilService.retrieveDateTimePattern(dateTimePatternId), true).isValid();
	}

	public formatWithGlobalLocale(dateTimeObj: MomentInput, dateTimePatternId: string): string {
		const dateFormat = this.converterUtilService.retrieveDateTimePattern(dateTimePatternId);
		let dateTimeStr = this.moment(dateTimeObj).format(dateFormat);

		if (dateTimeStr === INVALID_DATE) {
			dateTimeStr = this.moment(dateTimeObj, dateFormat).format(dateFormat);
		}

		return this.removeRedundantSeparator(dateTimeStr);
	}

	public formatRange(startDate: MomentInput, endDate: MomentInput, dateTimePatternId: string): string {
		const rangeStartDate = this.format(startDate, dateTimePatternId);
		const rangeEndDate = this.format(endDate, dateTimePatternId);

		if (rangeStartDate === rangeEndDate) {
			return rangeStartDate;
		}
		return `${rangeStartDate} - ${rangeEndDate}`;
	}

	public format(dateTimeObj, dateTimePatternId): string {
		this.converterUtilService.setGlobalLocale();
		return this.formatWithGlobalLocale(dateTimeObj, dateTimePatternId);
	}

	public formatMMRAssignmentDate(dateTimeObj, dateTimePatternId): string {
		this.converterUtilService.setGlobalLocale();
		return this.formatWithGlobalLocaleMMRAssignment(dateTimeObj, dateTimePatternId);
	}

	public formatWithGlobalLocaleMMRAssignment(dateTimeObj: MomentInput, dateTimePatternId: string): string {
		const dateFormat = this.converterUtilService.retrieveDateTimePattern(dateTimePatternId);
		const dateTimeStr = this.moment(dateTimeObj, dateFormat).format(dateFormat);

		return this.removeRedundantSeparator(dateTimeStr);
	}

	public normalizeDate(value: string): Date {
		if (this.moment(value, this.moment.ISO_8601).isValid()) {
			return this.moment(value, this.moment.ISO_8601).toDate();
		}
	}

	public getISOWeekday(dateTimeObj: Date): number {
		return this.moment(dateTimeObj).isoWeekday();
	}

	public getMonth(dateTimeObj: Date): number {
		return this.moment(dateTimeObj).month();
	}

	public getDayOfWeek(dateTimeObj: DateInput): number {
		return this.moment(dateTimeObj).day();
	}

	public add(date: DateInput, amount: number, amountUnit: string): DateInput {
		return this.moment(date).add(amount, amountUnit).toDate();
	}

	public subtract(date: DateInput, amount: number, amountUnit: string): DateInput {
		return this.moment(date).subtract(amount, amountUnit).toDate();
	}

	public getDifference(firstDate: DateInput, secondDate: DateInput, granularity: GRANULARITY = GRANULARITY.DAY): number {
		return this.moment(firstDate).diff(secondDate, granularity);
	}

	public parse(dateTimeStr, dateTimePatternId): any {
		this.converterUtilService.setGlobalLocale();
		return this.moment(dateTimeStr, this.converterUtilService.retrieveDateTimePattern(dateTimePatternId)).toDate();
	}

	public removeRedundantSeparator(dateTimeStr: string): string {
		return dateTimeStr.replace(/\.\./g, '.'); // FLC-52865 Remove redundant separator period when the translation already contains a period
	}

	public momentLizeDate(dateTimeObj: Date): Date {
		return this.moment(dateTimeObj);
	}

	public momentLizeDateWithFormat(value: string, format: string): Date {
		return this.moment(value, format);
	}

	public isValidMomentDate(dateTimeObj: Date, format: string): Date {
		return this.moment(dateTimeObj, format).isValid();
	}

	public invalidAt(dateTimeObj: Date, format: string): Date {
		return this.moment(dateTimeObj, format).invalidAt();
	}

	public getDateWithTimeZone(utcDate: string | Date, offsetMinutes: number): any {
		if (this.moment(utcDate).isValid()) {
			return this.moment.utc(utcDate).utcOffset(offsetMinutes);
		}
	}

	public getAllMonthsBetweenTwoDates(startDate: string, endDate: string): string[] {
		const start = this.moment(startDate);
		const end = this.moment(endDate);
		const dates = [start.format('YYYY-MM')];
		const month = this.moment(start);

		while (month < end) {
			month.add(1, 'month');
			dates.push(month.format('YYYY-MM'));
		}
		return dates;
	}

	public getDateRangeByMonth(monthDate: string): string[] {
		const monthMoment = this.moment(monthDate);
		const startDate = `${monthMoment.format('YYYY-MM-')}01`;
		const days = monthMoment.daysInMonth();
		const endDate = monthMoment.format('YYYY-MM-') + days;

		return [startDate, endDate];
	}

	public getWeekNumber(date: DateInput, customFirstDayOfWeek?: number): number {
		const dateMoment = this.moment(date);
		const dateYear = dateMoment.year();
		const weekOffset = this.getFirstWeekOffset(dateYear, customFirstDayOfWeek);
		const week = Math.floor((dateMoment.dayOfYear() - weekOffset - 1) / UTILS.WEEK_DURATION_IN_DAYS) + 1;
		let resWeek;
		let resYear;

		if (week < 1) {
			resYear = dateYear - 1;
			resWeek = week + this.getWeeksNumberInYear(resYear, customFirstDayOfWeek);
		} else if (week > this.getWeeksNumberInYear(dateYear, customFirstDayOfWeek)) {
			resWeek = week - this.getWeeksNumberInYear(dateYear, customFirstDayOfWeek);
		} else {
			resWeek = week;
		}

		return resWeek;
	}

	public formatDateToISO(dateString: string): string {
		return this.moment(dateString).utc(true).toISOString();
	}

	public formatDate(date): string {
		return this.format(date, DATE_TIME_PATTERN.ISOCalendarDateId);
	}

	public getDefaultDate(date, offset): any {
		const offsetNumber = offset ? toNumber(offset) : -1;

		return this.moment(date).add(offsetNumber, 'days').toDate();
	}

	public getIsValid(paramValue): boolean {
		return this.moment(paramValue).isValid();
	}

	public formatWithPatternStr(dateTimeObj: MomentInput, dateTimePattern: string): string {
		let dateTimeStr = this.moment(dateTimeObj).format(dateTimePattern);

		if (dateTimeStr === INVALID_DATE) {
			dateTimeStr = this.moment(dateTimeObj, dateTimePattern).format(dateTimePattern);
		}

		return this.removeRedundantSeparator(dateTimeStr);
	}

	public getDateBeforeWithoutTimezone(dateString: string, daysBefore?: number): Date {
		const DAYS_BEFORE = 1;
		const date = new Date(`${dateString}T00:00:00.000`);

		date.setDate(date.getDate() - (daysBefore || DAYS_BEFORE));
		return date;
	}

	private getFirstWeekOffset(year: number, firstDayOfWeek?: number): number {
		const startOfYear = this.moment(`${year}-01-01`);
		const weekStartDay = isNumber(firstDayOfWeek) ? firstDayOfWeek : this.converterUtilService.getWeekStartDay();

		return this.getOffsetToNextThursday(startOfYear.day()) - this.getOffsetToNextThursday(weekStartDay);
	}

	private getWeeksNumberInYear(year: number, firstDayOfWeek?: number): number {
		const daysInYear = this.moment([year]).isLeapYear() ? YEAR.LEAP : YEAR.COMMON;
		const weekOffset = this.getFirstWeekOffset(year, firstDayOfWeek);
		const weekOffsetNext = this.getFirstWeekOffset(year + 1, firstDayOfWeek);

		return Math.floor((daysInYear - weekOffset + weekOffsetNext) / UTILS.WEEK_DURATION_IN_DAYS);
	}

	private getOffsetToNextThursday(dayNumber: number): number {
		return (DAY.THURSDAY - dayNumber + UTILS.WEEK_DURATION_IN_DAYS) % UTILS.WEEK_DURATION_IN_DAYS;
	}
}
