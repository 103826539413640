import {Component, ComponentFactoryResolver, ComponentRef, Input, OnChanges, OnInit, SimpleChanges, Type, ViewChild} from '@angular/core';
import {each} from 'lodash';

import {DynamicItemHostDirective} from './dynamic-item-host.directive';

export interface ItemInput {
	name: string;
	value: any;
	// [key: string]: number;
}

@Component({
	selector: 'krn-ng-dynamic-item-manager',
	template: '<ng-template krn-ng-dynamic-item-host></ng-template>'
})
export class DynamicItemManagerComponent implements OnChanges, OnInit {
	@Input() public item: Type<Component>;
	@Input() public itemLoad = true;
	@Input() public itemInputs: ItemInput[];

	@ViewChild(DynamicItemHostDirective, {static: true})
	public dynamicHost: DynamicItemHostDirective;

	public componentRef: ComponentRef<any>;

	constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

	public ngOnInit(): void {
		if (this.itemLoad) {
			this.loadComponent();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.itemLoad && changes.itemLoad.currentValue && !changes.itemLoad.previousValue) {
			this.loadComponent();
		}
		if (this.componentRef && changes.itemInputs) {
			this.loadItemInputs();
		}
	}

	private loadComponent(): void {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.item);

		const viewContainerRef = this.dynamicHost.viewContainerRef;

		viewContainerRef.clear();

		this.componentRef = viewContainerRef.createComponent(componentFactory);
		this.loadItemInputs();
	}

	private loadItemInputs(): void {
		each(this.itemInputs, itemInput => {
			this.componentRef.instance[itemInput.name] = itemInput.value;
		});
	}
}
