import {includes} from 'lodash';

import {PropertyState, PropertyValues} from '../../core/property/property.interface';

import {PropertyStateCoreService} from '../../core/property/propertyState.core.service';
import {PropertyActionService} from '../../services/property-action.service';

export class PropertyStateService extends PropertyStateCoreService {
	constructor(private propertyActionService: PropertyActionService) {
		super(null);
	}

	public getPropertiesState = (): PropertyState => {
		let propertyState;
		const subscription = this.propertyActionService.select(['property'])
			.subscribe(state => propertyState = state);

		subscription.unsubscribe();
		return propertyState;
	};

	public dispatchPropertyAction = (file: string, properties: PropertyValues): void => {
		this.propertyActionService.dispatch(this.propertyActionService.actions.loadFile(file, properties));
	};

	public getProperty = (property: string): string | undefined => this.getPropertiesState().properties[property];

	public isFileLoaded = (file: string): boolean => includes(this.getPropertiesState().loadedFiles, file);
}
