import {WifiLocation, WifiPluginDefinition} from '../services/wifi-location.interface';
import {CordovaPluginWindow} from './cordova-plugin-window.interface';

import {PropertyFactory} from '../../property/property.factory';
import {PropertyFilterConfig} from '../../property/property.filter';
import {BaseWifiLocationService} from '../services/base-wifi-location.service';

export class MobileWifiLocationService extends BaseWifiLocationService {

	constructor(protected window: CordovaPluginWindow, protected supportsWifiLocation: boolean, protected propertyFactory: PropertyFactory, protected propertyFilter: PropertyFilterConfig) {
		super(window, supportsWifiLocation, propertyFactory, propertyFilter);
	}

	public requestWifiLocation = (): void => {
		this.window.kronosWifiLocation.getWiFiLocation(
			this.wifiSuccess,
			this.wifiError
		);
	};
	
	private wifiSuccess = (wifiData: WifiPluginDefinition): void => {
		const wifi: WifiLocation = JSON.parse(wifiData.value);

		this.context.successCallback(wifi);
	};

	private wifiError = (error?: string | Error): void => {
		this.context.errorCallback(error);
	};
}
