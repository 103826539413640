import {Injectable} from '@angular/core';

import {ApiService} from '../core/communication/api-service/api-service.interfaces';

import {PermissionsCoreService} from '../core/authorization/permissions-core.service';
import {DataService} from './communication/data.service';

@Injectable({
	providedIn: 'root',
	deps: [DataService]
})
export class PermissionsService extends PermissionsCoreService {
	constructor(apiService: DataService) {
		super(apiService as ApiService);
	}
}
