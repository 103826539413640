import {cloneDeep, find, get, head, includes, isEmpty, isEqual, isNil, map, split} from 'lodash';

import {ApiService, Transport} from '../../communication/api-service/api-service.interfaces';
import {
	DocumentOpenAnalyticsRequest,
	HelpActionHandlerResponse,
	HelpContext,
	HelpResultsHit,
	HelpRowData,
	HelpSearchAggregation,
	HelpSearchRequest,
	HelpSearchRequestAggregation,
	HelpSearchRequestDispatch,
	HelpSearchResults,
	HelpSearchResultsTransformed,
	HelpService,
	HelpSortParams,
	VendorHelpContext
} from './contextual-help.interfaces';

import {BANNER_HEIGHT, NAVBAR_HEIGHT, REM_NUM, SPACE_NUM} from '../../framework/directives/persistent-banner/persistent-banner.constants';
import {UserInfoService} from '../../framework/services/user-info.service';
import {CommonConstants} from '../../platform/constants/CommonConstants';
import {keyCodes} from '../../platform/constants/keys.constant';
import {SessionStorage} from '../../platform/caching/sessionStorage.service';
import {actionHandler} from './contextual-help-search.actions';
import {AggregationsType, ContextualHelpActions} from './contextual-help-search.constants';
import {contextualHelpReducerForDispatch} from './contextual-help.reducer';

const CREATED_DATE_KEY = 'Created Date';
const DEFAULT_VENDOR_NAME = 'KRONOS';
const KEY = 'contextualHelp-BaseUrl';
const NO_MATCH = -1;
const NO_RESULTS = 0;

export const VENDOR_HELP_CONTEXT_DEFAULT: Readonly<VendorHelpContext> = {
	vendorName: DEFAULT_VENDOR_NAME,
	isHelpCustomized: false
};

export const HELP_CONTEXT_DEFAULT: Readonly<HelpContext> = {
	lang: CommonConstants.HTML_INFRASTRUCTURE_PROPERTY_FILE_DEFAULT_LOCALE,
	searchPage: 'Default.htm',
	cshid: null,
	baseUrl: '',
	landingPageUrl: '',
	contextualPageUrl: '',
	vendorHelpContext: VENDOR_HELP_CONTEXT_DEFAULT
};

const getHelpVendorRoute = (sessionStorage: SessionStorage, vendorName: string): string => sessionStorage[KEY].replace('help', `help/${vendorName}`);

const getBaseUrl = (
	sessionStorage: SessionStorage,
	apiService: ApiService,
	userInfoService: UserInfoService,
	helpContext: HelpContext
): Promise<HelpContext> => {
	const URL_INDEX = 1;
	const services = [userInfoService.getVendorData()];

	if (!(sessionStorage && sessionStorage[KEY])) {
		services.push(apiService.get(`common/sysconfig/${KEY}`, undefined, undefined, Transport.REST, false));
	}

	return Promise.all(services).then(res => {
		helpContext.vendorHelpContext = head(res) || helpContext.vendorHelpContext;
		const vendorName = helpContext.vendorHelpContext.vendorName;

		if (sessionStorage) {
			const isNonUKGVendor = get(helpContext, 'vendorHelpContext.isHelpCustomized') && !isEqual(vendorName, DEFAULT_VENDOR_NAME);

			if (sessionStorage[KEY]) {
				return isNonUKGVendor && isEqual(sessionStorage[KEY].indexOf(`/${vendorName}`), NO_MATCH)
					? getHelpVendorRoute(sessionStorage, vendorName)
					: Promise.resolve(sessionStorage[KEY]);
			}

			sessionStorage[KEY] = res[URL_INDEX];
			return isNonUKGVendor ? getHelpVendorRoute(sessionStorage, vendorName) : sessionStorage[KEY];
		}
		return res[1];
	});
};

const getContextualPageUrl = (helpContext: HelpContext): string => encodeURI(`${helpContext.landingPageUrl}#cshid=${helpContext.cshid}`);

const getLandingPageUrl = (helpContext: HelpContext): string => encodeURI([helpContext.baseUrl, helpContext.lang, helpContext.searchPage].join('/'));

export const getHelpContext = (
	userInfoService: UserInfoService,
	sessionStorageService: SessionStorage,
	apiService: ApiService,
	context: any
): Promise<HelpContext> => {
	let helpContext: HelpContext = {...HELP_CONTEXT_DEFAULT};

	return getBaseUrl(sessionStorageService, apiService, userInfoService, helpContext).then(baseUrl =>
		userInfoService.getLoggedOnUserData().then(userData => {
			helpContext = {...helpContext, ...context, ...{baseUrl}};
			helpContext.lang = get(userData, 'locale.name', helpContext.lang);
			helpContext.landingPageUrl = getLandingPageUrl(helpContext);
			helpContext.contextualPageUrl = getContextualPageUrl(helpContext);
			return helpContext;
		})
	);
};

export const searchCommunityHelp = (searchRequest, apiService: ApiService): Promise<any> =>
	apiService.create('/community/search', {data: searchRequest}, undefined, Transport.REST, false).then(response => response);

export const analyticsForDocumentOpen = (searchRequest: DocumentOpenAnalyticsRequest, apiService: ApiService): Promise<any> =>
	apiService.create('/community/analyticsForDocumentOpen', {data: searchRequest}, undefined, Transport.REST, false).then(response => response);

export const getLocale = (userData): string => {
	let locale = get(userData, 'locale.name', '');

	if (includes(locale, '_')) {
		locale = head(split(locale, '_'));
	}
	return locale;
};

export const showLocaleMsg = async(userInfoService: UserInfoService): Promise<boolean> => {
	const userInfo = await userInfoService.getLoggedOnUserData();

	return !isEqual(getLocale(userInfo), 'en');
};

export const checkSearchFAP = async(userInfoService: UserInfoService): Promise<boolean> => {
	const userFeatures = await userInfoService.getUserFeaturesData();

	return userFeatures.isCommunitySearchEnabled;
};

export const createRowData = (hits: HelpResultsHit[]): HelpRowData[] =>
	map(
		hits,
		(hit: HelpResultsHit): HelpRowData => ({
			title: head(get(hit, 'highlight.TitleToDisplay', [''])),
			summary: head(get(hit, 'highlight.SummaryToDisplay', [''])),
			originalTitle: get(hit, 'originalTitle', ''),
			createdDate: find(hit.metadata, metadataEntry => isEqual(metadataEntry.key, CREATED_DATE_KEY)),
			href: hit.href,
			public: get(hit, 'visibleInPublicKnowledgeBase', true),
			objName: get(hit, 'objName', ''),
			documentUriHash: get(hit, 'documentUriHash', '')
		})
	);

export const transformResponse = (response: HelpSearchResultsTransformed): HelpSearchResults => {
	const hits: HelpRowData[] = createRowData(get(response, 'result.hits')) || [];

	return {
		aggregationsArray: response.aggregationsArray,
		results: {
			total: get(response, 'result.total', NO_RESULTS),
			hits
		},
		isCoveoEngine: get(response, 'isCoveoEngine', false),
		searchQueryUid: get(response, 'searchQueryUid', '')
	};
};

export const doCommunitySearch = async(
	store: any,
	apiDataService: ApiService,
	searchRequest: HelpSearchRequest,
	searchRequestDispatch: HelpSearchRequestDispatch,
	action: ContextualHelpActions,
	postAction: ContextualHelpActions,
	isRefined = false,
	isClear = false
): Promise<HelpSearchResults> => {
	store.dispatch(actionHandler(action, searchRequestDispatch));
	searchRequest.actionType = includes([ContextualHelpActions.Loading, ContextualHelpActions.LoadingMore], action) ? postAction : action;
	const response = await searchCommunityHelp(cloneDeep(searchRequest), apiDataService);
	const searchResult = transformResponse(response);
	let actionHandlerResponse: HelpActionHandlerResponse = {
		searchResult,
		searchRequest: cloneDeep(searchRequest)
	};

	actionHandlerResponse = contextualHelpReducerForDispatch(actionHandlerResponse, postAction);
	actionHandlerResponse.refined = isRefined;
	actionHandlerResponse.refineOpened = isClear;

	store.dispatch(actionHandler(postAction, actionHandlerResponse));

	return searchResult;
};

export const sortResultsBy = (sortKey: string, sortby: string, resultCount: number, service: HelpService): void => {
	if (!isEqual(sortKey, sortby) && resultCount !== NO_RESULTS) {
		service.sort({sortby: sortKey, from: NO_RESULTS});
	}
};

export const doKeyPressAction = (keyCode: number): boolean => keyCode === keyCodes.RETURN || keyCode === keyCodes.SPACE;

export const subscribeSearchResultSortChange = (sortParams: HelpSortParams, state: any): void => {
	sortParams.loading = state.loading;

	if (sortParams.loading || sortParams.contentBeingRefined) {
		sortParams.resultCount = NO_RESULTS;
	} else {
		sortParams.searchResult = state.searchResult;
		sortParams.resultCount = get(sortParams.searchResult, 'results.total', NO_RESULTS);
		sortParams.refined = state.refined;
		sortParams.sortby = state.searchRequest.sortby;
	}
};

export const persistentBannerHeight = (isMainContainer: boolean): number => {
	const messageElements = document.getElementsByTagName('krn-message-inpage');

	return isEmpty(messageElements) ? 0 : ((isMainContainer ? NAVBAR_HEIGHT : 0) + messageElements.length * BANNER_HEIGHT - SPACE_NUM) / REM_NUM;
};

export const checkIfUserHasUpdatedFilters = (
	aggregationsArray: HelpSearchRequestAggregation[],
	initialFilters: HelpSearchRequestAggregation[]
): boolean => !isEqual(aggregationsArray, initialFilters);

export const getExistingFilters = (state: any, type: string): any => find(get(state, 'searchRequest.aggregations'), item => isEqual(item.type, type));

export const markExistingFiltersChecked = (aggregations: HelpSearchAggregation[], existingFilters: any, type: string): HelpSearchAggregation =>
	find(aggregations, (item: any) => {
		if (isEqual(item.key, type)) {
			item.values = map(item.values, value => ({...value, checked: includes(existingFilters, value.Contentname)}));
			if (isEmpty(item.values)) {
				item.values = [{Contentname: existingFilters, checked: true}];
			}
			return true;
		}
	});

export const setAggregationsArray = (filterBySource: string[], filterByDate: string): HelpSearchRequestAggregation[] => {
	const aggregationsArray = [];

	if (!isEmpty(filterBySource)) {
		aggregationsArray.push({type: AggregationsType.Sources, filter: filterBySource});
	}
	if (!isEmpty(filterByDate)) {
		aggregationsArray.push({type: AggregationsType.CreatedDate, filter: [filterByDate]});
	}
	return aggregationsArray;
};

export const preserveInitialState = (
	initialFilters: HelpSearchRequestAggregation[],
	filterBySource: string[],
	filterByDate: string
): HelpSearchRequestAggregation[] => {
	const newInitialFilters = [];

	if (isNil(initialFilters)) {
		if (!isEmpty(filterBySource)) {
			newInitialFilters.push({type: AggregationsType.Sources, filter: filterBySource});
		}
		if (!isEmpty(filterByDate)) {
			newInitialFilters.push({type: AggregationsType.CreatedDate, filter: [filterByDate]});
		}
	}
	return newInitialFilters;
};

export const isCoveoEngine = (state: unknown): boolean => get(state, 'searchResult.isCoveoEngine');
