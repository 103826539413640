export interface KrnUtilitiesMobileWindow extends Window {
	krnUtilities: any;
}

const isWfdMobileApp = (winRef): boolean => winRef.navigator.userAgent.match(/(wfdMobileApp)/i) !== null;
const SECURE_APP_HEADER = 'SECURE_APP_HEADER';
const MOBILE_INIT_ENDPOINT = '/mobileapp/context';

const callBackIfExists = (callback?: () => void): void => {
	if (callback) {
		callback();
	}
};

export const mobileContextInit = (win: KrnUtilitiesMobileWindow, callback): void => {
	if (isWfdMobileApp(win)) {
		// eslint-disable-next-line angular/document-service
		document.addEventListener(
			'deviceready',
			() => {
				win.krnUtilities
					.getSecureAppHeader()
					.then(secureHeader => {
						if (secureHeader.status) {
							win.sessionStorage.setItem(SECURE_APP_HEADER, secureHeader.value);
						}
						callBackIfExists(callback);
					})
					.catch(() => {
						callBackIfExists(callback);
					});
			},
			false
		);
	} else {
		callBackIfExists(callback);
	}
};

export const runMobileContext = (win: KrnUtilitiesMobileWindow): Promise<any> =>
	new Promise(resolve => {
		const secureIdentifier = win.sessionStorage.getItem(SECURE_APP_HEADER);

		if (isWfdMobileApp(win) && secureIdentifier) {
			const fullURL = win.location.origin + MOBILE_INIT_ENDPOINT;
			const request = new XMLHttpRequest();
			const READY_STATE = 4;

			request.onreadystatechange = () => {
				if (request.readyState === READY_STATE) {
					resolve(true);
				}
			};

			request.open('GET', fullURL, true);
			request.setRequestHeader('mobileapp', secureIdentifier);
			request.send();
		} else {
			resolve(false);
		}
	});
