import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IgniteModule} from '@ignite/angular';

import {FocusOnShowModule} from '../../../utils/focus-on-show/focus-on-show.module';
import {ModalKeyboardNavigationModule} from '../../../utils/modal-keyboard-navigation/modal-keyboard-navigation.module';
import {ReturnFocusElementModule} from '../../../utils/return-focus-element/return-focus-element.module';
import {PropertyModule} from '../../../providers/property/property.module';
import {SlideoutContentModule} from './slide-out-content/slideout-content.module';
import {SlideOutComponent} from './slide-out.component';

@NgModule({
	declarations: [SlideOutComponent],
	exports: [SlideOutComponent],
	imports: [
		CommonModule,
		ModalKeyboardNavigationModule,
		ReturnFocusElementModule,
		SlideoutContentModule,
		FocusOnShowModule,
		IgniteModule,
		PropertyModule
	]
})
export class SlideOutModule {}
