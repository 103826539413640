import {Injectable} from '@angular/core';

import {DataStorageService} from '../core/platform/personalization/local-storage.interfaces';

import {LocalStorageCoreService} from '../core/platform/personalization/local-storage.service';
import {DimLocalStorageService} from '../core/platform/services/localStorage/dim-local-storage.service';
import {OneAppDataService} from '../core/platform/services/localStorage/one-app-data.service';
import {ClientContainerContextService} from './clientContainer/client-container-context.service';
import {WindowRefService} from './window-ref.service';

export const ngStorage = 'ngStorage-';

@Injectable({
	providedIn: 'root',
	deps: [ClientContainerContextService, WindowRefService],
	useFactory: getLocalStorageService
})
export class LocalStorageService extends LocalStorageCoreService {}

export function getLocalStorageService(
	clientContainerContextService: ClientContainerContextService,
	windowRefService: WindowRefService
): DataStorageService {
	return clientContainerContextService.isOneApp()
		? new OneAppDataService(windowRefService.nativeWindow)
		: new DimLocalStorageService(windowRefService.nativeWindow);
}
