import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LogoutComponent} from '../authentication/logout/logout.component';
import {ContextualHelpComponent} from './header/action-item/contextual-help/contextual-help.component';

const navBarRoutes: Routes = [
	{
		path: 'contextualHelp',
		outlet: 'app-slideout',
		component: ContextualHelpComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(navBarRoutes)],
	exports: [RouterModule]
})
export class NavBarRoutingModule {}
