import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {get, head, isEmpty, isEqual, isUndefined, last, pick, uniqueId} from 'lodash';
import {PopoverDirective} from 'ngx-bootstrap/popover';

import {ButtonAttrs} from '../../core/button/button.interfaces';
import {PopoverButtonAttrs, PopoverCloseType, popoverRenderType} from '../../core/popover-button/popover-button.interfaces';

import {keyCodes} from '../../core/platform/constants/keys.constant';
import {DEFAULT_ARIA_PROP} from '../../core/popover-button/popover-button.constants';
import {
	getAriaLabel,
	getButtonAttrs,
	initAlignment,
	initButtonAttrs,
	initContainerClass,
	initIcon,
	loadPopoverButtonStrings
} from '../../core/popover-button/popover-button.utility';
import {PropertyFactory} from '../../core/property/property.factory';
import {isRealMobileDevice} from '../../core/platform/services/DeviceConfig.utility';
import {FOCUSABLE_ELEMENTS} from '../../core/platform/accessibility/utils.constants';
import {PropertyFilterPipe} from '../../providers/property/property.filter.pipe';
import {WindowRefService} from '../../services/window-ref.service';
import {DeviceConfigService} from '../../services/device-config.service';

const TURN_OFF_DELAY = 5000;
const DELAY = 500;
const BODY_CONTAINER = 'body';
const EMPTY = '';
const SMALL = 'small';

@Component({
	selector: 'krn-ng-popover-button',
	templateUrl: './popover-button.component.html',
	styleUrls: ['./popover-button.component.less']
})
export class PopoverButtonComponent implements OnInit, OnChanges {
	@Input() public alignment: string;
	@Input() public adaptivePosition: boolean;
	@Input() public attrs: PopoverButtonAttrs;
	@Input() public buttonText: string;
	@Input() public buttonTextAlignment: string;
	@Input() public interactive: boolean;
	@Input() public classes: string[] | string;
	@Input() public closeAriaLabel: string;
	@Input() public container: string;
	@Input() public containerClass: string;
	@Input() public focusOnCloseContainer: boolean;
	@Input() public label: string;
	@Input() public icon: string;
	@Input() public openAriaLabel: string;
	@Input() public modalRole: string;
	@Input() public renderType: string;

	@Output() public onHidden = new EventEmitter();
	@Output() public onShown = new EventEmitter();

	@ViewChild('popover', {read: PopoverDirective})
	public popover: PopoverDirective;

	@ViewChild('popoverBtn')
	public popoverBtn: ElementRef;

	public buttonAttrs: ButtonAttrs;
	public enableAnnounceExpanded = true;
	public fallbackId: string = uniqueId('popover-button-');
	public closeButtonId: string = uniqueId('popover-close-button-');
	public defaultAriaLabel: string;
	public isRealMobileDevice: boolean;
	public hasPopup: boolean;
	public isSmall: boolean;

	private closedBy: PopoverCloseType;
	private isPopoverOpened: boolean;
	private isPopoverScrollable: boolean;
	private openingPopover: boolean;
	private pbId: string;
	private window: Window;

	constructor(
		private windowRefService: WindowRefService,
		private propertyFactory: PropertyFactory,
		private propertyFilterPipe: PropertyFilterPipe,
		private deviceConfigService: DeviceConfigService
	) {
		this.window = this.windowRefService.nativeWindow;
		this.adaptivePosition = true;
		this.closedBy = PopoverCloseType.UNKNOWN;
		this.openingPopover = false;
		this.containerClass = EMPTY;
		this.isRealMobileDevice = isRealMobileDevice(this.window);
		const isSafari = this.deviceConfigService.isSafari(this.windowRefService.nativeWindow.navigator);
		const isChrome = this.deviceConfigService.isChrome(this.windowRefService.nativeWindow.navigator);

		this.isSmall = this.deviceConfigService.getBreakpoint() === SMALL;
		this.hasPopup = this.isRealMobileDevice || (!this.isRealMobileDevice && !isSafari && !isChrome);
	}

	public delayClosePopover(): void {
		this.windowRefService.nativeWindow.setTimeout(() => this.hidePopover());
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (!isEqual(this.renderType, popoverRenderType.ALWAYS_FIT_CONTAINER) || this.isRealMobileDevice) {
			this.interactive = true;
		}
		if (!isEmpty(pick(changes, ['attrs', 'closeAriaLabel', 'openAriaLabel']))) {
			this.pbId = get(this.attrs, 'id') || this.fallbackId;
			this.buttonAttrs = getButtonAttrs(this.pbId, this.attrs, this.ariaLabel(), this.isPopoverOpened, this.interactive, this.hasPopup);
		}
	}

	public async ngOnInit(): Promise<void> {
		this.pbId = get(this.attrs, 'id') || this.fallbackId;
		this.alignment = initAlignment(this.alignment);
		this.buttonAttrs = initButtonAttrs(this.pbId, this.attrs, this.interactive, this.hasPopup);
		this.containerClass = initContainerClass(this.containerClass, this.renderType);
		this.icon = initIcon(this.icon);
		this.isPopoverOpened = false;
		this.isPopoverScrollable = false;
		this.container = this.container || BODY_CONTAINER;
		this.defaultAriaLabel = await this.getDefaultAriaLabel();
		this.buttonAttrs = getButtonAttrs(this.pbId, this.attrs, this.ariaLabel(), this.isPopoverOpened, this.interactive, this.hasPopup);
	}

	public onButtonBlurred(): void {
		if (!this.interactive && this.isPopoverOpened && !this.isPopoverScrollable) {
			setTimeout(() => this.hidePopover(), DELAY);
		}
	}

	public onPopoverHidden(): void {
		this.focusToButton();
		this.onHidden.emit();
		this.isPopoverOpened = false;
		this.isPopoverScrollable = false;
		this.buttonAttrs = getButtonAttrs(this.pbId, this.attrs, this.ariaLabel(), this.isPopoverOpened, this.interactive, this.hasPopup);
		this.enableAnnounceExpanded = true;
	}

	public onPopoverShown(): void {
		this.onShown.emit();
		this.isPopoverOpened = true;
		this.buttonAttrs = getButtonAttrs(this.pbId, this.attrs, this.ariaLabel(), this.isPopoverOpened, this.interactive, this.hasPopup);
		const popoverHost: HTMLElement = document.querySelector('popover-container');

		popoverHost.removeAttribute('role');
		setTimeout(() => (this.enableAnnounceExpanded = false), TURN_OFF_DELAY);
	}

	public ariaLabel = (): string => getAriaLabel(this.isPopoverOpened, this.openAriaLabel, this.closeAriaLabel, this.defaultAriaLabel);

	public onKeyDown(event: KeyboardEvent): void {
		if (event.keyCode === keyCodes.ESC && this.isPopoverOpened) {
			event.stopPropagation();
			this.onButtonBlurred();
		} else if (event.keyCode === keyCodes.ENTER && !this.isPopoverOpened) {
			this.openingPopover = true;
		}
	}

	public onMenuBlur(): void {
		if (this.isPopoverOpened) {
			this.onMenuClosed();
		}
	}

	public onMenuEntered(): void {
		if (this.isPopoverOpened) {
			this.openingPopover = false;
		}
	}

	public onMenuClosed(closedByPopover = false): void {
		this.closedBy = closedByPopover ? PopoverCloseType.POPOVER : PopoverCloseType.CONTAINER;
		if (!this.openingPopover) {
			this.hidePopover();
		}
		this.openingPopover = false;
	}

	public onMenuMouseUp(): void {
		this.closedBy = PopoverCloseType.CONTAINER;
	}

	public hidePopover(): void {
		this.popover.hide();
	}

	public showPopover(): void {
		this.popover.show();
	}

	public returnFocus(position: string): void {
		const allFocusable = document.querySelector('popover-container').querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS);

		if (position === 'top') {
			head(allFocusable).focus();
		} else {
			last(allFocusable).focus();
		}
	}

	public onHeaderClick(): void {
		this.openingPopover = true;
	}

	public onScrollChecked(isContentScrollable): void {
		this.isPopoverScrollable = isContentScrollable;
	}

	private async getDefaultAriaLabel(): Promise<string> {
		await loadPopoverButtonStrings(this.propertyFactory);
		return Promise.resolve(this.propertyFilterPipe.transform(DEFAULT_ARIA_PROP));
	}

	private focusToButton(): void {
		if (
			((this.closedBy !== PopoverCloseType.CONTAINER || isUndefined(this.focusOnCloseContainer)) && this.interactive) ||
			this.focusOnCloseContainer
		) {
			const elementWithFocus = document.activeElement;
			const buttonElement = document.getElementById(this.buttonAttrs.id);

			if (elementWithFocus !== buttonElement) {
				buttonElement.focus();
			}
		}
		this.closedBy = PopoverCloseType.UNKNOWN;
	}
}
