import {Inject, Injectable} from '@angular/core';
import {first, get, toLower} from 'lodash';

import {ApiService} from '../../core/communication/api-service/api-service.interfaces';
import {Props, RemoteOptions} from './mfe.interfaces';

import {ClientContainerContextService} from '../clientContainer/client-container-context.service';
import {DataService} from '../communication/data.service';
import {PreferencesCoreService} from '../../core/platform/preferences/preferences.service';
import {ReleaseToggleService} from '../../core/platform/releaseToggle/release-toggle.service';
import {CHAT_ICON_OPTIONS, END_POINT, PINNED_POST_OPTIONS, TALK_MFE} from './mfe.constants';

@Injectable({
	providedIn: 'root'
})
export class MfeService {
	private languageCode: string;
	private countryCode: string;

	constructor(
		@Inject(DataService) private apiService: ApiService,
		private preferencesService: PreferencesCoreService,
		private releaseToggleService: ReleaseToggleService,
		private clientContainerContextService: ClientContainerContextService
	) {}

	public getRemoteOptions(mfeData: string): Promise<any> {
		switch (mfeData) {
			case TALK_MFE.pinnedPost:
				return this.buildRemoteOptions(PINNED_POST_OPTIONS);
			case TALK_MFE.chatIcon:
				return this.buildRemoteOptions(CHAT_ICON_OPTIONS);
			default:
				return Promise.resolve({});
		}
	}

	public getProps(mfeData: string): any {
		switch (mfeData) {
			case TALK_MFE.pinnedPost:
			case TALK_MFE.chatIcon:
				return this.buildProps(mfeData);
			default:
				return {};
		}
	}

	public isFeatureSwitchEnabled(fsKey: string): Promise<boolean> {
		return this.releaseToggleService.getAll().then(res => res[fsKey]);
	}

	private getLocale(): string {
		const preferences = this.preferencesService.getPreferences();

		this.languageCode = get(preferences, 'localePolicy.languageCode');
		this.countryCode = get(preferences, 'localePolicy.countryCode');

		return `${toLower(this.languageCode)}_${toLower(this.countryCode)}`;
	}

	private async buildRemoteOptions(options: RemoteOptions): Promise<object> {
		const res = await this.apiService.get(END_POINT.talkConfig);
		const isLicensed = res?.licensed;
		const serverLink = res?.vanityUrl && first(res.vanityUrl.split('app'));
		const timestamp = new Date().getTime();

		if (isLicensed && !!serverLink) {
			return {
				remoteEntry: `${serverLink}${options.remoteEntry}?t=${timestamp}`,
				remoteName: options.remoteName,
				exposedModule: options.exposedModule,
				elementName: options.elementName
			};
		}
		return {};
	}

	private buildProps(mfeInstanceId: string): Props {
		return {
			mfeInstanceId,
			data_server: END_POINT.dataServer,
			locale: this.getLocale(),
			fromNativeApp: this.clientContainerContextService.isOneApp()
		};
	}
}
