import {Injectable} from '@angular/core';
import {isEqual} from 'lodash';

import {WFD_URL} from '../../../core/iframe-framework/iframe-framework.constant';
import {MenuItemConfig} from '../../../core/navBar/models/menu-item-config.model';
import {HOME_URI, OLD_HOME_URI} from '../../../core/navBar/nav-bar-menu';
import {WindowRefService} from '../../../services/window-ref.service';

@Injectable({
	providedIn: 'root'
})
export class NavBarUrlService {
	private window;

	constructor(windowRefService: WindowRefService) {
		this.window = windowRefService.nativeWindow;
	}

	public parseURLForLink(): string {
		// For use in MarkSelectedMenu to find appropriate menulink
		const path = this.window.location.pathname;
		const index = path.lastIndexOf('/');
		let route = path.endsWith('/') ? `${path.slice(0, index)}#` : `${path}#`;

		route = route.startsWith('/') ? route.slice(1) : route;
		route = route.startsWith(WFD_URL.slice(1)) ? route : `${route}/`;
		return route.replace(`${HOME_URI}#`, '#/');
	}

	public getDimensionsLink(item: MenuItemConfig): string {
		const uri = item.uri.startsWith('http:') || item.uri.startsWith('/') ? item.uri : `/${item.uri}`;

		return isEqual(uri, OLD_HOME_URI) ? `/${HOME_URI}` : uri;
	}
}
