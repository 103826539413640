<div class="menuOption" *ngIf="buttonConfig">
	<krn-ng-message-popup *ngIf="message"
		[messageText]="message"
		messageType="error"
		(onMessageButton)="setErrorMessage()"
		(onMessageClosed)="setErrorMessage()"
		[open]="message !== undefined">
	</krn-ng-message-popup>
	<div *ngIf="isLoading" class="impersonation-loader">
		<div class="backdrop"></div>
		<krn-ng-loader [messages]="['html.navBar.userImpersonation.impersonating.loader' | property:[selectedUser]]"
			classes="bounding-box bg-white loader-position"
			show="all">
		</krn-ng-loader>
	</div>
	<krn-ng-button
		(buttonClicked)="buttonConfig.callback()"
		[attrs]="{
			'aria-expanded': !isCollapsed,
			'aria-label': ('html.navBar.profileMenu.settings.subMenu.ariaLabel' | property: [buttonConfig.text]),
			'id': buttonConfig.id + '_button',
			'title': buttonConfig.text
		}"
		[icon]="'icon-k-small-caret-' + (isCollapsed ? 'down' : 'up')"
		[classes]="buttonConfig.btnClasses + (isCollapsed ? '' : ' expanded')"
		[buttonText]="buttonConfig.text"
		alignment="left">
	</krn-ng-button>
	<div *ngIf="!isCollapsed"
		krn-ng-modal-keyboard-navigation
		automation-id="impersonationUserList"
		modalRole="list"
		noWrap="true"
		role="menu"
		tabindex="-1"
		class="role-section-list py-1">
		<krn-ng-button *ngFor="let user of impersonationDetails.impersonationAllowedUsers; index as i"
			(buttonClicked)="performUserImpersonation(user)"
			[attrs]="{
				'aria-label': 'html.navBar.profileMenu.settings.item.ariaLabel' | property: [user.fullName, i + 1, impersonationDetails.impersonationAllowedUsers.length],
				'role': 'button',
				'title': user.fullName
			}"
			[buttonText]="user?.fullName"
			classes="authPerson w-100 info-impersination">
		</krn-ng-button>
	</div>
</div>
