import {AccessibilitySpeakerConfig} from '../services/accessibility-speaker.interface';

import {defaultConfig} from './accessibility-speaker.constant';

export function readForSR(text: string, incConfig: AccessibilitySpeakerConfig, $: any, $timeout = setTimeout): void {
	if (!text) {
		return;
	}
	const config = {...defaultConfig, ...incConfig};
	let $elm = $('.accessibility-speaker');

	if ($elm.length > 0) {
		$elm.empty();
	} else {
		$elm = $('<div></div>', {
			class: 'accessibility-speaker',
			css: {
				position: 'absolute',
				top: 0,
				left: '-9999px'
			}
		});
		$('body').append($elm);
	}
	$elm.attr('aria-live', config.isAssertive ? 'assertive' : 'polite');

	$timeout(() => {
		$elm.empty();
		$elm.append(text);
		$timeout(() => {
			$elm.empty();
		}, config.removeTimeout);
	}, config.readTimeout);
}
