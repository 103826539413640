import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {PreferencesServiceProvider} from '../../../providers/preferences.provider';
import {PropertyModule} from '../../../providers/property/property.module';
import {AttributesModule} from '../../../utils/attributes/attributes.module';
import {AuthenticationService} from '../authentication/authentication.service';
import {NavbarContainerModule} from './container/navbar.container.module';
import {HeaderModule} from './header/header.module';
import {MainMenuModule} from './main-menu/main-menu.module';
import {NavBarRoutingModule} from './nav-bar-routing.module';
import {NavBarSharedModule} from './navbar.shared.module';

@NgModule({
	imports: [
		CommonModule,
		AttributesModule,
		PropertyModule,
		NavBarSharedModule,
		NavBarRoutingModule,
		HeaderModule,
		MainMenuModule,
		NavbarContainerModule
	],
	exports: [NavbarContainerModule],
	providers: [AuthenticationService, PreferencesServiceProvider]
})
export class NavBarModule {}
