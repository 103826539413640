import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {assign, get} from 'lodash';

import {MenuIntegrationService} from '../../../../../core/navBar/menu-integration.service';
import {MenuIntegration} from '../../../../../core/navBar/models/menu-integrations.model';
import {PropertyFactory} from '../../../../../core/property/property.factory';

@Component({
	selector: 'krn-ng-menu-integration-selection',
	templateUrl: './menu-integration-selection.html'
})
export class MenuIntegrationSelectionComponent implements OnInit, OnChanges {
	@Input() public menuAdditionalData: any;

	public propertiesLoaded: boolean;
	public menuIntegrations: MenuIntegration[];
	public selectedMenuType = {
		label: '',
		primary: false
	};

	constructor(private propertyFactory: PropertyFactory, private menuIntegrationService: MenuIntegrationService) {
		this.propertiesLoaded = false;
	}
	public ngOnInit(): void {
		this.propertyFactory
			.loadProperties([
				{
					name: 'container_web-common_nav-bar_strings.properties',
					path: 'components/zed-core/properties/navBar/'
				}
			])
			.then(() => {
				this.propertiesLoaded = true;
			});
	}
	public ngOnChanges(changes: SimpleChanges): void {
		assign(this.selectedMenuType, this.menuIntegrationService.getSelectedMenuIntegParam(get(changes, 'menuAdditionalData.currentValue')));
	}
}
