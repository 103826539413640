<div class="employee" [ngClass]="getLayout()" [krn-ng-attributes]="attrs" [tooltip]="attrs?.title" [placement]="alignment">
	<div [ngClass]="calculatedImageClasses" [ngStyle]="imageStyle">
		<img *ngIf="!showInitials" [src]="imgSrc" [id]="photoAttrId" [alt]="attrs?.alt || photoName">
		<div *ngIf="showInitials" role="img" [ngClass]="calculatedInitialsClasses" [attr.aria-label]="attrs?.alt || photoName">{{calculatedInitials}}</div>
	</div>
	<div *ngIf="message || secondary" [ngClass]="getMessageAlignment()">
		<div *ngIf="message" [ngClass]="messageClasses" class="primary">{{message}}</div>
		<div *ngIf="secondary" [ngClass]="messageClasses" class="secondary">{{secondary}}</div>
	</div>
</div>
