import {NgModule} from '@angular/core';

import {ModalKeyboardNavigationDirective} from './modal-keyboard-navigation.directive';
import {selectorProvider} from './selector.provider';

@NgModule({
	declarations: [ModalKeyboardNavigationDirective],
	exports: [ModalKeyboardNavigationDirective],
	providers: [selectorProvider]
})
export class ModalKeyboardNavigationModule {}
