import * as jQuery from 'jquery';
import {noop} from 'lodash';

import {KeyNavService} from './keyboard-navigation.interfaces';

import {keys} from '../../constants/keys.constant';
import {EVENTS_HANDLERS} from '../utils.constants';

const $: any = jQuery;

export class NoTabNavigationService implements KeyNavService {
	private KEYDOWN_HANDLERS;

	constructor(private focusableElements: HTMLElement[], private container: HTMLElement) {
		this.KEYDOWN_HANDLERS = {
			[keys.TAB]: this.onTabPressed
		};
	}

	public initialize(): void {
		$(this.container).on(EVENTS_HANDLERS.KEYDOWN, (event: KeyboardEvent) => this.keyDownHandler(event));
	}

	public destroy(): void {
		$(this.container).off(EVENTS_HANDLERS.KEYDOWN);
	}

	private keyDownHandler(event: KeyboardEvent): void {
		(this.KEYDOWN_HANDLERS[event.key] || noop)(event);
	}

	private onTabPressed = (event: KeyboardEvent): void => {
		event.preventDefault();
	};
}
