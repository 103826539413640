<label
	[attr.id]="'sspb_' + attrs?.id"
	class="single-select-picker form-element-container"
	[ngClass]="labelClasses"
	[krn-ng-attributes]="{dir: attrs?.dir}"
	(click)="handleBubbleBtnClick($event)"
	(keydown)="selectPickerDelete($event)"
	#picker>
	<span
		*ngIf="attrs?.required"
		class="required-flag"
		[krn-ng-attributes]="{'aria-hidden': true}">
		*
	</span>
	<span
		*ngIf="attrs?.required"
		class="sr-only"
		[krn-ng-attributes]="{'aria-hidden': true, id: 'required_' + attrs?.id}">
		{{ 'picker.value.required' | property }}
	</span>
	<span
		*ngIf="label"
		class="control-label"
		[krn-ng-attributes]="{'aria-hidden': attrs['aria-hidden'] !== undefined ? attrs['aria-hidden'] : true, id: 'label_' + attrs?.id}"
		[tooltip]="attrs?.title" [container]="'#sspb_' + attrs?.id" [containerClass]="tooltipContainerClass">
		{{ label }}
	</span>
	<span
		class="single-select-picker-dropdown form-element value"
		[krn-ng-attributes]="{'aria-hidden': attrs['aria-hidden'] !== undefined ? attrs['aria-hidden'] : true, id: 'value_' + attrs?.id}"
		[ngClass]="{placeholder: selectedValue === undefined, disabled: attrs?.disabled}"
		[tooltip]="attrs?.title" [container]="'#sspb_' + attrs?.id" [containerClass]="tooltipContainerClass">
		{{ (selectedValue && selectedValue[dataLabel]) || attrs?.placeholder }}
	</span>
	<span
		*ngIf="(!selectedValue || !selectedValue[dataLabel]) && !attrs?.placeholder"
		class="sr-only"
		[krn-ng-attributes]="{'aria-hidden': true, id: 'emptyValue_' + attrs?.id}">
		{{ 'picker.value.empty' | property }}
	</span>
	<div *ngIf="!showAsNative" class="trigger-content d-flex justify-content-center align-items-center" [ngClass]="layout">
		<span class="caret" aria-hidden="true"><i [ngClass]="isOpened ? 'icon-k-small-caret-up' : 'icon-k-small-caret-down'"></i></span>
	</div>
	<ng-content></ng-content>
</label>
