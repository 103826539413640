import {propertyFilterInit} from '../../core/property/property.filter';
import {PropertyStateService} from './property-state.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PropertyFilterProvider = {
	provide: propertyFilterInit,
	deps: [PropertyStateService],
	useFactory: getPropertyFilter
};

export function getPropertyFilter(propertyStateService: PropertyStateService): any {
	return propertyFilterInit(propertyStateService);
}
