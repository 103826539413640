import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

import {MessageConfig} from '../../core/iframe-framework/iframe-framework.interface';
import {Message} from './message.interfaces';

@Injectable({providedIn: 'root'})
export class MessageService {
	private messageSource = new Subject<Message>();

	public messageSource$(): Observable<Message> {
		return this.messageSource;
	}

	public showMessage(messageType: string, config: MessageConfig): void {
		this.messageSource.next({messageType, config});
	}
}
