import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {MfeHostComponent} from './mfe-host.component';

const mfeHostRoutes: Routes = [
	{
		path: 'search',
		component: MfeHostComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(mfeHostRoutes)],
	exports: [RouterModule]
})
export class MfeHostRoutingModule{}
