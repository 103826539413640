import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {isInteger} from 'lodash';

import {ButtonAttrs, ButtonTypes} from '../../core/button/button.interfaces';

import {getButtonClasses, getButtonLayout, getButtonRole} from '../../core/button/button.utility';
import {defaultTooltipConfig} from '../../../lib/providers/tooltip-config.provider';
import {CLICK_EVENT_IGNORE_TIME} from '../../../lib/core/platform/constants/CommonConstants';

@Component({
	selector: 'krn-ng-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.less']
})
export class ButtonComponent implements OnChanges {
	@Input() public alignment: string;
	@Input() public attrs: ButtonAttrs;
	@Input() public buttonText: string;
	@Input() public btnType: ButtonTypes;
	@Input() public classes: string[] | string;
	@Input() public icon: string;
	@Input() public image: string;
	@Input() public tooltipDelay: number;

	@Output() public buttonBlurred = new EventEmitter();
	@Output() public buttonFocused = new EventEmitter();
	@Output() public buttonClicked = new EventEmitter();
	@Output() public buttonEntered = new EventEmitter();
	@Output() public buttonLeft = new EventEmitter();

	@ViewChild('btnId')
	public btnId: ElementRef;

	public btnClasses: string[] | string;
	public layout: string;
	public role: string;

	private lastClickEventTimeStamp = null;

	public ngOnChanges(): void {
		this.attrs = this.attrs || {};
		this.role = getButtonRole(this.btnType);
		this.layout = getButtonLayout(this.alignment);
		this.btnClasses = getButtonClasses(this.btnType, this.icon || this.image, this.classes);
		this.tooltipDelay = isInteger(this.tooltipDelay) ? this.tooltipDelay : defaultTooltipConfig.delay;
	}

	public handleButtonBlurred($event: FocusEvent): void {
		this.buttonBlurred.emit($event);
	}

	public handleButtonClicked($event: MouseEvent): void {
		const currentClickEventTimeStamp = Date.now();

		if (!this.lastClickEventTimeStamp || currentClickEventTimeStamp - this.lastClickEventTimeStamp > CLICK_EVENT_IGNORE_TIME) {
			this.btnId.nativeElement.focus();
			this.buttonClicked.emit($event);
		}
		this.lastClickEventTimeStamp = currentClickEventTimeStamp;
	}

	public handleButtonFocused($event: FocusEvent): void {
		this.buttonFocused.emit($event);
	}

	public handleButtonEntered($event: MouseEvent): void {
		this.buttonEntered.emit($event);
	}

	public handleButtonLeft($event: MouseEvent): void {
		this.buttonLeft.emit($event);
	}
}
