import {isNull} from 'lodash';

import {EmbeddedIframeActions} from './embedded.interfaces';

import {UnsavedDataCoreService} from '../framework/services/unsaved-data-core.service';
import {SlideOutManagerCoreService} from '../generic/slideOutContainer/slideout-manager.core.service';
import {BaseEmbeddedUnsavedDataCoreService} from './base-embedded-unsaved-data.core.service';

export class EmbeddedUnsavedDataCoreService extends BaseEmbeddedUnsavedDataCoreService {
	constructor(
		protected window: Window,
		protected unsavedDataService: UnsavedDataCoreService,
		protected slideOutManagerService: SlideOutManagerCoreService
	) {
        super(window, unsavedDataService, slideOutManagerService);
    }

	public verifyUnsavedData(action: string, url: string): Promise<boolean> {
		if (this.slideOutManagerService.hasOpenedSlideOuts()) {
			return action === EmbeddedIframeActions.ON_BACK ? this.verifyUnsavedDataForLastSlideOut() : this.verifyUnsavedDataForAllSlideOuts();
		}
		return this.verifyUnsavedDataForPage(url);
	}

	protected refreshState(): void {
		//
	}

	protected verifyUnsavedDataForLastSlideOut(): Promise<boolean> {
		this.slideOutManagerService.closeLastOpenedSlideOut();
		return Promise.resolve(true);
	}

	protected verifyUnsavedDataForAllSlideOuts(): Promise<boolean> {
		return new Promise(resolve => {
			this.slideOutManagerService.hasUnsavedData().then(hasUnsavedData => {
				resolve(hasUnsavedData);
			});
		});
	}

	private verifyUnsavedDataForPage = (url?: string): Promise<boolean> => {
		if (this.unsavedDataService.isDirty && !isNull(this.unsavedDataService.onUnsavedVerify)) {
			return new Promise(resolve => {
				this.unsavedDataService.onUnsavedVerify(navigate => {
					if (navigate && url) {
						this.window.location.href = url;
					}
					resolve(!navigate);
				});
				this.refreshState();
			});
		}

		if (url) {
			this.window.location.href = url;
		}

		return Promise.resolve(false);
	};
}
