import {get, isEmpty} from 'lodash';
import {createActions} from 'redux-actions';

export const actionsHelper = (): any => ({
	SET_RIGHT_MENU_ITEMS: (rightMenuItems: string[]) => ({rightMenuItems}),
	SET_TITLE: (title: string) => ({title}),
	SET_VISIBLE: (visible: boolean) => ({visible}),
	MENU_TOGGLED: (isMenuToggled: boolean) => ({isMenuToggled}),
	FETCH_MENU_DATA: (success: any, error: any) => ({
		menu: get(success, 'menu'),
		additionalData: get(success, 'additionalData'),
		error,
		isFetching: isEmpty(get(success, 'menu')) && isEmpty(error)
	}),
	UPDATE_MENU_DATA: (extendedMenu: any) => ({extendedMenu}),
	SET_STATE: (state: any) => ({state})
});

export const {setRightMenuItems, setTitle, setVisible, menuToggled, fetchMenuData, updateMenuData, setState} = createActions(actionsHelper());
