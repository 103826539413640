<div class="navigation">
	<nav class="navmenu" #navMenuRef
		krn-ng-modal-keyboard-navigation
		modalRole="list"
		[class.open]="isMainMenuOpen"
		[ngStyle]="styles"
		id="navmenu">
		<krn-ng-button
			id="navmenu-close-btn"
			krn-ng-focus-on-show="closeMenuHeaderButton"
			focus-delay="300"
			[attrs]="{'automation-id': 'closeMenuHeaderButton','id': 'closeMenuHeaderButton', 'tabindex': '0', 'title': ('html.navBar.menu.closemenu' | property),
			'aria-label': ('html.navBar.menu.closemenu' | property)}"
			btnType="button"
			icon="icon-k-close"
			(buttonClicked)="onMainMenuToggled()"
			#closeBtn>
		</krn-ng-button>
		<krn-ng-profile-menu [userInfo]="userInfo$ | async" [isMainMenuOpen]="isMainMenuOpen"></krn-ng-profile-menu>
		<krn-ng-search
			classes="navmenu-search"
			clearIcon="icon-k-cancel-circle"
			searchBoxClass="navmenu-search"
			[ariaSearchDetail]="'html.navBar.searchDetail.ariaLabel' | property"
			[attrs]="{
				'automation-id': 'searchMenu',
				id: 'search_box',
				placeholder: ('html.navBar.searchInput.search' | property),
				'aria-label': ('html.navBar.searchInput.ariaLabel' | property)
			}"
			[searchTerm]="searchTerm"
			(updatedSearchTerm)="updateSearchTerm($event)">
		</krn-ng-search>
		<krn-ng-nav-bar-main-menu class="navbar-mainMenu" automation-id="navBarMainMenu" [menuData]="menuData$ | async" [searchTerm]="searchTerm" tabindex="-1"></krn-ng-nav-bar-main-menu>
	</nav>
</div>

<krn-ng-nav-header
	[additionalData]="additionalData$ | async"
	[app]="app"
	[attr.aria-hidden]="isMainMenuOpen"
	[title]="displayTitle ? (title$ | async) : ''"
	[homeUri]="homeUri$ | async"
	[rightSectionItems]="rightMenuItems$ | async"
	[logoVersion]="logoVersion"
	[isMenuOpen]="isMainMenuOpen"
	(onMainMenuToggled)="onMainMenuToggled()">
</krn-ng-nav-header>

<krn-ng-screen-reader-only [content]="{text: isMainMenuOpen ? ('html.navbar.menu.openmenu.expanded.label' | property) : ''}" ariaLive="assertive"></krn-ng-screen-reader-only>
