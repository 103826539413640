import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';

import {CommonConstants} from '../platform/constants/CommonConstants';
import {SessionStorage} from '../platform/caching/sessionStorage.service';
import {GET_PROPERTY_TIMESTAMP} from './property.utility';

export class PropertyTimestampService {
	private cachedRequest;

	public getPropertyTimestamp(apiService: ApiService, logService, sessionStorageService: SessionStorage): Promise<any> {
		const timestampFromSessionStorage = sessionStorageService.get(GET_PROPERTY_TIMESTAMP);

		if (timestampFromSessionStorage) {
			return Promise.resolve(timestampFromSessionStorage);
		}
		
		if (!this.cachedRequest) {
			this.cachedRequest = apiService.find('property/get/timestamp', undefined, Transport.REST, undefined, undefined, undefined, {requireAuthenticate: false})
				.then(timestamp => {
					sessionStorageService.set(GET_PROPERTY_TIMESTAMP, timestamp);
					return timestamp;
				})
				.catch(err => {
					this.cachedRequest = undefined;
					return logService.error(CommonConstants.HTML_INFRASTRUCTURE_PROPERTY_TIMESTAMP_ERROR_MESSAGE);
				});
		}

		return this.cachedRequest;
	}
}