import {assign, cloneDeep, filter, find, forEach, get, includes, isEqual, isUndefined, noop} from 'lodash';

import {HelpActionHandlerResponse} from './contextual-help.interfaces';

import {AggregationsType, ContextualHelpActions, CreatedDateValues} from './contextual-help-search.constants';

export const DEFAULT_REQUEST = {
	searchString: '',
	resultsPerPage: 50,
	from: 0,
	sortby: '_score',
	orderBy: 'desc',
	aggregations: [{type: AggregationsType.CreatedDate, filter: [CreatedDateValues.AllTime]}]
};

export const DEFAULT_STATE = {
	searchResult: {
		aggregationsArray: [],
		results: {
			total: 0,
			hits: []
		}
	},
	searchRequest: DEFAULT_REQUEST,
	loading: true,
	refined: false
};

const actionMap = {
	[ContextualHelpActions.Search]: state => ({...state, loading: false, refined: false, reset: true}),
	[ContextualHelpActions.LoadMore]: state => ({...state, loading: false, refined: false, loadingMore: false, loadedMoreData: true}),
	[ContextualHelpActions.Sort]: state => ({...state, loading: false, reset: false}),
	[ContextualHelpActions.Refined]: state => ({...state, loading: false, refined: true, refineOpened: false})
};

export const contextualHelpReducerForDispatch = (state: HelpActionHandlerResponse, action: ContextualHelpActions): HelpActionHandlerResponse =>
	(actionMap[action] || noop)(state) || state;

export const contextualHelpReducer = (state, action): any => {
	let newState;

	switch (action.type) {
		case ContextualHelpActions.Search:
		case ContextualHelpActions.Sort:
			return assign({}, state, action.response);
		case ContextualHelpActions.Refined:
			/* eslint-disable no-case-declarations */
			let newValuesForFilterBySource;
			let newValuesForFilterByDate;
			let sv;
			let dv;

			if (action.response.searchResult.results.total === 0) {
				const filterBySource = find(get(action.response, 'searchRequest.aggregations'), item => isEqual(item.type, AggregationsType.Sources));
				const filterByDate = find(get(action.response, 'searchRequest.aggregations'), item => isEqual(item.type, AggregationsType.CreatedDate));

				if (filterBySource) {
					const prevFilterBySource = find(get(state, 'searchResult.aggregationsArray'), item => isEqual(item.key, AggregationsType.Sources));

					newValuesForFilterBySource = filter(prevFilterBySource.values, value => includes(filterBySource.filter, value.Contentname));
					forEach(newValuesForFilterBySource, v => {
						v.value = 0;
					});
					sv = assign({}, prevFilterBySource);
					sv.values = newValuesForFilterBySource;
				}

				if (filterByDate) {
					const prevFilterByDate = find(get(state, 'searchResult.aggregationsArray'), item => isEqual(item.key, AggregationsType.CreatedDate));

					newValuesForFilterByDate = filter(prevFilterByDate.values, value => includes(filterByDate.filter, value.Contentname));
					forEach(newValuesForFilterByDate, v => {
						v.value = 0;
					});
					dv = assign({}, prevFilterByDate);
					dv.values = newValuesForFilterByDate;
				}

				newState = assign({}, action.response);
				newState.searchResult.aggregationsArray = [sv, dv];
			} else {
				newState = assign({}, state, action.response);
			}
			if (!action.response.refineOpened) {
				delete newState.previousState;
			}
			return newState;

		case ContextualHelpActions.Loading: {
			newState = assign({}, state);
			newState.loading = true;
			newState.loadingMore = false;
			newState.searchRequest = cloneDeep(action.response.searchRequest);
			return newState;
		}
		case ContextualHelpActions.LoadingMore: {
			newState = assign({}, state);
			newState.loading = false;
			newState.loadingMore = true;
			newState.searchRequest = cloneDeep(action.response.searchRequest);
			return newState;
		}
		case ContextualHelpActions.LoadMore: {
			if (!action.response.loading) {
				const hits = 'searchResult.results.hits';

				action.response.searchResult.results.currentPageCount = action.response.searchResult.results.hits.length;
				const newRowData = get(state, hits).concat(get(action.response, hits));

				if (!isUndefined(get(action.response, hits))) {
					assign(action.response.searchResult.results.hits, newRowData);
				}
				const newLoadMoreState = assign({}, action.response);

				newLoadMoreState.refined = state.refined;
				return newLoadMoreState;
			}
		}
		// eslint-disable-next-line no-fallthrough
		case ContextualHelpActions.RefinePanelOpen: {
			const newRefinePanelState = assign({}, action.response);

			newRefinePanelState.previousState = assign({}, state);
			newRefinePanelState.refineOpened = true;
			delete newRefinePanelState.refineCancelled;
			return newRefinePanelState;
		}
		case ContextualHelpActions.Cancel: {
			const newCancelState = assign({}, state.previousState);

			delete newCancelState.previousState;
			newCancelState.refineCancelled = true;
			newCancelState.refineOpened = false;
			return newCancelState;
		}
		default:
			return state;
	}
};
