import {FactoryProvider} from '@angular/core';

import {SelectorService} from '../../core/platform/accessibility/selectors/selector.service';
import {VisableSelector} from '../../core/platform/accessibility/selectors/visable.selector';

export const selectorProvider: FactoryProvider = {
	provide: SelectorService,
	deps: [],
	useFactory: getSelectorService
};

export function getSelectorService(): SelectorService {
	const service = new SelectorService();

	service.loadSelector(new VisableSelector());
	return service;
}
