<div *ngIf="isMenuOpen" class="navbar-backdrop coverScreen" tabindex="-1" aria-hidden="true" (click)="toggleSidebar()"></div>
<header class="navbar-nav navbar-default navbar-fixed-top" [class.open]="isMenuOpen">
	<div class="container-fluid">
		<div class="row">
			<div class="navbar-default__head">
				<div *ngIf="!isMenuOpen">
					<krn-ng-button
						id="navmenu-open-btn"
						btnType="button"
						classes="hamburger-menu btn btn-with-icon"
						[class.open]="isMenuOpen"
						krn-ng-focus-on-show
						krn-ng-return-focus-element="navmenu-open-btn"
						focus-delay="600"
						return-focus-from="closeMenuHeaderButton"
						[skip-focus-if]="isPageLoaded"
						[attrs]="{
							'aria-expanded': isMenuOpen,
							'aria-label': ('html.navBar.menu.openmenu' | property),
							'automation-id': 'openMenuHeaderButton',
							id: 'openMenuHeaderButton'
						}"
						icon="icon-k-menu"
						(buttonClicked)="toggleSidebar()"
						#openMenuBtn>
					</krn-ng-button>
				</div>
				<div>
					<button
						type="button"
						class="btn home-button"
						(click)="onHomeButtonClick($event)"
						automation-id="returnBackToHomePageButton"
						[tooltip]="'html.navBar.menu.navigateToHome' | property"
						[attr.aria-label]="'html.navBar.menu.home.label' | property">
						<i class="item-icon icon-k-home"></i>
					</button>
				</div>
				<div *ngIf="showInMobileApp()">
					<button
						class="btn home-button"
						(click)="launchMobileApp()"
						type="button"
						[tooltip]="'html.navBar.menu.button.adp' | property"
						[attr.aria-label]="'html.navBar.menu.button.adp' | property"
						automation-id="returnBackToADPButton">
						<img [src]="getImage()" class="fitImageForButton" [alt]="'html.navBar.menu.button.adp' | property" />
					</button>
				</div>
				<div *ngIf="logoVersion">
					<a
						href="{{ homeUri }}"
						(click)="onHomeButtonClick($event)"
						class="navbar-logo d-none d-sm-inline-block"
						automation-id="logoHeaderButton"
						tabindex="-1"
						aria-hidden="true"
						[tooltip]="'html.navBar.menu.company.logo' | property">
						<img [alt]="'html.navBar.menu.company.logo.img.alt' | property" tabindex="-1" aria-hidden="true" src="../images/logo/{{ logoVersion }}" />
					</a>
				</div>
			</div>
			<h1 class="navbar-default__title" [attr.aria-hidden]="!title">
				<span class="navbar-title ng-binding d-md-block" automation-id="titleHeaderLabel">{{ title }}</span>
			</h1>
			<div class="navbar-default__manage">
				<krn-ng-talk-icons></krn-ng-talk-icons>
				<div *ngFor="let rightSectionItem of (isMobile() ? (rightSectionItems | krnNgActionItemSticky) : rightSectionItems) | krnNgActionItemNoText">
					<krn-ng-dynamic-item-manager [item]="rightSectionItem"></krn-ng-dynamic-item-manager>
				</div>
				<div *ngIf="isMobile()">
					<krn-ng-popover-button
						alignment="bottom right"
						[adaptivePosition]="false"
						renderType="alwaysFitContainer"
						classes="btn-secondary"
						icon="icon-k-group-overflow"
						container="#overflowContent"
						[focusOnCloseContainer]="false"
						[interactive]="true"
						[openAriaLabel]="'html.navBar.actions' | property"
						[closeAriaLabel]="'html.navBar.actions' | property"
						modalRole="menu"
						(onShown)="onPopoverShown()">
						<div *ngFor="let rightSectionItem of rightSectionItems | krnNgActionItemSticky: false" class="header-overflow">
							<krn-ng-dynamic-item-manager [item]="rightSectionItem" [itemInputs]="[{name: 'additionalData', value: additionalData}]">
							</krn-ng-dynamic-item-manager>
						</div>
					</krn-ng-popover-button>
				</div>
			</div>
		</div>
	</div>
</header>
<div id="overflowContent"></div>
