import {Component} from '@angular/core';
import {Router} from '@angular/router';

import {ClientContainerContextService} from '../../../services/clientContainer/client-container-context.service';
import {ClientContainerService} from '../../../services/clientContainer/client-container.service';
import {EmbeddedPostMessageService} from '../../../services/embedded-post-message.service';
import {SessionStorageService} from '../../../services/session-storage.service';
import {WindowRefService} from '../../../services/window-ref.service';
import {BaseEmbeddedPageComponent} from './base-embedded-page.component';
import {EmbeddedUnsavedDataService} from './embedded-unsaved-data.service';

@Component({
	selector: 'krn-ng-embedded-page',
	templateUrl: './embedded-page.component.html'
})
export class EmbeddedPageComponent extends BaseEmbeddedPageComponent {
	constructor(
		protected clientContainerService: ClientContainerService,
		protected embeddedPostMessageService: EmbeddedPostMessageService,
		protected router: Router,
		protected sessionStorageService: SessionStorageService,
		protected windowRefService: WindowRefService,
		protected embeddedUnsavedDataService: EmbeddedUnsavedDataService,
		protected clientContainerContextService: ClientContainerContextService
	) {
		super(
			clientContainerService,
			embeddedPostMessageService,
			router,
			sessionStorageService,
			windowRefService,
			embeddedUnsavedDataService,
			clientContainerContextService
		);
	}
}
