export enum AllowedActions {
	AUTHNSSID = 'authn_ssid',
	DIRTY_POPUP_REQUIRED = 'dirty_popup_required',
	NAVIGATION = 'navigation',
	RELOAD = 'reload'
	
}

export enum AllowedServices {
	WFD = 'wfd'
}

export enum AllowedType {
	INFO = 'info',
	CLOSE_POPUP = 'close_popup'
}
