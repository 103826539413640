import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {get, includes, isEmpty, isEqual, noop} from 'lodash';
import {first} from 'rxjs/operators';

import {WFD_URL} from '../../../../../core/iframe-framework/iframe-framework.constant';
import {setLocationUrl} from '../../../../../core/iframe-framework/iframe-message-handler.utility';
import {MenuItemConfig} from '../../../../../core/navBar/models/menu-item-config.model';
import {HOME_URI} from '../../../../../core/navBar/nav-bar-menu';
import {NavBarMenuItemCore} from '../../../../../core/navBar/nav-bar.menu-item';
import {keyCodes} from '../../../../../core/platform/constants/keys.constant';
import {PropertyFilterPipe} from '../../../../../providers/property/property.filter.pipe';
import {DeviceConfigService} from '../../../../../services/device-config.service';
import {SessionStorageService} from '../../../../../services/session-storage.service';
import {UnsavedDataService} from '../../../../../services/unsaved-data-service';
import {WindowRefService} from '../../../../../services/window-ref.service';
import {selectorProvider} from '../../../../../utils/modal-keyboard-navigation/selector.provider';
import {NavBarMenuService} from '../../nav-bar-menu.service';
import {$, ARIALABEL, FOCUS_DELAY, NEW_TAB, NEW_WINDOW_TYPE_ID} from './menu-item.constant';
import {MenuItemService} from './menu-item.service';
import {NavbarActionService} from '../../container/navbar.action.service';

@Component({
	selector: 'krn-ng-menu-item',
	templateUrl: './menu-item.component.html',
	styleUrls: ['./menu-item.component.less'],
	providers: [selectorProvider]
})
export class MenuItemComponent implements OnInit, AfterViewInit {
	@Input() public itemConfig: MenuItemConfig;
	@Input() public isSearch: boolean;
	@Input() public isSelected: boolean;
	@Input() public subIndex: number;
	@Input() public itemIndex: number;
	@Input() public menuData: MenuItemConfig[];

	public accessibilitySpeaker: HTMLElement;
	private navBar;
	private category;
	private isSafari: boolean;
	private categoryKeyDownMap;
	private keyDownMap;

	constructor(
		private router: Router,
		private menuItemUtils: NavBarMenuItemCore,
		private deviceConfigService: DeviceConfigService,
		private sessionStorageService: SessionStorageService,
		private navBarMenuService: NavBarMenuService,
		private menuItemService: MenuItemService,
		private elementRef: ElementRef<HTMLElement>,
		private propertyFilterPipe: PropertyFilterPipe,
		private unsavedDataService: UnsavedDataService,
		public windowRefService: WindowRefService,
		private navbarActionService: NavbarActionService
	) {
		this.keyDownMap = {
			[keyCodes.ENTER]: this.clickTarget,
			[keyCodes.SPACE]: this.clickTarget,
			[keyCodes.RIGHT_ARROW]: this.stopEvent,
			[keyCodes.LEFT_ARROW]: this.loseFocus,
			[keyCodes.UP_ARROW]: this.otherKeyAction,
			[keyCodes.DOWN_ARROW]: this.otherKeyAction,
			[keyCodes.TAB]: this.otherKeyAction
		};
		this.categoryKeyDownMap = {
			[keyCodes.ENTER]: this.spaceOrEnterKeyCategory,
			[keyCodes.SPACE]: this.spaceOrEnterKeyCategory,
			[keyCodes.RIGHT_ARROW]: this.rightArrowKeyCategory,
			[keyCodes.LEFT_ARROW]: this.leftArrowKeyCategory,
			[keyCodes.UP_ARROW]: this.otherKeyCategory,
			[keyCodes.DOWN_ARROW]: this.otherKeyCategory,
			[keyCodes.TAB]: this.tabKeyCategory
		};
	}

	public ngOnInit(): void {
		this.navBar = this.sessionStorageService.get('navBar');
		this.isSafari = this.deviceConfigService.isSafari(this.windowRefService.nativeWindow.navigator);
		this.category = this.navBar ? this.menuItemUtils.getNavBarCategories(this.itemConfig.id, this.navBar) : this.navBar;
		this.itemConfig.options = this.menuItemUtils.getDefaults(this.category).options;

		const menuItemState = this.menuItemUtils.setExpandedState(this.itemConfig, this.category, this.menuItemUtils.getNavBarState());

		this.updateNavBarAndCategory(menuItemState);
		this.itemConfig.target = this.itemConfig.typeId === NEW_WINDOW_TYPE_ID ? '_blank' : undefined;
		if (this.itemConfig.isNested) {
			this.itemConfig.innerMenu = this.isSearch ? this.itemConfig.filteredMenu : this.itemConfig.menu;
		} else {
			this.itemConfig = {...this.itemConfig, shiftTabToId: this.setMoveShiftTabToId(), tabToId: this.setMoveTabToId()};
		}
		this.itemConfig.automationId = this.menuItemUtils.getAutomationId(this.itemConfig);
		this.accessibilitySpeaker = this.elementRef.nativeElement.querySelector('.help-accessibility-speaker');
		if (this.isSearch) {
			this.itemConfig.options.expanded = this.isSearch;
		}
	}

	public ngAfterViewInit = (): void => this.setSelectedElementsAndUpdateTitle();

	public updateNavBarAndCategory(state): void {
		if (state) {
			this.navBar = state.navBar;
			this.category = state.newCategory;
		}
	}

	public toggleCategory = (): void => {
		this.itemConfig.options.expanded = !this.itemConfig.options.expanded;
		this.updateNavBarAndCategory(this.menuItemUtils.setToggledCategory(this.itemConfig, this.menuItemUtils.getNavBarState()));
		if (this.itemConfig.options.expanded) {
			setTimeout(() => $(this.elementRef.nativeElement).find('.submenu a').first().focus(), FOCUS_DELAY);
		}
	};

	public handleMenuSelection(event): void {
		const subeMenuAriaLabel = (
			isEmpty((event.target as HTMLElement).getAttribute(ARIALABEL)) ? (event.target.parentElement as HTMLElement) : (event.target as HTMLElement)
		).getAttribute(ARIALABEL);

		this.accessibilitySpeaker.innerHTML = '';
		this.accessibilitySpeaker.append(this.propertyFilterPipe.transform('html.navbar.subMenu.selected', [subeMenuAriaLabel]));
		this.unsavedDataService.redirect$.pipe(first()).subscribe(this.onRedirect(event));
		if (this.isSelected) {
			event.preventDefault();
		} else if ((this.itemConfig.href || this.itemConfig.uri) && this.itemConfig.target !== NEW_TAB) {
			if (this.unsavedDataService.getCustomPageDirty()) {
				event.preventDefault();
				this.unsavedDataService.showCustomUnsavedDialog();
			} else if (this.unsavedDataService.getDirty()) {
				event.preventDefault();
				this.unsavedDataService.showUnsavedDialog(this.unsavedDataService.unsavedModal, undefined, undefined, undefined, undefined);
			} else {
				this.handleNavigation(event);
			}
		}
		this.navBarMenuService.toggleMenu();
	}

	public handleNavigation = (event: KeyboardEvent, redirect?: boolean): void => {
		this.menuItemService.selectMenuLinkAndUpdateNavbar(this.itemConfig);
		if (!(event.ctrlKey || event.shiftKey)) {
			this.navbarActionService.dispatch(this.navbarActionService.actions.setTitle(this.itemConfig.text));
		}
		this.navigateViaRouterIfPossible(event, redirect);
		this.navBarMenuService.clearSearch();
	};

	public getCategoryAriaLabel(): string {
		return this.propertyFilterPipe.transform(
			`html.navbar.menu.${this.isSafari ? (this.itemConfig.options.expanded ? 'safari.expanded.' : 'safari.collapsed.') : ''}label`,
			[this.itemConfig.text, this.itemConfig.positionItem, this.itemConfig.totalCategory]
		);
	}

	public getLinkAriaLabel(): string {
		const isHomeLink = isEqual(this.getRouterLink(), `/${HOME_URI}`);

		if (this.isSafari) {
			return this.propertyFilterPipe.transform(`html.navbar.${isHomeLink ? 'homeMenu.' : 'subMenu.'}safari.label`, [
				this.itemConfig.text,
				this.itemConfig.positionItem,
				this.itemConfig.totalCategory
			]);
		}
		return isHomeLink ? this.itemConfig.text : this.propertyFilterPipe.transform('html.navbar.subMenu.label', [this.itemConfig.text]);
	}

	public getRouteUrl = (): string => {
		if (this.itemConfig.uri) {
			return this.itemConfig.uri.startsWith('http')
				? this.itemConfig.uri
				: `${this.windowRefService.nativeWindow.location.origin}${this.getRouterLink()}`;
		}
		return this.itemConfig.href;
	};

	public isExpanded = (): boolean => this.itemConfig.options.expanded;

	public isCollapsed = (): boolean => !this.itemConfig.options.expanded;

	public toggleCategoryByKey = (event: KeyboardEvent): void => (this.categoryKeyDownMap[event.which] || noop)(event);

	public handleSubCategoryKeyDown = (event: KeyboardEvent): void => (this.keyDownMap[event.which] || noop)(event);

	private onRedirect =
		event =>
		(redirect: boolean): void => {
			if (redirect) {
				this.handleNavigation(event, redirect);
			}
		};
	private spaceOrEnterKeyCategory = (event: KeyboardEvent): void => {
		this.stopEvent(event);
		this.toggleCategory();
	};
	private rightArrowKeyCategory = (event: KeyboardEvent): void => {
		if (this.itemConfig.options.expanded) {
			this.stopEvent(event);
		} else {
			event.stopPropagation();
			this.toggleCategory();
		}
	};
	private leftArrowKeyCategory = (event: KeyboardEvent): void => {
		event.stopPropagation();
		if (this.itemConfig.options.expanded) {
			this.toggleCategory();
			$(`#${get(this.category, 'id')}_category`).focus();
		}
	};
	private tabKeyCategory = (event: KeyboardEvent): void => {
		if (!event.shiftKey && this.itemConfig.options.expanded) {
			this.menuItemUtils.setSubMenuSelectedFocus(event);
		} else {
			this.menuItemUtils.topLevelMenuFocus(event, this.menuData, this.itemIndex, this.subIndex);
		}
	};
	private otherKeyCategory = (event: KeyboardEvent): void => {
		this.menuItemUtils.topLevelMenuFocus(event, this.menuData, this.itemIndex, this.subIndex);
	};
	private otherKeyAction = (event: KeyboardEvent): void => {
		if (!this.subIndex) {
			this.menuItemUtils.topLevelMenuFocus(event, this.menuData, this.itemIndex, this.subIndex);
		}
	};
	private stopEvent = (event: KeyboardEvent): void => {
		event.preventDefault();
		event.stopPropagation();
	};
	private clickTarget = (event: KeyboardEvent): void => {
		(event.target as HTMLElement).click();
		this.stopEvent(event);
	};

	private isSpaRoute = (): boolean => this.itemConfig.isSPA || includes(this.getRouterLink(), WFD_URL);

	private loseFocus = (event: KeyboardEvent): void => {
		this.stopEvent(event);
		if (this.subIndex) {
			const currentEle = $(`${this.itemConfig.shiftTabToId}`);

			(currentEle as HTMLElement).click();
			setTimeout(() => currentEle.focus());
		}
	};

	private getRouterLink = (): string =>
		this.itemConfig.isSPA ? this.itemConfig.uri.replace(WFD_URL, '') : this.menuItemService.getNonSPALink(this.itemConfig);

	private navigateViaRouterIfPossible = (event: KeyboardEvent, redirect = false): void => {
		if (this.isSpaRoute()) {
			event.preventDefault();
			this.router.navigateByUrl(this.getRouterLink().replace(WFD_URL, ''));
		} else if (redirect) {
			setLocationUrl(this.windowRefService.nativeWindow, this.getRouterLink(), true);
		}
	};

	private setTitle = (): void => this.navbarActionService.dispatch(this.navbarActionService.actions.setTitle(this.itemConfig.text));

	private setSelectedElementsAndUpdateTitle(): void {
		if (this.isSelected && this.isSearch !== true) {
			this.menuItemService.selectMenuLinkAndUpdateNavbar(this.itemConfig);
			setTimeout(this.setTitle);
		}
	}

	private setMoveShiftTabToId = (): string => (this.subIndex ? this.idBySubIndex() : this.searchBoxId());
	private idBySubIndex = (): string => `#${this.menuData[this.itemIndex].id}_category`;
	private searchBoxId = (): string => (this.isSearch ? '#search_box_clear-btn' : '#search_box');
	private setMoveTabToId = (): string => this.menuItemUtils.setMoveTabToId(this.menuData, this.itemIndex, this.subIndex);
}
