import {get, isFunction, noop} from 'lodash';

import {CordovaPluginWindow} from '../mobile/cordova-plugin-window.interface';
import {ScanConfig, ScanResult, TabType} from './client-container.interface';

import {INAPPBROWSER_OPTIONS, getTabType} from './client-container.utility';

export class MobileCommonFeature {
	constructor(protected window: CordovaPluginWindow) {}

	public scan(scanConfig: ScanConfig = {prompt: ''}): Promise<ScanResult> {
		const barcodeScanner = get(this.window, 'cordova.plugins.barcodeScanner', {});

		if (!isFunction(barcodeScanner.scan)) {
			return Promise.reject('Plugin call has failed.');
		}

		return new Promise((resolve, reject) => {
			barcodeScanner.scan(
				(result): any => resolve(result),
				(error): any => reject(error),
				scanConfig
			);
		});
	}

	public print = (content?: string): void => get(this.window.top, 'cordova.plugins.printer.print', noop)(content);

	public supportsPrint = (): boolean => !!get(this.window.top, 'cordova.plugins.printer.print');

	public supportsWifiLocation = (): boolean => !!get(this.window.top, 'kronosWifiLocation.getWiFiLocation');

	public supportsMapping = (): boolean => true;

	public openNewTab = (url: string, tab: string = TabType.BLANK): void => this.window.top.cordova.InAppBrowser.open(url, getTabType(tab), INAPPBROWSER_OPTIONS);
}
