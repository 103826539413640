import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {WindowRefService} from '../../../../services/window-ref.service';
import {AttributesModule} from '../../../../utils/attributes/attributes.module';
import {FocusOnShowModule} from '../../../../utils/focus-on-show/focus-on-show.module';
import {ModalKeyboardNavigationModule} from '../../../../utils/modal-keyboard-navigation/modal-keyboard-navigation.module';
import {MoveShiftTabToModule} from '../../../../utils/move-shift-tab-to/move-shift-tab-to.module';
import {MoveTabToModule} from '../../../../utils/move-tab-to/move-tab-to.module';
import {ScreenReaderOnlyModule} from '../../../../utils/screen-reader-only/screen-reader-only.module';
import {AvatarModule} from '../../../avatar/avatar.module';
import {ButtonModule} from '../../../button/button.module';
import {LoaderModule} from '../../../loader/loader.module';
import {MessageModule} from '../../../message/message.module';
import {PopoverButtonModule} from '../../../popover-button/popover-button.module';
import {NavBarMenuService} from '../nav-bar-menu.service';
import {NavBarUrlService} from '../nav-bar-url.service';
import {NavBarSharedModule} from '../navbar.shared.module';
import {MainMenuComponent} from './main-menu.component';
import {NavBarMenuProvider} from './main-menu.provider';
import {ManagerRoleListComponent} from './manager-role-list/manager-role-list.component';
import {ManagerRoleServiceProvider} from './manager-role-list/manager-role-list.provider';
import {MenuIntegrationParamComponent} from './menu-integration-param/menu-integration-param.component';
import {MenuIntegrationSelectionComponent} from './menu-integration-selection/menu-integration-selection.component';
import {MenuIntegrationComponent} from './menu-integration/menu-integration.component';
import {MenuIntegrationServiceProvider} from './menu-integration/menu-integration.provider';
import {MenuItemFilterPipe} from './menu-item/menu-item-filter.pipe';
import {MenuItemPositionsPipe} from './menu-item/menu-item-positions.pipe';
import {MenuItemTotalFilterCountPipe} from './menu-item/menu-item-total-filter-count.pipe';
import {MenuItemComponent} from './menu-item/menu-item.component';
import {MenuItemProvider} from './menu-item/menu-item.provider';
import {MultipleEmploymentComponent} from './multiple-employments/multiple-employment.component';
import {ProfileMenuComponent} from './profile-menu/profile-menu.component';
import {ProfileMenuProvider} from './profile-menu/profile-menu.provider';
import {UserImpersonationComponent} from './user-impersonation/user-impersonation.component';

@NgModule({
	declarations: [
		MainMenuComponent,
		MenuItemComponent,
		ManagerRoleListComponent,
		MenuItemFilterPipe,
		MenuItemPositionsPipe,
		MenuItemTotalFilterCountPipe,
		MultipleEmploymentComponent,
		ProfileMenuComponent,
		UserImpersonationComponent,
		MenuIntegrationComponent,
		MenuIntegrationParamComponent,
		MenuIntegrationSelectionComponent
	],
	imports: [
		AttributesModule,
		ButtonModule,
		CommonModule,
		NavBarSharedModule,
		AvatarModule,
		FocusOnShowModule,
		LoaderModule,
		MessageModule,
		ModalKeyboardNavigationModule,
		PopoverButtonModule,
		TooltipModule.forRoot(),
		MoveTabToModule,
		MoveShiftTabToModule,
		ScreenReaderOnlyModule
	],
	providers: [
		ManagerRoleServiceProvider,
		MenuItemProvider,
		NavBarMenuProvider,
		ProfileMenuProvider,
		MenuIntegrationServiceProvider,
		NavBarUrlService,
		NavBarMenuService,
		WindowRefService
	],
	exports: [ProfileMenuComponent, MainMenuComponent]
})
export class MainMenuModule {}
