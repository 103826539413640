import {Injectable} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {findIndex, isString} from 'lodash';

import {INDEX_NOT_FOUND} from '../../core/iframe-framework/iframe-framework.constant';
import {getUrlObj} from '../../core/iframe-framework/iframe-message-handler.utility';
import {IFrameValidatorCoreService} from '../../core/iframe-framework/iframe-validator.core.service';
import {WindowRefService} from '../window-ref.service';

@Injectable({providedIn: 'root'})
export class IFrameValidatorService extends IFrameValidatorCoreService {
	public anchorNode: HTMLAnchorElement;
	public registeredFrames: any[] = [];

	constructor(private domSanitizer: DomSanitizer, private windowRefService: WindowRefService) {
		super(windowRefService.nativeWindow);
		this.anchorNode = windowRefService.nativeWindow.document.createElement('a');
	}

	public isFrameValid = (origin: string): boolean => (!isString(origin)) ? false : this.getMatchedIndex(origin.replace(/:\d*$/, '')) !== INDEX_NOT_FOUND;

	public registerFrameUrl = (url: any): void => {
		const originUrl = this.getOriginUrl(url);

		if (this.getMatchedIndex(originUrl) === INDEX_NOT_FOUND) {
			this.registeredFrames.unshift(originUrl);
		}
	};

	private getOriginUrl = (url: any): SafeResourceUrl => {
		this.anchorNode.setAttribute('href', url.changingThisBreaksApplicationSecurity || url);
		const urlObj = getUrlObj(this.anchorNode, this.windowRefService.nativeWindow.location);

		return this.domSanitizer.bypassSecurityTrustResourceUrl(`${urlObj.protocol}//${urlObj.host}`);
	};

	private getMatchedIndex = (url: any): number => findIndex(this.registeredFrames, frame => {
			const iframeUrl = url.changingThisBreaksApplicationSecurity || url;

			return frame.changingThisBreaksApplicationSecurity.toString() === iframeUrl.toString();
		});
}