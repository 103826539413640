import {stringify} from 'querystring-es3';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Params} from '@angular/router';
import {camelCase, get, includes, isFunction, keys, noop, set} from 'lodash';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {setCloseButtonFocus} from '../../../core/generic/slideOutContainer/slideout-container.utils';
import {ACTION_TYPES} from '../../../core/generic/slideOutContainer/slideout.constants';
import {SlideoutCommunicationService} from '../../../services/slideout-communication.service';
import {BaseSliderActionHandler} from '../slide-out/slide-out-content/handlers/base-slider-action-handler';
import {DefaultSliderActionHandler} from '../slide-out/slide-out-content/handlers/default-slider-action-handler';
import {SlideOutComponent} from '../slide-out/slide-out.component';
import {SlideOutActionService} from '../../../services/slideout-action.service';
import {OtherActionService} from './other-action.service';

@Component({
	selector: 'krn-ng-iframe-slideout',
	templateUrl: './iframe-slideout.component.html',
	styleUrls: ['./iframe-slideout.component.less']
})
export class IframeSlideoutComponent implements OnInit, OnDestroy {
	@Input() public actionHandler: BaseSliderActionHandler;
	@Input() public iframeConfig: any = {};
	@Input() public iframeId = 'angularIframeSlider';
	@Input() public isCustomIframeContent = false;
	@Input() public navigateOnClose = true;
	@Input() public onClose: () => void;

	@ViewChild(SlideOutComponent)
	public slideOutContainer: SlideOutComponent;

	public autoFocus: boolean;
	public onSlideOutClose = noop;
	public returnFocusElement: string;

	private openOnLoad = true;
	private slideoutDestroy$ = new Subject<void>();

	constructor(
		public defaultSliderActionHandler: DefaultSliderActionHandler,
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private slideoutCommunicationService: SlideoutCommunicationService,
		private slideOutActionService: SlideOutActionService,
		private otherActionService: OtherActionService
	) {}

	public ngOnDestroy(): void {
		this.slideoutDestroy$.next();
		this.slideoutDestroy$.complete();
		this.slideoutCommunicationService.destroyListeners();
	}

	public ngOnInit(): void {
		this.actionHandler = this.actionHandler || this.defaultSliderActionHandler;
		if (this.isCustomIframeContent) {
			return;
		}
		this.route.params.pipe(takeUntil(this.slideoutDestroy$)).subscribe(this.onParamsChange);
		this.route.data.pipe(takeUntil(this.slideoutDestroy$)).subscribe(this.onRouteData);
		this.slideoutCommunicationService.openListener().pipe(takeUntil(this.slideoutDestroy$));
		this.slideoutCommunicationService.getComponentObs().pipe(takeUntil(this.slideoutDestroy$)).subscribe(this.handleAction.bind(this));
		this.slideOutActionService
			.select(['slideout', 'returnFocusElement'])
			.pipe(takeUntil(this.slideoutDestroy$))
			.subscribe(this.defineReturnFocusElement.bind(this));
	}

	public onSlideOutLoad = (): void => {
		this.onSlideOutClose = this.triggerOnClose;
		if (this.openOnLoad) {
			this.slideOutActionService.dispatch(this.slideOutActionService.actions.open());
		}
	};

	private handleAction = (action): void => {
		const type = get(action, 'type');

		if (type === ACTION_TYPES.SLIDEOUT_FOCUS) {
			setCloseButtonFocus(true, $(this.slideOutContainer.innerModal.nativeElement));
		} else if (type === ACTION_TYPES.OPEN_AFTER_CLOSE) {
			this.slideOutActionService.dispatch(this.slideOutActionService.actions.close());
			this.onSlideOutClose = () => {
				this.slideOutActionService.dispatch({
					type: ACTION_TYPES.OPEN_WITH_ROUTER,
					payload: action.payload
				});
				this.triggerOnClose();
			};
		} else if (action) {
			this.handleOtherAction(action, type);
		}
	};

	private handleOtherAction = (action, type): void => {
		if (includes(keys(this.slideOutActionService.actions), camelCase(type))) {
			this.slideOutActionService.dispatch(action);
			return;
		}
		this.otherActionService.dispatch(action);
	};

	private defineReturnFocusElement(returnFocusElement: string): void {
		this.returnFocusElement = this.returnFocusElement || returnFocusElement;
	}

	private onParamsChange = (params: Params): void => {
		set(
			this.iframeConfig,
			'url',
			this.sanitizer.bypassSecurityTrustResourceUrl(
				`${window.location.origin}/component-router#/${params.sliderRoute}?${stringify(get(this.route, 'snapshot.queryParams', {}))}`
			)
		);
		this.defineReturnFocusElement(get(this.route, 'snapshot.queryParams.returnFocusElement'));
		this.autoFocus = get(this.route, 'snapshot.queryParams.autoFocus', false);
	};

	private onRouteData = (data): void => (this.openOnLoad = get(data, 'openOnLoad', this.openOnLoad));

	private triggerOnClose = (): void => {
		if (isFunction(this.onClose)) {
			this.onClose();
		}
	};
}
