import {clone, isEmpty, isNil, isNumber} from 'lodash';
import {BehaviorSubject, fromEvent, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {JOIN_SYMBOL, LOAD_COMPLETED_TILE_ID_KEY} from './card-loader-manager.utility';

const NOT_FOUND = -1;

export class CardLoaderManagerCoreService {
    private tileLoadingThreshold;
	private defaultTileLoadingThreshold = 5;
    private currentLoadingTileCount = 0;
    private indexOfNextTileToLoad = 0;
    private tileIds: number[] = [];
    private currentLoadedTileIds: number[] = [];
    private previousLoadedTileIds: number[] = [];

    private nextTileToLoad$: Subject<number> = new ReplaySubject<number>();
    private allTilesLoaded$: Subject<boolean> = new BehaviorSubject<boolean>(false);
    private sessionStorageChange: Subscription;

    constructor(protected window: any) {}

    public initTileLoading = (tileIds: number[], tileLoadingThreshold?: number): void => {
        this.sessionStorageChange = fromEvent<StorageEvent>(this.window, 'storage').pipe(
            filter(event => event.storageArea === sessionStorage && event.key === `${LOAD_COMPLETED_TILE_ID_KEY}`),
            map(event => +event.newValue.split(JOIN_SYMBOL)[0]),
            filter(newVlaue => isNumber(newVlaue))
        ).subscribe(completedTileId => this.loadCompletedAndStartNext(completedTileId));
        this.previousLoadedTileIds = clone(this.currentLoadedTileIds);
        this.indexOfNextTileToLoad = 0;
        this.currentLoadedTileIds = [];
        this.tileIds = tileIds;
        this.allTilesLoaded$.next(isEmpty(tileIds));
		this.tileLoadingThreshold = isNil(tileLoadingThreshold) ? this.defaultTileLoadingThreshold : tileLoadingThreshold;

        while (this.currentLoadingTileCount < this.tileLoadingThreshold && this.indexOfNextTileToLoad < tileIds.length) {
            this.loadNextTile();
        }
    };

    public canLoad = (tileId: number, ignoreThrottling = false): Observable<number> => {
        if (this.isTileLoadingThrottlingEnabled() && !this.isAllTilesLoaded() && !ignoreThrottling) {
            return this.nextTileToLoad$.asObservable().pipe(filter(id => tileId === id));
        }
        this.loadCompletedAndStartNext(tileId);
        return of(tileId);
    };

    public loadCompletedAndStartNext = (loadCompletedTileId: number): void => {
        if (!this.isTileLoadedComplete(loadCompletedTileId, this.currentLoadedTileIds) && this.isTileInInitList(loadCompletedTileId)) {
            this.markComplete(loadCompletedTileId);
            if (this.tileIds[this.indexOfNextTileToLoad]) {
                this.loadNextTile();
            }
        }
    };

    public allComplete = (): Observable<boolean> => this.allTilesLoaded$.asObservable();

    public destroy = (): void => {
        this.indexOfNextTileToLoad = 0;
        this.tileIds = [];
        this.currentLoadedTileIds = [];
        this.previousLoadedTileIds = [];
        this.currentLoadingTileCount = 0;
		if (!isNil(this.sessionStorageChange)) {
			this.sessionStorageChange.unsubscribe();
		}
    };

	public destroyAndCleanup = (): void => {
		this.destroy();
		this.nextTileToLoad$ = new ReplaySubject<number>();
		this.allTilesLoaded$ = new BehaviorSubject<boolean>(false);
	};

    private loadNextTile = (): void => {
        this.indexOfNextTileToLoad ++;
        if (this.isTileLoadedComplete(this.tileIds[this.indexOfNextTileToLoad - 1], this.previousLoadedTileIds)) {
            this.markComplete(this.tileIds[this.indexOfNextTileToLoad - 1]);
        } else {
            this.nextTileToLoad$.next(this.tileIds[this.indexOfNextTileToLoad - 1]);
            this.currentLoadingTileCount ++;
        }
    };

    private markComplete = (loadCompletedTileId: number): void => {
        this.currentLoadedTileIds.push(loadCompletedTileId);
        if (this.currentLoadingTileCount > 0) {
            this.currentLoadingTileCount --;
        }

        if (this.isAllTilesLoaded()) {
            this.allTilesLoaded$.next(true);
        }
    };

    private isTileLoadedComplete = (tileId: number, tileIds: number[]): boolean => tileIds.indexOf(tileId) !== NOT_FOUND;

    private isTileInInitList = (tileId: number): boolean => this.isTileLoadedComplete(tileId, this.tileIds);

    private isTileLoadingThrottlingEnabled = (): boolean => !isEmpty(this.tileIds);

    private isAllTilesLoaded = (): boolean => this.tileIds.length === this.currentLoadedTileIds.length;
}
