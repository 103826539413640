import {SimplifiedOrgmapCommunicationService} from '../core/simplified-orgmap/simplified-orgmap-communication.service';
import {SessionStorageService} from '../services/session-storage.service';

export function getSbsSelectionService(sessionStorageService): SimplifiedOrgmapCommunicationService {
	const simplifiedOrgmapCommunicationService = new SimplifiedOrgmapCommunicationService(sessionStorageService);

	return simplifiedOrgmapCommunicationService;
}

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const SbsSelectionServiceProvider = {
	provide: SimplifiedOrgmapCommunicationService,
	deps: [SessionStorageService],
	useFactory: getSbsSelectionService
};
