import {forEach, isEmpty} from 'lodash';

import {MenuItemConfig} from './models/menu-item-config.model';

const setFilterMenuPosition = (collection: MenuItemConfig[], position: number, totalMenuItemCount: number): number => {
    forEach(collection, (item: MenuItemConfig) => {
        item.positionItem = undefined;
        item.totalCategory = undefined;

        if (!item.isNested) {
            item.totalCategory = totalMenuItemCount;
            item.positionItem = position ++;
        }
        
        if (!isEmpty(item.filteredMenu)) {
            position = setFilterMenuPosition(item.filteredMenu, position, totalMenuItemCount);
        }
    });

    return position;
};

export const getFilterTotalMenuItemCount = (collection: MenuItemConfig[]): number => {
    let totalMenuItemCount = 0;

    forEach(collection, (item: MenuItemConfig) => {

        if (!item.isNested) {
            totalMenuItemCount ++;
        }

        if (!isEmpty(item.filteredMenu)) {
            totalMenuItemCount += getFilterTotalMenuItemCount(item.filteredMenu);
        }
    });

    return totalMenuItemCount;
};

export const setFilterCategoriesPosition = (collection: MenuItemConfig[]): void => {
    const totalMenuItemCount: number = getFilterTotalMenuItemCount(collection);

    setFilterMenuPosition(collection, 1, totalMenuItemCount);
};

export const setCategoriesPosition = (collection: MenuItemConfig[], isSearch: boolean): void => {
    let idx: number;
    const lengthCategory = collection.length;
    const collectionName = isSearch ? 'filteredMenu' : 'menu';

    for (idx = 0; idx < lengthCategory; idx++) {
        if (collection[idx].isNested && collection[idx][collectionName]) {
            setCategoriesPosition(collection[idx][collectionName], isSearch);
        }

        collection[idx].totalCategory = lengthCategory;
        collection[idx].positionItem = idx + 1;
    }
};