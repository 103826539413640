import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

import {PreferencesCoreService} from '../../core/platform/preferences/preferences.service';
import {PercentageCoreService} from '../../core/platform/converters/percentage-core.service';
import {ConverterUtilService} from './converter-util.service';
import {FloatService} from './float.service';

@Injectable({providedIn: 'root'})
export class PercentageService extends PercentageCoreService {
	constructor(
		logService: NGXLogger,
		preferencesService: PreferencesCoreService,
		converterUtilService: ConverterUtilService,
		floatService: FloatService
	) {
		super(logService, preferencesService.getPreferences(), converterUtilService, floatService);
	}
}
