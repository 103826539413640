import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {get, indexOf, join, keys, last, map, toLower, toString, values} from 'lodash';
import {INGXLoggerConfig, INGXLoggerMetadata, NgxLoggerLevel, NGXLoggerWriterService} from 'ngx-logger';

import {EmbeddedIframeActions, dimensionMapToOneAppLogLevel} from '../core/embedded/embedded.interfaces';
import {ClientContext} from '../core/platform/clientContainer/client-container.interface';

import {LOG_REQUEST_ID} from '../core/embedded//embedded.constants';
import {postMessageEmbedded} from '../core/embedded/embedded-page.utility';
import {getTopWfdWindow} from '../core/iframe-framework/iframe-message-handler.utility';
import {ClientContainerContextService} from './clientContainer/client-container-context.service';
import {WindowRefService} from './window-ref.service';

@Injectable()
export class WriterCustomizedService extends NGXLoggerWriterService {
	constructor(
		@Inject(PLATFORM_ID) protected platformId,
		protected clientContainerContextService: ClientContainerContextService,
		protected windowRefService: WindowRefService
	) {
		super(platformId);
	}

	public writeMessage(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): void {
		if (this.clientContainerContextService.context() === ClientContext.EMBEDDED_SESSION) {
			postMessageEmbedded(getTopWfdWindow(this.windowRefService.nativeWindow), {
				requestId: LOG_REQUEST_ID,
				action: EmbeddedIframeActions.LOG,
				level: toLower(NgxLoggerLevel[metadata.level]),
				message: this.generateLogMessage(metadata)
			});
			return;
		} else if (this.clientContainerContextService.isOneApp()) {
			let correlationId;

			if (get(last(metadata.additional), 'correlationId')) {
				correlationId = metadata.additional.pop().correlationId;
			}

			this.windowRefService.nativeWindow.OneAppCommon.LOG[this.getOneAppMatchedLogLevel(metadata.level)](this.windowRefService.nativeWindow, {
				correlationId,
				message: this.generateLogMessage(metadata)
			});
			return;
		}

		super.writeMessage(metadata, config);
	}

	private getOneAppMatchedLogLevel(level: NgxLoggerLevel): string {
		const index = indexOf(values(NgxLoggerLevel), level);
		const key = keys(NgxLoggerLevel)[index];

		return dimensionMapToOneAppLogLevel[toString(toLower(key))];
	}

	private generateLogMessage = (metadata: INGXLoggerMetadata): string =>
		join(
			map([metadata.message, ...metadata.additional], arg => JSON.stringify(arg)),
			' '
		);
}
