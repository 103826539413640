import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

import {OfflineButtonService} from '../../../services/offline-button.service';

@Component({
	selector: '',
	styleUrls: ['./offline.component.less'],
	templateUrl: './offline.component.html'
})
export class OfflineComponent implements OnInit {
	public showWorkOffline;
	public offlineHeader = 'app.core.offline.header.connection.lost';
	public offlineBody = 'app.core.offline.body.connection.lost';

	constructor(public bsModalRef: BsModalRef, private offlineButtonService: OfflineButtonService) {}

	public ngOnInit(): void {
		this.showWorkOffline = this.offlineButtonService.canWorkOffline();
	}

	public runner(): void {
		this.offlineButtonService.runOfflineMode();
	}
}
