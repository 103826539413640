import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild} from '@angular/core';
import * as jQuery from 'jquery';
import {forEach, get, isEmpty, uniqueId} from 'lodash';

import {SelectItemType, SelectListAttrs} from '../../core/generic/select-item/select-item.interfaces';

import {appendSelectionLayout, findItems, findSelectedItems, getInputAttrs, isReadOnly} from '../../core/generic/select-item/select-item.utility';

const $ = jQuery;
const BUTTON_TAG = 'BUTTON';
const ALLOW_TAB = 0;
const DISALLOW_TAB = -2; // Important: tabindex is set to -2 so there is no tab stop but the element is considered focusable
const TAB_INDEX = 'tabindex';

@Component({
	selector: 'krn-ng-select-item',
	templateUrl: './select-item.component.html',
	styleUrls: ['./select-item.component.less']
})
export class SelectItemComponent implements OnChanges, AfterViewInit, OnDestroy {
	@Input() public alignment: string;
	@Input() public attrs: SelectListAttrs;
	@Input() public classes: string[] | string;
	@Input() public container = 'body';
	@Input() public isSelected = false;
	@Input() public multiSelectByTab = true;
	@Input() public showAsNative = false;
	@Input() public selectType: string = SelectItemType.SINGLE;
	@Input() public value: string | number;
	@Input() public isMixed = false;

	@Output() public selectedChange = new EventEmitter<string[] | number[]>();
	@Output() public singleSelectedChange = new EventEmitter<Event>();
	@Output() public singleSelectionCompleted = new EventEmitter<Event>();
	@Output() public onFocus = new EventEmitter<void>();

	@ViewChild('inputId')
	public inputId: ElementRef;
	@ViewChild('focusArea', {static: false})
	public focusArea: ElementRef;

	public selected: string[] | number[];
	public allClasses: string;
	public isReadOnly: boolean;
	public layout: string;
	public inputType = 'icon-k-checkmark';
	public defaultInputID: string = uniqueId();

	public ngOnChanges(changes: SimpleChanges): void {
		this.attrs = getInputAttrs(this.attrs, this.selectType, this.defaultInputID);
		this.isReadOnly = isReadOnly(this.attrs, this.selectType);
		this.allClasses = appendSelectionLayout(this.classes, this.alignment);
		this.setMixedState();

		if (changes.selectType || changes.multiSelectByTab) {
			this.removeTabStops();
		}
	}

	public ngAfterViewInit(): void {
		this.setMixedState();
		if (this.focusArea) {
			this.focusArea.nativeElement.addEventListener('click', this.handleFocusArea);
		}
	}

	public handleSelect(): void {
		this.selectedChange.emit(findSelectedItems(this.attrs));
	}

	public handleSingleSelect(event: Event): void {
		if (this.selectType === SelectItemType.SINGLE && !this.isSelected) {
			this.singleSelectedChange.emit(event);
		}

		this.singleSelectionCompleted.emit(event);
	}

	public handleFocus(): void {
		if (this.selectType === SelectItemType.MULTI && !this.multiSelectByTab) {
			this.addTabStops();
		}
		setTimeout(this.emitOnFocus);
	}

	public ngOnDestroy(): void {
		if (this.focusArea) {
			this.focusArea.nativeElement.removeEventListener('click', this.handleFocusArea);
		}
	}

	public handleFocusArea = (event: Event): void => {
		if (this.inputId && !(get(event.target, 'tagName') === BUTTON_TAG || !isEmpty($(event.target).parents('button')))) {
			this.inputId.nativeElement.focus();
			this.inputId.nativeElement.click();
		}
	};

	private addTabStops(): void {
		const id = get(this.attrs, 'id');

		forEach(findItems(this.attrs), item => $(item).attr(TAB_INDEX, $(item).attr('id') === id ? ALLOW_TAB : DISALLOW_TAB));
	}

	private emitOnFocus = (): void => this.onFocus.emit();

	private removeTabStops(): void {
		forEach(findItems(this.attrs), item => $(item).removeAttr(TAB_INDEX));
	}

	private setMixedState(): void {
		if (this.inputId) {
			this.inputId.nativeElement.indeterminate = this.isMixed;
		}
	}
}
