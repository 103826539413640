import {NetworkStream} from './network-stream.service';

export interface Logger {
	info: (...args: any[]) => void;
	trace: (...args: any[]) => void;
	debug: (...args: any[]) => void;
	warn: (...args: any[]) => void;
	error: (...args: any[]) => void;
	fatal: (...args: any[]) => void;
}

export class NetworkStreamLogger {
	constructor(private createLogger, private level, private window, private $log: Console) {}

	public create(name: string, onSend: (recs: any[]) => Promise<any>): Logger {
		return this.createLogger({
			name,
			level: this.level,
			stream: new NetworkStream(onSend, this.window, this.$log)
		});
	}
}
