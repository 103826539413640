import {A11yAttrs, BaseAttrs, FocusAttrs} from '../../platform/attributes.interfaces';

export enum SelectItemType {
	MULTI = 'multi',
	NONE = 'none',
	SINGLE = 'single'
}

export interface SelectListAttrs extends BaseAttrs, A11yAttrs, FocusAttrs {
	type?: string;
	name?: string;
	readonly?: boolean;
}
