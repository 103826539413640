import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalModule} from 'ngx-bootstrap/modal';

import {PropertyFilterPipe} from '../../../providers/property/property.filter.pipe';
import {PropertyModule} from '../../../providers/property/property.module';
import {FocusOnShowModule} from '../../../utils/focus-on-show/focus-on-show.module';
import {ButtonModule} from '../../button/button.module';
import {ToggleModule} from '../../toggle/toggle.module';
import {NewFeaturesModalComponent} from './new-features-modal.component';
import {NewFeaturesComponent} from './new-features.component';

@NgModule({
	declarations: [NewFeaturesComponent, NewFeaturesModalComponent],
	exports: [NewFeaturesComponent, NewFeaturesModalComponent],
	imports: [ButtonModule, CommonModule, FocusOnShowModule, ModalModule.forRoot(), PropertyModule, ToggleModule],
	providers: [PropertyFilterPipe]
})
export class NewFeaturesModule {}
