import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {invoke, set} from 'lodash';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';

import {PropertyFactory} from '../../../core/property/property.factory';
import {OfflineCoreService} from '../../../core/offline/offline.core.service';
import {SocketService} from '../../../services/communication/socket.service';
import {OfflineComponent} from './offline.component';
import {DataService} from '../../../services/communication/data.service';
import {WindowRefService} from '../../../services/window-ref.service';
import {AuthenticationRegistrationService, AuthenticationService} from '../authentication';
import {LOGIN_CONSTANTS} from '../../../core/authentication/authentication.constants';

const CLOSE_MODAL_DELAY = 1000;

@Injectable({
	providedIn: 'root',
	deps: [DataService]
})
export class OfflineService extends OfflineCoreService implements OnDestroy {
	private modalSubscription: Subscription;
	private bsModalRef: BsModalRef;
	private errorModalSubscription: Subscription;

	constructor(
		private router: Router,
		sockets: SocketService,
		propFactory: PropertyFactory,
		logger: NGXLogger,
		private bsModalService: BsModalService,
		dataService: DataService,
		windowRef: WindowRefService,
		private authService: AuthenticationService,
		authenticationRegistrationService: AuthenticationRegistrationService
	) {
		super(sockets, propFactory, logger, dataService, windowRef.nativeWindow, authenticationRegistrationService);
	}

	public initializeOfflineModal(): void {
		this.modalSubscription = this.getModalObservable().subscribe(disconnected => {
			this.showBsModal(disconnected);
		});
		this.errorModalSubscription = this.getErrorModalObservable().subscribe(connectionError => {
			this.showBsModal(connectionError);
			if (connectionError) {
				set(this.bsModalRef, 'content.offlineHeader', 'app.core.offline.header.connection.not.establish');
				set(this.bsModalRef, 'content.offlineBody', 'app.core.offline.body.connection.not.establish');
			}
		});
	}
	public ngOnDestroy(): void {
		this.modalSubscription.unsubscribe();
		this.errorModalSubscription.unsubscribe();
		this.unsubscribeFromAll();
	}

	protected retriesExpired(): void {
		super.retriesExpired();
		this.showBsModal(false);
		this.authService.dispatchActionAuthenticateError({message: LOGIN_CONSTANTS.SYSTEM_UNAVAILABLE});
		this.router.navigateByUrl(LOGIN_CONSTANTS.UNAUTHORIZED_URL);
	}

	protected sessionExpired(): void {
		super.sessionExpired();
		this.authService.dispatchActionAuthenticateError({message: LOGIN_CONSTANTS.SESSION_EXPIRED_CODE});
		this.router.navigateByUrl(LOGIN_CONSTANTS.UNAUTHORIZED_URL);
	}

	private showBsModal(showModal): void {
		if (this.sockets.isReady && showModal) {
			this.logger.error(`Connection Disruption: Showing Offline Modal: ${new Date().toUTCString()}`);
			this.bsModalRef = this.bsModalService.show(OfflineComponent, {
				backdrop: 'static',
				class: 'modal-lg',
				keyboard: false
			});
		} else {
			setTimeout(() => {
				if (this.bsModalRef) {
					invoke(this, 'bsModalRef.hide');
					this.bsModalRef = null;
					this.logger.error(`Connection Disruption: Closing Offline Modal: ${new Date().toUTCString()}`);
				}
			}, CLOSE_MODAL_DELAY);
		}
	}
}
