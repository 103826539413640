import {head, upperCase} from 'lodash';

import {ApiService, Transport} from '../../communication/api-service/api-service.interfaces';
import {VendorHelpContext} from '../../navBar/contextual-help/contextual-help.interfaces';
import {UserFeatures, UserInfo} from './user-info.interface';

import {VENDOR_HELP_CONTEXT_DEFAULT} from '../../navBar/contextual-help/contextual-help.utils';
import {SessionStorage} from '../../platform/caching/sessionStorage.service';

export class UserInfoService {
	private cachedRequest;
	private userFeatures;
	private cachedVendorRequest;

	constructor(private apiService: ApiService, private sessionStorageService: SessionStorage, private promiseWrapper: PromiseConstructor = Promise) {}

	public getLoggedOnUserData(): Promise<UserInfo> {
		const data = this.sessionStorageService.get('UserInfo');

		if (data) {
			return this.promiseWrapper.resolve(data);
		}

		this.cachedRequest = this.cachedRequest || this.requestForUserData();

		return this.cachedRequest;
	}

	public getUserFeaturesData(): Promise<UserFeatures> {
		this.userFeatures = this.userFeatures || this.requestForUserFeaturesData();
		return this.userFeatures;
	}

	/**
	 * Removes user data from the session storage cache. This will cause the next getLoggedOnUserData() call to refetch the data from the server.
	 * This should happen automatically when the user logs out, so only use this if you know what you're doing.
	 */
	public clearLoggedOnUserData(): void {
		this.sessionStorageService.clear('UserInfo');
		this.sessionStorageService.clear('Impersonation');
		this.sessionStorageService.clear('VendorInfo');
		this.clearCachedRequest();
		this.clearCachedVendorRequest();
	}

	public getVendorData(): Promise<VendorHelpContext> {
		const data = this.sessionStorageService.get('VendorInfo');
		const userInformationData = this.sessionStorageService.get('UserInfo');

		if (data) {
			return this.promiseWrapper.resolve(data);
		} else if (userInformationData && userInformationData.tenantName === '/tms') {
			return this.promiseWrapper.resolve({...VENDOR_HELP_CONTEXT_DEFAULT});
		} 
		this.cachedVendorRequest = this.cachedVendorRequest || this.requestForVendorData();
		return this.cachedVendorRequest;
	}

	private getInitials(firstName: string, lastName: string): string {
		return [upperCase(head(firstName)), upperCase(head(lastName))].join('');
	}

	private requestForUserData(): Promise<void | UserInfo> {
		return this.apiService.find('/get/UserInfo', undefined, Transport.REST).then(
			response => {
				const userInfo: UserInfo = response as UserInfo;

				userInfo.initials = this.getInitials(userInfo.firstName, userInfo.lastName);
				this.sessionStorageService.set('UserInfo', userInfo);
				return userInfo;
			},
			() => this.clearCachedRequest()
		);
	}

	private requestForVendorData(): Promise<void | VendorHelpContext> {
		const vendorInfoURI = 'branding.vendor.get';

		return this.apiService.get(vendorInfoURI, undefined, undefined, Transport.SOCKET, false).then(
			response => {
				const vendorHelpContext: VendorHelpContext = {
					...VENDOR_HELP_CONTEXT_DEFAULT,
					vendorName: response.vendorName, isHelpCustomized: response.isCustomHelp ? response.isCustomHelp : false
				};

				this.sessionStorageService.set('VendorInfo', vendorHelpContext);

				return vendorHelpContext;
			},
			() => this.clearCachedVendorRequest()
		);
	}

	private requestForUserFeaturesData(): Promise<void | UserFeatures> {
		return this.apiService.find('/get/userFeatures', undefined, Transport.REST).then(
			response => response,
			() => this.clearCachedUserFeaturesRequest()
		);
	}

	private clearCachedRequest(): void {
		this.cachedRequest = undefined;
	}

	private clearCachedUserFeaturesRequest(): void {
		this.userFeatures = undefined;
	}

	private clearCachedVendorRequest(): void {
		this.cachedVendorRequest = undefined;
	}
}
