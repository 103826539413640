import {get} from 'lodash';
import {handleActions} from 'redux-actions';

import {setKioskMode} from './kiosk.actions';

export const KIOSK_DEFAULT_STATE = {
	kioskMode: undefined
};

export const kioskReducer = handleActions(
	{
		[setKioskMode]: (state, action) => ({...state, kioskMode: get(action.payload, 'kioskMode', KIOSK_DEFAULT_STATE.kioskMode)})
	},
	KIOSK_DEFAULT_STATE
);
