import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {AttributesModule} from '../../../../utils/attributes/attributes.module';
import {DynamicItemModule} from '../../../../utils/dynamic-item/dynamic-item.module';
import {FocusOnShowModule} from '../../../../utils/focus-on-show/focus-on-show.module';
import {MoveShiftTabToModule} from '../../../../utils/move-shift-tab-to/move-shift-tab-to.module';
import {MoveTabToModule} from '../../../../utils/move-tab-to/move-tab-to.module';
import {ReturnFocusElementModule} from '../../../../utils/return-focus-element/return-focus-element.module';
import {ButtonModule} from '../../../button/button.module';
import {PopoverButtonModule} from '../../../popover-button/popover-button.module';
import {NavBarSharedModule} from '../navbar.shared.module';
import {ActionItemNoTextPipe} from './action-item/action-item-no-text.pipe';
import {ActionItemStickyPipe} from './action-item/action-item-sticky.pipe';
import {ActionItemModule} from './action-item/action-item.module';
import {TalkIconsComponent} from './action-item/talk-icons/talk-icons.component';
import {NavBarHeaderComponent} from './navbar-header.component';
import {MfeHostComponentModule} from '../../mfe-host/mfe-host.module';

@NgModule({
	declarations: [NavBarHeaderComponent, ActionItemNoTextPipe, ActionItemStickyPipe, TalkIconsComponent],
	imports: [
		CommonModule,
		DynamicItemModule,
		ActionItemModule,
		NavBarSharedModule,
		PopoverButtonModule,
		AttributesModule,
		MfeHostComponentModule,
		MoveTabToModule,
		MoveShiftTabToModule,
		FocusOnShowModule,
		ReturnFocusElementModule,
		TooltipModule.forRoot(),
		ButtonModule
	],
	exports: [NavBarHeaderComponent]
})
export class HeaderModule {}
