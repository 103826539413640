import * as jQuery from 'jquery';

import {LogServiceInterface} from '../platform/services/logService/log.interface';

import {LOCALSTORAGE_PREFIX, STORAGE_EVENT, TIME_TO_CLOSE_POPUP} from './authStack/auth0-stack.constant';
import {Auth0StackCoreService} from './authStack/auth0-stack.core.service';
import {REMOVE_ARIA_LIVE_TIMEOUT} from './session.constants';
import {SessionTimeoutCoreController} from './session.timeout.core.component';

const $: any = jQuery;

export abstract class Auth0SessionTimeoutCoreController extends SessionTimeoutCoreController {
	public removeAriaLiveTimeout = REMOVE_ARIA_LIVE_TIMEOUT;
	public onClose: () => void;
	public onDismiss: () => void;
	protected auth0StackService: Auth0StackCoreService;

	protected constructor(protected dispatch, protected windowRef: Window, protected logger: LogServiceInterface) {
		super(dispatch);
	}

	public init(): void {
		super.init();
		$(this.windowRef).on(STORAGE_EVENT, this.listenLocalStorageEvent);
	}

	public confirm(): void {
		super.cancelInterval();
		if (this.onClose) {
			this.onClose();
			$(this.windowRef).off(STORAGE_EVENT, this.listenLocalStorageEvent);
		}
	}

	public decline(): void {
		super.cancelInterval();
		if (this.onDismiss) {
			this.onDismiss();
			$(this.windowRef).off(STORAGE_EVENT, this.listenLocalStorageEvent);
		}
	}

	public onCountdownOver = (): void => {
		super.cancelInterval();
		if (this.onClose) {
			this.logger.info(TIME_TO_CLOSE_POPUP, new Date().toUTCString());
			this.onClose();
			$(this.windowRef).off(STORAGE_EVENT, this.listenLocalStorageEvent);
		}
	};

	private listenLocalStorageEvent = ({originalEvent}): void => {
		if (originalEvent && originalEvent.storageArea === this.windowRef.localStorage
			&& originalEvent.key === `${LOCALSTORAGE_PREFIX}${this.auth0StackService.clientId}`
			&& JSON.parse(originalEvent.newValue).isActive && !JSON.parse(originalEvent.oldValue).isActive && this.onClose) {
			this.logger.info(TIME_TO_CLOSE_POPUP, new Date().toUTCString());
			this.onClose();
			this.auth0StackService.listenUserActiveEvent();
		}
	};
}
