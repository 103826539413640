import {Inject, Injectable, Optional} from '@angular/core';
import * as jQuery from 'jquery';
import {NGXLogger} from 'ngx-logger';
import {noop} from 'lodash';

import {PrimusSocket} from '../../core/communication/socket/primus.socket';
import {AuthenticationRegistrationService} from '../../ui/framework/authentication/authentication.registration.service';
import {WindowRefService} from '../window-ref.service';
import {PrimusSocketService} from './primus-socket.service';
import {DISABLE_SOCKETS} from './socket.config';
import {BeforeUnloadService} from '../before-unload.service';
import {ClientContainerContextService} from '../clientContainer/client-container-context.service';
import {HealthCheckService} from '../health-check/health-check.service';

const $: any = jQuery;
const defPortMapping = {
	'http:': 80,
	'https:': 443
};
const CSRF_TOKEN_STORAGEKEY = 'storagect';
const STORAGE_EVENT = 'storage';
const STORAGE_EVENT_CYCLE = 1000;
const NO_EVENT = 0;

@Injectable({providedIn: 'root'})
export class SocketService extends PrimusSocket implements PrimusSocketService {
	public isReady = false;
	public isReconnect: boolean;
	public canProcessReconnect = false;
	public lastStorageEvent = NO_EVENT;
	private spark: any;
	private window: any;

	constructor(
		private logger: NGXLogger,
		private windowRefService: WindowRefService,
		private authenticationRegistrationService: AuthenticationRegistrationService,
		private beforeUnloadService: BeforeUnloadService,
		protected clientContainerContextService: ClientContainerContextService,
		healthCheckService: HealthCheckService,
		@Optional() @Inject(DISABLE_SOCKETS) private disableSockets: boolean = false
	) {
		super(logger, windowRefService.nativeWindow.window, clientContainerContextService, healthCheckService);
		this.window = this.windowRefService.nativeWindow;

		this.authenticationRegistrationService.whenAuthenticated(disableSockets ? noop : this.connectWhenAuthenticated);
	}

	public processReconnect(): void {
		const csrfToken = this.getCSRFToken();
		const storageCSRFToken = JSON.parse(this.windowRefService.nativeWindow.window.localStorage.getItem(CSRF_TOKEN_STORAGEKEY)).id;

		if (this.canProcessReconnect && csrfToken === storageCSRFToken) {
			this.canProcessReconnect = false;
			this.disconnect();
			this.spark = this.connect();
			this.isReconnect = false;
		}
	}

	public reconnect(): void {
		this.isReconnect = true;
	}

	protected disconnect(): void {
		this.spark.off('outgoing::url', this.spark.outgoingUrlFn);
		this.spark.off('open', this.spark.openFn);
		this.spark.off('end', this.spark.endFn);
		this.spark.recovery.destroy();
	}

	protected connect(): any {
		const location = this.windowRefService.nativeWindow.window.location;
		const socketPort = location.port || defPortMapping[location.protocol];
		const socketAddress = `${location.origin}:${socketPort}`;
		const tokenId = this.getCSRFToken();
		const page = this.getPage();
		const spark = super.connect(socketAddress, this.isReconnect);

		spark.outgoingUrlFn = str => {
			str.query = `csrf=${tokenId}`;
			str.query = page && page.length ? `${str.query}&page=${page}` : str.query;
			str.query = this.isReconnect ? `${str.query}&isReconnect=true` : str.query;
			this.logger.log('socket starts connection');
			this.isReconnect = false;
		};

		spark.openFn = () => {
			this.isReconnect = false;
			this.logger.log('Socket connected: connection is alive and kicking');
			const latestCSRFToken = this.getCSRFToken();

			this.windowRefService.nativeWindow.window.localStorage.setItem(CSRF_TOKEN_STORAGEKEY, JSON.stringify({id: latestCSRFToken}));
			this.isReady = true;
			this.connected();
		};

		spark.endFn = () => {
			if (this.isReconnect) {
				this.canProcessReconnect = true;
				this.processReconnect();
			}
		};

		spark.on('outgoing::url', spark.outgoingUrlFn);
		spark.on('open', spark.openFn);
		spark.on('end', spark.endFn);

		this.beforeUnloadService.add(() => {
			this.logger.debug('closing socket connection on unload');
			this.end();
			this.isReady = false;
		});

		return spark.open();
	}

	protected promiseWrapper(execute: (resolve, reject) => void): Promise<any> {
		return new Promise(execute);
	}

	private getPage(): string {
		const indexOfPage = 2;

		return window.location.pathname.split('/')[indexOfPage];
	}

	private getCSRFToken(): string {
		return $('meta[name="csrf"]').attr('content');
	}

	private handleStorageEvent = (event): void => {
		if (event && event.key === CSRF_TOKEN_STORAGEKEY) {
			const now = new Date().getTime();

			if (this.lastStorageEvent === NO_EVENT || now - this.lastStorageEvent > STORAGE_EVENT_CYCLE) {
				this.lastStorageEvent = now;

				const storageCSRFToken = JSON.parse(this.windowRefService.nativeWindow.window.localStorage.getItem(CSRF_TOKEN_STORAGEKEY)).id;

				$('meta[name="csrf"]').attr('content', storageCSRFToken);
				this.canProcessReconnect = true;
				this.processReconnect();
			}
		}
	};

	private connectWhenAuthenticated = (): void => {
		this.logger.info('Connecting to socket...');

		this.spark = this.connect();
		this.windowRefService.nativeWindow.window.addEventListener(STORAGE_EVENT, this.handleStorageEvent);
	};
}
