import {includes} from 'lodash';

import {ClientContext, TabType} from './client-container.interface';

export const getTabType = (tab): any => includes([TabType.BLANK, TabType.SYSTEM], tab) ? tab : TabType.BLANK;

export const INAPPBROWSER_OPTIONS = 'location=no';

export const getServiceByClientContext = <T>(
	currentClientContext: ClientContext,
	typeMap: {[key in ClientContext]: any},
	args: object[]
): T => new typeMap[currentClientContext](...args);