import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {TooltipConfigProvider} from '../../../providers/tooltip-config.provider';
import {AttributesModule} from '../../../utils/attributes/attributes.module';
import {PopoverButtonModule} from '../../popover-button/popover-button.module';
import {SingleSelectPopoverPickerComponent} from './single-select-popover-picker.component';
import {SelectItemModule} from '../../select-item/select-item.module';
import {FocusOnShowModule} from '../../../utils/focus-on-show/focus-on-show.module';
import {SingleSelectPickerBaseModule} from '../single-select-picker-base/single-select-picker-base.module';

@NgModule({
	declarations: [SingleSelectPopoverPickerComponent],
	exports: [SingleSelectPopoverPickerComponent],
	imports: [
		AttributesModule,
		CommonModule,
		FocusOnShowModule,
		PopoverButtonModule,
		SelectItemModule,
		SingleSelectPickerBaseModule,
		TooltipModule.forRoot()
	],
	providers: [TooltipConfigProvider]
})
export class SingleSelectPopoverPickerModule {}
