import {Injectable} from '@angular/core';

import {PropertyTimestampService as PropertyTimestampBaseService} from '../../core/property/property-timestamp.service';

@Injectable({providedIn: 'root'})
export class PropertyTimestampService extends PropertyTimestampBaseService {

	constructor() {
		super();
	}
}