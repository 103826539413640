import {get, PropertyPath} from 'lodash';

export type StateExtend<T> = T & {
  // Allows any extra properties to be defined in an action.
  [extraProps: string]: any
};

export function stateGetter<T, R>(propertyPath: PropertyPath, defaultValue?: R): any {
	const fuc = (state): R => get(state, propertyPath, defaultValue);

	fuc.propertyPath = propertyPath;
	return fuc;
}

interface Action {
	type: string;
}

export interface AnyAction extends Action {
  // Allows any extra properties to be defined in an action.
  [extraProps: string]: any
}

export interface Store {
	//dispatch<T extends Action>(action: T): any;
	dispatch: <T extends Action>(action: T) => any;
}

export function dispatch(store: Store) {
	return (action: any): void => store.dispatch(action);
}
