import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalModule} from 'ngx-bootstrap/modal';

import {PropertyFilterPipe} from '../../../providers/property/property.filter.pipe';
import {PropertyModule} from '../../../providers/property/property.module';
import {UserInfoServiceProvider} from '../../../providers/user-info-service.provider';
import {DynamicItemModule} from '../../../utils/dynamic-item/dynamic-item.module';
import {ButtonModule} from '../../button/button.module';
import {TileCopyComponent} from '../../domain/slide-outs/tile-copy/tile-copy.component';
import {TileFullScreenModalModule} from '../../domain/tile/fullscreen-modal/fullscreen-modal.module';
import {PopoverButtonModule} from '../../popover-button/popover-button.module';
import {SlideoutContentModule} from '../slide-out/slide-out-content/slideout-content.module';
import {SlideOutModule} from '../slide-out/slide-out.module';
import {ActionFrameworkComponent} from './action-framework.component';
import {ActionFrameworkService} from './action-framework.service';
import {AdapterButtonComponent} from './components/buttons/adapter-button/adapter-button.component';
import {ToggleButtonComponent} from './components/buttons/toggle-button/toggle-button.component';
import {CoolbarComponent} from './components/coolbar/coolbar.component';
import {ToolbarComponent} from './components/toolbar/toolbar.component';

@NgModule({
	declarations: [CoolbarComponent, ToolbarComponent, AdapterButtonComponent, ToggleButtonComponent, ActionFrameworkComponent, TileCopyComponent],
	imports: [
		CommonModule,
		ButtonModule,
		PopoverButtonModule,
		PropertyModule,
		DynamicItemModule,
		SlideOutModule,
		SlideoutContentModule,
		TileFullScreenModalModule,
		ModalModule.forRoot()
	],
	exports: [CoolbarComponent, ToolbarComponent, ToggleButtonComponent, AdapterButtonComponent, ActionFrameworkComponent],
	providers: [PropertyFilterPipe, UserInfoServiceProvider, ActionFrameworkService]
})
export class ActionFrameworkModule {}
