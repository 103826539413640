import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

import {BeforeUnloadCoreService} from '../core/platform/services/before-unload-core.service';
import {WindowRefService} from './window-ref.service';

@Injectable({
	providedIn: 'root',
	deps: [WindowRefService, NGXLogger]
})
export class BeforeUnloadService extends BeforeUnloadCoreService {
	constructor(window: WindowRefService, logger: NGXLogger) {
		super(window.nativeWindow, logger);
	}
}
