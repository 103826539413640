import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';

import {EmbeddedIframeActions} from '../../embedded/embedded.interfaces';
import {ClientContainer} from '../../platform/clientContainer/client-container.interface';

import {postMessageEmbedded} from '../../embedded/embedded-page.utility';
import {NAVIGATED_BACK_REQUEST_ID, NAVIGATED_FOFWARD_REQUEST_ID} from '../../embedded/embedded.constants';
import {SlideOutManagerCoreService} from './slideout-manager.core.service';

export abstract class BaseSlideOutContainer {
	public showSlideOut = false;
	public validationCallback: () => Promise<any>;

	protected showSlideOut$: Subject<boolean> = new Subject();
	protected slideoutDestroy$: Subject<void> = new Subject<void>();

	constructor(protected slideOutManagerService: SlideOutManagerCoreService, protected window: Window, protected clientContainerService: ClientContainer) {
		this.showSlideOut$.pipe(takeUntil(this.slideoutDestroy$), distinctUntilChanged()).subscribe(showSlideOut => {
			this.showSlideOut = showSlideOut;

			if (this.isNestedInIframeSlideout()) {
				return;
			}

			if (this.showSlideOut) {
				const ALERTS_SLIDE_OUT_URL = '/component-router#/alerts';

				if (!this.slideOutManagerService.hasOpenedSlideOuts() && !window.location.href.includes(ALERTS_SLIDE_OUT_URL)) {
					this.clientContainerService.dispatchEvent({
						detail: {
							method: 'goModal',
							arg: {
								modal: true,
								forPageURL: window.location.href
							}
						}
					});
				}
				this.slideOutManagerService.updateOpenedSlideOuts(this);
				postMessageEmbedded(window, {requestId: NAVIGATED_FOFWARD_REQUEST_ID, action: EmbeddedIframeActions.NAVIGATED_FOFWARD});
			} else {
				this.slideOutManagerService.popLastOpenedSlideOut();
				postMessageEmbedded(window, {requestId: NAVIGATED_BACK_REQUEST_ID, action: EmbeddedIframeActions.NAVIGATED_BACK});
				if (!this.slideOutManagerService.hasOpenedSlideOuts()) {
					this.clientContainerService.dispatchEvent({
						detail: {
							method: 'goModal',
							arg: {
								modal: false,
								forPageURL: window.location.href
							}
						}
					});
				}
			}
		});

		this.clientContainerService.getActionSubscription('onAttach', () => {
            this.clientContainerService.dispatchEvent({
                detail: {
                    method: 'goModal',
                    arg: {
                        modal: true,
                        forPageURL: window.location.href
                    }
                }
            });
          });
	}

	private isNestedInIframeSlideout(): boolean {
		return this.window.frameElement && this.window.frameElement.id === 'angularIframeSlider';
	}

	public abstract close(): void;
}