import {Component} from '@angular/core';

import {DelegatedRoleBannerCoreComponent} from '../../../core/delegated-role-banner/delegated-role-banner.core.component';
import {UserInfoService} from '../../../core/framework/services/user-info.service';
import {PropertyFactory} from '../../../core/property/property.factory';
import {WindowRefService} from '../../../services/window-ref.service';

@Component({
	selector: 'krn-ng-delegated-role-banner',
	templateUrl: './delegated-role-banner.component.html'
})
export class DelegatedRoleBannerComponent extends DelegatedRoleBannerCoreComponent {
	constructor(
		protected windowRefService: WindowRefService,
		protected propertyFactory: PropertyFactory,
		protected userInfoService: UserInfoService
	) {
		super(windowRefService.nativeWindow, propertyFactory, userInfoService);
	}

	public ngOnInit(): void {
		super.onInit();
	}
}
