import {UrlObj} from './iframe-framework.interface';

import {getUrlObj} from './iframe-message-handler.utility';

export class IFrameValidatorCoreService {
	public anchorNode: HTMLAnchorElement = window.document.createElement('a');
	constructor(protected windowRef) {}

	public getHostName = (url: string): UrlObj => {
		this.anchorNode.setAttribute('href', url);
		return getUrlObj(this.anchorNode, this.windowRef.location);
	};
}
