export const KIOSK_LOGIN_URL = '/kiosk/login/user';
export const KIOSK_AUTHN_URL = '/kiosk/authn';
export const KIOSK_PROCESS_URL = '/kiosk/process';
export const KIOSK_START_URL = 'kiosk/start';
export const KIOSK_RESTORE_URL = 'kiosk/restore';
export const KIOSK_DEACTIVATE_URL = 'kiosk/deactivate';
export const KIOSK_EXTEND_URL = 'kiosk/auth0Extend';
export const SSO_PORTAL_URL = '/sso/portal';
export const KIOSK_SERVERTIME_URL = '/kiosk/servertime';
export const KIOSK_USER_INSTANCE_API_URL = '/kiosk/instances';
export const KIOSK_CONFIG_API_URL = '/kiosks/config';
export const KIOSK_INSTANCE_COPY_API_URL = '/kiosk/copy';
export const KIOSK_FEDERATED_LOGOUT = '/sso/loggedout';
export const KIOSK_FEDERATED_LOGIN = 'kiosk/login/federated';
export const KIOSK_ADMIN_LOGOUT = '/kiosk/logout';
export const KIOSK_ADMIN_LOGOUT_URL = '/kiosk/adminLogoutURL';
export const KIOSK_ADMIN_LOGON = '../kiosk';
export const KIOSK_AUTH0_LOGOUT_URL = '/kiosk/auth0Logout';
export const KIOSK_OIDC_LOGOUT_URL = '/kiosk/auth0OIDCLogout';
export const KIOSK_CLEAR_IMPERSONATION_COOKIES = '/kiosk/clearImpersonationCookies';
export const KIOSK_ADMIN_HREF = 'kiosk';
export const KIOSK_HOME_HREF = 'kiosk/home';
export const KIOSK_LOGIN_HREF = 'kiosk/login/user';
export const WFD = 'wfd';
export const EMP_START_KIOSK_FACP = 'EA_START_KIOSK';
export const EMP_COPY_KIOSK_FACP = 'EA_COPY_KIOSK';
export const MGR_START_KIOSK_FACP = 'SA_START_KIOSK';
export const MGR_COPY_KIOSK_FACP = 'SA_COPY_KIOSK';
export const KIOSK_AUTHN_ERROR = 'kiosk.user.authentication.error';
export const KIOSK_AUTHZ_ERROR = 'kiosk.user.authorization.error';
export const KIOSK_FORBIDDEN_ERROR = 'kiosk.user.forbidden.error';
export const KIOSK_TOKEN_REFRESH_ERROR = 'kiosk.user.token.refresh.error';
export const KIOSK_TIMEOUT_ERROR = 'kiosk.timeout.error';
export const KIOSK_STATUS = 'kioskStatus';
export const GET_PROPERTY_PREFERENCES = 'preferences';
export const KIOSK_CONFIG = 'kioskConfig';
export const KIOSK_ADMIN_PAGE = `${WFD}/${KIOSK_ADMIN_HREF}`;
export const KIOSK_USER_PAGE = WFD + KIOSK_LOGIN_URL;
export const ADMIN = 'admin';
export const VANITY_URL_COOKIE = 'VANITY_URL';
export const EQUAL_TO = '=';
export const SEMICOLON = ';';
export const KIOSK_USERBYBADGE_API_URL = '/kiosk/userByBadge';
export const KIOSK_TIMEOUT_ERROR_STATUS_CODE = 401;
export const KIOSK_TIMEOUT_ERROR_CODE = 'KIOSK_TIMEOUT_ERROR';

export const POSITION = {
	LONGITUDE: 'longitude',
	LATITUDE: 'latitude',
	ACCURACY: 'accuracy'
};

export const KIOSK_ACTIONS = {
	AUTO_PUNCH: 0,
	PUNCH_CANCEL_DEDUCT: 1,
	ADVANCED_PUNCH: 2,
	WORK: 3,
	HOME: 4,
	STOP: 5
};

export const ADMIN_KIOSK_MENU = {
	START: {
		id: 'adminStartKiosk',
		label: 'kiosk.admin.menu.action.start'
	},
	RESTORE: {
		id: 'adminRestoreKiosk',
		label: 'kiosk.admin.menu.action.restore'
	},
	DUPLICATE: {
		id: 'adminDuplicateKiosk',
		label: 'kiosk.admin.menu.action.duplicate'
	},
	LOGOUT: {
		id: 'adminLogoutKiosk',
		label: 'kiosk.admin.menu.action.logout'
	}
};

export const ADMIN_KIOSK_TITLE_MAPPING = {
	[ADMIN_KIOSK_MENU.START.id]: 'kiosk.startKiosk.label',
	[ADMIN_KIOSK_MENU.RESTORE.id]: 'kiosk.restoreKiosk.label',
	[ADMIN_KIOSK_MENU.DUPLICATE.id]: 'kiosk.duplicateKiosk.label'
};

export const ADMIN_KIOSK_BUTTON_MAPPING = {
	[ADMIN_KIOSK_MENU.START.id]: 'kiosk.startKioskButton.label',
	[ADMIN_KIOSK_MENU.RESTORE.id]: 'kiosk.restoreKioskButton.label',
	[ADMIN_KIOSK_MENU.DUPLICATE.id]: 'kiosk.duplicateKioskButton.label'
};

export const USER_KIOSK_MENU = {
	STOP: {
		id: 'userStopKiosk',
		label: 'kiosk.user.menu.action.stop'
	},
	RESTART: {
		id: 'userRestartKiosk',
		label: 'kiosk.user.menu.action.restart'
	}
};

export const USER_KIOSK_PAGE_MODE_MAPPING = {
	[USER_KIOSK_MENU.STOP.id]: 'kiosk.user.stopKiosk.label',
	[USER_KIOSK_MENU.RESTART.id]: 'kiosk.user.restartKiosk.label'
};

export const USER_KIOSK_BUTTON_MAPPING = {
	[USER_KIOSK_MENU.STOP.id]: 'kiosk.user.stopKioskButton.label',
	[USER_KIOSK_MENU.RESTART.id]: 'kiosk.user.restartKioskButton.label'
};
