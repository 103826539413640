const FOCUSEABLE_ARRAYS = [
	'button:not([disabled]):not([tabindex="-1"]):not(.sr-only)',
	'[href]:not([disabled]):not([tabindex="-1"]):not(.sr-only)',
	'input:not([disabled]):not([tabindex="-1"]):not(.sr-only)',
	'input:not([disabled]):not([tabindex="-1"]):not(.sr-only):not([type="hidden"])',
	'select:not([disabled]):not([tabindex="-1"]):not(.sr-only)',
	'textarea:not([disabled]):not([tabindex="-1"]):not(.sr-only)',
	'[tabindex]:not([tabindex="-1"]):not(.sr-only)'
];
const KEYBOARD_NAVIGATION_DIRECTIVES = ['[krn-modal-keyboard-navigation]', '[krn-ng-modal-keyboard-navigation]'];
const ACCORDION_HEADER_ELE = ['button:not([accordion-heading])', 'div:not([accordion-heading])'];

export const FOCUSABLE_ELEMENTS = FOCUSEABLE_ARRAYS.join(', ');

export const ONE_LEVEL_UNDER_SELECTOR = FOCUSEABLE_ARRAYS.map(item =>
	KEYBOARD_NAVIGATION_DIRECTIVES.map(directive => `${directive} ${item}`).join(', ')
).join(', ');

export const TWO_LEVEL_UNDER_SELECTOR = FOCUSEABLE_ARRAYS.map(item =>
	KEYBOARD_NAVIGATION_DIRECTIVES.map(directive => `${directive} ${directive} ${item}`).join(', ')
).join(', ');

export const THREE_LEVEL_UNDER_SELECTOR = FOCUSEABLE_ARRAYS.map(item =>
	KEYBOARD_NAVIGATION_DIRECTIVES.map(directive => `${directive} ${directive} ${directive} ${item}`).join(', ')
).join(', ');

export const ACCORDION_HEADER_SELECTOR = ACCORDION_HEADER_ELE.map(item =>
	KEYBOARD_NAVIGATION_DIRECTIVES.map(directive => `${directive} ${item}`).join(', ')
).join(', ');

export enum SelectorCase {
	DEFAULT = 'DEFAULT',
	DIRECTLY_UNDER = 'DIRECTLY_UNDER',
	TWO_LEVEL_UNDER = 'TWO_LEVEL_UNDER'
}

export const ARIA = {
	CHECKED: 'aria-checked',
	CONTROLS: 'aria-controls',
	DESCRIBEDBY: 'aria-describedby',
	EXPANDED: 'aria-expanded',
	HASPOPUP: 'aria-haspopup',
	HIDDEN: 'aria-hidden',
	LABEL: 'aria-label',
	LABELLEDBY: 'aria-labelledby',
	LIVE: 'aria-live',
	REQUIRED: 'aria-required',
	SELECTED: 'aria-selected',
	POSINSET: 'aria-posinset',
	SETSIZE: 'aria-setsize'
};

export const FOCUS_ON_WATCH_DATA = '{krnNgFocusOnShow: krnNgFocusOnShow, focusDelay: focusDelay, skipFocusIf: skipFocusIf}';

export const ELEMENTS_TO_EXCLUDE = ['input[type="checkbox"]', 'input[type="search"]'];

export const ELEMENTS_TO_INCLUDE = ['input[type="radio"]', '.btn-primary'];

export const EVENTS_HANDLERS = {
	KEYDOWN: 'keydown',
	CLICK: 'click',
	FOCUS: 'focus',
	BLUR: 'blur',
	MOUSEDOWN: 'mousedown',
	MOUSELEAVE: 'mouseleave',
	TOUCHSTART: 'touchstart'
};

export const MODAL_ROLES = {
	APPLICATION: 'application',
	LIST: 'list',
	MENU: 'menu',
	MENUBAR: 'menubar',
	NONE: 'none',
	SEARCHABLEMENU: 'searchableMenu',
	SELECT: 'select',
	TOPLEVELMENU: 'topLevelMenu'
};

export const ROLE = 'role';

export const TAB_STOP = {
	ALLOW: 0,
	SKIP: -1
};

export const FOCUS_TIMEOUT = 200;

export const SKIPTOMAIN_FOCUS_TIMEOUT = 600;
