import {ProfileMenu} from '../../../../../core/navBar/profile-menu';
import {PropertyFactory} from '../../../../../core/property/property.factory';
import {PropertyFilterConfig, propertyFilterInit} from '../../../../../core/property/property.filter';
import {NavBarMenuService} from '../../nav-bar-menu.service';

export function getProfileMenu(propertyFilter: PropertyFilterConfig, propertyFactory: PropertyFactory, navBarMenuService: NavBarMenuService): any {
	return new ProfileMenu(propertyFilter, propertyFactory, navBarMenuService);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ProfileMenuProvider = {
	provide: ProfileMenu,
	deps: [propertyFilterInit, PropertyFactory, NavBarMenuService],
	useFactory: getProfileMenu
};
