import {LocationConfiguration} from './interfaces/simplified-orgmap.interface';

import {SessionStorageCoreService} from '../platform/caching/sessionStorage.service';

const sbsContextKey = 'sbsContextKey';

export class SimplifiedOrgmapCommunicationService {
	constructor(private sessionStorageService: SessionStorageCoreService) {}

	public getFromSessionStorage(): LocationConfiguration {
		return this.sessionStorageService.get(sbsContextKey);
	}

	public registerSimplifiedOrgMapContext(SimplifiedOrgLocConfig): LocationConfiguration {
		const simplifiedOrgLocationConfig = SimplifiedOrgLocConfig as LocationConfiguration;

		// eslint-disable-next-line padding-line-between-statements
		this.sessionStorageService.set(sbsContextKey, simplifiedOrgLocationConfig);

		return simplifiedOrgLocationConfig;
	}
}
