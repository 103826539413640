import {CommonConstants} from '../platform/constants/CommonConstants';

export const INITIAL = 'XX';

export const EMPLOYEE_IMAGE_SIZE = {
	EXTRA_SMALL: 1.5,
	SMALL: 2,
	MEDIUM: 2.5,
	LARGE: 4
};

export const ALIGNMENTS = {
	[CommonConstants.HTML_ALIGNMENT.LEFT]: 'flex-row-reverse',
	[CommonConstants.HTML_ALIGNMENT.RIGHT]: 'flex-row',
	[CommonConstants.HTML_ALIGNMENT.TOP]: 'flex-column-reverse',
	[CommonConstants.HTML_ALIGNMENT.BOTTOM]: 'flex-column'
};

export const IMG_SRC = '/images/default-employee-img.png';

export const IMG_PATH = '/employee/photo?photoId=';
