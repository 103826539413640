import {WifiLocation} from './wifi-location.interface';

export enum LocationType {
	COARSE_LOCATION_TYPE = 'COARSE',
	FINE_LOCATION_TYPE = 'FINE'
}

export interface GeoContext {
	canAccept?: boolean;
	currentBestLocation?: GeoLocation;
	desiredAccuracy?: number;
	errorCallback?: ErrorCallback;
	successCallback?: SuccessCallback;
	initialTimeout?: any;
	timeout?: any;
	watch?: number;
}

export interface GeoLocation {
	locationType?: LocationType;
	accuracy: number;
	latitude: number;
	longitude: number;
	timestamp?: number;
	wifi?: WifiLocation
}

export interface Coords {
	latitude: number;
	longitude: number;
	accuracy: number;
}

export interface Position {
	coords: Coords;
	timestamp: number;
}

export type ErrorCallback = ((err: string | Error) => any);
export type SuccessCallback = ((loc: GeoLocation) => any);
