import {forEach, isEmpty, isEqual, sortBy} from 'lodash';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';

import {UserInfoService} from '../framework/services/user-info.service';
import {SessionStorage} from '../platform/caching/sessionStorage.service';
import {PropertyFilterConfig} from '../property/property.filter';
import {ImpersonationAllowedUser, ImpersonationDetails, ImpersonationParams} from './models/user-impersonation-detail.model';
import {ProfileMenuItemConfig} from './profile-menu';
import {GET_USER_IMPERSONATION_API, IMPERSONATION_PARAMS, SESSION_STORAGE_KEY} from './user-impersonation.constants';

export class UserImpersonationService {
	constructor(
		protected sessionStorageService: SessionStorage,
		protected userDataService: UserInfoService,
		protected propertyFilter: PropertyFilterConfig,
		protected apiService: ApiService
	) {
		'ngInject';
	}

	public getButtonConfig(callback: () => void): ProfileMenuItemConfig {
		return {
			id: 'userImpersonationSupportAccessButton',
			text: this.propertyFilter('html.navbar.userImpersonation.supportAccess'),
			classes: 'btn managerRoleSwitch info-menu',
			postpendTransclude: false,
			callback
		};
	}

	public clearSessionBeforeImpersonation(): void {
		this.sessionStorageService.reset();
		this.userDataService.clearLoggedOnUserData();
	}

	public getSelfImpersonateErrorMsg(): string {

		return this.propertyFilter('html.navbar.userImpersonation.selfImpersonationError');
	}

	public getErrorMsg(error: any, userFullName?: string): string {
		const errorMsg = error
			? error.error || error.title || error.message || error
			: this.propertyFilter('html.commonInfra.platform.errorframework.errormessage.systemerror');
		const reason = this.propertyFilter('html.navbar.userImpersonation.reasonErrorMsg', [errorMsg]);

		return userFullName ? this.propertyFilter('html.navbar.userImpersonation.errorMsg', [userFullName, reason]) : reason;
	}

	public performUserImpersonation(user: ImpersonationAllowedUser): Promise<any> {
		return this.userDataService.getLoggedOnUserData().then(currentUserInfo => {
			if (isEqual(user.fullName, `${currentUserInfo.firstName} ${currentUserInfo.lastName}`)) {
				return 'FAILED';
			}

			return this.apiService.create('/impersonate', {supportUserImpersonateAs: user.userName}, this.getImpersonationParams(), Transport.REST, false, null, false, { routeTo: 'bck' });
		});
	}

	public getImpersonationParams = (): ImpersonationParams => IMPERSONATION_PARAMS;

	public getUserImpersonation(): Promise<ImpersonationDetails> {
		const data = this.sessionStorageService.get(SESSION_STORAGE_KEY);

		return data
			? Promise.resolve(data)
			: this.apiService.get(GET_USER_IMPERSONATION_API).then((response: ImpersonationDetails)=>  {
					response.isMultipleEmploymentMode = !isEmpty(response.impersonationAllowedEmployments);
					this.syncSortEmploymentNames(response);
					this.sessionStorageService.set(SESSION_STORAGE_KEY, response);
					return response;
		});
	}

	public isMultipleEmploymentsMode = (details: ImpersonationDetails): boolean =>
		details && !isEmpty(details.impersonationAllowedEmployments);

	public isImpersonationAllowed = (details: ImpersonationDetails): boolean =>
		details && details.isImpersonationAllowed && (details.isKgsUser || details.isAdminUser) && !isEmpty(details.impersonationAllowedUsers);

	private syncSortEmploymentNames(details: ImpersonationDetails): void {
		if (details.isMultipleEmploymentMode) {
			details.impersonationAllowedEmployments = sortBy(details.impersonationAllowedEmployments, ['employmentName']);
		}
	}
}
