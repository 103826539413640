export const keys = {
	BACKSPACE: 'Backspace',
	DEL: 'Delete',
	DOWN_ARROW: 'ArrowDown',
	ENTER: 'Enter',
	ESCAPE: 'Escape',
	LEFT_ARROW: 'ArrowLeft',
	RIGHT_ARROW: 'ArrowRight',
	SPACE: 'Space',
	TAB: 'Tab',
	UP_ARROW: 'ArrowUp',

	// IE/Edge specific keys
	DOWN: 'Down',
	ESC: 'Esc',
	LEFT: 'Left',
	RIGHT: 'Right',
	UP: 'Up'
};

export const keyCodes = {
	LEFT_CLICK: 1,
	RIGHT_CLICK: 3,
	TAB: 9,
	STRG: 17,
	CAPSLOCK: 20,
	CTRL: 17,
	CTRLRIGHT: 18,
	CTRLR: 18,
	SHIFT: 16,
	RETURN: 13,
	ENTER: 13,
	BACKSPACE: 8,
	BCKSP: 8,
	ALT: 18,
	ALTR: 17,
	ALTRIGHT: 17,
	SPACE: 32,
	WIN: 91,
	MAC: 91,
	FN: null,
	PG_UP: 33,
	PG_DOWN: 34,
	END: 35,
	HOME: 36,
	LEFT: 37,
	LEFT_ARROW: 37,
	UP: 38,
	UP_ARROW: 38,
	RIGHT: 39,
	RIGHT_ARROW: 39,
	DOWN: 40,
	DOWN_ARROW: 40,
	ESC: 27,
	INSERT: 45,
	DEL: 46,
	A_KEY: 65,
	MULTIPLY: 106,
	SUBTRACT: 109,
	DIVIDE: 111,
	F1: 112,
	F2: 113,
	F3: 114,
	F4: 115,
	F5: 116,
	F6: 117,
	F7: 118,
	F8: 119,
	F9: 120,
	F10: 121,
	F11: 122,
	F12: 123,
	EQUAL: 187,
	DASH: 189,
	PERIOD: 190,
	ZERO: 48,
	NINE: 57,
	NUM_ZERO: 96,
	NUM_NINE: 105
};
