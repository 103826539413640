import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

import {PageContextCoreService} from '../core/pageContext/page-context-core.service';
import {SessionStorageCoreService} from '../core/platform/caching/sessionStorage.service';
import {DateService} from './converters/date.service';
import {SessionStorageService} from './session-storage.service';
import {AuthenticationRegistrationService} from '../ui/framework/authentication/authentication.registration.service';
import {ClientContainerService} from './clientContainer/client-container.service';

@Injectable({
	providedIn: 'root',
	deps: [NGXLogger, DateService, SessionStorageService, AuthenticationRegistrationService, ClientContainerService]
})
export class PageContextService extends PageContextCoreService {
	constructor(
		logger: NGXLogger,
		dateService: DateService,
		sessionStorageService: SessionStorageService,
		authenticationRegistrationService: AuthenticationRegistrationService,
		clientContainerService: ClientContainerService
	) {
		super(logger, dateService, sessionStorageService as SessionStorageCoreService, authenticationRegistrationService, clientContainerService);
	}
}
