import {Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {get, isNil, trim, debounce} from 'lodash';
import {Subject} from 'rxjs';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';

import {SearchAttrs, SearchTermChange} from '../../core/search/search.interfaces';

import {keyCodes} from '../../core/platform/constants/keys.constant';
import {getClasses} from '../../core/platform/composite-form.utility';
import {PropertyFactory} from '../../core/property/property.factory';
import {AUTOMATION_ID_ATTR, DEBOUNCE_TIME, DEFAULT_CHANGE_THRESHOLD, DEFAULT_OPTIONS} from '../../core/search/search.constants';
import {
	getAutomationId,
	getBtnClasses,
	getFormElementClasses,
	getInputAttrs,
	hasSearchValue,
	loadProperties,
	notifyUserOfSearch,
	selectText
} from '../../core/search/search.utility';
import {PropertyFilterPipe} from '../../providers/property/property.filter.pipe';
import {DeviceConfigService} from '../../services/device-config.service';
import {ReactiveFormsBase} from '../../utils/reactive-forms/reactive-forms-base.class';

@Component({
	selector: 'krn-ng-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.less'],
	providers: [PropertyFilterPipe, {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SearchComponent), multi: true}]
})
export class SearchComponent extends ReactiveFormsBase implements OnChanges, OnInit, OnDestroy {
	@Input() public alignment: string;
	@Input() public ariaSearchDetail: string;
	@Input() public attrs: SearchAttrs;
	@Input() public classes: string | string[];
	@Input() public clearIcon: string;
	@Input() public label: string;
	@Input() public searchBoxClass: string;
	@Input() public searchTerm: string;
	@Input() public searchResultCount: number;
	@Input() public submitOnChange: boolean;
	@Input() public submitOnChangeThreshold: number = DEFAULT_CHANGE_THRESHOLD;
	@Input() public trimSearchTerm: boolean;
	@Input() public debounceTime: number = DEBOUNCE_TIME;

	@Output() public onSearchTermChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() public updatedSearchTerm: EventEmitter<SearchTermChange> = new EventEmitter<SearchTermChange>();

	public baseAutoId: object;
	public containerClasses: string[];
	public formElementClasses: string[];
	public hasSearch = false;
	public searchBtnHasFocus: boolean;
	public isMobileDevice: boolean;

	public getAutomationId = getAutomationId;
	public getBtnClasses = getBtnClasses;
	public hasSearchValue = hasSearchValue;
	public selectText = selectText;

	public searchNotifier$: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
	private unsubscribe$ = new Subject();
	private propertyFilter;
	private searchNotifierDebounce = debounce(event => {
		this.searchNotifier$.next(event);
	}, this.debounceTime);

	constructor(
		protected propertyFactory: PropertyFactory,
		private propertyFilterPipe: PropertyFilterPipe,
		private deviceConfigService: DeviceConfigService
	) {
		super();
		this.propertyFilter = this.propertyFilterPipe.transform.bind(this.propertyFilterPipe);
	}

	public stopClickPropagation(event): void {
		event.stopPropagation();
	}

	public searchButtonClicked(event): void {
		this.stopClickPropagation(event);
		this.triggerSearch(true);
	}

	public triggerSearch = (startSearch: boolean): void => {
		this.onSearchTermChange.emit(this.searchTerm);
		this.hasSearch = hasSearchValue(this.searchTerm) && startSearch;
		if (this.submitOnChange && this.searchTerm && this.searchTerm.length < this.submitOnChangeThreshold) {
			return;
		}
		const searchTerm = this.trimSearchTerm ? trim(this.searchTerm) : this.searchTerm;

		if (startSearch) {
			this.onChange(searchTerm);
		}
		this.updatedSearchTerm.emit({searchTerm, startSearch});
		if (!hasSearchValue(this.searchTerm)) {
			this.notifyUser();
		}
	};

	public getInputAttrs = (attrs: SearchAttrs, defaultPlaceholder: string, label?: string): SearchAttrs =>
		getInputAttrs(attrs, defaultPlaceholder, this.propertyFilter, label);

	public async ngOnInit(): Promise<void> {
		this.clearIcon = this.clearIcon || DEFAULT_OPTIONS.CLEAR_ICON;
		this.submitOnChange = !isNil(this.submitOnChange) ? this.submitOnChange : true;
		this.trimSearchTerm = !isNil(this.trimSearchTerm) ? this.trimSearchTerm : true;
		this.isMobileDevice = this.deviceConfigService.isMobileDevice();

		this.searchNotifier$
			.pipe(
				takeUntil(this.unsubscribe$),
				map((event?: KeyboardEvent) => ({event, searchTerm: this.searchTerm})),
				distinctUntilChanged((previous, current) => {
					if (get(current, 'event.keyCode') === keyCodes.ENTER) {
						return false;
					}
					return previous.searchTerm === current.searchTerm;
				})
			)
			.subscribe(() => this.triggerSearch(this.submitOnChange));

		await loadProperties(this.propertyFactory);

		this.baseAutoId = {[AUTOMATION_ID_ATTR]: get(this.attrs, AUTOMATION_ID_ATTR)};
		this.attrs = getInputAttrs(this.attrs, null, this.propertyFilter, this.label);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.containerClasses = getClasses([], this.alignment, get(this.attrs, 'dir'));
		this.formElementClasses = getFormElementClasses(!this.submitOnChange, `${this.searchBoxClass} search-simple-input`);
		if (this.hasSearch && !isNil(changes.searchResultCount)) {
			this.notifyUser(this.searchResultCount);
		}
	}

	public notiyUserCallByParent(count: number): void {
		this.notifyUser(count);
	}

	public onCancelButtonPress(event: Event): void {
		event.stopPropagation();
		const inputElement = document.getElementById(get(this.attrs, 'id'));

		this.hasSearch = false;
		this.searchTerm = '';
		this.updateSearchTerm();
		if (inputElement) {
			inputElement.focus();
		}
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next(undefined);
		this.unsubscribe$.complete();
	}

	public updateSearchTerm(event?: KeyboardEvent): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		this.hasSearch = false;
		this.searchNotifierDebounce(event);
	}

	public writeValue(value: string): void {
		this.searchTerm = value;
		this.updateSearchTerm();
	}

	private notifyUser = (resultsTotal?: number): void => {
		notifyUserOfSearch(this.searchTerm, resultsTotal, this.propertyFilter);
	};
}
