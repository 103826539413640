import {get} from 'lodash';

import {CordovaPluginWindow} from './cordova-plugin-window.interface';
import {ErrorResult, LocalAuth, LocalAuthHandler} from './local-auth.interface';

import {ANY_AUTH, BIO_AUTH, LOCAL_AUTH_INT_ERR_AUTH_REQ, LOCAL_AUTH_INT_ERR_CANNOT_USE, LOCAL_AUTH_INT_ERR_FLAG_REQ, LOCAL_AUTH_INT_ERR_INVALID_TYPE} from './local-auth.constants';

export class LocalAuthCoreService implements LocalAuthHandler{
	constructor(protected $window: CordovaPluginWindow) {}

	public runLocalAuth(localAuthFlag: number, localAuthType: string): Promise<LocalAuth> {
		return new Promise<LocalAuth>((resolve, reject) => {
			if (!localAuthFlag) {
				reject(LOCAL_AUTH_INT_ERR_FLAG_REQ);
			}

			if (!localAuthType) {
				reject(LOCAL_AUTH_INT_ERR_AUTH_REQ);
			}

			if (!get(this.$window, 'krnUtilities.runAnyAuth')) {
				reject(LOCAL_AUTH_INT_ERR_CANNOT_USE);
			}

			let localAuthPromise;

			switch(localAuthType) {
				case BIO_AUTH:
					localAuthPromise = this.$window.krnUtilities.runBioAuth(localAuthFlag);
					break;
				case ANY_AUTH:
					localAuthPromise = this.$window.krnUtilities.runAnyAuth(localAuthFlag);
					break;
				default:
					reject(LOCAL_AUTH_INT_ERR_INVALID_TYPE);
					break;
			}
			if (localAuthPromise) {
				localAuthPromise
					.then((result: LocalAuth) => resolve(result))
					.catch((error: ErrorResult) => reject(error));
			}
		});
	}
}
