import {forEach, nth} from 'lodash';
import * as $ from 'jquery';
import {Subscription} from 'rxjs';

import {ApiService, Transport} from '../../communication/api-service/api-service.interfaces';
import {AuthStack} from './auth-stack.interface';

import {AuthenticationRegistrationCoreService} from '../../authentication/authentication.registration.core.service';
import {setSessionStatus} from '../session.actions';
import {SESSION_EVENTS} from '../session.constants';
import {ClientContainerContextCoreService} from '../../platform/clientContainer/client-container-context.core.service';
import {SessionCoreService} from '../session.core.service';

const ZERO = 0;
const ONE = 1;

export class OpenAMStackCoreService implements AuthStack {
	protected model: any;
	private sessionService: SessionCoreService;
	private sessionListeners: Subscription[] = [];

	constructor(
		protected dispatch,
		protected logger: any,
		protected authenticationRegistrationService: AuthenticationRegistrationCoreService,
		protected apiService: ApiService,
		protected clientContainerContextCoreService: ClientContainerContextCoreService = null
	) {
		this.authenticationRegistrationService.whenAuthenticated(() => this.init());
	}

	public init(): void {
		this.setupListeners();
	}

	public destroy(): void {
		this.destroyListener();
	}

	public resetSession(): Promise<any> {
		return this.apiService.get('session/reset', undefined, undefined, Transport.REST);
	}

	public setSessionService(service: SessionCoreService): void {
		this.sessionService = service;
	}

	protected setSessionStatus(eventName): any {
		return setSessionStatus(eventName);
	}

	private setupListeners(): void {
		if (!this.clientContainerContextCoreService || !this.clientContainerContextCoreService.isOneApp()) {
			this.sessionListeners.push(this.apiService.listen(SESSION_EVENTS.PING_EXPIRING).subscribe(eventData => this.pingExpiring(eventData)));
			this.sessionListeners.push(this.apiService.listen(SESSION_EVENTS.EXTENDED).subscribe(eventData => this.extended(eventData)));
			this.sessionListeners.push(this.apiService.listen(SESSION_EVENTS.ENDED).subscribe(eventData => this.ended(eventData)));
			this.sessionListeners.push(this.apiService.listen(SESSION_EVENTS.RECONNECT).subscribe(eventData => this.reconnect(eventData)));
			this.sessionListeners.push(this.apiService.listen(SESSION_EVENTS.UNAVAILABLE).subscribe(eventData => this.unavailable(eventData)));
			this.sessionListeners.push(this.apiService.listen(SESSION_EVENTS.EXPIRED).subscribe(eventData => this.expired(eventData)));
			this.sessionListeners.push(this.apiService.listen(SESSION_EVENTS.EXPIRING).subscribe(eventData => this.expiring(eventData)));
		}
	}
	private destroyListener(): void {
		forEach(this.sessionListeners, listener => {
			listener.unsubscribe();
		});
		this.sessionListeners = [];
	}

	private pingExpiring(eventData): void {
		const gracePeriod = nth(eventData.args, ZERO);
		const extendable = nth(eventData.args, ONE);

		this.logger.info(SESSION_EVENTS.PING_EXPIRING, gracePeriod, extendable);

		this.apiService.broadcast('sso.isValid', undefined);
	}

	private expiring(eventData): void {
		const eventArgs = this.handleEvents(eventData, SESSION_EVENTS.EXPIRING);

		if (this.sessionService) {
			this.sessionService.expiringCallback(SESSION_EVENTS.EXPIRING, eventArgs);
		}
	}

	private extended(eventData): void {
		const eventArgs = this.handleEvents(eventData, SESSION_EVENTS.EXTENDED);

		if (this.sessionService) {
			this.sessionService.extendedCallback(SESSION_EVENTS.EXTENDED, eventArgs);
		}
	}

	private expired(eventData): void {
		const eventArgs = this.handleEvents(eventData, SESSION_EVENTS.EXPIRED);

		if (this.sessionService) {
			this.sessionService.expiredCallback(SESSION_EVENTS.EXPIRED, eventArgs);
		}
	}

	private ended(eventData): any {
		const eventArgs = this.handleEvents(eventData, SESSION_EVENTS.ENDED);

		if (this.sessionService) {
			this.sessionService.endedCallback(SESSION_EVENTS.ENDED, eventArgs);
		}
	}

	private reconnect(eventData): any {
		const eventArgs = this.handleEvents(eventData, SESSION_EVENTS.RECONNECT);

		if (eventData.args && eventData.args[ZERO]) {
			$('meta[name="csrf"]').attr('content', eventData.args[ZERO]);
		}
		if (this.sessionService) {
			this.sessionService.reconnectCallback(SESSION_EVENTS.RECONNECT, eventArgs);
		}
	}

	private unavailable(eventData): any {
		const eventArgs = this.handleEvents(eventData, SESSION_EVENTS.UNAVAILABLE);

		if (this.sessionService) {
			this.sessionService.unavailableCallback(SESSION_EVENTS.UNAVAILABLE, eventArgs);
		}
	}

	private handleEvents(eventData, eventName: string): any {
		const gracePeriod = nth(eventData.args, 0);
		const extendable = nth(eventData.args, 1);

		this.logger.info(eventName, gracePeriod, extendable);
		this.dispatch(this.setSessionStatus(eventName));

		return {gracePeriod, extendable};
	}
}
