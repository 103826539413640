import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {CustomIframeSlideoutComponent} from './custom-iframe-slideout/custom-iframe-slideout.component';
import {IframeSlideoutComponent} from './iframe-slideout.component';

const outlet = 'app-slideout';
const iframeSlideoutRoutes: Routes = [
	{
		path: 'customIframeSlideout/iframe-container/:serviceName/:action',
		outlet,
		component: CustomIframeSlideoutComponent
	},
	{
		path: 'legacySlideout/manualOpen/:sliderRoute',
		outlet,
		component: IframeSlideoutComponent,
		data: {openOnLoad: false}
	},
	{
		path: 'legacySlideout/:sliderRoute',
		outlet,
		component: IframeSlideoutComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(iframeSlideoutRoutes)],
	exports: [RouterModule]
})
export class IframeSlideoutRoutingModule {}
