import {keys} from 'lodash';

import {ReducersMapping} from '../redux/redux.interfaces';

import {ReducerRegistry} from '../redux/reducer.registry';
import {ACTION_TYPES} from '../redux/redux.constants';

export abstract class StoreManager<T> {
	constructor(protected reducerRegistry: ReducerRegistry<T>) {}
	
	
	protected initReducerSubscriber(): void {
		this.reducerRegistry.getReducers$().subscribe(reducerMapping => this.updateStoreReducers(reducerMapping));
	}
	
	protected registerReducers(newReducers: object): any {
		return {
			type: ACTION_TYPES.REGISTER_REDUCERS,
			payload: {reducers: keys(newReducers)}
		};
	}

	protected abstract updateStoreReducers(a: ReducersMapping<T>): void;
}
