import {get} from 'lodash';

import {FocusElementId} from './services/keyboard-navigation.interfaces';

import {isAnyFocusableElement} from './htmlElement.utility';
import {FOCUSABLE_ELEMENTS} from './utils.constants';

const NO_ELEMENTS = 0;
const FIRST = 0;

export class FocusOnShowBase {
	protected focusElementId: FocusElementId;
	protected focusDelay = 0;
	protected skipFocusIf = false;
	protected element: HTMLElement;
	protected cacheElement: HTMLElement;
	protected focusElement: HTMLElement;
	protected waitingFocusElementSelector: string;
	protected focusTimeout;

	protected getWaitingFocusElementSelector(currentFocusElementId: string | boolean): void {
		if (currentFocusElementId === true) {
			this.waitingFocusElementSelector = '[focus-on-show-here]';
		}
	}

	protected setFocusToElement(): void {
		const isVisible = this.isElementVisible();

		if (this.focusElementId === true) {
			this.setFocusOnTargetElement();
		} else if (!this.focusElement && isVisible) {
			clearTimeout(this.focusTimeout);
			this.focusTimeout = setTimeout(() => {
				this.focusElement = this.getFocusElement();

				if (this.focusElement && !this.skipFocusIf) {
					this.focusNow();
				}
			}, this.focusDelay);
		} else if (!isVisible) {
			this.focusElement = undefined;
		}
	}

	private isElementVisible(): boolean {
		return this.element && get(this.element, 'style.display') !== 'none';
	}

	private findElementBySelector(selectors: string, element: HTMLElement | Document = document): HTMLElement {
		const current = element.querySelectorAll<HTMLElement>(selectors);

		return current.length === NO_ELEMENTS ? undefined : current.item(FIRST);
	}

	private setFocusOnTargetElement(): void {
		let waitingFocusElement = this.findElementBySelector(this.waitingFocusElementSelector);
		const tabindex = waitingFocusElement ? parseInt(waitingFocusElement.getAttribute('tabindex'), 10) : NaN;

		if (Number.isNaN(tabindex) || tabindex < 0) {
			waitingFocusElement = this.findElementBySelector(FOCUSABLE_ELEMENTS, waitingFocusElement);
		}

		if (waitingFocusElement !== undefined) {
			clearTimeout(this.focusTimeout);
			this.focusTimeout = setTimeout(() => {
				waitingFocusElement.focus();
				this.waitingFocusElementSelector = null;
			}, this.focusDelay);
		}
	}

	private getFocusElement(): HTMLElement {
		if (!this.cacheElement) {
			this.cacheElement = this.findElementById() || this.findInputElement();
		}

		return this.cacheElement;
	}

	private findElementById(): HTMLElement {
		return this.focusElementId ? this.findElementBySelector(`#${this.focusElementId}`) : undefined;
	}

	private findInputElement(): HTMLElement {
		if (isAnyFocusableElement(this.element)) {
			return this.element;
		}
		return this.findElementBySelector(FOCUSABLE_ELEMENTS, this.element);
	}

	private focusNow = (): void => {
		this.focusElement.focus();
	};
}
