import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';
import {forEach, includes, isUndefined, keys, omit, omitBy} from 'lodash';

@Directive({selector: '[krn-ng-attributes]'})
export class AttributesDirective implements OnChanges {
	@Input('krn-ng-attributes') public attributes: object;

	constructor(private renderer: Renderer2, private element: ElementRef<HTMLElement>) {}

	public ngOnChanges(changes: SimpleChanges): void {
		const currentWithValues = omitBy(changes.attributes.currentValue, isUndefined);
		const diffKeys = keys(omit(changes.attributes.previousValue, keys(currentWithValues)));

		forEach(diffKeys, key => {
			if (!this.isTooltip(key)) {
				this.renderer.removeAttribute(this.element.nativeElement, key);
			}
		});

		forEach(this.attributes, (value, key) => {
			if (!isUndefined(value) && !this.isTooltip(key)) {
				this.renderer.setAttribute(this.element.nativeElement, key, value);
			}
		});
	}

	private isTooltip(key: string): any {
		return includes(['title', 'tooltip'], key);
	}
}
