import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {find, get, isEmpty, isEqual, map} from 'lodash';

import {MenuIntegrationService} from '../../../../../core/navBar/menu-integration.service';
import {MenuIntegrationParam} from '../../../../../core/navBar/models/menu-integration-param.model';
import {MenuIntegration} from '../../../../../core/navBar/models/menu-integrations.model';

@Component({
	selector: 'krn-ng-menu-integration',
	templateUrl: './menu-integration.html'
})
export class MenuIntegrationComponent implements OnChanges {
	@Input('menu-additional-data') public menuAdditionalData: any;
	@Input('menu-item-total') public menuItemTotal: number;
	@Input() public selectedValue: MenuIntegrationParam;

	public menuIntegrations: MenuIntegration[];

	constructor(private menuIntegrationService: MenuIntegrationService) {}

	public ngOnChanges(change: SimpleChanges): void {
		if (!isEqual(get(change, 'menuAdditionalData.currentValue'), get(change, 'menuAdditionalData.previousValue'))) {
			const updatedIntegations = this.menuIntegrationService.prepareMenuIntegrationsData(
				this.menuAdditionalData,
				get(change, 'menuAdditionalData.currentValue')
			);

			if (isEmpty(this.menuIntegrations)) {
				this.menuIntegrations = updatedIntegations;
			} else {
				map(this.menuIntegrations, item => {
					delete item.values;
					const updateItem = find(updatedIntegations, updatedIntegation => isEqual(item.parameter, updatedIntegation.parameter));

					item.values = updateItem.values;
				});
			}
		}
	}
}
