import {endsWith, filter, fromPairs, get, has, isEmpty, map, negate, overEvery, partialRight, replace, startsWith, toLower} from 'lodash';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';
import {CommonConst, Logger, ParsedData, Property, PropertyState} from './property.interface';

import {PreferencesCoreService} from '../platform/preferences/preferences.service';
import {PropertyStateCoreService} from './propertyState.core.service';

export class PropertyFileFactory {
	constructor(
		private CommonConstants: CommonConst,
		private propertyStateService: PropertyStateCoreService,
		private apiService: ApiService,
		private preferencesService: PreferencesCoreService,
		private logger: Logger = console,
		private promiseWrapper: PromiseConstructor = Promise
	) {}

	public loadPropertiesFromFileSystem(propertiesToLoad: Property[]): Promise<PropertyState> {
		const filteredProperties = filter(
			propertiesToLoad,
			(property: Property) => !this.propertyStateService.isFileLoaded(property.name) && this.validatePropertyFileParams(property)
		);

		if (isEmpty(filteredProperties)) {
			return this.promiseWrapper.resolve(this.propertyStateService.getPropertiesState());
		}

		return this.fetchFromFileSystem(filteredProperties);
	}

	private fetchFromFileSystem(propertiesToLoad: Property[]): Promise<PropertyState> {
		const locale = this.localeFromPreferences();
		const justLanguage = this.localeFromPreferences(true);

		return this.promiseWrapper
			.all(
				map(propertiesToLoad, (property: Property) => {
					let url = property.path + this.propertyNameWithLocale(property, locale);

					// it will try to find the file with the specified locale, falling back to just lang, then no locale
					return this.readFile(property.name, url)
						.catch(() => {
							url = property.path + this.propertyNameWithLocale(property, justLanguage);
							return this.readFile(property.name, url);
						})
						.catch(() => {
							url = property.path + property.name;
							return this.readFile(property.name, url);
						})
						.catch(() => {
							this.logger.error(this.CommonConstants.HTML_INFRASTRUCTURE_PROPERTY_FILE_ERROR_MESSAGE);
						});
				})
			)
			.then(() => this.propertyStateService.getPropertiesState());
	}

	private readFile(propertyName: string, url: string): Promise<void> {
		return this.apiService
			.find(url, undefined, Transport.REST, undefined, undefined, undefined, {requireAuthenticate: false})
			.then((response: string) => this.parseDataFromFile(propertyName, response))
			.then(response => this.propertyStateService.dispatchPropertyAction(response.fileName, response.properties));
	}

	private propertyNameWithLocale(property: Property, language: string): string {
		const propertyExtension = this.CommonConstants.HTML_INFRASTRUCTURE_PROPERTY_FILE_EXT;
		const defaultLocale = this.CommonConstants.HTML_INFRASTRUCTURE_PROPERTY_FILE_DEFAULT_LOCALE;

		if (language && language !== defaultLocale) {
			return replace(property.name, propertyExtension, `_${language}${propertyExtension}`);
		}
		return property.name;
	}

	private localeFromPreferences(justLanguage = false): string {
		const locale = get(this.preferencesService.getPreferences(), 'localePolicy');

		if (justLanguage) {
			return has(locale, 'languageCode') ? toLower(locale.languageCode) : '';
		}
		return has(locale, 'languageCode') && has(locale, 'countryCode') ? `${toLower(locale.languageCode)}_${locale.countryCode}` : '';
	}

	private parseDataFromFile(fileName: string, data: string): ParsedData {
		const isNotEmpty = negate(isEmpty);
		const isNotAComment = negate(partialRight(startsWith, '#'));
		const propertyRows = data
			.split('\n')
			.map(row => row.trim())
			.filter(row => overEvery(isNotAComment, isNotEmpty)(row as never))
			.map(line => {
				const split = line.split('=');
				const name = split[0].trim();
				const value = split[1].trim();

				return [name, value];
			});
		const rows = fromPairs(propertyRows);

		return {
			fileName,
			properties: rows
		};
	}

	private validatePropertyFileParams(params: Property): boolean {
		let isValid = true;

		if (isEmpty(params.path)) {
			this.logger.error('Property file "path" is not specified. The property file will not be loaded.', params);
			isValid = false;
		} else if (!endsWith(params.path, '/')) {
			this.logger.warn('Property file "path" is not ending with "/". It has been fixed. ', params);
			params.path += '/';
		}

		if (isEmpty(params.name)) {
			this.logger.error('Property file "name" is not specified. The property file will not be loaded.', params);
			isValid = false;
		}

		return isValid;
	}
}
