import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {noop} from 'lodash';

import {PropertyFactory} from '../../../../../core/property/property.factory';
import * as consts from '../../../../../core/offline/offline.constants';
import {PropertyFilterPipe} from '../../../../../providers/property/property.filter.pipe';
import {SlideOutHelperService} from '../../../../../services/slide-out-helper.service';
import {ActionItemBaseComponent, ActionItemOptions} from './action-item-base.component';
import {OfflineService} from '../../../offline/offline.service';

@Component({
	templateUrl: './action-item.template.html',
	providers: [PropertyFilterPipe, OfflineService]
})
export class NetworkActionItemComponent extends ActionItemBaseComponent implements OnDestroy {
	public static readonly DEFAULT = true;
	public static readonly actionItemName = 'NetworkActionItemComponent';
	public static readonly actionItemOptions: Partial<ActionItemOptions> = {
		id: 'networkSlow',
		icon: 'icon-k-offline',
		text: consts.TOOLTIP_KEY,
		title: consts.TOOLTIP_KEY,
		tooltip: consts.TOOLTIP_KEY,
		propertyFilePath: consts.PROPERTY_FILE_PATH,
		propertyFileName: consts.PROPERTY_FILE_NAME,
		visible: false,
		sticky: true
	};

	public onClick = noop;

	constructor(
		public propertyFactory: PropertyFactory,
		public propertyFilterPipe: PropertyFilterPipe,
		public route: ActivatedRoute,
		slideOutHelper: SlideOutHelperService,
		private offlineService: OfflineService,
		private changeRef: ChangeDetectorRef
	) {
		super(NetworkActionItemComponent.actionItemOptions, propertyFactory, propertyFilterPipe, route, slideOutHelper);

		this.offlineService.getDisplayObservable().subscribe(value => {
			this.options.visible = value;
			this.changeRef.markForCheck();
		});

		this.offlineService.initializeOfflineModal();
	}

	public ngOnDestroy(): void {
		this.offlineService.unsubscribeFromAll();
	}
}
