import {find, forEach, isFunction, keys, last, map, replace} from 'lodash';
import {fromEvent} from 'rxjs';

import {GlobalData} from '../../one-app/one-app-event-bus.interfaces';

import {getTopWfdWindow} from '../../iframe-framework/iframe-message-handler.utility';
import {SENDER_NAME} from '../../one-app/one-app-event-bus.constants';
import {LocalStorageCoreService} from './local-storage.service';
import {ngStorage} from '../services/localStorage/dim-local-storage.service';
import {IS_STORAGE_ENABLED} from './local-storage.constants';
import {CommonConstants} from '../constants/CommonConstants';

export const STORAGE_KEY_NAME = 'dim_storage_keys';
const DOMAINLIST: string[] = ['ess', 'schedule', 'timekeeping', 'forecasting', 'activities'];

export class OneAppDataCoreService extends LocalStorageCoreService {
	protected isDataSync = false;
	protected localStorage;
	protected topWfdWindow: Window;

	constructor(protected window: Window) {
		'ngInject';
		super();
		this.topWfdWindow = getTopWfdWindow(window);
		fromEvent(this.window, 'beforeunload').subscribe(() => {
			this.isDataSync = false;
		});
	}

	public syncData(): Promise<void> {

		// syncData only needs to be executed in ess/schedule/timekeeping/forecasting/activities
		if (!this.isPersonalizationDomain()) {
			return;
		}
		// if in angular, the key of this.localStorage includes `ngStorage-`, but in angularjs, the key is no `ngStorage-`
		const localKeys = this.getPersonalizationKey(this.getKeysWithoutNgStorage(keys(this.localStorage)));

		let oneAppKeys = [];

		return (this.topWfdWindow as any).OneAppCommon.DATA.getGlobalData(this.topWfdWindow, SENDER_NAME, {key: STORAGE_KEY_NAME}).then(
			response => {
				if (response.data && response.data.value) {
					oneAppKeys = this.getPersonalizationKey(this.getKeysWithoutNgStorage((this.topWfdWindow as any).OneAppCommon.UTIL.b64DecodeJSON(response.data.value)));
					forEach(localKeys, key => {
						if (oneAppKeys.indexOf(key) < 0 && !this.isLocalStorageBuiltInProperty(key)) {
							this.removeItem(replace(key, ngStorage, ''));
						}
					});
				}
				this.isDataSync = true;
				this.updateStorageKeys();
			},
			() => {
				this.isDataSync = true;
				this.updateStorageKeys();
			}
		);
	}

	public setGlobalData(data: GlobalData): void {
		data.value = (this.topWfdWindow as any).OneAppCommon.UTIL.b64EncodeJSON(data.value);
		(this.topWfdWindow as any).OneAppCommon.DATA.setGlobalData(this.topWfdWindow, SENDER_NAME, data);
	}

	public deleteGlobalData(key?: string): void {
		(this.topWfdWindow as any).OneAppCommon.DATA.deleteGlobalData(this.topWfdWindow, SENDER_NAME, {key});
	}

	protected updateStorageKeys(): void {
		if (this.isDataSync) {
			// angularjs apply changes to window.localStorage
			if (this.localStorage.$apply) {
				this.localStorage.$apply();
			}
			this.setGlobalData({key: STORAGE_KEY_NAME, value: this.getPersonalizationKey(keys(this.localStorage))});
		}
	}

	protected getKeysWithoutNgStorage = (keyList: string[]): string[] =>
		map(keyList, (key: string) => (key.includes(ngStorage) ? last(key.split(ngStorage)) : key));

	protected isLocalStorageBuiltInProperty = (key: string): boolean => isFunction(this.localStorage[key]) || key.includes(IS_STORAGE_ENABLED);

	protected getPersonalizationKey = (keyList: string[]): string[] => keyList.filter((key: string) => key.includes(CommonConstants.PERSONALIZATION_PREFIX));

	private isPersonalizationDomain():boolean {
		const domain = find(DOMAINLIST, (domainItem: string) => this.topWfdWindow.location.pathname.includes(domainItem));

		return !!domain;
	}
}
