import {Injectable} from '@angular/core';

import {UnsavedDataService} from '../../../services/unsaved-data-service';
import {OneAppUnsavedDataCoreService} from '../../../core/one-app/one-app-unsaved-data.core.service';
import {WindowRefService} from '../../../services/window-ref.service';
import {DefaultSliderActionHandler} from '../slide-out/slide-out-content/handlers/default-slider-action-handler';
import {SlideOutManagerService} from '../slide-out/slide-out-manager.service';

@Injectable({providedIn: 'root'})
export class OneAppUnsavedDataService extends OneAppUnsavedDataCoreService {
	constructor(
        protected windowRefService: WindowRefService,
        protected unsavedDataService: UnsavedDataService,
        protected slideOutManagerService: SlideOutManagerService
	) {
        super(windowRefService.nativeWindow, unsavedDataService, slideOutManagerService);
    }
}
