import {find, get, includes, map} from 'lodash';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';

import {isWfdMobileApp} from '../platform/services/DeviceConfig.utility';
import {fetchMenuData, setTitle} from './nav-bar.actions';

export class NavBarService {
	protected fetchMenuData = fetchMenuData;
	protected setTitle = setTitle;

	constructor(private apiService: ApiService, protected window: Window) {}

	public init(app: string, path: string): any {
		return dispatch => {
			const isMobileApp = isWfdMobileApp(this.window);
			const query = {suite: app || 'falcon', pathUrl: path};
			const uri = this.window.location.pathname + this.window.location.hash;

			dispatch(this.fetchMenuData());
			this.apiService
				.find('/navBar/menu', isMobileApp ? {...query, isMobileApp} : query, Transport.REST, false, {routeTo: 'bck'})
				.then(response => {
					dispatch(this.fetchMenuData(response));
					let subMenu = [];

					map(get(response, 'menu'), (item, index) => {
						subMenu = index ? subMenu.concat(get(item, 'menu')) : subMenu.concat(item);
					});
					dispatch(this.setTitle(get(find(subMenu, menuItem => includes(menuItem.uri, uri)), 'text')));
				},
				response => dispatch(this.fetchMenuData(undefined, response.error))
				);
		};
	}
}
