import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {BsModalRef} from 'ngx-bootstrap/modal';

import {PropertyFactory} from '../../../core/property/property.factory';
import {NewFeaturesService} from './new-features.service';

@Component({
	host: {class: 'h-100'},
	selector: 'krn-ng-new-features',
	templateUrl: './new-features.component.html',
	styleUrls: ['./new-features.component.less'],
	encapsulation: ViewEncapsulation.None
})
export class NewFeaturesComponent implements OnDestroy, OnInit {
	public doNotShowAgain = false;
	public newReleaseFeatureURL: SafeResourceUrl;

	constructor(
		public bsModalRef: BsModalRef,
		private sanitizer: DomSanitizer,
		private newFeaturesService: NewFeaturesService,
		private propertyFactory: PropertyFactory
	) {}

	public ngOnDestroy(): void {
		this.newFeaturesService.saveUserNewReleaseFeaturePreference(this.doNotShowAgain);
	}

	public async ngOnInit(): Promise<void> {
		await this.propertyFactory.loadProperties([
			{
				name: 'container_web-common_nav-bar_strings.properties',
				path: 'components/zed-core/properties/navBar/'
			}
		]);
		const helpContext = await this.newFeaturesService.getContextualHelpContext();
		const preferences = this.newFeaturesService.getNewReleaseFeaturePreference();

		this.newReleaseFeatureURL = this.getNewFeaturesUrl(this.newFeaturesService.createNewFeatureContentURL(helpContext, preferences));
	}

	public onClose(): void {
		this.bsModalRef.hide();
	}

	private getNewFeaturesUrl(url: string): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
