import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import {loadRemoteModule} from '@angular-architects/module-federation';
import {has} from 'lodash';
import {NGXLogger} from 'ngx-logger';
import {Subject, filter, fromEvent, take, takeUntil} from 'rxjs';

const ONE = 1;

@Component({
	selector: 'krn-ng-mfe-host',
	templateUrl: './mfe-host.component.html',
	styleUrls: ['./mfe-host.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfeHostComponent implements OnChanges, OnDestroy {
	@ViewChild('vc', {read: ViewContainerRef, static: true})
	public vc: ViewContainerRef;

	@Input() public metaData: any;
	@Input() public props: any;
	@Input() public loaderHeight: string;

	public loading = true;

	private unsubscribe$ = new Subject<void>();

	constructor(private cd: ChangeDetectorRef, protected logger: NGXLogger) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (has(changes, 'metaData') && this.metaData !== undefined) {
			this.clearMfeContainer();

			if (this.metaData === null) {
				this.hideLoader();
			} else {
				this.listenForMfeCompleted();
				this.renderMfe();
			}
		}
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next(undefined);
		this.unsubscribe$.complete();
	}

	private clearMfeContainer = (): void => {
		this.unsubscribe$.next(null);
		this.vc.clear();
	};

	private hideLoader = (error?): void => {
		this.loading = false;
		this.cd.detectChanges();

		if (error) {
			this.logger.error(error);
		}
	};

	private listenForMfeCompleted = (): void => {
		fromEvent(window, 'mfe:completed')
			.pipe(
				takeUntil(this.unsubscribe$),
				filter(event => (event as any)?.detail?.mfeInstanceId === this.props?.mfeInstanceId),
				take(ONE)
			)
			.subscribe(() => this.hideLoader());
	};

	private renderMfe = async (): Promise<void> => {
		const remoteOptions = this.metaData;

		await loadRemoteModule(remoteOptions).catch(this.hideLoader);

		const props = this.props;
		const element = document.createElement(remoteOptions.elementName, props);

		element.dataset.props = JSON.stringify(props);
		this.vc.element.nativeElement.appendChild(element);
		this.cd.detectChanges();
	};
}
