import {handleActions} from 'redux-actions';

import {setSessionStatus} from '../../zed-core/session/session.actions';
import {SessionState} from '../../zed-core/session/session.state';

export const SESSION_DEFAULT_STATE: SessionState = {
	status: ''
};

export const sessionReducer = handleActions(
	{
		[setSessionStatus]: (state, action) => ({...state, status: action.payload ? action.payload.status : action.status})
	},
	SESSION_DEFAULT_STATE
);
