import {camelCase, get, includes, keys, uniqueId} from 'lodash';
import {fromEvent, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {ACTION_TYPES} from '../../../../../core/generic/slideOutContainer/slideout.constants';
import {sendPostMessage} from '../../../../../core/iframe-framework/iframe-message-handler.utility';
import {WindowRefService} from '../../../../../services/window-ref.service';
import {ContainerSemaphoreService} from './container-semaphore.service';
import {SlideOutActionService} from '../../../../../services/slideout-action.service';
import {OtherActionService} from '../../../iframe-slideout/other-action.service';

export abstract class BaseSliderActionHandler {
	public onDestroy = this.destroyFn.bind(this);
	public slideOutId: string;
	public isDataDirty = false;
	public shouldDispatch = false;
	private destroy$: Subject<void> = new Subject<void>();
	private closeObs: Subject<void> = new Subject<void>();

	constructor(
		public window: WindowRefService,
		public otherActionService: OtherActionService,
		public semaphoreService: ContainerSemaphoreService,
		public slideOutActionService: SlideOutActionService,
	) {
		fromEvent(this.window.nativeWindow, 'message').pipe(takeUntil(this.destroy$)).subscribe(this.onDestroy);

		fromEvent(this.window.nativeWindow, 'message')
			.pipe(filter((event: any) => event?.data?.configuration?.data?.type === 'updateDirty'))
			.subscribe((event: any) => {
				this.isDataDirty = event.data.configuration.data.isDataDirty;
			});

		this.initObservables();
		this.slideOutId = uniqueId('slideout-');
	}

	public readonly close = (): void => {
		const window = this.window.nativeWindow;
		const iFrameSlider = window.frames.angularIframeSlider;

		if (this.isDataDirty && iFrameSlider) {
			sendPostMessage(iFrameSlider.contentWindow, window.location.origin, 'info', 'navigation', {type: 'close'}, 'wfd');
		} else {
			this.closeObs.next();
		}
	};

	protected sendData(action, internal = false): void {
		const component = internal ? ACTION_TYPES.SLIDEOUT_CONTAINER : ACTION_TYPES.SLIDEOUT_COMPONENT;

		if (this.shouldDispatch) {
			this.handleOtherAction(action);
		} else if (this.window.nativeWindow.parent) {
			this.window.nativeWindow.parent.postMessage({component, action}, '*');
		}
	}

	private handleOtherAction(action): void {
		const actions = this.slideOutActionService.actions;
		const type = get(action, 'type', '');

		if (includes(keys(actions), camelCase(type))) {
			this.slideOutActionService.dispatch(action);
		} else {
			this.otherActionService.dispatch(action);
		}
	}

	private initObservables(): void {
		this.semaphoreService
			.getSemaphore()
			.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				this.shouldDispatch = value;
			});

		this.closeObs.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.sendData(this.slideOutActionService.actions.close(this.slideOutId), !this.shouldDispatch);
		});
	}

	private destroyFn(event): void {
		if (event.data === ACTION_TYPES.SLIDEOUT_DESTROY) {
			this.destroy$.next();
		}
	}
}
