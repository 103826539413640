import {Component, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {get, head, isUndefined} from 'lodash';

import {KrnMessageDetailsModel} from '../../../../../../core/generic/message/message.interface';
import {HelpContext} from '../../../../../../core/navBar/contextual-help/contextual-help.interfaces';
import {SearchTermChange} from '../../../../../../core/search/search.interfaces';

import {UserInfoService} from '../../../../../../core/framework/services/user-info.service';
import {showLocaleMsg} from '../../../../../../core/navBar/contextual-help/contextual-help.utils';
import {
	AggregationsType,
	ContextualHelpActions,
	CreatedDateValues
} from '../../../../../../core/navBar/contextual-help/contextual-help-search.constants';
import {
	LOCALE_INFO_PROPERTY,
	MIN_STRING_LENGTH,
	PANEL_TITLE_PROPERTY,
	SEARCH_LENGTH_VALIDATION_PROPERTY
} from '../../../../../../core/navBar/contextual-help/contextual-help.constants';
import {PropertyFilterPipe} from '../../../../../../providers/property/property.filter.pipe';
import {ReturnFocusElementDirective} from '../../../../../../utils/return-focus-element/return-focus-element.directive';
import {DefaultSliderActionHandler} from '../../../../slide-out/slide-out-content/handlers/default-slider-action-handler';
import {SlideOutComponent} from '../../../../slide-out/slide-out.component';
import {ContextualHelpService} from './contextual-help.service';
import {ContextualHelpSearchResultComponent} from '../contextual-help-search-result/contextual-help-search-result.component';

const RESULTS_PER_PAGE = 50;
const RESULTS_START = 0;
const DELAY_TIME_OUT = 150;

@Component({
	selector: 'krn-ng-contextual-help',
	templateUrl: './contextual-help.component.html',
	styleUrls: ['./contextual-help.component.less']
})
export class ContextualHelpComponent implements OnInit {
	@ViewChild('helpInfoMsg', {read: ReturnFocusElementDirective})
	public returnFocusElement: ReturnFocusElementDirective;

	@ViewChild(SlideOutComponent)
	public slideOutContainer: SlideOutComponent;

	@ViewChild('searchResult')
	public searchResult: ContextualHelpSearchResultComponent;

	public allowedByFAP: boolean;
	public communityHelpBeingSearched = false;
	public componentSrc: SafeUrl;
	public contentBeingRefined = false;
	public helpContext: HelpContext;
	public inPageMessages: KrnMessageDetailsModel = undefined;
	public loading: boolean;
	public refineOpened;
	public searchText: string;
	public subscription;
	public validationMessage: KrnMessageDetailsModel;

	constructor(
		private sanitizer: DomSanitizer,
		private router: Router,
		public actionHandler: DefaultSliderActionHandler,
		private propertyFilter: PropertyFilterPipe,
		private contextualHelpService: ContextualHelpService,
		private userInfoService: UserInfoService
	) {
		this.helpContext = get(router.getCurrentNavigation(), 'extras.state');
	}

	public async ngOnInit(): Promise<void> {
		if (this.helpContext) {
			this.componentSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.helpContext.contextualPageUrl);
			this.helpContext.title = this.propertyFilter.transform(PANEL_TITLE_PROPERTY);
		}

		this.allowedByFAP = await this.contextualHelpService.checkSearchFAP();
		if (this.allowedByFAP) {
			this.contextualHelpService.createStore();
			this.subscription = this.contextualHelpService.subscribeToStore(this.subscribeSearchResultChange);
		}
	}

	public onSlideOutLoad(): void {
		setTimeout(() => {
			if (this.helpContext) {
				this.slideOutContainer.openSlideOut();
			} else {
				this.slideOutContainer.close();
			}
		});
	}

	public browseAllHelp(): void {
		this.slideOutContainer.close();
		setTimeout(() => {
			window.open(this.helpContext.landingPageUrl);
		}, DELAY_TIME_OUT);
	}

	public onSearchTermChange(searchTerm: string): void {
		if (this.validationMessage) {
			this.resetMessage();
		}
	}

	public async triggerSearch(searchTermChange: SearchTermChange): Promise<void> {
		try {
			this.searchText = searchTermChange.searchTerm;

			if (searchTermChange.startSearch) {
				if (isUndefined(this.searchText) || this.searchText.length < MIN_STRING_LENGTH) {
					const messageText = this.propertyFilter.transform(SEARCH_LENGTH_VALIDATION_PROPERTY, [MIN_STRING_LENGTH]);

					this.validationMessage = {
						messageType: 'error',
						message: {messageText},
						options: {showCloseButton: true}
					};
					return;
				}

				this.resetMessage();
				this.communityHelpBeingSearched = true;
				if (this.searchResult) {
					this.searchResult.resetNewSearch();
				}

				const searchRequest = {
					searchString: this.searchText,
					resultsPerPage: RESULTS_PER_PAGE,
					from: RESULTS_START,
					sortby: '_score',
					orderBy: 'desc',
					aggregations: [{type: AggregationsType.CreatedDate, filter: [CreatedDateValues.AllTime]}]
				};

				this.contextualHelpService.communitySearchRequest = searchRequest;
				const results = await Promise.all([
					showLocaleMsg(this.userInfoService),
					this.contextualHelpService.search(searchRequest, ContextualHelpActions.Loading, ContextualHelpActions.Search)
				]);

				if (head(results)) {
					const messageText = this.propertyFilter.transform(LOCALE_INFO_PROPERTY);

					this.inPageMessages = {
						messageType: 'info',
						message: {messageText},
						options: {showCloseButton: true}
					};
				}
			}
		} catch (error) {
			const messageText = this.propertyFilter.transform('html.navbar.contextualHelp.search.error');

			this.inPageMessages = {
				messageType: 'error',
				message: {messageText},
				options: {showCloseButton: true}
			};
		}
	}

	public resetMessage = (): void => {
		this.validationMessage = undefined;
		this.inPageMessages = undefined;
	};

	public toggleCommunitySearch(): void {
		this.communityHelpBeingSearched = !this.communityHelpBeingSearched;
	}

	public markRefineClosed(): void {
		this.searchResult.markRefineClosed();
	}

	public onCloseInfoMsg(): void {
		this.returnFocusNow();
		this.inPageMessages = undefined;
	}

	public setContentBeingRefined(beingRefined: boolean): void {
		this.contentBeingRefined = beingRefined;
	}

	private subscribeSearchResultChange = (state): void => {
		if (isUndefined(state.refineOpened)) {
			return;
		}
		this.loading = state.loading;
		this.refineOpened = state.refineOpened;
	};

	private returnFocusNow = (): void => (this.returnFocusElement ? this.returnFocusElement.returnFocusNowEvent() : undefined);
}
