export const MINUS_ONE = -1;
export const INDEX_NOT_FOUND = -1;
export const ZERO = 0;
export const ONE = 1;
export const STRING = 'string';
export const NAVIGATION_EXCLUSION = ['close_popup', 'close_slider'];
export const ALLOWED_MESSAGE_TYPE = ['error', 'success', 'warning'];
export const MENU_ID = 'mainMenu';
export const EXTERNAL_SERVICE_IDENTIFIERS = 'external-services';
export const EVENT_BROADCAST = 'event_broadcast';
export const CONFIGURATION_URL = 'configuration.url';
export const SYSTEM = '_system';
export const BLANK = '_blank';
export const WFD_URL = '/wfd';
export const WFD_HOME_URL = `${WFD_URL}/home`;
export const WFD_UNAUTHORIZED_URL = `${WFD_URL}/unauthorized`;
export const REM_NUM = 16;
export const MARGIN_TOP = 'margin-top';

export const ACTIONS = {
	NAVIGATION: 'navigation',
	MESSAGE: 'message',
	PING: 'ping',
	DIRTY_STATE: 'dirty_state',
	LOGIN_SUCCESS: 'login_success',
	AUTHNSSID: 'authn_ssid',
	BROADCAST: 'event_broadcast',
	STATE: 'state'
};

export const NAVIGATION_ACTION_TYPE = {
	POPUP: 'POP_UP',
	SLIDER: 'SLIDER',
	NEWTAB: 'NEW_TAB',
	NAVIGATE: 'NAVIGATE',
	PORTAL: 'PORTAL',
	CLOSE_POPUP: 'CLOSE_POPUP',
	CLOSE_SLIDER: 'CLOSE_SLIDER',
	UPDATE: 'UPDATE'
};
