export const DEVICE_MEDIA_QUERY = {
	ORIENTATION_PORTRAIT: '(orientation: portrait)',
	ORIENTATION_LANDSCAPE: '(orientation: landscape)',
	MIN_WIDTH_PORTRAIT: '(min-width:768px)',
	MIN_WIDTH_LANDSCAPE: '(min-width:1024px)'
};

export const DEVICES = {
	DESKTOP: 'desktop',
	TABLET: 'tablet',
	PHONE: 'phone'
};

export const MOBILE_OS = {
	ANDROID: 'android',
	IOS: 'ios',
	UNKNOWN: 'unknown'
};

export const USER_AGENT = {
	CHROME: 'Chrome',
	FIREFOX: 'Firefox',
	SAFARI: 'Safari'
};

export const TOUCHPOINT = 1;
