import {NGXLogger} from 'ngx-logger';

import {PreferencesCoreService} from '../core/platform/preferences/preferences.service';
import {DataService} from '../services/communication/data.service';
import {PreferencesService} from '../services/preference.service';
import {SessionStorageService} from '../services/session-storage.service';
import {WindowRefService} from '../services/window-ref.service';

export function getPreferencesService(
	logger: NGXLogger,
	windowService: WindowRefService,
	sessionStorageService: SessionStorageService,
	dataService: DataService
): any {
	return new PreferencesService(logger, windowService.nativeWindow, sessionStorageService, dataService);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PreferencesServiceProvider = {
	provide: PreferencesCoreService,
	deps: [NGXLogger, WindowRefService, SessionStorageService, DataService],
	useFactory: getPreferencesService
};
