export const MESSAGE_KEY = 'message.messageText';
export const MESSAGE_TEXT = 'messagemodel.messageText';
export const NAME = 'name';
export const MESSAGE_DELAY = 50;
export const REM_NUM = 16;
export const SPACE_NUM = 10;
export const BANNER_HEIGHT = 45;
export const NAVBAR_HEIGHT = 40;
export const NO_OFFSET_TOP = 0;
export const THEME_MESSAGE_KEY = 'html.commonInfra.platform.Branding.Preview.Warning';
