<div class="popover-button" (keydown)="onKeyDown($event)" (keyup.enter)="onMenuClosed()" #popoverBtn>
	<div
		class="popover-button-container"
		[containerClass]="containerClass"
		(onHidden)="onPopoverHidden()"
		(onShown)="onPopoverShown()"
		[container]="container"
		[popover]="attrs?.disabled ? '' : openTemplate"
		[placement]="alignment"
		[outsideClick]="true"
		[adaptivePosition]="adaptivePosition"
		#popover="bs-popover">
		<krn-ng-button
			(buttonBlurred)="onButtonBlurred()"
			[buttonText]="buttonText"
			[classes]="classes"
			[icon]="icon"
			[alignment]="buttonTextAlignment"
			[attrs]="buttonAttrs">
		</krn-ng-button>
	</div>
	<div *ngIf="attrs && attrs['aria-labelledby']" aria-hidden="true" class="sr-only" [id]="'describe-icon-' + attrs?.id || fallbackId">
		{{ ariaLabel() }}
	</div>
</div>
<ng-template #openTemplate>
	<div
		krn-ng-modal-keyboard-navigation
		[modalRole]="modalRole"
		(blurCallback)="onMenuBlur()"
		(closeCallback)="onMenuClosed(true)"
		(click)="delayClosePopover()"
		(enterCallback)="onMenuClosed()"
		(mouseup)="onMenuMouseUp()"
		(keyup.enter)="onMenuEntered()">
		<button *ngIf="isSmall" class="sr-only" [attr.aria-label]="'popover.first.item' | property" (focus)="returnFocus('bottom')"></button>
		<div ngClass="modal-header align-items-center {{ renderType }}" (click)="onHeaderClick()">
			<div class="modal-title">{{(label | property) || label}}</div>
			<krn-ng-button
				krn-ng-focus-on-show
				icon="icon-k-close"
				[attrs]="{'aria-label': 'popover.fullScreen.close' | property, id: closeButtonId}"
				(buttonClicked)="hidePopover()">
			</krn-ng-button>
		</div>
		<div krn-ng-scroll-detector (scrollChecked)="onScrollChecked($event)">
			<ng-content></ng-content>
		</div>
		<button *ngIf="isSmall" class="sr-only" [attr.aria-label]="'popover.last.item' | property" (focus)="returnFocus('top')"></button>
	</div>
	<div
		*ngIf="interactive"
		aria-live="polite"
		[attr.aria-label]="'popoverContainer.expanded.label' | property: [ariaLabel()]"
		[attr.aria-hidden]="!enableAnnounceExpanded"></div>
</ng-template>
