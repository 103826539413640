import {flatten, includes, isEmpty, isString, isUndefined, size, split} from 'lodash';

import {Classes, Messages, PropertiesApi} from './loader.interfaces';

import {PropertyFactory} from '../../property/property.factory';
import {BOUNDING_BOX} from './loader.constants';

export {loadProperties, selectClasses, selectSpinnerAriaLabel, selectSpinnerText, speakComplete, speakStarted};

const LAST_MESSAGE = 1;
let defLoaderMessages;
let propertiesPromise;

function defaultLoaderMessages(propertiesApi: PropertiesApi): string[] {
	if (!defLoaderMessages) {
		defLoaderMessages = [propertiesApi.defFirstLoaderProp(), propertiesApi.defSecondLoaderProp(), propertiesApi.defThirdLoaderProp()];
	}

	return defLoaderMessages;
}

function getMessageFromMessages(messages: Messages, messageOffset: number, propertiesApi: PropertiesApi): string | any {
	if (isString(messages)) {
		return messages;
	}

	const messageList = isEmpty(messages) ? defaultLoaderMessages(propertiesApi) : messages;
	const offset = messageOffset < size(messageList) ? messageOffset : size(messageList) - LAST_MESSAGE;

	return messageList[offset];
}

function loadProperties(propertyFactory: PropertyFactory): Promise<any> {
	if (isUndefined(propertiesPromise)) {
		propertiesPromise = propertyFactory.loadProperties([
			{
				name: 'container_web-common_loader_strings.properties',
				path: 'components/zed-core/properties/generic/loader/'
			}
		]);
	}
	return propertiesPromise;
}

function selectClasses(showStatusText: boolean, classes: Classes): Classes {
	const cls = (isString(classes) ? split(classes, ' ') : classes) || [];

	return !showStatusText || includes(cls, BOUNDING_BOX) ? classes : flatten([cls, BOUNDING_BOX]);
}

function selectSpinnerAriaLabel(text: string, propertiesApi: PropertiesApi): string {
	return text || propertiesApi.defFirstLoaderProp();
}

function selectSpinnerText(showStatusText: boolean, messages: Messages, propertiesApi: PropertiesApi, messageOffset = 0): string {
	return showStatusText ? getMessageFromMessages(messages, messageOffset, propertiesApi) : undefined;
}

function speakComplete(text: string, propertiesApi: PropertiesApi): string {
	return text ? propertiesApi.speakCompleteProp(text): propertiesApi.defSpeakCompleteProp();
}

function speakStarted(text: string, propertiesApi: PropertiesApi): string {
	return text ? propertiesApi.speakStartedProp() : propertiesApi.defSpeakStartedProp();
}
