<ng-container *ngIf="showAsNative; then singleSelectNativePicker; else singleSelectPopoverPicker"></ng-container>
<ng-template #singleSelectNativePicker>
	<krn-ng-single-select-native-picker
		[alignment]="alignment"
		[attrs]="attrs"
		[classes]="classes"
		[data]="data"
		[dataLabel]="dataLabel"
		[label]="label"
		[(selected)]="selected"
		[toolTipLabel]="toolTipLabel"
		(selectedChange)="onSelectedChange($event)">
	</krn-ng-single-select-native-picker>
</ng-template>
<ng-template #singleSelectPopoverPicker>
	<krn-ng-single-select-popover-picker
		[alignment]="alignment"
		[adaptivePosition]="adaptivePosition"
		[attrs]="attrs"
		[classes]="classes"
		[containerClass]="containerClass"
		[data]="data"
		[dataLabel]="dataLabel"
		[label]="label"
		[modalRole]="modalRole"
		renderType="alwaysFitContainer"
		[(selected)]="selected"
		[toolTipLabel]="toolTipLabel"
		(clickPopoverButton)="clickPopoverButton($event)"
		(selectedChange)="onSelectedChange($event)">
	</krn-ng-single-select-popover-picker>
</ng-template>
