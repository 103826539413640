import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, map} from 'rxjs';
import {camelCase, forIn, get, set} from 'lodash';

import {NAV_BAR_DEFAULT_STATE, navBarReducerHelper} from '../../../../core/navBar/nav-bar.reducer';
import {NavBarState} from '../../../../core/navBar/nav-bar.state';
import {actionsHelper} from '../../../../core/navBar/nav-bar.actions';

@Injectable({providedIn: 'root'})
export class NavbarActionService {
	public actions;
	private navbarSource = {...NAV_BAR_DEFAULT_STATE};
	private navbarSubject = new BehaviorSubject({...NAV_BAR_DEFAULT_STATE});

	constructor() {
		this.actions = this.getActions();
	}

	public select = (path: string[]): Observable<NavBarState[keyof NavBarState]> => this.navbarSubject.pipe(map(state => get({navBar: state}, path)));

	public dispatch = (action: any): void => {
		this.navbarSource = get(navBarReducerHelper(), action.type)(this.navbarSource, action);
		this.navbarSubject.next(this.navbarSource);
	};

	private getActions = (): Partial<any> => {
		const actions = {};
	
		forIn(actionsHelper(), (val: (...params) => any, key: string) => {
			set(actions, camelCase(key), (...params) => ({type: key, payload: val(...params)}));
		});
		return actions;
	};
}
