import {ActionReducerMap} from '@ngrx/store';

import authenticationReducer from '@kronos/zed/src/lib/core/authentication/authentication.reducer';
import {userInfoReducer} from '@kronos/zed/src/lib/core/framework/services/user-info.reducer';
import {slideoutReducer} from '@kronos/zed/src/lib/core/generic/slideOutContainer/slideout.reducer';
import {navBarReducer} from '@kronos/zed/src/lib/core/navBar/nav-bar.reducer';
import {propertyReducer} from '@kronos/zed/src/lib/core/property/property.reducer';
import {sessionReducer} from '@kronos/common/components/session/core/session.reducer';

import {kioskReducer} from './kiosk/core/kiosk.reducer';

export function actionReducerMapFactory(): ActionReducerMap<any, any> {
	return {
		authentication: authenticationReducer,
		navBar: navBarReducer,
		property: propertyReducer,
		session: sessionReducer,
		slideout: slideoutReducer,
		userInfo: userInfoReducer,
		kiosk: kioskReducer
	};
}
