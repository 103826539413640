import {every, forIn, get, has, includes, isEmpty, isObject, isUndefined, keys, startsWith} from 'lodash';

import {Configuration, EventBroadcastSchemaToValidate, EventData, SchemaToValidate, UrlObj, WindowLocation} from './iframe-framework.interface';
import {AllowedActions, AllowedServices, AllowedType} from './post-message.interfaces';

import {BLANK, EVENT_BROADCAST, INDEX_NOT_FOUND, STRING, SYSTEM, WFD_URL, ZERO} from './iframe-framework.constant';

export const postMessage = <T>(contentWindow: any, target: string, eventData: T): void => {
	if (!isUndefined(contentWindow)) {
		contentWindow.postMessage(eventData, target);
	}
};

export const initializeMessageListener = (window, msessageListener: (event) => void): void => {
	window.addEventListener('message', msessageListener);
};

export const removeMessageListener = (window, messageListener: (event) => void): void => {
	window.removeEventListener('message', messageListener);
};

export const sendPostMessageWithData = (contentWindow: any, target: string, eventData: EventData): void => {
	if (
		!isValidData(eventData) ||
		!includes(AllowedActions, eventData.action) ||
		!includes(AllowedServices, eventData.configuration.serviceName) ||
		!includes(AllowedType, eventData.type) ||
		!isFullUrl(target)
	) {
		// not a valid message type
		return;
	}
	postMessage<EventData>(contentWindow, target, eventData);
};

export const sendPostMessage = (contentWindow: any, target: string, type: string, action: string, data: any, serviceName: string): void => {
	const eventData = {
		type,
		action,
		configuration: {
			data,
			serviceName
		}
	};

	sendPostMessageWithData(contentWindow, target, eventData);
};

export const sendPostMessageToFrameWithAction = (data:any, type:string, action:string): void => {
	const childIframe : any = document.querySelectorAll('iframe[id*= portal-frame]');
	let contentWindow = {};
	let target = '';

	if (!isEmpty(childIframe) && !isUndefined(childIframe[0].src)) {
		target = childIframe[0].src.slice(0, childIframe[0].src.indexOf('/', 8)); // get target url from iframe src
		contentWindow = childIframe[0].contentWindow;
		sendPostMessage(contentWindow, target, 'close_popup', 'dirty_popup_required', data, 'wfd');
	}
};

export const openNewTab = (window, configuration: Configuration): void => {
	window.open(configuration.url, (window as any).cordova ? SYSTEM : BLANK);
};

export const getWindowInstance = (window): any => (!window || window.parent === window) ? window : window.parent;

export const getTopWfdWindow = (window): any => {
    let topWfdWindow: Window;

    try {

        if (window.parent.location.href && window.parent !== window) {
            return getTopWfdWindow(window.parent);
        }
        topWfdWindow = window;
    } catch {
        topWfdWindow = window;
    }
    return topWfdWindow;
};

export const setLocationUrl = (window, href: string, useCurrentWin = false): void => {
	const win = useCurrentWin ? window : getWindowInstance(window);

	win.location.href = href;
};

/*
* validate the post message JSON, only check keys and if keys has object iterate again to match the schema
* @param data{Object}, schema{Object}
* @return boolean
* */
export const isValidPostMessage = (data: object, schema: object): boolean => {
	const schemaKeys = keys(schema);

	return every(schemaKeys, key => {
		const hasData = has(data, key);

		return hasData && isObject(schema[key]) ? isObject(data[key]) && isValidPostMessage(data[key], schema[key]) : hasData && typeof (data[key]) === schema[key];
	});
};


export const isFullUrl = (url: string): boolean => {
	const URL_REGEX = /^https?:\/\/\.*/i;

	return URL_REGEX.test(url);
};

export const isValidData = (data): boolean => {
	const schemaToValidate: SchemaToValidate = {
		type: STRING, // slider/new-tab/close-tab/popup/close-popup/portal|error/success/warning
		action: STRING, // navigation/dirtystate/message/token_register
		configuration: {
			serviceName: STRING
		}
	};
	const eventBroadcastSchemaToValidate: EventBroadcastSchemaToValidate = {
		type: STRING,
		action: STRING,	// event_broadcast
		configuration: {
			...schemaToValidate.configuration,
			event: STRING
		}
	};
	const schema = get(data, 'action') === EVENT_BROADCAST ? eventBroadcastSchemaToValidate : schemaToValidate;

	return isObject(data) && isValidPostMessage(data, schema);
};

export const getUrlObj = (anchorNode: HTMLAnchorElement, windowLocation: WindowLocation): UrlObj => ({
    protocol: anchorNode.protocol || windowLocation.protocol,
    host: anchorNode.hostname || windowLocation.hostname
});

export const getUrlWithQueryParams = (url: string, key: string, value: any): string => {
	const urlRegex = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
	const hashIndex = url.indexOf('#');
	const hashUri = hashIndex === INDEX_NOT_FOUND ? '' : url.substring(hashIndex);
	let uri = hashIndex === INDEX_NOT_FOUND ? url : url.substring(ZERO, hashIndex);
	const localeParam = `${(includes(uri, '?') ? '&' : '?') + key}=${value}`;

	uri = uri.match(urlRegex) ? uri.replace(urlRegex, `$1${key}=${value}$2`) : uri + localeParam;
	return uri + hashUri;
};

export const getUrlWithQueryParamList = (url: string, params): string => {
	let currUrl = url;

	forIn(params, (value, key) => (currUrl = getUrlWithQueryParams(currUrl, key, value)));
	return currUrl;
};

export const isWFDHome = (window: Window): boolean => startsWith(get(window, 'location.pathname'), WFD_URL);
