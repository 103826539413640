import * as jQuery from 'jquery';
import {size} from 'lodash';

import {KeyNavService} from './keyboard-navigation.interfaces';

import {keys} from '../../constants/keys.constant';
import {EVENTS_HANDLERS} from '../utils.constants';

const $: any = jQuery;
const FIRST = 0;
const ONE_ITEM = 1;
const enum TabState {
	ignoreShift,
	noShift,
	withShift
}

export class TabsNavigationService implements KeyNavService {
	private firstTabElement: JQuery;
	private lastTabElement: JQuery;

	constructor(private focusableElements: HTMLElement[], private noWrap: boolean) {}

	public initialize(): void {
		this.firstTabElement = this.getNode(FIRST);
		if (!this.noWrap) {
			if (size(this.focusableElements) > ONE_ITEM) {
				this.lastTabElement = this.getNode(this.focusableElements.length - ONE_ITEM);

				this.firstTabElement.on(EVENTS_HANDLERS.KEYDOWN, this.firstTabElementHandler);
				this.lastTabElement.on(EVENTS_HANDLERS.KEYDOWN, this.lastTabElementHandler);
			} else {
				this.firstTabElement.on(EVENTS_HANDLERS.KEYDOWN, this.firstAndLastTabElementHandler);
			}
		}
	}

	public destroy(): void {
		if (this.firstTabElement) {
			this.firstTabElement.off(EVENTS_HANDLERS.KEYDOWN, this.lastTabElement ? this.firstTabElementHandler : this.firstAndLastTabElementHandler);
			this.firstTabElement = undefined;
		}
		if (this.lastTabElement) {
			this.lastTabElement.off(EVENTS_HANDLERS.KEYDOWN, this.lastTabElementHandler);
			this.lastTabElement = undefined;
		}
	}

	public getNode(index): any {
		return $(this.focusableElements[index]);
	}

	private firstAndLastTabElementHandler = (event): any => this.tabHandler(event, TabState.ignoreShift);

	private firstTabElementHandler = (event): any => this.tabHandler(event, TabState.withShift, this.lastTabElement);

	private lastTabElementHandler = (event): any => this.tabHandler(event, TabState.noShift, this.firstTabElement);

	private tabHandler = (event: KeyboardEvent, withShift: TabState, focusableElement?: JQuery): void => {
		if (event.code === keys.TAB && this.matchShiftState(event, withShift)) {
			event.preventDefault();
			if (focusableElement) {
				focusableElement.trigger('focus');
			}
		}
	};

	private matchShiftState = (event: KeyboardEvent, withShift: TabState): boolean =>
		withShift === TabState.ignoreShift || withShift === this.toTabState(event);

	private toTabState = (event: KeyboardEvent): TabState => (event.shiftKey ? TabState.withShift : TabState.noShift);
}
