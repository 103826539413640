import * as jquery from 'jquery';

import {AccessibilitySpeakerConfig} from '../../core/platform/services/accessibility-speaker.interface';

import {defaultConfig} from '../../core/platform/accessibility/accessibility-speaker.constant';
import {readForSR} from '../../core/platform/accessibility/accessibility-speaker.utility';

const $: JQueryStatic = jquery;


export function accessibilitySpeakerRead(text: string, incConfig: AccessibilitySpeakerConfig = defaultConfig, jQuery = $): any {
	return readForSR(text, incConfig, jQuery);
}
