import {get, includes, trim} from 'lodash';

import {CUSTOM_MOMENT_LOCALES, DATE_TIME_PATTERN, NEGATIVE_PATTERNS, UTILS} from './converter-util.constants';

const MONTH_SHORT = 'MMM';
const MONTH = 'MMMM';
const DATE_NUMBER = 'DD';
const DAY_NAME = 'dddd';
const CALENDAR_DATE = 'YYYY-MM-DD';
const ISO_LONG_DATE_TIME = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
const MOMENT_LONG_DATE_TIME = 'YYYY-MM-DD HH:mm:ss.SSS';
const MOMENT_DATE_TIME = 'YYYY-MM-DD HH:mm:ss.SS';
const MONTH_DAY = 'MMMM DD';
const SHORT_DAY = 'ddd';
const SHORT_MONTH_YEAR = 'MMM YYYY';
const MONTH_YEAR = 'MMMM YYYY';
const YEAR = 'YYYY';

export class ConverterUtilCoreService {
	constructor(private logService, private preferencesService, private moment) {}

	public retrieveDateTimePattern(dateTimePatternId): any {
		const dateTimeFormats = get(this.preferencesService.getPreferences(), 'localePolicy.dateTimeFormats');

		// please keep the DATE_TIME_PATTERN in sync with the branches
		switch (dateTimePatternId) {
			case DATE_TIME_PATTERN.shortDateFormatId:
				return this.convertPatternToMomentPattern(dateTimeFormats.shortDateFormat);
			case DATE_TIME_PATTERN.longDateFormatId:
				return this.convertPatternToMomentPattern(dateTimeFormats.longDateFormat);
			case DATE_TIME_PATTERN.longDateTimeFormatId:
				return this.convertPatternToMomentPattern(`${dateTimeFormats.longDateFormat} ${dateTimeFormats.timeFormat}`).replace(/a/g, 'A');
			case DATE_TIME_PATTERN.dayMonthFormatId:
				return this.convertPatternToMomentPattern(dateTimeFormats.dayMonthFormat);
			case DATE_TIME_PATTERN.timeFormatId:
				return this.convertPatternToMomentPattern(dateTimeFormats.timeFormat).replace(/a/g, 'A');
			case DATE_TIME_PATTERN.monthShortId:
				return this.convertPatternToMomentPattern(MONTH_SHORT);
			case DATE_TIME_PATTERN.monthId:
				return this.convertPatternToMomentPattern(MONTH);
			case DATE_TIME_PATTERN.dayNameId:
				return this.convertPatternToMomentPattern(DAY_NAME);
			case DATE_TIME_PATTERN.dateNumberId:
				return this.convertPatternToMomentPattern(DATE_NUMBER);
			case DATE_TIME_PATTERN.ISO8601Id:
				return this.moment.ISO_8601;
			case DATE_TIME_PATTERN.ISOCalendarDateId:
				return this.convertPatternToMomentPattern(CALENDAR_DATE);
			case DATE_TIME_PATTERN.ISOLongDateTimeId:
				return this.convertPatternToMomentPattern(ISO_LONG_DATE_TIME);
			case DATE_TIME_PATTERN.momentLongDateTimeId:
				return this.convertPatternToMomentPattern(MOMENT_LONG_DATE_TIME);
			case DATE_TIME_PATTERN.momentDateTimeId:
				return this.convertPatternToMomentPattern(MOMENT_DATE_TIME);
			case DATE_TIME_PATTERN.monthDayId:
				return this.convertPatternToMomentPattern(MONTH_DAY);
			case DATE_TIME_PATTERN.shortDayId:
				return this.convertPatternToMomentPattern(SHORT_DAY);
			case DATE_TIME_PATTERN.yearId:
				return this.convertPatternToMomentPattern(YEAR);
			case DATE_TIME_PATTERN.shortMonthYearId:
				return this.convertPatternToMomentPattern(SHORT_MONTH_YEAR);
			case DATE_TIME_PATTERN.monthYearId:
				return this.convertPatternToMomentPattern(MONTH_YEAR);
			default:
				throw new Error(`Pattern id not found - ${dateTimePatternId}`);
		}
	}

	public formatWithNegativePattern(str, pattern): any {
		str = str.replace(new RegExp('-', 'g'), '');
		switch (pattern) {
			case NEGATIVE_PATTERNS.BETWEEN_PARENTHESIS:
				return `(${str})`;
			case NEGATIVE_PATTERNS.MINUS_RIGHT_NO_SPACE:
				return `${str}-`;
			case NEGATIVE_PATTERNS.MINUS_RIGHT_WITH_SPACE:
				return `${str} -`;
			case NEGATIVE_PATTERNS.MINUS_LEFT_NO_SPACE:
				return `-${str}`;
			case NEGATIVE_PATTERNS.MINUS_LEFT_WITH_SPACE:
				return `- ${str}`;
			default:
				this.logService.warn(`userPreferences.localePolicy.numberFormat.negativePattern = ${pattern} is not found in `, NEGATIVE_PATTERNS);
				return `-${str}`;
		}
	}

	public mathTruncate(int): any {
		return int < 0 ? Math.ceil(int) : Math.floor(int);
	}

	public isContainsNegativeSymbol(str): any {
		return !!str.match(UTILS.NEGATIVE_PATTERN);
	}

	public cleanNegativeSymbols(str): any {
		return trim(str.replace(UTILS.NEGATIVE_PATTERN, ''));
	}

	public getMomentLang(): any {
		const momentLang = this.getMomentLocale();

		this.moment.updateLocale(momentLang, {
			week: {
				dow: this.getWeekStartDay()
			}
		});

		return this.moment().locale(momentLang).locale();
	}

	public getGlobalLocale(): any {
		return this.moment.locale();
	}

	public getMomentLocale(): any {
		const languageCode = get(this.preferencesService.getPreferences(), 'localePolicy.languageCode', 'en').toLowerCase();
		const countryCode = get(this.preferencesService.getPreferences(), 'localePolicy.countryCode', 'us').toLowerCase();

		return this.moment.locale([`${languageCode}-${countryCode}`, languageCode]);
	}

	public setGlobalLocale(locale?): void {
		this.moment.locale(locale || this.getMomentLocale());
	}

	public getWeekStartDay(): any {
		return this.preferencesService.getPreferences().localePolicy.weekStartDay - 1;
	}

	public updateMomentLocaleConfig(): void {
		let currentMomentLocale;
		const localePolicy = get(this.preferencesService.getPreferences(), 'localePolicy', {});

		if (localePolicy.languageCode && localePolicy.countryCode) {
			currentMomentLocale = this.getMomentLocale();

			if (includes(CUSTOM_MOMENT_LOCALES.TWO_LETTER_SHORT_DAY_LOCALES, currentMomentLocale)) {
				this.moment.updateLocale(currentMomentLocale, {
					parentLocale: currentMomentLocale,
					weekdaysShort: this.moment.localeData(currentMomentLocale).weekdaysMin()
				});
			}
		}
	}

	private convertPatternToMomentPattern(pattern): any {
		return this.moment().toMomentFormatString(pattern);
	}
}
