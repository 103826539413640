import {get, isUndefined, omitBy} from 'lodash';
import {handleActions} from 'redux-actions';

import {ActiveSlideout, SlideoutState} from './slideout.interfaces';

import {allowClose, close, open, openWithRouter, setKeepFocusAfterLoading} from './slideout.actions';
import {getSlideOutRoute} from './slideout.utility';

export const SLIDEOUT_INITIAL_STATE: SlideoutState = {
	activeSlideout: undefined,
	close: {id: null, timestamp: 0},
	closeAllowed: true,
	keepFocusAfterLoading: false,
	open: {id: null, timestamp: 0},
	returnFocusElement: undefined
};

const getQueryParams = (action): object => {
	const queryParams = get(action, 'payload.queryParams');
	const returnFocusElement = get(action, 'payload.returnFocusElement');

	return queryParams || returnFocusElement ? {returnFocusElement, ...queryParams} : undefined;
};

const openWithRouterReducer = (state: SlideoutState, action): SlideoutState => ({
	...state,
	activeSlideout: omitBy(
		{
			context: get(action, 'payload.context'),
			queryParams: getQueryParams(action),
			route: getSlideOutRoute(get(action, 'payload.sliderRoute')),
			timestamp: Date.now(),
			skipLocationChange: get(action, 'payload.skipLocationChange')
		},
		isUndefined
	) as ActiveSlideout,
	returnFocusElement: get(action, 'payload.returnFocusElement')
});

const closeReducer = (state: SlideoutState, action): SlideoutState => ({
	...state,
	open: SLIDEOUT_INITIAL_STATE.open,
	close: {id: get(action, 'payload.id'), timestamp: Date.now()},
	returnFocusElement: undefined
});

const openReducer = (state: SlideoutState, action): SlideoutState => ({
	...state,
	open: {id: get(action, 'payload.id'), timestamp: Date.now()}
});

const allowCloseReducer = (state: SlideoutState, action): SlideoutState => ({
	...state,
	closeAllowed: action.payload.allow
});

const setKeepFocusAfterLoadingReducer = (state: SlideoutState, action): SlideoutState => ({
	...state,
	keepFocusAfterLoading: action.payload.shouldKeepFocus
});

export const reducerHelper = (): any => ({
	[openWithRouter]: openWithRouterReducer,
	[close]: closeReducer,
	[open]: openReducer,
	[allowClose]: allowCloseReducer,
	[setKeepFocusAfterLoading]: setKeepFocusAfterLoadingReducer
});

export const slideoutReducer = handleActions(
	reducerHelper(),
	SLIDEOUT_INITIAL_STATE
);
