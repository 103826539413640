import {ZonedDateTime} from './time-zone.interfaces';

export abstract class TimeZoneCoreService {
	protected currentNowPromise: Promise<Date>;
	private lastUpdateTime: Date;
	private lastServerTime: Date;

	constructor() {
		this.lastUpdateTime = new Date();
	}

	public getAdjustedNowForCurrentUser = (utc = true): Promise<Date> =>
		utc && this.currentNowPromise ? this.currentNowPromise.then(date => this.addToCurrentTime(date, this.lastUpdateTime)) : this.nowFromServer(utc);

	public getAdjustedNowSync = (): Date => (this.lastServerTime ? this.addToCurrentTime(this.lastServerTime, this.lastUpdateTime) : undefined);

	public getNowForSelectedEmployee = (employeeId: number): Promise<Date> =>
		this.nowForEmployee(employeeId).then((zdt: ZonedDateTime) => this.fromZonedToDate(zdt, false));

	protected getNowFromServer = (): Promise<Date> =>
		this.nowFromServer().then(date => {
			this.lastUpdateTime = new Date();
			this.lastServerTime = date;
			return date;
		});

	private addToCurrentTime = (current: Date, initial: Date): Date => {
		const timeDifference = Date.now() - initial.getTime();
		const newTime = new Date();

		newTime.setTime(current.getTime() + timeDifference);
		return newTime;
	};

	private nowFromServer = (utc = true): Promise<Date> => this.nowForUser().then((zdt: ZonedDateTime) => this.fromZonedToDate(zdt, utc));

	private fromZonedToDate = (zdt: ZonedDateTime, utc = true): Date =>
		utc
			? new Date(Date.UTC(zdt.year, zdt.monthValue - 1, zdt.dayOfMonth, zdt.hour, zdt.minute, zdt.second, zdt.nano / 1000000))
			: new Date(zdt.year, zdt.monthValue - 1, zdt.dayOfMonth, zdt.hour, zdt.minute, zdt.second, zdt.nano / 1000000);

	protected abstract nowForEmployee(employeeId: number): Promise<ZonedDateTime>;

	protected abstract nowForUser(): Promise<ZonedDateTime>;
}
