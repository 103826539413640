import {filter, indexOf} from 'lodash';
import {empty, Observable} from 'rxjs';
import {defaultIfEmpty, map} from 'rxjs/operators';

import {ActionItemBaseComponent} from './action-item-base.component';
import {HelpActionItemComponent} from './contextual-help/help-action-item.component';
import {NetworkActionItemComponent} from './network-action-item.component';
import {NotificationActionItemComponent} from './notification-action-item.component';
import {QuickFindActionItemComponent} from './quick-find-action-item.component';
import {SuiteXSearchActionItemComponent} from './suite-x-search-action-item.component';

export const navBarActions: any[] = [
	NetworkActionItemComponent,
	SuiteXSearchActionItemComponent,
	QuickFindActionItemComponent,
	HelpActionItemComponent,
	NotificationActionItemComponent
];

export const getActionsConfig = (keys$: Observable<string[]> = empty()): Observable<ActionItemBaseComponent[]> =>
	keys$.pipe(
		defaultIfEmpty([]),
		map(keys => filter(navBarActions, navBarAction => navBarAction.DEFAULT || indexOf(keys, navBarAction.actionItemName) >= 0))
	);
