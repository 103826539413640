<div *ngIf="!loading">
	<div class="contextual-help-search-result-list">
		<div class="list-item" *ngFor="let item of rowData; index as index">
			<div [ngClass]="rowData.length - 1 === index ? 'last' : 'not-last'">
				<h4 class="title">
					<a href="javascript:void(0)" (click)="clickTitle(item, index)" id="csh-result-{{ index }}">
						<i
							class="icon-k-lock"
							*ngIf="!item.public"
							[tooltip]="'html.navbar.contextualHelp.search.list.title.tooltip' | property"
							[attr.aria-label]="'html.navbar.contextualHelp.search.list.title.tooltip' | property"
						></i>
						<span class="result-title" [innerHTML]="item.title"></span>
					</a>
				</h4>
				<div class="summary" *ngIf="item.summary">
					<span [innerHTML]="item.summary"></span>
				</div>
				<div class="created-date" *ngIf="item.createdDate">
					<span class="label-text">{{ 'html.navbar.contextualHelp.search.list.createdDate' | property }} </span>
					<span>{{ item.createdDate.value[0] }}</span>
				</div>
			</div>
		</div>
		<footer class="d-flex justify-content-center p-3" *ngIf="hasMoreDataToLoad">
			<krn-ng-loader classes="bg-transparent spinner-size-small load-more" *ngIf="loadingMore"></krn-ng-loader>
			<krn-ng-button
				*ngIf="!loadingMore"
				(buttonClicked)="loadMore()"
				[buttonText]="'html.navbar.contextualHelp.search.list.loadMore' | property"
				[attrs]="{
					'aria-label': 'html.navbar.contextualHelp.search.list.loadMore.tooltip' | property,
					'automation-id': 'loadMoreBtn',
					title: 'html.navbar.contextualHelp.search.list.loadMore.tooltip' | property
				}">
			</krn-ng-button>
		</footer>
	</div>

	<div *ngIf="!loading && rowData.length === 0" class="no-result-area">
		<krn-ng-illustration-message
			classes="h-100"
			[showIllustration]="true"
			classes="illustration-no-size"
			customImage="Emptystate2-Generic.svg"
			[customMessage]="'html.navbar.contextualHelp.search.noResultMessage' | property">
		</krn-ng-illustration-message>
	</div>
</div>
