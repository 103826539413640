import {isEmpty} from 'lodash';
import * as JQuery from 'jquery';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';
import {AllowedActions, AllowedServices, AllowedType} from './post-message.interfaces';

import {ZERO} from './iframe-framework.constant';
import {sendPostMessage} from './iframe-message-handler.utility';

const $: any = JQuery;

export const getServiceIdentifier = (apiService: ApiService, serviceName: string): Promise<any> => {
	const serviceIdentifierPromise: any = {};

	if (!serviceIdentifierPromise[serviceName]) {
		serviceIdentifierPromise[serviceName] = apiService.get('external.services.get', undefined, {pathParams: {name: serviceName}});
	}
	return serviceIdentifierPromise[serviceName];
};

export const sendAuthnSsid = (apiService: ApiService, targetOrigin: string, isAuthnSsidReceived: boolean): void => {
	const childIframe: JQuery<HTMLElement> = $(`iframe[id*= portal-frame][src*= "${targetOrigin}" ]`);

	if (!isAuthnSsidReceived && childIframe && childIframe.length > ZERO) {
		apiService.get('authnssid.get', undefined, undefined, Transport.SOCKET).then((data: string) => {
			/* because of cookie wrapped in double quotes, we use this slice method to remove the extra double quotes. */
			// eslint-disable-next-line no-useless-escape
			const authnSsidValue = (!isEmpty(data) && data.charAt(0) === '\"') ? data.slice(1, data.length - 1) : data;
			const iframe: HTMLIFrameElement = childIframe[ZERO] as HTMLIFrameElement;

			sendPostMessage(iframe.contentWindow, targetOrigin, AllowedType.INFO, AllowedActions.AUTHNSSID, authnSsidValue, AllowedServices.WFD);
		});
	}
};