export {isFeatureEnabled, registerFeatures};
import {ReleaseToggle} from './release-toggle.interface';

let releaseToggles: ReleaseToggle;

function isFeatureEnabled(key: string): boolean {
	if (releaseToggles && releaseToggles[key]) {
		return Boolean(releaseToggles[key]);
	}
	return false;
}

function registerFeatures(features: ReleaseToggle): void {
	releaseToggles = features;
}
