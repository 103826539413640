import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, Optional} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ApiService} from '../../../../core/communication/api-service/api-service.interfaces';

import {Auth0StackCoreService} from '../../../../core/session/authStack/auth0-stack.core.service';
import {SESSION_EVENTS} from '../../../../core/session/session.constants';
import {ClientContainerContextService} from '../../../../services/clientContainer/client-container-context.service';
import {DataService} from '../../../../services/communication/data.service';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {AuthenticationRegistrationService} from '../../authentication/authentication.registration.service';
import {ActivityDetectorService} from '../activityDetector/activity-detector.service';
import {SessionActionService} from '../session.action';

@Injectable({providedIn: 'root'})
export class Auth0StackService extends Auth0StackCoreService {
	private notifier$ = new Subject();

	constructor(
		protected logger: NGXLogger,
		protected authenticationRegistrationService: AuthenticationRegistrationService,
		@Inject(DataService) protected apiService: ApiService,
		protected activityDetectorService: ActivityDetectorService,
		protected localStorageService: LocalStorageService,
		@Inject(DOCUMENT) protected document: Document,
		protected sessionActionService: SessionActionService,
		@Optional() protected clientContainerContextService: ClientContainerContextService
	) {
		super(logger, authenticationRegistrationService, apiService, activityDetectorService, localStorageService, document,
			sessionActionService.dispatch, clientContainerContextService);
		this.sessionActionService
			.select('status')
			.pipe(takeUntil(this.notifier$))
			.subscribe((status: string) => {
				if (status === SESSION_EVENTS.EXTEND) {
					this.extendSession();
				}
			});
	}

	public destroy(): void {
		super.destroy();
		this.notifier$.next(undefined);
		this.notifier$.complete();
	}

	protected setSessionStatus(eventName): string {
		return eventName;
	}
}
