<div class="illustration-on" [ngClass]="(customMessage || propertyName) && !classes ? 'illustration-size' : classes">
  <div *ngIf="illustrationStatus" class="illustration" [tooltip]="attrs.title" aria-hidden="true">
    <img [src]="imgSrc" class="illustration-class" [alt]="attrs.title">
  </div>
  <div class="message-action-margin"
       [ngClass]="illustrationStatus ? '': 'no-image-padding'">
    <p class="msg-text" id="{{attrs?.id}}">{{customMessage || (propertyName | property)}}</p>
    <ng-content></ng-content>
  </div>
</div>

