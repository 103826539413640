import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';
import {v1 as uuidv1} from 'uuid';
import {startsWith} from 'lodash';

import {NodeDownErrorModal} from '../../../ui/node-down-error/node-down-error.modal.component';
import {NodeDownPageContext} from '../../../ui/node-down-error/node-down-error-constants';
import {PropertyFilterPipe} from '../../../providers/property/property.filter.pipe';
import {PropertyFactory} from '../../../core/property/property.factory';
import {ERROR_MESSAGE_TYPE} from '../../../core/platform/constants/message.constants';
import {MESSAGE_PROPERTIES} from '../../../core/generic/message/message.constant';
import {ClientContainerContextService} from '../../clientContainer/client-container-context.service';
import {DataService} from '../data.service';

@Injectable()
export class RestInterceptor implements HttpInterceptor {
	private bsModalRef: BsModalRef = null;
	private friendlyErrorMessage: string;

	constructor(
		private logger: NGXLogger,
		private clientContainerContextService: ClientContainerContextService,
		private modalService: BsModalService,
		private dataService: DataService,
		private propertyFilter: PropertyFilterPipe,
		private propertyFactory: PropertyFactory,
	) {
		this.propertyFactory.loadProperties(MESSAGE_PROPERTIES).then(() => {
			this.friendlyErrorMessage = this.propertyFilter.transform('html.commonInfra.platform.errorframework.errormessage.sessiontimeout');
		});
	}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const pageHeader = req.headers.get('page');
		let correlationId: string;
		let started: number;
		let status: number;

		if (this.clientContainerContextService.isOneApp()) {
			correlationId = uuidv1();
			started = Date.now();
			req = req.clone({setHeaders: {'Correlation-Id': correlationId}});
		}

		return next.handle(req).pipe(
			tap({
				next: event => {
					if (event instanceof HttpResponse) {
						status = event.status;
					}
				},
				error: error => {
					status = error.status;
					this.errorMessageHandler(error);
				}
			}),
			map((response: HttpResponse<any>) => {
				const moduleMap = {
					ess: NodeDownPageContext.Scheduling,
					forecast: NodeDownPageContext.Scheduling,
					patternTemplates: NodeDownPageContext.Scheduling,
					schedule: NodeDownPageContext.Scheduling,
					'schedule-period': NodeDownPageContext.Scheduling,
					scheduleAdmin: NodeDownPageContext.Scheduling,
					'shift-templates': NodeDownPageContext.Scheduling,
					shiftBuilder: NodeDownPageContext.Scheduling,
					staffing: NodeDownPageContext.Scheduling,
					teamManagement: NodeDownPageContext.Scheduling,
					workloadplanner: NodeDownPageContext.Scheduling,
					attendance: NodeDownPageContext.Timekeeping,
					'hours-allocation-landing-page': NodeDownPageContext.Timekeeping,
					leave: NodeDownPageContext.Timekeeping,
					'ota-landing-page': NodeDownPageContext.Timekeeping,
					quicktimestamp: NodeDownPageContext.Timekeeping,
					'timecard-landing-page': NodeDownPageContext.Timekeeping,
					timekeeping: NodeDownPageContext.Timekeeping,
					'tk-print': NodeDownPageContext.Timekeeping
				};

				if (response.status === 502 && pageHeader && !!moduleMap[pageHeader] && this.bsModalRef === null) {
					this.logger.debug(`Showing Error Modal on this request ${{req, response}}`);
					this.bsModalRef = this.modalService.show(NodeDownErrorModal, {backdrop: 'static'});
					this.bsModalRef.content.pageModule = moduleMap[pageHeader];
					this.bsModalRef.content.onClose = this.modalClosed;
					this.dataService.get('/user/clearNodeSpecificSessionCookie', null, {nodeType: moduleMap[pageHeader]});
				}

				return response;
			}),
			finalize(() => {
				if (!this.clientContainerContextService.isOneApp()) {
					return;
				}
				const elapsed = Date.now() - started;
				const msg = `RestInterceptor: response: ${status} ${req.method} ${req.url}, time elapsed: ${elapsed} ms.`;

				this.logger.debug(msg, {correlationId});
			})
		);
	}

	private modalClosed(): void {
		this.bsModalRef = null;
	}
	private errorMessageHandler(error): void {
		if (startsWith(error.message, ERROR_MESSAGE_TYPE.PARSE) ) {
			error.message = this.friendlyErrorMessage;
		}
	}
}
