import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';

import {ActivityDetectorCoreService} from '../../../../core/session/activityDetector/activity-detector.core.service';

@Injectable({providedIn: 'root'})
export class ActivityDetectorService extends ActivityDetectorCoreService {
	constructor(@Inject(DOCUMENT) protected document: Document) {
		super(document);
	}
}
