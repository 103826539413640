import {isNull} from 'lodash';

import {BaseEmbeddedUnsavedDataCoreService} from '../embedded/base-embedded-unsaved-data.core.service';
import {UnsavedDataCoreService} from '../framework/services/unsaved-data-core.service';
import {SlideOutManagerCoreService} from '../generic/slideOutContainer/slideout-manager.core.service';

export class OneAppUnsavedDataCoreService extends BaseEmbeddedUnsavedDataCoreService {
	constructor(
		protected window: Window,
		protected unsavedDataService: UnsavedDataCoreService,
		protected slideOutManagerService: SlideOutManagerCoreService
	) {
        super(window, unsavedDataService, slideOutManagerService);
    }

	public verifyUnsavedData = (action: string, url: string): Promise<boolean> => this.verifyUnsavedDataForPage();

	private verifyUnsavedDataForPage = (): Promise<boolean> => Promise.resolve(this.unsavedDataService.isDirty && !isNull(this.unsavedDataService.onUnsavedVerify));
}
