import {get, includes, isEmpty, isEqual, some} from 'lodash';
import {combineLatest, from, fromEvent, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

import {ClientContainer} from '../platform/clientContainer/client-container.interface';
import {CordovaPluginWindow} from '../platform/mobile/cordova-plugin-window.interface';
import {PropertyState} from '../property/property.interface';
import {GoModalData, ResponseData, UnSavedDataResponse} from './one-app-event-bus.interfaces';
import {CardType} from '../cardLibrary/card.interfaces';

import {getTopWfdWindow} from '../iframe-framework/iframe-message-handler.utility';
import {SessionStorageCoreService} from '../platform/caching/sessionStorage.service';
import {PropertyFactory} from '../property/property.factory';
import {NavigationDirection, OneAppAction, actionMap, SENDER_NAME} from './one-app-event-bus.constants';
import {fireEvent, initializeEventBusListener} from './one-app-event-bus.utility';
import {TYPE_CONFIG_PATH} from '../tile/tile.constants';
import {getCurrentTile, isTileOpened} from '../tile/tile.utility';

export abstract class OneAppEventBusCoreService {
	protected title$: Subject<string> = new Subject();
	protected url$: Subject<string> = new Subject();
	protected properties$: Observable<PropertyState>;
	protected topWfdWindow: CordovaPluginWindow;
	private requestID = 0;
	private excludeTitleList: string[] = [];

	constructor(
		protected window: Window,
		protected sessionStorage: SessionStorageCoreService,
		protected clientContainerService: ClientContainer,
		protected propertyFactory: PropertyFactory
	) {
		this.topWfdWindow = getTopWfdWindow(window);
		fromEvent(this.window, 'mobileAppEventBus')
			.pipe(filter((event: CustomEvent) => this[get(event, 'detail.method')]))
			.subscribe((event: CustomEvent) => (event.detail.arg ? this[event.detail.method](event.detail.arg) : this[event.detail.method]()));

		this.properties$ = from(
			this.propertyFactory
				.loadProperties([
					{
						name: 'container_web-common_embedded_strings.properties',
						path: 'components/zed-core/properties/embedded/'
					},
					{
						name: 'wat_web-attendance_pages_strings.properties',
						path: 'apps/attendance/pages/properties/'
					}
				])
				.then(properties => {
					this.excludeTitleList.push(this.getProperties('embedded.timeAndAttendance.label'));
					this.excludeTitleList.push(this.getProperties('attendance.title'));
					return properties;
				})
		);

		combineLatest([this.url$, this.title$, this.properties$])
			.pipe(
				map(([url, title]) => ({url, title})),
				distinctUntilChanged((prev, curr) => (some(this.excludeTitleList, item => isEqual(item, curr.title)) ? false : prev.title === curr.title))
			)
			.subscribe(({url, title}) => {
				this.topWfdWindow.document.title = title;
				const eventData = {
					action: OneAppAction.NAVIGATION_COMPLETE,
					requestID: ++this.requestID,
					data: {
						direction: NavigationDirection.FORWARD,
						menu: {
							id: `dim.${title}`,
							type: 'WEB_CORDOVA',
							label: title,
							url,
							supportsUnsavedData: this.getSupportsUnsavedDataStatus(url)
						}
					}
				};

				if (includes(url, 'controlCenter#/controlCenter')) {
					this.topWfdWindow.OneAppCommon.DATA.getUserData(this.topWfdWindow, SENDER_NAME, {key: 'menu.back.stack'})
					.then(res => {
						let currentStackUrl;

						if (get(res, 'data.value')) {
							currentStackUrl = JSON.parse(this.topWfdWindow.OneAppCommon.UTIL.b64DecodeStr(res.data.value))[0].url;
						}
						if (!includes(currentStackUrl, '/navigateToControl?redirectUrl=/controlCenter')) {
							fireEvent(this.topWfdWindow, eventData);
						}
					});
				} else {
					fireEvent(this.topWfdWindow, eventData);
				}
			});
	}

	public initializeMessageListener(getResponseFn: (action: string, url: string, data: ResponseData) => Promise<any>): void {
		initializeEventBusListener(this.window, (event: any) => {
			const action: string = get(event, 'detail.action', '');
			const data: ResponseData = get(event, 'detail.data', '');
			const response: Promise<any> = getResponseFn(action, '', data);

			if (response) {
				response.then(res => this.sendEventBusMessage({action: actionMap[action], data: this.getUnsavedDataResponse(res)}));
			}
		});
	}

	private setupKeepAlive(): void {
		// TODO to be implemented
	}

	private getSupportsUnsavedDataStatus(url: string): boolean {
		// control center page not support unsaved data
		if (includes(url, 'controlCenter#/controlCenter')) {
			return false;
		}
		//when opened tile type is embed, then return false, otherwise will be true
		if (isTileOpened) {
			const currentTile = getCurrentTile(this.sessionStorage);

			return currentTile ? get(currentTile, TYPE_CONFIG_PATH) !== CardType.EMBED : true;
		}
		return true;
	}

	private sendTitleIfNeeded({title}: {title: string}): void {
		if (!isEmpty(title)) {
			this.title$.next(title);
		}
	}

	private getUnsavedDataResponse(flag: boolean): UnSavedDataResponse {
		return {unsavedData: flag};
	}

	private sendEventBusMessage({action, data}: {action: string; data: UnSavedDataResponse}): void {
		fireEvent(this.topWfdWindow, {
			action,
			requestID: ++this.requestID,
			data
		});
	}

	private goModal(data: GoModalData): void {
		fireEvent(this.topWfdWindow, {
			action: OneAppAction.GO_MODAL,
			requestID: ++this.requestID,
			data
		});
	}

	private navigatedForward = ({url}: {url: string}): void => {
		if (!isEmpty(url) && !includes(url, 'login')) {
			this.url$.next(url);
		}
	};

	private navigatedBack(): void {
		this.topWfdWindow.OneAppCommon.NAV.complete(this.topWfdWindow, SENDER_NAME, {
			direction: NavigationDirection.BACK
		});
	}

	private onRoleSwitched(data): void {
		this.topWfdWindow.OneAppCommon.APP_FLOW.onRoleSwitched(this.topWfdWindow, SENDER_NAME, data);
	}

	public abstract getProperties(key: string): string;
}
