import {NGXLogger} from 'ngx-logger';

import {ApiService} from '../../core/communication/api-service/api-service.interfaces';
import {Logger} from '../../core/property/property.interface';

import {CommonConstants} from '../../core/platform/constants/CommonConstants';
import {PreferencesCoreService} from '../../core/platform/preferences/preferences.service';
import {PropertyFileFactory} from '../../core/property/propertyFile.factory';
import {DataService} from '../../services/communication/data.service';
import {PropertyStateService} from './property-state.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PropertyFileFactoryProvider = {
	provide: PropertyFileFactory,
	deps: [PropertyStateService, DataService, PreferencesCoreService, NGXLogger],
	useFactory: getPropertyFileFactory
};

export function getPropertyFileFactory(
	propertyStateService: PropertyStateService,
	dataService: ApiService,
	preferencesCoreService: PreferencesCoreService,
	logger: NGXLogger
): any {
	return new PropertyFileFactory(CommonConstants, propertyStateService, dataService, preferencesCoreService, logger as Logger);
}
