import {startsWith, trim} from 'lodash';

import {UTILS} from './converter-util.constants';

export {
	addLeadingZero,
	cleanPatternSymbols,
	findGroupingSymbols,
	formatPositiveFloat,
	getPrecision,
	getResultNum,
	isBadGroupingSymbols,
	isOnlyDecimals,
	roundToPrecision
};

function addLeadingZero(numberString: string, decimalSymbol: string): string {
	return startsWith(numberString, decimalSymbol) ? `0${numberString}` : numberString;
}

function cleanPatternSymbols(floatStr: string, groupingSymbol: string): string {
	return trim(floatStr.replace(findGroupingSymbols(groupingSymbol), '').replace(UTILS.NEGATIVE_PATTERN, ''));
}

function findGroupingSymbols(groupingSymbol: string): RegExp {
	return new RegExp(`\\${groupingSymbol}`, 'g');
}

function formatPositiveFloat(float: number, decimalSymbol: string, precision: number, formatWithGroupingPattern: (str: string) => string): string {
	const sections = float.toString().split('.');
	const primarySection = formatWithGroupingPattern(sections[0]);
	let decimalSection = sections[1] || '';

	if (decimalSection.length < precision) {
		decimalSection += new Array(precision - decimalSection.length + 1).join('0');
	} else {
		decimalSection = decimalSection.substring(0, precision);
	}

	return decimalSection ? primarySection + decimalSymbol + decimalSection : primarySection;
}

function getPrecision(keepFloatPrecision: boolean, length: number, decimalPrecision: number): number {
	return keepFloatPrecision ? length : decimalPrecision;
}

function getResultNum(numberGroup: string, groupingSymbol: string, decimalGroup: string, isNegative: boolean): number | string {
	const numberGroupFormatted = numberGroup.replace(findGroupingSymbols(groupingSymbol), '');
	const resultStr = decimalGroup ? `${numberGroupFormatted}.${decimalGroup}` : numberGroupFormatted;
	const resultStrWithSymbol = isNegative ? `-${resultStr}` : resultStr;

	return Number.isSafeInteger(Math.floor(Number(resultStr))) ? Number(resultStrWithSymbol) : resultStrWithSymbol;
}

/* Validate that there is no bad grouping symbol*/
function isBadGroupingSymbols(floatStr: string, groupingSymbol: string): boolean {
	// Ensure that the grouping symbol 'space' is not interfering with the validation
	const positiveFloatStr = trim(floatStr.replace(UTILS.NEGATIVE_PATTERN, ''));
	const regexFindGoodGroupingSymbol = new RegExp(`\\d(\\${groupingSymbol})\\d`, 'g');
	const noGroupingSymbolsStr = positiveFloatStr.replace(regexFindGoodGroupingSymbol, '');

	return !!noGroupingSymbolsStr.match(findGroupingSymbols(groupingSymbol));
}

// Check if there is the good decimal symbol and if there is only one decimal symbol
function isOnlyDecimals(floatStr: string, decimalSymbol: string): RegExpMatchArray {
	const regexOnlyDecimals = new RegExp(`^[(|)|-]?[\\s]*\\d+(\\${decimalSymbol})\\d+[\\s]*[(|)|-]?$`, 'g');

	return floatStr.match(regexOnlyDecimals);
}

function roundToPrecision(num: number, precision: number): number {
	return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
}
