export enum ContextualHelpActions {
	Search = 'SEARCH',
	Sort = 'SORT',
	Loading = 'LOADING',
	LoadingMore = 'LOADING_MORE',
	Refined = 'REFINED',
	LoadMore = 'LOAD_MORE',
	Cancel = 'CANCEL',
	RefinePanelOpen = 'REFINE_PANEL_OPEN'
}

export enum AggregationsType {
	CreatedDate = 'post_time',
	Sources = '_type'
}

export enum CreatedDateValues {
	AllTime = 'All Time'
}
