import {
	filter,
	flattenDeep,
	get,
	has,
	includes,
	intersection,
	isEmpty,
	isEqual,
	isString,
	isUndefined,
	join,
	omitBy,
	size,
	split,
	trim
} from 'lodash';

import {PopoverButtonAttrs, PopoverType} from '../../popover-button/popover-button.interfaces';
import {PropertiesApi, SingleSelectPickerAttrs} from './single-select-picker.interfaces';

import {CommonConstants} from '../../platform/constants/CommonConstants';
import {getUniqueId} from '../../platform/form-element.utility';
import {ARIA} from '../../platform/accessibility/utils.constants';

const encapsulatedClasses = ['overflow-value-left', 'overflow-value-right', 'overflow-value-wrap'];
const DEFAULT_CONTAINER_CLASS = 'krn-select-popover';
const DEFAULT_TAB_INDEX = 0;
const DISABLE_TAB_INDEX = -1;
const DISABLED = 'disabled';

export const getAttributesWithId = (attrs?: SingleSelectPickerAttrs, label?: string, data?: object[]): SingleSelectPickerAttrs => {
	const needId = !has(attrs, 'id') || isEmpty(attrs.id);

	return omitBy(
		{
			...attrs,
			id: needId ? getUniqueId(attrs, label, 'select') : get(attrs, 'id'),
			disabled: size(data) ? get(attrs, DISABLED) : DISABLED
		},
		isUndefined
	);
};

export const getContainerClasses = (classes?: string | string[]): string =>
	join(
		filter(flattenDeep([DEFAULT_CONTAINER_CLASS, classes]), item => trim(item)),
		' '
	);

export const getDefaultTooltip = (allProperties: PropertiesApi, attrs?: SingleSelectPickerAttrs, label?: string, value?: string): string => {
	const propLabel = isEmpty(label) ? undefined : label;
	const propValue = value || get(attrs, 'placeholder') || undefined;

	return propLabel && propValue ? allProperties.tooltipProp(propLabel, propValue) : propValue || propLabel;
};

export const getNativeAttrs = (attrs?: SingleSelectPickerAttrs, label?: string, value?: object): SingleSelectPickerAttrs => {
	const id = get(attrs, 'id');
	const labelId = id && !isEmpty(label) ? `label_${id}` : '';
	const emptyId = id && isEmpty(value) && !get(attrs, 'placeholder') ? `emptyValue_${id}` : '';
	const labelledBy = trim(`${emptyId} ${labelId}`);

	return omitBy(
		{
			[ARIA.DESCRIBEDBY]: get(attrs, ARIA.DESCRIBEDBY),
			[ARIA.LABEL]: get(attrs, ARIA.LABEL),
			[ARIA.LABELLEDBY]: get(attrs, ARIA.LABELLEDBY) || (isEmpty(labelledBy) ? undefined : labelledBy),
			id,
			tabindex: get(attrs, 'tabindex') || (get(attrs, DISABLED) === DISABLED ? DISABLE_TAB_INDEX : DEFAULT_TAB_INDEX)
		},
		isUndefined
	);
};

export const getPickerClasses = (classes?: string | string[]): string => {
	const classList = isString(classes) ? split(classes, ' ') : classes;

	return join(intersection(classList, encapsulatedClasses), ' ');
};

export const getPopoverAttrs = (attrs?: SingleSelectPickerAttrs, useDefaultTemplate = false, title?: string): PopoverButtonAttrs => {
	const id = get(attrs, 'id');

	return omitBy(
		{
			[ARIA.DESCRIBEDBY]: get(attrs, ARIA.DESCRIBEDBY),
			[ARIA.LABELLEDBY]: get(attrs, ARIA.LABELLEDBY, id ? `label_${id} emptyValue_${id} value_${id} required_${id}` : undefined),
			[ARIA.HASPOPUP]: get(attrs, ARIA.HASPOPUP, useDefaultTemplate ? PopoverType.LIST_BOX : true),
			disabled: get(attrs, DISABLED) ? DISABLED : undefined,
			tabindex: get(attrs, 'tabindex'),
			title: get(attrs, 'title', title),
			role: get(attrs, 'role'),
			id
		},
		isUndefined
	);
};

export const getPopoverClasses = (alignment?: string, classes?: string | string[], dir?: string): string => {
	const hasLabelRight = includes(classes, 'label-right');
	let cls = ['btn-secondary', 'btn-select', 'h-100', 'w-100'];

	if ((!alignment || alignment !== CommonConstants.HTML_ALIGNMENT.RIGHT) && dir !== 'rtl' && !hasLabelRight) {
		cls = cls.concat(['btn-justify-end', 'pr-s']);
	} else if (alignment === CommonConstants.HTML_ALIGNMENT.RIGHT || hasLabelRight || dir === 'rtl') {
		cls = cls.concat(['btn-justify-start', 'pl-s']);
	}
	return cls.join(' ');
};

export const getSelectedValue = (data: object[], newValue: object, oldValue: object): object => {
	const dataValueItem = data ? data.find(item => isEqual(item, newValue)) : null;

	return data && (newValue || isUndefined(newValue)) && !isEqual(oldValue, newValue) ? dataValueItem || newValue : oldValue;
};

export const getTooltipValue = (item: object, tooltipPropertyName: string, valuePropertyName: string): string =>
	isEmpty(item) ? undefined : get(item, tooltipPropertyName || valuePropertyName);

export const getBaseAttrs = (
	allProperties: PropertiesApi,
	attrs?: SingleSelectPickerAttrs,
	label?: string,
	value?: string
): SingleSelectPickerAttrs => {
	const title = get(attrs, 'title');

	return {
		...attrs,
		title: isEmpty(title) ? getDefaultTooltip(allProperties, attrs, label, value) : title
	};
};
