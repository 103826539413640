import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {get} from 'lodash';

import {UserInfoService} from '../../../../../core/framework/services/user-info.service';
import {returnFocusId} from '../../../../../core/navBar/nav-bar-action-item.service';
import {PropertyFactory} from '../../../../../core/property/property.factory';
import {MOBILE_OS} from '../../../../../core/platform/services/DeviceConfigServices.constants';
import {PropertyFilterPipe} from '../../../../../providers/property/property.filter.pipe';
import {DeviceConfigService} from '../../../../../services/device-config.service';
import {PermissionsService} from '../../../../../services/permissions.service';
import {SlideOutHelperService} from '../../../../../services/slide-out-helper.service';
import {ActionItemOptions} from './action-item-base.component';
import {ActionItemIframeBaseComponent} from './action-item-iframe-base.component';

const LABEL_PROPERTY = 'quickFind.button.label';

@Component({
	templateUrl: './action-item.template.html',
	providers: [PropertyFilterPipe]
})
export class QuickFindActionItemComponent extends ActionItemIframeBaseComponent implements OnInit {
	public static readonly DEFAULT = false;
	public static readonly actionItemName = 'QuickFindActionItemComponent';
	public static readonly actionItemOptions: Partial<ActionItemOptions> = {
		id: 'quickFind',
		icon: 'icon-k-search',
		propertyFilePath: 'components/zed-core/properties/quick-find/',
		propertyFileName: 'container_web-common_quick-find_strings.properties',
		text: LABEL_PROPERTY,
		title: LABEL_PROPERTY,
		tooltip: LABEL_PROPERTY,
		visible: false
	};

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private permissionsService: PermissionsService,
		public propertyFactory: PropertyFactory,
		public propertyFilterPipe: PropertyFilterPipe,
		public route: ActivatedRoute,
		slideOutHelperService: SlideOutHelperService,
		private userInfoService: UserInfoService,
		private deviceConfigService: DeviceConfigService
	) {
		super(QuickFindActionItemComponent.actionItemOptions, propertyFactory, propertyFilterPipe, route, slideOutHelperService);
	}

	public async ngOnInit(): Promise<void> {
		const userData = await this.userInfoService.getLoggedOnUserData();

		return this.isVisible(get(userData, 'personId'));
	}

	public returnFocusId(): string {
		return returnFocusId(this.deviceConfigService.isMobileDevice(), super.returnFocusId());
	}

	private isVisible = async (employeeId: number): Promise<void> => {
		const EMPLOYEE_SEARCH_FACP = 'EMPLOYEE_SEARCH';

		try {
			const permissions = await this.permissionsService.fetchPermissions({
				fapNames: [EMPLOYEE_SEARCH_FACP],
				employeeId
			});

			this.options.visible = this.permissionsService.isAccessGranted(permissions, EMPLOYEE_SEARCH_FACP, 'ALLOWED');
			if (this.options.visible) {
				await this.propertyFactory.loadProperties([{name: this.options.propertyFileName, path: this.options.propertyFilePath}]);
				this.context = {
					title: this.propertyFilterPipe.transform(LABEL_PROPERTY),
					closeBtnVisible: this.deviceConfigService.getMobileOS() !== MOBILE_OS.IOS
				};
			}
		} catch (e) {
			this.options.visible = false;
		}
		this.changeDetectorRef.detectChanges();
	};
}
