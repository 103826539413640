export type MessageType = 'error' | 'info' | 'warning' | 'success' | 'important' | 'information';

export interface MessageAttrs {
	focus?: boolean;
}

export type Classes = string[] | string;

export interface BaseMessageModel {
	messageText: string;
	headerText?: string;
	uid?: string;
}

export interface BaseOptionsModel {
	messageIconClass?: string;
	fixedHeight?: boolean;
	showCloseButton?: boolean;
	showType?: boolean;
}

export interface KrnMessageDetailsModel {
	messageType: MessageType;
	message?: BaseMessageModel;
	options?: BaseOptionsModel;
}

export interface KrnMessageGroupModel {
	messageType: MessageType;
	messages: BaseMessageModel[];
	options?: BaseOptionsModel;
}

export interface KrnMessageInpageModel {
	messagemodel?: MessageDataModel;
	messageoptions?: MessageOptionsModel;
}

// Previous version needs to be phased out
export interface MessageDataModel extends BaseMessageModel {
	messageIconClass?: string;
	messageType?: MessageType;
	messageIconShapeClass?: string;
}

export interface MessageOptionsModel {
	closeinpage?: boolean;
	focusinpage?: boolean;
	returnFocusElement?: any;
	timeout?: number;
	isVisible?: boolean;
}

export enum MessagePopupButton {
	primary,
	secondary
}
