import {constant, get, isNil, isString, isUndefined, trim} from 'lodash';

import {Preferences} from '../preferences/preferences.interface';


import {ConverterUtilCoreService} from './converter-util-core.service';
import {GROUPING_PATTERNS, NEGATIVE_PATTERNS} from './converter-util.constants';
import {DECIMAL_PRECISION_PATH, DECIMAL_SYMBOL_PATH, GROUPING_PATTERN_PATH, GROUPING_SYMBOL_PATH, NEGATIVE_PATTERN_PATH} from './float.constants';
import {
	addLeadingZero,
	cleanPatternSymbols,
	findGroupingSymbols,
	formatPositiveFloat,
	getPrecision,
	getResultNum,
	isBadGroupingSymbols,
	isOnlyDecimals,
	roundToPrecision
} from './float.utility';

/*
 This is the same grouping pattern as Windows in the Regional Settings
 grouping_pattern : 1 = no grouping, 2 = group by 3, 3 = group only the last 3,  4 = group by 2 except the last 3

 Examples:
 --------
 12345678						-- No Group
 123 456 789				-- Group By 3
 123456 789					-- Group the last 3 digits
 12 34 56 789				-- Group by 2 except the last 3 digits

 Patterns:
 --------
 NO_GROUP(1), GROUP_BY_3(2), GROUP_ONLY_LAST_3 (3), GROUP_BY_2_EXCEPT_LAST_3(4)

 This is the same negative pattern as Windows in the Regional Settings
 number_negative_pattern : 1 = (1.1), 2 = -1.1, 3 = - 1.1, 4 = 1.1-, 5 = 1.1 -

 Examples:
 --------
 (1.1)    				-- Number between parenthesis
 -1.1     				-- Minus on left, no space
 - 1.1    				-- Minus on left, with space
 1.1-     				-- Minus on right, no space
 1.1 -    				-- Minus on right, with space

 Patterns:
 --------
 BETWEEN_PARENTHESIS(1), MINUS_RIGHT_NO_SPACE(2), MINUS_RIGHT_WITH_SPACE (3), MINUS_LEFT_NO_SPACE(4), MINUS_LEFT_WITH_SPACE(5)

 */

const groupPatterns = {
	[GROUPING_PATTERNS.NO_GROUP]: floatStr => floatStr,
	[GROUPING_PATTERNS.GROUP_BY_3]: (floatStr, groupingSymbol) => floatStr.replace(/(\d)(?=(\d\d\d)+$)/g, `$1${groupingSymbol}`),
	[GROUPING_PATTERNS.GROUP_ONLY_LAST_3]: (floatStr, groupingSymbol) => floatStr.replace(/(\d+)(?=(\d\d\d)+$)/g, `$1${groupingSymbol}`),
	[GROUPING_PATTERNS.GROUP_BY_2_EXCEPT_LAST_3]: (floatStr, groupingSymbol) => floatStr.replace(/(\d)(?=(\d\d)+\d$)/g, `$1${groupingSymbol}`)
};

const negativeLeftPattern = (formattedStr): any => !!formattedStr.match(/^[-][\s]*[0-9]+$/);
const negativeRightPattern = (formattedStr): any => !!formattedStr.match(/^[0-9]+[\s]*[-]$/);
const negativePatterns = {
	[NEGATIVE_PATTERNS.BETWEEN_PARENTHESIS]: formattedStr => !!formattedStr.match(/^[(][\s]*[0-9]+[\s]*[)]$/),
	[NEGATIVE_PATTERNS.MINUS_LEFT_NO_SPACE]: negativeLeftPattern,
	[NEGATIVE_PATTERNS.MINUS_LEFT_WITH_SPACE]: negativeLeftPattern,
	[NEGATIVE_PATTERNS.MINUS_RIGHT_NO_SPACE]: negativeRightPattern,
	[NEGATIVE_PATTERNS.MINUS_RIGHT_WITH_SPACE]: negativeRightPattern
};

export class FloatCoreService {
	constructor(private logService: any, private preferences: Preferences, private converterUtilService: ConverterUtilCoreService) {}

	// ^[0-9.,]+$ match only simple positive cases
	// ^[-]?[0-9.,]+[-]?$ match with the - at the beginning and the end
	// ^[(][0-9.,]+[)]$ match with the ()
	// ^[(]?[-]?[\s]?[0-9.,]+[\s]?[-]?[)]?$ match all cases
	public isValid = (floatStr: string): boolean => {
		const groupingSymbol = get(this.preferences, GROUPING_SYMBOL_PATH);
		const decimalSymbol = get(this.preferences, DECIMAL_SYMBOL_PATH);

		if (!isString(floatStr) || isBadGroupingSymbols(floatStr, groupingSymbol)) {
			return false;
		}

		const formattedStr = addLeadingZero(trim(floatStr.replace(findGroupingSymbols(groupingSymbol), '')), decimalSymbol);

		// Check if this is a float
		if (formattedStr.match(/^[0-9]+$/)) {
			return true;
		}

		// Check if there is the good decimal symbol and if there is only one decimal symbol
		const onlyDecimals = Boolean(isOnlyDecimals(formattedStr, decimalSymbol));

		// Check negatives values : find a negative sign OR Useful for negative integer AND when the grouping symbol is a space
		return this.converterUtilService.isContainsNegativeSymbol(formattedStr)
			? this.isNegativePatternCorrect(formattedStr, onlyDecimals)
			: onlyDecimals;
	};

	public format = (float: number, precision?: number): string => {
		const negativePattern = get(this.preferences, NEGATIVE_PATTERN_PATH);
		const decimalSymbol = get(this.preferences, DECIMAL_SYMBOL_PATH);
		const decimalPrecision = get(this.preferences, DECIMAL_PRECISION_PATH);

		const result = formatPositiveFloat(
			float,
			decimalSymbol,
			isNil(precision) ? decimalPrecision : precision,
			this.formatWithGroupingPattern.bind(this)
		);

		return float < 0 ? this.converterUtilService.formatWithNegativePattern(result, negativePattern) : result;
	};

	public parse = (floatStr: string, isRounding = false, keepFloatPrecision = false, decimalPrecision?: number): number => {
		const decimalSymbol = get(this.preferences, DECIMAL_SYMBOL_PATH);
		const groupingSymbol = get(this.preferences, GROUPING_SYMBOL_PATH);

		if (!this.isValid(floatStr)) {
			throw new Error();
		}

		const isNegativeFloatString = this.converterUtilService.isContainsNegativeSymbol(floatStr);
		const positiveStr = isNegativeFloatString ? cleanPatternSymbols(floatStr, groupingSymbol) : floatStr;

		const digitGroups = positiveStr.split(decimalSymbol);
		const numberGroup = digitGroups[0];
		const decimalGroup = digitGroups[1];
		const definedDecimalPresision = isUndefined(decimalPrecision) ? get(this.preferences, DECIMAL_PRECISION_PATH) : decimalPrecision;
		const precision = getPrecision(keepFloatPrecision, get(decimalGroup, 'length'), definedDecimalPresision);
		const resultNum = getResultNum(numberGroup, groupingSymbol, decimalGroup, isNegativeFloatString);

		return precision >= 0 && decimalGroup ? roundToPrecision(Number(resultNum), precision) : resultNum as number;
	};

	public roundToPrecision = (num: number, precision: number): number => Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);

	private defaultGroupingPattern = (floatStr: string, groupingPattern: string): string => {
		this.logService.warn(`userPreferences.${GROUPING_PATTERN_PATH} = ${groupingPattern} is not found in `, GROUPING_PATTERNS);
		return floatStr;
	};

	private defaultNegativePattern = (negativePattern: string): boolean => {
		this.logService.warn(`userPreferences.${NEGATIVE_PATTERN_PATH} = ${negativePattern} is not found in `, NEGATIVE_PATTERNS);
		return false;
	};

	private formatWithGroupingPattern(floatStr: string): string {
		const groupingPattern = get(this.preferences, GROUPING_PATTERN_PATH);
		const groupingSymbol = get(this.preferences, GROUPING_SYMBOL_PATH);

		return (
			(groupPatterns[groupingPattern] || constant(undefined))(floatStr, groupingSymbol) ||
			this.defaultGroupingPattern(floatStr, groupingPattern)
		);
	}

	/* Check if the negative pattern match */
	private isNegativePatternCorrect(floatStr: string, isCheckDecimalSymbol: boolean): boolean {
		const decimalSymbol = get(this.preferences, DECIMAL_SYMBOL_PATH);
		const negativePattern = get(this.preferences, NEGATIVE_PATTERN_PATH);
		const formattedStr = isCheckDecimalSymbol ? floatStr.replace(decimalSymbol, '') : floatStr;

		return isUndefined(negativePatterns[negativePattern])
			? this.defaultNegativePattern(negativePattern)
			: negativePatterns[negativePattern](formattedStr);
	}
}
