import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

import {SlideoutContentContext} from './slideout-content.interface';

import {loadProperties} from '../../../../core/generic/slideOutContainer/slideout.utility';
import {PropertyFactory} from '../../../../core/property/property.factory';
import {BaseSliderActionHandler} from './handlers/base-slider-action-handler';

@Component({
	selector: 'krn-slide-out-content',
	templateUrl: './slideout-content.component.html',
	styleUrls: ['./slideout-content.component.less'],
	encapsulation: ViewEncapsulation.None
})
export class SlideoutContentComponent implements OnInit {
	@Input() public actionHandler: BaseSliderActionHandler;
	@Input() public context: SlideoutContentContext;

	constructor(private propertyFactory: PropertyFactory) {
		this.context = {closeBtnVisible: true};
	}

	public ngOnInit(): void {
		loadProperties(this.propertyFactory);
	}
}
