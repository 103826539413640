import {datadogRum} from '@datadog/browser-rum';

import {UserInfo} from '../../framework/services/user-info.interface';

const initDatadogRUM = (applicationId: string, clientToken: string, {firstName, lastName, personId, tenantExternalName}): void => {
	datadogRum.init({
		applicationId,
		clientToken,
		env: tenantExternalName,
		site: 'datadoghq.eu',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 100,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input'
	});

	datadogRum.setUser({
		id: personId,
		name: `${firstName} ${lastName}`
	});
};

export const retrieveMetaDataContent = (param: string): string => $(`meta[name=${param}]`).attr('content');

export const implementRUM = (userInfo): void => {
	if (retrieveMetaDataContent('data-dog-application-id')) {
		initDatadogRUM(retrieveMetaDataContent('data-dog-application-id'), retrieveMetaDataContent('data-dog-client-token'), userInfo);
	}
};
