import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DynamicItemHostDirective} from './dynamic-item-host.directive';
import {DynamicItemManagerComponent} from './dynamic-item-manager.component';

@NgModule({
	imports: [CommonModule],
	exports: [DynamicItemManagerComponent],
	declarations: [DynamicItemManagerComponent, DynamicItemHostDirective]
})
export class DynamicItemModule {}
