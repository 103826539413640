import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {DeprecationMessageService} from '../../services/deprecation-message/deprecation-message.service';
import {TooltipConfigProvider} from '../../providers/tooltip-config.provider';
import {AvatarComponent} from './avatar.component';
import {AttributesModule} from '../../utils/attributes/attributes.module';

/**
 * @deprecated This avatar component is deprecated and will be removed in the future in favor of components found within the Ignite design system. [Click here for more details](https://engstash.int.kronos.com/projects/FRONT/repos/frontend/browse/packages/web/zed/README.md)
 */
@NgModule({
	declarations: [AvatarComponent],
	exports: [AvatarComponent],
	imports: [CommonModule, AttributesModule, TooltipModule.forRoot()],
	providers: [TooltipConfigProvider]
})
export class AvatarModule {
	constructor(private deprecationMessageService: DeprecationMessageService) {
		this.deprecationMessageService.log('avatar');
	}
}
