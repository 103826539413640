export const DEF_FIRST_LOADER_PROPERTY = 'html.commonInfra.components.loader.loading';
export const DEF_SECOND_LOADER_PROPERTY = 'html.commonInfra.components.loader.long';
export const DEF_SPEAK_COMPLETE_PROPERTY = 'html.commonInfra.components.loader.complete.def';
export const DEF_SPEAK_STARTED_PROPERTY = 'html.commonInfra.components.loader.started.def';
export const DEF_THIRD_LOADER_PROPERTY = 'html.commonInfra.components.loader.longer';
export const SPEAK_COMPLETE_PROPERTY = 'html.commonInfra.components.loader.complete';
export const SPEAK_STARTED_PROPERTY = 'html.commonInfra.components.loader.started';

export const ONE_SECOND = 1000;
export const UPDATE_MS = 10000;

export const BOUNDING_BOX = 'bounding-box';
