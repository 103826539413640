import {Observable} from 'rxjs';

import {SocketData} from '../socket/socketBus.interface';

export enum Transport {
	REST,
	SOCKET
}

export interface ApiService {
	broadcast: (route: string, id: number, query?: object) => void;
	create: (
		route: string,
		data?: object,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	) => Promise<any>;
	find: (
		route: string,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	) => Promise<any>;
	get: (
		route: string,
		id?: number,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	) => Promise<any>;
	listen: (event: string) => Observable<SocketData>;
	patch: (
		route: string,
		id: number,
		data?: object,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	) => Promise<any>;
	remove: (
		route: string,
		id: number,
		data?: object,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	) => Promise<any>;
	stream: (route: string, query?: object) => Observable<object>;
	update: (
		route: string,
		id: number,
		data?: object,
		query?: object,
		transport?: Transport,
		handleError?: boolean,
		headers?: any,
		getFullHttpResponse?: boolean,
		extraConfig?: object
	) => Promise<any>;
}
