import {ActionBarElement, ToolbarTypes} from '../actionToolBar/action-tool-bar.interfaces';
import {Card} from '../cardLibrary/card.interfaces';

import {TILE_ACTION, TILE_STATE} from '../tileLibrary/tile-library.constants';

export interface TileAccess {
	canCopy: boolean;
	canEdit: boolean;
	canDelete: boolean;
	canNavigate?: boolean;
	canRefresh: boolean;
}

export interface TileAttributes {
	id: number;
	name: string;
	label: string;
	width: number;
	height: number;
	categories: string[];
}

export interface Tile {
	access: TileAccess;
	attributes: TileAttributes;
	card: Card[];
	state?: TILE_STATE;
	action?: TILE_ACTION;
}

export interface TileSummary {
	id: number;
	cardType: string;
}

export enum TileActionEnableState {
	NotApplicable,
	AllEnabled,
	AllDisabled
}

export interface TileFocus {
	tileId: number;
	category: string;
	focus: boolean;
}

export interface TileNavigation {
	tileId: number;
	event: KeyboardEvent;
}

export interface TilebarData {
	tileBar: {
		infinite: boolean;
		elements: ActionBarElement[];
		id: string;
		tooltip: string;
		label: string;
		type: ToolbarTypes;
		visible: true;
		autoCommit: true;
	};
}

export interface TileExpand {
	tileId: number;
	expand: boolean;
}
