import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {has, isEmpty} from 'lodash';

import {isSafeHtml} from '../../../../core/generic/message/message.utility';

@Component({
	selector: 'krn-ng-single-message',
	templateUrl: './single-message.component.html',
	styleUrls: ['./single-message.component.less']
})
export class SingleMessageComponent implements OnChanges {
	@Input() public messageText: string;
	@Input() public headerText: string;
	@Input() public fixedHeight = false;
	public trustAsHtml = false;

	public ngOnChanges(changes: SimpleChanges): void {
		if (has(changes, 'messageText')) {
			this.trustAsHtml = isSafeHtml(this.messageText);
		}
	}
}
