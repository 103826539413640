export const SESSION_CHECK_USER_ACTIVITY_DURATION = 1000 * 60 * 1;
export const DEFAULT_MAX_IDLE_TIME = 1000 * 60 * 28;
export const ONE_SECOND = 1000;
export const TIME_SINCE_LAST_CALL = 'Auth0 time since last call...';
export const TIME_SINCE_LAST_ACTIVITY = 'Auth0 time since last activity...';
export const TIME_TO_SHOW_POPUP = 'Auth0 time to show popup';
export const TIME_TO_CLOSE_POPUP = 'Auth0 time to close popup';
export const TIME_TO_EXTEND_SESSION = 'Auth0 extend the session';
export const TIME_TO_EXPIRE_SESSION = 'Auth0 session expired';
export const VISIBILITYCHANGE_EVENT = 'visibilitychange';
export const THROTTLE_TIME = 1000;
export const DEFAULT_ACTIVITY_EVENTS = ['mousemove', 'click', 'keydown'];
export const EXTEND_SESSION_ERROR = 'Auth0 extend session error';
export const EXTEND_SESSION_URL = '/oidc/checksession';
export const SESSION_PREFIX = 'AUTH0_SESSION_';
export const SESSION_CLIENT_ID_KEY = `${SESSION_PREFIX}CLIENT_ID`;
export const SESSION_INFO_URL = 'session/getSessionInfo';
export const SESSION_RESET_URL = 'session/reset';
export const STORAGE_EVENT = 'storage';
export const LOCALSTORAGE_PREFIX = 'ngStorage-';
export const EXTEND_SESSION_IFRAME_TIMEOUT_DELAY = 1000;
export const EXTEND_SESSION_IFRAME_PREFIX = 'auth0-extend-session-';
export const EXTEND_SESSION_IFRAME_ERROR_CONTENT = '400 Bad Request';
export const EXTEND_SESSION_START_IFRAME_ID = 'Auth0: start to extend session through frame id';
export const EXTEND_SESSION_IFRAME_FAIL = 'Auth0: fail to extend session through frame id';
export const EXTEND_SESSION_IFRAME_SUCESS = 'Auth0: extended session through frame id';
export const EXTEND_SESSION_CONTENT_STATE = 'Auth0: iframe content state is';
export const INIT_SESSION_INFO_ERROR = 'auth0 clientId and maxIdleTime error';
