import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {get, set} from 'lodash';

import {Content} from './screen-reader-only.interfaces';

import {ScreenReaderOnlyCtrlBase} from '../../core/platform/accessibility/screen-reader-only-base.controller';

@Component({
	selector: 'krn-ng-screen-reader-only',
	templateUrl: './screen-reader-only.component.html'
})
export class ScreenReaderOnlyComponent extends ScreenReaderOnlyCtrlBase implements OnChanges, OnDestroy {
	@Input() public ariaLive: string;
	@Input() public content: Content;
	@Input() public removeTimeout: number;

	public ngOnChanges(changes: SimpleChanges): void {
		if (get(changes, 'content.currentValue')) {
			this.watchContent(get(this, 'content.text'), this.emptyContent);
		}
	}

	public ngOnDestroy(): void {
		this.cancelEmptyContentTimeout();
	}

	public emptyContent = (): void => {
		set(this, 'content.text', '');
	};
}
