<div class="menu-integration-param menuOption" *ngIf="propertiesLoaded && integrationValues && integrationValues.length > 1">
	<krn-ng-menu-button [config]="buttonConfig" [ngClass]="{'expanded': !isCollapsed}" 
						aria-expanded="!isCollapsed" 
						(keydown)="settingsMenuKeydown($event)" 
						alignment="right"
						class="menu-integration-param">
		<span [ngClass]="{'icon-k-small-caret-up': !isCollapsed, 'icon-k-small-caret-down': isCollapsed}"></span>
	</krn-ng-menu-button>
	<div class="role-section-list" tabindex="-1" *ngIf="!isCollapsed" krn-ng-modal-keyboard-navigation modalRole="list" noWrap="true">
		<a *ngFor="let value of integrationValues; index as i; trackBy: trackFunc" role="button" tabindex="0" [id]="value.label" (keydown)="handleSubMenuKeyDown($event)" 
			[ngClass]="{'active-role': value.selected}" 
			[attr.aria-label]="(value.selected ? 'html.navBar.profileMenu.settings.item.ariaLabel.selected' : 'html.navBar.profileMenu.settings.item.ariaLabel') | property: [value.label, i + 1, integrationValues.length]" (click)="switchParam(value)" class="menu-integration-param authPerson">{{value.label}}
			 <span *ngIf="value.primary">{{'html.navbar.' + integrationParam + '.primary' | property }}</span>
		</a>
	</div>
</div>
