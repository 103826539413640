<a [krn-ng-attributes]="{
      'target': config.target,
      'href': config.href,
      'id': config.id + '_link',
      'tooltip': config.tooltip,
      'disabled': config.disabled,
      'tabindex': '0',
      'aria-expanded': config.expanded,
      'aria-label': config.ariaLabel,
      'aria-describedby': config.id + '_ariaDesc',
      'role': config.role
    }"
    [tooltip]="config?.tooltip || config?.title"
    [hidden]="!config.visible"
    (click)="onClick()"
    [ngClass]="config.classes">
  <span *ngIf="!config.postpendTransclude" data-placement="left">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>
  <span id="{{config.id}}_icon" *ngIf="config.icon" [ngClass]="config.icon"></span>
  <span id="{{config.id}}_text" *ngIf="config.text">{{config.text}}</span>
  <span *ngIf="config.postpendTransclude && !config.disabled" data-placement="right">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>
  <ng-template #content><ng-content></ng-content></ng-template>
</a>
<span id="{{config.id}}_ariaDesc" *ngIf="config.ariaDesc" class="sr-only">{{config.ariaDesc | property}}</span>