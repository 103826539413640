import {Component, Inject, OnInit} from '@angular/core';
import {get} from 'lodash';

import {ApiService} from '../../../../core/communication/api-service/api-service.interfaces';

import {DataService} from '../../../../services/communication/data.service';
import {SessionStorageService} from '../../../../services/session-storage.service';
import {AuthenticationService} from '../authentication.service';
import {SessionService} from '../../session/session.service';

@Component({
	selector: 'logout',
	template: '<div></div>'
})
export class LogoutComponent implements OnInit {
	constructor(
		private authService: AuthenticationService,
		@Inject(DataService) private apiService: ApiService,
		private sessionStorageService: SessionStorageService,
		private sessionService: SessionService
	) {
	}

	public ngOnInit(): void {
		const uniqParam = new Date().getTime();
		let logoutURL;

		this.apiService.get(`/user/logout?ts=${uniqParam}`)
			.then(response => {
				logoutURL = get(response, 'message');
			})
			.finally(() => {
				this.clearSessionAndLogout(logoutURL);
			});
	}

	private clearSessionAndLogout(logoutURL): void {
		const userInfo = this.sessionStorageService.get('UserInfo');

		this.sessionService.destroy();
		this.sessionStorageService.reset();
		if (logoutURL && userInfo && userInfo.userAuthType && userInfo.userAuthType.toUpperCase() === 'BASIC') {
			this.navigateToResponseUrl(logoutURL);
		} else {
			this.authService.logout();
		}
	}

	private navigateToResponseUrl(url: string): void {
		location.href = url;
	}
}
