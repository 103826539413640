import {isEmpty} from 'lodash';

import {KnownPlace, KnownPlaceData, LocationInfo, PunchMapData, TimecardMapData} from './mapping.interface';

import {EDIT_BY_EMPLOYEE, VALIDATION, GEOFENCE_METHOD_ID, GEOFENCE_METHOD} from './mapping.constants';

export const getPropByPath = (object, path, defaultValue?): any => {
	const _path = Array.isArray(path) ? path : path.split('.');

	if (object && _path.length) {
		return getPropByPath(object[_path.shift()], _path, defaultValue);
	}
	return object === undefined ? defaultValue : object;
};

export const checkPropByPath = (object, path): any => {
	const _path = Array.isArray(path) ? path : path.split('.');

	if (object && _path.length) {
		return getPropByPath(object[_path.shift()], _path);
	}
	return object;
};

const getPunchDtm = (punch): string => punch.punchDtm;

const getTimeZone = (punch): string => getPropByPath(punch, 'timeZone.name', null);

const getTransfer = (punch): string => getPropByPath(punch, 'transfer.transferString', '');

const getLatitude = (punch): number => punch.geoLocation.latitude;

const getLongitude = (punch): number => punch.geoLocation.longitude;

const getAccuracy = (punch): number => punch.geoLocation.accuracy;

const getLocation = (punch): LocationInfo =>
	checkPropByPath(punch, 'geoLocation.geoLocationInfo')
		? {
				lat: getLatitude(punch),
				long: getLongitude(punch),
				accuracy: getAccuracy(punch)
		}
		: undefined;

const hasComments = (punch): boolean => getPropByPath(punch, 'hasComments', false);

const getCommentsCount = (punch): number => getPropByPath(punch, 'commentsNotes.length', 0);

const hasEdits = (punch): boolean => getPropByPath(punch, 'editByType.id', EDIT_BY_EMPLOYEE) !== EDIT_BY_EMPLOYEE;

const hasExceptions = (punch): boolean => getExceptionsCount(punch) > 0;

const getExceptionsCount = (punch): number => getPropByPath(punch, 'exceptions.length', 0);

const getKnownPlaceName = (punch): string => getPropByPath(punch, 'geoLocation.geoLocationData.knownPlace', '');

const getGeofenceMethod = (punch, knownPlaceName): GEOFENCE_METHOD => {
	if (knownPlaceName === '') {
		return undefined;
	}
	switch (getPropByPath(punch, 'geoLocation.geofenceMethodId', GEOFENCE_METHOD_ID.UNDEFINED)) {
		case GEOFENCE_METHOD_ID.GPS:
			return GEOFENCE_METHOD.GPS;
		case GEOFENCE_METHOD_ID.WIFI:
			return GEOFENCE_METHOD.WIFI;
		default:
			return undefined;
	}
};

const getJobPath = (punch): string => getPropByPath(punch, 'orgJob.name', '');

const getJobName = (punch): string => getJobPath(punch).split('/').pop();

// We need to fix timezone if time part of the date string does not have timezone specified
/* istanbul ignore next */
const needFixTimezone = (dateString: string): boolean => {
	const splits = dateString.split('T');

	if (splits.length < 2) {
		return false;
	}
	return !/\+|-|Z/.test(splits[1]);
};

// Build dateTime from date in UTC timezone
// So it keeps the same date and time that is passed in.
const defaultDttmFormats = {
	dateFormat: {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		timeZone: 'UTC'
	},
	timeFormat: {
		hour: '2-digit',
		minute: '2-digit',
		timeZone: 'UTC'
	}
};

const dateTimeDelimiter = ' • ';
const getDate = (date): string => new Intl.DateTimeFormat('default', (defaultDttmFormats.dateFormat as any)).format(date);
const getTime = (date): string => new Intl.DateTimeFormat('default', (defaultDttmFormats.timeFormat as any)).format(date);
const getDateTime = (date): string => getDate(date) + dateTimeDelimiter + getTime(date);

const mapPunch = (punch): any => {
	let punchDtm = getPunchDtm(punch);

	// If dateTime string does not have a timezone specifier
	// For iOS 12, it is parsed in UTC timezone
	// For iOS > 12, it is parsed in the local timezone
	// Fixing the dateTime string by appending Z if needed
	// So it always is parsed in UTC.
	if (needFixTimezone(punchDtm)) {
		punchDtm = `${punchDtm}Z`;
	}
	const date = new Date(punchDtm);
	const knownPlaceName = getKnownPlaceName(punch);

	return {
		dateTime: getDateTime(date),
		dateMilliseconds: date.getTime(),
		timeZone: getTimeZone(punch),
		transfer: getTransfer(punch),
		location: getLocation(punch),
		hasComments: hasComments(punch),
		commentsCount: getCommentsCount(punch),
		hasEdits: hasEdits(punch),
		hasExceptions: hasExceptions(punch),
		exceptionsCount: getExceptionsCount(punch),
		geofenceMethod: getGeofenceMethod(punch, knownPlaceName),
		jobName: getJobName(punch),
		knownPlaceName
	};
};

const isPhantom = (punch): boolean => punch.isPhantom;

const punchReducer = (punches, punch): any[]=> {
	if (getPunchDtm(punch) && !isPhantom(punch)) {
		punches.push(mapPunch(punch));
	}
	return punches;
};

const punchesReducer = (punches, workSpan): any[] => {
	if (workSpan.startPunch) {
		punches.in = punchReducer(punches.in, workSpan.startPunch);
	}

	if (workSpan.endPunch) {
		punches.out = punchReducer(punches.out, workSpan.endPunch);
	}

	return punches;
};

const usersMapper = apiCriteria => listing => {
	const workSpans = listing.workedShifts.flatMap(shift => shift.workedSpans);
	const punches = workSpans.reduce(punchesReducer, {in: [], out: []});

	return {
		userID: listing.employee.id,
		fullName: apiCriteria.employees.find(x => x.id === listing.employee.id).fullName,
		inPunches: punches.in,
		outPunches: punches.out
	};
};

export const getOrgJobIds = (apiPayload) : KnownPlace[]=> {
	const mapOrgJobId = (workSpan): string => getPropByPath(
		workSpan,
		'orgJob.id',
		getPropByPath(workSpan, 'primaryOrgJob.id', null)
	);

	return apiPayload
		.flatMap(listing => listing.workedShifts)
		.flatMap(workedShift =>  workedShift.workedSpans)
		.map(mapOrgJobId)
		.filter((orgJobId, index, array) => orgJobId != null && array.indexOf(orgJobId) === index);
};

export const buildTimecardContract = (apiCriteria, apiPayload: KnownPlace[]): TimecardMapData => ({
	selectedPeriod: apiCriteria.dateRange.name,
	users: apiPayload.map(usersMapper(apiCriteria))
});

const checkForGeolocation = (punch): any =>
	punch.find(location => checkPropByPath(location, 'location.lat')) || {};

export const hasPlottableData = (timecardContract): boolean => {
	let canPlot = false;

	for (const punchList of timecardContract.users) {
		if (!isEmpty(checkForGeolocation(punchList.inPunches)) || !isEmpty(checkForGeolocation(punchList.outPunches))) {
			canPlot = true;
			break;
		}
	}

	return canPlot;
};

const mapGeofenceMethod = (validation: VALIDATION): GEOFENCE_METHOD => {
	switch (validation) {
		case VALIDATION.GPS:
			return GEOFENCE_METHOD.GPS;
		case VALIDATION.WIFI:
			return GEOFENCE_METHOD.WIFI;
		default:
			return undefined;
	}
};

const geofenceMethodsReducer = (geofenceMethods: GEOFENCE_METHOD[], validation: VALIDATION): GEOFENCE_METHOD[] => {
	const geofenceMethod = mapGeofenceMethod(validation);

	if (geofenceMethod) {
		geofenceMethods.push(geofenceMethod);
	}
	return geofenceMethods;
};

const getGeofenceMethods = (knownPlace): GEOFENCE_METHOD[] => knownPlace.validationOrder.reduce(geofenceMethodsReducer, []);

const mapKnownPlace = (knownPlace: KnownPlace): KnownPlaceData => ({
	lat: knownPlace.latitude,
	long: knownPlace.longitude,
	radius: knownPlace.radius,
	name: knownPlace.name,
	geofenceMethods: getGeofenceMethods(knownPlace)
});

export const buildPunchTileContract = (apiPayload: KnownPlace[]): PunchMapData => ({
	knownPlaces: apiPayload.map(mapKnownPlace)
});

export const chunkSubstr = (str = '', size = 0): string[] => {
	if (size === 0) {
		return [];
	}

	const numChunks = Math.ceil(str.length / size);
	const chunks = new Array(numChunks);

	for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
		chunks[i] = str.substr(o, size);
	}
	return chunks;
};

export const testFunctions = {
	getDate,
	getTime,
	getDateTime,
	getGeofenceMethod,
	mapGeofenceMethod
};
