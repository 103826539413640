import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {DeprecationMessageService} from '../../services/deprecation-message/deprecation-message.service';
import {PropertyModule} from '../../providers/property/property.module';
import {TooltipConfigProvider} from '../../providers/tooltip-config.provider';
import {IllustrationMessageComponent} from './illustration-message.component';

/**
 * @deprecated This illustration-message component is deprecated and will be removed in the future in favor of components found within the Ignite design system. [Click here for more details](https://engstash.int.kronos.com/projects/FRONT/repos/frontend/browse/packages/web/zed/README.md)
 */
@NgModule({
	declarations: [IllustrationMessageComponent],
	exports: [IllustrationMessageComponent],
	imports: [CommonModule, PropertyModule, TooltipModule.forRoot()],
	providers: [TooltipConfigProvider]
})
export class IllustrationMessageModule {
	constructor(private deprecationMessageService: DeprecationMessageService) {
		this.deprecationMessageService.log('illustration-message');
	}
}
