import * as jQuery from 'jquery';

import {Selector} from './selector.interface';

const $: any = jQuery;

export class VisableSelector implements Selector {
	public select(list: HTMLElement[]): HTMLElement[] {
		return list.filter(element => !$(element).is(':hidden'));
	}
}
