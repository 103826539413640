import {cloneDeep, isNil, some} from 'lodash';

import {PageContext} from './page-context.interfaces';

import {CONTEXT_UKGPRO, CONTEXT_WFM, DEFAULT_CONTEXT} from './page-context.constants';

type ReplaceStateFn = (arg: string) => void;

export const excludedItem = (key: string | number): boolean =>
	some(
		[
			'id',
			'ids',
			'personNumber',
			'employeeId',
			'employees',
			'peopleIdList',
			'selectedTileId',
			'defaultSegmentDuration',
			'focusedEmployeeId',
			'qualifier',
			'value',
			'symbolicPeriodId',
			'timeframeId',
			'locationIdList',
			'orgJobId',
			'staffingDashboardId',
			'deploymentId',
			'name',
			'description',
			'REQUEST_TOTAL',
			'ALL_TOTAL',
			'label',
			'categoryLabel'
		],
		(item): any => item === key
	);

export const getDefaultContext = (): PageContext => cloneDeep(DEFAULT_CONTEXT);

export const getSearchParam = (url: string): string => {
	let result = '';

	url = url.replace(CONTEXT_UKGPRO, CONTEXT_WFM);
	const questionMark = url.indexOf('?');
	const poundSign = url.indexOf('#');

	if (questionMark >= 0) {
		if (questionMark > poundSign) {
			result = url.substring(questionMark + 1);
		}

		if (questionMark < poundSign) {
			result = url.substring(questionMark + 1, poundSign);
		}
	}

	return result;
};

export const getPathFromLocation = (location: Location): string => {
	const locationHash = location.hash;
	let path = location.pathname;

	if (locationHash !== '') {
		path = path.concat(locationHash.split('?')[0]);
	}
	return path;
};

export const getContextFromURL = (url: string, replaceState: ReplaceStateFn): PageContext => {
	const params = new URLSearchParams(getSearchParam(url));
	const encodedContext = params.get('context');

	if (isNil(encodedContext)) {
		return undefined;
	}

	try {
		const context = JSON.parse(atob(encodedContext));

		context.isPersistedContext = true;
		if (!isNil(context)) {
			replaceState(getPathFromLocation(window.location));
		}
		return context;
	} catch (e) {
		return undefined;
	}
};
