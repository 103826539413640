import {Injectable} from '@angular/core';
import {identity} from 'lodash';
import {NGXLogger} from 'ngx-logger';
import {filter, first, Observable} from 'rxjs';

import {AuthenticationRegistrationCoreService} from '../../../core/authentication/authentication.registration.core.service';
import {WindowRefService} from '../../../services/window-ref.service';
import {AuthenticationStore} from './authentication.store';

@Injectable({
	providedIn: 'root',
	deps: [NGXLogger, WindowRefService]
})
export class AuthenticationRegistrationService extends AuthenticationRegistrationCoreService {
	constructor(logger: NGXLogger, protected authenticationStore: AuthenticationStore, protected windowRefService: WindowRefService) {
		super(logger, windowRefService.nativeWindow);
		this.whenAuthenticated(() => this.processAuthenticated());
	}

	public whenAuthenticated(callback: () => void): void {
		this.isAuthenticated().pipe(filter(identity), first()).subscribe(callback);
	}

	public isAuthenticated(): Observable<boolean> {
		return this.authenticationStore.selector('isAuthenticated');
	}
}
