import {get, isString, toLower, trim} from 'lodash';

import {Preferences} from '../preferences/preferences.interface';

import {CommonConstants} from '../constants/CommonConstants';
import {ConverterUtilCoreService} from './converter-util-core.service';
import {DATE_TIME_PATTERN} from './converter-util.constants';
import {
	DEFAULT_TIME_AM_MARKER,
	DEFAULT_TIME_PM_MARKER,
	escapeRegExp,
	formatTimeStr,
	LOCALE_TIME_MARKER_EXCEPTIONS,
	NUMBER_WITHOUT_SEPARATORS_REGEXP
} from './time-service.utility';

export class TimeServiceCore {
	constructor(private preferences: Preferences, private converterUtilService: ConverterUtilCoreService, private moment) {}

	public calculateDuration(endDateTimeObj, startDateTimeObj, durationType): number {
		return this.moment(endDateTimeObj).diff(this.moment(startDateTimeObj), durationType, true);
	}

	// isISO flag is temporary solution till the time localPolicy team comes with permanent solution
	public isValid(timeStr, isISO): boolean {
		if (!isString(timeStr)) {
			return false;
		}

		timeStr = toLower(timeStr.replace(/\s|\t/, ''));

		if (timeStr.match(this.getTimeRegex(timeStr, isISO))) {
			return this.getMomentDate(timeStr).isValid();
		}
		return false;

	}

	public formatWithGlobalLocale(dateTimeObj): any {
		return this.moment(dateTimeObj).format(this.converterUtilService.retrieveDateTimePattern(DATE_TIME_PATTERN.timeFormatId));
	}

	public format(dateTimeObj): any {
		const dateTimeMoment = this.moment(dateTimeObj);

		dateTimeMoment.locale(this.converterUtilService.getMomentLocale());
		return dateTimeMoment.format(this.converterUtilService.retrieveDateTimePattern(DATE_TIME_PATTERN.timeFormatId));
	}

	public formatTohhmmss(timeObj): any {
		return this.format(this.moment(timeObj, CommonConstants.HTML_INFRASTRUCTURE_ISO_TIME_WITHOUT_MS_FORMAT));
	}

	// isISO flag is temporary solution till the time localPolicy team comes with permanent solution
	public parse(timeStr, isISO): any {
		if (!this.isValid(timeStr, isISO)) {
			throw new Error();
		}
		return this.getMomentDate(timeStr);
	}

	public formatInISO(dateTimeObj): any {
		return dateTimeObj.format(CommonConstants.HTML_INFRASTRUCTURE_ISO_TIME_WITHOUT_MS_FORMAT);
	}

	private getAmTimeMarker(): any {
		return this.preferences.localePolicy.localeSettings.timeAmMarker;
	}

	private getPmTimeMarker(): any {
		return this.preferences.localePolicy.localeSettings.timePmMarker;
	}

	private getAmRegexPattern(): any {
		const amPattern = escapeRegExp(this.getAmTimeMarker());
		const amShortPattern = `${amPattern.charAt(0)}$`;

		return `(${[DEFAULT_TIME_AM_MARKER, DEFAULT_TIME_AM_MARKER.charAt(0), amPattern, amShortPattern].join('|')})`;
	}

	private getPmRegexPattern(): any {
		const pmPattern = escapeRegExp(this.getPmTimeMarker());
		const pmShortPattern = `${pmPattern.charAt(0)}$`;

		return `(${[DEFAULT_TIME_PM_MARKER, DEFAULT_TIME_PM_MARKER.charAt(0), pmPattern, pmShortPattern].join('|')})`;
	}

	private getAmPmRegexPattern(): any {
		return `(${this.getAmRegexPattern()}|${this.getPmRegexPattern()})`;
	}

	// isISO flag is temporary solution till the time localPolicy team comes with permanent solution
	private getTimeRegex(timeStr, isISO): any {
		const amPmRegexPattern = this.getAmPmRegexPattern();
		const amPmRegex = new RegExp(amPmRegexPattern, 'gi');
		const amPmRegexNeeded = timeStr.match(amPmRegex);
		const timeValue = amPmRegexNeeded ? this.cleanAmPmPattern(timeStr) : timeStr;

		if (timeValue.indexOf('.') > -1) {
			// eslint-disable-next-line no-useless-escape
			return new RegExp(`^([0-9]{1,2}\.[0-9]{1,2})${amPmRegexNeeded ? amPmRegexPattern : ''}?$`, 'i');
		} else if (timeValue.indexOf(':') > -1) {
			// this is temporary solution, might be there can be separate method for server request/response time conversion logic
			// temporary solution having isISO flag accepts hh:mm:ss/hh:mm/HH:mm:ss/HH:mm and its AM/PM variants
			return new RegExp(
				// eslint-disable-next-line no-useless-escape
				`^([0-9]{1,2}${isISO ? '(' : ''}\:[0-9]{1,2}${isISO ? '){1,2})' : ')'}${amPmRegexNeeded ? amPmRegexPattern : ''}?$`,
				'i'
			);
		}
			if (amPmRegexNeeded) {
				return new RegExp(`${NUMBER_WITHOUT_SEPARATORS_REGEXP + amPmRegexPattern}$`, 'i');
			}
				return new RegExp(`${NUMBER_WITHOUT_SEPARATORS_REGEXP}$`);


	}

	private cleanAmPmPattern(timeStr): any {
		const amPmRegex = new RegExp(this.getAmPmRegexPattern(), 'gi');

		return trim(timeStr.replace(amPmRegex, ''));
	}

	private getFormattedTime(timeStr): any {
		const amRegex = new RegExp(this.getAmRegexPattern(), 'gi');
		const pmRegex = new RegExp(this.getPmRegexPattern(), 'gi');
		const isAm = timeStr.match(amRegex);
		const isPm = timeStr.match(pmRegex);
		const languageCode = get(this.preferences, 'localePolicy.languageCode', 'en').toLowerCase();

		if (isAm || isPm) {
			timeStr = this.cleanAmPmPattern(timeStr);
		}

		const formattedTimeStr = formatTimeStr(timeStr, get(this.preferences, 'localePolicy.localeSettings.timeSeparator'));

		if (isAm) {
			const timeAmMarker = LOCALE_TIME_MARKER_EXCEPTIONS.indexOf(languageCode) > -1 ? DEFAULT_TIME_AM_MARKER : escapeRegExp(this.getAmTimeMarker());

			return `${formattedTimeStr} ${timeAmMarker}`;
		} else if (isPm) {
			const timePmMarker = LOCALE_TIME_MARKER_EXCEPTIONS.indexOf(languageCode) > -1 ? DEFAULT_TIME_PM_MARKER : escapeRegExp(this.getPmTimeMarker());

			return `${formattedTimeStr} ${timePmMarker}`;
		}
		return formattedTimeStr;

	}

	private getMomentDate(timeStr): any {
		return this.moment(`1900-01-01 ${this.getFormattedTime(timeStr)}`, ['YYYY-MM-DD hh:mm', 'YYYY-MM-DD hh:mm a']);
	}

}
