import {Injectable} from '@angular/core';

import {MenuItemConfig} from '../../../../../core/navBar/models/menu-item-config.model';
import {SelectedLink} from '../../../../../core/navBar/nav-bar-menu';
import {WindowRefService} from '../../../../../services/window-ref.service';
import {NavBarMenuService} from '../../nav-bar-menu.service';
import {NavBarUrlService} from '../../nav-bar-url.service';

@Injectable({
	providedIn: 'root'
})
export class MenuItemService {
	private window;

	constructor(private navBarMenuService: NavBarMenuService, private navBarUrlService: NavBarUrlService, private windowRefService: WindowRefService) {
		this.window = windowRefService.nativeWindow;
	}

	public selectMenuLinkAndUpdateNavbar(itemConfig: MenuItemConfig): void {
		this.navBarMenuService.resetMenu();
		itemConfig.isSelected = true;
		this.navBarMenuService.setSelectedElement(this.getSelectedLinkFromDOM(itemConfig));
	}

	public getNonSPALink(itemConfig: MenuItemConfig): string {
		return this.navBarUrlService.getDimensionsLink(itemConfig);
	}

	private getSelectedLinkFromDOM(itemConfig: MenuItemConfig): SelectedLink {
		const selectedLink: SelectedLink = {item: null, category: null};

		selectedLink.item = document.getElementById(`${itemConfig.id  }_link`);
		const selectedCategory = selectedLink.item.closest('.menu-category');

		selectedLink.category = selectedCategory ? selectedCategory.children[0] : null;
		return selectedLink;
	}
}
