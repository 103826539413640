<div *ngIf="!hidden && messages?.length > 0"
     [ngClass]="messageType === 'success' ? 'alert-success fadeOut' : 'alert-' + messageType">
	<div class="msg-wrapper alert">
		<div class="msg-header-left">
			<span aria-hidden="true" class="icon-stack">
				<img *ngIf="imagePath" [src]="imagePath" class="image-only" >
				<i *ngIf="iconClass" class="icon-only" [ngClass]="iconClass"></i>
			</span>
			<span class="sr-only">{{groupTypeLabel | property:[messages?.length]}}</span>
		</div>
		<div *ngIf="showClose" class="cross-btn-wrapper">
			<button type="button" class="btn close" (click)="close()" #closeMsgButton
				[attr.title]="'html.commonInfra.platform.errorframework.errormodal.close' | property"
				[attr.aria-labelledby]="'inpage-text-' + messagesIdSuffix">
				<i aria-hidden="true" class="icon-k-close"></i>
				<span class="sr-only">{{'html.commonInfra.platform.errorframework.errormodal.close' | property }}</span>
			</button>
		</div>
		<div class="msg-text-indent" id="inpage-text-{{messagesIdSuffix}}" aria-hidden="true">
			<strong *ngIf="showGroupName" class="msg-header-type" role="heading" aria-level="1">
				{{groupTypeLabel | property:[messages?.length]}}<br>
			</strong>
			<div *ngFor="let item of messages; index as $index; last as $last"
				class="msg-text-container"
				[attr.data-uid]="item.uid">
				<krn-ng-single-message [headerText]="item?.headerText"
					[messageText]="item?.messageText" [fixedHeight]="fixedHeight">
				</krn-ng-single-message>
				<span><hr *ngIf="!$last" class="msg-inpage-stroke"></span>
			</div>
		</div>
	</div>
</div>
