import {memoize} from 'lodash';

import {ApiService, Transport} from '../../communication/api-service/api-service.interfaces';
import {Preferences} from '../preferences/preferences.interface';

import {ENDPOINTS} from './url-services.constants';

export class URLCoreService {
	// TODO: Convert getServerPreferences to REST
	public getServerPreferences = memoize(
		(data?: any): Promise<Preferences> => this.apiService.get(ENDPOINTS.GET_SERVER_PREFERENCES, undefined, data, Transport.REST, false)
	);

	public isPhotosEnabled = memoize(
		(data?: any): Promise<boolean> => this.apiService.get(ENDPOINTS.IS_PHOTOS_ENABLED, undefined, data, Transport.REST, false)
	);

	constructor(private apiService: ApiService) {}
}
