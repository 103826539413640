import {get, indexOf, inRange, isString, isUndefined, round, startsWith, trim, values} from 'lodash';

import {Preferences} from '../preferences/preferences.interface';
import {Duration} from './date-time-service.interfaces';

import {ConverterUtilCoreService} from './converter-util-core.service';
import {NEGATIVE_PATTERNS, TIME_UNITS, UTILS} from './converter-util.constants';
import {FloatCoreService} from './float-core.service';
import {DECIMAL_PRECISION_PATH, GROUPING_SYMBOL_PATH, NEGATIVE_PATTERN_PATH} from './float.constants';
import {DISPLAY_DURATION_PATH, MAX_MINUTES_VALUE, NO_MINUTES, TIME_FORMAT_PATH} from './hour.constants';

const HALF_SECONDS = 30;
const HOURS = 0;
const DEFAULT_FLOAT_DURATION = 0.0;
const HRS_TO_MINS = 60;
const HRS_TO_SECS = 3600;
const HRS_TO_MILLISECS = 3600 * 1000;
const MINUTES = 1;
const STARTING = 0;
const SUB_TWO_CHARS = 2;

const durationUnits = {
	[TIME_UNITS.MILLISECONDS]: duration => duration / HRS_TO_MILLISECS,
	[TIME_UNITS.MINUTES]: duration => duration / HRS_TO_MINS,
	[TIME_UNITS.SECONDS]: duration => duration / HRS_TO_SECS
};
const minusLeftPattern = (durationStr): any => !!durationStr.match(/^[-][\s]*([0-9]+)?[\s]*[:][\s]*[0-9]+[\s]*$/);
const minusRightPattern = (durationStr): any => !!durationStr.match(/^[\s]*([0-9]+)?[\s]*[:][\s]*[0-9]+[\s]*[-]$/);
const negativePatterns = {
	[NEGATIVE_PATTERNS.BETWEEN_PARENTHESIS]: durationStr => !!durationStr.match(/^[(][\s]*([0-9]+)?[\s]*[:][\s]*[0-9]+[\s]*[)]$/),
	[NEGATIVE_PATTERNS.MINUS_LEFT_NO_SPACE]: minusLeftPattern,
	[NEGATIVE_PATTERNS.MINUS_LEFT_WITH_SPACE]: minusLeftPattern,
	[NEGATIVE_PATTERNS.MINUS_RIGHT_NO_SPACE]: minusRightPattern,
	[NEGATIVE_PATTERNS.MINUS_RIGHT_WITH_SPACE]: minusRightPattern
};

export abstract class HourCoreService {
	constructor(
		private logService: any,
		private preferences: Preferences,
		private converterUtilService: ConverterUtilCoreService,
		private floatService: FloatCoreService
	) {}

	public format = (duration: number, durationUnit?: string): string => {
		const displayDurationInHours = this.getDisplayDurationInHours() || !isUndefined(duration) && duration.toString().match(':');

		// it's hours service, so default is 'hours'
		durationUnit = durationUnit || TIME_UNITS.HOURS;
		if (indexOf(values(TIME_UNITS), durationUnit) === -1) {
			this.logService.error(`"${durationUnit}" is not a valid duration. Defaulting to hours`);
			durationUnit = 'hours';
		}

		return displayDurationInHours
			? this.formatDurationInHours(duration, durationUnit)
			: this.formatDurationInFloat(duration, durationUnit);
	};

	public isValid = (durationVal: string): boolean => isString(durationVal) ? this.isValidTime(durationVal) || this.isValidFraction(durationVal) : false;

	public parse = (durationStr: string, keepFloatPrecision): any => {
		if (!this.isValid(durationStr)) {
			throw new Error();
		}

		if (this.isValidFraction(durationStr)) {
			return this.floatService.parse(durationStr, false, keepFloatPrecision);
		}

		const isNegativeHourString = this.converterUtilService.isContainsNegativeSymbol(durationStr);

		durationStr = isNegativeHourString ? this.cleanPatternSymbols(durationStr) : durationStr;

		const sections = durationStr.split(':');
		const hours = trim(sections[HOURS]) ? trim(sections[HOURS]) : '0';
		const minutes = trim(sections[MINUTES]) ? (trim(sections[MINUTES]) as string).substring(STARTING, SUB_TWO_CHARS) : '00';

		return this.hoursMinutesToDuration(isNegativeHourString, hours, minutes).durationInHours;
	};

	public getDisplayDurationInHours = (): any => get(this.preferences, DISPLAY_DURATION_PATH);

	private adjustDurationMinutes(minutes: number, seconds: number): number {
		return seconds >= HALF_SECONDS ? ++minutes : seconds <= -HALF_SECONDS ? --minutes : minutes;
	}

	private cleanPatternSymbols(durationStr: string): string {
		const groupingSymbol = get(this.preferences, GROUPING_SYMBOL_PATH);

		return trim(durationStr.replace(new RegExp(`\\${groupingSymbol}`, 'g'), '').replace(UTILS.NEGATIVE_PATTERN, ''));
	}

	private defaultDuration = (duration: number): number => duration;

	private formatDurationInFloat(duration: number, durationUnit: string): string {
		const toUnitFunction = durationUnits[durationUnit] || this.defaultDuration;
		const durationValue = isUndefined(duration) ? DEFAULT_FLOAT_DURATION : duration;
		const hourDuration = toUnitFunction(durationValue);

		return this.floatService.format(round(hourDuration, get(this.preferences, DECIMAL_PRECISION_PATH)));
	}

	private formatDurationInHours(duration: number, durationUnit: string): string {
		const negativePattern = get(this.preferences, NEGATIVE_PATTERN_PATH);
		const convDuration = this.toDuration(duration, durationUnit);
		let hours = this.converterUtilService.mathTruncate(convDuration.durationInHours);
		let minutes = this.adjustDurationMinutes(convDuration.minutes, convDuration.seconds);

		if (minutes === MAX_MINUTES_VALUE) {
			hours++;
			minutes = NO_MINUTES;
		}

		const durationStr = `${this.localizeHoursFormat(hours)}:${this.formatTensNumbers(minutes)}`;

		return convDuration.durationInSeconds < NO_MINUTES
			? this.converterUtilService.formatWithNegativePattern(durationStr, negativePattern)
			: durationStr;
	}

	private formatTensNumbers(numberStr: number): string {
		return inRange(numberStr, UTILS.MINUS_NINE_DIGIT, UTILS.TEN_DIGIT) ? UTILS.ZERO_STRING + numberStr : numberStr.toString();
	}

	private defaultNegativePatternCorrect(negativePattern: string): boolean {
		this.logService.warn(`userPreferences.${NEGATIVE_PATTERN_PATH} = ${negativePattern} is not found in `, NEGATIVE_PATTERNS);
		return false;
	}

	private isNegativePatternCorrect(durationStr: string): boolean {
		const negativePattern = get(this.preferences, NEGATIVE_PATTERN_PATH);

		return isUndefined(negativePatterns[negativePattern])
			? this.defaultNegativePatternCorrect(negativePattern)
			: negativePatterns[negativePattern](durationStr);
	}

	private isValidFraction(durationStr: string): boolean {
		return this.floatService.isValid(durationStr);
	}

	private isValidTime(durationStr: string): boolean {
		const isNegativeHourString = this.converterUtilService.isContainsNegativeSymbol(durationStr);

		return isNegativeHourString
			? this.isNegativePatternCorrect(trim(durationStr))
			: !!durationStr.match(/^[\s]*([0-9]+)?[\s]*[:][\s]*[0-9]+[\s]*$/);
	}

	private localizeHoursFormat(hours: number): string {
		const format = get(this.preferences, TIME_FORMAT_PATH);

		return startsWith(format, 'HH') || startsWith(format, 'hh') ? this.formatTensNumbers(hours) : hours.toString();
	}

	protected abstract hoursMinutesToDuration(isNegativeHours: boolean, hours: string, minutes: string): Duration;

	protected abstract toDuration(value: number, units: string): Duration;
}
