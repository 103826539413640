import {ChangeDetectorRef, Component, HostListener, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {find, get, set, unset} from 'lodash';

import {ApiService, Transport} from '../../../../../core/communication/api-service/api-service.interfaces';

import {init, NotificationCount} from '../../../../../core/navBar/notification-action-item.utility';
import {keyCodes} from '../../../../../core/platform/constants/keys.constant';
import {PropertyFactory} from '../../../../../core/property/property.factory';
import {ReleaseToggleService} from '../../../../../core/platform/releaseToggle/release-toggle.service';
import {dispatch} from '../../../../../core/platform/types/state';
import {PropertyFilterPipe} from '../../../../../providers/property/property.filter.pipe';
import {DataService} from '../../../../../services/communication/data.service';
import {SessionStorageService} from '../../../../../services/session-storage.service';
import {SlideOutHelperService} from '../../../../../services/slide-out-helper.service';
import {ActionItemOptions} from './action-item-base.component';
import {ActionItemIframeBaseComponent} from './action-item-iframe-base.component';

const LABEL_PROPERTY = 'html.commonInfra.components.alert-notification.alerts';
const NAVIGATE_PROPERTY = 'html.commonInfra.components.alert-notification.aria.navigate';
const NO_NOTIFICATIONS = 0;

@Component({
	templateUrl: './notification-action-item.template.html',
	providers: [PropertyFilterPipe]
})
export class NotificationActionItemComponent extends ActionItemIframeBaseComponent implements OnInit {
	public static readonly DEFAULT = false;
	public static readonly actionItemName = 'NotificationActionItemComponent';
	public static readonly actionItemOptions: Partial<ActionItemOptions> = {
		autoFocus: true,
		id: 'alerts',
		uri: 'controlCenter#/home',
		icon: 'icon-k-alerts',
		text: '',
		title: '',
		tooltip: '',
		mainClasses: '',
		propertyFilePath: 'components/zed-core/properties/notification/',
		propertyFileName: 'container_web-common_notification_strings.properties',
		postpendTransclude: true,
		sticky: true,
		ariaDesc: 'html.commonInfra.components.alert-notification.aria.open.panel',
		expanded: false
	};

	public callback: () => void;
	public context: any = {};
	public counter: number;
	public hasPermission: boolean;
	public notificationCount: NotificationCount;

	constructor(
		private store: Store<any>,
		private changeRef: ChangeDetectorRef,
		@Inject(DataService) private dataService: ApiService,
		private sessionStorageService: SessionStorageService,
		public propertyFactory: PropertyFactory,
		public propertyFilterPipe: PropertyFilterPipe,
		public route: ActivatedRoute,
		slideOutHelperService: SlideOutHelperService,
		private releaseToggleService: ReleaseToggleService
	) {
		super(NotificationActionItemComponent.actionItemOptions, propertyFactory, propertyFilterPipe, route, slideOutHelperService);
		this.counter = 0;
		this.hasPermission = false;
		this.context = {displayNotifications: true};
	}

	@HostListener('window:keydown', ['$event'])
	public handleKeyDown(event: KeyboardEvent): void {
		if (event.keyCode === keyCodes.TAB && get(this.options, 'expanded')) {
			set(this.options, 'expanded', false);
		}
	}

	public async ngOnInit(): Promise<void> {
		await this.propertyFactory.loadProperties([
			{
				name: this.options.propertyFileName,
				path: this.options.propertyFilePath
			}
		]);
		if (await this.hasControlCenterAccess()) {
			this.hasPermission = true;
			this.onCountChange(await init(this.dataService, this.sessionStorageService, this.releaseToggleService, true, true, this.onCountChange,  dispatch(this.store)));
		}
	}

	public onClick(event: Event): void {
		super.onClick(event);
		set(this.options, 'expanded', true);
	}

	public onFocus = (): void => {
		set(this.options, 'expanded', false);
	};

	private hasControlCenterAccess = (): Promise<any> =>
		this.dataService
			.find('controlCenter/notification/config', undefined, Transport.REST, false)
			.then(result => get(result, 'accessPermission', false));

	private canDisplayNotifications = (additionalData: object): boolean => {
		const selectedEin = find(get(additionalData, 'wfr.eins'), 'selected');

		return selectedEin ? get(selectedEin, 'primary', false) : true;
	};

	private onCountChange = (notificationCount?: NotificationCount): void => {
		this.notificationCount = notificationCount;
		this.counter = get(notificationCount, 'ALL', NO_NOTIFICATIONS);
		this.context.displayNotifications = this.canDisplayNotifications(this.additionalData);
		this.options.postpendTransclude = this.context.displayNotifications;

		const title = this.context.displayNotifications ? LABEL_PROPERTY : 'html.commonInfra.components.preview-panel.noItems.label';

		if (this.context.displayNotifications && this.counter > NO_NOTIFICATIONS) {
			this.options.mainClasses = '';
			this.options.id = 'alerts';
			this.options.icon = 'icon-k-unread-notifications';
			this.options.onFocus = this.onFocus;
		} else {
			this.options.mainClasses = 'previewPanelButton previewPanelButton2';
			this.options.id = 'alertsCenter';
			this.options.icon = 'icon-k-alerts';
			this.options.role = 'button';
		}
		if (this.isNoNotifications()) {
			unset(this.options, 'expanded');
			unset(this.options, 'role');
			set(this.options, 'ariaDesc', NAVIGATE_PROPERTY);
		}
		this.options.title = this.options.tooltip = this.propertyFilterPipe.transform(
			this.isNoNotifications() ? NAVIGATE_PROPERTY : title,
			this.isNoNotifications() ? [] : [this.counter]
		);
		this.context.title = this.propertyFilterPipe.transform('html.commonInfra.components.preview-panel.header');
		set(this.options, 'ariaLabel', this.options.title);
		this.changeRef.markForCheck();
	};

	private isNoNotifications = (): boolean => this.counter === NO_NOTIFICATIONS;
}
