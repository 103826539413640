import {ClientContext} from '../../clientContainer/client-container.interface';

import {BrowserServerFileSavingDecodedCoreService} from './browser-server-file-saving-decoded-filename.core.service';
import {EmbeddedSessionServerFileSavingCoreService} from './embedded-session-server-file-saving.core.service';
import {KioskServerFileSavingCoreService} from './kiosk-server-file-saving.core.service';
import {MobileServerFileSavingCoreService} from './mobile-server-file-saving.core.service';
import {OneAppServerFileSavingCoreService} from './one-app-server-file-saving.core.service';
import {ProEmbeddedServerFileSavingCoreService} from './pro-embedded-server-file-saving.core.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ServerFileSavingServiceMap: {[key in ClientContext]: object} = {
    [ClientContext.BROWSER]: BrowserServerFileSavingDecodedCoreService,
    [ClientContext.WFD_MOBILE]: MobileServerFileSavingCoreService,
    [ClientContext.EMBEDDED_SESSION]: EmbeddedSessionServerFileSavingCoreService,
    [ClientContext.KIOSK]: KioskServerFileSavingCoreService,
    [ClientContext.ONE_APP]: OneAppServerFileSavingCoreService,
    [ClientContext.PRO_EMBEDDED]: ProEmbeddedServerFileSavingCoreService
};