import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {CookieModule} from 'ngx-cookie';
import {IgniteModule} from '@ignite/angular';

import {RestServiceFetchPolyfill} from '@kronos/common/components/communication/rest/core/rest-service.fetch-polyfill';
import {BootstrapService} from '@kronos/zed/src/lib/services/bootstrap.service';
import {DataServiceProvider} from '@kronos/zed/src/lib/services/communication/data.service.provider';
import {RestInterceptor} from '@kronos/zed/src/lib/services/communication/interceptor/rest-interceptor';
import {ActionFrameworkModule} from '@kronos/zed/src/lib/ui/framework/action-framework/action-framework.module';
import {AuthenticationModule} from '@kronos/zed/src/lib/ui/framework/authentication/authentication.module';
import {EmbeddedPageModule} from '@kronos/zed/src/lib/ui/framework/embedded-page/embedded-page.module';
import {OneAppPageModule} from '@kronos/zed/src/lib/ui/framework/one-app-page/one-app-page.module';
import {DelegatedRoleBannerModule} from '@kronos/zed/src/lib/ui/framework/delegated-role-banner/delegated-role-banner.module';
import {IframeContainerModule} from '@kronos/zed/src/lib/ui/framework/iframe-container/iframe-container.module';
import {IframeSlideoutModule} from '@kronos/zed/src/lib/ui/framework/iframe-slideout/iframe-slideout.module';
import {NavBarServiceProvider} from '@kronos/zed/src/lib/ui/framework/nav-bar/container/navBarService.provider';
import {NavBarModule} from '@kronos/zed/src/lib/ui/framework/nav-bar/navbar.module';
import {NewFeaturesModule} from '@kronos/zed/src/lib/ui/framework/new-features/new-features.module';
import {PersistentBannerModule} from '@kronos/zed/src/lib/ui/framework/persistent-banner/persistent-banner.module';
import {SessionTimeoutModule} from '@kronos/zed/src/lib/ui/framework/session/session-timeout.module';
import {MessageModule} from '@kronos/zed/src/lib/ui/message/message.module';
import {WriterCustomizedService} from '@kronos/zed/src/lib/services/writer-customized.service';
import {APP_CONFIG_ENVIRONMENT} from '@kronos/zed/src/lib/services/environment.token';
import {PrimusSocketService} from '@kronos/zed/src/lib/services/communication/primus-socket.service';
import {DISABLE_SOCKETS} from '@kronos/zed/src/lib/services/communication/socket.config';
import {SocketService} from '@kronos/zed/src/lib/services/communication/socket.service';
import {LocalStorageService} from '@kronos/zed/src/lib/services/local-storage.service';
import {DISABLE_TIMEZONE_SERVICE} from '@kronos/zed/src/lib/services/time-zone/time-zone.config';

import {environment} from '../environments/environment';
import {actionReducerMapFactory} from './action-reducer-map.factory';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {APP_CONFIG_ACTION_REDUCER_MAP} from './app.token';
import {isAuth0KioskRunning} from './kiosk/core/utils/kiosk.utility';
import {KioskAuth0HTTPInterceptorService} from './kiosk/services/kiosk.auth0.http-interceptor.service';

export function bootstrap(bootstrapService: BootstrapService) {
	return () => bootstrapService.bootstrap();
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		HttpClientModule,
		AppRoutingModule,
		AuthenticationModule,
		EmbeddedPageModule,
		OneAppPageModule,
		DelegatedRoleBannerModule,
		IframeSlideoutModule,
		NavBarModule,
		SessionTimeoutModule,
		BrowserAnimationsModule,
		ModalModule.forRoot(),
		CollapseModule.forRoot(),
		BsDropdownModule.forRoot(),
		IframeContainerModule,
		MessageModule,
		LoggerModule.forRoot(
			{level: NgxLoggerLevel.DEBUG},
			{
				writerProvider: {
					provide: 'TOKEN_LOGGER_WRITER_SERVICE',
					useClass: WriterCustomizedService
				}
			}
		),
		CookieModule.withOptions(),
		ActionFrameworkModule,
		NewFeaturesModule,
		PersistentBannerModule,
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		IgniteModule.forRoot(),
		environment.extModules
	],
	providers: [
		RestServiceFetchPolyfill,
		BootstrapService,
		WriterCustomizedService,
		NavBarServiceProvider,
		DataServiceProvider,
		environment.bootstrappableProviders,
		{provide: PrimusSocketService, useExisting: SocketService},
		{provide: 'BootstrappableProviders', useValue: environment.bootstrappableProviders},
		{provide: DISABLE_SOCKETS, useFactory: isAuth0KioskRunning, deps: [LocalStorageService]},
		{provide: DISABLE_TIMEZONE_SERVICE, useFactory: isAuth0KioskRunning, deps: [LocalStorageService]},
		{provide: APP_INITIALIZER, useFactory: bootstrap, deps: [BootstrapService], multi: true},
		{provide: APP_CONFIG_ACTION_REDUCER_MAP, useFactory: actionReducerMapFactory},
		{
			provide: APP_CONFIG_ENVIRONMENT,
			useValue: {production: environment.production, workersPath: environment.workersPath}
		},
		{provide: HTTP_INTERCEPTORS, useClass: RestInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: KioskAuth0HTTPInterceptorService, multi: true}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
