import * as $ from 'jquery';
import {isUndefined, keys, get} from 'lodash';

import {ApiService} from '../../communication/api-service/api-service.interfaces';
import {LocalStorage} from '../personalization/local-storage.interfaces';
import {BrandingColor, BrandingImageVersion} from './branding.interface';
import {ClientContainer} from '../clientContainer/client-container.interface';

import {ACTIVE_BRANDING_KEY, BRANDING_IMAGE_VERSION_KEY, BRANDING_IMAGE_VERSION_URL, BRANDING_URL, PREVIEW_STATUS_KEY} from './branding.constants';

export class BrandingCoreService {
	private cachedColorData: BrandingColor;
	private cachedImageVersion: BrandingImageVersion;
	private cachePreviewStatus: boolean;

	constructor(
		protected apiService: ApiService,
		protected storage: LocalStorage,
		protected document: Document,
		protected window,
		protected clientContainerService: ClientContainer
	) {}

	public setPreviewStatus(previewStatus = false): void {
		this.storage.set(PREVIEW_STATUS_KEY, previewStatus);
		this.cachePreviewStatus = previewStatus;
	}

	public getPreviewStatus(): boolean {
		return this.cachePreviewStatus || this.storage.get(PREVIEW_STATUS_KEY);
	}

	public setColors(data: BrandingColor | null): void {
		this.storage.set(ACTIVE_BRANDING_KEY, data);
		this.cachedColorData = data;
	}

	public async getColors(): Promise<BrandingColor> {
		const data = this.getColorsFromCache() || (await this.apiService.get(BRANDING_URL));

		if (!this.cachedColorData) {
			this.setColors(data);
			this.setCssVariables(data);
		}
		return data;
	}

	public getColorsSync(): BrandingColor {
		let data = this.getColorsFromCache();

		if (data && data.themeExtension) {
			this.clientContainerService.getColorSync(data);
		}
		const URL_REGEX = /^.*\/wfd\/v\d+\/.*$/;
		const urlPrefix = URL_REGEX.test(get(this.window, 'location.pathname')) ? '' : '/';

		if (!data) {
			$.ajax({
				async: false,
				dataType: 'json',
				cache: false,
				success: result => {
					data = result;
					this.setColors(result);
					this.setCssVariables(result);
				},
				url: `${urlPrefix}${BRANDING_URL}`
			});
		}
		if (!this.cachedColorData) {
			this.setColors(data);
			this.setCssVariables(data);
		}
		return data;
	}

	public async getImageVersion(): Promise<BrandingImageVersion> {
		const version = this.getVersionFromCache() || (await this.apiService.get(BRANDING_IMAGE_VERSION_URL));

		if (!this.cachedImageVersion) {
			this.setImageVersion(version);
		}
		return version;
	}

	public setImageVersion(version: BrandingImageVersion | null): void {
		this.storage.set(BRANDING_IMAGE_VERSION_KEY, version);
		this.cachedImageVersion = version;
	}

	public removeCache(): void {
		this.setColors(null);
		this.setPreviewStatus(false);
		this.setImageVersion(null);
	}

	public setCssVariables(data: BrandingColor | null): void {
		if (data && !data.defaultRow) {
			for (const categoryKey of keys(data.themeExtension)) {
				const category = data.themeExtension[categoryKey];

				for (const themeItemKey of keys(category)) {
					const themeItemData = category[themeItemKey];

					this.document.documentElement.style.setProperty(`--${themeItemData.cssTokenName}`, themeItemData.colorValue);
				}
			}
		} else {
			this.document.documentElement.removeAttribute('style');
		}
	}

	private getVersionFromCache(): BrandingImageVersion {
		return this.cachedImageVersion || this.storage.get(BRANDING_IMAGE_VERSION_KEY);
	}

	private getColorsFromCache(): BrandingColor {
		return this.cachedColorData || this.storage.get(ACTIVE_BRANDING_KEY);
	}
}
