import {get, isEmpty} from 'lodash';
import {fromEvent, Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {ClientContainer} from '../../platform/clientContainer/client-container.interface';
import {SlideoutDTO} from './slideout.interfaces';

import {WFD_URL} from '../../iframe-framework/iframe-framework.constant';
import {postMessage} from '../../iframe-framework/iframe-message-handler.utility';
import {PageContextCoreService} from '../../pageContext/page-context-core.service';
import {ACTION_TYPES} from './slideout.constants';

export class SlideoutCommunicationService {
	private windowSubscription;
	private communicationSubject = new Subject<any>();

	constructor(private window: any, private pageContext: PageContextCoreService, private clientContainerService: ClientContainer) {}

	public getSliderUrl(componentRoute: string): string {
		return `${window.location.origin}${WFD_URL}/${componentRoute}`;
	}

	public openListener(): Observable<any> {
		this.windowSubscription = fromEvent(this.window, 'message')
			.pipe(map((message: MessageEvent) => message.data))
			.subscribe(this.communicationSubject);

		return this.communicationSubject.pipe(filter((dto: SlideoutDTO) => dto.component === ACTION_TYPES.SLIDEOUT_CONTAINER));
	}

	public getComponentObs(): Observable<any> {
		return this.communicationSubject.pipe(
			filter((dto: SlideoutDTO) => dto.component === ACTION_TYPES.SLIDEOUT_COMPONENT),
			map((dto: SlideoutDTO) => dto.action)
		);
	}

	public destroyListeners(): void {
		const iframeEl = get($('#angularIframeSlider'), '[0].contentWindow');

		if (iframeEl) {
			iframeEl.postMessage(ACTION_TYPES.SLIDEOUT_DESTROY, '*');
		}

		if (this.windowSubscription) {
			this.windowSubscription.unsubscribe();
		}
	}

	public setSliderData(otherContext: any): void {
		if (!isEmpty(otherContext)) {
			this.pageContext.setContext({otherContext});
		}
	}

	public getSliderData(): any {
		return get(this.pageContext.getContext(), 'otherContext');
	}

	public postCloseSlideOutMessage(closeSlideOut: boolean): void {
		const topWindow = this.clientContainerService.getTopWfdWindow();

		if (topWindow !== this.window) {
			postMessage(topWindow, topWindow.origin, {
				type: 'info',
				action: 'navigation',
				configuration: {
					data: {closeSlideOut}
				}
			});
		}
	}
}
