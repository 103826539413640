import {BrandingService} from '@kronos/zed/src/lib/services/branding.service';
import {AuthenticationService} from '@kronos/zed/src/lib/ui/framework/authentication/authentication.service';

import {KioskBootstrapService} from '../app/kiosk/services/kiosk.bootstrap.service';
import {NgrxService} from '../app/services/ngrx.service';

export const environment = {
	production: true,
	silentLogon: false,
	websocketAddress: 'http://localhost:4200',
	bootstrappableProviders: [NgrxService, AuthenticationService, BrandingService, KioskBootstrapService],
	extModules: [],
	workersPath: '/bundles/wfd/workers'
};
