import {assignIn, isBoolean, isNull, isUndefined, noop, startsWith} from 'lodash';

import {MESSAGE_TYPES} from '../../generic/message/message.constant';
import {
	initializeMessageListener,
	removeMessageListener,
	sendPostMessageToFrameWithAction
} from '../../iframe-framework/iframe-message-handler.utility';
import {BeforeUnloadCoreService} from '../../platform/services/before-unload-core.service';
import {isOneApp} from '../../platform/services/DeviceConfig.utility';
import {PropertyFactory} from '../../property/property.factory';
import {CHROME_EXTENSIONS} from './unsaved-data.constants';

export class UnsavedDataCoreService {
	public isDirty = false;
	public isCustomPageDirty = false;
	public onUnsavedVerify: any = null;
	public sessionExpiredListener: any;
	public locationChangeListener;

	constructor(
		protected window,
		protected propertyFilter,
		protected beforeUnloadService: BeforeUnloadCoreService,
		protected propertyFactory: PropertyFactory
	) {
		this.propertyFactory.loadProperties([
			{
				name: 'container_web-common_unsaved-data_strings.properties',
				path: 'components/zed-core/properties/framework/'
			}
		]);
		initializeMessageListener(window, this.setDirtyFromEvent);
		this.addUnloadService();
	}

	public changeLocation = (url, doneCallback): boolean => {
		if (this.isDirty && !isNull(this.onUnsavedVerify)) {
			this.onUnsavedVerify(redirect => {
				if (!isUndefined(doneCallback)) {
					doneCallback(redirect);
				}
				this.verify(redirect, url);
			});
			return true;
		}
		return false;
	};

	public initUnsavedDialog = (): any => ({
		open: false,
		width: 340,
		yesClicked: noop,
		noClicked: noop
	});

	public setDirty = (value): void => {
		this.isDirty = value;
	};

	public getDirty = (): boolean => this.isDirty;

	public setCustomPageDirty = (value): void => {
		this.isCustomPageDirty = value;
		this.setDirty(this.isCustomPageDirty);
	};

	public getCustomPageDirty = (): boolean => this.isCustomPageDirty;

	public setOnUnsavedVerify = (onUnsavedVerifyCallback: () => void): any => {
		this.onUnsavedVerify = onUnsavedVerifyCallback;
	};

	public showUnsavedDialog = (unsaved, yesAction, noAction, header, msg): void => {
		assignIn(unsaved, this.getUnsavedDialogAttributes());
		unsaved.open = true;
		unsaved.yesClicked = isUndefined(yesAction) ? noop : yesAction;
		unsaved.noClicked = isUndefined(noAction) ? noop : noAction;
		unsaved.headerValue = isUndefined(header) ? this.defaultHeaderValue() : header;
		unsaved.messageValue = isUndefined(msg) ? this.defaultMessageValue() : msg;
	};

	public reset = (): void => {
		this.sessionExpiredListener();
		if (this.locationChangeListener) {
			this.locationChangeListener();
		}
		removeMessageListener(this.window, this.setDirtyFromEvent);
	};

	public verify = (redirect, url: string): void => {
		if (redirect) {
			this.setDirty(false);
			this.window.location = url;
		}
	};

	protected defaultHeaderValue = (): string => this.propertyFilter('html.commonInfra.components.unsaved.header');

	protected defaultMessageValue = (): string =>
		`${this.propertyFilter('html.commonInfra.components.unsaved.body1')}\n${this.propertyFilter('html.commonInfra.components.unsaved.body2')}`;

	private setDirtyFromEvent = (event): void => {
		if (isBoolean(event.data) && !startsWith(event.origin, CHROME_EXTENSIONS)) {
			this.setDirty(event.data);
		}
	};

	private addUnloadService = (): void => {
		this.beforeUnloadService.add(() => {
			if (this.isDirty) {
				sendPostMessageToFrameWithAction(false, 'info', 'close_popup');
				return this.propertyFilter('html.commonInfra.components.unsaved.body1');
			}
		});
	};

	private getUnsavedDialogAttributes = (): any => ({
		type: MESSAGE_TYPES.WARNING,
		headerAsKey: false,
		messageAsKey: false,
		showDialog: true
	});
}
