import {Component, Input, OnInit} from '@angular/core';
import {get, isString} from 'lodash';

import {ImpersonationAllowedUser, ImpersonationDetails} from '../../../../../core/navBar/models/user-impersonation-detail.model';
import {UserImpersonationBaseController} from '../../../../../core/navBar/controllers/user-impersonation-base.controller';
import {PropertyFactory} from '../../../../../core/property/property.factory';
import {WindowRefService} from '../../../../../services/window-ref.service';
import {MultipleEmploymentService} from './multiple-employment.service';

@Component({
	selector: 'krn-ng-multiple-employements',
	templateUrl: './multiple-employment.component.html'
})
export class MultipleEmploymentComponent extends UserImpersonationBaseController implements OnInit {
	@Input() public impersonationDetails: ImpersonationDetails;
	@Input() public menuItemTotal: number;
	public message: string;

	constructor(windowRef: WindowRefService, multipleEmploymentService: MultipleEmploymentService, propertyFactory: PropertyFactory) {
		super(windowRef.nativeWindow.window, multipleEmploymentService, propertyFactory);
	}

	public ngOnInit(): void {
		this.initialize();
	}

	public setErrorMessage(message?: string): void {
		this.message = message;
	}

	protected displayErrorMsg(error: any): void {
		const errorResponse = get(error, 'error', error);

		this.setErrorMessage(this.generateErrorMsg(isString(errorResponse) ? JSON.parse(errorResponse) : errorResponse));
	}
}
