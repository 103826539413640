import {Store} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {setRightMenuItems} from '@kronos/zed/src/lib/core/navBar/nav-bar.actions';
import {NavBarState} from '@kronos/zed/src/lib/core/navBar/nav-bar.state';
import {BootstrapService} from '@kronos/zed/src/lib/services/bootstrap.service';
import {NotificationActionItemComponent} from '@kronos/zed/src/lib/ui/framework/nav-bar/header/action-item/notification-action-item.component';
import {QuickFindActionItemComponent} from '@kronos/zed/src/lib/ui/framework/nav-bar/header/action-item/quick-find-action-item.component';

import {AppService} from '../app.service';
import {RouteResolver} from '../route.resolver';

@Injectable()
export class EssRouteResolver extends RouteResolver {
	constructor(protected store: Store<NavBarState>, bootstrapService: BootstrapService, injector: Injector, appService: AppService) {
		super(store, bootstrapService, injector, appService);
	}

	public resolve(route: ActivatedRouteSnapshot /* , state: RouterStateSnapshot */): Observable<any> | Promise<any> | any {
		this.store.dispatch(setRightMenuItems([QuickFindActionItemComponent.actionItemName, NotificationActionItemComponent.actionItemName]));
		return super.resolve(route /*, state */);
	}
}
