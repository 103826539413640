import {includes, has} from 'lodash';

import {ClientContext} from '../../platform/clientContainer/client-container.interface';
import {CordovaPluginWindow} from '../mobile/cordova-plugin-window.interface';

import {isOneApp, isWfdMobileApp} from '../../platform/services/DeviceConfig.utility';
import {isOneAppInSession, isSMA} from '../../embedded/embedded-page.utility';
import {IS_ONE_APP} from '../../embedded/embedded.constants';
import {SessionStorage} from '../caching/sessionStorage.service';

const KIOSK_ROUTE = 'wfd/kiosk';

export class ClientContainerContextCoreService {
	public isKiosk = false;
	private clientContext: ClientContext;
	constructor(protected window: CordovaPluginWindow, protected sessionStorage: SessionStorage) {
		this.clientContext = this.initClientContext();
	}

	public context = (): ClientContext => this.clientContext;

	public isEmbedded = (): boolean => includes([ClientContext.ONE_APP, ClientContext.EMBEDDED_SESSION, ClientContext.PRO_EMBEDDED], this.clientContext) || isOneAppInSession(this.sessionStorage.get(IS_ONE_APP));

	public isOneApp = (): boolean => this.clientContext === ClientContext.ONE_APP || isOneAppInSession(this.sessionStorage.get(IS_ONE_APP));

	public isSMA = (): boolean => this.clientContext === ClientContext.EMBEDDED_SESSION;

	public isProEmbedded = (): boolean => this.window && has(this.window, 'top.ukg');

	public isBrowser = (): boolean => this.clientContext === ClientContext.BROWSER;

	private initClientContext(): ClientContext {
		let context: ClientContext;

		if (isWfdMobileApp(this.window)) {
			context = ClientContext.WFD_MOBILE;
		} else if (isOneApp(this.window) || isOneAppInSession(this.sessionStorage.get(IS_ONE_APP))) {
			context = ClientContext.ONE_APP;
		} else if (isSMA(this.sessionStorage)) {
			context = ClientContext.EMBEDDED_SESSION;
		} else if (this.isProEmbedded()) {
			context = ClientContext.PRO_EMBEDDED;
		} else if (this.isKiosk || (this.window && this.window.location && includes(this.window.location.href, KIOSK_ROUTE))) {
			context = ClientContext.KIOSK;
		} else {
			context = ClientContext.BROWSER;
		}

		return context;
	}
}
