import {noop} from 'lodash';

import {ExportParams} from './server-file-saving.interface';

import {BrowserServerFileSavingCoreService} from './browser-server-file-saving.core.service';

export class EmbeddedSessionServerFileSavingCoreService extends BrowserServerFileSavingCoreService {
	public downloadFilePromise;

	public downloadFile(url: string, params: ExportParams, isDataViewDownload = false, callback: () => void = noop): Promise<any> {
		this.downloadFilePromise = super
			.downloadFile(url, params, false)
			.then(arg => this.window.dispatchEvent(new CustomEvent('upPostMessage', {detail: {method: 'exportFile', arg}})));
		return Promise.resolve();
	}

	// eslint-disable-next-line no-useless-escape
	public formatFileName = (fileName: string): string => fileName.replace(/[\/:?]/g, '_');
}
