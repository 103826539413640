import {assign} from 'lodash';
import {NGXLogger} from 'ngx-logger';

import {Preferences} from '../core/platform/preferences/preferences.interface';

import {PreferencesCoreService} from '../core/platform/preferences/preferences.service';
import {DataService} from './communication/data.service';
import {SessionStorageService} from './session-storage.service';
import {WindowRefService} from './window-ref.service';

export class PreferencesService extends PreferencesCoreService {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	private cachedPreference: Preferences = {} as Preferences;

	constructor(
		logger: NGXLogger,
		windowService: WindowRefService,
		storage: SessionStorageService,
		dataService: DataService,
		protected locale?: string
	) {
		super(logger, windowService, storage, dataService, locale);
	}

	public getPreferences(): Preferences {
		assign(this.cachedPreference, super.getPreferences());
		return this.cachedPreference;
	}

	protected setPreferences(preferences: Preferences): void {
		super.setPreferences(preferences);
		assign(this.cachedPreference, preferences);
	}
}
