<div class="modal-header">
  <h3 id="sessionUnavailableModalTitle" class="modal-title">{{'app.core.session.unavailable.title' | property}}</h3>
</div>
<div class="modal-body">{{'app.core.session.unavailable.body' | property:timeLeft}}</div>
<krn-ng-screen-reader-only
  id="sessionUnavailableModalDesc"
  [ariaLive]="isIOSDevice ? 'polite' : 'off'"
  removeTimeout="5000"
  [content]="{text: 'app.core.session.unavailable.body' | property:currentTimeLeft}">
</krn-ng-screen-reader-only>
<div class="modal-footer">
  <button id="sessionUnavailableModalButton" type="button" [attr.aria-label]="'app.core.session.unavailable.confirm' | property"  class="btn btn-primary" (click)="decline()">{{'app.core.session.unavailable.confirm' | property}}</button>
</div>
