import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

import {PreferencesCoreService} from '../../core/platform/preferences/preferences.service';
import {FloatCoreService} from '../../core/platform/converters/float-core.service';
import {ConverterUtilService} from './converter-util.service';

@Injectable({providedIn: 'root'})
export class FloatService extends FloatCoreService {
	constructor(logService: NGXLogger, preferencesService: PreferencesCoreService, converterUtilService: ConverterUtilService) {
		super(logService, preferencesService.getPreferences(), converterUtilService);
	}
}
