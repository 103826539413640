import {Injectable} from '@angular/core';
import moment from 'moment';

import {Duration} from '../../core/platform/converters/date-time-service.interfaces';

import {PreferencesCoreService} from '../../core/platform/preferences/preferences.service';
import {TimeServiceCore} from '../../core/platform/converters/time-service.core';
import {ConverterUtilService} from './converter-util.service';

@Injectable({providedIn: 'root'})
export class TimeService extends TimeServiceCore {
	constructor(preferencesService: PreferencesCoreService, converterUtilService: ConverterUtilService) {
		super(preferencesService.getPreferences(), converterUtilService, moment);
	}

	public hoursMinutesToDuration(isNegativeHours: boolean, hours: string, minutes: string): Duration {
		const negative = isNegativeHours ? '-' : '';

		return this.momentToDuration(moment.duration(`${negative}${hours}:${minutes}`, 'hours'));
	}

	public toDuration(value: number | string, units: string): Duration {
		return this.momentToDuration(moment.duration(value, units as moment.DurationInputArg2));
	}

	private momentToDuration = (source: moment.Duration): Duration => ({
		hours: source.hours(),
		minutes: source.minutes(),
		seconds: source.seconds(),
		durationInHours: source.asHours(),
		durationInMinutes: source.asMinutes(),
		durationInSeconds: source.asSeconds()
	});
}
