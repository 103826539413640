import {AfterContentChecked, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {get, debounce} from 'lodash';

import {FocusElementId} from '../../core/platform/accessibility/services/keyboard-navigation.interfaces';

import {FocusOnShowBase} from '../../core/platform/accessibility/focus-on-show-base.directive';

const DEBOUNCE_TIME = 500;

@Directive({
	selector: '[krn-ng-focus-on-show]'
})
export class FocusOnShowDirective extends FocusOnShowBase implements AfterContentChecked, OnChanges {
	@Input('krn-ng-focus-on-show') protected focusElementId: FocusElementId;
	@Input('focus-delay') protected focusDelay = 0;
	@Input('skip-focus-if') protected skipFocusIf: boolean;

	private setFocusFunc: () => void;

	constructor(protected elementRef: ElementRef<HTMLElement>) {
		super();
		this.element = elementRef.nativeElement;
		this.setFocusFunc = debounce(() => this.setFocusToElement(), DEBOUNCE_TIME);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		const currentFocusElementId: any = get(changes, 'focusElementId.currentValue');

		this.getWaitingFocusElementSelector(currentFocusElementId);
	}

	public ngAfterContentChecked(): void {
		this.setFocusFunc();
	}
}
