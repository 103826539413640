import {noop} from 'lodash';
import {createActions} from 'redux-actions';

import {AuthenticationState} from './authentication.state';

export const {authenticate, logout} = createActions({
	authenticate: authenticationAction,
	logout: noop
});

export function authenticationAction(error): Partial<AuthenticationState> {
	const isAuthenticated = !error;

	return {isAuthenticated, error};
}