import {Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges} from '@angular/core';
import {concat, has, includes, isString, join, size, toUpper, uniqueId} from 'lodash';

import {AvatarAttrs} from '../../core/avatar/avatar.interfaces';

import {getInitialsFromName} from '../../core/avatar/avatar.utility';
import {ALIGNMENTS, EMPLOYEE_IMAGE_SIZE, IMG_SRC, IMG_PATH, INITIAL} from '../../core/avatar/avatar.constants';
import {CommonConstants, NUMBERS} from '../../core/platform/constants/CommonConstants';
import {BASE_DATA_PATH, BASE_PATH} from '../../services/communication/data.config';

const SINGLE_NAME = 1;

@Component({
	selector: 'krn-ng-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.less']
})
export class AvatarComponent implements OnInit, OnChanges {
	@Input() public photoId: number | string;
	@Input() public photoName: string;
	@Input() public imageWidth: number;
	@Input() public imageHeight: number;
	@Input() public initials: string;
	@Input() public message: string;
	@Input() public secondary: string;
	@Input() public alignment: string;
	@Input() public attrs: AvatarAttrs;
	@Input() public imageClasses: string[] | string;
	@Input() public messageClasses: string[] | string;

	public showInitials: boolean;
	public calculatedInitials: string;
	public calculatedImageClasses: string;
	public calculatedInitialsClasses: string[];
	public photoAttrId: string;

	public imageStyle: {height: string; width: string};
	public imgSrc: string;

	private imagePath: string;
	private basePath: string;

	constructor(
		@Optional() @Inject(BASE_PATH) appBasePath: string,
		@Optional() @Inject(BASE_DATA_PATH) appDataPath: string
	) {
		this.showInitials = true;
		this.imgSrc = (appBasePath || '') + IMG_SRC;
		this.imagePath = (appDataPath || '') + IMG_PATH;
		this.basePath = this.imagePath;
		this.calculatedInitials = INITIAL;
		this.imageWidth = EMPLOYEE_IMAGE_SIZE.SMALL;
		this.imageHeight = EMPLOYEE_IMAGE_SIZE.SMALL;
	}

	public ngOnInit(): void {
		this.updateStyles();
		this.calculateImageClasses();
		this.calculateInitialsClasses();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (has(changes, 'photoId')) {
			this.updateImagePath();
			this.updateImage();
			this.updateImageId();
		}

		if (has(changes, 'imageClasses')) {
			this.calculateImageClasses();
		}

		if (has(changes, 'initials') || has(changes, 'photoName')) {
			this.setInitials();
		}

		if (has(changes, 'imageWidth') || has(changes, 'imageHeight')) {
			this.updateStyles();
			this.calculateInitialsClasses();
		}
	}

	public getLayout(): string {
		return `d-flex ${ALIGNMENTS[this.alignment || CommonConstants.HTML_ALIGNMENT.BOTTOM]} align-items-center`;
	}

	public getMessageAlignment(): string {
		const align = includes([CommonConstants.HTML_ALIGNMENT.RIGHT, CommonConstants.HTML_ALIGNMENT.LEFT], this.alignment)
			? `message-text-${this.alignment}`
			: '';

		return `message d-flex flex-column ${align}`;
	}

	private calculateImageClasses(): void {
		const calculatedImageClassesArray = concat(
			['employee-image', `avatar-color-${Math.floor(Math.random() * 16)}`],
			isString(this.imageClasses) ? this.imageClasses.split(' ') : this.imageClasses
		);

		this.calculatedImageClasses = join(calculatedImageClassesArray, ' ');
	}

	private calculateInitialsClasses(): void {
		const calculatedInitialsClassesArray = ['initials'];

		switch (Number(this.imageWidth)) {
			case EMPLOYEE_IMAGE_SIZE.EXTRA_SMALL:
				calculatedInitialsClassesArray.push('extra-small');
				break;
			case EMPLOYEE_IMAGE_SIZE.SMALL:
				calculatedInitialsClassesArray.push('small');
				break;
			case EMPLOYEE_IMAGE_SIZE.MEDIUM:
				calculatedInitialsClassesArray.push('medium');
				break;
			case EMPLOYEE_IMAGE_SIZE.LARGE:
				calculatedInitialsClassesArray.push('large');
				break;
			default:
				calculatedInitialsClassesArray.push('small');
				break;
		}

		this.calculatedInitialsClasses = calculatedInitialsClassesArray;
	}

	private setInitials(): void {
		let initialsFromName;

		if (!this.initials && this.photoName) {
			initialsFromName = getInitialsFromName(this.photoName);
		}
		const initials = (size(initialsFromName) === SINGLE_NAME ? undefined : initialsFromName) || this.initials || INITIAL;

		this.calculatedInitials = toUpper(
			size(initials) <= NUMBERS.TWO ? initials : initials.charAt(NUMBERS.ZERO) + initials.charAt(initials.length - NUMBERS.ONE)
		);
	}

	private updateStyles(): void {
		this.imageStyle = {
			width: `${this.imageWidth}rem`,
			height: `${this.imageHeight}rem`
		};
	}

	private updateImagePath(): void {
		this.imagePath = `${this.basePath}${this.photoId}`;
	}

	private updateImage(): void {
		if (this.photoId) {
			const image = new Image();

			image.onload = () => {
				this.imgSrc = this.imagePath;
				this.showInitials = false;
			};

			image.onerror = () => {
				this.showInitials = true;
			};

			image.src = this.imagePath;
		}
	}

	private updateImageId(): void {
		this.photoAttrId = this.photoId ? uniqueId(`${this.photoId}_`) : undefined;
	}
}
