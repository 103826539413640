import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {get} from 'lodash';

import {SlideOutActionService} from './slideout-action.service';

@Injectable({
	providedIn: 'root'
})
export class SlideOutHelperService {
	constructor(private router: Router, private slideOutActionService: SlideOutActionService) {}

	public closeSlider = (id?: string): void => {
		this.slideOutActionService.dispatch(this.slideOutActionService.actions.close(id));
	};

	public openSlider = (sliderRoute: string | string[], queryParams: object = {}, state: object = {}, skipLocationChange = true): void => {
		const serviceName = get(state, 'serviceName');

		this.router.navigate([{outlets: {'app-slideout': sliderRoute}}], {
			queryParams,
			state,
			skipLocationChange
		});
		if (serviceName) {
			this.slideOutActionService.dispatch(this.slideOutActionService.actions.open(serviceName));
		}
	};
}
