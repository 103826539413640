import {ActionReducerMap, ReducerManager, Store} from '@ngrx/store';
import {isEmpty} from 'lodash';

import {ReducersMapping} from '../core/platform/redux/redux.interfaces';

import {StoreManager} from '../core/platform/redux/store.manager';
import {ReducerRegistryService} from './reducer-registry.service';

export class StoreManagerService extends StoreManager<ActionReducerMap<any, any>> {
	constructor(protected reducerRegistry: ReducerRegistryService, private store: Store, private reducerManager: ReducerManager) {
		super(reducerRegistry);
		this.initReducerSubscriber();
	}

	public getStore(): Store {
		return this.store;
	}

	protected updateStoreReducers(reducers: ReducersMapping<ActionReducerMap<any, any>>): void {
		if (reducers.newReducers) {
			this.reducerManager.addReducers(reducers.newReducers);
			if (!isEmpty(reducers.newReducers)) {
				this.store.dispatch(this.registerReducers(reducers.newReducers));
			}
		}
		if (reducers.namesToUnRegister) {
			this.reducerManager.removeReducers(reducers.namesToUnRegister);
		}
	}
}
