import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DeprecationMessageService} from '../../services/deprecation-message/deprecation-message.service';
import {PropertyModule} from '../../providers/property/property.module';
import {ButtonModule} from '../button/button.module';
import {SimpleInputModule} from './../simple-input/simple-input.module';
import {SearchComponent} from './search.component';

/**
 * @deprecated This search component is deprecated and will be removed in the future in favor of components found within the Ignite design system. [Click here for more details](https://engstash.int.kronos.com/projects/FRONT/repos/frontend/browse/packages/web/zed/README.md)
 */
@NgModule({
	declarations: [SearchComponent],
	exports: [SearchComponent],
	imports: [ButtonModule, CommonModule, PropertyModule, SimpleInputModule]
})
export class SearchModule {
	constructor(private deprecationMessageService: DeprecationMessageService) {
		this.deprecationMessageService.log('search');
	}
}
