import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {get, reject} from 'lodash';

import {ManagerRoleService} from '../../../../../core/navBar/manager-role.service';
import {FlatDelegatedManagerRole, ManagerRole, ManagerRoleData, ManagerRoleDelegation} from '../../../../../core/navBar/models/manager-role.models';
import {PropertyFactory} from '../../../../../core/property/property.factory';
import {NavBarMenuService} from '../../nav-bar-menu.service';
import {PropertyFilterPipe} from '../../../../../providers/property/property.filter.pipe';
import {DeviceConfigService} from '../../../../../services/device-config.service';

const MY_SELF_ID = '-1337';

@Component({
	selector: 'krn-ng-manager-role-list',
	templateUrl: './manager-role-list.component.html',
	providers: [PropertyFilterPipe, {provide: 'window', useValue: window}]
})
export class ManagerRoleListComponent implements OnInit {
	@Input('active-role') public activeRole: ManagerRole;
	@Input('delegate-active-role') public delegateActiveRole: any;
	@Input('menu-item-pos') public menuItemPos: number;
	@Input('menu-item-total') public menuItemTotal: number;
	@Input() public roles: ManagerRole[];
	@Input() public delegations: ManagerRoleDelegation[];

	@ViewChild('popoverButtonContainer')
	public popoverButtonContainer: ElementRef;

	public propertiesLoaded: boolean;
	public buttonConfig: any;
	public isCollapsed: boolean;
	public managerRoleData: ManagerRoleData;
	public assignedRoles: ManagerRole[];
	public flatDelegatedRoles: FlatDelegatedManagerRole[];
	public infoPopoverText: string;
	public totalMenuItem: number;

	private readonly indexStep = 1;
	private isSafari: boolean;

	constructor(
		private managerRoleService: ManagerRoleService,
		private propertyFactory: PropertyFactory,
		private propertyFilterPipe: PropertyFilterPipe,
		private navBarMenuService: NavBarMenuService,
		private deviceConfigService: DeviceConfigService,
		@Inject('window') private window: any
	) {
		this.propertiesLoaded = false;
		this.isCollapsed = false;
	}

	public ngOnInit(): void {
		this.propertyFactory
			.loadProperties([
				{
					name: 'container_web-common_nav-bar_strings.properties',
					path: 'components/zed-core/properties/navBar/'
				}
			])
			.then(() => {
				this.buttonConfig = this.managerRoleService.getButtonConfig(this.toggleCollapsed.bind(this));
				this.propertiesLoaded = true;
			});
		this.flatDelegatedRoles = this.getFlatDelegatedRoles(this.delegations);
		this.assignedRoles = this.prepareRoles();
		this.delegations = reject(this.delegations, {id: MY_SELF_ID});
		this.totalMenuItem = this.assignedRoles.length + this.delegations.length;
		this.isSafari = this.deviceConfigService.isSafari(this.window.navigator);
	}

	public getRoleString(role: ManagerRole): string {
		return this.managerRoleService.getRoleString(role);
	}

	public getRoleLabel(role: ManagerRole, roleIndex: number): string {
		const rolelabel = this.isActiveRole(role.assignmentId)
			? 'html.navbar.roles.type.assigned.list.title.selected'
			: 'html.navbar.roles.type.assigned.list.title';

		return this.propertyFilterPipe.transform(rolelabel, [this.getRoleString(role), roleIndex + this.indexStep, this.totalMenuItem]);
	}

	public getDelegatedRoleString(delegatedRole: FlatDelegatedManagerRole): string {
		return this.managerRoleService.getDelegatedRoleString(delegatedRole);
	}

	public getDelegateString(person): string {
		return this.managerRoleService.getDelegateString(person);
	}

	public getDelegateLabel(delegateRole: ManagerRoleDelegation, delegateIndex: number): string {
		const rolelabel = this.isActiveDelgateRole(delegateRole.id)
			? 'html.navbar.roles.type.delegated.list.title.selected'
			: 'html.navbar.roles.type.delegated.list.title';

		return this.propertyFilterPipe.transform(rolelabel, [
			this.getDelegateString(delegateRole),
			delegateIndex + this.indexStep + this.assignedRoles.length,
			this.totalMenuItem
		]);
	}

	public isActiveRole(assignmentId: number): boolean {
		return get(this.activeRole, 'assignmentId') === assignmentId;
	}

	public isActiveDelgateRole(roleId: number | string): boolean {
		return get(this.delegateActiveRole, 'id', '') === roleId;
	}

	public toggleCollapsed(): void {
		this.isCollapsed = !this.isCollapsed;
	}

	public keydownCallback(event): void {
		this.navBarMenuService.menuArrowKeyCallback(this.isCollapsed, event, this.toggleCollapsed.bind(this));
	}

	public handleSubMenuKeyDown(event): void {
		this.navBarMenuService.handleSubMenuKeyDown(event);
	}

	public performRoleSwitch(role: ManagerRole): void {
		this.managerRoleService.performRoleSwitch(role).then(this.reloadPageIfNeeded.bind(this));
	}

	public performDelegatedRoleSwitch(role: FlatDelegatedManagerRole): void {
		this.managerRoleService.performDelegatedRoleSwitch(role).then(this.reloadPageIfNeeded.bind(this));
	}

	public getSubMenuAriaLabel(): string {
		return this.propertyFilterPipe.transform(
			`html.navBar.profileMenu.settings.subMenu.${this.isSafari ? (this.isCollapsed ? 'safari.collapsed.' : 'safari.expanded.') : ''}ariaLabel`,
			[this.buttonConfig.text]
		);
	}

	private reloadPageIfNeeded(shouldReloadPage: boolean): void {
		if (shouldReloadPage) {
			this.window.location.href = '/';
		}
	}

	private prepareRoles(): ManagerRole[] {
		const allowedRoles = this.managerRoleService.filterAllowed(this.roles);

		return this.managerRoleService.sort(allowedRoles);
	}

	/**
	 * Flat map manager delegations since one manager delegation can contain several delegated roles
	 * @param delegations manager delegations
	 */
	private getFlatDelegatedRoles(delegations: ManagerRoleDelegation[]): FlatDelegatedManagerRole[] {
		return this.managerRoleService.flatMap(delegations);
	}
}
