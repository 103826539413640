import {Inject, Injectable} from '@angular/core';

import {ApiService} from '../core/communication/api-service/api-service.interfaces';

import TelemetryCoreService from '../core/platform/services/telemetry.core.service';
import {DataService} from './communication/data.service';
import {LoggerNetworkStreamService} from './logger-network-stream.service';

@Injectable({
	providedIn: 'root'
})
export class TelemetryService extends TelemetryCoreService {
	constructor(@Inject(DataService) protected apiService: ApiService, protected loggerNetworkStreamService: LoggerNetworkStreamService) {
		super(apiService, window, loggerNetworkStreamService);
	}
}
