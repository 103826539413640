import {CordovaPluginWindow} from '../../mobile/cordova-plugin-window.interface';

import DeviceConfigCoreService from '../DeviceConfigServices';
import {BrowserOfflineButtonCoreService} from './browser-offline-button-core.service';

export class MobileOfflineButtonCoreService extends BrowserOfflineButtonCoreService {
    constructor(private deviceConfigService: DeviceConfigCoreService, private windowRef: CordovaPluginWindow) {
		super();
	}

	// krnDataLoad is global loaded by mobile app
	public canWorkOffline = (): boolean => this.deviceConfigService.isMobileApp() ? this.windowRef.krnDataLoad.hasFACP() : false;

	public runOfflineMode = (): void => {
		// This will cause the page to load the offline entry screen in the dimensions mobile app.
		this.windowRef.krnDataLoad.getOfflineBundlePath();
	};

}