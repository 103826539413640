import {Injectable} from '@angular/core';

import {DayCoreService} from '../../core/platform/converters/day-core.service';
import {FloatService} from './float.service';

@Injectable({providedIn: 'root'})
export class DayService extends DayCoreService {
	constructor(floatService: FloatService) {
		super(floatService);
	}
}
