import {Injectable} from '@angular/core';
import {get} from 'lodash';
import {NGXLogger} from 'ngx-logger';

import {ApiService} from '../core/communication/api-service/api-service.interfaces';
import {EmbeddedMenuCategory, LegacyEmbeddedMenuItem} from '../core/embedded/embedded.interfaces';

import {EmbeddedMenuItemsCoreService} from '../core/embedded/embedded-menu-items.core.service';
import {SessionStorageCoreService} from '../core/platform/caching/sessionStorage.service';
import {PropertyFactory} from '../core/property/property.factory';
import {DataService} from './communication/data.service';
import {SessionStorageService} from './session-storage.service';

@Injectable({providedIn: 'root'})
export class EmbeddedMenuItemsService extends EmbeddedMenuItemsCoreService {
	constructor(apiService: DataService, logService: NGXLogger, propertyFactory: PropertyFactory, sessionStorage: SessionStorageService) {
		super(apiService as ApiService, logService, propertyFactory, sessionStorage as SessionStorageCoreService);
	}

	public getData = (menus: EmbeddedMenuCategory[] | LegacyEmbeddedMenuItem[], key: string): any => get(menus, `body.${key}`);
}
