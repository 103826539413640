import {camelCase, isEmpty} from 'lodash';

import {MenuItemConfig} from './models/menu-item-config.model';
import {SearchInputConfig} from './search-input';

export const getFilteredMenu = (
	menu: MenuItemConfig[],
	searchText: string,
	initialFoundLinkAmount = 0,
	currentSearchId?: string
): SearchInputConfig => {
	const filteredMenu: any = [];
	let foundLinksAmount = initialFoundLinkAmount;
	let searchId = currentSearchId;

	menu.forEach(menuItem => {
		if (menuItem.isNested) {
			const categoryObj = getCategory(menuItem, searchText, foundLinksAmount);

			if (categoryObj) {
				foundLinksAmount = categoryObj.foundLinksAmount;
				categoryObj.searchId = categoryObj.id + categoryObj.searchId;
				filteredMenu.push(categoryObj);
				categoryObj.automationId = getAutomationId(categoryObj);
			}
		} else if (menuItem.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
			foundLinksAmount++;
			menuItem.searchId = menuItem.id.toString();
			searchId = isEmpty(searchId) ? menuItem.searchId : searchId + menuItem.searchId;
			filteredMenu.push(menuItem);
			menuItem.automationId = getAutomationId(menuItem);
		}
	});
	return {filteredMenu, foundLinksAmount, searchId};
};

function getCategory(category: MenuItemConfig, searchText: string, foundLinksAmount: number): MenuItemConfig | void {
	const menu = new MenuItemConfig(category);
	const filteredMenuResults = getFilteredMenu(category.menu, searchText, foundLinksAmount, category.searchId);

	menu.filteredMenu = filteredMenuResults.filteredMenu;
	menu.searchId = filteredMenuResults.searchId;
	menu.foundLinksAmount = filteredMenuResults.foundLinksAmount;

	if (!menu.filteredMenu.length) {
		return;
	}

	return menu;
}

function getAutomationId(item: MenuItemConfig): string {
	const postfix = item.isNested ? 'MenuCategory' : 'MenuLink';

	return camelCase(item.text) + postfix;
}
