import * as jQuery from 'jquery';
import {find, get, isFunction, toLower, toUpper} from 'lodash';

import {Preferences} from './preferences/preferences.interface';

import {ZOOM_OVERFLOW_VISIBLE_CLASS, ZOOM_VIEW_CONTENT_CLASS} from './constants/desktop-mimic-mobile.constants';
import {FOCUSABLE_ELEMENTS, SKIPTOMAIN_FOCUS_TIMEOUT} from './accessibility/utils.constants';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const FusionCharts: any;
const $: any = jQuery;
let isLicensed = false;

export const DEFAULT_LANGUAGE_CODE = 'en';

export const setHTMLLanguage = (preferences: Preferences): void => {
	const countryCode = get(preferences, 'localePolicy.countryCode');
	const languageCode = toLower(get(preferences, 'localePolicy.languageCode', DEFAULT_LANGUAGE_CODE));
	const langCode = countryCode ? `${languageCode}-${toUpper(countryCode)}` : languageCode;

	$('html').attr('lang', langCode);
};

export const getHTMLLanguage = (): string => $('html').attr('lang');

export const addDesktopMimicMobileClass = (): void => {
	$('body').addClass(ZOOM_OVERFLOW_VISIBLE_CLASS);
	$('.view-content').addClass(ZOOM_VIEW_CONTENT_CLASS);
};

export const removeDesktopMimicMobileClass = (): void => {
	$('body').removeClass(ZOOM_OVERFLOW_VISIBLE_CLASS);
	$('.view-content').removeClass(ZOOM_VIEW_CONTENT_CLASS);
};

export const focusOnMain = (): void => {
	const allFocusableElements = document.querySelector('main').querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS);
	const targetElement = find(
		Array.from(allFocusableElements),
		element => element.clientHeight && element.clientWidth && element.getAttribute('tabindex') !== ''
	);

	if (targetElement) {
		setTimeout(() => targetElement.focus(), SKIPTOMAIN_FOCUS_TIMEOUT);
	}
};

export const pageNotInIframe = (window: Window): boolean => window.location === window.parent.location;

export const activateFusionChartsLicense = (): void => {
	const configLicense = get(FusionCharts, 'options.license');
	const fusionchartsLicenseKey = $('meta[name="fusionCharts-key"]').attr('content');

	if (!isLicensed && isFunction(configLicense)) {
		configLicense({
			key: fusionchartsLicenseKey,
			creditLabel: false
		});
		isLicensed = true;
	}
};

export const retrieveAgGridLicense = (): string => $('meta[name="ag-grid-key"]').attr('content');
