import {Pipe, PipeTransform} from '@angular/core';

import {MenuItemConfig} from '../../../../../core/navBar/models/menu-item-config.model';
import {SearchInput} from '../../../../../core/navBar/search-input';
@Pipe({
	name: 'menuItemFilter'
})
export class MenuItemFilterPipe implements PipeTransform {
	public transform(items: MenuItemConfig[] = [], searchText = ''): MenuItemConfig[] {
		const searchInput = new SearchInput();
		const toggledItems = items.map(item => {
			const expanded = !!searchText;
			const options = {...item.options, expanded};

			return {...item, options};
		});

		return searchInput.getFilteredMenuAndSetFoundLinks(toggledItems, searchText);
	}
}
