import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, ActivationStart, Router} from '@angular/router';
import {filter} from 'rxjs';

import {ApiService} from '../../../../../../core/communication/api-service/api-service.interfaces';

import {UserInfoService} from '../../../../../../core/framework/services/user-info.service';
import {returnFocusId} from '../../../../../../core/navBar/nav-bar-action-item.service';
import {getHelpContext} from '../../../../../../core/navBar/contextual-help/contextual-help.utils';
import {PropertyFactory} from '../../../../../../core/property/property.factory';
import {accessibilitySpeakerRead} from '../../../../../../services/accessibility/accessibility-speaker.utility';
import {DataService} from '../../../../../../services/communication/data.service';
import {DeviceConfigService} from '../../../../../../services/device-config.service';
import {SessionStorageService} from '../../../../../../services/session-storage.service';
import {SlideOutHelperService} from '../../../../../../services/slide-out-helper.service';
import {WindowRefService} from '../../../../../../services/window-ref.service';
import {PropertyFilterPipe} from '../../../../../../providers/property/property.filter.pipe';
import {ActionItemBaseComponent, ActionItemOptions} from '../action-item-base.component';
import {ContextualHelpService} from './contextual-help.service';

const LABEL_PROPERTY = 'html.navBar.help';

@Component({
	templateUrl: '../action-item.template.html',
	providers: [PropertyFilterPipe]
})
export class HelpActionItemComponent extends ActionItemBaseComponent implements OnInit {
	public static readonly DEFAULT = true;
	public static readonly actionItemName = 'HelpActionItemComponent';
	public static readonly actionItemOptions: Partial<ActionItemOptions> = {
		automationId: 'contextualHelpHeaderButton',
		id: 'contextualHelp',
		icon: 'icon-k-help',
		text: LABEL_PROPERTY,
		title: LABEL_PROPERTY,
		tooltip: LABEL_PROPERTY,
		mainClasses: 'item-question',
		btnClasses: 'contextual-help btn'
	};

	constructor(
		@Inject(DataService) private dataService: ApiService,
		private contextualHelpService: ContextualHelpService,
		private deviceConfigService: DeviceConfigService,
		public propertyFactory: PropertyFactory,
		public propertyFilterPipe: PropertyFilterPipe,
		public route: ActivatedRoute,
		private router: Router,
		private sessionStorageService: SessionStorageService,
		slideOutHelperService: SlideOutHelperService,
		private userInfoService: UserInfoService,
		private windowRef: WindowRefService
	) {
		super(HelpActionItemComponent.actionItemOptions, propertyFactory, propertyFilterPipe, route, slideOutHelperService);
	}

	public ngOnInit(): void {
		this.router.events.pipe(filter(event => event instanceof ActivationStart)).subscribe(this.onNavigationStart);
	}

	public async onClick(event: Event): Promise<void> {
		const helpContext = await getHelpContext(this.userInfoService, this.sessionStorageService, this.dataService, this.contextualHelpService.get());

		if (this.deviceConfigService.isMobileApp() && helpContext) {
			this.windowRef.nativeWindow.cordova.openUrlInBrowserTab(helpContext.contextualPageUrl);
		} else {
			this.context = helpContext;
			super.onClick(event);
		}
	}

	public onFocus = (): void => {
		if (this.deviceConfigService.isMobileApp()) {
			accessibilitySpeakerRead(this.propertyFilterPipe.transform('html.navBar.help.mobile.openTip'));
		}
	};

	public returnFocusId(): string {
		return returnFocusId(this.deviceConfigService.isMobileDevice(), super.returnFocusId());
	}

	private onNavigationStart = (event: ActivationStart): void => {
		if (event?.snapshot?.outlet === 'primary') {
			this.contextualHelpService.remove();
		}
	};
}
