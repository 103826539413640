import {NavBarMenuItemCore} from '../../../../../core/navBar/nav-bar.menu-item';
import {SessionStorageService} from '../../../../../services/session-storage.service';

export function getNavBarMenuItemCore(sessionStorageService): any {
	return new NavBarMenuItemCore(sessionStorageService);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MenuItemProvider = {
	provide: NavBarMenuItemCore,
	deps: [SessionStorageService],
	useFactory: getNavBarMenuItemCore
};
