import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

import {Duration} from '../../core/platform/converters/date-time-service.interfaces';

import {PreferencesCoreService} from '../../core/platform/preferences/preferences.service';
import {HourCoreService} from '../../core/platform/converters/hour-core.service';
import {ConverterUtilService} from './converter-util.service';
import {FloatService} from './float.service';
import {TimeService} from './time.service';

@Injectable({providedIn: 'root'})
export class HourService extends HourCoreService {
	constructor(
		logService: NGXLogger,
		preferencesService: PreferencesCoreService,
		converterUtilService: ConverterUtilService,
		floatService: FloatService,
		private timeService: TimeService
	) {
		super(logService, preferencesService.getPreferences(), converterUtilService, floatService);
	}

	protected hoursMinutesToDuration(isNegativeHours: boolean, hours: string, minutes: string): Duration {
		return this.timeService.hoursMinutesToDuration(isNegativeHours, hours, minutes);
	}

	protected toDuration(value: number, units: string): Duration {
		return this.timeService.toDuration(value, units);
	}
}
