import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {get, includes} from 'lodash';
import {NGXLogger} from 'ngx-logger';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ApiService} from '../../../core/communication/api-service/api-service.interfaces';
import {UserInfo} from '../../../core/framework/services/user-info.interface';

import {AuthenticationCoreService} from '../../../core/authentication/authentication.core.service';
import {LOGIN_CONSTANTS, MESSAGES} from '../../../core/authentication/authentication.constants';
import {SESSION_EVENTS} from '../../../core/session/session.constants';
import {PreferencesCoreService} from '../../../core/platform/preferences/preferences.service';
import {setHTMLLanguage} from '../../../core/platform/platform.utility';
import {Bootstrappable} from '../../../services/bootstrap.service';
import {BrandingService} from '../../../services/branding.service';
import {DataService} from '../../../services/communication/data.service';
import {ExternalAuthService} from '../../../services/external-auth-service';
import {UserInfoActionService} from '../../../services/user-info-action.service';
import {WindowRefService} from '../../../services/window-ref.service';
import {AuthenticationStore} from './authentication.store';
import {SessionActionService} from '../session/session.action';
import {LocalStorageService} from '../../../services';

@Injectable()
export class AuthenticationService extends AuthenticationCoreService implements Bootstrappable, OnDestroy {
	private notifier$ = new Subject();

	constructor(
		protected router: Router,
		protected windowRefService: WindowRefService,
		@Inject(DataService) protected dataService: ApiService,
		private externalAuthService: ExternalAuthService,
		protected logger: NGXLogger,
		protected preferencesService: PreferencesCoreService,
		protected brandingService: BrandingService,
		protected authenticationStore: AuthenticationStore,
		private sessionActionService: SessionActionService,
		private userInfoActionService: UserInfoActionService,
		protected localStorageService: LocalStorageService
	) {
		super(dataService, logger, brandingService, windowRefService.nativeWindow.window);
		this.updateAuthStateIfAuthCookiesPresent();

		this.authenticationStore
			.selector('isAuthenticated')
			.pipe(takeUntil(this.notifier$))
			.subscribe(status => {
				if (status) {
					this.preferencesService.fetchPreferences().then(preferences => {
						setHTMLLanguage(preferences);
					});

					brandingService.getColorsSync();
					this.externalAuthService.initialize();
				}
			});

		this.sessionActionService
			.select('status')
			.pipe(takeUntil(this.notifier$))
			.subscribe((status: string) => {
				if (status === SESSION_EVENTS.ENDED || status === SESSION_EVENTS.EXPIRED) {
					this.navigateToLogoutRoute();
				}
			});
		this.authenticationStore
			.selector('authenticationErrorMessage')
			.pipe(takeUntil(this.notifier$))
			.subscribe((authenticationErrorMessage: string) => {
				if (authenticationErrorMessage === MESSAGES.LOGINLIMITREACHED_MESSAGE) {
					this.router.navigateByUrl(LOGIN_CONSTANTS.UNAUTHORIZED_URL);
				}
			});

		this.userInfoActionService
			.select(['userInfo'])
			.pipe(takeUntil(this.notifier$))
			.subscribe((data: UserInfo) => {
				const isSupportUser = Boolean(get(data, 'isSupportUser', false));

				this.getLogoutUrl(data.auth0Login && !isSupportUser).then(url => this.setDefaultLogoutUrl(url));
			});
	}

	public ngOnDestroy(): void {
		this.notifier$.next(undefined);
		this.notifier$.complete();
	}

	public async bootstrap(): Promise<void> {
		await this.initiateLogin();
	}

	public getQueryParamsInURL(urlString: string): URLSearchParams | null {
		try {
			const url = new URL(urlString);

			//console.log(`URL created: ${url}`);
			const params = new URLSearchParams(url.search);

			return params;
		} catch (error) {
			//console.error('Error parsing URL:', error);
			return null;
		}
	}

	public async initiateLogin(): Promise<void> {
		if (includes(this.windowRefService.nativeWindow.window.location.href, 'wfd/ukgpro/launcher')) {
			return Promise.resolve();
		}

		const env = this.windowRefService.nativeWindow.window.cordova ? {...this.windowRefService.nativeWindow.window.cordova} : document.cookie;

		await this.kioskAuth0Login(env);
		return super.login(env);
	}

	public async isAuth0Tenant(): Promise<boolean> {
		let isAuth0Tenant = false;

		const auth0TenantInStorage = this.localStorageService.get('isAuth0Tenant');

		if (auth0TenantInStorage) {
			return auth0TenantInStorage;
		}

		const cxTenants: string[] = ['manufacturing', 'healthcare', 'combined', 'retail'];

		if (cxTenants.includes(new URL(this.windowRefService.nativeWindow.window.location.href).searchParams.get('tenantId'))) {
			return false;
		}

		await this.dataService.create('/kiosk/auth0Login', {vanity_url: this.windowRefService.nativeWindow.window.location.host},
			{checkIsAuth0Tenant: true}).then(result => {
			isAuth0Tenant = result;
			this.localStorageService.set('isAuth0Tenant', isAuth0Tenant);
		}, error => {
			this.logger.error('Some error occured while checking if tenant is Auth0 or not.');
		});

		return isAuth0Tenant;
	}

	public logout(): void {
		super.establishLogout();
	}

	public navigateToLogoutRoute(): void {
		this.router.navigate(['logout']);
	}

	public dispatchActionAuthenticateError(errorObject: any): void {
		this.authenticationStore.updateAuthenticateState(errorObject);
	}

	protected dispatchActionLogout(): void {
		this.authenticationStore.updateLogoutState();
	}

	protected dispatchActionAuthenticate(): void {
		this.authenticationStore.updateAuthenticateState();
	}

	protected async kioskAuth0Login(env: {isAuthenticated: () => boolean} = null): Promise<any> {
		if (!this.isAuthenticationDone(env)) {
			if (this.windowRefService.nativeWindow.window.location.pathname.match('^/wfd/kiosk/?$') && await this.isAuth0Tenant()) {
				await this.dataService.create('/kiosk/auth0Login', {vanity_url: this.windowRefService.nativeWindow.window.location.host}, {tenantId: 'DEFAULT'})
					.then(response => {
						if (response.redirect_url) {
							// redirect to the Auth0 login page:
							this.windowRefService.nativeWindow.window.location.href = response.redirect_url;
						} else {
							return response.json(); // or however you normally process the response
						}
					}, error => {
						if (error.status === 302) {
							const redirectUrl = error.headers.get('Location');

							if (redirectUrl) {
								this.windowRefService.nativeWindow.window.location.href = redirectUrl;
								return;
							}
						}
						return error.json();
						//console.log('Error while trying to call backend for login auth0.');
					});
			} else if (includes(this.windowRefService.nativeWindow.window.location.href, 'code=') &&
				includes(this.windowRefService.nativeWindow.window.location.href, 'wfd/kiosk/auth0Login')) {
				// Getthe authcode returned by Auth0 after user entered credentials have been verified by Auth0
				const params = this.getQueryParamsInURL(this.windowRefService.nativeWindow.window.location.href);
				const authcode = params.get('code');
				const tenantShortname = params.get('tenantId');

				await this.dataService.create('/kiosk/auth0Callback',
					{authcode, vanity_url: this.windowRefService.nativeWindow.window.location.host}, {tenantId: tenantShortname})
					.then(output => {
						// Storing received access token expiry time into localStorage so that Token Refresh Daemon can read it
						const tokenExpiryTime = output.body.kioskAuth0TokenExpiry;

						this.localStorageService.set('kioskAuth0TokenExpiry', tokenExpiryTime);
						this.localStorageService.set('kioskAuth0ClientLogin', true);

						this.windowRefService.nativeWindow.window.location.href = `${this.windowRefService.nativeWindow.window.location.origin}/wfd/kiosk/login`;
					});
			}
		}
	}
}
