import {escape, isNil, trim} from 'lodash';

import {PropertyStateCoreService} from './propertyState.core.service';

export type PropertyFilterConfig = (input: string, replacements?: any[], applyEscape?: any) => string;

export function propertyFilterInit(propertyStateService: PropertyStateCoreService): PropertyFilterConfig {
	return (input: string, replacements: any[] = [], applyEscape = false): string => {

		let loadedProperty: string = propertyStateService.getProperty(input);

		if (replacements && loadedProperty) {
			loadedProperty = loadedProperty.replace(/\{(\d)\}/g, match => {
				const index = Number(trim(match, '{}'));

				return applyEscape ? escape(replacements[index]) : isNil(replacements[index]) ? '' : replacements[index];
			});
		}
		return loadedProperty;
	};
}
