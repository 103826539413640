import {Component, OnInit} from '@angular/core';
import {get} from 'lodash';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {NewReleaseFeaturePreference} from '../../../core/framework/services/user-info.interface';

import {NewFeaturesComponent} from './new-features.component';
import {NewFeaturesService} from './new-features.service';

@Component({
	selector: 'krn-ng-new-features-modal',
	template: '<div></div>'
})
export class NewFeaturesModalComponent implements OnInit {
	private preferences: NewReleaseFeaturePreference;

	constructor(private modalService: BsModalService, private newFeaturesService: NewFeaturesService) {}

	public async ngOnInit(): Promise<void> {
		this.preferences = await this.newFeaturesService.showNewReleaseFeature();

		if (this.showNewFeatures()) {
			const config = {class: 'modal-lg new-feature-modal', backdrop: true, ignoreBackdropClick: true};

			this.modalService.show(NewFeaturesComponent, config);
			this.newFeaturesService.newPpopupFeatureShowed();
		}
	}

	private showNewFeatures(): boolean {
		return get(this.preferences, 'enabled', false) && !get(this.preferences, 'hasAlertOpened', false);
	}
}
