import {isString} from 'lodash';

import {FloatCoreService} from './float-core.service';

export class DayCoreService {
	constructor(private floatService: FloatCoreService) {}

	public isValid = (durationStr): boolean => isString(durationStr) ? this.floatService.isValid(durationStr) : false;

	public format = (duration): string => this.floatService.format(duration);

	public parse = (durationStr): number => {
		if (!this.isValid(durationStr)) {
			throw new Error();
		}
		return this.floatService.parse(durationStr);
	};
}
