import {get, includes, some} from 'lodash';
import {fromEvent} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';

import {ScanResult} from '../platform/clientContainer/client-container.interface';
import {CordovaPluginWindow} from '../platform/mobile/cordova-plugin-window.interface';
import {EmbeddedIframeActions, EmbeddedLocalAuthType, EmbeddedMessageSource, EmbeddedPostMessage} from './embedded.interfaces';
import {LocalAuth} from '../platform/mobile/local-auth.interface';

import {EMBEDDED_SESSION} from '../authentication/authentication.constants';
import {postMessage} from '../iframe-framework/iframe-message-handler.utility';
import {SessionStorage} from '../platform/caching/sessionStorage.service';
import {LOCAL_AUTH_INT_ERR_CANNOT_USE} from '../platform/mobile/local-auth.constants';
import {LOCAL_AUTH_REQUEST_ID, SCAN_REQUEST_ID, TARGET_ORIGIN, UKGPRO_MENU_ITEMS} from './embedded.constants';

export const isEmbeddable = (path: string, sessionStorage: SessionStorage): boolean => {
    const ukgproMenuItems = get(sessionStorage.get(UKGPRO_MENU_ITEMS), 'menu', []);
    const pattern = get(/^\/?(.+?)(\?.+)?$/.exec(path), '1');

    return some(ukgproMenuItems, item => pattern && new RegExp(pattern).test(item.uri || item.url));
};

export const isSMA = (sessionStorage: SessionStorage): boolean => includes(location.href, '/wfd/ukgpro/launcher') || sessionStorage.get(EMBEDDED_SESSION) === 'true';

export const isOneAppInSession = (key: any): boolean => isKeyTruthy(key);

export const isKeyTruthy = (key: any): boolean => key === 'true' || key === true;

export const postMessageEmbedded = (window: any, payload: EmbeddedPostMessage): void => {
    postMessage<EmbeddedPostMessage>(window.top, TARGET_ORIGIN, {...payload, source: EmbeddedMessageSource.DIMENSIONS});
};

export const requestLocalAuthentication = (
    window: CordovaPluginWindow, 
    authType: EmbeddedLocalAuthType | string, 
    sessionStorage: SessionStorage
): Promise<LocalAuth> => {
    if (isSMA(sessionStorage) && includes([EmbeddedLocalAuthType.ANY_AUTH, EmbeddedLocalAuthType.BIO_AUTH], authType)) {
        const localAuthToCall = authType === EmbeddedLocalAuthType.BIO_AUTH
                                    ? EmbeddedIframeActions.CHALLENGE_BIOMETRICS
                                    : EmbeddedIframeActions.CHALLENGE_LOCAL_AUTH;

        postMessageEmbedded(window, {requestId: LOCAL_AUTH_REQUEST_ID,  action: localAuthToCall});
        return fromEvent<MessageEvent>(window, 'message').pipe(
            filter((event: MessageEvent) => event.data && event.data.requestId === LOCAL_AUTH_REQUEST_ID),
            map((event: MessageEvent) => {
                if ((event.data.isBiometricAuthAvailable || event.data.isLocalAuthAvailable) && event.data.result) {
                    return {status: true};
                }
                return {status: false, msg: LOCAL_AUTH_INT_ERR_CANNOT_USE};
            }),
            take(1)
        ).toPromise();
    }
    
    return Promise.reject(LOCAL_AUTH_INT_ERR_CANNOT_USE);
};

export const scan = (window: CordovaPluginWindow): Promise<ScanResult> => {
    postMessageEmbedded(window, {requestId: SCAN_REQUEST_ID, action: EmbeddedIframeActions.SCAN});
    return fromEvent<MessageEvent>(window, 'message').pipe(
        filter((event: MessageEvent) => get(event, 'data.requestId') === SCAN_REQUEST_ID),
        map((event: MessageEvent) => get(event, 'data.scanResult')),
        take(1)
    ).toPromise();
};