import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IgniteModule} from '@ignite/angular';

import {PropertyModule} from '../../../providers/property/property.module';
import {DelegatedRoleBannerComponent} from './delegated-role-banner.component';

@NgModule({
	declarations: [DelegatedRoleBannerComponent],
	exports: [DelegatedRoleBannerComponent],
	imports: [CommonModule, IgniteModule, PropertyModule]
})
export class DelegatedRoleBannerModule {}
