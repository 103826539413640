import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {get} from 'lodash';

import {PropertiesApi, SingleSelectPickerAttrs} from '../../../core/generic/single-select-picker/single-select-picker.interfaces';
import {PropertyState} from '../../../core/property/property.interface';

import {
	EMPTY_VALUE,
	LABEL_LEFT_CLASS,
	PROPERTIES,
	SINGLE_SELECT_PICKER_PROPERTIES
} from '../../../core/generic/single-select-picker/single-select-picker.constants';
import {
	getBaseAttrs,
	getNativeAttrs,
	getSelectedValue,
	getTooltipValue
} from '../../../core/generic/single-select-picker/single-select-picker.utility';
import {TEXT_ALIGMENT} from '../../../core/platform/constants/CommonConstants';
import {getPlacement} from '../../../core/platform/form-element.utility';
import {PropertyFactory} from '../../../core/property/property.factory';
import {PropertyFilterPipe} from '../../../providers/property/property.filter.pipe';

@Component({
	selector: 'krn-ng-single-select-native-picker',
	templateUrl: './single-select-native-picker.component.html',
	styleUrls: ['./single-select-native-picker.component.less']
})
export class SingleSelectNativePickerComponent implements OnChanges {
	@Input() public alignment: string;
	@Input() public attrs: SingleSelectPickerAttrs;
	@Input() public classes: string | string[];
	@Input() public data: object[];
	@Input() public dataLabel: string;
	@Input() public label: string;
	@Input() public selected: object;
	@Input() public toolTipLabel: string;
	@Input() public tooltipContainerClass: string | string[];

	@Output() public selectedChange = new EventEmitter<object>();

	public baseAttrs: SingleSelectPickerAttrs;
	public placement: string;
	public layout: string;
	public nativeAttrs: object;
	public selectedValue: object;

	private properties: Promise<PropertyState>;
	private allPropertiesApi: PropertiesApi = {
		tooltipProp: (label: string, value: string): string => this.propertyFilterPipe.transform(PROPERTIES.TOOLTIP, [label, value])
	};

	constructor(private propertyFactory: PropertyFactory, private propertyFilterPipe: PropertyFilterPipe) {
		this.properties = this.loadSingleSelectPickerStrings();
	}

	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		this.placement = getPlacement(this.alignment, this.attrs?.dir);
		this.selectedValue = getSelectedValue(this.data, get(changes, 'selected.currentValue'), this.selectedValue);
		this.layout = this.alignment === TEXT_ALIGMENT.RIGHT ? LABEL_LEFT_CLASS : EMPTY_VALUE;
		this.nativeAttrs = getNativeAttrs(this.attrs, this.label, this.selectedValue);

		await this.properties;
		this.baseAttrs = getBaseAttrs(this.allPropertiesApi, this.attrs, this.label, getTooltipValue(this.selected, this.toolTipLabel, this.dataLabel));
	}

	public onSelected(): void {
		this.selectedChange.emit(this.selectedValue);
	}

	public onSelectedChange(selectedChange: object): void {
		this.selectedChange.emit(selectedChange);
	}

	private loadSingleSelectPickerStrings = (): Promise<PropertyState> => this.propertyFactory.loadProperties(SINGLE_SELECT_PICKER_PROPERTIES);
}
