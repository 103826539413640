import {get, includes, isString, isUndefined, omitBy, size} from 'lodash';

import {FORM_ELEMENT_CLASSES, INVALID_CLASS, REVERSE_CLASS} from './constants/composite-form.constants';

export {getClasses, getFormElementClasses};

function getClasses(classes?: string | string[], alignment?: string, dir?: string): string[] {
	const reverseClass = alignment === 'right' || dir === 'rtl' ? REVERSE_CLASS : '';
	let containerClasses = classes || [];

	if (isString(containerClasses)) {
		containerClasses = containerClasses.split(' ');
	}
	containerClasses.push(reverseClass);
	return containerClasses;
}

function getFormElementClasses(classes?: string | string[]): string {
	const invalidClass = includes(classes, INVALID_CLASS) ? ` ${INVALID_CLASS}` : '';

	return `${FORM_ELEMENT_CLASSES}${invalidClass}`;
}
