// eslint-disable-next-line @typescript-eslint/naming-convention
export const CommonConstants = {
	TML_INFRASTRUCTURE_SESSION_STORAGE_NOT_SUPPORTED: 'Browser does not support session storage',
	HTML_INFRASTRUCTURE_PROPERTY_DEFAULT_URL: '/property/get',
	HTML_INFRASTRUCTURE_PROPERTY_DEFAULT_CONTEXT_PREFIX: 'context.',
	HTML_INFRASTRUCTURE_PROPERTY_CONTEXT_ERROR_MESSAGE: 'Please check logs. Unable to load properties for context :',
	HTML_INFRASTRUCTURE_PROPERTY_TIMESTAMP_ERROR_MESSAGE: 'Please check logs. Unable to retrieve timestamp',
	HTML_INFRASTRUCTURE_PROPERTY_FILE_LOADED: 'LOADED',
	HTML_INFRASTRUCTURE_PROPERTY_FILE_LOAD_BASE_FILE: 'LOAD_BASE_FILE',
	HTML_INFRASTRUCTURE_PROPERTY_FILE_EXT: '.properties',
	HTML_INFRASTRUCTURE_PROPERTY_FILE_ENCODING: 'UTF-8',
	HTML_INFRASTRUCTURE_PROPERTY_FILE_DEFAULT_LOCALE: 'en_US',
	HTML_INFRASTRUCTURE_PROPERTY_FILE_DEFAULT_MODE: 'map',
	HTML_INFRASTRUCTURE_PROPERTY_FILE_DEFAULT_CACHE: true,
	HTML_INFRASTRUCTURE_PROPERTY_FILE_ERROR_MESSAGE: 'Unable to load properties file',
	HTML_INFRASTRUCTURE_CUSTOM_AJAX_HEADER: 'X-REST-API',
	HTML_INFRASTRUCTURE_JSON_MESSAGE_DATE_TIME_FORMAT: 'yyyy-MM-dd HH:mm:ss.fff',
	HTML_INFRASTRUCTURE_JSON_MESSAGE_DATE_FORMAT: 'yyyy-MM-dd 00:00:00.000',
	HTML_INFRASTRUCTURE_JSON_MESSAGE_ISO_DATE_FORMAT: 'YYYY-MM-DDT00:00:00.000',
	HTML_INFRASTRUCTURE_ISO_DATE_FORMAT: 'yyyy-MM-ddT00:00:00.000',
	HTML_INFRASTRUCTURE_ISO_DATE_ONLY_FORMAT: 'yyyy-MM-dd',
	HTML_INFRASTRUCTURE_ISO_TIME_ONLY_FORMAT: 'HH:mm',
	HTML_INFRASTRUCTURE_ISO_TIME_WITHOUT_MS_FORMAT: 'HH:mm:ss',
	HTML_INFRASTRUCTURE_ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE: 'YYYY-MM-DDTHH:mm:ss',
	HTML_INFRASTRUCTURE_ISO_DATE_TIME_FORMAT: 'yyyy-MM-ddTHH:mm:ss.fff',
	HTML_INFRASTRUCTURE_JSON_MESSAGE_TIME_FORMAT: 'HH:mm:ss.fff',
	HTML_INFRASTRUCTURE_MOMENT_DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss.SSS',
	HTML_INFRASTRUCTURE_MOMENT_DATE_ONLY_FORMAT: 'YYYY-MM-DD',
	HTML_INFRASTRUCTURE_MOMENT_TIME_ONLY_FORMAT: 'HH:mm:ss.SSS',
	HTML_INFRASTRUCTURE_MOMENT_DAY_ONLY_FORMAT: 'dddd',
	HTML_INFRASTRUCTURE_MOMENT_MONTH_ONLY_FORMAT: 'MMMM',
	HTML_INFRASTRUCTURE_MOMENT_YEAR_ONLY_FORMAT: 'YYYY',
	HTML_INFRASTRUCTURE_MOMENT_MONTH_DAY_YEAR_FORMAT: 'MMM D, YYYY',
	HTML_INFRASTRUCTURE_MOMENT_DATE_DAY_FORMAT: 'YYYY-MM-DD dddd',
	HTML_INFRASTRUCTURE_MOMENT_MONTH_AND_YEAR_FORMAT: 'MMMM YYYY',
	HTML_INFRASTRUCTURE_MOMENT_ABBR_MONTH_AND_YEAR_FORMAT: 'MMM YYYY',
	THEME: 'bluefin',
	HTML_INFRASTRUCTURE_EXPORT_CONSTANTS: {
		HTML_INFRASTRUCTURE_DEFAULT_EXPORT_FORMAT: 'csv',
		HTML_INFRASTRUCTURE_DEFAULT_INPUT_FORMAT: 'tsv',
		HTML_INFRASTRUCTURE_DEFAULT_EXPORT_FILENAME: 'exportData',
		HTML_INFRASTRUCTURE_DEFAULT_EXPORT_CHARSET: 'UTF-8'
	},
	HTML_INFRASTRUCTURE_FORMATTING_CONSTANTS: {
		CURRENCY_CONSTANTS: {
			METRIC_DECIMAL_SYMBOL: '.'
		}
	},
	HTML_INFRASTRUCTURE_SESSIONSTORAGE: {
		HTML_NOTIFICATION_COUNT_CACHE: 'notificationCountCache',
		HTML_NOTIFICATIONCACHE: 'notificationCache',
		HTML_NOTIFICATION_MESSAGE: 'message'
	},
	PERSONALIZATION_PREFIX: 'personalization',
	HTML_INFRASTRUCTURE_FILEUPLOAD_TIMEOUT: 'Request timed out',
	HTML_INFRASTRUCTURE_FILEUPLOAD_NUMOFRETRIES: 7,
	DATETIME_FORMATS: {
		ISO: 'YYYY-MM-DDTHH:mm:ss'
	},
	HTML_ALIGNMENT: {
		BOTTOM: 'bottom',
		LEFT: 'left',
		RIGHT: 'right',
		TOP: 'top'
	},
	HTML_EXT_ALIGNMENT: {
		BOTTOM_LEFT: 'bottom left',
		BOTTOM_RIGHT: 'bottom right',
		LEFT_BOTTOM: 'left bottom',
		LEFT_TOP: 'left top',
		RIGHT_BOTTOM: 'right bottom',
		RIGHT_TOP: 'right top',
		TOP_LEFT: 'top left',
		TOP_RIGHT: 'top right'
	}
};

export const TEXT_ALIGMENT = {
	CENTER: 'center',
	LEFT: 'left',
	RIGHT: 'right'
};

export const NUMBERS = {
	MINUS_ONE: -1,
	ZERO: 0,
	ONE: 1,
	TWO: 2
};

export const STATUS_CODE = {
	INFORMATION: 100,
	SUCCESS: 200,
	REDIRECT: 300,
	CLIENT_ERROR: 400,
	PAYLOAD_TOO_LARGE: 413,
	SYSTEM_ERROR: 500,
	OUT_OF_BOUNDS: 600
};

export const FILE_UPLOAD_URL = {
	RESTURL: '/restcall/v1/platform/file_uploads',
	URL: 'platform/file_uploads'
};

export const BIT_NUMBER = 1024;

export const CLICK_EVENT_IGNORE_TIME = 600;

export const NOTIFICATIONS_CARD_REFRESH_NOTIFICATIONS = 'common.notificationsCard.refreshNotifications';
