import {Injectable} from '@angular/core';
import {camelCase, forIn, get, set} from 'lodash';
import {BehaviorSubject, map, Observable} from 'rxjs';

import {actionsHelper} from '../core/framework/services/user-info.actions';
import {reducerHelper, USER_INFO_DEFAULT_STATE} from '../core/framework/services/user-info.reducer';

@Injectable({
	providedIn: 'root'
})
export class UserInfoActionService {
	public actions;
	private userInfoSource = {...USER_INFO_DEFAULT_STATE};
	private userInfoSubject = new BehaviorSubject({...USER_INFO_DEFAULT_STATE});

	constructor() {
		this.actions = this.userInfoActionsHelper();
	}

	public select = (path: string[]): Observable<any> => this.userInfoSubject.pipe(map(state => get({userInfo: state}, path)));

	public dispatch = (action: any): void => {
		this.userInfoSource = get(reducerHelper(), action.type)(this.userInfoSource, action);
		this.userInfoSubject.next(this.userInfoSource);
	};

	private userInfoActionsHelper = (): Partial<any> => {
		const actions = {};

		forIn(actionsHelper(), (val: (...params) => any, key: string) => {
			set(actions, camelCase(key), (...params) => ({type: key, payload: val(...params)}));
		});
		return actions;
	};
}
