import {Injectable} from '@angular/core';

import {PropertyFactory} from '../../core/property/property.factory';
import {CurrencyService} from './currency.service';
import {IncentiveFormatterCoreService} from '../../core/platform/converters/incentive-formatter-core.service';
import {PropertyFilterPipe} from '../../providers/property/property.filter.pipe';

@Injectable({
	providedIn: 'root'
})
export class IncentiveFormatterService extends IncentiveFormatterCoreService {
	constructor(propertyFilter: PropertyFilterPipe, propertyFactory: PropertyFactory, currencyService: CurrencyService) {
		super(propertyFilter.transform.bind(propertyFilter), propertyFactory, currencyService);
	}
}
