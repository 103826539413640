import {get, has, isUndefined, join, omitBy, pick, trim} from 'lodash';

import {ButtonAttrs} from '../button/button.interfaces';
import {PopoverButtonAttrs, popoverRenderType} from './popover-button.interfaces';

import {ARIA} from '../platform/accessibility/utils.constants';
import {PropertyFactory} from '../property/property.factory';
import {
	ALWAYS_FIT_CONTAINER_CLASS,
	ALWAYS_FULL_SCREEN_CLASS,
	DEFAULT_ALIGNMENT,
	DEFAULT_CONTAINER_CLASS,
	DEFAULT_ICON_CLASS
} from './popover-button.constants';

const popoverRenderClass = {
	[popoverRenderType.ALWAYS_FIT_CONTAINER]: ALWAYS_FIT_CONTAINER_CLASS,
	[popoverRenderType.ALWAYS_FULL_DISPLAY]: ALWAYS_FULL_SCREEN_CLASS
};

export const getAriaLabel = (opened: boolean, openLabel: string, closeLabel: string, defaultLabel: string): string =>
	(opened ? openLabel : closeLabel) || defaultLabel;

export const initButtonAttrs = (id: string, attrs?: PopoverButtonAttrs, interactive = false, isMobileDevice = false): ButtonAttrs =>
	omitBy(
		{
			id,
			...setAriaHaspopup(attrs, interactive, isMobileDevice),
			...pick<any>(attrs, [ARIA.LABELLEDBY, ARIA.REQUIRED, ARIA.CONTROLS, 'disabled', 'role', 'tabindex', 'title']),
			[ARIA.DESCRIBEDBY]: has(attrs, ARIA.LABELLEDBY) ? `describe-icon-${id}` : get(attrs, ARIA.DESCRIBEDBY)
		},
		isUndefined
	);

export const getButtonAttrs = (
	id: string,
	attrs: PopoverButtonAttrs,
	ariaLabel: string,
	ariaExpanded: boolean,
	interactive = false,
	isMobileDevice = false
): ButtonAttrs => ({
	...initButtonAttrs(id, attrs, interactive, isMobileDevice),
	[ARIA.LABEL]: ariaLabel,
	[ARIA.EXPANDED]: ariaExpanded
});

export const initAlignment = (alignment?: string): string => alignment || DEFAULT_ALIGNMENT;

export const initDefaultClass = (renderType?: string): string => popoverRenderClass[renderType] || DEFAULT_CONTAINER_CLASS;

export const initContainerClass = (containerClass?: string, renderType?: string): string =>
	trim(join([containerClass, initDefaultClass(renderType)], ' '));

export const initIcon = (icon?: string): string => icon || DEFAULT_ICON_CLASS;

export const loadPopoverButtonStrings = (propertyFactory: PropertyFactory): Promise<any> =>
	propertyFactory.loadProperties([
		{
			name: 'container_web-common_popover-button_strings.properties',
			path: '/components/zed-core/properties/popover-button/'
		}
	]);

const setAriaHaspopup = (attrs: PopoverButtonAttrs, interactive: boolean, isMobileDevice: boolean): any =>
	isMobileDevice ? {
		[ARIA.HASPOPUP]: interactive || undefined,
		...pick<any>(attrs, [ARIA.HASPOPUP])
	} : undefined;
