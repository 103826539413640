import {compact, head, isEmpty, isString, isUndefined} from 'lodash';

import {ButtonClasses, ButtonTypes} from './button.interfaces';

import {CommonConstants, TEXT_ALIGMENT} from '../platform/constants/CommonConstants';

const bottomClass = 'flex-column';
const alignments = {
	[CommonConstants.HTML_ALIGNMENT.LEFT]: 'flex-row-reverse',
	[CommonConstants.HTML_ALIGNMENT.RIGHT]: 'flex-row',
	[CommonConstants.HTML_ALIGNMENT.TOP]: 'flex-column-reverse',
	[CommonConstants.HTML_ALIGNMENT.BOTTOM]: bottomClass,
	[TEXT_ALIGMENT.CENTER]: bottomClass
};

export function getButtonClasses(btnType: string, icon: string, classes): string[] | string {
	if (isEmpty(classes)) {
		return btnType === ButtonTypes.ANCHOR ? ButtonClasses.LINK : isUndefined(icon) ? ButtonClasses.PRIMARY : ButtonClasses.SECONDARY;
	}
	return isString(classes) ? classes : compact(classes);
}

export function getButtonLayout(alignment: string): string {
	return alignments[alignment ? head(alignment.split(' ')) : CommonConstants.HTML_ALIGNMENT.RIGHT];
}

export function getButtonRole(btnType: string): string {
	return btnType === ButtonTypes.ANCHOR ? 'link' : 'button';
}
