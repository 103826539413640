import '@ukg-oneapp/common-lib';

import {OneAppMessage, IOneAppCommonEvent} from './one-app-event-bus.interfaces';

import {SENDER_NAME} from './one-app-event-bus.constants';

export const fireEvent = (window: any, payload: any): Promise<IOneAppCommonEvent> => {
	const eventData: OneAppMessage = {
		sender: SENDER_NAME,
		senderID: window.OneAppCommon.getProvider(window),
		...payload
	};
	const json: string = JSON.stringify(eventData);

	return window.OneAppCommon.fireEvent(window, json);
};

export const initializeEventBusListener = (window: any, handler: (event) => void): void => {
	window.document.addEventListener('UKGEvent', handler, false);
};
