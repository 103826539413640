<div *ngIf="propertiesLoaded">
	<krn-ng-button
		(keydown)="keydownCallback($event)"
		(buttonClicked)="buttonConfig.callback()"
		[attrs]="{
			'aria-expanded': !isCollapsed,
			'aria-label': getSubMenuAriaLabel(),
			id: buttonConfig.id,
			title: buttonConfig.text
		}"
		[icon]="'icon-k-small-caret-' + (isCollapsed ? 'down' : 'up')"
		[classes]="buttonConfig.classes + (isCollapsed ? '' : ' expanded')"
		[buttonText]="buttonConfig.text"
		alignment="left">
	</krn-ng-button>
	<div *ngIf="!isCollapsed" krn-ng-modal-keyboard-navigation modalRole="list" noWrap="true">
		<!--<div krn-message id="navBar-service-message-notification"></div>-->
		<span id="roleListInfoButton" [tooltip]="'html.navbar.roles.type.assigned' | property" class="role-section-header">
			{{'html.navbar.roles.type.assigned' | property}}
			<krn-ng-popover-button
				*ngIf="!isCollapsed"
				class="role-list-info-button"
                alignment="bottom right"
				[attrs]="{tabindex: 0}"
				renderType="alwaysFitContainer"
				containerClass="krn-fit-container positionLeft"
				container="#roleListInfoContainer"
				icon="icon-k-legend"
				[adaptivePosition]="false"
				[openAriaLabel]="'html.navbar.roles.information' | property"
				[closeAriaLabel]="'html.navbar.roles.information' | property">
				<div class="popoverButtonBody information-icon-popover" #popoverButtonContainer>
					<ng-container *ngFor="let role of assignedRoles">
						<div *ngIf="getRoleString(role) || role.description" class="role-info">
							<span class="name">{{getRoleString(role)}}</span
							><br />
							<span class="description">{{role.description}}</span>
						</div>
					</ng-container>
					<ng-container *ngFor="let role of delegations">
						<div *ngIf="getDelegatedRoleString(role) || role.description" class="delegated-role-info">
							<span class="name">{{getDelegatedRoleString(role)}}</span
							><br />
							<span class="description">{{role.description}}</span>
						</div>
					</ng-container>
				</div>
			</krn-ng-popover-button>
		</span>
		<div id="roleListInfoContainer"></div>
		<div class="role-section-list" role="listbox" tabindex="-1" [attr.aria-label]="'html.navbar.roles.type.assigned' | property">
			<a
				*ngFor="let role of assignedRoles; index as roleIndex"
				role="button"
				tabindex="0"
				[id]="role.assignmentId"
				(keydown)="handleSubMenuKeyDown($event)"
				(click)="performRoleSwitch(role)"
				class="authPerson"
				[ngClass]="{'active-role': isActiveRole(role.assignmentId)}"
				[attr.aria-label]="getRoleLabel(role, roleIndex)">
				{{getRoleString(role)}}
			</a>
		</div>

		<span *ngIf="delegations.length" [tooltip]="'html.navbar.roles.type.delegated' | property" class="role-section-header" aria-hidden="true">{{
			'html.navbar.roles.type.delegated' | property
		}}</span>
		<div
			class="role-section-list"
			role="listbox"
			tabindex="-1"
			[attr.aria-label]="'html.navbar.roles.type.delegated' | property"
			*ngIf="delegations.length">
			<a
				*ngFor="let role of delegations; index as delegateIndex"
				tabindex="0"
				role="button"
				[id]="role.id"
				(keydown)="handleSubMenuKeyDown($event)"
				(click)="performDelegatedRoleSwitch(role)"
				[attr.aria-label]="getDelegateLabel(role, delegateIndex)"
				class="authPerson"
				[ngClass]="{'active-role': isActiveDelgateRole(role.id)}">
				{{getDelegateString(role)}}
			</a>
		</div>
	</div>
</div>
