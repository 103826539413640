import {Inject, Injectable, Optional} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

import {APP_CONFIG_ENVIRONMENT, Environment} from '../environment.token';
import {HealthCheckCoreService} from '../../core/health-check/health-check.core.service';
import {WindowRefService} from '../window-ref.service';

@Injectable({
	providedIn: 'root'
})
export class HealthCheckService extends HealthCheckCoreService {
	constructor(
		private logger: NGXLogger,
		private windowRefService: WindowRefService,
		@Optional() @Inject(APP_CONFIG_ENVIRONMENT) private environment: Environment
	) {
		super(logger, windowRefService.nativeWindow.window, environment.workersPath);
	}
}
