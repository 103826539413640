import {Injectable} from '@angular/core';
import {noop} from 'lodash';

import {Transport} from '../../../../../core/communication/api-service/api-service.interfaces';

import {UserInfoService} from '../../../../../core/framework/services/user-info.service';
import {ImpersonationAllowedEmployment, ImpersonationAllowedUser} from '../../../../../core/navBar/models/user-impersonation-detail.model';
import {IMPERSONATION_PARAMS} from '../../../../../core/navBar/user-impersonation.constants';
import {UserImpersonationService as UserImpersonationCoreService} from '../../../../../core/navBar/user-impersonation.service';
import {PropertyFilterPipe} from '../../../../../providers/property/property.filter.pipe';
import {DataService} from '../../../../../services/communication/data.service';
import {SessionStorageService} from '../../../../../services/session-storage.service';

@Injectable({
	providedIn: 'root'
})
export class MultipleEmploymentService extends UserImpersonationCoreService {
	constructor(sessionStorage: SessionStorageService, userInfo: UserInfoService, propertyFilter: PropertyFilterPipe, apiService: DataService) {
		super(sessionStorage, userInfo, propertyFilter.transform.bind(propertyFilter), apiService);
	}

	public getButtonConfig(callback: () => void): any {
		const btnConfig = super.getButtonConfig(callback);

		return {
			...btnConfig,
			btnClasses: `${btnConfig.classes} text-left w-100`,
			onClick: callback,
			onFocus: noop,
			prependTransclude: true,
			text: this.propertyFilter('html.navbar.multipleEmployments.employments.label') || 'Employments',
			visible: true,
			id: 'multipleEmploymentsBtn'
		};
	}

	public getImpersonationParams = (): any => ({params: IMPERSONATION_PARAMS, responseType: 'text', headers: {
		isMultipleEmploymentUser: 'TRUE'
	}});

	public performUserImpersonation(employment: ImpersonationAllowedUser | ImpersonationAllowedEmployment): Promise<any> {

		return this.apiService.create('/impersonate', {supportUserImpersonateAs: employment.userName}, this.getImpersonationParams(), Transport.REST, false, null, false, { routeTo: 'bck' });			
	}
}
