import {CordovaPluginWindow} from '../mobile/cordova-plugin-window.interface';

import {SessionStorage} from '../caching/sessionStorage.service';
import {BrowserContainerCoreService} from './browser-container.core.service';

export class KioskContainerCoreService extends BrowserContainerCoreService {
	constructor(window: CordovaPluginWindow, sessionStorage: SessionStorage) {
		super(window, sessionStorage);
	}
}
