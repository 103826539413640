import {find, forEach, get, isEmpty, isNil, without} from 'lodash';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';

import {PropertyFilterConfig} from '../property/property.filter';
import {MenuIntegrationParam} from './models/menu-integration-param.model';
import {MenuIntegration} from './models/menu-integrations.model';

export class MenuIntegrationService {
	constructor(private propertyFilter: PropertyFilterConfig, private apiService: ApiService) {}

	public getButtonConfig = function(integrationParam, callback): any {
		return {
			id: `${integrationParam}ToggleButton`,
			text: this.propertyFilter(`html.navbar.${integrationParam}.label`),
			classes: 'btn delegateAuth info-menu',
			postpendTransclude: false,
			callback
		};
	};

	/**
	 * With this function, we create a cookie with the following name:
	 *
	 * MULTI_ID_EIN
	 *
	 * The value of this cookie is the ID of selected EIN if it is non-primary EIN.
	 * But if you have selected non-primary EIN, null will be set as cookie value.
	 */
	public menuChange = function(integrationParam: string, updatedParamValue: MenuIntegrationParam): any {
		const params = updatedParamValue.primary ? {menuIntegParam: integrationParam} : {menuIntegParam: integrationParam, id: updatedParamValue.id};

		return this.apiService.find(
			'/navBar/menuChange',
			params,
			Transport.REST
		);
	};

	/**
	 * With this function, primary EIN is being set at the first position on array.
	 * This has been done to display primary EIN at the top of EIN list.
	 */
	public putPrimaryOnTop = (params: MenuIntegrationParam[]): MenuIntegrationParam[] => {
		if (isEmpty(params)) {
			return;
		}

		const primaryParam = find(params, 'primary');
		const allNonPrimaryParams: MenuIntegrationParam[] = without(params, primaryParam);
		const allParams = [];

		allParams.push(primaryParam);
		return allParams.concat(allNonPrimaryParams);
	};

	public prepareMenuIntegrationsData(menuAdditionalDataList, menuAdditionalData): MenuIntegration[] {
		const menuIntegrations: MenuIntegration[] = [];

		if (isNil(menuAdditionalData)) {
			return menuIntegrations;
		}

		const serviceIDs = Object.keys(menuAdditionalData);

		forEach(serviceIDs, sID => {
			const integrationParameters = Object.keys(menuAdditionalData[sID]);

			forEach(integrationParameters, integrationParam => {
				menuIntegrations.push({
					serviceId: sID,
					parameter: integrationParam,
					values: menuAdditionalDataList[sID][integrationParam]
				});
			});
		});
		return menuIntegrations;
	}

	/**
	 * Whenever menu is updated based on some additional data, that additional data must be displayed.
	 * To display additional data based on some menu type, we return the ID based on which menu has been updated.
	 *
	 * In this case, additional data is menu type id which is passed in req.query.menuType.
	 *
	 * @returns ID of menu type
	 */
	public getSelectedMenuIntegParam(menuAdditionalData): any {
		if (isEmpty(menuAdditionalData)) {
			return;
		}

		const eins = get(menuAdditionalData, 'wfr.eins');

		return find(eins, 'selected');
	}
}
