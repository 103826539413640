import {LogServiceInterface} from '../platform/services/logService/log.interface';

export abstract class AuthenticationRegistrationCoreService {
	constructor(protected logger: LogServiceInterface, protected windowRef) {}

	protected processAuthenticated() : void {
		if (this.windowRef.navigator.userAgent.match(/(wfdMobileApp)/i)) {
			this.windowRef.initNativeData = true;
		}
	}

	public abstract whenAuthenticated(callback: () => void): void;
}
