import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DeprecationMessageService} from '../../services/deprecation-message/deprecation-message.service';
import {PropertyFilterPipe} from '../../providers/property/property.filter.pipe';
import {PropertyModule} from '../../providers/property/property.module';
import {AttributesModule} from '../../utils/attributes/attributes.module';
import {LoaderComponent} from './loader.component';

/**
 * @deprecated This loader component is deprecated and will be removed in the future in favor of components found within the Ignite design system. [Click here for more details](https://engstash.int.kronos.com/projects/FRONT/repos/frontend/browse/packages/web/zed/README.md)
 */
@NgModule({
	declarations: [LoaderComponent],
	exports: [LoaderComponent],
	imports: [AttributesModule, CommonModule, PropertyModule],
	providers: [PropertyFilterPipe]
})
export class LoaderModule {
	constructor(private deprecationMessageService: DeprecationMessageService) {
		this.deprecationMessageService.log('loader');
	}
}
