<button [class]="config.btnClasses"
        (click)="config.onClick($event)"
        [krn-ng-attributes]="{
          'aria-label': config?.ariaLabel || config?.title,
          'aria-describedby': config?.ariaDesc ? config?.id + '_ariaDesc': undefined,
          'aria-expanded': config?.expanded,
          'disabled': config?.disabled,
          'id': config?.id + '_button'
        }"
        [tooltip]="config?.title"
        [hidden]="!config.visible"
        (focus)="config.onFocus()">
  <span *ngIf="config.prependTransclude" data-placement="left">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>
  <span id="{{config.id}}_icon" *ngIf="config.icon" [class]="config.icon"></span>
  <span id="{{config.id}}_text" *ngIf="config.text">{{config.text}}</span>
  <span *ngIf="config.postpendTransclude" data-placement="right">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>
  <ng-template #content><ng-content></ng-content></ng-template>
</button>
<span id="{{config.id}}_ariaDesc" *ngIf="config.ariaDesc" class="sr-only" aria-hidden="true">{{config.ariaDesc | property}}</span>
