import {CordovaPluginWindow} from '../mobile/cordova-plugin-window.interface';
import {ClientContainer} from './client-container.interface';
import {ApiService} from '../../communication/api-service/api-service.interfaces';
import {LogServiceInterface} from '../services/logService/log.interface';

import {EmbeddedMenuItemsCoreService} from '../../embedded/embedded-menu-items.core.service';
import {SessionStorage} from '../caching/sessionStorage.service';
import {ClientContainerContextCoreService} from './client-container-context.core.service';
import {getServiceByClientContext} from './client-container.utility';
import {ClientServiceMap} from './client-container.constant';

export class ClientContainerCoreService {
	constructor(
		private window: CordovaPluginWindow,
		private sessionStorage: SessionStorage,
		protected clientContainerContextCoreService: ClientContainerContextCoreService,
		protected embeddedMenuItemsService: EmbeddedMenuItemsCoreService,
		protected apiService: ApiService,
		protected logger: LogServiceInterface
	) {}

	public getContainer(): ClientContainer {
		const contextType = this.clientContainerContextCoreService.context();
		const commonDependencies = [this.window, this.sessionStorage];
		const dependencies: object[] = this.clientContainerContextCoreService.isEmbedded() ? [...commonDependencies, this.embeddedMenuItemsService] : commonDependencies;

		if (this.clientContainerContextCoreService.isOneApp()) {
			dependencies.push(this.apiService, this.logger);
		}

		return getServiceByClientContext<ClientContainer>(
			contextType,
			ClientServiceMap,
			dependencies
		);
	}
}
