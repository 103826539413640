import {includes, isNil, values} from 'lodash';

import {IllustrationMessageAttributes, IllustrationState} from './illustration-message.interfaces';

import {PropertyFactory} from '../property/property.factory';

export {loadProperties, getPropertyName, getImagePath,createDefaultConfig,computeIllustrationStatus, checkAndFallBackImage};

const messageMap = values(IllustrationState);
const defaultMessagePrefix = 'common.illustrationMessage.default.message.';

function loadProperties(propertyFactory: PropertyFactory): Promise<any> {
	return propertyFactory.loadProperties([
		{
			name: 'container_web-common_noDataMessage_strings.properties',
			path: '/components/zed-core/properties/no-data-message/'
		}
	]);
}

function getPropertyName(illustration: string): string {
	return isDefaultIllustration(illustration) ? defaultMessagePrefix + illustration : undefined;
}

function isDefaultIllustration(illustration: string): boolean {
	return includes(messageMap, illustration);
}

function getImagePath(illustration: string, customIllustration?: string) : string {
	const imageName = customIllustration ? checkAndFallBackImage(customIllustration) : getFallBackImage(illustration, 'svg');

	return imageName ? `/images/illustration/${imageName}` : undefined;
}


function computeIllustrationStatus(showIllustration: boolean, imgLocation: string) : boolean {
	return showIllustration && !isNil(imgLocation);
}

function createDefaultConfig(htmlAttributes: IllustrationMessageAttributes): IllustrationMessageAttributes{
	return {...htmlAttributes};
}

function checkAndFallBackImage(customIllustration: string): any {
		const nameAndExtension = customIllustration.split('.');
		const extension = nameAndExtension.pop();
		const name = nameAndExtension.pop();

		return getFallBackImage(name, extension);
}

function getFallBackImage(name: string, extension: string): any {
	return (extension === 'svg' && (isNil(typeof SVGRect) || typeof SVGRect === 'undefined')) ? `${name}.png` : `${name}.${extension}`;
}
