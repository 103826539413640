import {Component, Input} from '@angular/core';

import {PropertyFactory} from '../../core/property/property.factory';
import {PropertyFilterPipe} from '../../providers/property/property.filter.pipe';
import {NodeDownPageContext} from './node-down-error-constants';

@Component({
	selector: 'node-down-error-modal',
	templateUrl: './node-down-error.modal.html',
	styleUrls: ['./node-down-error.modal.component.less']
})
export class NodeDownErrorModal {
	@Input()
	public pageModule;

	@Input()
	public onClose: () => null;

	public timekeepingModuleLabel: string;
	public schedulingModuleLabel: string;
	public NodeDownPageContext = NodeDownPageContext;

	constructor(protected propertyFactory: PropertyFactory, private propertyFilter: PropertyFilterPipe) {
		this.propertyFactory
			.loadProperties([
				{
					name: 'container_web-common_nodeDownModal_strings.properties',
					path: 'components/zed-core/properties/node-down-modal/'
				}
			])
			.then(() => {
				this.timekeepingModuleLabel = this.propertyFilter.transform('app.core.node.down.error.body.timekeeping');
				this.schedulingModuleLabel = this.propertyFilter.transform('app.core.node.down.error.body.scheduling');
			});
	}
}
