import {Injectable} from '@angular/core';
import {includes} from 'lodash';
import {Observable, Subject} from 'rxjs';

import {SelectedLink} from '../../../core/navBar/nav-bar-menu';
import {keyCodes} from '../../../core/platform/constants/keys.constant';

@Injectable({
	providedIn: 'root'
})
export class NavBarMenuService {
	public selectedLink: SelectedLink = {
		category: null,
		item: null
	};

	private clearSearchSource = new Subject<void>();
	private menuToggleSource = new Subject<void>();
	private resetMenuSource = new Subject<void>();
	private scrollToElementSource = new Subject<void>();
	private settingsMenuToggleSource = new Subject<void>();
	private offlineModeSource = new Subject<void>();

	public clearSearch$ = (): Observable<void> => this.clearSearchSource;

	public menuToggle$ = (): Observable<void> => this.menuToggleSource;

	public resetMenu$ = (): Observable<void> => this.resetMenuSource;

	public scrollToElement$ = (): Observable<void> => this.scrollToElementSource;

	public settingsMenuToggle$ = (): Observable<void> => this.settingsMenuToggleSource;

	public offlineMode$ = (): Observable<void> => this.offlineModeSource;

	public clearSearch = (): void => {
		this.clearSearchSource.next();
	};

	public runOfflineMode = (): void => {
		this.offlineModeSource.next();
	};

	public resetMenu = (): void => {
		this.resetMenuSource.next();
	};

	public toggleMenu = (): void => {
		this.menuToggleSource.next();
	};

	public toggleSettingsMenu = (): void => {
		this.settingsMenuToggleSource.next();
	};

	public scrollTo = (): void => {
		this.scrollToElementSource.next();
	};

	public setSelectedElement = (selectedLink: SelectedLink): void => {
		this.selectedLink = selectedLink;
	};

	public getSelectedElement = (): SelectedLink => this.selectedLink;

	public menuArrowKeyCallback = (isCollapsed, event, callback): void => {
		if ((isCollapsed && event.which === keyCodes.RIGHT_ARROW) || (!isCollapsed && event.which === keyCodes.LEFT_ARROW)) {
			event.stopPropagation();
			callback();
		}
	};

	public handleSubMenuKeyDown = (event: KeyboardEvent): void => {
		if (includes([keyCodes.SPACE, keyCodes.ENTER], event.which)) {
			(event.target as HTMLElement).click();
			event.preventDefault();
			event.stopPropagation();
		}
	};
}
