<ukg-ignite-shell asset-prefix="/bundles/wfd" [language]="pageLanguage" [languageJson]="overrideUkgLanguageJson$ | async">
	<krn-ng-persistent-banner></krn-ng-persistent-banner>
	<krn-ng-embedded-page *ngIf="showEmbeddedPage"></krn-ng-embedded-page>
	<krn-ng-one-app-page *ngIf="showOneAppPage"></krn-ng-one-app-page>
	<krn-ng-delegated-role-banner *ngIf="showOneAppPage && navigationEnd"></krn-ng-delegated-role-banner>
	<krn-ng-new-features-modal *ngIf="showNewFeatures"></krn-ng-new-features-modal>
	<ukg-skip-to-main *ngIf="pageNotInIframe" [attr.aria-hidden]="isMenuOpen" [skipToMainCallback]="skipToMain"></ukg-skip-to-main>
	<krn-ng-navbar-container *ngIf="showOneAppPage" class="hide-dim-header" aria-hidden="true" inert [app]="app"> </krn-ng-navbar-container>
	<krn-ng-navbar-container
		*ngIf="!showOneAppPage && showNavBar"
		[app]="app"
		[displayTitle]="displayTitle"
		[visible]="showNavBar$ | async"
		[rightMenuItems]="(navBar | async)?.rightMenuItems"
		(navbarStateChange)="navbarStateChange($event)"
		(onMainMenuToggle)="mainMenuToggle($event)">
	</krn-ng-navbar-container>
	<krn-ng-message-popup
		*ngIf="unsavedDataService.unsavedModal.open"
		[open]="unsavedDataService.unsavedModal.open"
		messageType="warning"
		[headerText]="unsavedDataService.unsavedModal.headerValue"
		[messageText]="unsavedDataService.unsavedModal.messageValue"
		[primaryLabel]="'html.commonInfra.components.unsaved.leave' | property"
		(onMessageButton)="unsavedDataService.onMessageButton($event)"
		(onMessageClosed)="unsavedDataService.onMessageButton($event)"
		[showSecondary]="true">
	</krn-ng-message-popup>
	<main
		#mainRef
		class="main-content"
		[class.open]="isMenuOpen"
		[ngClass]="showNavBar ? 'page-content' : 'no-nav'"
		[attr.aria-hidden]="isMenuOpen$ | async"
		[ngStyle]="{height: getMainHeight()}">
		<div class="row page-content-row">
			<krn-ng-message-group
				*ngIf="showMessage"
				[messageType]="message.messageType"
				[iconClass]="'icon-k-' + message.messageType"
				[showClose]="true"
				[messages]="messages"
				(onGroupClose)="groupMessageClosed()">
			</krn-ng-message-group>
			<div class="view-content" [class.no-nav]="!showNavBar">
				<router-outlet></router-outlet>
				<router-outlet name="app-slideout"></router-outlet>
			</div>
		</div>
	</main>
</ukg-ignite-shell>
