import {ApiCriteria, Mapping, MappingHandler} from '../platform/mobile/mapping.interface';

import {MAP_SUCCESS, MapType} from '../platform/mobile/mapping.constants';

export class EmbeddedMappingCoreService implements MappingHandler {
	constructor(protected $window: Window) {}

	public launchMap(apiCriteria: ApiCriteria, mapType: MapType): Promise<Mapping> {
		return new Promise<Mapping>((resolve, reject) => {
			this.$window.dispatchEvent(
				new CustomEvent(
					'upPostMessage',
					{
						detail: {
							method: 'launchNativeMap',
							arg: {
								apiCriteria,
								mapType
							}
						}
					})
			);
			resolve(MAP_SUCCESS);
		});
	}
}
