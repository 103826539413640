import {get, isEmpty, isString, trim} from 'lodash';

import {CURRENCY_NEGATIVE_PATTERNS, CURRENCY_POSITIVE_PATTERNS, UTILS} from './converter-util.constants';

export const getSpacing = (symbol): string => isEmpty(symbol) ? '' : ' ';

export const formatWithPositivePattern = (currencyStr: string, symbol, preferences, logger): string => {
	const positivePattern = get(preferences, 'localePolicy.currencyFormat.positivePattern');

	switch (positivePattern) {
	case CURRENCY_POSITIVE_PATTERNS.CURRENCY_LEFT_NO_SPACE:
		return `${symbol}${currencyStr}`;
	case CURRENCY_POSITIVE_PATTERNS.CURRENCY_LEFT_WITH_SPACE:
		return `${symbol}${getSpacing(symbol)}${currencyStr}`;
	case CURRENCY_POSITIVE_PATTERNS.CURRENCY_RIGHT_NO_SPACE:
		return `${currencyStr}${symbol}`;
	case CURRENCY_POSITIVE_PATTERNS.CURRENCY_RIGHT_WITH_SPACE:
		return `${currencyStr}${getSpacing(symbol)}${symbol}`;
	default:
		logger.warn(`userPreferences.currencyFormat.positivePattern = ${positivePattern} is not found in, ${CURRENCY_POSITIVE_PATTERNS}`);
		return `${currencyStr}${getSpacing(symbol)}${symbol}`;
	}
};

export const isNegativePatternCorrect = (currencyStr: string, preferences, logger): boolean => {
	const negativePattern = get(preferences, 'localePolicy.currencyFormat.negativePattern');
	const decimalSymbol = get(preferences, 'localePolicy.numberFormat.decimalSymbol');

	currencyStr = currencyStr.replace(decimalSymbol, '');
	switch (negativePattern) {
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_BETWEEN_PARENTHESIS_NO_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_BETWEEN_PARENTHESIS_WITH_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_BETWEEN_PARENTHESIS_NO_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_BETWEEN_PARENTHESIS_WITH_SPACE:
			return !!currencyStr.match(/^[(][\s]*[0-9]+[\s]*[)]$/);
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_LEFT_NO_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_LEFT_WITH_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_LEFT_NO_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_LEFT_WITH_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_BEFORE_CURRENCY_NO_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_BEFORE_CURRENCY_WITH_SPACE:
			return !!currencyStr.match(/^[-][\s]*[0-9]+$/);
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_RIGHT_NO_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_RIGHT_WITH_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_RIGHT_NO_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_RIGHT_WITH_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_AFTER_CURRENCY_NO_SPACE:
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_AFTER_CURRENCY_WITH_SPACE:
			return !!currencyStr.match(/^[0-9]+[\s]*[-]$/);
	
		default:
			logger.warn(`userPreferences.currencyFormat.negativePattern = ${negativePattern} is not found in, ${CURRENCY_NEGATIVE_PATTERNS}`);
			return false;
		}
};

export const formatWithNegativePattern = (currencyStr: string, symbol, preferences, logger): string => {
	const negativePattern = get(preferences, 'localePolicy.currencyFormat.negativePattern');

	currencyStr = trim(currencyStr.replace(UTILS.NEGATIVE_PATTERN, ''));

	switch (negativePattern) {
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_BETWEEN_PARENTHESIS_NO_SPACE:
			return `(${symbol}${currencyStr})`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_BETWEEN_PARENTHESIS_WITH_SPACE:
			return `(${symbol}${getSpacing(symbol)}${currencyStr})`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_BETWEEN_PARENTHESIS_NO_SPACE:
			return `(${currencyStr}${symbol})`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_BETWEEN_PARENTHESIS_WITH_SPACE:
			return `(${currencyStr}${getSpacing(symbol)}${symbol})`;
	
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_LEFT_NO_SPACE:
			return `${symbol}-${currencyStr}`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_LEFT_WITH_SPACE:
			return `${symbol}${getSpacing(symbol)}-${currencyStr}`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_LEFT_NO_SPACE:
			return `-${currencyStr}${symbol}`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_LEFT_WITH_SPACE:
			return `-${currencyStr}${getSpacing(symbol)}${symbol}`;
	
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_RIGHT_NO_SPACE:
			return `${symbol}${currencyStr}-`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_RIGHT_WITH_SPACE:
			return `${symbol}${getSpacing(symbol)}${currencyStr}-`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_RIGHT_NO_SPACE:
			return `${currencyStr}-${symbol}`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_RIGHT_WITH_SPACE:
			return `${currencyStr}-${getSpacing(symbol)}${symbol}`;
	
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_BEFORE_CURRENCY_NO_SPACE:
			return `-${symbol}${currencyStr}`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_LEFT_MINUS_BEFORE_CURRENCY_WITH_SPACE:
			return `-${symbol}${getSpacing(symbol)}${currencyStr}`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_AFTER_CURRENCY_NO_SPACE:
			return `${currencyStr}${symbol}-`;
		case CURRENCY_NEGATIVE_PATTERNS.CURRENCY_RIGHT_MINUS_AFTER_CURRENCY_WITH_SPACE:
			return `${currencyStr}${getSpacing(symbol)}${symbol}-`;
	
		default:
			logger.warn(`userPreferences.currencyFormat.negativePattern = ${negativePattern} is not found in, ${CURRENCY_NEGATIVE_PATTERNS}`);
			return `${currencyStr} ${symbol}`;
		}
};

export const cleanPatternSymbols = (currencyStr: string, preferences): string => {
	const groupingSymbol = get(preferences, 'localePolicy.numberFormat.groupingSymbol');

	return trim(currencyStr.replace(new RegExp(`\\${groupingSymbol}`, 'g'), ''));
};

export const parseCurrencyCode = (currencyStr: string): string => isString(currencyStr) ? currencyStr.replace(/[^A-Za-z]/g, '') : '';

export const parseFormattedAmount = (currencyStr: string): string => isString(currencyStr) ? currencyStr.replace(/[A-Za-z\s]/g, '') : currencyStr;
