import {ErrorCallback, SuccessCallback, WifiLocationContext} from './wifi-location.interface';

import {PropertyFactory} from '../../property/property.factory';
import {PropertyFilterConfig} from '../../property/property.filter';

export class BaseWifiLocationService {
	protected context: WifiLocationContext;
	protected errorMessage: string;

	constructor(
		protected window: Window,
		protected supportsWifiLocation: boolean,
		protected propertyFactory: PropertyFactory,
		protected propertyFilter: PropertyFilterConfig) {
		this.propertyFactory.loadProperties([
			{
				name: 'container_web-common_geolocation_strings.properties',
				path: '/components/zed-core/properties/platform/'
			}
		]).then(() => {
			this.errorMessage = this.propertyFilter('common.wifilocation.cannot.retrieved');
		});
	}

	public retrieveWifiLocation(successCallback: SuccessCallback, errorCallback: ErrorCallback): void {
		this.context = {};

		this.context.successCallback = successCallback;
		this.context.errorCallback = errorCallback;
		if (this.supportsWifiLocation) {
			this.requestWifiLocation();
		} else {
			this.context.errorCallback(this.errorMessage);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected requestWifiLocation(): void {}
}
