import {get, isNil} from 'lodash';
import {fromEvent} from 'rxjs';
import {filter} from 'rxjs/operators';

import {ClientContainer} from '../platform/clientContainer/client-container.interface';
import {EmbeddedIframeActions, EmbeddedPostMessageServiceInterface, NavigationResponse} from './embedded.interfaces';

import {initializeMessageListener} from '../iframe-framework/iframe-message-handler.utility';
import {SessionStorageCoreService} from '../platform/caching/sessionStorage.service';
import {isEmbeddable, isSMA, postMessageEmbedded} from './embedded-page.utility';
import {EMBEDDED_BACK_CLICKED, CAPABILITIES, EXPORT_FILE_REQUEST_ID, GEO_REQUEST_ID, KEEP_ALIVE_INTERVAL, LAUNCH_NATIVE_MAP_REQUEST_ID, LAUNCH_UNIVERSAL_LINK_REQUEST_ID, NAVIGATED_FOFWARD_REQUEST_ID, OPEN_NEW_TAB_REQUEST_ID, WIFI_INFORMATION_ID} from './embedded.constants';

export class EmbeddedPostMessageCoreService implements EmbeddedPostMessageServiceInterface {
	constructor(protected window: Window, protected sessionStorage: SessionStorageCoreService, protected clientContainerService: ClientContainer) {
		fromEvent(this.window, 'upPostMessage')
			.pipe(filter((event: CustomEvent) => event.detail && event.detail.method && this[event.detail.method]))
			.subscribe((event: CustomEvent) => event.detail.arg ? this[event.detail.method](event.detail.arg) : this[event.detail.method]());
	}

	public initializeMessageListener(getResponseFn: (action: string, url: string) => Promise<any>): void {
		initializeMessageListener(this.window, (event: any) => {
			const action: string = get(event, 'data.action', '');
			const url: string = get(event, 'data.url', '');
			const requestId: string | number = get(event, 'data.requestId');
			const response: Promise<any> = getResponseFn(action, url);

			if (response) {
				response.then(res => event.source.postMessage({requestId, result: this.getUnsavedDataResponse(res)}, '*'));
			}
		});
	}

	public sendHandlerReady(): void {
		postMessageEmbedded(this.window, {
			action: EmbeddedIframeActions.HANDLER_READY,
			capabilities: CAPABILITIES
		});
	}

	public setupKeepAlive(): void {
		if (isSMA(this.sessionStorage)) {
			this.window.setInterval(() => {
				postMessageEmbedded(this.window, {action: EmbeddedIframeActions.KEEP_ALIVE});
			}, KEEP_ALIVE_INTERVAL);
		}
	}

	public sendTitleIfNeeded({title}: {title: string}): void {
		if (isSMA(this.sessionStorage) && title) {
			postMessageEmbedded(this.window, {
				action: EmbeddedIframeActions.SET_TITLE,
				title
			});
		}
	}

	public sendNavigationRequest({path}: {path: string}): boolean {
		if (isSMA(this.sessionStorage) && !isEmbeddable(path, this.sessionStorage)) {
			postMessageEmbedded(this.window, {
				action: EmbeddedIframeActions.NAVIGATION_REQUEST,
				url: path
			});
			return true;
		}
		return false;
	}

	public getGeoCoordinates(): boolean {
		if (isSMA(this.sessionStorage)) {
			postMessageEmbedded(this.window, {requestId: GEO_REQUEST_ID, action: EmbeddedIframeActions.GET_GEO_COORDINATES});
			return true;
		}
		return false;
	}

	public openNewTab({url, target, options}: {url: string, target: string, options: string}): boolean {
		if (isSMA(this.sessionStorage)) {
			postMessageEmbedded(this.window, {requestId: OPEN_NEW_TAB_REQUEST_ID, action: EmbeddedIframeActions.OPEN_NEW_TAB, url, target, options});
			return true;
		}
		return false;
	}

	public exportFile(data: object): void {
		if (isSMA(this.sessionStorage)) {
			postMessageEmbedded(this.window, {requestId: EXPORT_FILE_REQUEST_ID, action: EmbeddedIframeActions.EXPORT_FILE, data});
		}
	}

	public getWifiInformation(): void {
		if (isSMA(this.sessionStorage)) {
			postMessageEmbedded(this.window, {requestId: WIFI_INFORMATION_ID, action: EmbeddedIframeActions.WIFI_INFORMATION});
		}
	}

	public launchNativeMap(data: object): void {
		if (isSMA(this.sessionStorage)) {
			postMessageEmbedded(this.window, {requestId: LAUNCH_NATIVE_MAP_REQUEST_ID, action: EmbeddedIframeActions.LAUNCH_NATIVE_MAP, data});
		}
	}

	public launchUniversalLink(data: object): void {
		if (isSMA(this.sessionStorage)) {
			postMessageEmbedded(this.window, {requestId: LAUNCH_UNIVERSAL_LINK_REQUEST_ID, action: EmbeddedIframeActions.LAUNCH_UNIVERSAL_LINK, data});
		}
	}

	public navigatedForward = ({url}: {url: string}): void => {
		if (
			isSMA(this.sessionStorage) &&
			!isNil(url) &&
			!this.sessionStorage.get(EMBEDDED_BACK_CLICKED) &&
			this.window === this.clientContainerService.getTopWfdWindow()
		) {
			postMessageEmbedded(this.window, {requestId: NAVIGATED_FOFWARD_REQUEST_ID, action: EmbeddedIframeActions.NAVIGATED_FOFWARD, url});
		}
	};

	public getUnsavedDataResponse(flag: boolean): NavigationResponse {
		return {cancel: flag};
	}
}
