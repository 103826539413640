import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import {Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {noop} from 'lodash';

import {ZonedDateTime} from '../../core/platform/services/time-zone.interfaces';

import {TimeZoneCoreService} from '../../core/platform/services/time-zone-core.service';
import {REQUEST_EMPLOYEE_PATH, REQUEST_USER_PATH, UPDATE_MS} from '../../core/platform/services/time-zone.constants';
import {AuthenticationRegistrationService} from '../../ui/framework/authentication/authentication.registration.service';
import {DataService} from '../communication/data.service';
import {DISABLE_TIMEZONE_SERVICE} from './time-zone.config';


@Injectable({providedIn: 'root'})
export class TimeZoneService extends TimeZoneCoreService implements OnDestroy {
	private unsubscribe$ = new Subject();

	constructor(private dataService: DataService, authenticationRegistrationService: AuthenticationRegistrationService,
		@Optional() @Inject(DISABLE_TIMEZONE_SERVICE) private disableTimeZoneService: boolean = false) {
		super();

		authenticationRegistrationService.whenAuthenticated(disableTimeZoneService ? noop : this.getTimestampWhenAuthenticated);
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next(undefined);
		this.unsubscribe$.complete();
	}

	protected nowForEmployee(employeeId: number): Promise<ZonedDateTime> {
		return this.dataService.get(REQUEST_EMPLOYEE_PATH, employeeId) as Promise<ZonedDateTime>;
	}

	protected nowForUser(): Promise<ZonedDateTime> {
		return this.dataService.get(REQUEST_USER_PATH) as Promise<ZonedDateTime>;
	}

	private getServerTimestampNow = (): void => {
		this.currentNowPromise = this.getNowFromServer();
	};

	private getTimestampWhenAuthenticated = (): void => {
		this.getServerTimestampNow();

		timer(UPDATE_MS, UPDATE_MS)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(this.getServerTimestampNow);
	};
}
