import * as jQuery from 'jquery';
import {head, isEmpty} from 'lodash';

import {FocusElementId} from './services/keyboard-navigation.interfaces';

import {keys} from '../constants/keys.constant';
import {EVENTS_HANDLERS, FOCUSABLE_ELEMENTS} from './utils.constants';

const $: any = jQuery;

export class MoveTabToBase {
	protected tabToElementId: FocusElementId;
	protected keyDownElement: HTMLElement;

	protected initialize(): void {
		$(this.keyDownElement).on(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler);
	}

	protected keyDownHandler = (event: KeyboardEvent): void => {
		if (!isEmpty(this.tabToElementId)) {
			const tabElm = $(this.tabToElementId);
			const tabElementsFirst = tabElm.is(FOCUSABLE_ELEMENTS) ? $(document).find(this.tabToElementId) : head(tabElm.find(FOCUSABLE_ELEMENTS));

			if (event.code === keys.TAB && !event.shiftKey && tabElementsFirst) {
				event.preventDefault();
				$(tabElementsFirst).trigger('focus');
			}
		}
	};

	protected destroy(): void {
		$(this.keyDownElement).off(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler);
	}
}