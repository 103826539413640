import {NavBarMenu} from '../../../../core/navBar/nav-bar-menu';

export function getNavBarMenu(): any {
	return new NavBarMenu();
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NavBarMenuProvider = {
	provide: NavBarMenu,
	deps: [],
	useFactory: getNavBarMenu
};
