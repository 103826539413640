import {chain, find, get, isEmpty, isNil} from 'lodash';

import {UserInfo} from '../framework/services/user-info.interface';
import {PropertyState} from '../property/property.interface';

import {UserInfoService} from '../framework/services/user-info.service';
import {OneAppProducts, SENDER_NAME} from '../one-app/one-app-event-bus.constants';
import {PropertyFactory} from '../property/property.factory';

export class DelegatedRoleBannerCoreComponent {
	public showBanner = false;
	public delegator = '';
	private homeMenuItem;

	constructor(protected window: any, protected propertyFactory: PropertyFactory, protected userInfoService: UserInfoService) {}

	public onInit(): void {
		Promise.all([this.loadProperties(), this.getTenantInfo(), this.getUserInfo()]).then(([_, currentTenant, userInfo]) => {
			this.setHomeMenuItem(currentTenant, userInfo);
			this.setDelegator(userInfo);
			this.setShowBanner(userInfo);
		});
	}

	public onClick(): void {
		if (this.homeMenuItem) {
			this.window.OneAppCommon.NAV.forward(this.window, SENDER_NAME, this.homeMenuItem);
		}
	}

	private loadProperties(): Promise<PropertyState> {
		return this.propertyFactory.loadProperties([
			{
				name: 'container_web-common_delegated-role-banner_strings.properties',
				path: 'components/zed-core/properties/delegated-role-banner/'
			}
		]);
	}

	private getTenantInfo(): Promise<any> {
		return this.window.OneAppCommon.DATA.getCurrentTenant(this.window, SENDER_NAME).then(response => {
			const currentTenant = get(response, 'data.value', {});

			currentTenant.attributes = isNil(currentTenant.attributes) ? {} : this.window.OneAppCommon.UTIL.b64DecodeJSON(currentTenant.attributes);
			return currentTenant;
		});
	}

	private getUserInfo(): Promise<UserInfo> {
		return this.userInfoService.getLoggedOnUserData();
	}

	private setHomeMenuItem(currentTenant, userInfo): void {
		const switchRoleType = get(currentTenant, 'attributes.parentProduct') === OneAppProducts.PRO ? 'switchProDefaultRole' : 'switchMyRole';
		const defaultRole = find(get(userInfo, 'delegate.myRoles'), {default: true});

		if (defaultRole) {
			const encodedDefaultRole = btoa(JSON.stringify(defaultRole));
			const homeUrl = new URL(`/wfd/home?${switchRoleType}=${encodedDefaultRole}`, this.window.location.origin).href;

			this.homeMenuItem = {
				id: 'footer.home',
				automationid: 'ukg.footer.Home',
				icon: 'home',
				url: homeUrl,
				type: 'WEB_CORDOVA',
				showToolbar: false,
				clearPageStack: true
			};
		}
	}

	private setDelegator(userInfo): void {
		this.delegator = get(userInfo, 'delegate.delegateActiveRole.delegator');
	}

	private setShowBanner(userInfo): void {
		this.showBanner = !isEmpty(get(userInfo, 'delegate.delegateActiveRole'));
	}
}
