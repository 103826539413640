import {Injectable} from '@angular/core';

import {ExternalIdentifierCoreService} from '../../core/iframe-framework/external-identifier.core.service';
import {SessionStorageCoreService} from '../../core/platform/caching/sessionStorage.service';
import {ExternalAuthService} from '../external-auth-service';
import {SessionStorageService} from '../session-storage.service';

@Injectable({providedIn: 'root'})
export class ExternalIdentifierService extends ExternalIdentifierCoreService {
	constructor(sessionStorageService: SessionStorageService, externalAuthService: ExternalAuthService) {
		super(sessionStorageService as SessionStorageCoreService, externalAuthService);
	}
}
