import {Injectable} from '@angular/core';

import {WindowRefService} from '../../../../../services/window-ref.service';
import {BaseSliderActionHandler} from './base-slider-action-handler';
import {ContainerSemaphoreService} from './container-semaphore.service';
import {SlideOutActionService} from '../../../../../services/slideout-action.service';
import {OtherActionService} from '../../../iframe-slideout/other-action.service';

@Injectable({
	providedIn: 'root'
})
export class DefaultSliderActionHandler extends BaseSliderActionHandler {
	constructor(
		public window: WindowRefService,
		public otherActionService: OtherActionService,
		public semaphoreService: ContainerSemaphoreService,
		public slideOutActionService: SlideOutActionService
	) {
		super(window, otherActionService, semaphoreService, slideOutActionService);
	}

	public dispatchToWindow(action: any, internal): void {
		this.sendData(action, internal);
	}
}
