export const LOGON_PROFILE_SESSION_STORAGE_KEY = 'mobileApp_logonProfile';
export const BIO_AUTH = 'biometric';
export const ANY_AUTH = 'any';
export const PUNCH_CODE = 1;
export const LOCAL_AUTH_INT_ERR_AUTH_REQ = 'Local Auth type is required.';
export const LOCAL_AUTH_INT_ERR_CANNOT_USE = 'Local Authentication cannot be used.';
export const LOCAL_AUTH_INT_ERR_FLAG_REQ = 'Local Auth flag is required.';
export const LOCAL_AUTH_INT_ERR_INVALID_TYPE = 'Invalid Local Auth type requested.';
export const ONE_LOGON_PROFILE = 'oneapp.logonProfile';
export const LOCAL_AUTH_MSG_CODES = {
    LOGON: 0,
    PUNCH: 1,
    ERROR: 2
};
export const LOCAL_AUTH_MSG_LIST = [
    {ID: LOCAL_AUTH_MSG_CODES.LOGON, MSG: 'common.local.auth.msg.codes.logon'},
    {ID: LOCAL_AUTH_MSG_CODES.PUNCH, MSG: 'common.local.auth.msg.codes.punch'},
    {ID: LOCAL_AUTH_MSG_CODES.ERROR, MSG: 'common.local.auth.msg.codes.error'}
];
