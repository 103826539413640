import * as jQuery from 'jquery';
import {filter, find, forEach, isEqual, noop} from 'lodash';

import {KeyNavService} from './keyboard-navigation.interfaces';

import {keyCodes, keys} from '../../constants/keys.constant';
import {toFocusableElementString} from '../focusable-elements.utility';
import {ELEMENTS_TO_INCLUDE, EVENTS_HANDLERS} from '../utils.constants';

const $: any = jQuery;

export class EnterDetectionService  implements KeyNavService {
	private KEYDOWN_HANDLERS;
	
	constructor(private focusableElements: HTMLElement[], private focusableElementStrings: string[], private enterCallback) {
		this.KEYDOWN_HANDLERS = {
			[keys.ENTER]: this.enterHandler,
			[keys.UP_ARROW]: this.enterHandler,
			[keys.DOWN_ARROW]: this.enterHandler
		};
	}

	public getNode(element: HTMLElement): any {
		return $(element);
	}

	public initialize(): void {
		forEach(this.focusableElements, element => this.getNode(element).on(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler));
	}

	public destroy(): void {
		forEach(this.focusableElements, element => this.getNode(element).off(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler));
	}

	private keyDownHandler = (event: KeyboardEvent): void => {
		(this.KEYDOWN_HANDLERS[event.code] || noop)(event);
	};

	private enterHandler = (event: KeyboardEvent): void => {
		if (this.enterCallback) {
			setTimeout(() => {
				const focusElementString = toFocusableElementString(document.activeElement as HTMLElement);
				const found = find(this.focusableElementStrings, element => isEqual(element, focusElementString));
				const includedElements = filter(ELEMENTS_TO_INCLUDE, ele => $(event.target).is(ele));

				if (found && includedElements.length && (event.keyCode !== keyCodes.UP_ARROW && event.keyCode !== keyCodes.DOWN_ARROW)) {
					event.preventDefault();
					// eslint-disable-next-line no-unused-expressions
					this.enterCallback.emit ? this.enterCallback.emit() : this.enterCallback();
				}
			});
		}
	};
}
