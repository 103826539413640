import {Injectable} from '@angular/core';

import {DeviceConfigService} from '../../../services/device-config.service';
import {WindowRefService} from '../../../services/window-ref.service';
import {SLIDE_OUT_ISO_DEVICE_HELPER_ID} from '../../../core/generic/slideOutContainer/slideout.constants';

const DEFAULT_DELAY = 450;

@Injectable({
	providedIn: 'root'
})
export class SlideOutAccessibilityService {
	constructor(
		private deviceConfigService: DeviceConfigService,
		private windowRefService: WindowRefService
	) {}

	public iosDeviceFocusHelper(delay = DEFAULT_DELAY): void {
		this.windowRefService.nativeWindow.setTimeout(() => {
			const iosDeviceHelperEle = this.windowRefService.nativeWindow.document.querySelector(`#${SLIDE_OUT_ISO_DEVICE_HELPER_ID}`);

			iosDeviceHelperEle.focus();
		}, delay);
	}

	public isNeedIosDeviceHelper = (): boolean => this.deviceConfigService.getMobileOS() === 'ios' && this.windowRefService.nativeWindow.self === this.windowRefService.nativeWindow.top;
}
