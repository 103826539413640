import {MenuItemConfig} from './models/menu-item-config.model';
import {getFilteredMenu} from './search-input.utils';

export interface SearchInputConfig {
	filteredMenu: MenuItemConfig[];
	foundLinksAmount: number;
	searchId: string;
}

export class SearchInput {
	private foundLinksAmount = 0;

	public getFoundLinksAmount(): any {
		return this.foundLinksAmount;
	}

	public clearFoundLinksAmount(): any {
		this.foundLinksAmount = 0;
	}

	public getFilteredMenuAndSetFoundLinks(menu: MenuItemConfig[], searchText: string): MenuItemConfig[] {
		const {foundLinksAmount, filteredMenu} = getFilteredMenu(menu, searchText, this.foundLinksAmount);

		this.foundLinksAmount = foundLinksAmount;
		return filteredMenu;
	}
}
