<div *ngIf="groupModels" class="clearfix message-framework-inpage" role="dialog"
	[krn-ng-attributes]="{
		'aria-label': 'html.commonInfra.components.common.inlinemessage.count' | property:[groupModels.length]
	}" tabindex="0">

	<div *ngFor="let msgGroup of (groupModels); let i = index;" class="msg-wrapper" [ngClass]="classes"> 
		<krn-ng-message-group [messageType]="msgGroup?.messageType" [messages]="msgGroup?.messages"
				[iconClass]="msgGroup?.options?.messageIconClass" [showClose]="showClose || msgGroup?.options?.showCloseButton"
				[showGroupName]="msgGroup?.options?.showType" [fixedHeight]="msgGroup?.options?.fixedHeight" 
				[focus]="setFocus && (firstClose === msgGroup?.messageType)"
				[attrs]="attrs"
				[messagesIdSuffix]="messagesIdSuffix + '-' +  i"
                (onGroupClosed)=onGroupClosed($event)>
		</krn-ng-message-group>
	</div>	
</div>
