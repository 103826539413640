import {Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {isArray} from 'lodash';

import {SimpleInputAttrs} from '../../core/generic/simple-input/simple-input.interfaces';

import {
	getAttributes,
	getInputTooltip,
	getInputValue,
	isButton,
	isButtonPlaceholder,
	generateAriaLabelBy
} from '../../core/generic/simple-input/simple-input.utility';
import {getPlacement} from '../../core/platform/form-element.utility';
import {ReactiveFormsBase} from '../../utils/reactive-forms/reactive-forms-base.class';

@Component({
	selector: 'krn-ng-simple-input',
	templateUrl: './simple-input.component.html',
	styleUrls: ['./simple-input.component.less'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SimpleInputComponent),
			multi: true
		}
	]
})
export class SimpleInputComponent extends ReactiveFormsBase implements OnChanges {
	@Input() public alignment: string;
	@Input() public classes: string | string[];
	@Input() public hint: string;
	@Input() public label: string;
	@Input() public attrs: SimpleInputAttrs;
	@Input() public inputValue: any;

	@Output() public blur = new EventEmitter<any>();
	@Output() public focus = new EventEmitter<any>();
	@Output() public inputClick = new EventEmitter<any>();
	@Output() public inputValueChange = new EventEmitter<any>();

	public placement: string;
	public inputTooltip: string | number;
	public isFocused: boolean;
	public ariaLabelBy: string;
	public inputClasses: string;

	public isButton = isButton;
	public isPlaceholder = isButtonPlaceholder;

	public ngOnChanges(changes?: SimpleChanges): void {
		this.attrs = getAttributes(this.attrs, this.label);
		this.inputValue = getInputValue(this.attrs, this.inputValue);
		this.placement = getPlacement(this.alignment, this.attrs.dir);
		this.inputTooltip = getInputTooltip(this.attrs, this.inputValue, this.classes);
		this.ariaLabelBy = generateAriaLabelBy(this.attrs, this.label, this.hint);
		this.setInputClasses();
	}

	public onInputClick(event: MouseEvent | KeyboardEvent): void {
		if (isButton(this.attrs)) {
			this.inputClick.emit(event);
		}
	}

	public onValueChange(newValue: string | number): void {
		if (!isButtonPlaceholder(this.attrs, newValue)) {
			this.inputValueChange.emit(newValue);
			this.onChange(newValue);
		}
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public writeValue(outsideValue: any): void {
		this.inputValue = outsideValue;
		this.onValueChange(outsideValue);
	}

	public handleFocus($event: FocusEvent): void {
		this.focus.emit($event);
		this.isFocused = true;
	}

	public handleBlur($event: FocusEvent): void {
		this.blur.emit($event);
		this.isFocused = false;
	}

	private setInputClasses = (): void => {
		const classes = isArray(this.classes) ? this.classes.join(' ') : this.classes;

		if (this.inputClasses !== classes) {
			this.inputClasses = classes;
		}
	};
}
