import {Injectable} from '@angular/core';
import {isFunction} from 'lodash';
import {Subject} from 'rxjs';

import {UnsavedModal} from '../core/framework/services/unsaved-data.interfaces';
import {MessagePopupButton} from '../core/generic/message/message.interface';

import {UnsavedDataCoreService} from '../core/framework/services/unsaved-data-core.service';
import {PropertyFactory} from '../core/property/property.factory';
import {PropertyFilterPipe} from '../providers/property/property.filter.pipe';
import {BeforeUnloadService} from './before-unload.service';
import {ClientContainerContextService} from './clientContainer/client-container-context.service';
import {WindowRefService} from './window-ref.service';

@Injectable({
	providedIn: 'root',
	deps: [WindowRefService, PropertyFilterPipe, BeforeUnloadService, PropertyFactory]
})
export class UnsavedDataService extends UnsavedDataCoreService {
	public unsavedModal: UnsavedModal;
	public redirect$ = new Subject<boolean>();
	public showCustomUnsavedDialog$ = new Subject<void>();

	constructor(window: WindowRefService, propertyFilter: PropertyFilterPipe, beforeUnloadService: BeforeUnloadService, propertyFactory: PropertyFactory) {
		super(window.nativeWindow, propertyFilter.transform.bind(propertyFilter), beforeUnloadService, propertyFactory);
		this.unsavedModal = this.initUnsavedDialog();
	}

	public onMessageButton = (button: MessagePopupButton): void => {
		const redirect = button === MessagePopupButton.primary;

		if (redirect) {
			this.setDirty(false);
			if (isFunction(this.unsavedModal.yesClicked)) {
				this.unsavedModal.yesClicked();
			}
		} else if (isFunction(this.unsavedModal.noClicked)) {
			this.unsavedModal.noClicked();
		}
		
		this.unsavedModal.open = false;
		this.redirect(redirect);
	};

	public redirect = (redirect: boolean): void => {
		this.redirect$.next(redirect);
	};

	public showCustomUnsavedDialog = (): void => {
		this.showCustomUnsavedDialog$.next();
	};
}
