export const TALK_MFE = {
	pinnedPost: 'pinned-post',
	chatIcon: 'chat-icon'
};

export const PINNED_POST_OPTIONS = {
	remoteEntry: 'static/public/micro-frontends/mf-feedlist/v2/remoteEntry.js',
	remoteName: 'mf_feedlist',
	exposedModule: './App',
	elementName: 'mf-feedlist'
};

export const CHAT_ICON_OPTIONS = {
	remoteEntry: 'static/public/micro-frontends/mf-chat/v1/remoteEntry.js',
	remoteName: 'mf_chat',
	exposedModule: './App',
	elementName: 'mf-chat'
};

export const END_POINT = {
	dataServer: '/talk/proxyserver',
	talkConfig: '/talk/configdetails'
};

export const TALK_PERMISSION = {
	PINNED_POST_FS: 'TalkPinnedPost',
	CHAT_ICON_FS: 'TalkChatAndActivity'
};
