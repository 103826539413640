import {Injectable} from '@angular/core';

import {BaseWifiLocationService} from '../core/platform/services/base-wifi-location.service';
import {PropertyFactory} from '../core/property/property.factory';
import {PropertyFilterPipe} from '../providers/property/property.filter.pipe';
import {ClientContainerService} from './clientContainer/client-container.service';

@Injectable({
	providedIn: 'root',
	deps: [ClientContainerService, PropertyFactory, PropertyFilterPipe],
	useFactory: getWifiLocationService
})
export class WifiLocationService extends BaseWifiLocationService {}

export function getWifiLocationService(
	clientContainerService: ClientContainerService,
	propertyFactory: PropertyFactory,
	propertyFilter: PropertyFilterPipe
): BaseWifiLocationService {
	return clientContainerService.wifiLocation(propertyFactory, propertyFilter.transform.bind(propertyFilter));
}
