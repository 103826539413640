<krn-slide-out-container
	id="contextual-help-search-filter"
	class="contextual-help-search-filter"
	(onSlideOutClose)="cancel()"
	[removeSlideOutOnClose]="true"
	[navigateOnClose]="false"
	krn-ng-return-focus-element="helpSearchRefineAction"
	(onLoad)="onSlideOutLoad()">
	<div class="slideout-container-content">
		<div class="slideout__header filter-header">
			<h1 id="form-title" class="slideout-header-title">{{ 'html.navbar.contextualHelp.search.refine.heading' | property }}</h1>
		</div>

		<div class="slideout__content filter-content" tabindex="-1">
			<krn-ng-loader classes="bg-transparent spinner-size-small contextual-help-result-spinner" *ngIf="loading"></krn-ng-loader>
			<div krn-ng-focus-on-show focus-delay="150" *ngIf="!loading">
				<div class="row">
					<krn-ng-button
						*ngIf="refined"
						[buttonText]="'html.navbar.contextualHelp.search.refine.clear' | property"
						(buttonClicked)="clearFilter()"
						classes="btn-primary btn-link btn-icon-small"
						id="clearFilterAction"
						[attrs]="{
							'aria-label': 'html.navbar.contextualHelp.search.refine.clear' | property,
							'automation-id': 'clearFilterBtn',
							title: 'html.navbar.contextualHelp.search.refine.clear' | property
						}">
					</krn-ng-button>
				</div>

				<div class="section">
					<h2 class="source-header">{{ sources?.label }}</h2>
					<div *ngFor="let source of sources?.values">
						<krn-ng-select-item
							[attr.automation-id]="generateInputAutomationId('source', source.displayName)"
							selectType="multi"
							[isSelected]="source.checked"
							showAsNative="true"
							(selectedChange)="addSourceFilter(source.Contentname)">
							<div class="source-text">{{ source.displayName }} [{{ source.value }}]</div>
						</krn-ng-select-item>
					</div>
				</div>

				<div class="section" *ngIf="createdDates?.values?.length > 0">
					<h2 class="source-header">{{ createdDates?.label }}</h2>
					<div *ngFor="let createdDate of createdDates?.values">
						<krn-ng-select-item
							[attr.automation-id]="generateInputAutomationId('date', createdDate.Contentname)"
							selectType="single"
							[isSelected]="createdDate.checked"
							showAsNative="true"
							[attrs]="{name: 'dates'}"
							(selectedChange)="addDateFilter(createdDate.Contentname)">
							<div class="source-text">
								{{ createdDate.Contentname }} <span *ngIf="createdDate.Contentname !== ALLTIME_LABEL">[{{ createdDate.value }}]</span>
							</div>
						</krn-ng-select-item>
					</div>
				</div>
			</div>
		</div>

		<div class="slideout__footer">
			<div class="d-flex justify-content-end">
				<krn-ng-button
					[attrs]="{
						id: 'cancel_button',
						title: 'html.navbar.contextualHelp.search.refine.cancel' | property,
						'aria-label': 'html.navbar.contextualHelp.search.refine.cancel' | property
					}"
					classes="btn-secondary h-100 btn-justify-center"
					[buttonText]="'html.navbar.contextualHelp.search.refine.cancel' | property"
					(buttonClicked)="cancel()">
				</krn-ng-button>
				<krn-ng-button
					[attrs]="{
						id: 'apply_button',
						title: 'html.navbar.contextualHelp.search.refine.apply' | property,
						'aria-label': 'html.navbar.contextualHelp.search.refine.apply' | property
					}"
					classes="btn-primary h-100 btn-justify-center"
					[buttonText]="'html.navbar.contextualHelp.search.refine.apply' | property"
					(buttonClicked)="apply()">
				</krn-ng-button>
			</div>
		</div>
	</div>
</krn-slide-out-container>
