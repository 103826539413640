import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {isEmpty} from 'lodash';

import {Props, RemoteOptions} from '../../../../../../services/mfe/mfe.interfaces';

import {TALK_MFE, TALK_PERMISSION} from '../../../../../../services/mfe/mfe.constants';
import {MfeService} from '../../../../../../services/mfe/mfe.service';

@Component({
  selector: 'krn-ng-talk-icons',
  templateUrl: './talk-icons.component.html',
  styleUrls: ['./talk-icons.component.less']
})
export class TalkIconsComponent implements OnInit {
	public remoteOptions: RemoteOptions;
	public props: Props;
	public featureSwitch: boolean;

	constructor(private mfeService: MfeService, private cdr: ChangeDetectorRef) {}

	public ngOnInit(): void {
		this.mfeService.isFeatureSwitchEnabled(TALK_PERMISSION.CHAT_ICON_FS).then(chatIconFeatureSwitch => {
			this.featureSwitch = chatIconFeatureSwitch;

			if (chatIconFeatureSwitch) {
				this.mfeService.getRemoteOptions(TALK_MFE.chatIcon).then(res => {
					this.remoteOptions = isEmpty(res) ? null : res;
					this.cdr.detectChanges();
				});
				this.props = this.mfeService.getProps(TALK_MFE.chatIcon);
			}
		});
	}
}
