import {get, has} from 'lodash';

import {loadMessageProperties} from '../../../../zed-core/generic/message/message.utility';
import {PropertyFactory} from '../../../../zed-core/property/property.factory';

export const getPersistentBannerProperty = (propertyFactory: PropertyFactory, messageKey: string, callback: ((message: string) => void)): void => {
	loadMessageProperties(propertyFactory).then(messageData => {
		if (has(messageData, 'properties')) {
			callback(messageData.properties[messageKey]);
		}
	});
};

export const getHelpUrl = (preferencesService, helpBaseUrl: string): string =>  {
	const preferences = preferencesService.getPreferences();
	const languageCode = get(preferences, 'localePolicy.languageCode').toLowerCase();
	const countryCode = get(preferences, 'localePolicy.countryCode');

	return  `${helpBaseUrl}/${languageCode}_${countryCode}/Content/MasterTopics/BrowserSupport.htm`;
};

export const getMessageReplacements = (url: string, message: string): string => `<a target="_blank" href='${url}'>${message}</a>`;
