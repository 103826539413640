export const NEGATIVE_PATTERNS = {
	BETWEEN_PARENTHESIS: 'BETWEEN_PARENTHESIS',
	MINUS_RIGHT_NO_SPACE: 'MINUS_RIGHT_NO_SPACE',
	MINUS_RIGHT_WITH_SPACE: 'MINUS_RIGHT_WITH_SPACE',
	MINUS_LEFT_NO_SPACE: 'MINUS_LEFT_NO_SPACE',
	MINUS_LEFT_WITH_SPACE: 'MINUS_LEFT_WITH_SPACE'
};
export const GROUPING_PATTERNS = {
	NO_GROUP: 'NO_GROUP',
	GROUP_BY_3: 'GROUP_BY_3',
	GROUP_ONLY_LAST_3: 'GROUP_ONLY_LAST_3',
	GROUP_BY_2_EXCEPT_LAST_3: 'GROUP_BY_2_EXCEPT_LAST_3'
};
export const CURRENCY_POSITIVE_PATTERNS = {
	CURRENCY_LEFT_NO_SPACE: 'CURRENCY_LEFT_NO_SPACE',
	CURRENCY_LEFT_WITH_SPACE: 'CURRENCY_LEFT_WITH_SPACE',
	CURRENCY_RIGHT_NO_SPACE: 'CURRENCY_RIGHT_NO_SPACE',
	CURRENCY_RIGHT_WITH_SPACE: 'CURRENCY_RIGHT_WITH_SPACE'
};
export const CURRENCY_NEGATIVE_PATTERNS = {
	CURRENCY_LEFT_BETWEEN_PARENTHESIS_NO_SPACE: 'CURRENCY_LEFT_BETWEEN_PARENTHESIS_NO_SPACE',
	CURRENCY_LEFT_BETWEEN_PARENTHESIS_WITH_SPACE: 'CURRENCY_LEFT_BETWEEN_PARENTHESIS_WITH_SPACE',
	CURRENCY_RIGHT_BETWEEN_PARENTHESIS_NO_SPACE: 'CURRENCY_RIGHT_BETWEEN_PARENTHESIS_NO_SPACE',
	CURRENCY_RIGHT_BETWEEN_PARENTHESIS_WITH_SPACE: 'CURRENCY_RIGHT_BETWEEN_PARENTHESIS_WITH_SPACE',

	CURRENCY_LEFT_MINUS_LEFT_NO_SPACE: 'CURRENCY_LEFT_MINUS_LEFT_NO_SPACE',
	CURRENCY_LEFT_MINUS_LEFT_WITH_SPACE: 'CURRENCY_LEFT_MINUS_LEFT_WITH_SPACE',
	CURRENCY_RIGHT_MINUS_LEFT_NO_SPACE: 'CURRENCY_RIGHT_MINUS_LEFT_NO_SPACE',
	CURRENCY_RIGHT_MINUS_LEFT_WITH_SPACE: 'CURRENCY_RIGHT_MINUS_LEFT_WITH_SPACE',

	CURRENCY_LEFT_MINUS_RIGHT_NO_SPACE: 'CURRENCY_LEFT_MINUS_RIGHT_NO_SPACE',
	CURRENCY_LEFT_MINUS_RIGHT_WITH_SPACE: 'CURRENCY_LEFT_MINUS_RIGHT_WITH_SPACE',
	CURRENCY_RIGHT_MINUS_RIGHT_NO_SPACE: 'CURRENCY_RIGHT_MINUS_RIGHT_NO_SPACE',
	CURRENCY_RIGHT_MINUS_RIGHT_WITH_SPACE: 'CURRENCY_RIGHT_MINUS_RIGHT_WITH_SPACE',

	CURRENCY_LEFT_MINUS_BEFORE_CURRENCY_NO_SPACE: 'CURRENCY_LEFT_MINUS_BEFORE_CURRENCY_NO_SPACE',
	CURRENCY_LEFT_MINUS_BEFORE_CURRENCY_WITH_SPACE: 'CURRENCY_LEFT_MINUS_BEFORE_CURRENCY_WITH_SPACE',
	CURRENCY_RIGHT_MINUS_AFTER_CURRENCY_NO_SPACE: 'CURRENCY_RIGHT_MINUS_AFTER_CURRENCY_NO_SPACE',
	CURRENCY_RIGHT_MINUS_AFTER_CURRENCY_WITH_SPACE: 'CURRENCY_RIGHT_MINUS_AFTER_CURRENCY_WITH_SPACE'
};
export const DATE_TIME_PATTERN = {
	longDateFormatId: 'LONG_DATE_TIME',
	longDateTimeFormatId: 'LONG_DATE_TIME_EXTENDED',
	shortDateFormatId: 'SHORT_DATE_TIME',
	dayMonthFormatId: 'DAY_MONTH_DATE_TIME',
	shortDateMonthFormatId: 'SHORT_DATE_MONTH',
	timeFormatId: 'TIME_DATE_TIME',
	monthShortId: 'MONTH_SHORT',
	monthId: 'MONTH',
	dayNameId: 'DAY_NAME',
	dateNumberId: 'DATE_NUMBER',
	ISO8601Id: 'ISO_8601',
	ISOCalendarDateId: 'CALENDAR_DATE',
	ISOLongDateTimeId: 'ISO_LONG_DATE_TIME',
	momentLongDateTimeId: 'MOMENT_LONG_DATE_TIME',
	momentDateTimeId: 'MOMENT_DATE_TIME',
	monthDayId: 'MONTH_DAY',
	monthYearId: 'MONTH_YEAR',
	shortDayId: 'SHORT_DAY',
	shortMonthYearId: 'SHORT_MONTH_YEAR',
	yearId: 'YEAR'
};
export const UTILS = {
	DAY_DURATION: 24,
	WEEK_DURATION_IN_DAYS: 7,
	TEN_DIGIT: 10,
	MINUS_NINE_DIGIT: -9,
	ZERO_STRING: '0',
	ONE_DAY: 1,
	ONE_WEEK: 1,
	SECONDS_IN_HOUR: 3600,
	NEGATIVE_PATTERN: /[(|)|-]/g,
	FOREVER_DATE: '3000-01-01'
};
export const TIME_UNITS = {
	MILLISECONDS: 'milliseconds',
	SECONDS: 'seconds',
	MINUTES: 'minutes',
	HOURS: 'hours'
};
export const CUSTOM_MOMENT_LOCALES = {
	TWO_LETTER_SHORT_DAY_LOCALES: ['de', 'nl']
};
export enum GRANULARITY {
	YEAR = 'year',
	MONTH = 'month',
	WEEK = 'week',
	DAY = 'day',
	HOUR = 'hour',
	MINUTE = 'minute',
	SECOND = 'second'
}

export enum DAYS_LOCALE {
	SATURDAY = 7
}
export enum DAY {
	THURSDAY = 4
}
export enum YEAR {
	LEAP = 366,
	COMMON = 365
}
