import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

import {PreferencesCoreService} from '../../core/platform/preferences/preferences.service';
import {CurrencyCoreService} from '../../core/platform/converters/currency.core.service';
import {ConverterUtilService} from './converter-util.service';
import {FloatService} from './float.service';

@Injectable({providedIn: 'root'})
export class CurrencyService extends CurrencyCoreService {
	constructor(logService: NGXLogger, preferencesService: PreferencesCoreService, converterUtilService: ConverterUtilService, floatService: FloatService) {
		super(converterUtilService, floatService, preferencesService.getPreferences(), logService);
	}
}
