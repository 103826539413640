import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {get, isEmpty, isEqual, last} from 'lodash';

import {IframeSlideOut} from './custom-iframe-slideout.interfaces';

import {ACTION_TYPES} from '../../../../core/generic/slideOutContainer/slideout.constants';
import {WindowRefService} from '../../../../services/window-ref.service';
import {ContainerSemaphoreService} from '../../slide-out/slide-out-content/handlers/container-semaphore.service';
import {IframeSlideoutComponent} from '../iframe-slideout.component';
import {DefaultSliderActionHandler} from '../../slide-out/slide-out-content/handlers/default-slider-action-handler';
import {SlideOutActionService} from '../../../../services/slideout-action.service';
import {OtherActionService} from '../other-action.service';

@Component({
	selector: 'krn-ng-custom-iframe-slideout',
	templateUrl: './custom-iframe-slideout.component.html'
})
export class CustomIframeSlideoutComponent {
	public slideouts: IframeSlideOut[] = [];

	constructor(
		private otherActionService: OtherActionService,
		private route: ActivatedRoute,
		private router: Router,
		private semaphoreService: ContainerSemaphoreService,
		private windowRefService: WindowRefService,
		public slideOutActionService: SlideOutActionService
	) {
		this.route.params.subscribe(this.onParamsLoaded);
	}

	private onParamsLoaded = (params): void => {
		if (isEqual(get(params, 'action'), ACTION_TYPES.CLOSE)) {
			this.slideouts.pop();
		} else {
			const actionHandler = new DefaultSliderActionHandler(this.windowRefService, this.otherActionService, this.semaphoreService, this.slideOutActionService);
			const navigationContext = this.router.getCurrentNavigation();

			actionHandler.slideOutId = get(params, 'serviceName', actionHandler.slideOutId);
			this.slideouts.push({
				component: IframeSlideoutComponent,
				inputs: [
					{name: 'actionHandler', value: actionHandler},
					{name: 'isCustomIframeContent', value: true},
					{name: 'iframeId', value: null},
					{name: 'iframeConfig', value: get(navigationContext, 'extras.state')},
					{name: 'navigateOnClose', value: isEmpty(this.slideouts)},
					{name: 'onClose', value: this.onClose}
				]
			});
		}
	};

	private onClose = (): void => {
		const serviceName = get(last(this.slideouts), 'inputs.[0].value.slideOutId');

		if (!isEmpty(serviceName)) {
			this.router.navigate([{outlets: {'app-slideout': `customIframeSlideout/iframe-container/${serviceName}/${ACTION_TYPES.CLOSE}`}}], {
				skipLocationChange: true
			});
		}
	};
}
