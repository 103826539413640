import {AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import * as jquery from 'jquery';
import {find, get, has, isEmpty, isEqual, isNil, set} from 'lodash';
import {NGXLogger} from 'ngx-logger';
import {Observable, Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';

import {ImpersonationAllowedEmployment, ImpersonationDetails, UserInfo} from '../../../../../core/framework/services/user-info.interface';

import {setLocationUrl} from '../../../../../core/iframe-framework/iframe-message-handler.utility';
import {ProfileMenu, ProfileMenuConfig} from '../../../../../core/navBar/profile-menu';
import {keyCodes} from '../../../../../core/platform/constants/keys.constant';
import {PropertyFactory} from '../../../../../core/property/property.factory';
import {OfflineButtonService} from '../../../../../services/offline-button.service';
import {WindowRefService} from '../../../../../services/window-ref.service';
import {NavBarMenuService} from '../../nav-bar-menu.service';
import {UserImpersonationService} from '../user-impersonation/user-impersonation.service';
import {NavbarActionService} from '../../container/navbar.action.service';
import {FOCUS_DELAY} from '../menu-item/menu-item.constant';

const $: JQueryStatic = jquery;

@Component({
	selector: 'krn-ng-profile-menu',
	templateUrl: './profile-menu.component.html',
	styleUrls: ['./profile-menu.component.less']
})
export class ProfileMenuComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
	@Input() public userInfo: UserInfo;
	@Input() public isMainMenuOpen: boolean;
	@ViewChild('signOutBtn')
	public signOutBtn: ElementRef;
	@ViewChild('settingsMenu')
	public settingsMenu: ElementRef;

	public readonly additionalData$: Observable<object>;

	public showSettingsMenu: boolean;
	public config: ProfileMenuConfig;
	public menuItemLength: number;
	public currentPhotoId: string;
	public showAvatarIcon: boolean;
	public caretStatus: string;
	public activeEmployment: ImpersonationAllowedEmployment;

	public isImpersonationAllowed = this.userImpersonationService.isImpersonationAllowed;
	public isMultipleEmploymentsMode = this.userImpersonationService.isMultipleEmploymentsMode;

	private unsubscribe$ = new Subject();

	constructor(
		private router: Router,
		private profileMenu: ProfileMenu,
		private logger: NGXLogger,
		private navBarMenuService: NavBarMenuService,
		private offlineButtonService: OfflineButtonService,
		private windowRefService: WindowRefService,
		private userImpersonationService: UserImpersonationService,
		private propertyFactory: PropertyFactory,
		private navbarActionService: NavbarActionService
	) {
		this.showSettingsMenu = false;
		this.showAvatarIcon = false;
		this.caretStatus = '';
		this.config = this.profileMenu.config;
		this.config.changePassword.href = this.config.changePassword.uri.startsWith('/')
			? this.config.changePassword.uri
			: `/${this.config.changePassword.uri}`;

		this.propertyFactory.loadProperties([
			{
				name: 'container_web-userprofile_userProfile_strings.properties',
				path: '/apps/userProfile/pages/properties/'
			}
		]);
		this.additionalData$ = this.navbarActionService.select(['navBar', 'additionalData']).pipe(takeUntil(this.unsubscribe$));
	}

	@HostListener('keydown', ['$event'])
	public handleKeyDown(event): void {
		const settingMenuElement = document.querySelector('.settings-menu');

		if (event.keyCode === keyCodes.ESC && settingMenuElement.contains(event.target)) {
			event.stopPropagation();
			this.showSettingsMenu = !this.showSettingsMenu;
			this.toggleAvatarIcon();
			$('.navmenu-info').focus();
		}
	}

	public ngAfterViewInit(): void {
		if (this.offlineButtonService.canWorkOffline()) {
			$('.navmenu-offline').removeClass('ng-hide');
		}
	}

	public ngOnInit(): void {
		this.navBarMenuService.settingsMenuToggle$().pipe(takeUntil(this.unsubscribe$)).subscribe(this.toggleSettings);
		this.navBarMenuService.offlineMode$().pipe(takeUntil(this.unsubscribe$)).subscribe(this.runOfflineMode);
		this.userImpersonationService.getUserImpersonation().then(this.setImpersonationDetails).catch(this.logger.error.bind(this.logger));
		this.additionalData$.subscribe(this.onAdditionalData);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (has(changes, 'isMainMenuOpen')) {
			const mainMenuCurrentValue = changes.isMainMenuOpen.currentValue;
			const mainMenuPreviousValue = changes.isMainMenuOpen.previousValue;

			if (!isEqual(mainMenuCurrentValue, mainMenuPreviousValue) && !mainMenuCurrentValue && this.showSettingsMenu) {
				this.config.settings.open = this.showSettingsMenu = !this.showSettingsMenu;
			}
		}
		if (
			!this.showAvatarIcon &&
			has(changes, 'userInfo.currentValue.delegate.myRoles') &&
			has(changes, 'userInfo.currentValue.delegate.switchRoleDelegates') &&
			(changes.userInfo.currentValue.delegate.myRoles.length > 1 || changes.userInfo.currentValue.delegate.switchRoleDelegates.length > 0)
		) {
			this.showAvatarIcon = true;
		}

		if (
			!this.showAvatarIcon &&
			get(changes, 'userInfo.currentValue.impersonate.isImpersonationAllowed', false) &&
			this.isImpersonationAllowed(changes.userInfo.currentValue.impersonate)
		) {
			this.showAvatarIcon = true;
		}
		this.toggleAvatarIcon();
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next(undefined);
		this.unsubscribe$.complete();
	}

	public toggleAvatarIcon(): void {
		this.caretStatus = this.showAvatarIcon ? (this.showSettingsMenu ? 'icon-k-small-caret-up' : 'icon-k-small-caret-down') : '';
	}

	public toggleSettings = (): void => {
		this.config.settings.open = this.showSettingsMenu = !this.showSettingsMenu;
		this.menuItemLength = this.menuItemLength ? this.menuItemLength : $('.settings-menu .menuOption').length;
		this.toggleAvatarIcon();
		if (this.config.settings.open && this.menuItemLength > 0) {
			setTimeout(() => $('.settings-menu .menuOption').first().find('.info-menu').focus(), FOCUS_DELAY);
		}
	};

	public settingsMenuKeydown(event): void {
		this.navBarMenuService.menuArrowKeyCallback(!this.config.settings.open, event, this.toggleSettings.bind(this));
	}

	public goToCurrentPage(currentUrl: string): void {
		if (currentUrl === this.config.signOut.uri) {
			this.router.navigateByUrl(currentUrl);
		} else {
			setLocationUrl(this.windowRefService.nativeWindow, currentUrl, true);
		}
	}

	public setImpersonationDetails = (impersonationDetails: ImpersonationDetails): UserInfo => {
		if (get(impersonationDetails, 'isMultipleEmploymentMode')) {
			this.showAvatarIcon = true;
			this.toggleAvatarIcon();
			this.setActiveEmployment(impersonationDetails.impersonationAllowedEmployments);
		}
		return set(this.userInfo, 'impersonate', impersonationDetails);
	};

	private onAdditionalData = (res): void => {
		if (!isEmpty(res) && !this.showAvatarIcon) {
			this.showAvatarIcon = true;
		}
	};

	private runOfflineMode = (): void => this.offlineButtonService.runOfflineMode();

	private setActiveEmployment(employmentList: ImpersonationAllowedEmployment[]): void {
		if (!isEmpty(employmentList) && !isNil(this.userInfo.personId)) {
			this.activeEmployment = find(employmentList, employment =>
				employment.personId === this.userInfo.personId);
			set(this.activeEmployment, 'active', true);
		} else if (!isEmpty(employmentList)) { // the impersonation call can succeed before the userData call which means we need to retry
			setTimeout(this.setActiveEmployment.bind(this, employmentList), 250);
		}
	}
}
