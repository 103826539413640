
import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, SimpleChanges} from '@angular/core';
import {has, pick, union} from 'lodash';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {Classes, MessagePopupButton} from '../../../../core/generic/message/message.interface';

import {MESSAGE_SIMPLE_TITLES, MESSAGE_TYPES} from '../../../../core/generic/message/message.constant';
import {selectClasses, selectImagePath} from '../../../../core/generic/message/message.selectors';
import {loadMessageProperties} from '../../../../core/generic/message/message.utility';
import {PropertyFactory} from '../../../../core/property/property.factory';
import {BASE_PATH} from '../../../../services/communication/data.config';

import {MessageBaseComponent} from '../message-base/message-base.component';
import {MessagePopupContentComponent} from '../message-popup-content/message-popup-content.component';

@Component({
	selector: 'krn-ng-message-popup',
	templateUrl: './message-popup.component.html'
})
export class MessagePopupComponent extends MessageBaseComponent implements OnDestroy, OnInit {
	@Input() public classes: Classes;
	@Input() public headerText: string;
	@Input() public messageText: string;
	@Input() public open = false;
	@Input() public primaryLabel: string;
	@Input() public secondaryLabel: string;
	@Input() public showPrimary: boolean;
	@Input() public showSecondary: boolean;

	@Output() public onMessageButton = new EventEmitter<MessagePopupButton>();
	@Output() public onMessageClosed = new EventEmitter();
	@Output() public openChange = new EventEmitter<boolean>();

	public msgPopup: BsModalRef;

	public messageTitles: {[key: string]: string} = MESSAGE_SIMPLE_TITLES;

	private properties: Promise<any>;
	private contentCallbacks = ['onClose', 'onPrimaryClicked', 'onSecondaryClicked'];
	private contentProperties = ['classes', 'iconClass', 'imagePath', 'messageType', 'showPrimary', 'showSecondary'];
	private contentText = ['headerText', 'messageText', 'primaryLabel', 'secondaryLabel'];

	constructor(
		private modalService: BsModalService,
		private propertyFactory: PropertyFactory,
		@Optional() @Inject(BASE_PATH) basePath: string
	) {
		super(basePath);

		this.showPrimary = true;
		this.showSecondary = false;
		this.messageType = MESSAGE_TYPES.INFO;
		this.classes = selectClasses();
		this.imagePath = selectImagePath();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		super.ngOnChanges(changes);

		if (has(changes, 'classes') || has(changes, 'messageType')) {
			this.classes = selectClasses(this.classes, this.messageType);
		}
		if (has(changes, 'open')) {
			this.onOpenChange();
		}
	}

	public ngOnDestroy(): void {
		this.closePopup();
	}

	public async ngOnInit(): Promise<any> {
		this.properties = await loadMessageProperties(this.propertyFactory);
	}

	public onClose = (): void => {
		this.close();
		this.onMessageClosed.emit();
	};

	public onPrimaryClicked = (): void => {
		this.close();
		this.onMessageButton.emit(MessagePopupButton.primary);
	};

	public onSecondaryClicked = (): void => {
		this.close();
		this.onMessageButton.emit(MessagePopupButton.secondary);
	};

	private close = (): void => {
		this.closePopup();
		this.open = false;
		this.openChange.emit(false);
	};

	private closePopup = (): void => {
		if (this.msgPopup) {
			this.msgPopup.hide();
			this.msgPopup = undefined;
		}
	};

	private onOpenChange(): void {
		if (this.open) {
			this.msgPopup = this.modalService.show(MessagePopupContentComponent, {
				ariaDescribedby: 'dialog-message',
				ariaLabelledBy: 'dialog-name',
				class: 'krn-modal-sm',
				ignoreBackdropClick: true,
				initialState: pick(this, union(this.contentProperties, this.contentCallbacks, this.contentText))
			});
		} else {
			this.closePopup();
		}
	}
}
