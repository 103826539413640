import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {PropertyFilterPipe} from '../../../providers/property/property.filter.pipe';
import {PropertyModule} from '../../../providers/property/property.module';
import {TooltipConfigProvider} from '../../../providers/tooltip-config.provider';
import {DynamicItemModule} from '../../../utils/dynamic-item/dynamic-item.module';
import {ReturnFocusElementModule} from '../../../utils/return-focus-element/return-focus-element.module';
import {LoaderModule} from '../../loader/loader.module';
import {IframeContainerModule} from '../iframe-container/iframe-container.module';
import {SlideoutContentModule} from '../slide-out/slide-out-content/slideout-content.module';
import {SlideOutModule} from '../slide-out/slide-out.module';
import {CustomIframeSlideoutComponent} from './custom-iframe-slideout/custom-iframe-slideout.component';
import {IframeSlideoutRoutingModule} from './iframe-slideout-routing.module';
import {IframeSlideoutComponent} from './iframe-slideout.component';

@NgModule({
	declarations: [CustomIframeSlideoutComponent, IframeSlideoutComponent],
	exports: [CustomIframeSlideoutComponent, IframeSlideoutComponent],
	imports: [
		CommonModule,
		DynamicItemModule,
		IframeSlideoutRoutingModule,
		LoaderModule,
		PropertyModule,
		ReturnFocusElementModule,
		SlideOutModule,
		SlideoutContentModule,
		IframeContainerModule,
		TooltipModule.forRoot()
	],
	providers: [PropertyFilterPipe, TooltipConfigProvider]
})
export class IframeSlideoutModule {}
