import {Directive, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {get, noop} from 'lodash';

import {PropertyFactory} from '../../../../../core/property/property.factory';
import {PropertyFilterPipe} from '../../../../../providers/property/property.filter.pipe';
import {SlideOutHelperService} from '../../../../../services/slide-out-helper.service';

export interface ActionItemOptions {
	autoFocus?: boolean;
	automationId?: string;
	id: string;
	uri: string;
	href: string;
	target: string;
	icon: string;
	text: string;
	title: string;
	tooltip: string;
	btnClasses: string;
	mainClasses: string;
	visible: boolean;
	enabled: boolean;
	prependTransclude: boolean;
	postpendTransclude: boolean;
	callback: () => void;
	onClick: (event: Event) => void;
	propertyParam: [];
	propertyFilePath: string;
	propertyFileName: string;
	sticky: boolean;
	ariaDesc: string;
	expanded?: boolean;
	role?: string;
	onFocus?: () => void;
	skipLocationChange?: boolean;
}

interface ModifyOptions {
	[key: string]: Partial<ActionItemOptions>;
}

@Directive()
export abstract class ActionItemBaseComponent {
	private static modifyOptions: ModifyOptions = {};

	@Input() public additionalData: object;

	public readonly DEFAULT: boolean;
	public context: any;
	public options: ActionItemOptions;
	public onFocus = noop;

	protected constructor(
		private actionItemOptions: Partial<ActionItemOptions>,
		public propertyFactory: PropertyFactory,
		public propertyFilterPipe: PropertyFilterPipe,
		public route: ActivatedRoute,
		public slideOutHelperService: SlideOutHelperService
	) {
		const defaultActionItemOptions: Readonly<ActionItemOptions> = {
			id: '',
			uri: '',
			href: '',
			target: '',
			icon: '',
			text: '',
			title: '',
			tooltip: '',
			mainClasses: '',
			btnClasses: 'btn',
			visible: true,
			enabled: true,
			prependTransclude: false,
			postpendTransclude: false,
			callback: noop,
			onClick: (event: Event) => this.onClick(event),
			propertyParam: [],
			propertyFilePath: 'components/zed-core/properties/navBar/',
			propertyFileName: 'container_web-common_nav-bar_strings.properties',
			sticky: false,
			ariaDesc: '',
			onFocus: () => this.onFocus()
		};

		this.options = {
			...defaultActionItemOptions,
			...actionItemOptions,
			...ActionItemBaseComponent.modifyOptions[get(this.constructor, 'actionItemName')]
		};
		if (this.options.propertyFileName && this.options.propertyFilePath) {
			this.propertyFactory.loadProperties([{name: this.options.propertyFileName, path: this.options.propertyFilePath}]).then(() => {
				this.options.text = propertyFilterPipe.transform(this.options.text);
				this.options.title = propertyFilterPipe.transform(this.options.title);
				this.options.tooltip = propertyFilterPipe.transform(this.options.tooltip);
			});
		}
	}

	public static modifyOptionsOnCreate(name: string, value: Partial<ActionItemOptions>): void {
		this.modifyOptions[name] = value;
	}

	public onClick(event: Event): void {
		this.slideOutHelperService.openSlider(
			[`${this.options.id}`],
			{returnFocusElement: this.returnFocusId()},
			this.context,
			get(this.options, 'skipLocationChange', true)
		);
	}

	public returnFocusId(): string {
		return `${this.options.id}_button`;
	}
}
