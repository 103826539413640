import {saveAs} from 'file-saver';
import * as jquery from 'jquery';
import {forEach, get, indexOf, lowerCase, noop, omit, trim} from 'lodash';

import {ApiService, Transport} from '../../../communication/api-service/api-service.interfaces';
import {CordovaPluginWindow} from '../../mobile/cordova-plugin-window.interface';
import {ServerFileSaving} from './server-file-saving.interface';

import {autoBom} from './bom.utility';

const $: any = jquery;

export class BrowserServerFileSavingCoreService implements ServerFileSaving {
	constructor(protected apiService: ApiService, protected window: CordovaPluginWindow) {}

	public saveFile = (fileData: any): object => {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const ContentType = fileData.headers()['content-type'];
		const blob = autoBom(fileData.data, ContentType);
		const contentDisposition = fileData.headers()['content-disposition'];
		const fileName = this.formatFileName(trim(contentDisposition.slice(indexOf(contentDisposition, '=') + 1), '"'));

		saveAs(blob, fileName);
		return {blob, fileName};
	};

	public downloadDataview = (exportURL: string, params: object): Promise<any> => {
		const method = lowerCase(get(params, 'method')) || 'post';
		const filterParams = omit(params, ['method']);
		const form = $('<form>', {
			action: exportURL,
			target: '_blank',
			method
		});

		forEach(filterParams, (param, key) => {
			form.append(
				$('<input>', {
					name: key,
					value: param,
					type: 'hidden'
				})
			);
		});

		return Promise.resolve(form.appendTo('body').trigger('submit'));
	};

	public downloadFile(exportURL: string, params: object, isDataViewDownload: boolean, callback: () => void = noop): Promise<any> {
		return new Promise((resolve, reject) => {
			if (isDataViewDownload) {
				this.downloadDataview(exportURL, params)
					.then(() => resolve(null))
					.then(callback);
			} else {
				const method = get(params, 'method') || 'post';
				const filterParams = omit(params, ['method']);

				if (lowerCase(method) === 'get') {
					this.apiService
						.get(exportURL, undefined, undefined, Transport.REST, true, undefined, true, {responseType: 'blob'})
						.then(response => resolve(this.saveFile(response)))
						.then(callback);
				} else {
					this.apiService
						.create(
							exportURL,
							jquery.param(filterParams) as any,
							undefined,
							Transport.REST,
							true,
							{'Content-Type': 'application/x-www-form-urlencoded'},
							true,
							{responseType: 'blob'}
						)
						.then(response => resolve(this.saveFile(response)))
						.then(callback);
				}
			}
		});
	}

	public formatFileName = (fileName: string): string => fileName;
}
