<krn-ng-single-select-picker-base
	[alignment]="alignment"
	[attrs]="baseAttrs"
	[autoResize]="autoResize"
	[classes]="classes"
	[data]="dataList"
	[dataLabel]="dataLabel"
	[isOpened]="isOpened"
	[label]="label"
	[selected]="selected"
	[showAsNative]="false"
	[tooltipContainerClass]="tooltipContainerClass"
	(selectedChange)="onSelectedChange($event)">
	<div (keydown.arrowDown)="onArrowDown()" class="popover-picker-trigger">
		<krn-ng-popover-button
			[renderType]="renderType"
			alignment="bottom left"
			buttonTextAlignment="left"
			class="h-100 single-select-picker-popover w-100"
			closeAriaLabel=" "
			icon="icon"
			openAriaLabel=" "
			[adaptivePosition]="adaptivePosition"
			[attrs]="popoverAttrs"
			[classes]="popoverClasses"
			[container]="'#' + popoverContainerId"
			[containerClass]="containerClass"
			[interactive]="true"
			[label]="label"
			[modalRole]="modalRole"
			(onHidden)="onHidden()"
			(onShown)="onShown()">
			<div class="popoverBody" #popoverBody tabindex="-1" (focus)="onFocus()">
				<div *ngIf="isOpened">
					<ng-container *ngTemplateOutlet="outerPopoverBodyTemplate || defaultPopoverBody"></ng-container>
				</div>
			</div>
		</krn-ng-popover-button>
	</div>
</krn-ng-single-select-picker-base>
<div [id]="popoverContainerId"></div>
<ng-template #defaultPopoverBody>
	<ul class="list" role="listbox" tabindex="-1">
		<li *ngFor="let item of data">
			<krn-ng-select-item
				selectType="single"
				krn-ng-focus-on-show
				[attrs]="{
					id: item.id,
					name: 'radioGroup',
					role: 'option',
					title: item[toolTipLabel] || item[dataLabel],
					'aria-checked': selected === item ? true : undefined,
					'aria-label': item[toolTipLabel] || item[dataLabel],
					'aria-selected': selected === item
				}"
				[container]="'#' + popoverContainerId"
				[isSelected]="isSelectedObject(item)"
				[skip-focus-if]="!isSelectedObject(item)"
				(singleSelectedChange)="onDefaultPopoverSelectedChange($event, item)"
				(onFocus)="onPopoverFocusChange(item)">
				<div
					class="select-item"
					[krn-ng-attributes]="{'automation-id': item[toolTipLabel] || item[dataLabel], 'aria-hidden': true}"
					[ngClass]="getItemClasses(popoverClasses)">
					{{ item[dataLabel] }}
				</div>
			</krn-ng-select-item>
		</li>
	</ul>
</ng-template>
