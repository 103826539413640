import {Transport} from '../../communication/api-service/api-service.interfaces';

import {Logger, NetworkStreamLogger} from './logger.network-stream';

export interface TelemetryRecord {
	app: string;
	area?: string;
	action?: string;
	values?: any;
}

export default class Telemetry {
	public log: Logger;
	private timers = [];

	constructor(private apiDataService: any, private window: Window, StreamLogger: NetworkStreamLogger) {
		this.log = StreamLogger.create('telemetry-logger', records => this.sendRecords(records));
	}

	public startTimer(name: string, time: number): void {
		this.timers[name] = time || this.window.performance.now();
	}

	public getTimer(name: string): number {
		if (!this.timers[name]) {
			return undefined;
		}

		const time = this.window.performance.now() - this.timers[name];

		this.timers[name] = undefined;
		return time;
	}

	private sendRecords(records: TelemetryRecord[]): Promise<any> {
		const body = {
			records
		};

		return this.apiDataService.create('/telemetry', body, undefined, Transport.REST, false);
	}
}
