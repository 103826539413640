import {camelCase, concat, findIndex, findKey, get, head, includes, isEmpty, isNumber, isUndefined, kebabCase, map, omitBy} from 'lodash';

import {ActionBarElement, ToolbarAlignment, ToolbarTypes} from '../actionToolBar/action-tool-bar.interfaces';
import {Property} from '../property/property.interface';
import {Tile, TileActionEnableState, TilebarData} from './tile.interfaces';
import {CardType} from '../cardLibrary/card.interfaces';

import {PropertyFilterConfig} from '../property/property.filter';
import {
	CHART_TYPE,
	DEF_ACTION_MENU_ALIGNMENT,
	HCA_CARD_TYPE,
	MANAGEEXCEPTIONSCARD_TYPE,
	MULTISERIESCHART_TYPE,
	MVCARD_TYPE_ACCRUAL,
	MVCARD_TYPE_DEFAULT,
	SUBTYPE_PATH,
	TILE_PROPERTIES,
	TYPE_CONFIG,
	TYPE_CONFIG_PATH,
	TYPE_DATA_PATH,
	TYPE_PATH
} from './tile.constants';
import {CURRENT_CONTEXT_KEY, SELECT_TILE_ID_KEY} from '../pageContext/page-context.constants';
import {SessionStorage} from '../platform/caching/sessionStorage.service';

const NO_ID = -1;

const enableMap = {
	[TileActionEnableState.AllEnabled]: true,
	[TileActionEnableState.AllDisabled]: false
};

export const getTileSessionStorageKey = (tileId: number): string => `Tile-${tileId}`;

export const getTileId = (tile: Tile): number => get(tile, 'attributes.id');

export const getCardSubType = (tile: Tile): string => {
	const cardType = get(tile, TYPE_PATH);

	if (cardType === CHART_TYPE) {
		return CHART_TYPE.toLowerCase();
	} else if (cardType === MULTISERIESCHART_TYPE) {
		return MULTISERIESCHART_TYPE.toLowerCase();
	}

	return get(tile, SUBTYPE_PATH);
};

export const getCardType = (tile: Tile): string => {
	if (tile && (get(tile, TYPE_PATH) === CardType.GENERIC || get(tile, TYPE_PATH) === CardType.CUSTOM)) {
		if (
			get(tile, TYPE_CONFIG) &&
			includes([CardType.MVABSENCE, CardType.MVOVERTIME, CardType.MVMYACCRUAL, CardType.MVANALYTIC], get(tile, TYPE_CONFIG_PATH))
		) {
			return get(tile, TYPE_CONFIG_PATH) === CardType.MVMYACCRUAL ? MVCARD_TYPE_ACCRUAL : MVCARD_TYPE_DEFAULT;
		} else if (get(tile, TYPE_CONFIG_PATH) === CardType.MVHCANALYTIC) {
			return HCA_CARD_TYPE;
		}
		return get(tile, TYPE_CONFIG_PATH) === CardType.MANAGE_EXCEPTIONS ? MANAGEEXCEPTIONSCARD_TYPE : get(tile, SUBTYPE_PATH);
	}
	return get(tile, TYPE_DATA_PATH);
};

const isVerticalToolbar = (element: ActionBarElement): boolean =>
	element.alignment === ToolbarAlignment.VERTICAL && element.type === ToolbarTypes.TOOLBAR;

export const getUniqueId = (label: string): string => camelCase(label).concat('Tile');

export const isActionElement = (element: ActionBarElement): boolean => !isEmpty(element.elements);

export const getTileActionElementId = (jsonModel: TilebarData): string => {
	const elements: ActionBarElement[] = get(jsonModel, 'tileBar.elements');
	const tileActionElement = elements.find(element => isActionElement(element));

	return tileActionElement ? tileActionElement.id : '';
};

export const getJsonModel = (
	jsonModel: TilebarData,
	tile: Tile,
	propertyFilter: PropertyFilterConfig,
	useTileActionId = false,
	showActionBtnOnly = true,
	overrideEnableState: TileActionEnableState = TileActionEnableState.NotApplicable
): object => {
	if (jsonModel) {
		let elements: ActionBarElement[] = get(jsonModel, 'tileBar.elements');
		const tileActionsIdx = findIndex(elements, element => isActionElement(element));

		if (showActionBtnOnly) {
			elements = [elements[tileActionsIdx]];
		}

		return {
			...jsonModel,
			tileBar: {
				...jsonModel.tileBar,
				elements: map(elements, (element: ActionBarElement, idx: number) => {
					const label = propertyFilter(element.label, [tile.attributes.label]) || element.label;
					let id = element.id + tile.attributes.id;

					if (idx === tileActionsIdx) {
						id = useTileActionId ? getUniqueId(tile.attributes.label).concat('Actions') + tile.attributes.id : element.id;
					}

					return omitBy(
						{
							toolbarAlignment: isVerticalToolbar(element) ? DEF_ACTION_MENU_ALIGNMENT : undefined,
							...element,
							enable:
								overrideEnableState === TileActionEnableState.NotApplicable
									? get(element, 'enable')
									: includes(['TileNavigationAdapter', 'TileNavigationCustomAdapter'], get(element, 'actionId'))
									? get(element, 'enable')
									: enableMap[overrideEnableState],

							label,
							tooltip: label,
							id,
							elements: map(element.elements, innerBtn => ({
								...innerBtn,
								id: innerBtn.id + tile.attributes.id
							}))
						},
						isUndefined
					);
				})
			}
		};
	}
	return null;
};

function getCallToAction(components: any[]): object {
	const targetComponentIndex = findKey(components, component => get(component, 'params.callToAction.id'));

	return targetComponentIndex ? get(components[targetComponentIndex], 'params.callToAction') : {};
}

const getPropertiesByCard = (cardSubType: string): Property => {
	const angularOnly = ['mvCard', 'healthcareProductivityCard'];
	const mvCards = ['mvCardDefault', 'mvCardAccrual'];
	const kebabCasePaths = [...angularOnly, 'activityFormsCard', 'customCard', 'oneClickTimeOff'];
	const pathPrefix = 'components/zed-core/properties/cardLibrary';
	let subType = camelCase(cardSubType);

	if (includes(mvCards, subType)) {
		subType = 'mvCard';
	}
	if (includes(kebabCasePaths, subType)) {
		subType = kebabCase(subType);
	}

	return {
		name: `container_web-common_${subType}_strings.properties`,
		path: `${pathPrefix}/cards/${subType}/`
	};
};

export const getTileProperties = (tile: Tile): Property[] => {
	const cardType = getCardType(tile);
	const tileType = camelCase(cardType);

	return tileType && tileType !== camelCase(MULTISERIESCHART_TYPE) ? concat(TILE_PROPERTIES, getPropertiesByCard(cardType)) : TILE_PROPERTIES;
};

export function getUri(tile: Tile): string {
	const callToAction = getCallToAction(get(tile, 'card[0].configuration.components'));
	const dataViewId = get(callToAction, 'id') || get(tile, 'card[0].data.dataViewId');

	return head(tile.card).id > NO_ID && isNumber(dataViewId) ? `/ia#/dataView/${dataViewId}` : undefined;
}

export const isTileOpened = (sessionStorage: SessionStorage): boolean =>
	!!get(JSON.parse(sessionStorage.get(CURRENT_CONTEXT_KEY) || '{}'), SELECT_TILE_ID_KEY);

export const getCurrentTile = (sessionStorage: SessionStorage): Tile => {
	const selectedTileId = get(JSON.parse(sessionStorage.get(CURRENT_CONTEXT_KEY) || '{}'), SELECT_TILE_ID_KEY);

	return selectedTileId ? sessionStorage.get(getTileSessionStorageKey(selectedTileId)) : undefined;
};
