<div class="navmenu-profile" id="{{ config.id }}" [hidden]="!config.visible">
	<label class="user-profile-area btn navmenu-info" *ngIf="userInfo as user">
		<krn-ng-button
			classes="btn-focus-on-relative"
			alignment="left"
			btnType="button"
			[icon]="caretStatus"
			automation-id="settingsMenuButton"
			id="{{ config.settings.id }}_button"
			[attrs]="{
				'aria-expanded': showSettingsMenu,
				'aria-hidden': !caretStatus,
				disabled: !caretStatus,
				title: config.settings.tooltip
			}"
			(buttonClicked)="config.settings.callback()">
		</krn-ng-button>
		<div class="navmenu-user">
			<div class="employeeUserName" id="{{ user.personId }}_EmployeeName">
				{{'html.userprofile.employeeName' | property:[user.firstName, user.lastName]}}
			</div>
			<span
				*ngIf="user.impersonate?.impersonatedBy && !isMultipleEmploymentsMode(user.impersonate)"
				id="{{ user.impersonate?.impersonatedBy }}_activeImpersonation"
				class="job one-line"
				[tooltip]="'html.navBar.userImpersonation.impersonatedBy.prefix' | property: [user.impersonate?.impersonatedBy]">
				{{ 'html.navBar.userImpersonation.impersonatedBy.prefix' | property: [user.impersonate?.impersonatedBy] }}
			</span>
			<span
				*ngIf="user.delegate && user.delegate.myRoles.length > 1"
				id="{{ user.personId }}_managerRole"
				class="job"
				[ngClass]="{'one-line': !!user.delegate.activeRole.delegatorId}"
				[tooltip]="user.delegate.activeRole.name">
				{{ user.delegate.activeRole.name }}
			</span>
			<span
				*ngIf="user.delegate && user.delegate.delegateActiveRole.delegatorId"
				id="{{ user.personId }}_delegator"
				class="job one-line"
				[tooltip]="'html.navBar.profileMenu.role.delegator' | property: [user.delegate.delegateActiveRole.delegator]">
				{{ 'html.navBar.profileMenu.role.delegator' | property: [user.delegate.delegateActiveRole.delegator] }}
			</span>
			<span
				*ngIf="isMultipleEmploymentsMode(user.impersonate)"
				id="{{ (activeEmployment?.employmentName ? activeEmployment?.employmentName : activeEmployment?.userName) }}_activeEmployment"
				class="job one-line"
				[tooltip]="(activeEmployment?.employmentName ? activeEmployment?.employmentName : activeEmployment?.userName)">
				{{ (activeEmployment?.employmentName ? activeEmployment?.employmentName : activeEmployment?.userName) }}
			</span>
			<div *ngIf="(additionalData$ | async)?.wfr && (additionalData$ | async)?.wfr.eins.length > 1" class="user-split-line"></div>
			<krn-ng-menu-integration-selection
				*ngIf="additionalData$ | async"
				[menuAdditionalData]="additionalData$ | async"></krn-ng-menu-integration-selection>
		</div>
		<span class="employeeInitial initialSmall" tabindex="-1" aria-hidden="true">
			<krn-ng-avatar
				[imageWidth]="4"
				[imageHeight]="4"
				[photoId]="user.photoId"
				[initials]="user.initials"
				photoName="{{ user.firstName }} {{ user.lastName }}"
				class="{{ user.classes }}"
				automation-id="employeePhotoMenuImage"
				id="employeeProfileImage_profileMenu_{{ user.photoId }}">
			</krn-ng-avatar>
		</span>
	</label>
	<div #settingsMenu class="settings-menu settings-menu-scroll-bar" tabindex="-1" [hidden]="!showSettingsMenu">
		<div *ngIf="userInfo as user">
			<krn-ng-menu-integration *ngIf="additionalData$ | async" [menu-additional-data]="additionalData$ | async" [menu-item-total]="menuItemLength">
			</krn-ng-menu-integration>
			<krn-ng-manager-role-list
				class="menuOption"
				*ngIf="user.delegate && (user.delegate.myRoles.length > 1 || user.delegate.switchRoleDelegates.length)"
				[active-role]="user.delegate.activeRole"
				[delegate-active-role]="user.delegate.delegateActiveRole"
				[roles]="user.delegate.myRoles"
				[menu-item-pos]="isImpersonationAllowed(user.impersonate) ? menuItemLength - 1 : menuItemLength"
				[menu-item-total]="menuItemLength"
				[delegations]="user.delegate.switchRoleDelegates">
			</krn-ng-manager-role-list>
			<krn-ng-user-impersonation
				*ngIf="isImpersonationAllowed(user.impersonate)"
				[menuItemTotal]="menuItemLength"
				[impersonationDetails]="user?.impersonate">
			</krn-ng-user-impersonation>
			<krn-ng-multiple-employements
				*ngIf="isMultipleEmploymentsMode(user.impersonate)"
				[menuItemTotal]="menuItemLength"
				[impersonationDetails]="user?.impersonate">
			</krn-ng-multiple-employements>
		</div>
	</div>
	<div class="navmenu-action">
		<krn-ng-button
			[attrs]="{
				'aria-label': config.changePassword?.text,
				'automation-id': 'editProfileMenuLink',
				title: config.changePassword?.text
			}"
			classes="btn-ngEditProfile"
			id="{{ config.changePassword?.id }}_link"
			icon="icon-k-edit"
			[buttonText]="config.changePassword?.text"
			btnType="anchor"
			(buttonClicked)="goToCurrentPage(config.changePassword.href)"
			alignment="left">
		</krn-ng-button>
		<krn-ng-button
			[attrs]="{
				'aria-label': config.signOut?.text,
				'automation-id': 'signOutMenuButton',
				title: config.signOut?.text
			}"
			classes="btn-ngSignout"
			id="signOutBtn"
			icon="icon-k-global-logout"
			[buttonText]="config.signOut?.text"
			(buttonClicked)="goToCurrentPage(config.signOut.uri)"
			alignment="left">
		</krn-ng-button>
	</div>
	<div class="navmenu-offline ng-hide">
		<button
			automation-id="offlineButton"
			[attr.id]="config.offlineButton.id + '_button'"
			[ngClass]="config.offlineButton.classes"
			[tooltip]="config.offlineButton?.tooltip"
			(click)="config.offlineButton.callback()">
			<span [attr.id]="config.offlineButton.id + '_text'">{{
				'html.navbar.profileMenu.offlineButton' | property
			}}</span>
		</button>
	</div>
</div>
