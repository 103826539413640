import {forEach, keys} from 'lodash';
import {handleActions} from 'redux-actions';

import {PropertyState} from './property.interface';

export const PROPERTY_DEFAULT_STATE: PropertyState = {
	loadedFiles: [],
	properties: {},
	restPropertyService: true
};

export const reducerHelper = (): Partial<any> => ({
	LOAD_FILE: (state, action) => {
		const actionProperties = action.properties ? action.properties : action.payload.properties;
		const loadedFiles = [...state.loadedFiles, ...[action.fileName ? action.fileName : action.payload.fileName]];
		const properties = {...state.properties};

		forEach(keys(actionProperties), key => {
			properties[key] = actionProperties[key];
		});

		return {
			...state,
			properties,
			loadedFiles
		};
	},
	CLEAR_PROPERTY_FILES: (state): any => ({
			...state,
			loadedFiles: PROPERTY_DEFAULT_STATE.loadedFiles,
			properties: PROPERTY_DEFAULT_STATE.properties
	})
});

export const propertyReducer = handleActions(
	reducerHelper(),
	PROPERTY_DEFAULT_STATE
);
