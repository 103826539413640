import * as jQuery from 'jquery';
import {noop} from 'lodash';

import {KeyNavService} from './keyboard-navigation.interfaces';

import {keys} from '../../constants/keys.constant';
import {EVENTS_HANDLERS} from '../utils.constants';

const $: any = jQuery;

export class EscNavigationService implements KeyNavService {
	private KEYDOWN_HANDLERS;

	constructor(private focusableElements: HTMLElement[], private container: HTMLElement, private closeCallback) {
		this.KEYDOWN_HANDLERS = {
			[keys.ESCAPE]: this.onClose,
			[keys.ESC]: this.onClose
		};
	}

	public initialize(): void {
		$(this.container).on(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler);
	}

	public destroy(): void {
		$(this.container).off(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler);
	}

	private keyDownHandler = (event: KeyboardEvent): void => {
		(this.KEYDOWN_HANDLERS[event.key] || noop)(event);
	};

	private onClose = (event: KeyboardEvent): void => {
		event.preventDefault();
		event.stopPropagation();

		if (this.closeCallback) {
			// eslint-disable-next-line no-unused-expressions
			this.closeCallback.emit ? this.closeCallback.emit() : this.closeCallback();
		}
	};
}
