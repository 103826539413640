import {Injectable} from '@angular/core';
import moment from 'moment';
import 'moment-jdateformatparser';
import {NGXLogger} from 'ngx-logger';

import {PreferencesCoreService} from '../../core/platform/preferences/preferences.service';
import {ConverterUtilCoreService} from '../../core/platform/converters/converter-util-core.service';

@Injectable({
	providedIn: 'root'
})
export class ConverterUtilService extends ConverterUtilCoreService {
	//preferencesService is used instead preferences.getPreferences() because ConverterUtilService is injected during app init, and preferences are not loaded by that time yet
	constructor(logger: NGXLogger, preferencesService: PreferencesCoreService) {
		super(logger, preferencesService, moment);
	}
}
