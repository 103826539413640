<div class="modal-popup"
		 krn-ng-modal-keyboard-navigation
		 [ngClass]="classes">
  <div class="align-items-center d-flex modal-header">
    <span aria-hidden="true"
          class="icon-stack">
      <img *ngIf="imagePath"
           [src]="imagePath"
           class="image-only">
      <i *ngIf="iconClass"
         class="icon-only"
         [ngClass]="iconClass">
      </i>
    </span>
    <h4 id="dialog-name"
        class="flex-grow-1 modal-title">{{headerText || (messageTitles[messageType] | property)}}
    </h4>
    <button type="button"
            class="btn close m-0 p-0"
            (click)="onClose()"
            #closeMsgButton
            krn-ng-focus-on-show
            [attr.aria-label]="'html.commonInfra.platform.errorframework.errormodal.close' | property">
      <i aria-hidden="true"
         class="icon-k-close">
      </i>
      <span class="sr-only">{{'html.commonInfra.platform.errorframework.errormodal.close' | property}}</span>
    </button>
  </div>
  <div id="dialog-message"
       class="modal-body">
    <span class="message-text">{{messageText}}</span>
  </div>
  <div class="border-0 d-flex justify-content-end modal-footer">
    <span *ngIf="showSecondary"
          class="btn-secondary-container">
      <krn-ng-button [classes]="['btn-secondary', 'd-flex', 'h-100', 'justify-content-center', 'w-100']"
                     [buttonText]="secondaryLabel || ('html.commonInfra.platform.errorframework.errormodal.cancel' | property)"
                     (buttonClicked)="onSecondaryClicked()">
      </krn-ng-button>
    </span>
    <span *ngIf="showPrimary"
          class="btn-primary-container">
      <krn-ng-button [classes]="['btn-primary', 'd-flex', 'h-100', 'justify-content-center', 'w-100']"
                     [buttonText]="primaryLabel || ('html.commonInfra.platform.errorframework.errormodal.ok' | property)"
                     (buttonClicked)="onPrimaryClicked()">
      </krn-ng-button>
    </span>
  </div>
</div>
