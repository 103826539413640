import {forEach} from 'lodash';
import {Subscription, fromEventPattern, throttleTime} from 'rxjs';
import {NodeEventHandler} from 'rxjs/internal/observable/fromEvent';

import {DEFAULT_ACTIVITY_EVENTS, THROTTLE_TIME} from '../authStack/auth0-stack.constant';

export type Callback = (value: any) => void;

export class ActivityDetectorCoreService {
	private subscription: Subscription;
	private _callback: Callback;

	constructor(protected document: Document) {}

	public get callback(): Callback {
		return this._callback;
	}

	public set callback(callback: Callback) {
		this._callback = callback;
	}

	public isEventRegistered(): boolean {
		return this.subscription !== null;
	}

	public registerEvents(): void {
		this.unregisterEvents();

		const addActivityHandler = (handler: NodeEventHandler): void => {
			const iframes = this.document.querySelectorAll('iframe') as NodeListOf<HTMLIFrameElement>;

			forEach(DEFAULT_ACTIVITY_EVENTS, (event: string) => {
				this.document.addEventListener(event, handler);
				forEach(iframes, iframe => iframe.contentWindow.addEventListener(event, handler));
			});
		};
		const removeActivityHandler = (handler: NodeEventHandler): void => {
			const iframes = this.document.querySelectorAll('iframe') as NodeListOf<HTMLIFrameElement>;

			forEach(DEFAULT_ACTIVITY_EVENTS, (event: string) => {
				this.document.removeEventListener(event, handler);
				forEach(iframes, iframe => iframe.contentWindow.removeEventListener(event, handler));
			});
		};

		this.subscription = fromEventPattern(addActivityHandler, removeActivityHandler).pipe(throttleTime(THROTTLE_TIME)).subscribe(this.callback);
	}

	public unregisterEvents(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}
}
