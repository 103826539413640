import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IgniteModule} from '@ignite/angular';

import {PropertyModule} from '../../../providers/property/property.module';
import {ScreenReaderOnlyModule} from '../../../utils/screen-reader-only/screen-reader-only.module';
import {Auth0SessionTimeoutComponent} from './auth0-session-timeout.component';
import {SessionTimeoutComponent} from './session-timeout.component';
import {SessionUnavailableComponent} from './session-unavailable/session-unavailable.component';

@NgModule({
	declarations: [SessionTimeoutComponent, SessionUnavailableComponent, Auth0SessionTimeoutComponent],
	exports: [SessionTimeoutComponent, SessionUnavailableComponent, Auth0SessionTimeoutComponent],
	imports: [CommonModule, IgniteModule, PropertyModule, ScreenReaderOnlyModule]
})
export class SessionTimeoutModule {}
