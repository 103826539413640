import {GenericBaseItem, GenericMenuItem} from '../navBar/nav-bar-menu.interfaces';

export enum EmbeddedMessageSource {
	UKGPRO = 'ukgpro',
	DIMENSIONS = 'dimensions'
}

export enum EmbeddedIframeActions {
	HANDLER_READY = 'handlerReady',
	LOGIN = 'login',
	LOGOUT = 'logout',
	SESSION_CHECK = 'sessionCheck',
	KEEP_ALIVE = 'keepAlive',
	API_CALLS = 'apiCalls',
	INIT_SESSION = 'initSession',

	GET_MENU_ITEMS = 'getMenuItems',
	GET_ALL_MENU_ITEMS = 'getAllMenuItems',
	SET_TITLE = 'setTitle',

	LOG = 'log',
	NAVIGATE = 'navigate',
	NAVIGATE_FOFWARD = 'navigateForward',
	NAVIGATED_FOFWARD = 'navigatedForward',
	NAVIGATED_BACK = 'navigatedBack',
	NAVIGATION_REQUEST = 'navigationRequest',
	EXPORT_FILE = 'exportFile',

	ON_BLUR = 'onBlur',
	ON_BACK = 'onBack',
	ON_UNLOAD = 'onUnload',

	GET_GEO_COORDINATES = 'getGeoCoordinates',
	WIFI_INFORMATION = 'getWifiInformation',
	CHALLENGE_BIOMETRICS = 'challengeBiometrics',
	CHALLENGE_LOCAL_AUTH = 'challengeLocalAuth',
	OPEN_NEW_TAB = 'openNewTab',
	LAUNCH_NATIVE_MAP = 'launchNativeMap',
	LAUNCH_UNIVERSAL_LINK = 'launchUniversalLink',
	SCAN = 'scan'
}

export interface EmbeddedPostMessage {
	requestId?: string | number;
	source?: EmbeddedMessageSource;
	action: string;
	accessToken?: string;
	embeddable?: boolean;
	idToken?: string;
	page?: string;
	title?: string;
	url?: string;
	result?: any;
	capabilities?: Capability[];
	target?: string;
	options?: string;
	data?: object;
	apiPayload?: ApiCallsPayload;
	level?: string;
	message?: string;
}

export interface Capability {
	name: string;
}

export interface LegacyEmbeddedMenuItem {
	id: string;
	name: string;
	url: string;
}

interface EmbeddedBaseItem {
	symbolicId: string;
}

export interface EmbeddedMenuCategory extends GenericBaseItem<EmbeddedBaseItem> {
	uri?: string;
	menu?: EmbeddedMenuItem[];
}

export interface EmbeddedMenuItem extends GenericMenuItem<EmbeddedBaseItem> {
	uri: string;
}

export enum EmbeddedLocalAuthType {
	BIO_AUTH = 'biometric',
	ANY_AUTH = 'any'
}

export interface NavigationResponse {
	cancel: boolean;
}

export enum EmbeddedMethodType {
	GET = 'GET',
	POST = 'POST'
}

export interface ApiCallsPayload {
	endpoint: string;
	method: EmbeddedMethodType;
	bodyData?: string;
	headers?: object;
}

export interface ApiCallsResponse {
	status: boolean;
	payload?: any;
}

interface MobileDevice {
	description: {
		appVersion: string;
	};
	permissions: {
		location: boolean;
		push: boolean;
		scan: boolean;
	};
}

export interface DeviceInfoResponse {
	appInstanceId: string;
	mobileAppName: string;
	mobileDevice: MobileDevice;
	mobileDeviceType: string;
	personId: string;
}

export interface EmbeddedPostMessageServiceInterface {
	initializeMessageListener: (callback: (action: string, url: string, data?: any) => Promise<any>) => void;
}

export interface EmbeddedUnsavedDataServiceInterface {
	verifyUnsavedData: (action: string, url?: string) => Promise<boolean>;
	destroySubscription: () => void;
}

// dimension's log level maps to OneApp's log level (see https://engconf.int.kronos.com/display/UKGSE/OneAppLoggingLevels)
export enum dimensionMapToOneAppLogLevel {
	trace = 'verbose',
	debug = 'debug',
	info = 'info',
	log = 'info',
	warn = 'warn',
	error = 'error',
	fatal = 'error',
	off = 'off'
}
