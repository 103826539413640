import {isFunction} from 'lodash';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {getTopWfdWindow} from '../../iframe-framework/iframe-message-handler.utility';
import {Breakpoints} from '../constants/breakpoints';
import * as deviceConfigUtility from './DeviceConfig.utility';

export class ZoomCoreService {
	public deviceConfigUtility = deviceConfigUtility;
	public MIMIC_MOBILE_FLAG = 'showMobileUIOnDesktop';
	private topWindow: Window = null;

	constructor(private window) {
		this.topWindow = getTopWfdWindow(this.window);
		if (this.isValidTopWindow(this.topWindow) && !deviceConfigUtility.isRealMobileDevice(this.topWindow)) {
			this.updateMobileUISessionFlag(this.isDesktopAndHitBreakpoint());
			this.addZoomListener();
		}
		if (!deviceConfigUtility.isRealMobileDevice(this.window)) {
			this.updateShowMobileUICss(this.isMimicMobileUI(), this.window, this.topWindow);
		}
	}

	public isMimicMobileUI = (): boolean => this.isDesktopAndHitBreakpoint() && this.hasMobileUISessionFlag();

	public isHitZoomBreakpoint = (): boolean => this.topWindow && this.topWindow.innerWidth < Breakpoints.SMALL.MAX;

	private getDevicePixelRatio = (userAgent: string): number => {
		let ratio: number;

		if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
			const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

			document.body.appendChild(svg);
			ratio = svg.currentScale;
			document.body.removeChild(svg);
		} else {
			ratio = this.window.devicePixelRatio;
		}
		return ratio;
	};

	private addZoomListener = (): void => {
		const lastWindowStatus = {
			innerWidth: this.window.innerWidth,
			innerHeight: this.window.innerHeight,
			devicePixelRatio: this.getDevicePixelRatio(navigator.userAgent)
		};
		const DEBOUNCE_TIME = 500;

		fromEvent(this.topWindow, 'resize')
			.pipe(debounceTime(DEBOUNCE_TIME))
			.subscribe(() => {
				if (this.hasMobileUISessionFlag() !== this.isDesktopAndHitBreakpoint() && this.isZooming(lastWindowStatus)) {
					this.topWindow.location.reload();
				} else {
					this.updateShowMobileUICss(this.isMimicMobileUI(), this.window, this.topWindow);
				}
				this.recordLastWindowStatus(lastWindowStatus);
			});
	};

	private isValidTopWindow = (topWindow): boolean =>
		topWindow &&
		this.window === topWindow &&
		this.window.navigator &&
		isFunction(this.window.addEventListener) &&
		isFunction(this.window.removeEventListener);

	private isDesktopAndHitBreakpoint = (): boolean => !deviceConfigUtility.isRealMobileDevice(this.topWindow) && this.isHitZoomBreakpoint();

	private updateShowMobileUICss = (showMobileUIOnDesktop: boolean, currentWindow, topWindow): void => {
		const html = document.querySelector('html');
		let flagClassName = '';

		flagClassName = currentWindow === topWindow ? 'show-mobile-ui' : 'show-mobile-ui-iframe';

		if (showMobileUIOnDesktop && !html.className.includes(flagClassName)) {
			html.className += ` ${flagClassName}`;
		} else if (!showMobileUIOnDesktop && html.className.includes(flagClassName)) {
			html.className = html.className.replace(flagClassName, '');
		}
	};

	private isValidSessionStorageOnTopWin = (): boolean =>
		this.topWindow &&
		this.topWindow.sessionStorage &&
		isFunction(this.topWindow.sessionStorage.getItem) &&
		isFunction(this.topWindow.sessionStorage.removeItem);

	private hasMobileUISessionFlag = (): boolean => {
		if (this.isValidSessionStorageOnTopWin()) {
			return this.topWindow.sessionStorage.getItem(this.MIMIC_MOBILE_FLAG) === this.MIMIC_MOBILE_FLAG;
		}
		return false;
	};

	private updateMobileUISessionFlag = (showMobileUIOnDesktop: boolean): void => {
		if (this.isValidSessionStorageOnTopWin()) {
			if (showMobileUIOnDesktop) {
				this.topWindow.sessionStorage.setItem(this.MIMIC_MOBILE_FLAG, this.MIMIC_MOBILE_FLAG);
			} else {
				this.topWindow.sessionStorage.removeItem(this.MIMIC_MOBILE_FLAG);
			}
		}
	};

	private isZooming = (lastWindowStatus): boolean => {
		const noZoomRatio = 1;
		const maxOffset = 0.02;
		const zoomRatio = this.getDevicePixelRatio(navigator.userAgent) / lastWindowStatus.devicePixelRatio;
		const widthZoomRatioOffset = Math.abs((lastWindowStatus.innerWidth / this.window.innerWidth - zoomRatio) / zoomRatio);
		const heightZoomRatioOffset = Math.abs((lastWindowStatus.innerHeight / this.window.innerHeight - zoomRatio) / zoomRatio);

		return zoomRatio !== noZoomRatio && widthZoomRatioOffset < maxOffset && heightZoomRatioOffset < maxOffset;
	};

	private recordLastWindowStatus = (lastWindowStatus): void => {
		lastWindowStatus.innerWidth = this.window.innerWidth;
		lastWindowStatus.innerHeight = this.window.innerHeight;
		lastWindowStatus.devicePixelRatio = this.getDevicePixelRatio(navigator.userAgent);
	};
}
