import {Pipe, PipeTransform} from '@angular/core';

import {getFilterTotalMenuItemCount} from '../../../../../core/navBar/accessibility-helper.utility';
import {MenuItemConfig} from '../../../../../core/navBar/models/menu-item-config.model';

@Pipe({
	name: 'menuItemTotalFilterCount'
})
export class MenuItemTotalFilterCountPipe implements PipeTransform {
	public transform(items: MenuItemConfig[] = []): number {
		return getFilterTotalMenuItemCount(items);
	}
}
