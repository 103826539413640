<button type="button" (role)="role"
    (focus)="handleButtonFocused($event)"
    (blur)="handleButtonBlurred($event)"
    (click)="handleButtonClicked($event)"
    (mousedown)="pop.hide()"
    (mouseenter)="handleButtonEntered($event)"
    (mouseleave)="handleButtonLeft($event)"
    class="btn"
    [krn-ng-attributes]="{
        'id': attrs?.id,
        'aria-label': attrs['aria-label'],
        'aria-labelledby': attrs['aria-labelledby'],
        'aria-describedby': attrs['aria-describedby'],
        'aria-haspopup': attrs['aria-haspopup'],
        'aria-controls': attrs['aria-controls'],
        'aria-expanded': attrs['aria-expanded'],
        'aria-posinset': attrs['aria-posinset'],
        'aria-selected': attrs['aria-selected'],
        'aria-disabled': attrs['aria-disabled'],
        'aria-setsize': attrs['aria-setsize'],
        'aria-required': attrs['aria-required'],
        'aria-hidden': attrs['aria-hidden'],
        'automation-id': attrs['automation-id'],
        'disabled': (attrs?.disabled || undefined),
        'role': attrs?.role || role,
        'tabindex': attrs?.tabindex,
        'type': attrs?.type || 'button'
    }"
    [ngClass]="btnClasses"
    #btnId>

    <div class="d-flex" [ngClass]="layout" [class.justify-content-center]="!buttonText" #pop="bs-tooltip" [delay]="tooltipDelay" [tooltip]="attrs?.title" containerClass="mt-2 krn-default-tooltip">
        <div *ngIf="!buttonText && !attrs['aria-label']" class="sr-only">{{attrs?.title}}</div>
        <div *ngIf="icon" aria-hidden="true" class="btn-icon" [class.w-100]="!buttonText">
            <i [ngClass]="icon"></i>
        </div>
        <div *ngIf="image && !icon" aria-hidden="true" class="btn-img">
            <img [src]="image" [alt]="attrs?.title || attrs['aria-label'] || buttonText" class="w-100 h-100">
        </div>
        <div *ngIf="buttonText"	class="btn-text" [ngClass]="{'btn-label-icon' : icon || image, 'btn-label-normal' : !icon && !image}">{{buttonText}}</div>
    </div>
</button>
