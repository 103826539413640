import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {KrnMessageDetailsModel} from '../../../core/generic/message/message.interface';

import {PreferencesCoreService} from '../../../core/platform/preferences/preferences.service';
import {PropertyFilterPipe} from '../../../providers/property/property.filter.pipe';
import {PersistentBannerService} from './persistent-banner.service';

@Component({
	selector: 'krn-ng-persistent-banner',
	templateUrl: './persistent-banner.component.html',
	providers: [PropertyFilterPipe]
})
export class PersistentBannerComponent implements OnInit, OnDestroy {
	public persistentBannerMessage: KrnMessageDetailsModel;
	public showPersistentBanner = false;

	private persistentBannerMessageSubscription: Subscription;
	private persistentBannerHideSubscription: Subscription;

	constructor(protected preferencesService: PreferencesCoreService, private persistentBannerService: PersistentBannerService) {}

	public ngOnInit(): void {
		this.subscriptionEventInit();
	}

	public ngOnDestroy(): void {
		this.persistentBannerHideSubscription.unsubscribe();
		this.persistentBannerMessageSubscription.unsubscribe();
	}

	public onCloseMessage(): void {
		this.showPersistentBanner = false;
	}

	private subscriptionEventInit(): void {
		this.persistentBannerMessageSubscription = this.persistentBannerService
			.persistentBannerMessageSource$()
			.subscribe((message: KrnMessageDetailsModel) => {
				this.persistentBannerMessage = message;
				this.showPersistentBanner = true;
			});
		this.persistentBannerHideSubscription = this.persistentBannerService
			.persistentBannerHideSource$()
			.subscribe(() => (this.showPersistentBanner = false));
	}
}
