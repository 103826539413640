import {get, head, indexOf, isFunction, lowerCase, noop, omit, trim} from 'lodash';
import * as jquery from 'jquery';

import {ExportParams, ResponseOption} from './server-file-saving.interface';
import {ApiService, Transport} from '../../../communication/api-service/api-service.interfaces';
import {CordovaPluginWindow} from '../../mobile/cordova-plugin-window.interface';
import {LogServiceInterface} from '../logService/log.interface';

import {MobileServerFileSavingCoreService} from './mobile-server-file-saving.core.service';
import DeviceConfigCoreService from '../DeviceConfigServices';

export class OneAppServerFileSavingCoreService extends MobileServerFileSavingCoreService {
	constructor(
		protected apiService: ApiService,
		protected window: CordovaPluginWindow,
		protected deviceConfigService: DeviceConfigCoreService,
		protected logger: LogServiceInterface) {
		super(apiService, window);
	}

	public downloadFile(url: string, params: ExportParams, isDataViewDownload = false, callback: () => void = noop): Promise<any> {
		const method = get(params, 'method') || 'post';
		const filterParams = omit(params, ['method']);
		const options = {
			observe: 'response',
			responseType: 'blob',
			withCredentials: true
		};

		if (lowerCase(method) === 'get') {
			return this.apiService
				.get(url, undefined, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}, Transport.REST, true, null, true, options)
				.then(this.getOnResponseBackFn(callback));
		}
		return this.apiService
			.create(
				url,
				jquery.param(filterParams) as any,
				{headers: {'Content-Type': 'application/x-www-form-urlencoded'}},
				Transport.REST,
				true,
				{'Content-Type': 'application/x-www-form-urlencoded'},
				true,
				options
			)
			.then(this.getOnResponseBackFn(callback));
	}

	public saveFile = (options, successCb?, errorCb?): any => {
		const finalSuccessCb = successCb || noop;
		const errorFn = errorCb || noop;

		(this.window as any).resolveLocalFileSystemURL(options.path, this.getOnResolveLocalFileSystemURLFn(options, finalSuccessCb, errorFn), errorFn);
	};

	private getOnResponseBackFn = callback => response => {
		const isIOS = this.deviceConfigService.isIOSDevice();
		// exported file is saved to /tmp directory on iOS and to the public Download folder on Android,
		// so that Android user could use it, e.g. to send it in an e-mail
		const exportFilePath = isIOS ? this.window.cordova.file.tempDirectory : `${this.window.cordova.file.externalRootDirectory}Download/`;
		const {fileName, fileType, data} = this.getFileOption(response);

		this.saveFile({
			path: exportFilePath,
			fileName,
			fileType,
			data
		}, this.getOnSaveFileFn(fileType, callback), this.exportErrorCallback);
	};

	private getOnSaveFileFn(fileType, callback) {
		return pageContentUrl => {
			if (fileType.indexOf('xlsx') > -1) {
				fileType = 'application/vnd.ms-excel';
			}
			this.window.cordova.plugins.fileOpener2.showOpenWithDialog(decodeURI(pageContentUrl), fileType, {
				error: this.exportErrorCallback,
				success: callback
			});
		};
	}

	private exportErrorCallback(e): void {
		this.logger.error('An error occurred during exporting the content.', e);
	}

	private getFileOption(response): ResponseOption {
		const isIOS = this.deviceConfigService.isIOSDevice();
		let headers;
		let fileType = '';
		let fileName = '';
		let data: any = '';

		if (response.headers && response.headers.lazyInit) {
			response.headers.lazyInit();
			headers = response.headers.headers;
			fileType = head(headers.get('content-type'));
			fileName = this.getFileName(head(headers.get('content-disposition')));
			data = response.body;
		} else if (response.headers && isFunction(response.headers)) {
			headers = response.headers();
			fileType = get(headers,'content-type');
			fileName = this.getFileName(headers['content-disposition']);
			data = response.data;
		}
		//For now on Android only since iOS seems to work with octet-stream
		if (fileType.indexOf('octet-stream') > -1 && !isIOS) {
			fileType = '';
		}
		return {fileName, fileType, data};
	}

	private getFileName = (contentDisposition: string): string => {
		let fileName = decodeURI(trim(contentDisposition.slice(indexOf(contentDisposition, '=') + 1), '"'));

		fileName = fileName.replace('/Report Output/', '');
		fileName = fileName.replace(/[/:?\s]/g, '-');
		return fileName;
	};

	private getOnResolveLocalFileSystemURLFn = (options, finalSuccessCb, errorFn) => dirEntry => {
		dirEntry.getFile(
			options.fileName,
			{
				create: true,
				exclusive: false
			},
			fileEntry => {
				this.writeFile(fileEntry, options.data || '', options.fileType, finalSuccessCb, errorFn);
			},
			errorFn
		);
	};

	private writeFile = (fileEntry, dataObj, fileType, finalSuccessCb, errorFn): void => {
		fileEntry.createWriter(fileWriter => {
			fileWriter.onwriteend = () => {
				finalSuccessCb(fileEntry.nativeURL);
			};
			fileWriter.onerror = errorFn;
			fileWriter.write(dataObj);
		});
	};

}
