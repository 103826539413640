import {join, noop, omit, reduce} from 'lodash';

import {ExportParams} from './server-file-saving.interface';

import {BrowserServerFileSavingCoreService} from './browser-server-file-saving.core.service';

export class MobileServerFileSavingCoreService extends BrowserServerFileSavingCoreService {
	public downloadFile(url: string, params: ExportParams, isDataViewDownload = false, callback: () => void = noop): Promise<any> {
		let requestData = '';
		const method = params.method || 'GET';
		const filterParam = omit(params, ['method']);

		requestData = join(
			reduce(
				filterParam,
				(result: any, value: string, key: string) => (!!key && !!value && !result.push(`${key}=${encodeURIComponent(value)}`)) || result,
				[]
			),
			'&'
		);

		const options = {
			url,
			data: requestData,
			requestMethod: method
		};

		this.window.cordova.exportFile(options, callback);
		return Promise.resolve();
	}
}
