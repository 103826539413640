import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, Optional, Output, SimpleChanges, ViewChild} from '@angular/core';
import {get, size} from 'lodash';

import {BaseMessageModel} from '../../../../core/generic/message/message.interface';
import {A11yAttrs} from '../../../../core/platform/attributes.interfaces';

import {FADEOUT_TOTAL, ONE_MESSAGE, READ_TIMEOUT} from '../../../../core/generic/message/message.constant';
import {
	getCloseSettingForType,
	getMessageForType,
	getSRMessage,
	loadMessageProperties,
	setElementFocus
} from '../../../../core/generic/message/message.utility';
import {PropertyFactory} from '../../../../core/property/property.factory';
import {PropertyFilterPipe} from '../../../../providers/property/property.filter.pipe';
import {accessibilitySpeakerRead} from '../../../../services/accessibility/accessibility-speaker.utility';
import {BASE_PATH} from '../../../../services/communication/data.config';
import {MessageBaseComponent} from '../message-base/message-base.component';

const ARIA_HIDDEN = 'aria-hidden';

@Component({
	selector: 'krn-ng-message-group',
	templateUrl: './message-group.component.html',
	styleUrls: ['../message-base/message-base.component.less', './message-group.component.less'],
	providers: [PropertyFilterPipe]
})
export class MessageGroupComponent extends MessageBaseComponent implements AfterViewInit {
	@Input() public messages: BaseMessageModel[];
	@Input() public showGroupName = false;
	@Input() public showClose: boolean;
	@Input() public fixedHeight = false;
	@Input() public focus = false;
	@Input() public messagesIdSuffix: string;
	@Input() public attrs: A11yAttrs;

	@Output() public onGroupClosed = new EventEmitter<string>();
	@ViewChild('closeMsgButton')
	private closeMsgButton: ElementRef;

	public groupTypeLabel: string;
	public hidden = false;

	constructor(
		private propertyFactory: PropertyFactory,
		private propertyFilterPipe: PropertyFilterPipe,
		@Optional() @Inject(BASE_PATH) basePath: string
	) {
		super(basePath);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		super.ngOnChanges(changes);

		this.showClose = getCloseSettingForType(this.showClose, this.messageType);
		this.showGroupName = this.messages.length > ONE_MESSAGE ? true : this.showGroupName;
		this.groupTypeLabel = getMessageForType(this.messageType, size(this.messages));
		if (this.messageType === 'success') {
			this.hideSuccess();
		}
	}

	public async ngAfterViewInit(): Promise<void> {
		const propertiesPromise = loadMessageProperties(this.propertyFactory);

		setElementFocus(this.focus, get(this.closeMsgButton, 'nativeElement'));

		// read the message to screen reader users if focus does not go to it
		if (!this.focus && !get(this.attrs, ARIA_HIDDEN)) {
			await propertiesPromise;
			accessibilitySpeakerRead(getSRMessage(this.propertyFilterPipe.transform(this.groupTypeLabel, [size(this.messages)]), this.messages));
		}
	}

	public close(): void {
		this.messages = [];
		accessibilitySpeakerRead(this.propertyFilterPipe.transform('html.commonInfra.components.common.message.close'), {readTimeout: READ_TIMEOUT});
		this.onGroupClosed.emit(this.messageType);
	}

	private hideSuccess(): void {
		setTimeout(() => {
			this.hidden = true;
			this.onGroupClosed.emit(this.messageType);
		}, FADEOUT_TOTAL);
	}
}
