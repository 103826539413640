import {ApiService} from '../../../../../core/communication/api-service/api-service.interfaces';

import {MenuIntegrationService} from '../../../../../core/navBar/menu-integration.service';
import {PropertyFilterConfig, propertyFilterInit} from '../../../../../core/property/property.filter';
import {DataService} from '../../../../../services/communication/data.service';

export function getMenuIntegrationService(propertyFilter: PropertyFilterConfig, dataService: ApiService): any {
	return new MenuIntegrationService(propertyFilter, dataService);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MenuIntegrationServiceProvider = {
	provide: MenuIntegrationService,
	deps: [propertyFilterInit, DataService],
	useFactory: getMenuIntegrationService
};
