import * as moment from 'moment';
import * as jQuery from 'jquery';
import {divide, includes, isEqual, multiply, uniqueId} from 'lodash';
import {Subscription, asyncScheduler, interval} from 'rxjs';
import {AsyncScheduler} from 'rxjs/internal/scheduler/AsyncScheduler';

import {ApiService, Transport} from '../../communication/api-service/api-service.interfaces';
import {AuthStack, UserStatus} from './auth-stack.interface';

import {AuthenticationRegistrationCoreService} from '../../authentication/authentication.registration.core.service';
import {ClientContainerContextCoreService} from '../../platform/clientContainer/client-container-context.core.service';
import {LocalStorageCoreService} from '../../platform/personalization/local-storage.service';
import {ActivityDetectorCoreService} from '../activityDetector/activity-detector.core.service';
import {setSessionStatus} from '../session.actions';
import {AUTH0_CLOSE_POPUP_TIMEOUT, SESSION_EVENTS} from '../session.constants';
import {SessionCoreService} from '../session.core.service';
import {
	DEFAULT_MAX_IDLE_TIME,
	EXTEND_SESSION_CONTENT_STATE,
	EXTEND_SESSION_ERROR,
	EXTEND_SESSION_IFRAME_ERROR_CONTENT,
	EXTEND_SESSION_IFRAME_FAIL,
	EXTEND_SESSION_IFRAME_PREFIX,
	EXTEND_SESSION_IFRAME_SUCESS,
	EXTEND_SESSION_START_IFRAME_ID,
	EXTEND_SESSION_URL,
	INIT_SESSION_INFO_ERROR,
	ONE_SECOND,
	SESSION_CHECK_USER_ACTIVITY_DURATION,
	SESSION_CLIENT_ID_KEY,
	SESSION_INFO_URL,
    SESSION_RESET_URL,
	SESSION_PREFIX,
	TIME_SINCE_LAST_ACTIVITY,
	TIME_SINCE_LAST_CALL,
	TIME_TO_EXTEND_SESSION,
	TIME_TO_SHOW_POPUP,
	TIME_TO_EXPIRE_SESSION,
	VISIBILITYCHANGE_EVENT
} from './auth0-stack.constant';

const $: any = jQuery;
const ZERO = 0;
const TWO = 2;

interface Auth0UserActivity {
	lastActivityTime?: number;
	lastPopupShowTime?: number;
	lastAuth0CallTime?: number;
	isActive: boolean;
	userStatus: string;
}

interface Auth0SessionInfo {
	clientId: string;
	maxIdleTime: number;
	sessionTimeOut: number;
}

export class Auth0StackCoreService implements AuthStack {
	protected sessionTimeoutTemplate: string;
	protected popupClass: string;
	private sessionService: SessionCoreService;
	private _clientId: string;
	private intervalSubscription: Subscription;
	private extendSessionIfActiveHandler: () => void;
	private sessionEndedListener: Subscription;
	private maxIdleTime: number = DEFAULT_MAX_IDLE_TIME;
	private halfMaxIdleTime: number;
	private sessionTimeOut: number;

	constructor(
		protected logger: any,
		protected authenticationRegistrationService: AuthenticationRegistrationCoreService,
		protected apiService: ApiService,
		protected activityDetectorService: ActivityDetectorCoreService,
		protected localStorageService: LocalStorageCoreService,
		protected document: Document,
		protected dispatch,
		protected clientContainerContextCoreService: ClientContainerContextCoreService = null,
		protected scheduler: AsyncScheduler = asyncScheduler
	) {
		this.extendSessionIfActiveHandler = () => this.extendSessionIfActive();
		this.authenticationRegistrationService.whenAuthenticated(() => this.init());
	}

	public get clientId(): string {
		return this._clientId;
	}

	public init(): void {
		const lastClientId = this.localStorageService.getItem(SESSION_CLIENT_ID_KEY) || '';

		this.getSessionInfo()
			.then((data: Auth0SessionInfo) => {
				this._clientId = `${SESSION_PREFIX}${data.clientId}`;
				this.setMaxIdleTime(data.maxIdleTime);
				this.sessionTimeOut = multiply(data.sessionTimeOut, ONE_SECOND);
				if (!isEqual(lastClientId, this._clientId)) {
					this.clearAllAuth0SessionKey(lastClientId);
					this.resetSessionTimeInLocalStorage();
				}
				this.setupListeners();
			})
			.catch(error => {
				this.logger.error(INIT_SESSION_INFO_ERROR, error);
			});
	}

	public extendSession(): Promise<any> {
		if (!this._clientId) {
			return;
		}

		this.logger.info(TIME_TO_EXTEND_SESSION, new Date().toUTCString());
		return new Promise((resolve, reject) => {
			const frame = this.document.createElement('iframe');

			frame.src = EXTEND_SESSION_URL;
			frame.id = uniqueId(EXTEND_SESSION_IFRAME_PREFIX);
			frame.className = 'ext-service-frame';
			frame.setAttribute('tabindex', '-1');
			frame.onload = () => {
				this.logger.info(EXTEND_SESSION_CONTENT_STATE, frame.contentDocument.readyState);
				if (includes(frame.contentDocument.body.innerHTML, EXTEND_SESSION_IFRAME_ERROR_CONTENT)) {
					reject(new Error(`${EXTEND_SESSION_IFRAME_FAIL} ${frame.id}`));
				} else {
					this.logger.info(EXTEND_SESSION_IFRAME_SUCESS, frame.id);
					resolve(true);
				}
				frame.remove();
			};
			this.logger.info(EXTEND_SESSION_START_IFRAME_ID, frame.id);
			this.document.body.appendChild(frame);
		})
			.then(() => this.activateUserEvents())
			.catch(err => this.logger.error(EXTEND_SESSION_ERROR, err));
	}

	public activateUserEvents(): void {
		const now = this.getDateNow();

		this.setValueInStorage('lastActivityTime', now);
		this.setValueInStorage('lastAuth0CallTime', now);
		this.setValueInStorage('isActive', true);
		this.listenUserActiveEvent();
        this.resetSession();
	}

	public setSessionService(service: SessionCoreService): void {
		this.sessionService = service;
	}

	public destroy(): void {
		const clientId = this.localStorageService.getItem(SESSION_CLIENT_ID_KEY) || '';

		this.clearAllAuth0SessionKey(clientId);
		this.destroyListener();
	}

    public resetSession(): Promise<any> {
        return this.apiService.get(SESSION_RESET_URL, undefined, undefined, Transport.REST);
    }

	public listenUserActiveEvent(): void {
		this.activityDetectorService.registerEvents();
		if (!this.intervalSubscription) {
			this.intervalSubscription = interval(SESSION_CHECK_USER_ACTIVITY_DURATION).subscribe(this.extendSessionIfActiveHandler);
		}
		$(this.document).off(VISIBILITYCHANGE_EVENT, this.extendSessionIfActiveHandler).on(VISIBILITYCHANGE_EVENT, this.extendSessionIfActiveHandler);
	}

	protected setSessionStatus(eventName): any {
		return setSessionStatus(eventName);
	}

	private setMaxIdleTime(maxIdleTime: number): void {
		if (maxIdleTime && maxIdleTime > ZERO) {
			this.maxIdleTime = multiply(maxIdleTime, ONE_SECOND);
		}
		this.halfMaxIdleTime = divide(this.maxIdleTime, TWO);
	}

	private setupListeners(): void {
		this.activityDetectorService.callback = () => this.setUserActive();
		this.listenUserActiveEvent();
		if (!this.clientContainerContextCoreService || !this.clientContainerContextCoreService.isOneApp()) {
			this.sessionEndedListener = this.apiService.listen(SESSION_EVENTS.ENDED).subscribe(() => this.ended());
		}
	}

	private destroyListener(): void {
		this.unlistenUserActiveEvent();
		this.unlistenSessionEndedEvent();
	}

	private extendSessionIfActive(): void {
		if (this.document.hidden) {
			return;
		}

		const now = this.getDateNow();
		const timeSinceLastAuth0Call = now - this.getValueFromStorage('lastAuth0CallTime');
		const timeSinceLastActivity = now - this.getValueFromStorage('lastActivityTime');

		this.logger.info(TIME_SINCE_LAST_CALL, (moment as any).utc(timeSinceLastAuth0Call).format('mm:ss'));
		this.logger.info(TIME_SINCE_LAST_ACTIVITY, (moment as any).utc(timeSinceLastActivity).format('mm:ss'));
		if (timeSinceLastAuth0Call >= this.maxIdleTime) {
			if (timeSinceLastActivity < this.halfMaxIdleTime) {
				this.extendSession();
			} else if (timeSinceLastActivity < this.sessionTimeOut) {
				this.logger.info(TIME_TO_SHOW_POPUP, new Date().toUTCString());
				if (this.getValueFromStorage('isActive')) {
					this.setValueInStorage('isActive', false);
				}
				this.unlistenUserActiveEvent();
				this.setValueInStorage('lastPopupShowTime', this.getDateNow());
				this.setValueInStorage('userStatus', UserStatus.INACTIVE);
				this.sessionService.expiringCallback(SESSION_EVENTS.EXPIRING, {gracePeriod: AUTH0_CLOSE_POPUP_TIMEOUT, extendable: true});
			} else {
				this.logger.info(TIME_TO_EXPIRE_SESSION, new Date().toUTCString());
				this.sessionService.expiredCallback(SESSION_EVENTS.ENDED, {});
			}
		} else if (timeSinceLastAuth0Call >= this.halfMaxIdleTime && !this.activityDetectorService.isEventRegistered()) {
			this.activityDetectorService.registerEvents();
		}
	}

	private unlistenUserActiveEvent(): void {
		this.activityDetectorService.unregisterEvents();
		this.clearCheckUserActiveInterval();
		$(this.document).off(VISIBILITYCHANGE_EVENT, this.extendSessionIfActiveHandler);
	}

	private unlistenSessionEndedEvent(): void {
		if (this.sessionEndedListener) {
			this.sessionEndedListener.unsubscribe();
			this.sessionEndedListener = undefined;
		}
	}

	private clearCheckUserActiveInterval(): void {
		if (this.intervalSubscription) {
			this.intervalSubscription.unsubscribe();
			this.intervalSubscription = undefined;
		}
	}

	private setUserActive(): void {
		this.setValueInStorage('userStatus', UserStatus.ACTIVE);
		this.setValueInStorage('lastActivityTime', this.getDateNow());
		this.activityDetectorService.unregisterEvents();
	}

	private setValueInStorage(key: keyof Auth0UserActivity, value: any): void {
		const existing = this.localStorageService.getItem(this._clientId);

		existing[key] = value;
		this.localStorageService.setItem(this._clientId, existing);
	}

	private getValueFromStorage(key: keyof Auth0UserActivity): any {
		return (this.localStorageService.getItem(this._clientId) as Auth0UserActivity)[key];
	}

	private getDateNow(): number {
		return this.scheduler.now();
	}

	private clearAllAuth0SessionKey(lastClientId: string): void {
		if (lastClientId) {
			this.localStorageService.removeItem(lastClientId);
			this.localStorageService.removeItem(SESSION_CLIENT_ID_KEY);
		}
	}

	private resetSessionTimeInLocalStorage(): void {
		const now = this.getDateNow();

		this.localStorageService.setItem(SESSION_CLIENT_ID_KEY, this._clientId);
		this.localStorageService.setItem(this._clientId, {lastActivityTime: now, lastPopupShowTime: now, lastAuth0CallTime: now, isActive: true});
	}

	private getSessionInfo(): Promise<any> {
		return this.apiService.get(SESSION_INFO_URL, undefined, undefined, Transport.REST);
	}

	private ended(): void {
		const eventName = SESSION_EVENTS.ENDED;

		this.dispatch(this.setSessionStatus(eventName));
		this.sessionService.endedCallback(eventName);
	}
}
