import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {filter, Observable, Subject, takeUntil} from 'rxjs';

import {UserInfo} from '../../../../../core/framework/services/user-info.interface';

import {ActionItemBaseComponent, ActionItemOptions} from './action-item-base.component';
import {PropertyFactory} from '../../../../../core/property/property.factory';
import {ReleaseToggleService} from '../../../../../core/platform/releaseToggle/release-toggle.service';
import {PropertyFilterPipe} from '../../../../../providers';
import {SlideOutHelperService} from '../../../../../services/slide-out-helper.service';
import {UserInfoActionService} from '../../../../../services/user-info-action.service';
import * as consts from '../../../../../core/suite-x-search/suite-x-search.constants';

@Component({
	templateUrl: './action-item.template.html',
	providers: [PropertyFilterPipe]
})
export class SuiteXSearchActionItemComponent extends ActionItemBaseComponent implements OnDestroy, OnInit {
	public static readonly DEFAULT = true;
	public static readonly actionItemName = 'SuiteXSearchActionItemComponent';
	public static readonly actionItemOptions: Partial<ActionItemOptions> = {
		id: 'suiteXSearch',
		icon: 'icon-k-bryte-ai',
		text: consts.TOOLTIP_KEY,
		title: consts.TOOLTIP_KEY,
		tooltip: consts.TOOLTIP_KEY,
		propertyFilePath: consts.PROPERTY_FILE_PATH,
		propertyFileName: consts.PROPERTY_FILE_NAME,
		visible: false,
		sticky: false
	};

	public readonly userInfo$: Observable<UserInfo>;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		public propertyFactory: PropertyFactory,
		public propertyFilterPipe: PropertyFilterPipe,
		private releaseToggleService: ReleaseToggleService,
		public route: ActivatedRoute,
		slideOutHelper: SlideOutHelperService,
		private userInfoActionService: UserInfoActionService
	) {
		super(SuiteXSearchActionItemComponent.actionItemOptions, propertyFactory, propertyFilterPipe, route, slideOutHelper);
		this.userInfo$ = this.userInfoActionService.select(['userInfo']).pipe(
			takeUntil(this.destroy$),
			filter(userData => !!userData.locale && consts.SUPPORTED_LOCALES.includes(userData.locale.name))
		);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public ngOnInit(): void {
		this.userInfo$.subscribe(() => {
			this.releaseToggleService.getAsync(consts.RELEASE_TOGGLE_KEY).then(value => {
				this.options.visible = value;
			});
		});
	}
}
