import * as jQuery from 'jquery';
import {get, isArray, join, map} from 'lodash';

import {SelectItemType, SelectListAttrs} from './select-item.interfaces';

import {CommonConstants} from '../../platform/constants/CommonConstants';
import {ARIA} from '../../platform/accessibility/utils.constants';

const $: any = jQuery;

export {appendSelectionLayout, findItems, findSelectedItems, getInputAttrs, getNativeType, isReadOnly};

const alignments = {
	[CommonConstants.HTML_ALIGNMENT.LEFT]: 'flex-row-reverse',
	[CommonConstants.HTML_ALIGNMENT.RIGHT]: 'flex-row'
};

function appendSelectionLayout(classes: string[] | string, alignment: string): string {
	const flexClass = alignments[alignment || CommonConstants.HTML_ALIGNMENT.RIGHT];
	const classString = isArray(classes) ? join(classes, ' ') : classes || '';

	return `${classString} ${flexClass}`;
}

function findItems(attrs: SelectListAttrs): any[] {
	const groupName = get(attrs, 'name');

	return $(`input[name=${groupName}]`);
}

function findSelectedItems(attrs: SelectListAttrs): any[] {
	const groupName = get(attrs, 'name');
	const names = $(`input[name=${groupName}]:checked, input[${ARIA.CHECKED}=mixed]`);

	return map(names, 'defaultValue');
}

function getInputAttrs(attrs: SelectListAttrs, selectType: string, defaultInputID: string): SelectListAttrs {
	return {...attrs, id: get(attrs, 'id', defaultInputID), type: getNativeType(selectType)};
}

function getNativeType(selectType: string): string {
	return selectType === SelectItemType.SINGLE ? 'radio' : 'checkbox';
}

function isReadOnly(attrs: SelectListAttrs, selectType: string): boolean {
	return get(attrs, 'readonly') || selectType === 'none';
}
