import * as jQuery from 'jquery';
import {isEmpty, noop} from 'lodash';

import {KeyNavService} from './keyboard-navigation.interfaces';

import {keys} from '../../constants/keys.constant';
import {EVENTS_HANDLERS} from '../utils.constants';

const $: any = jQuery;
const FOCUS_TILE_TIMEOUT = 300;

export class EnterNavigationService implements KeyNavService {
	private KEYDOWN_HANDLERS;

	constructor(private focusableElements: HTMLElement[], private container: HTMLElement, private expandCallback) {
		this.KEYDOWN_HANDLERS = {
			[keys.ENTER]: this.onExpand,
			[keys.SPACE]: this.onExpand
		};
	}

	public initialize(): void {
		$(this.container).on(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler);
	}

	public destroy(): void {
		$(this.container).off(EVENTS_HANDLERS.KEYDOWN, this.keyDownHandler);
	}

	private keyDownHandler = (event: KeyboardEvent): void => {
		(this.KEYDOWN_HANDLERS[event.code] || noop)(event);
	};

	private onExpand = (event: KeyboardEvent): void => {
		if (event.target === this.container && !isEmpty(this.focusableElements)) {
			event.preventDefault();

			if (this.expandCallback) {
				// eslint-disable-next-line no-unused-expressions
				this.expandCallback.emit ? this.expandCallback.emit() : this.expandCallback();
			}

			setTimeout(() => {
				$(this.focusableElements[0]).trigger('focus');
			}, FOCUS_TILE_TIMEOUT);
		}
	};
}
