import {get, isEmpty, isEqual, isNull, isString} from 'lodash';

import {ApiService, Transport} from '../communication/api-service/api-service.interfaces';
import {LogServiceInterface} from '../platform/services/logService/log.interface';

import {BrandingCoreService} from '../platform/services/branding.core.service';
import {KrnUtilitiesMobileWindow, runMobileContext} from '../platform/services/mobileInitializer.utility';
import {EXPIRE_FALCON_AUTH_COOKIE, LOGIN_CONSTANTS} from './authentication.constants';
import {getAuthCookieValue} from './authentication.cookie-reader';
import {STATUS_CODE} from '../platform/constants/CommonConstants';

const CONTENT_TYPE_HEADER_KEY = 'content-type';
const NOT_FOUND = -1;
let IS_AUTH0_LOGIN = false;

export abstract class AuthenticationCoreService {
	private defaultLogoutUrl: string = null;
	private postLogoutRedirectUri: string = null;

	constructor(
		protected dataService: ApiService,
		protected logger: LogServiceInterface,
		protected brandingService: BrandingCoreService,
		protected window: Window,
		protected sessionStorageService?
	) {}

	public establishLogout(): void {
		const urlPromise = isNull(this.defaultLogoutUrl) ? this.getLogoutUrl(IS_AUTH0_LOGIN) : Promise.resolve(this.defaultLogoutUrl);

		urlPromise.then(url => {
			if (!isEmpty(url)) {
				url = this.appendPostLogoutRedirectUriParam(url);
				this.window.location.href = url;
			}
		});

		if (!this.isKioskAuth0Logout()) {
			document.cookie = EXPIRE_FALCON_AUTH_COOKIE;
		}

		this.dispatchActionLogout();
	}

	public appendPostLogoutRedirectUriParam = (url: string): string => isEmpty(this.postLogoutRedirectUri) ? url : `${url}?post_logout_redirect_uri=${this.postLogoutRedirectUri}`;

	public isKioskAuth0Logout(): boolean {
		return !isEmpty(this.postLogoutRedirectUri) && isEqual(this.postLogoutRedirectUri, 'wfd/kiosk/login/user');
	}

	public getLogoutUrl(auth0Login?: boolean): Promise<string> {
		return auth0Login ? this.getAuth0LogoutUrl() : this.getOpenAmLogoutUrl();
	}

	public login(env?: {isAuthenticated: () => boolean}): Promise<any> {
		if (this.updateAuthStateIfAuthCookiesPresent(env, false)) {
			this.logger.info('Login is already authenticated');
			return Promise.resolve();
		}
		this.logger.info('Login needs authentication');
		this.brandingService.removeCache();
		return this.establishSsoConnection()
			.then(response => {
				if (isEqual(response.status, STATUS_CODE.SUCCESS) && this.isHTMLContent(response.headers)) {
					throw new Error(LOGIN_CONSTANTS.UNAUTHORIZED_CODE);
				}
				this.logger.info(response);
				return this.returnSuccessResponse(response);
			})
			.catch(error => {
				this.logger.error(error);

				if (error.status === STATUS_CODE.SUCCESS) {
					return this.returnSuccessResponse(error);
				}
				const msg = get(error, 'data', get(error, 'error'));
				const errorMessage = msg ? msg.error || JSON.parse(msg).error : msg;
				const errorObject = new Error(errorMessage);

				if (get(errorMessage, 'error') === 'NoOpenAM') {
					errorObject.name = get(errorMessage, 'error');
					errorObject.message = get(errorMessage, 'location');
					window.location.href = get(errorMessage, 'location');
				}

				if (isEqual(errorMessage, LOGIN_CONSTANTS.UNAUTHORIZED_CODE) || isEqual(error.message, LOGIN_CONSTANTS.UNAUTHORIZED_CODE)) {
					this.getAuthEnvironment().then(result => {
						IS_AUTH0_LOGIN = get(result, 'data', false);
						this.establishLogout();
						this.logger.info('IS_AUTH0_LOGIN -', result);
					}).catch(err => {
						this.logger.error('Error retrieving auth environment', err);
						return null;
					});
				}
				this.dispatchActionAuthenticateError(errorObject);
				return error;
			});
	}

	public clearWfcCookies(): Promise<any> {
		return this.dataService.get(LOGIN_CONSTANTS.CLEAR_WFC_COOKIES, undefined, undefined, Transport.REST, true, null, true, {observe: 'response'});
	}

	public clearNodeSpecificCookies(nodeType: string): Promise<any> {
		const baseUrl = LOGIN_CONSTANTS.CLEAR_NODE_SPECIFIC_COOKIES;
		const urlParam = `?nodeType=${nodeType}`;

		return this.dataService.get(baseUrl+urlParam);
	}

	public setDefaultLogoutUrl(url): void {
		this.defaultLogoutUrl = url;
	}

	public setPostLogoutRedirectUri(uri): void {
		this.postLogoutRedirectUri = uri;
	}

	protected updateAuthStateIfAuthCookiesPresent(env?: {isAuthenticated: () => boolean}, dispatchAction = true): boolean {
		if (this.isAuthenticationDone(env)) {
			if (dispatchAction) {
				this.dispatchActionAuthenticate();
			}
			return true;
		}
		return false;
	}

	protected isAuthenticationDone(env: {isAuthenticated: () => boolean} = null): boolean {
		if (env && env.isAuthenticated && env.isAuthenticated()) {
			this.logger.info('Environment is already authenticated');
			return true;
		}
		const acValue = getAuthCookieValue(document.cookie);

		this.logger.info('Document authentication', acValue);
		return acValue === 'true';
	}

	protected getOpenAmLogoutUrl(): Promise<string> {
		return this.window.location.protocol === LOGIN_CONSTANTS.HTTP_PROTOCOL
			? this.dataService
			.get(LOGIN_CONSTANTS.GET_OPENAM_PATH)
			.then(openamEndpoint => `${this.getEndpoint(openamEndpoint)}${LOGIN_CONSTANTS.LOGOUT_URL}`)
			.catch(err => {
				this.logger.error('Error retrieving logout url', err);
				return null;
			})
			: Promise.resolve(this.getLogoutUrlAddress(LOGIN_CONSTANTS.LOGOUT_SECURE_URL));
	}

	protected getAuth0LogoutUrl(): Promise<string> {
		return Promise.resolve(this.getLogoutUrlAddress(LOGIN_CONSTANTS.AUTH0_LOGOUT_URL));
	}

	private getLogoutUrlAddress(url: string): string {
		return `${this.window.location.protocol}//${this.window.location.host}${url}`;
	}

	private establishSsoConnection(): Promise<any> {
		const baseUrlToHit = LOGIN_CONSTANTS.SSO_PORTAL;
		const dateTime = new Date().getTime();

		const endUrlToHit = window.location.search === '' ? `?time=${dateTime}` : `${window.location.search}&time=${dateTime}`;
		const urlToHit = baseUrlToHit + endUrlToHit;

		return this.dataService.get(urlToHit, undefined, {responseType: 'text'}, Transport.REST, true, null, true, {observe: 'response'});
	}

	private getAuthEnvironment(): Promise<any> {
		return this.dataService.get(LOGIN_CONSTANTS.GET_ENVIRONMENT_PATH);
	}

	private getEndpoint = (endpoint): string => get(endpoint, 'data.message') || get(endpoint, 'message');

	private isHTMLContent(headers): boolean {
		const contentType = headers && headers[CONTENT_TYPE_HEADER_KEY];

		return isString(contentType) && contentType.indexOf('text/html') !== NOT_FOUND;
	}

	private async returnSuccessResponse(response: any): Promise<any> {
		if (this.sessionStorageService !== undefined) {
			this.sessionStorageService.reset();
		}

		try {
			await runMobileContext((window as unknown) as KrnUtilitiesMobileWindow);
		} catch (ex) {
			this.logger.error('error in runmobilecontext', ex);
		}
		this.dispatchActionAuthenticate();
		return response;
	}

	protected abstract dispatchActionLogout();

	protected abstract dispatchActionAuthenticateError(errorObject: any);

	protected abstract dispatchActionAuthenticate();
}
