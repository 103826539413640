import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MessageModule} from '../../message/message.module';
import {PersistentBannerComponent} from './persistent-banner.component';

@NgModule({
	declarations: [PersistentBannerComponent],
	exports: [PersistentBannerComponent],
	imports: [CommonModule, MessageModule]
})
export class PersistentBannerModule {}
