import {get, includes} from 'lodash';

import {PropertyFactory} from '../../property/property.factory';
import {ImpersonationAllowedUser, ImpersonationAllowedEmployment} from '../models/user-impersonation-detail.model';
import {REDIRECT_TIMEOUT} from '../user-impersonation.constants';
import {UserImpersonationService} from '../user-impersonation.service';

export abstract class UserImpersonationBaseController {
	public propertiesLoaded: boolean;
	public buttonConfig: any;
	public isCollapsed: boolean;
	public isLoading: boolean;
	public impersonationDetails: any;
	public selectedUser: string;

	constructor(private windowRef: Window, private userImpersonationService: UserImpersonationService, private propertyFactory: PropertyFactory) {
		this.propertiesLoaded = false;
		this.isCollapsed = false;
	}

	public initialize(): void {
		this.propertyFactory
			.loadProperties([
				{
					name: 'container_web-common_nav-bar_strings.properties',
					path: 'components/zed-core/properties/navBar/'
				}
			])
			.then(() => {
				this.propertiesLoaded = true;
				this.buttonConfig = this.userImpersonationService.getButtonConfig(this.toggleCollapsed.bind(this));
			});
	}

	public toggleCollapsed(): void {
		this.isCollapsed = !this.isCollapsed;
	}

	public performUserImpersonation(user: ImpersonationAllowedUser): void {
		const performImpersionation = (): void => {
			this.userImpersonationService.clearSessionBeforeImpersonation();
			setTimeout(() => {
				this.isLoading = false;
				this.windowRef.location.href = '/';
			}, REDIRECT_TIMEOUT);
		};

		this.selectedUser = user && user.fullName || get(user, 'employmentName', null);
		this.isLoading = true;
		this.userImpersonationService
			.performUserImpersonation(user)
			.then(response => {
				if (response !== 'FAILED') {
					performImpersionation();
				} else {
					this.isLoading = false;
					this.displayErrorMsg(this.userImpersonationService.getSelfImpersonateErrorMsg());
				}
			})
			.catch(error => {
				if(error && error.status === 502) {
					performImpersionation();
				} else {
					this.isLoading = false;
					this.displayErrorMsg(error);
				}
			});
	}

	protected generateErrorMsg = (error: any): string => this.userImpersonationService.getErrorMsg(error, this.selectedUser);

	protected abstract displayErrorMsg(error: any): void;
}
