import {get} from 'lodash';

export class NetworkStream {
	private records: any[] = [];
	private currentTimeout: number;
	private isTimerStarted = false;
	private readonly userAgent = typeof this.window !== 'undefined' ? this.window.navigator.userAgent : 'no-window';
	private readonly throttleTime = 60000;

	constructor(private readonly onSend: (recs: any[]) => Promise<any>, private readonly window: Window, private readonly $log: Console) {}

	public write(record: any): void {
		// Only start the timer if the application actually uses the telemetry logger
		if (!this.isTimerStarted) {
			this.isTimerStarted = true;
			this.startTimer();
		}

		record.url = get(this.window, 'location.href');
		record.userAgent = this.userAgent;
		this.records.push(record);
	}

	public stop(): void {
		if (this.currentTimeout) {
			this.window.clearTimeout(this.currentTimeout);
			this.currentTimeout = null;
		}
	}

	private startTimer(): void {
		// prevent logging if the user is a bot
		if (/bot|crawler|spider|crawling/i.test(this.userAgent)) {
			return;
		}

		this.window.addEventListener(
			'beforeunload',
			() => {
				if (this.currentTimeout) {
					this.window.clearTimeout(this.currentTimeout);
				}

				if (this.records) {
					this.send(this.records);
				}
			},
			false
		);

		this.throttleRequests();
	}

	private throttleRequests(): void {
		this.currentTimeout = this.window.setTimeout(() => {
			if (this.records.length) {
				this.send(this.records);
			}

			this.throttleRequests();
		}, this.throttleTime);
	}

	private send(records): void {
		if (this.shouldSendRecords()) {
			this.onSend(records)
				.then(() => (this.records.length = 0))
				.catch(e => this.$log.warn('Browser bunyan was unable to send server stream:', e));
		}
	}

	private shouldSendRecords(): boolean {
		return this.window.navigator.onLine;
	}
}
