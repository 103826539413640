import {SessionCoreService} from '../session.core.service';

export interface AuthStack {
	setSessionService: (service: SessionCoreService) => void;
	init: () => void;
	// extend or reset session
	extendSession?: () => Promise<any>;
	resetSession?: () => Promise<any>;
	destroy: () => void;
}

export enum UserStatus {
    ACTIVE = 'active',
	INACTIVE = 'inactive'
}
