import {cloneDeep, filter, find, forEach, get, includes, isEmpty, isEqual, isUndefined, size, some, unionBy} from 'lodash';

import {ApiService} from '../communication/api-service/api-service.interfaces';

const inValidStatus = ['FAILURE', 'FAILURE_TIMEOUT'];
const inProgressStatus = 'INPROGRESS';

export const listenForExtendedMenu = (
	apiService: ApiService,
	logger: any,
	callback: (tenantId?: any, personId?: any, menuData?: any) => void
): any => {
	apiService.listen('menutItem.update').subscribe(response => {
		const responseArgs: any[] = response.args;
		const tenantId = responseArgs[0];
		const personId = responseArgs[1];
		const extendedMenu = responseArgs[2];
		const extendedMenuStatus = get(extendedMenu, 'status');

		if ((isEmpty(get(extendedMenu, 'menu')) || includes(inValidStatus, extendedMenuStatus)) && isEmpty(get(extendedMenu, 'additionalData'))) {
			if (!isEqual(extendedMenuStatus, inProgressStatus)) {
				logger.error('Error in retrieving external menu:- ', extendedMenu);
			}
			return callback();
		}

		return callback(tenantId, personId, extendedMenu);
	});
};

/*
 * Update the WFD menu by merging the menu from integrated service
 * find by label-if menu exist, simple concat else merge based in sort order
 * */
export const updateMainMenu = (extendedMenu: any, mainMenu: any, isPhone: boolean): any => {
	let insertIndex;
	let selectedMenu;
	const internalMenus = [];

	const updatedMainMenu = cloneDeep(mainMenu);
	let menu = get(updatedMainMenu, 'menu');

	const removeAllMenuItemsForExternalService = (menus, serviceId, level): any => {
		forEach(menus, (item, index, all) => {
			if (item && item.menu) {
				removeAllMenuItemsForExternalService(item.menu, serviceId, ++level);
			} else if (item && item.serviceId === serviceId) {
				item.hasExternalItem = true;
			}
			if (isUndefined(item.menu) && level === 0) {
				internalMenus.push(all[Number(index)]);
			} else if (!isUndefined(item.menu)) {
				item.menu = filter(item.menu, m => isUndefined(m.hasExternalItem));
				if (size(all[index].menu)) {
					internalMenus.push(all[Number(index)]);
				}
			}
		});
	};

	removeAllMenuItemsForExternalService(menu, extendedMenu.serviceId, 0);
	menu = internalMenus;

	forEach(extendedMenu.menu, category => {
		if (isPhone) {
			category.menu = filter(category.menu, currentMenu => get(currentMenu, 'mobileVisible', true));
		}

		if (!size(category.menu)) {
			return;
		}

		insertIndex = size(menu);
		selectedMenu = find(menu, {label: category.label});

		if (selectedMenu && selectedMenu.menu) {
			selectedMenu.menu = unionBy(selectedMenu.menu, category.menu, 'label');
		} else {
			some(menu, (someMenu, index) => {
				if (category.order < someMenu.order) {
					insertIndex = index;
					return true;
				}
				return false;
			});

			menu.splice(insertIndex, 0, category);
		}
	});
	updatedMainMenu.additionalData = extendedMenu.additionalData;
	updatedMainMenu.menu = menu;
	return updatedMainMenu;
};
