import {NavigationEnd, Router} from '@angular/router';
import {Directive, OnDestroy, OnInit} from '@angular/core';
import {includes, get, startsWith} from 'lodash';
import {filter} from 'rxjs';

import {EmbeddedPostMessageServiceInterface, EmbeddedUnsavedDataServiceInterface} from '../../../core/embedded/embedded.interfaces';

import {WFD_URL} from '../../../core/iframe-framework/iframe-framework.constant';
import {BaseEmbeddedPageCoreController} from '../../../core/embedded/base-embedded-page.core.controller';
import {ClientContainerContextService} from '../../../services/clientContainer/client-container-context.service';
import {ClientContainerService} from '../../../services/clientContainer/client-container.service';
import {SessionStorageService} from '../../../services/session-storage.service';
import {WindowRefService} from '../../../services/window-ref.service';

@Directive()
export class BaseEmbeddedPageComponent extends BaseEmbeddedPageCoreController implements OnInit, OnDestroy {
	private currentUrl: string;

	constructor(
		protected clientContainerService: ClientContainerService,
		protected embeddedPostMessageService: EmbeddedPostMessageServiceInterface,
		protected router: Router,
		protected sessionStorageService: SessionStorageService,
		protected windowRefService: WindowRefService,
		protected embeddedUnsavedDataService: EmbeddedUnsavedDataServiceInterface,
		protected clientContainerContextService: ClientContainerContextService
	) {
		super(windowRefService.nativeWindow, sessionStorageService, clientContainerService, embeddedPostMessageService, embeddedUnsavedDataService);
	}

	public ngOnInit(): void {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(this.navigationEnd.bind(this));
	}

	public ngOnDestroy(): void {
		this.embeddedUnsavedDataService.destroySubscription();
	}

	public navigationEnd(event: NavigationEnd): void {
		let path = get(event, 'url');

		path = startsWith(path, '/') ? path : `/${path}`;

		// when path is / or /home, the current page is the home page, and there is no need to send the navigationComplete action in OneApp
		if (includes(['/home', '/'], path) && this.clientContainerContextService.isOneApp()) {
			return;
		}

		this.currentUrl = `${window.location.origin}${WFD_URL}${path}`;

		if (path && !includes(path, '/ukgpro/launcher') && !includes(path, 'app-slideout')) {
			this.postNavigatedForwardMessage(this.currentUrl);
		}

		this.clientContainerService.getMenuItems().then(() => {
			this.sendTitle(this.currentUrl);
		});
	}
}
