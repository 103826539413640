import {Injectable} from '@angular/core';

import {SessionStorageService} from '../../services/session-storage.service';
import {WindowRefService} from '../window-ref.service';
import {ClientContainerContextCoreService} from '../../core/platform/clientContainer/client-container-context.core.service';

@Injectable({
	providedIn: 'root'
})
export class ClientContainerContextService extends ClientContainerContextCoreService {
	constructor(
		public windowRefService: WindowRefService, 
		public sessionStorageService: SessionStorageService
	) {
		super(windowRefService.nativeWindow, sessionStorageService);
	}
}