export const ALERT_CLASS_PREFIX = 'alert-';
export const FIRST_ELEMENT = 0;
export const NO_DELAY = 0;
export const ONE_MESSAGE = 1;
export const READ_TIMEOUT = 10;
export const FOCUS_TIMEOUT = 50;
export const DEBOUNCE_TIMEOUT = 100;
export const FADEOUT_DELAY = 5000;
export const FADEOUT_TIME = 2500;
export const FADEOUT_TOTAL = FADEOUT_DELAY + FADEOUT_TIME;

export const messageTypes = ['error', 'info', 'warning', 'success'];

export const MESSAGE_PROPERTIES = [
	{
		name: 'container_web-common_message_strings.properties',
		path: 'components/zed-core/properties/generic/message/'
	}
];
export enum MESSAGE_TYPES {
	ERROR = 'error',
	INFO = 'info',
	WARNING = 'warning',
	SUCCESS = 'success'
}
export const MESSAGE_TITLES = {
	[MESSAGE_TYPES.ERROR]: 'html.commonInfra.components.common.header.error',
	[MESSAGE_TYPES.INFO]: 'html.commonInfra.components.common.header.information',
	[MESSAGE_TYPES.SUCCESS]: 'html.commonInfra.components.common.header.success',
	[MESSAGE_TYPES.WARNING]: 'html.commonInfra.components.common.header.warning'
};
export const MESSAGE_SIMPLE_TITLES = {
	[MESSAGE_TYPES.ERROR]: 'html.commonInfra.components.common.words.error',
	[MESSAGE_TYPES.INFO]: 'html.commonInfra.components.common.words.information',
	[MESSAGE_TYPES.SUCCESS]: 'html.commonInfra.components.common.words.success',
	[MESSAGE_TYPES.WARNING]: 'html.commonInfra.components.common.words.warning'
};
export const MESSAGE_ORDER = {
	[MESSAGE_TYPES.ERROR]: 0,
	[MESSAGE_TYPES.WARNING]: 1,
	[MESSAGE_TYPES.SUCCESS]: 2,
	[MESSAGE_TYPES.INFO]: 3
};
