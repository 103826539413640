import {get, includes, some} from 'lodash';

import {ResponseData} from '../one-app/one-app-event-bus.interfaces';
import {ClientContainer} from '../platform/clientContainer/client-container.interface';
import {
	EmbeddedIframeActions,
	EmbeddedMenuCategory,
	EmbeddedMenuItem,
	EmbeddedPostMessageServiceInterface,
	EmbeddedUnsavedDataServiceInterface,
	LegacyEmbeddedMenuItem
} from './embedded.interfaces';

import {OneAppAction} from '../one-app/one-app-event-bus.constants';
import {SessionStorage} from '../platform/caching/sessionStorage.service';
import {EMBEDDED_BACK_CLICKED, UKGPRO_MENU_ITEMS} from './embedded.constants';
import {getCurrentTile, isTileOpened} from '../tile/tile.utility';
import {NAME_PATH} from '../tile/tile.constants';
import {isOneApp} from '../platform/services/DeviceConfig.utility';
import {getTopWfdWindow} from '../iframe-framework/iframe-message-handler.utility';

export abstract class BaseEmbeddedPageCoreController {
	constructor(
		protected window: Window,
		protected sessionStorageService: SessionStorage,
		protected clientContainerService: ClientContainer,
		protected embeddedPostMessageService: EmbeddedPostMessageServiceInterface,
		protected embeddedUnsavedDataService: EmbeddedUnsavedDataServiceInterface
	) {
		if (window === clientContainerService.getTopWfdWindow()) {
			embeddedPostMessageService.initializeMessageListener(this.iframeMessageHandler);
		}
	}

	public iframeMessageHandler = (action: string, url: string, data?: ResponseData): Promise<any> => {
		let response: Promise<any> = null;

		switch (action) {
			case EmbeddedIframeActions.ON_BACK:
				response = this.embeddedBack(action, url);
				break;

			case OneAppAction.IS_UNSAVED_DATA_PRESENT:
				response = this.embeddedBack(action, url);
				this.window.onbeforeunload = null;
				break;

			case EmbeddedIframeActions.ON_UNLOAD:
				response = this.embeddedUnsavedDataService.verifyUnsavedData(action, '');
				break;

			case OneAppAction.CONFIRMATION_DIALOG_RESPONSE:
				this.doDialogAction(data);
				break;

			default:
				break;
		}

		return response;
	};

	public doDialogAction(data: ResponseData): void {
		const iframe = document.querySelector('iframe');
		const modalCancelBtn: HTMLElement =
			document.querySelector('.modal-open .dialog-cancel') || (iframe && iframe.contentWindow.document.querySelector('.modal-open .dialog-cancel'));
		const modalOkBtn: HTMLElement =
			document.querySelector('.modal-open .dialog-primary') || (iframe && iframe.contentWindow.document.querySelector('.modal-open .dialog-primary'));
		const targetBtn = data.buttonPressed === 'positive' ? modalCancelBtn : modalOkBtn;

		if (targetBtn) {
			targetBtn.click();
		}
	}

	public postNavigatedForwardMessage = (url: string): void => {
		this.window.dispatchEvent(
			new CustomEvent(this.clientContainerService.getEventName(), {
				detail: {
					method: 'navigatedForward',
					arg: {url}
				}
			})
		);
		this.sessionStorageService.set(EMBEDDED_BACK_CLICKED, false);
	};

	public sendTitle = (newUrl: string): void => {
		let title = '';
		let currentUrl = get(/\w\/(.*)/.exec(newUrl), '1')
			.replace(/[?&]pageId=\d*/, '')
			.replace(/[?&]ctxt=\D*/, '');
		const menuItems = get(this.sessionStorageService.get(UKGPRO_MENU_ITEMS), 'menu', []);
		const homeUrlList = ['/wfd/home/tile', 'wfd/#', 'wfd/home#'];
		const isHomePage = some(homeUrlList, item => includes(newUrl, item));

		if (isHomePage) {
			currentUrl = 'wfd/home';
		}

		title = this.getTitle(menuItems, currentUrl);

		if (!title && includes(currentUrl, 'portal#/') && isTileOpened(this.sessionStorageService)) {
			title = get(getCurrentTile(this.sessionStorageService), NAME_PATH);
		}

		if (includes(newUrl, '/wfd/home/tile') && isOneApp(getTopWfdWindow(this.window))) {
			title = get(getCurrentTile(this.sessionStorageService), NAME_PATH, title);
		}

		if (title) {
			this.window.dispatchEvent(
				new CustomEvent(this.clientContainerService.getEventName(), {
					detail: {
						method: 'sendTitleIfNeeded',
						arg: {title}
					}
				})
			);
		}
	};

	public getTitle = (menuItems: EmbeddedMenuCategory[] | EmbeddedMenuItem[] | LegacyEmbeddedMenuItem[], currentUrl: string): string => {
		for (const item of menuItems) {
			if (includes(get(item, 'url'), currentUrl)) {
				return get(item, 'name');
			}
			if (includes(get(item, 'uri'), currentUrl)) {
				return get(item, 'text');
			}
			if (get(item, 'menu')) {
				const title = this.getTitle(get(item, 'menu'), currentUrl);

				if (title) {
					return title;
				}
			}
		}
	};

	private embeddedBack(action: string, url: string): Promise<any> {
		this.sessionStorageService.set(EMBEDDED_BACK_CLICKED, true);
		return this.embeddedUnsavedDataService.verifyUnsavedData(action, url);
	}
}
