export enum CardDataState {
	initializing,
	content,
	noContent,
	saving,
	saveSuccess,
	saveFail,
	dismissed
}

export interface CardComponentList {
	[key: string]: any;
}

export interface CardData {
	type: string;
	dataViewId?: number;
}

export interface Card {
	id: number;
	name: string;
	flag: string;
	type: CardType;
	subType: string;
	enableActions: boolean;
	data?: CardData;
	configuration?: {
		type: any;
		cardType: string;
		components?: any[];
		icon?: string;
		url?: string;
		urlText?: string;
		linkDescription?: string;
		serviceIdentifier?: any;
		navigation?: {
			url?: string;
			type?: string;
			label?: string;
			serviceName?: any;
		};
	};
}

export enum CardType {
	GENERIC = 'GENERIC',
	CUSTOM = 'CUSTOM',
	EMBED = 'EMBED',
	EXTERNAL = 'EXTERNAL',
	INTERNAL = 'INTERNAL',
	MANAGE_EXCEPTIONS = 'MANAGE_EXCEPTIONS',
	MVOVERTIME = 'MVOVERTIME',
	MVABSENCE = 'MVABSENCE',
	MVMYACCRUAL = 'MVMYACCRUAL',
	MVANALYTIC = 'MVANALYTIC',
	MVHCANALYTIC = 'MVHCANALYTIC',
	CHART = 'CHART',
	MULTI_SERIES_CHART = 'MULTISERIESCHART',
	PERSONINSIGHTS = 'PERSONINSIGHTS'
}

export const INPUT = {
	ALLOW_DELETE: 'allowDelete',
	CARD: 'card',
	CAN_NAVIGATE: 'canNavigate',
	DELETE: 'delete',
	TILE: 'tile'
};

