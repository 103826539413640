import {camelCase, cloneDeep, find, head, noop} from 'lodash';

import {NUMBERS} from '../platform/constants/CommonConstants';
import {keyCodes} from '../platform/constants/keys.constant';
import {MenuItemConfig} from './models/menu-item-config.model';
import {Category, ExpandedState, NavBarObj} from './models/menu-item.models';
import {SessionStorage} from '../platform/caching/sessionStorage.service';

export class NavBarMenuItemCore {
	private keyDownMap;

	constructor(protected sessionStorageService: SessionStorage) {
		this.keyDownMap = {
			[keyCodes.UP_ARROW]: this.upArrowAction,
			[keyCodes.DOWN_ARROW]: this.downArrowAction,
			[keyCodes.TAB]: this.tabArrowAction
		};
	}

	public getDefaults(category: Category): Category {
		return {
			id: null,
			text: null,
			tooltip: null,
			options: {
				visible: true,
				expanded: category ? category.options.expanded : false
			}
		};
	}

	public getNavBarState(): any {
		return this.sessionStorageService.get('navBar') || {mainMenu: {categories: []}};
	}

	public setExpandedState(config: MenuItemConfig, category: Category, navBarObj: NavBarObj): ExpandedState {
		const state = this.getExpandedState(config, category, navBarObj);

		if (state) {
			this.sessionStorageService.set('navBar', state.navBar);
		}
		return state;
	}

	public getExpandedState(config: MenuItemConfig, category: Category, navBarObj: NavBarObj): ExpandedState {
		let isContainSelected: boolean;
		let navBar: NavBarObj;
		let newCategory: Category;

		if (config.menu) {
			isContainSelected = Boolean(find(config.menu, {isSelected: true}));

			if (isContainSelected && !config.options.expanded) {
				navBar = cloneDeep(navBarObj);

				newCategory = category ? category : {id: config.id, options: config.options};
				newCategory.options.expanded = config.options.expanded = isContainSelected;

				navBar.mainMenu.categories.push(newCategory);
				return {navBar, newCategory};
			}
		}
	}

	public setToggledCategory(config: MenuItemConfig, navBarObj: NavBarObj): ExpandedState {
		const state = this.toggleCategory(config, navBarObj);

		this.sessionStorageService.set('navBar', state.navBar);
		return state;
	}

	public toggleCategory(config: MenuItemConfig, navBarObj: NavBarObj): ExpandedState {
		const navBar: NavBarObj = cloneDeep(navBarObj);
		let newCategory: Category = this.getNavBarCategories(config.id, navBar);

		if (newCategory) {
			newCategory.options.expanded = config.options.expanded;
		} else {
			newCategory = {id: config.id, options: config.options};
			navBar.mainMenu.categories.push(newCategory);
		}

		return {navBar, newCategory};
	}

	public getNavBarCategories(id: number, navBarObj: NavBarObj): Category {
		return find(navBarObj.mainMenu.categories, {id});
	}

	public getAutomationId(config): string {
		const postfix: string = config.isNested ? 'MenuCategory' : 'MenuLink';

		return camelCase(config.label) + postfix;
	}

	public topLevelMenuFocus(event: KeyboardEvent, menuData, itemIndex: number, subIndex: number): void {
		(this.keyDownMap[event.which] || noop)(event, menuData, itemIndex, subIndex);
	}

	public setSubMenuSelectedFocus(event): any {
		const targetElement = $(event.target);
		const element = targetElement.parent().parent() || targetElement;
		const expandedState = element.find('.expanded').length;
		const focusEle = element.find('.selected').length ? element.find('.selected') : head(element.find('li').filter(':first').find('a'));

		event.stopPropagation();

		if (expandedState) {
			event.preventDefault();
			focusEle.focus();
		}
	}

	public setMoveTabToId(menuData, itemIndex, subIndex): string {
		let focusId = '';

		if (subIndex) {
			const targetIndex = itemIndex === menuData.length - NUMBERS.ONE ? NUMBERS.ZERO : itemIndex + NUMBERS.ONE;

			focusId = targetIndex ? `#${menuData[targetIndex].id}_category` : `#${menuData[targetIndex].id}_link`;
		}
		return focusId;
	}

	private upArrowAction = (event: KeyboardEvent, menuData, itemIndex: number): void => {
		const targetIndex = itemIndex === 0 ? menuData.length - 1 : itemIndex - 1;

		this.moveFocusToTarget(event, menuData, targetIndex);
	};

	private downArrowAction = (event: KeyboardEvent, menuData, itemIndex: number): void => {
		const targetIndex = itemIndex === menuData.length - 1 ? 0 : itemIndex + 1;

		this.moveFocusToTarget(event, menuData, targetIndex);
	};

	private tabArrowAction = (event: KeyboardEvent, menuData, itemIndex: number, subIndex: number): void => {
		if (event.shiftKey && itemIndex > 0 && !subIndex) {
			const targetIndex = itemIndex === 0 ? menuData.length - 1 : itemIndex - 1;

			this.moveFocusToTarget(event, menuData, targetIndex);
		}
	};

	private moveFocusToTarget(event: KeyboardEvent, data: MenuItemConfig[], targetIndex: number): void {
		const targetEle = $(`#${data[targetIndex].id}_category`);
		const focusEle = targetEle.length ? targetEle : $(`#${data[targetIndex].id}_link`);

		event.preventDefault();
		event.stopPropagation();
		focusEle.focus();
	}
}
