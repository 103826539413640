import {AfterContentInit, Directive, ElementRef, EventEmitter, Output} from '@angular/core';
import * as jQuery from 'jquery';

const $: JQueryStatic = jQuery;

@Directive({
  selector: '[krn-ng-scroll-detector]'
})
export class ScrollDetectorDirective implements AfterContentInit {
	@Output() protected scrollChecked = new EventEmitter<boolean>();

	private containerElement: JQuery;
	private isContentScrollable = false;

  constructor(private containerElRef: ElementRef<HTMLElement>) {}

	public ngAfterContentInit(): void {
		this.containerElement = $(this.containerElRef.nativeElement);
		this.containerElement.find('*')
			.filter((idx, childElement): boolean => {
				const childElementOverflowY: string = $(childElement).css('overflowY');

				return childElementOverflowY === 'scroll' || childElementOverflowY === 'auto';
			})
			.each((idx, childElement): false | void => {
			this.isContentScrollable = this.checkIfContentOverflowing(childElement) && this.checkIfElementHasVerticalScrollbar(childElement);
			if (this.isContentScrollable) {
				return false;
			}
		});
		this.scrollChecked.emit(this.isContentScrollable);
	}

	private checkIfContentOverflowing(element: HTMLElement): boolean {
		return element.clientHeight < element.scrollHeight;
	}

	private checkIfElementHasVerticalScrollbar(element: HTMLElement): boolean {
		return element.clientWidth < element.offsetWidth;
	}
}
