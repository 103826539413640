import {Inject, Injectable} from '@angular/core';
import {cloneDeep} from 'lodash';

import {ApiService} from '../../../../../../core/communication/api-service/api-service.interfaces';
import {
	HelpContext,
	HelpRefineFilters,
	HelpSearchRequest,
	HelpService,
	DocumentOpenAnalyticsRequest
} from '../../../../../../core/navBar/contextual-help/contextual-help.interfaces';

import {UserInfoService} from '../../../../../../core/framework/services/user-info.service';
import {actionHandler} from '../../../../../../core/navBar/contextual-help/contextual-help-search.actions';
import {ContextualHelpActions} from '../../../../../../core/navBar/contextual-help/contextual-help-search.constants';
import {contextualHelpReducer, DEFAULT_STATE} from '../../../../../../core/navBar/contextual-help/contextual-help.reducer';
import {createStore} from '../../../../../../core/navBar/contextual-help/contextual-help.store';
import {
	HELP_CONTEXT_DEFAULT,
	checkSearchFAP,
	doCommunitySearch,
	analyticsForDocumentOpen
} from '../../../../../../core/navBar/contextual-help/contextual-help.utils';
import {NO_RESULTS} from '../../../../../../core/navBar/contextual-help/contextual-help.constants';
import {DataService} from '../../../../../../services/communication/data.service';

@Injectable({providedIn: 'root'})
export class ContextualHelpService implements HelpService {
	public communitySearchRequest: HelpSearchRequest;
	public store: any;

	private helpContext: HelpContext = HELP_CONTEXT_DEFAULT;

	constructor(@Inject(DataService) public apiDataService: ApiService, public userDataService: UserInfoService) {}

	public checkSearchFAP = async (): Promise<boolean> => checkSearchFAP(this.userDataService);

	public set = (cshid: string): void => {
		this.helpContext = {...HELP_CONTEXT_DEFAULT, cshid};
	};

	public get = (): HelpContext => this.helpContext;

	public remove = (): HelpContext => {
		this.helpContext = HELP_CONTEXT_DEFAULT;
		return this.helpContext;
	};

	public createStore(): void {
		this.store = createStore(contextualHelpReducer, DEFAULT_STATE);
	}

	public async loadMore(): Promise<void> {
		this.communitySearchRequest.from = this.communitySearchRequest.from + this.communitySearchRequest.resultsPerPage;
		await doCommunitySearch(
			this.store,
			this.apiDataService,
			cloneDeep(this.communitySearchRequest),
			{searchRequest: this.communitySearchRequest},
			ContextualHelpActions.LoadingMore,
			ContextualHelpActions.LoadMore
		);
	}

	public async sort(searchRequest: HelpSearchRequest): Promise<void> {
		this.communitySearchRequest = {...this.store.getState().searchRequest, from: searchRequest.from};
		this.communitySearchRequest.sortby = searchRequest.sortby;
		await this.search(this.communitySearchRequest, ContextualHelpActions.Loading, ContextualHelpActions.Sort);
	}

	public subscribeToStore(observer): any {
		return this.store.subscribe(observer);
	}

	public async search(searchRequest: HelpSearchRequest, loaderAction: ContextualHelpActions, postLoaderAction: ContextualHelpActions): Promise<void> {
		await doCommunitySearch(this.store, this.apiDataService, cloneDeep(this.communitySearchRequest), {searchRequest}, loaderAction, postLoaderAction);
	}

	public refineOpen(): void {
		const newRefineState = cloneDeep(this.store.getState());

		this.store.dispatch(actionHandler(ContextualHelpActions.RefinePanelOpen, newRefineState));
	}

	public cancelRefine(): void {
		this.store.dispatch(actionHandler(ContextualHelpActions.Cancel, this.store.getState()));
	}

	public async refine(searchRequest: HelpRefineFilters, isRefined: boolean, isClear: boolean): Promise<void> {
		this.communitySearchRequest.aggregations = searchRequest.aggregations;
		this.communitySearchRequest.from = NO_RESULTS;
		await doCommunitySearch(
			this.store,
			this.apiDataService,
			cloneDeep(this.communitySearchRequest),
			{searchRequest: this.communitySearchRequest},
			ContextualHelpActions.Loading,
			ContextualHelpActions.Refined,
			isRefined,
			isClear
		);
	}

	public async clickTitle(searchRequest: DocumentOpenAnalyticsRequest): Promise<void> {
		analyticsForDocumentOpen(searchRequest, this.apiDataService);
	}
}
