import {Component} from '@angular/core';

import {Classes, MessageType} from '../../../../core/generic/message/message.interface';


import {MESSAGE_SIMPLE_TITLES} from '../../../../core/generic/message/message.constant';

@Component({
	selector: 'krn-ng-message-popup-content',
	templateUrl: './message-popup-content.component.html',
	styleUrls: ['../message-base/message-base.component.less', './message-popup-content.component.less']
})
export class MessagePopupContentComponent {
	public classes: Classes;
	public headerText: string;
	public iconClass: string;
	public imagePath: string;
	public messageText: string;
	public messageType: MessageType;
	public open = false;
	public primaryLabel: string;
	public secondaryLabel: string;
	public showPrimary: boolean;
	public showSecondary: boolean;

	public messageTitles: {[key: string]: string} = MESSAGE_SIMPLE_TITLES;

	public onClose: (() => void);
	public onPrimaryClicked: (() => void);
	public onSecondaryClicked: (() => void);
}
