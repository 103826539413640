import {Inject, Injectable} from '@angular/core';
import {get, set} from 'lodash';

import {ApiService} from '../../core/communication/api-service/api-service.interfaces';
import {Event, EventData} from '../../core/iframe-framework/iframe-framework.interface';

import {getServiceIdentifier, sendAuthnSsid} from '../../core/iframe-framework/custom-request.utility';
import {
	ACTIONS,
	CONFIGURATION_URL,
	MINUS_ONE,
	NAVIGATION_EXCLUSION,
	ONE,
	ZERO
} from '../../core/iframe-framework/iframe-framework.constant';
import {
	initializeMessageListener,
	isFullUrl,
	isValidData,
	removeMessageListener
} from '../../core/iframe-framework/iframe-message-handler.utility';
import {DataService} from '../communication/data.service';
import {ExternalAuthService} from '../external-auth-service';
import {UnsavedDataService} from '../unsaved-data-service';
import {WindowRefService} from '../window-ref.service';
import {IFrameNavigation} from './iframe-navigation.service';
import {IFrameValidatorService} from './iframe-validator.service';
import {MessageService} from './message.service';

@Injectable({providedIn: 'root'})
export class PostMessageEventListener {
	constructor(
		private windowRef: WindowRefService,
		private IFrameValidator: IFrameValidatorService,
		private IFrameNavigationService: IFrameNavigation,
		private unsavedDataService: UnsavedDataService,
		private externalAuthService: ExternalAuthService,
		@Inject(DataService) private apiService: ApiService,
		private messageService: MessageService
	) {}

	public initialize = (iframeMessageListener: (event) => void = this.messageCallback): void => {
		initializeMessageListener(this.windowRef.nativeWindow, iframeMessageListener);
	};

	public removeListener = (iframeMessageListener: (event) => void): void => {
		removeMessageListener(this.windowRef.nativeWindow, iframeMessageListener);
	};

	public messageCallback = (event: Event): void => {
		const data = event.data;
		const targetOrigin = event.origin;

		if (isValidData(data) && data.action === ACTIONS.LOGIN_SUCCESS) {
			this.externalAuthService.setLoginSuccess(get(data, 'configuration.serviceName'));
			return;
		}
		if (isValidData(data) && this.IFrameValidator.isFrameValid(event.origin)) {
			switch (data.action) {
				case ACTIONS.NAVIGATION:
					this.handleNavigation(event, data);
					break;
				case ACTIONS.MESSAGE:
					this.messageService.showMessage(data.type, data.configuration);
					break;
				case ACTIONS.DIRTY_STATE:
					this.unsavedDataService.setCustomPageDirty(get(data, 'configuration.data', false));
					break;
				case ACTIONS.AUTHNSSID:
					sendAuthnSsid(this.apiService, targetOrigin, data.configuration.data);
					break;
				case ACTIONS.STATE:
					this.handleStateUpdate(event, data);
					break;
				default:
				// do nothing
			}
		}
	};

	private handleNavigation = (event: Event, data: EventData): void => {
		const url = get(data, CONFIGURATION_URL);
		const slash = '/';
		let location;

		if (NAVIGATION_EXCLUSION.indexOf(data.type.toLowerCase()) !== MINUS_ONE) {
			this.IFrameNavigationService.handleNavigation(data);
		}
		if (!url) {
			return;
		}
		if (isFullUrl(url) || get(data, 'configuration.isInternalUrl') === true) {
			this.IFrameNavigationService.handleNavigation(data);
		} else {
			try {
				location = get(event, 'source.location');
				set(data, CONFIGURATION_URL, `${location.protocol}//${location.host}${location.port ? `:${location.port}` : ''}/${url}`);
				this.IFrameNavigationService.handleNavigation(data);
			} catch (ex) {
				getServiceIdentifier(this.apiService, data.configuration.serviceName).then(serviceDetails => {
					const separator = url.slice(ZERO, ONE) !== slash && serviceDetails.url.slice(MINUS_ONE) !== slash ? slash : '';

					set(data, CONFIGURATION_URL, serviceDetails.url + separator + url);
					this.IFrameNavigationService.handleNavigation(data);
				});
			}
		}
	};

	private handleStateUpdate = (event: Event, data: EventData): void => {
		if (!get(data, CONFIGURATION_URL)) {
			return;
		}

		getServiceIdentifier(this.apiService, data.configuration.serviceName).then(serviceDetails => {
			this.IFrameNavigationService.handleNavigation(data);
		});
	};
}
