import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalModule as BsModalModule} from 'ngx-bootstrap/modal';

import {DeprecationMessageService} from '../../services/deprecation-message/deprecation-message.service';
import {PropertyModule} from '../../providers/property/property.module';
import {AttributesModule} from '../../utils/attributes/attributes.module';
import {FocusOnShowModule} from '../../utils/focus-on-show/focus-on-show.module';
import {ModalKeyboardNavigationModule} from '../../utils/modal-keyboard-navigation/modal-keyboard-navigation.module';
import {ButtonModule} from '../button/button.module';
import {MessageBaseComponent} from './components/message-base/message-base.component';
import {MessageGroupComponent} from './components/message-group/message-group.component';
import {MessageInpageComponent} from './components/message-inpage/message-inpage.component';
import {MessagePopupContentComponent} from './components/message-popup-content/message-popup-content.component';
import {MessagePopupComponent} from './components/message-popup/message-popup.component';
import {SingleMessageComponent} from './components/single-message/single-message.component';

/**
 * @deprecated This message component is deprecated and will be removed in the future in favor of components found within the Ignite design system. [Click here for more details](https://engstash.int.kronos.com/projects/FRONT/repos/frontend/browse/packages/web/zed/README.md)
 */
@NgModule({
	declarations: [
		MessageBaseComponent,
		MessageGroupComponent,
		MessageInpageComponent,
		MessagePopupComponent,
		MessagePopupContentComponent,
		SingleMessageComponent
	],
	imports: [BsModalModule.forRoot(), ButtonModule, CommonModule, FocusOnShowModule, ModalKeyboardNavigationModule, PropertyModule, AttributesModule],
	exports: [MessageInpageComponent, MessagePopupComponent, MessageGroupComponent]
})
export class MessageModule {
	constructor(private deprecationMessageService: DeprecationMessageService) {
		this.deprecationMessageService.log('message');
	}
}
