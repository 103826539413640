import {find, get, isString, split, startsWith, uniqueId} from 'lodash';

import {Classes, ToggleAnimation, ToggleAttrs} from './toggle.interfaces';

import {CommonConstants} from '../platform/constants/CommonConstants';
import {PropertyFactory} from '../property/property.factory';

const verticalAlignMap = {
	['pos-value-top']: 'align-self-start',
	['pos-value-center']: 'align-self-center',
	['pos-value-bottom']: 'align-self-end'
};

export const getAnimateToggleState = (state: boolean): ToggleAnimation => state ? ToggleAnimation.Active : ToggleAnimation.Inactive;

export const getAriaDescribedBy = (attrs: ToggleAttrs): string => get(attrs, 'aria-describedby');

export const getAriaLabel = (attrs: ToggleAttrs, label: string): string => get(attrs, 'aria-label', label);

export const getAriaLabelledBy = (attrs: ToggleAttrs, id: string): string => {
	const labelledBy = get(attrs, 'aria-labelledby');

	return labelledBy ? `${labelledBy} ${id}` : id;
};

export const getVerticalAlign = (classes: Classes): string => {
	const classList = isString(classes) ? split(classes, ' ') : classes;
	const match = find(classList, cls => startsWith(cls, 'pos-value'));

	return verticalAlignMap[match] || 'align-self-start';
};

export const initAlignment = (alignment?: string): string => alignment || CommonConstants.HTML_ALIGNMENT.LEFT;

export const initClasses = (classes?: Classes): Classes => classes || 'max-lines-one';

export const initToggleLabelId = (attrs?: ToggleAttrs): string => get(attrs, 'id', uniqueId('toggle-label-'));

export const loadToggleButtonStrings = (propertyFactory: PropertyFactory): Promise<any> =>
	propertyFactory.loadProperties([
		{
			name: 'container_web-common_toggle-button_strings.properties',
			path: '/components/zed-core/properties/toggle/'
		}
	]);
