<div class="d-flex align-items-center" [ngClass]="containerClasses">
	<div class="search-container composite-container hide-pipe flex-grow-1" [ngClass]="classes" [class.disabled]="attrs?.disabled">
		<div class="flex-grow-1 main-container">
			<krn-ng-simple-input
				(click)="stopClickPropagation($event)"
				[alignment]="alignment"
				[attrs]="getInputAttrs(attrs, ('common.search.placeholder' | property) || ' ')"
				[classes]="formElementClasses"
				[label]="label"
				(focus)="selectText($event, searchTerm)"
				(keydown.enter)="updateSearchTerm($event)"
				[(inputValue)]="searchTerm"
				(inputValueChange)="updateSearchTerm()">
			</krn-ng-simple-input>
		</div>
		<div class="flex-shrink-1 btn-container">
			<span *ngIf="submitOnChange && !hasSearchValue(searchTerm)">
				<ng-container *ngTemplateOutlet="searchButton"></ng-container>
			</span>
			<krn-ng-button
				*ngIf="hasSearchValue(searchTerm)"
				[attr.id]="attrs?.id ? attrs?.id + '_clear-btn' : undefined"
				[attrs]="{
					'automation-id': getAutomationId(baseAutoId, 'ClearButton'),
					'aria-label': ariaSearchDetail
						? ('common.search.clearBtnWithParams' | property: [ariaSearchDetail])
						: ('common.search.clearBtn' | property),
					title: ariaSearchDetail ? ('common.search.clearBtnWithParams' | property: [ariaSearchDetail]) : ('common.search.clearBtn' | property),
					disabled: !hasSearchValue(searchTerm) || attrs?.disabled,
					id: attrs?.id ? attrs?.id + '_search-clear-btn' : undefined
				}"
				(buttonClicked)="onCancelButtonPress($event)"
				classes="btn-secondary btn-search-clear position-relative clear-btn p-xs"
				[icon]="clearIcon">
			</krn-ng-button>
		</div>
	</div>
	<div *ngIf="!submitOnChange" class="flex-shrink-1 search-btn-container">
		<ng-container *ngTemplateOutlet="searchButton"></ng-container>
	</div>
	<div *ngIf="isMobileDevice && hasSearch && searchResultCount !== undefined" class="sr-only" aria-live="polite" aria-atomic="true">
		{{ searchResultCount ? ('common.search.xMatchesFound' | property: [searchResultCount]) : ('common.search.noMatchesFound' | property) }}
	</div>
</div>

<ng-template #searchButton>
	<krn-ng-button
		[attr.id]="attrs?.id ? attrs?.id + '_search-btn' : undefined"
		[attrs]="{
			'automation-id': getAutomationId(baseAutoId, 'SearchButton'),
			'aria-label': ariaSearchDetail ? ('common.search.submitBtnWithParams' | property: [ariaSearchDetail]) : ('common.search.submitBtn' | property),
			title: ariaSearchDetail ? ('common.search.submitBtnWithParams' | property: [ariaSearchDetail]) : ('common.search.submitBtn' | property),
			disabled: submitOnChange || attrs?.disabled
		}"
		(buttonBlurred)="searchBtnHasFocus = false"
		(buttonFocused)="searchBtnHasFocus = true"
		(buttonClicked)="searchButtonClicked($event)"
		[classes]="'btn-' + (searchTerm || !submitOnChange ? 'secondary btn-search-container' : 'default') + 'position-relative search-btn p-xs'"
		icon="icon-k-search">
	</krn-ng-button>
</ng-template>
