<div class="new-release-feature-popup"
     data-component="dialog-box"
     role="dialog"
     aria-labelledby="popup-title">
  <div class="row header d-flex justify-content-end">
    <h2 id="popup-title"
        style="display: none;">
      {{'html.navbar.newReleaseFeaturePreference.heading' | property}}
    </h2>
    <krn-ng-button [attrs]="{
                     'aria-label': ('html.navbar.newReleaseFeaturePreference.close' | property),
                     id: 'popupClose',
                     title: ('html.navbar.newReleaseFeaturePreference.close' | property)
                   }"
                   btnType="button"
                   (buttonClicked)="onClose()"
                   classes="btn btn-secondary close-button"
                   krn-ng-focus-on-show
                   icon="icon-k-close">
    </krn-ng-button>
  </div>
  <div class="modal-body">
    <div id="new-release-feature-content"
         *ngIf="newReleaseFeatureURL">
      <iframe automation-id="newReleaseFeatureFrame" [src]="newReleaseFeatureURL"></iframe>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-start">
    <krn-ng-toggle alignment="right"
                   automation-id="doNotShowAgain_Toggle"
                   [attrs]="{id: 'doNotShowLabel'}"
                   [label]="'html.navbar.newReleaseFeaturePreference.dontShowAgain' | property"
                   [(toggleState)]="doNotShowAgain">
    </krn-ng-toggle>
  </div>
</div>
