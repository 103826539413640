export const ACTION_TYPES = {
	CLOSE: 'CLOSE',
	CANCEL: 'CANCEL',
	SUBMIT: 'SUBMIT',
	OPEN_AFTER_CLOSE: 'OPEN_AFTER_CLOSE',
	OPEN_WITH_ROUTER: 'OPEN_WITH_ROUTER',
	SLIDEOUT_CONTAINER: 'SLIDEOUT_CONTAINER',
	SLIDEOUT_COMPONENT: 'SLIDEOUT_COMPONENT',
	SLIDEOUT_DESTROY: 'SLIDEOUT_DESTROY',
	SLIDEOUT_FOCUS: 'SLIDEOUT_FOCUS_ON_CLOSE'
};

export const SLIDEOUT_CONTEXT_KEY = 'slideoutContext';

// NOTE: Delay applying the transitioned classes until the slideout animation is quiescent
export const TRANSITIONED_DELAY = 250;

export const SLIDEOUT_PROPERTY = [{
	name: 'container_web-common_slideOutContainer_strings.properties',
	path: 'components/zed-core/properties/generic/slideOutContainer/'
}];

export const FOCUS_EXCLUDE = '.sr-only';

export const SLIDE_OUT_ISO_DEVICE_HELPER_ID = 'slide-out-ios-device-helper';

export const TASK_DRAWER_CLOSE_BUTTON_ID = 'taskDrawerCloseButton';
