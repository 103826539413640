import {Store} from '@ngrx/store';
import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {get} from 'lodash';
import {Observable, Subscription} from 'rxjs';

import {BaseMessageModel, KrnMessageDetailsModel} from '@kronos/zed/src/lib/core/generic/message/message.interface';
import {Message} from '@kronos/zed/src/lib/services/iframe-framework/message.interfaces';
import {OverrideUkgLanguageJson} from '@kronos/zed/src/lib/core/platform/platform.interfaces';
import {UserInfo} from '@kronos/zed/src/lib/core/framework/services/user-info.interface';

import {isSMA} from '@kronos/zed/src/lib/core/embedded/embedded-page.utility';
import {NAME, THEME_MESSAGE_KEY} from '@kronos/zed/src/lib/core/framework/directives/persistent-banner/persistent-banner.constants';
import {getPersistentBannerProperty} from '@kronos/common/components/framework/directives/persistent-banner/core/persistent-banner.utility';
import {MESSAGE_TYPES} from '@kronos/zed/src/lib/core/generic/message/message.constant';
import {ALLOWED_MESSAGE_TYPE, INDEX_NOT_FOUND, MINUS_ONE} from '@kronos/zed/src/lib/core/iframe-framework/iframe-framework.constant';
import {HOME_URI} from '@kronos/zed/src/lib/core/navBar/nav-bar-menu';
import {NO_OFFSET_TOP} from '@kronos/zed/src/lib/core/navBar/nav-bar.constants';
import {NavBarState, StateExtendNavBar} from '@kronos/zed/src/lib/core/navBar/nav-bar.state';
import {focusOnMain, getHTMLLanguage, pageNotInIframe} from '@kronos/zed/src/lib/core/platform/platform.utility';
import {PropertyFactory} from '@kronos/zed/src/lib/core/property/property.factory';
import {setState} from '@kronos/zed/src/lib/core/navBar/nav-bar.actions';
import {PropertyFilterPipe} from '@kronos/zed/src/lib/providers/property/property.filter.pipe';
import {BrandingService} from '@kronos/zed/src/lib/services/branding.service';
import {OverrideUkgLanguageJsonService} from '@kronos/zed/src/lib/services/override-ukg-language-json.service';
import {MessageService} from '@kronos/zed/src/lib/services/iframe-framework/message.service';
import {PostMessageEventListener} from '@kronos/zed/src/lib/services/iframe-framework/post-message-listener.service';
import {SessionStorageService} from '@kronos/zed/src/lib/services/session-storage.service';
import {UnsavedDataService} from '@kronos/zed/src/lib/services/unsaved-data-service';
import {WindowRefService} from '@kronos/zed/src/lib/services/window-ref.service';
import {PersistentBannerService} from '@kronos/zed/src/lib/ui/framework/persistent-banner/persistent-banner.service';
import {stateGetter} from '@kronos/zed/src/lib/core/platform/types/state';
import {isOneApp} from '@kronos/zed/src/lib/core/platform/services/DeviceConfig.utility';
import {implementRUM} from '@kronos/zed/src/lib/core/platform/thirdPartyAction/datadog.actions';
import {LocalStorageService} from '@kronos/zed/src/lib/services/local-storage.service';
import {UserInfoService} from '@kronos/zed/src/lib/core/framework/services/user-info.service';

import {isAuth0KioskRunning} from './kiosk/core/utils/kiosk.utility';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./../../../node_modules/@kronos/common/components/less/zed-tokens/ag-grid/ag-theme-custom-angular.less', './app.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [PropertyFilterPipe]
})
export class AppComponent implements OnInit, OnDestroy {
	@ViewChild('mainRef') public mainRef: ElementRef;

	public readonly isMenuOpen$: Observable<boolean>;
	public readonly showNavBar$: Observable<boolean>;
	public navBar: Observable<NavBarState>;
	public app = 'app';
	public navigationEnd = false;
	public showEmbeddedPage = false;
	public showOneAppPage = false;
	public showNavBar = false;
	public showMessage = false;
	public showNewFeatures = true;
	public messages: BaseMessageModel[];
	public message: Message;
	public isMenuOpen: boolean;
	public persistentBannerMessage: KrnMessageDetailsModel;
	public displayTitle: boolean;
	public pageLanguage: string;
	public pageNotInIframe: boolean;
	public overrideUkgLanguageJson$: Observable<OverrideUkgLanguageJson>;
	public userInfo: UserInfo;

	private navBarSubscription: Subscription;

	constructor(
		private store: Store<StateExtendNavBar>,
		private propertyFactory: PropertyFactory,
		private propertyFilter: PropertyFilterPipe,
		private cdRef: ChangeDetectorRef,
		private router: Router,
		private messageService: MessageService,
		private postMessageEventListener: PostMessageEventListener,
		private sessionStorageService: SessionStorageService,
		private brandingService: BrandingService,
		public unsavedDataService: UnsavedDataService,
		public windowRefService: WindowRefService,
		private persistentBannerService: PersistentBannerService,
		private overrideUkgLanguageJsonService: OverrideUkgLanguageJsonService,
		private localStorageService: LocalStorageService,
		private userInfoService: UserInfoService
	) {
		this.userInfoService.getLoggedOnUserData().then((userInfo: UserInfo) => {
			this.userInfo = userInfo;
			this.setFavicon();
			implementRUM(userInfo);
		});
		this.router.events.subscribe(this.onRouterEvents);
		this.isMenuOpen$ = this.store.select(stateGetter<StateExtendNavBar, boolean>(['navBar', 'isMenuToggled']));
		this.showNavBar$ = this.store.select(stateGetter<StateExtendNavBar, boolean>(['navBar', 'visible']));
		this.navBar = this.store.select(stateGetter<StateExtendNavBar, NavBarState>('navBar'));
		this.pageNotInIframe = pageNotInIframe(this.windowRefService.nativeWindow);
	}

	public ngOnInit(): void {
		this.showEmbeddedPage = isSMA(this.sessionStorageService);
		this.showOneAppPage = isOneApp(this.windowRefService.nativeWindow);
		this.showNewFeatures = this.isShowFeaturesEnabled();
		this.navBarSubscription = this.navBar.subscribe(this.onNavBarStateChange);
		this.isMenuOpen$.subscribe(this.onMenuOpen);
		this.messageService.messageSource$().subscribe(this.onMessage);
		this.postMessageEventListener.initialize();
		if (this.brandingService.getPreviewStatus() && !this.isIframe()) {
			this.brandingPersistentBannerInit();
		}
		this.pageLanguage = getHTMLLanguage();
		this.overrideUkgLanguageJson$ = this.overrideUkgLanguageJsonService.getOverrideJson();
	}

	public setFavicon(): void {
		if (this.userInfo && this.userInfo.vendorName) {
			const favicon = document.getElementById('favicon') as HTMLLinkElement;

			favicon.type = 'image/x-icon';
			favicon.rel = 'icon';
			favicon.href = `/${this.userInfo.vendorName.toLowerCase()}/favicon.ico`;
		}
	}

	public navbarStateChange(state): void {
		this.store.dispatch(setState(state));
	}

	public groupMessageClosed(): void {
		this.showMessage = false;
	}

	public ngOnDestroy(): void {
		this.navBarSubscription.unsubscribe();
	}

	public getMainHeight = (): string => {
		const offsetTop = get(this.mainRef, 'nativeElement.offsetTop', NO_OFFSET_TOP);

		return `calc(100% - ${offsetTop}px)`;
	};

	public skipToMain = (): void => focusOnMain();

	public mainMenuToggle = (status: boolean): void => {
		this.isMenuOpen = status;
	};

	private async brandingPersistentBannerInit(): Promise<void> {
		const brandingColor = await this.brandingService.getColors();
		const showMessage = this.persistentBannerService.showMessage.bind(this.persistentBannerService);
		const propertyFilter = this.propertyFilter.transform.bind(this.propertyFilter);

		getPersistentBannerProperty(this.propertyFactory as any, THEME_MESSAGE_KEY, (): void => {
			showMessage({
				messageType: MESSAGE_TYPES.INFO,
				message: {messageText: propertyFilter(THEME_MESSAGE_KEY, [get(brandingColor, NAME)])},
				options: {showCloseButton: false, fixedHeight: false}
			});
		});
	}

	private isIframe = (): boolean => this.windowRefService.nativeWindow.top !== this.windowRefService.nativeWindow;

	private onRouterEvents = (params: any): void => {
		if (params instanceof NavigationStart) {
			this.navigationEnd = false;
		}

		if (params instanceof NavigationEnd) {
			this.navigationEnd = true;
			this.displayTitle = this.windowRefService.nativeWindow.location.href.indexOf(HOME_URI) === INDEX_NOT_FOUND;
		}
	};

	private onMenuOpen = (status: boolean): void => {
		this.isMenuOpen = status;
	};

	private onMessage = (messageData: Message): void => {
		this.message = messageData;
		if (ALLOWED_MESSAGE_TYPE.indexOf(messageData.messageType) !== MINUS_ONE) {
			this.showMessage = true;
			this.messages = [
				{
					messageText: get(messageData, 'config.message')
				}
			];
		}
	};

	private onNavBarStateChange = (navState: NavBarState): void => {
		if (this.showNavBar !== navState.visible) {
			this.showNavBar = navState.visible;
			this.cdRef.detectChanges();
		}
	};

	private isShowFeaturesEnabled = (): boolean => !isAuth0KioskRunning(this.localStorageService);
}
