import {Injectable} from '@angular/core';

import {ClientContainerContextService} from '@kronos/zed/src/lib/services/clientContainer/client-container-context.service';
import {SessionStorageService} from '@kronos/zed/src/lib/services/session-storage.service';

export const SHOW_EMPLOYEE_HEADER = 'showEmployeeHeader';
export const SHOW_NAV_BAR = 'showNavBar';

@Injectable({providedIn: 'root'})
export class AppService {
	constructor(private sessionStorageService: SessionStorageService, private clientContainerContextService: ClientContainerContextService) {}

	public setShowEmployeeHeader = (showHeader: boolean): void =>
		this.sessionStorageService.set(SHOW_EMPLOYEE_HEADER, !this.clientContainerContextService.isSMA() && !this.clientContainerContextService.isProEmbedded() && showHeader);

	public setShowNavBar = (showNavBar: boolean): void =>
		this.sessionStorageService.set(SHOW_NAV_BAR, !this.clientContainerContextService.isEmbedded() && showNavBar);

	public getShowEmployeeHeader = (): boolean => this.sessionStorageService.get(SHOW_EMPLOYEE_HEADER);

	public getShowNavBar = (): boolean => this.sessionStorageService.get(SHOW_NAV_BAR);
}
