import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as jQuery from 'jquery';
import {isArray} from 'lodash';

import {KrnMessageDetailsModel, KrnMessageGroupModel, MessageAttrs} from '../../../../core/generic/message/message.interface';
import {A11yAttrs} from '../../../../core/platform/attributes.interfaces';

import {findFirstClose, loadMessageProperties, sortMessagesByTheme} from '../../../../core/generic/message/message.utility';
import {PropertyFactory} from '../../../../core/property/property.factory';

const $: any = jQuery;

@Component({
	selector: 'krn-ng-message-inpage',
	templateUrl: './message-inpage.component.html',
	styleUrls: ['./message-inpage.component.less']
})
export class MessageInpageComponent implements OnInit, OnChanges {
	@Input() public attrs: A11yAttrs;
	@Input() public setFocus: boolean;
	@Input() public model: KrnMessageDetailsModel[];
	@Input() public classes?: string;
	@Output() public onMessageClosed = new EventEmitter<string>();
	@Input() public messagesIdSuffix: string;
	@Input() public showClose: boolean;

	public groupModels: KrnMessageGroupModel[];
	public firstClose: string;

	constructor(public propertyFactory: PropertyFactory) {}

	public ngOnInit(): void {
		loadMessageProperties(this.propertyFactory);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.model && changes.model.currentValue) {
			this.groupModels = this.getMessageGroups(changes.model && changes.model.currentValue);
			this.firstClose = this.setFocus ? findFirstClose(this.groupModels) : undefined;
		}
	}

	public onGroupClosed(type: string): void {
		this.onMessageClosed.emit(type);
	}

	private getMessageGroups(model: KrnMessageDetailsModel | KrnMessageDetailsModel[]): KrnMessageGroupModel[] {
		return sortMessagesByTheme(isArray(model) ? model : [model]);
	}
}
