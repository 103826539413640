import {get, has, includes, isEmpty} from 'lodash';

import {SimpleInputAttrs, SimpleInputType} from './simple-input.interfaces';

import {getUniqueId} from '../../platform/form-element.utility';

export {getAttributes, getInputTooltip, getInputValue, isButton, isButtonPlaceholder, generateAriaLabelBy};

function isButton(attrs?: SimpleInputAttrs): boolean {
	return get(attrs, 'type') === SimpleInputType.BUTTON;
}

function isButtonPlaceholder(attrs?: SimpleInputAttrs, value?: string | number): boolean {
	return isButton(attrs) && get(attrs, 'placeholder') === value;
}

function getAttributes(attrs?: SimpleInputAttrs, label?: string): SimpleInputAttrs {
	return !has(attrs, 'id') || isEmpty(attrs.id) || isEmpty(attrs.type)
		? {
			type: SimpleInputType.TEXT,
			...attrs,
			id: getUniqueId(attrs, label, 'input')
		}
		: attrs;
}

function getInputTooltip(attrs?: SimpleInputAttrs, value?: string | number, classes?: string | string[]): string | number {
	return attrs.type === 'password' || includes(classes, 'invisible-without-focus') ? '' : attrs.title || value;
}

function getInputValue(attrs?: SimpleInputAttrs, value?: string | number): string | number {
	return isButton(attrs) && (!value || isEmpty(value)) ? attrs.placeholder : value;
}

function generateAriaLabelBy(attrs?: SimpleInputAttrs, label?: string, hint?: string): string {
	const attrsId = get(attrs, 'id', '');
	let labelOrHint = '';
	let currentLabel = '';

	if (!isEmpty(label)) {
		labelOrHint += `${attrsId}Label `;
	}
	if (!isEmpty(hint)) {
		labelOrHint += `${attrsId}Hint `;
	}
	currentLabel = attrs['aria-labelledby'] || labelOrHint;
	return !attrs['aria-labelledby'] && isButton(attrs) ? `${labelOrHint} ${attrsId}` : currentLabel;
}
