import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Navigation, NavigationEnd, Router} from '@angular/router';
import * as jquery from 'jquery';
import {debounce, get, includes, isEmpty, isNil, last} from 'lodash';
import {fromEvent, Observable} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {SlideoutEvent, SlideoutState} from '../../../core/generic/slideOutContainer/slideout.interfaces';
import {SlideoutContentContext} from './slide-out-content/slideout-content.interface';

import {BaseSlideOutContainer} from '../../../core/generic/slideOutContainer/base-slideout-container';
import {closeSlideOut, closeWithValidationCallback} from '../../../core/generic/slideOutContainer/slideout-container.utils';
import {SLIDEOUT_CONTEXT_KEY, TRANSITIONED_DELAY, SLIDE_OUT_ISO_DEVICE_HELPER_ID, TASK_DRAWER_CLOSE_BUTTON_ID} from '../../../core/generic/slideOutContainer/slideout.constants';
import {SLIDEOUT_INITIAL_STATE} from '../../../core/generic/slideOutContainer/slideout.reducer';
import {sendPostMessage} from '../../../core/iframe-framework/iframe-message-handler.utility';
import {keyCodes} from '../../../core/platform/constants/keys.constant';
import {DeviceConfigService} from '../../../services/device-config.service';
import {SessionStorageService} from '../../../services/session-storage.service';
import {WindowRefService} from '../../../services/window-ref.service';
import {BaseSliderActionHandler} from './slide-out-content/handlers/base-slider-action-handler';
import {ContainerSemaphoreService} from './slide-out-content/handlers/container-semaphore.service';
import {SlideOutManagerService} from './slide-out-manager.service';
import {DefaultSliderActionHandler} from './slide-out-content/handlers/default-slider-action-handler';
import {NavbarActionService} from '../nav-bar/container/navbar.action.service';
import {ClientContainerService} from '../../../services/clientContainer/client-container.service';
import {SlideOutActionService} from '../../../services/slideout-action.service';
import {OtherActionService} from '../iframe-slideout/other-action.service';
import {SlideOutAccessibilityService} from './slide-out-accessibility.service';

const $: JQueryStatic = jquery;
const DELAY_TIME_LOW = 200;
const DELAY_TIME_MEDIUM = 500;
const TASK_DRAWER_MASK_LAYER_SELECTOR = 'ukg-backdrop';

@Component({
	selector: 'krn-slide-out-container',
	templateUrl: './slide-out.component.html',
	styleUrls: ['./slide-out.component.less'],
	encapsulation: ViewEncapsulation.None
})
export class SlideOutComponent extends BaseSlideOutContainer implements AfterViewInit, OnDestroy {
	@Input() public context: any;
	@Input() public autoFocus: boolean;
	@Input() public returnFocus: boolean;
	@Input() public showSlideOut: boolean;
	@Input() public showSpinner: boolean;
	@Input() public spinnerLabel: string;
	@Input() public focusOnSpinner: boolean;
	@Input() public returnFocusElement: string;
	@Input() public removeSlideOutOnClose: boolean;
	@Input() public onRemoveSlideOut: () => void;
	@Input() public validationCallback: () => Promise<any>;
	@Input() public navigateOnClose = true;
	@Input() public actionHandler: BaseSliderActionHandler;
	@Input() public classes: string | string[];
	@Input() public useTaskDrawer: boolean;
	@Input() public heading: string;
	@Input() public subtitle: string;
	@Input() public isInnerSlideOut: boolean;
	@Output() public onSlideOutClose: EventEmitter<any> = new EventEmitter();
	@Output() public onLoad: EventEmitter<any> = new EventEmitter();

	@ViewChild('slideoutMask')
	public slideOutMask: ElementRef;

	@ViewChild('innerModal')
	public innerModal: ElementRef;

	public slideout$: Observable<SlideoutState[keyof SlideoutState]>;
	public readonly navbarVisible$: Observable<boolean>;

	public currentContext: SlideoutContentContext;

	public taskDrawerIsOpen = false;
	public taskDrawerCloseButtonId = TASK_DRAWER_CLOSE_BUTTON_ID;
	public hasTaskDrawerContainer: boolean;
	public slideOutIosDeviceHelperId = SLIDE_OUT_ISO_DEVICE_HELPER_ID;
	public useIOSDeviceHelper: boolean;

	private closeTimestamp: number;
	private debouncedClose = debounce(this.close.bind(this), 500, {leading: true});
	private navigationContext: Navigation;
	private openTimestamp: number = SLIDEOUT_INITIAL_STATE.open.timestamp;

	public constructor(
		protected clientContainerService: ClientContainerService,
		private elementRef: ElementRef,
		private router: Router,
		private otherActionService: OtherActionService,
		private semaphoreService: ContainerSemaphoreService,
		private sessionStorageService: SessionStorageService,
		public deviceConfigService: DeviceConfigService,
		private windowRef: WindowRefService,
		protected slideOutManagerService: SlideOutManagerService,
		private navbarActionService: NavbarActionService,
		protected slideOutActionService: SlideOutActionService,
		public slideOutAccessibilityService: SlideOutAccessibilityService
	) {
		super(slideOutManagerService, windowRef.nativeWindow, clientContainerService);
		this.slideout$ = this.slideOutActionService.select(['slideout']);
		this.navbarVisible$ = this.navbarActionService.select(['navBar', 'visible']);
		this.navigationContext = this.router.getCurrentNavigation();
	}

	public ngOnInit(): void {
		this.actionHandler = isNil(this.actionHandler)
			? new DefaultSliderActionHandler(this.windowRef, this.otherActionService, this.semaphoreService, this.slideOutActionService)
			: this.actionHandler;
		this.context = this.context || get(this.navigationContext, 'extras.state');
		this.returnFocusElement = this.returnFocusElement || get(this.navigationContext, 'extras.queryParams.returnFocusElement');
		this.returnFocus = isNil(this.returnFocus) ? !isEmpty(this.returnFocusElement) : this.returnFocus;
		this.setContext(this.context);
		if (this.useTaskDrawer) {
			this.setTaskDrawerContext();
			fromEvent(this.windowRef.nativeWindow, 'resize').pipe(takeUntil(this.slideoutDestroy$)).subscribe(this.onResize);
		}
	}

	public ngOnDestroy(): void {
		this.showSlideOut$.next(false);
		this.slideoutDestroy$.next();
		this.slideoutDestroy$.complete();
	}

	public ngAfterViewInit(): void {
		this.semaphoreService.toggleSemaphore();
		this.onLoad.next(true);
		this.slideOutActionService
			.select(['slideout', 'close'])
			.pipe(takeUntil(this.slideoutDestroy$))
			.subscribe((close: SlideoutEvent) => {
				this.closeTimestamp = this.executeEvent(close, this.closeTimestamp, SLIDEOUT_INITIAL_STATE.close.timestamp, this.debouncedClose);
			});
		this.slideOutActionService
			.select(['slideout', 'closeAllowed'])
			.pipe(takeUntil(this.slideoutDestroy$))
			.subscribe((closeAllowed: boolean) => {
				this.currentContext = {closeBtnVisible: get(this.context, 'closeBtnVisible', true) && closeAllowed};
				if (this.useTaskDrawer) {
					this.setTaskDrawerContext();
				}
			});
		this.slideOutActionService
			.select(['slideout', 'open'])
			.pipe(takeUntil(this.slideoutDestroy$))
			.subscribe((open: SlideoutEvent) => {
				this.openTimestamp = this.executeEvent(open, this.openTimestamp, SLIDEOUT_INITIAL_STATE.open.timestamp, this.openSlideOut);
			});
		if (this.useTaskDrawer) {
			this.windowRef.nativeWindow.setTimeout(() => {
				this.openTaskDrawer();
			}, DELAY_TIME_LOW);
		}
	}

	public openSlideOut = (): void => {
		this.showSlideOut$.next(true);
		this.handleKeyboardActions();
	};

	public close(): Promise<void> {
		if (this.useTaskDrawer && !this.isInnerSlideOut) {
			this.closeTaskDrawer();
		}
		const slideoutContext = this.sessionStorageService.get(SLIDEOUT_CONTEXT_KEY);

		this.sessionStorageService.clear(SLIDEOUT_CONTEXT_KEY);
		if (this.showSlideOut) {
			if (this.useTaskDrawer) {
				this.handleClose();
			} else {
				$(this.innerModal.nativeElement).on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', this.handleClose);
			}
		} else {
			this.handleClose();
		}

		if (slideoutContext) {
			const returnFocusIframe = document.getElementById(slideoutContext.returnFocusIframeId) as HTMLIFrameElement;

			if (returnFocusIframe && returnFocusIframe.contentWindow) {
				setTimeout(() => {
					sendPostMessage(returnFocusIframe.contentWindow, this.windowRef.nativeWindow.location.origin, 'info', 'navigation', {type: 'close'}, 'wfd');
				}, TRANSITIONED_DELAY);
			}
		}

		return closeWithValidationCallback(this.validationCallback).then(showSlideOut => {
			this.showSlideOut$.next(showSlideOut);
			if (!showSlideOut) {
				this.closeSlideOut(false);
			}
		});
	}

	public handleKeyboardActions(): void {
		if (!this.useTaskDrawer) {
			$(this.slideOutMask.nativeElement).on('keydown', event => {
				if (event.keyCode === keyCodes.ESC) {
					this.close();
				}
			});

			$(this.innerModal.nativeElement).one('transitionend', () => {
				setTimeout(() => {
					$(this.innerModal.nativeElement).addClass('changed-position');
					$('.view-content').addClass('no-webkit-scroll');
				}, TRANSITIONED_DELAY);
			});
		}
	}

	public taskDrawerDidOpen = (): void => {
		this.taskDrawerHeadingSpeaker();
		this.router.events
			.pipe(takeUntil(this.slideoutDestroy$), filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				if (includes(event.url, 'app-slideout:slide-out') && this.heading) {
					this.taskDrawerHeadingSpeaker();
				}
			});
		this.taskDrawerCloseButtonHandler();
		this.taskDrawerMaskLayerHandler();
	};

	public slideoutMaskClick(event): void {
		event.stopPropagation();
		event.preventDefault();
	}

	private onResize = (): void => {
		if (!this.elementRef.nativeElement.querySelector('ukg-drawer').contains(document.activeElement)) {
			this.getCloseButtonEle().focus();
		}
	};

	private taskDrawerCloseButtonHandler(): void {
		const closeButton = this.getCloseButtonEle();
		const shadowButton = this.getCloseButtonEle().shadowRoot.querySelector('button');

		closeButton.focus();
		$(shadowButton).on('click', event => {
			event.preventDefault();
			event.stopPropagation();
			this.slideOutActionService.dispatch(this.slideOutActionService.actions.close());
		});
	}

	private taskDrawerMaskLayerHandler(): void {
		const taskDrawerMaskLayer = this.windowRef.nativeWindow.document.querySelector(TASK_DRAWER_MASK_LAYER_SELECTOR);
		const clickCallback = (): void => {
			taskDrawerMaskLayer.removeEventListener('click', clickCallback);
			this.slideOutActionService.dispatch(this.slideOutActionService.actions.close());
		};

		if (taskDrawerMaskLayer) {
			taskDrawerMaskLayer.addEventListener('click', clickCallback);
		}
	}

	private taskDrawerHeadingSpeaker(): void {
		this.windowRef.nativeWindow.setTimeout(() => {
			const accessibilitySpeaker = $('#slideout-help-accessibility-speaker');
			let speakerContent = this.heading;

			if (this.subtitle) {
				speakerContent += ` ${this.subtitle}`;
			}
			accessibilitySpeaker.empty();
			accessibilitySpeaker.append(speakerContent);
		});
	}

	private getCloseButtonEle = (): HTMLElement => this.windowRef.nativeWindow.document.querySelector(`#${this.taskDrawerCloseButtonId}`);

	private removeTaskDrawerTooltip = (): void => {
		const tooltips = this.windowRef.nativeWindow.document.querySelectorAll('ukg-tooltip');

		last<HTMLElement>(tooltips).remove();
	};

	private closeTaskDrawer = (): void => {
		this.taskDrawerIsOpen = false;
		this.windowRef.nativeWindow.setTimeout(() => {
			this.hasTaskDrawerContainer = false;
			this.removeTaskDrawerTooltip();
		}, DELAY_TIME_LOW);
	};

	private openTaskDrawer = (): void => {
		this.hasTaskDrawerContainer = true;
		this.windowRef.nativeWindow.setTimeout(() => {
			const drawer = this.elementRef.nativeElement.querySelector('ukg-task-drawer');

			drawer.triggerDrawer().then(this.taskDrawerDidOpen);
		});
	};

	private executeEvent = (event: SlideoutEvent, current: number, initialize: number, callback): number => {
		const slideOutId = get(event, 'id');
		const newTimestamp = get(event, 'timestamp');

		if (isNil(slideOutId) || slideOutId === get(this.actionHandler, 'slideOutId')) {
			if (!isNil(current) && newTimestamp > current) {
				callback();
			}
			return newTimestamp;
		}
		return isNil(current) ? initialize : current;
	};

	private closeSlideOut(isLocationChange: boolean): void {
		this.showSlideOut$.next(false);

		closeSlideOut(
			$(this.innerModal?.nativeElement),
			$(this.slideOutMask?.nativeElement),
			isLocationChange,
			() => {
				this.processRemoveSlideOutOnClose();
			},
			this.processRemoveSlideOutOnClose
		);
	}

	private handleClose = (): void => {
		if (this.navigateOnClose) {
			let delayFunc = (func: () => void): void => func();

			if (this.useTaskDrawer) {
				delayFunc = (callback: () => void) => this.windowRef.nativeWindow.setTimeout(callback, DELAY_TIME_MEDIUM);
			}
			delayFunc(() => {
				this.router.navigate([{outlets: {'app-slideout': null}}]); // TODO: Fix CSS Transition
			});
		}
		this.onSlideOutClose.next(undefined);
	};

	private processRemoveSlideOutOnClose = (): void => {
		if (this.removeSlideOutOnClose) {
			this.elementRef.nativeElement.remove();
			if (this.onRemoveSlideOut) {
				this.onRemoveSlideOut();
			}
		}
	};

	private setContext(context: any): void {
		if (context) {
			this.sessionStorageService.set(SLIDEOUT_CONTEXT_KEY, context);
		}
	}

	private setTaskDrawerContext = (): void => {
		this.currentContext = {...this.currentContext, closeBtnVisible: false};
	};
}
