import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ModalKeyboardNavigationModule} from '../../../../utils/modal-keyboard-navigation/modal-keyboard-navigation.module';
import {MoveShiftTabToModule} from '../../../../utils/move-shift-tab-to/move-shift-tab-to.module';
import {FocusOnShowModule} from '../../../../utils/focus-on-show/focus-on-show.module';
import {ReturnFocusElementModule} from '../../../../utils/return-focus-element/return-focus-element.module';
import {ScreenReaderOnlyModule} from '../../../../utils/screen-reader-only/screen-reader-only.module';
import {ButtonModule} from '../../../button/button.module';
import {SearchModule} from '../../../search/search.module';
import {HeaderModule} from '../header/header.module';
import {MainMenuModule} from '../main-menu/main-menu.module';
import {NavBarSharedModule} from '../navbar.shared.module';
import {NavBarContainerComponent} from './navbar.container';

@NgModule({
	declarations: [NavBarContainerComponent],
	imports: [
		CommonModule,
		NavBarSharedModule,
		MainMenuModule,
		FocusOnShowModule,
		HeaderModule,
		SearchModule,
		ReturnFocusElementModule,
		ModalKeyboardNavigationModule,
		MoveShiftTabToModule,
		ButtonModule,
		ScreenReaderOnlyModule
	],
	exports: [NavBarContainerComponent]
})
export class NavbarContainerModule {}
