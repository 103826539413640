import {Injectable} from '@angular/core';

import {SlideoutCommunicationService as SlideoutCommunicationCoreService} from '../core/generic/slideOutContainer/slideout-communication.service';
import {ClientContainerService} from './clientContainer/client-container.service';
import {PageContextService} from './page-context.service';
import {WindowRefService} from './window-ref.service';

@Injectable({
	providedIn: 'root',
	deps: [WindowRefService, PageContextService, ClientContainerService]
})
export class SlideoutCommunicationService extends SlideoutCommunicationCoreService {
	constructor(
		private windowRefService: WindowRefService,
		private pageContextService: PageContextService,
		clientContainerService: ClientContainerService
	) {
		super(windowRefService.nativeWindow, pageContextService, clientContainerService);
	}
}
