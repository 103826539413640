import {unset} from 'lodash';

export class BeforeUnloadCoreService {
	public isInUnloadPhase = false;
	private registeredFunctions: Array<() => string | void> = [];
	private onUnloadCanceledFunctions: Array<() => void> = [];
	private onUnloadWithoutPromptFunctions: Array<() => void> = [];
	private isUnloadConfirmed = false;

	constructor(private window: Window, private logService) {
		// this unfortunately has to be done to prevent unrelated tests from failing
		// because tests don't properly mock their dependencies
		if (!window.addEventListener) {
			return;
		}

		this.setOnBeforeUnloadEvent();
		this.setOnUnloadEvent();
	}

	public add(fn: () => string | void): void {
		this.logService.debug('registering function to run on beforeunload event');
		this.registeredFunctions.push(fn);
	}

	public registerUnloadCanceledHandler(fn: () => void): void {
		this.logService.debug('registering function to run when beforeunload event is cancelled');
		this.onUnloadCanceledFunctions.push(fn);
	}

	public registerUnloadWithoutPromptHandler(fn: () => void): void {
		this.logService.debug('registering function to run when beforeunload event has no confirmation prompt');
		this.onUnloadWithoutPromptFunctions.push(fn);
	}

	private setOnBeforeUnloadEvent(): void {
		this.window.onbeforeunload = (event: BeforeUnloadEvent) => {
			this.logService.debug('on before unload called');
			this.isUnloadConfirmed = false;
			this.isInUnloadPhase = true;

			const cancelStrings = this.callRegisteredFunctions();
			const hasConfirmationPopup = !!cancelStrings.length;

			if (!hasConfirmationPopup) {
				this.callFunctionsOnUnloadWithoutPrompt();
			}

			this.callFunctionsOnCancelation();

			if (hasConfirmationPopup) {
				const confirmationMessage: string = cancelStrings.join('\n');

				event.returnValue = confirmationMessage;
				return confirmationMessage;
			}
			unset(event, 'returnValue');
		};
	}

	private callRegisteredFunctions(): string[] {
		const cancelStrings = [];

		this.registeredFunctions.forEach(fn => {
			const cancelString = fn();

			if (cancelString) {
				cancelStrings.push(cancelString);
			}
		});
		return cancelStrings;
	}

	/*
	 The assumption is that if the user is prompted and declines to leave,
	 the nested setTimeouts will run before unload event.
	 Otherwise it will either never run or run after unload event.
	 */
	private callFunctionsOnCancelation(): void {
		setTimeout(() => {
			this.logService.debug('calling onbeforeload settimeout');
			setTimeout(() => {
				this.logService.debug('onbeforeload settimeout called');
				if (!this.isUnloadConfirmed) {
					this.onUnloadCanceledFunctions.forEach(fn => fn());
				} else {
					this.logService.debug('unload has been confirmed. Will not call canceled functions.');
				}
				this.isInUnloadPhase = false;
			}, 2000);
		}, 10);
	}

	private callFunctionsOnUnloadWithoutPrompt(): void {
		this.onUnloadWithoutPromptFunctions.forEach(fn => {
			fn();
		});
	}

	private setOnUnloadEvent(): void {
		this.window.addEventListener('unload', () => {
			this.isUnloadConfirmed = true;
		});
	}
}