import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {get, isEqual, isFunction, isString, toString} from 'lodash';
import {Title} from '@angular/platform-browser';

import {imageURI, isADPMobileApp, returnBackToADP} from '../../../../core/navBar/adp-helper.service';
import {HOME_URI} from '../../../../core/navBar/nav-bar-menu';
import {accessibilitySpeakerRead} from '../../../../services/accessibility/accessibility-speaker.utility';
import {DeviceConfigService} from '../../../../services/device-config.service';
import {WindowRefService} from '../../../../services/window-ref.service';
import {ActionItemBaseComponent} from './action-item/action-item-base.component';
import {PropertyFilterPipe} from '../../../../providers/property/property.filter.pipe';
import { NavbarActionService } from '../container/navbar.action.service';

const SET_TITLE_DELAY = 100;

@Component({
	selector: 'krn-ng-nav-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.less'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarHeaderComponent implements OnChanges {
	@Input() public additionalData: any;
	@Input() public app: any;
	@Input() public title: string;
	@Input() public homeUri: string;
	@Input() public rightSectionItems: ActionItemBaseComponent[];
	@Input() public logoVersion: string;
	@Input() public isMenuOpen: boolean;

	@Output() public onMainMenuToggled: EventEmitter<void> = new EventEmitter<void>();

	public isPageLoaded = true;

	private window: any;

	constructor(
		public deviceConfigService: DeviceConfigService,
		private router: Router,
		private titleService: Title,
		private windowRefService: WindowRefService,
		private propertyFilter: PropertyFilterPipe,
		private navbarActionService: NavbarActionService
	) {
		this.window = windowRefService.nativeWindow;
	}

	public toggleSidebar(): void {
		if (this.isPageLoaded) {
			this.isPageLoaded = false;
		}
		this.onMainMenuToggled.emit();
	}

	public onHomeButtonClick(event: Event): void {
		event.preventDefault();
		if (isFunction(get(this, 'app.returnBackToHomePage'))) {
			this.app.returnBackToHomePage();
		} else {
			const appHomeUri = isString(this.app) && this.app !== 'falcon' ? `/${this.app}` : '/';
			const homeLink = this.isSPAOpened() ? `/${HOME_URI}` : this.homeUri || appHomeUri;

			if (!isEqual(this.window.location.pathname, homeLink)) {
				if (this.isSPAOpened()) {	
					this.windowRefService.nativeWindow.setTimeout(() => {
						this.titleService.setTitle(this.propertyFilter.transform('html.navBar.menu.home.label'));
						this.navbarActionService.dispatch(this.navbarActionService.actions.setTitle(this.titleService.getTitle()));
					}, SET_TITLE_DELAY);
					this.router.navigate([homeLink]);
				} else {
					this.window.location.href = homeLink;
				}
			}
		}
	}

	public launchMobileApp(): void {
		returnBackToADP();
	}

	public showInMobileApp(): boolean {
		return isADPMobileApp();
	}

	public getImage(): string {
		return imageURI();
	}

	public isMobile(): boolean {
		return this.deviceConfigService.isPhone();
	}

	public isFirefox(): boolean {
		return this.deviceConfigService.isFirefox(this.window.navigator);
	}

	public onPopoverShown = (): void => {
		if (this.window.QSI) {
			this.window.QSI.API.unload();
			this.window.QSI.API.load();
			this.window.QSI.API.run();
		}
	};

	public ngOnChanges(changes: SimpleChanges): void {
		const titleCurrentValue: string = toString(get(changes, 'title.currentValue'));

		if (titleCurrentValue) {
			accessibilitySpeakerRead(titleCurrentValue, {readTimeout: 300});
			this.titleService.setTitle(titleCurrentValue);
		}
	}

	private isSPAOpened(): boolean {
		return this.app === 'app';
	}
}
