import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {get, has, includes, isArray, isEmpty, isUndefined, size, some} from 'lodash';
import {Subscription, timer} from 'rxjs';

import {Classes, LoaderShow, Messages, PropertiesApi} from '../../core/generic/loader/loader.interfaces';
import {A11yAttrs} from '../../core/platform/attributes.interfaces';

import {
	DEF_FIRST_LOADER_PROPERTY,
	DEF_SECOND_LOADER_PROPERTY,
	DEF_SPEAK_COMPLETE_PROPERTY,
	DEF_SPEAK_STARTED_PROPERTY,
	DEF_THIRD_LOADER_PROPERTY,
	ONE_SECOND,
	SPEAK_COMPLETE_PROPERTY,
	SPEAK_STARTED_PROPERTY,
	UPDATE_MS
} from '../../core/generic/loader/loader.constants';
import {
	loadProperties,
	selectClasses,
	selectSpinnerAriaLabel,
	selectSpinnerText,
	speakComplete,
	speakStarted
} from '../../core/generic/loader/loader.utility';
import {PropertyFactory} from '../../core/property/property.factory';
import {PropertyFilterPipe} from '../../providers/property/property.filter.pipe';
import {accessibilitySpeakerRead} from '../../services/accessibility/accessibility-speaker.utility';

const ARIA_LABEL = 'aria-label';
const ARIA_LIVE = 'aria-live';
const OFF = 'off';
const ONE_MESSAGE = 1;

@Component({
	selector: 'krn-ng-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnChanges, OnDestroy, OnInit {
	@Input() public attrs: A11yAttrs;
	@Input() public classes: Classes;
	@Input() public cycleTime: number;
	@Input() public messages: Messages;
	@Input() public show: string;

	public spinnerText: string;
	public adjustedClasses: Classes;
	private messageCounter: number;
	private messageTimer: Subscription;
	private messageTimerInProgress: boolean;

	private allPropertiesApi: PropertiesApi = {
		defFirstLoaderProp: (): string => this.propertyFilterPipe.transform(DEF_FIRST_LOADER_PROPERTY),
		defSecondLoaderProp: (): string => this.propertyFilterPipe.transform(DEF_SECOND_LOADER_PROPERTY),
		defSpeakCompleteProp: (): string => this.propertyFilterPipe.transform(DEF_SPEAK_COMPLETE_PROPERTY),
		defSpeakStartedProp: (): string => this.propertyFilterPipe.transform(DEF_SPEAK_STARTED_PROPERTY),
		defThirdLoaderProp: (): string => this.propertyFilterPipe.transform(DEF_THIRD_LOADER_PROPERTY),
		speakCompleteProp: (label: string): string => this.propertyFilterPipe.transform(SPEAK_COMPLETE_PROPERTY, [label]),
		speakStartedProp: (): string => this.propertyFilterPipe.transform(SPEAK_STARTED_PROPERTY)
	};

	constructor(private propertyFactory: PropertyFactory, private propertyFilterPipe: PropertyFilterPipe) {
		this.messageTimerInProgress = false;
	}

	public hasVisibleMessages = (): any => !isEmpty(this.spinnerText) || some(this.messages, message => !isEmpty(message));

	public ngOnChanges(changes: SimpleChanges): void {
		const changedMsg = has(changes, 'messages') && !isUndefined(this.show);

		if (changedMsg) {
			this.spinnerText = selectSpinnerText(this.showMessage(), this.messages, this.allPropertiesApi);
			this.triggerMessageTimer();
			this.messageTimerInProgress = true;
		}
		if (changedMsg || has(changes, 'classes')) {
			this.adjustedClasses = selectClasses(this.showMessage(), this.classes);
		}
	}

	public ngOnDestroy(): void {
		if (this.messageTimer) {
			this.messageTimer.unsubscribe();
		}
		if (get(this.attrs, ARIA_LIVE) !== OFF) {
			accessibilitySpeakerRead(speakComplete(this.attrs[ARIA_LABEL], this.allPropertiesApi));
		}
	}

	public async ngOnInit(): Promise<void> {
		this.show = this.show || LoaderShow.IMG;
		this.attrs = this.attrs || {};
		this.adjustedClasses = selectClasses(this.showMessage(), this.classes);

		if (this.showMessage()) {
			await loadProperties(this.propertyFactory);
		}

		if (!this.messageTimerInProgress) {
			this.spinnerText = selectSpinnerText(this.showMessage(), this.messages, this.allPropertiesApi);
			this.triggerMessageTimer();
		}

		this.attrs[ARIA_LABEL] = selectSpinnerAriaLabel(this.attrs[ARIA_LABEL], this.allPropertiesApi);
		if (get(this.attrs, ARIA_LIVE) !== OFF) {
			accessibilitySpeakerRead(speakStarted(this.attrs[ARIA_LABEL], this.allPropertiesApi));
		}
	}

	public showImage = (): any => includes([LoaderShow.IMG, LoaderShow.ALL], this.show);

	public showMessage = (): any => includes([LoaderShow.MSG, LoaderShow.ALL], this.show);

	private incrementMessageCount = (): any => {
		this.spinnerText = selectSpinnerText(this.showMessage(), this.messages, this.allPropertiesApi, ++this.messageCounter);
	};

	private triggerMessageTimer(): void {
		const canCycleMessages = isEmpty(this.messages) || (isArray(this.messages) && size(this.messages) > ONE_MESSAGE);

		this.messageCounter = 0;
		if (!this.messageTimer && this.spinnerText && canCycleMessages) {
			this.messageTimer = timer(this.getCycleTime(), this.getCycleTime()).subscribe(this.incrementMessageCount.bind(this));
		}
	}

	private getCycleTime = (): any => (this.cycleTime ? this.cycleTime * ONE_SECOND : UPDATE_MS);
}
