export const RETRIEVAL_OPTIONS = {
	enableHighAccuracy: true,
	maximumAge: 10000
};
export const ONE_MINUTE = 60000;
export const ZERO_DELTA = 0;
export const INITIAL_TIMEOUT = 3000;
export const TRACKING_TIMEOUT = 5000;
export const DESIRED_ACCURACY = 75; // default accuracy if no server system setting has been defined.
export const FINE_ACCURACY = 10;
export const FINE_LOCATION_TYPE = 'FINE';
export const COARSE_LOCATION_TYPE = 'COARSE';
