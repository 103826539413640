import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {isWfdMobileApp, isOneApp} from '@kronos/zed/src/lib/core/platform/services/DeviceConfig.utility';
import {KrnUtilitiesMobileWindow, mobileContextInit} from '@kronos/zed/src/lib/core/platform/services/mobileInitializer.utility';
import {oneappSSOMobileContextInit} from '@kronos/zed/src/lib/core/platform/services/oneappMobileInitializer.utility';
import {SENDER_NAME} from '@kronos/zed/src/lib/core/one-app/one-app-event-bus.constants';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
	enableProdMode();
}

const bootstrapApplication = (): void => {
	if (isWfdMobileApp(window)) {
		mobileContextInit(window as unknown as KrnUtilitiesMobileWindow, bootstrapPlatform);
	} else {
		oneappSSOMobileContextInit(window, SENDER_NAME).then(resp => {
			if (resp) {
				bootstrapPlatform();
			}
		});
	}
};

const bootstrapPlatform = (): void => {
	platformBrowserDynamic().bootstrapModule(AppModule).catch();
};

if ((window === window.parent) && (isWfdMobileApp(window) || isOneApp(window))) {
	document.addEventListener('deviceready', bootstrapApplication, false);
} else {
	bootstrapPlatform();
}
